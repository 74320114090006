import { SetStateAction, useCallback, useMemo, useState } from "react";
import { Button, ButtonGroup, InputGroup } from "react-bootstrap";
import { APIInstanceWithAuthForAdmin } from "../../../api"
import { PropertyForm, PropertyFormAgencyExclusiveType, PropertyFormAgencyRelationship, PropertyFormAgencyRelationships, PropertyFormTypes } from "../../../api/entities/property/property-form";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider";
import { AdminPropertyI18NPrefix } from "../src/Property";
import { EntityData } from "./EntityData";

import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faCheck, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { ButtonGroupSelector } from "./ButtonGroupSelector";
import { GenericInput } from "./GenericInput";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { useAbortableLoading } from "../../../utils/abortable-loading";
import { downloadFromUrl } from "../../../utils/download-from-url";
import moment from "moment";
import { numberInShortPublic } from "../../../utils/number-in-short";


export const Form3Or5Generator = (props: {
  api: APIInstanceWithAuthForAdmin,
  loading?: boolean,
  onGenerate?: (form: PropertyForm) => PropertyForm
}) => {
  const {api, loading, onGenerate} = props;
  const generating = useAbortableLoading();
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const {t} = useI18N();
  const [form, setForm] = useState<PropertyForm>(new PropertyForm());
  const [generated, setGenerated] = useState(false);
  const [unsaved, setUnsaved] = useState(false);
  const updateForm = useCallback((value: SetStateAction<PropertyForm>) => {
    setForm(value)
  }, [setForm])
  const {formType, lang} = form;
  const modal = useGlobalModal();

  const fullyGenerated = useMemo(() => generated && form.lang && form.formType, [generated, form]);

  const handleGenerate = useCallback(async(form: PropertyForm) => {
    if (onGenerate && !fullyGenerated || (fullyGenerated && await modal.confirmUnsaved())) {
      setGenerated(true);
      const formUpdated = onGenerate({
        ...form
      })
      updateForm(formUpdated);
    }
  }, [onGenerate, fullyGenerated, updateForm])

  return (
    <EntityData
      object={form}
      onChange={form => updateForm(form)}
      noMarginCompensate
      tight
      meta={{
        formType: {
          type: "entireComponent",
          component: (
            <div className="w-100 justify-content-center d-flex">
              <ButtonGroup size="sm">
                {
                  PropertyFormTypes.map(t => {
                    return <Button key={t}
                      variant={formType === t ? "info" : "outline-info"}
                      onClick={() => {
                        handleGenerate({...form, formType: t})
                      }}
                    >{
                     t == "form3" ? "Form 3" : "Form 5" 
                    }</Button>;
                  })
                }
              </ButtonGroup>
            </div>
          )
        },
        lang: {
          hidden: !formType,
          type: "select",
          title: t(AdminPropertyI18NPrefix + "pdf.language"),
          placeholder: t("admin.commons.select") + "...",
          selectOptions: ["tc", "en"].map(lang => ({
            value: lang,
            label: t(AdminPropertyI18NPrefix + "pdf.languages." + lang)
          })),
          onChangeOverride: async(lang: PropertyForm["lang"]) => {
            handleGenerate({...form, lang})
          }
        },
        refNo: {
          hidden: !fullyGenerated,
          type: "text",
          title: "Ref No. | 參考編號",
        },

        vendor: {
          hidden: !fullyGenerated,
          type: "text",
          title: "Vendor | 賣方",
        },
        agent: {
          hidden: !fullyGenerated,
          type: "text",
          title: "Agent | 代理",
        },
        agencyExclusiveType: {
          hidden: !fullyGenerated,
          type: "valueComponent",
          title: "Agency exclusive type | 代理獨家方式",
          component: <div>
            <ButtonGroupSelector<PropertyFormAgencyExclusiveType> size="sm"
              deleteOthersIfSelected
              value={form.agencyExclusiveType}
              options={[
                {value: "my", label: {"en": "My agent", "tc": "本人代理"}[form.lang]},
                {value: "ourExclusive", label: {"en": "Our exclusive agent", "tc": "獨家代理"}[form.lang]},
                {value: "nonExclusive", label: {"en": "Non-exclusive agent", "tc": "非獨家代理"}[form.lang]}
              ]}
              onChange={(value, selected) => {
                updateForm({...form, agencyExclusiveType: selected ? null : value.value})
              }}
            />
          </div>
        },
        property: {
          hidden: !fullyGenerated,
          type: "text",
          title: "Property | 物業",
        },

        validatyPeriod: {
          hidden: !fullyGenerated,
          type: "valueComponent",
          title: "Validility Period | 有效期",
          component: (
            <div className="d-flex align-items-center">
              <DatePicker
                className="form-control"
                dateFormat="yyyy-MM-dd"
                selected={form.validatyPeriodFrom}
                selectsStart
                startDate={form.validatyPeriodFrom}
                endDate={form.validatyPeriodTo}
                onChange={validatyPeriodFrom => {
                  updateForm(form => {
                    const validatyPeriodTo = validatyPeriodFrom ? moment(validatyPeriodFrom).add(1, "years").subtract(1, "days").toDate() : null;
                    return {...form, validatyPeriodFrom, validatyPeriodTo};
                  })
                }}
                isClearable
                clearButtonClassName="clear-btn"
              />
              <span className="mx-2">－</span>
              <DatePicker
                className="form-control"
                dateFormat="yyyy-MM-dd"
                selected={form.validatyPeriodTo}
                selectsEnd
                minDate={form.validatyPeriodFrom}
                startDate={form.validatyPeriodFrom}
                endDate={form.validatyPeriodTo}
                onChange={date => {
                  updateForm(form => ({...form, validatyPeriodTo: date}))
                }}
                isClearable
                clearButtonClassName="clear-btn"
              />
            </div>
          )

        },

        agencyRelationship: {
          hidden: !fullyGenerated,
          type: "valueComponent",
          title: "Agency Relationship | 代理關係",
          component: <div>
            <ButtonGroupSelector<PropertyFormAgencyRelationship> size="sm"
              deleteOthersIfSelected
              value={form.agencyRelationship}
              options={[
                {value: "single", label: {"en": "Single Agency", "tc": "單邊代理"}[form.lang]},
                {value: "dual", label: {"en": "Dual Agency", "tc": "雙邊代理"}[form.lang]},
                {value: "potential-dual", label: {"en": "Potentially Dual Agency", "tc": "有可能代表雙方的代理"}[form.lang]}
              ]}
              onChange={(value, selected) => {
                updateForm({...form, agencyRelationship: selected ? null : value.value})
              }}
            />
          </div>
        },

        price: {
          hidden: !fullyGenerated,
          type: "text-int",
          title: formType == "form3" ? "List Price | 放盤價" : "List Rental | 放盤租金",
          divWidth: {xs: 6, md: 6},
          onChange: (value: number) => {
            if (value != null) {
              updateForm(form => ({...form, priceShort: numberInShortPublic(value, lang)}))
            }
          }
        },

        floatingRange: {
          hidden: !fullyGenerated,
          type: "valueComponent",
          title: "Range | 浮動",
          divWidth: {xs: 6, md: 6},
          component: (
            <InputGroup>
              <InputGroup.Text>±</InputGroup.Text>
              <GenericInput 
                type="text-int"
                value={form.floatingRange}
                onChange={floatingRange => updateForm(form => ({...form, floatingRange}))}
              />
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
          )
        },

        priceShort: {
          hidden: !fullyGenerated,
          type: "text",
          title: "Short | 縮寫",
          divWidth: {xs: 12, md: 12}
        },

        priceIncludeFees: {
          hidden: !(fullyGenerated && formType == "form5"),
          type: "valueComponent",
          title: lang == "en" ? "Rental per month inclusive/exclusive of rates and management fee" : "租金每月 包括/不包括 差餉及管理費",
          component: (
            <ButtonGroupSelector<boolean> size="sm"
              deleteOthersIfSelected
              value={form.priceIncludeFees}
              options={[
                {value: true, label: {"en": "Inclusive", "tc": "包括"}[form.lang]},
                {value: false, label: {"en": "Exclusive", "tc": "不包括"}[form.lang]},
              ]}
              onChange={(value, selected) => {
                updateForm({...form, priceIncludeFees: selected ? null : value.value})
              }}
            />
          )
        },
        
        inspection: {
          hidden: !fullyGenerated,
          type: "valueComponent",
          title: lang == "en" ? "Property Inspection" : "視察物業",
          component: (
            <div className="d-block">
              <div className="mb-2">(6a) {
                lang == "en" ? "The Landlord agrees to allow viewing of the Property by the Agent/tenant(s) at a mutually agreeable time." :
                  "業主同意容許 代理/租客 於雙方議定的時間查看物業。"
              }</div>
              <ButtonGroupSelector<boolean> size="sm"
                value={form.agreeViewing}
                options={[
                  {value: true, label: {"en": "Yes", "tc": "同意"}[form.lang]},
                  {value: false, label: {"en": "No", "tc": "不同意"}[form.lang]},
                ]}
                onChange={(value, selected) => {
                  updateForm({...form, agreeViewing: value.value})
                }}
              />


              <div className="my-2">(6b) {
                lang == "en" ? "The Vendor agrees to pass the keys of the Property to the Agent for safe custody and for the viewing of the Property." :
                  "賣方同意將物業的鎖匙交由代理妥為保管，以供查看物業之用。"
              }</div>
              <ButtonGroupSelector<boolean> size="sm"
                value={form.agreeKeepKeys}
                options={[
                  {value: true, label: {"en": "Yes", "tc": "同意"}[form.lang]},
                  {value: false, label: {"en": "No", "tc": "不同意"}[form.lang]},
                ]}
                onChange={(value, selected) => {
                  updateForm({...form, agreeKeepKeys: value.value})
                }}
              />

              <div className="my-2">(6c) {
                lang == "en" ? "The Vendor authorizes the Agent to pass the keys to other estate agents/persons for the viewing of the Property." :
                  "賣方授權代理將鎖匙交予其他 地產代理/人士，以供查看物業之用。"
              }</div>
              <ButtonGroupSelector<boolean> size="sm"
                value={form.agreePassKeys}
                options={[
                  {value: true, label: {"en": "Yes", "tc": "同意"}[form.lang]},
                  {value: false, label: {"en": "No", "tc": "不同意"}[form.lang]},
                ]}
                onChange={(value, selected) => {
                  updateForm({...form, agreePassKeys: value.value})
                }}
              />
            </div>
            
          )
        },

        authorizeSubListing: {
          hidden: !fullyGenerated,
          type: "valueComponent",
          title: lang == "en" ? "Sub-listing" : "分銷放盤",
          component: (
            <div className="d-block">
              <div className="mb-2">(7a) {
                lang == "en" ? "The Vendor authorizes the Agent to sub-list the Property and pass relevant information about the Vendor and the Property supplied by the Vendor to other estate agents for sub-listing purposes." :
                  "賣方授權代理將物業分銷放盤，並將由賣方提供的關於賣方及物業的資料交予其他地產代理以供分銷放盤之用。"
              }</div>
              <ButtonGroupSelector<boolean> size="sm"
                value={form.authorizeSubListing}
                options={[
                  {value: true, label: {"en": "Yes", "tc": "同意"}[form.lang]},
                  {value: false, label: {"en": "No", "tc": "不同意"}[form.lang]},
                ]}
                onChange={(value, selected) => {
                  updateForm({...form, authorizeSubListing: value.value})
                }}
              />
            </div>
          )
        },

        authorizeAdvertising: {
          hidden: !fullyGenerated,
          type: "valueComponent",
          title: lang == "en" ? "Advertising" : "廣告宣傳",
          component: (
            <div className="d-block">
              <div className="mb-2">(8a) {
                lang == "en" ? "The Vendor authorizes the Agent to issue advertisement in respect of the Property." :
                  "賣方授權代理發出關於物業的廣告。"
              }</div>
              <ButtonGroupSelector<boolean> size="sm"
                value={form.authorizeAdvertising}
                options={[
                  {value: true, label: {"en": "Yes", "tc": "同意"}[form.lang]},
                  {value: false, label: {"en": "No", "tc": "不同意"}[form.lang]},
                ]}
                onChange={(value, selected) => {
                  updateForm({...form, authorizeAdvertising: value.value})
                }}
              />
            </div>
          )
        },

        agentSpecificRelatives: {
          hidden: !fullyGenerated,
          type: "valueComponent",
          title: lang == "en" ? "Disclosure of Interest by Agent" : "代理須披露權益",
          component: (
            <div className="d-block">
              <div className="mb-2">(9a) {
                lang == "en" ? "The person signing this Agreement on behalf of the Agent discloses that he or his specified relative, or any nominee of his or of his specified relative, or the Agent or any employee/substantial shareholder/partner/director of the Agent has a pecuniary or other beneficial interest in the Property:" :
                  "代表代理簽署本協議的人現披露：他或其指明親屬，或其任何代名人，或其指明親屬的任何代名人，或代理或代理的任何僱員/大股東/合夥人/董事對物業擁有金錢上的或其他實益的權益："
              }</div>
              <ButtonGroupSelector<boolean> size="sm"
                value={form.agentSpecificRelatives}
                options={[
                  {value: true, label: {"en": "Yes", "tc": "有"}[form.lang]},
                  {value: false, label: {"en": "No", "tc": "沒有"}[form.lang]},
                ]}
                onChange={(value, selected) => {
                  updateForm({...form, agentSpecificRelatives: value.value})
                }}
              />
            </div>
            
          )
        },

        agreeFormReceived: {
          hidden: !fullyGenerated,
          type: "valueComponent",
          title: lang == "en" ? "Acknowledgement by Vendor" : "賣方的確認",
          component: (
            <ButtonGroupSelector<boolean> size="sm" vertical
              value={form.agreeFormReceived}
              options={[
                {value: true, label: {
                 "form3": {
                    "en": "received the Property Information Form including the Vendor’s Statement required to be provided under clause 5 of this Agreement.", 
                    "tc": "收取本協議第 5 條規定提供的物業資料表格(包括賣方的陳述)。"
                  },
                  "form5": {
                    "en": "received the Leasing Information Form required to be provided under clause 5 of this Agreement. ", 
                    "tc": "收取本協議第5條規定提供的出租資料表格。"
                  }, 
                }[formType]?.[form.lang]},
                {value: false, label: {
                  "form3": {
                     "en": "agreed to receive the Property Information Form including the Vendor’s Statement before entering into a binding agreement for sale and purchase between the Vendor and a purchaser.", 
                     "tc": "同意於賣方與一名買方訂立具約束力的買賣協議之前收取物業資料表格(包括賣方的陳述)。"
                   },
                   "form5": {
                     "en": "agreed to receive the Leasing Information Form before entering into a binding lease between the Landlord and a tenant.", 
                     "tc": "同意於業主與一名租客訂立具約束力的租契之前收取出租資料表格。"
                   }, 
                 }[formType]?.[form.lang]},
              ]}
              onChange={(value, selected) => {
                updateForm({...form, agreeFormReceived: value.value})
              }}
            />
          )
        },

        extraTerms: {
          hidden: !fullyGenerated,
          type: "text-area",
          title: lang == "en" ? "Extra Terms" : "額外條款",
          
        }, 

        signatoryInfo: {
          hidden: !fullyGenerated,
          type: "text",
          title: lang == "en" ? 
            "Name and licence number of the signatory" : 
            "簽署人的姓名或名稱及牌照號碼"
        },

        agentBusinessNumber: {
          hidden: !fullyGenerated,
          type: "text",
          title: lang == "en" ? "Number of Agent's statement of particulars of business" : "代理的營業詳情說明書號碼"
        },

        address: {
          hidden: !fullyGenerated,
          type: "text",
          title: lang == "en" ? "Address" : "地址"
        },

        phone: {
          hidden: !fullyGenerated,
          type: "text",
          title: lang == "en" ? "Telephone number" : "電話號碼"
        },

        fax: {
          hidden: !fullyGenerated,
          type: "text",
          title: lang == "en" ? "Fax number" : "傳真號碼"
        },

        withSignature: {
          hidden: !fullyGenerated,
          type: "switch",
          title: lang == "en" ? "With signature" : "連同簽署"
        },

        date: {
          hidden: !fullyGenerated,
          type: "valueComponent",
          title: lang == "en" ? "Signature date" : "簽署日期",
          component: (
            <DatePicker
              className="form-control"
              dateFormat="dd/MM/yyyy"
              selected={form.date}
              onChange={date => {
                updateForm(form => ({...form, date}))
              }}
              isClearable
              clearButtonClassName="clear-btn"
            />
          )
        },

        commission: {
          hidden: !fullyGenerated,
          type: "valueComponent",
          title: lang == "en" ? "Commission" : "佣金",
          component: (
            <div className="d-block">
              <ButtonGroupSelector<boolean> size="sm" vertical
                className="mb-2"
                value={form.commissionUponSigning}
                options={[
                  {value: true, label: {
                    "form3": {
                       "en": "upon the signing of the agreement for sale and purchase", 
                       "tc": "簽署買賣協議時"
                     },
                     "form5": {
                       "en": "upon the signing of the lease", 
                       "tc": "買賣協議指明的物業交易完成時"
                     }, 
                   }[formType]?.[form.lang]},
                  {value: false, label: {
                    "form3": {
                       "en": "upon the completion of the property transaction as specified in the agreement for sale and purchase", 
                       "tc": "簽署租契時"
                     },
                     "form5": {
                       "en": "upon the commencement of the tenancy as specified in the lease", 
                       "tc": "租契指明的租賃期開始時"
                     }, 
                   }[formType]?.[form.lang]},
                ]}
                onChange={(value, selected) => {
                  updateForm({...form, commissionUponSigning: selected ? null : value.value})
                }}
              />

              <div className="d-flex align-items-center">
                <InputGroup>
                  <InputGroup.Text>HK$</InputGroup.Text>
                  <GenericInput 
                    type="text-float"
                    disabled={form.commissionPercentage != null}
                    value={form.commissionAmount}
                    onChange={commissionAmount => updateForm(form => ({...form, commissionAmount}))}
                  />
                </InputGroup>
                <span className="mx-2">{
                  form.lang == "en" ? "OR" : "或"
                }</span>
                <InputGroup>
                  <GenericInput 
                    type="text-float"
                    disabled={form.commissionAmount != null}
                    value={form.commissionPercentage}
                    onChange={commissionPercentage => updateForm(form => ({...form, commissionPercentage}))}
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            
          )
        },

        commissionRefund: {
          hidden: !fullyGenerated,
          type: "valueComponent",
          title: lang == "en" ? "Commission Refund if Transaction Fails" : "若交易未能完成的佣金退還",
          component: (
            <div className="d-block">
              <ButtonGroupSelector<boolean> size="sm"
                deleteOthersIfSelected
                value={form.commissionRefundWithInterest}
                options={[
                  {value: true, label: {"en": "With interest", "tc": "連同利息"}[form.lang]},
                  {value: false, label: {"en": "Without interest", "tc": "不連同利息"}[form.lang]},
                ]}
                onChange={(value, selected) => {
                  updateForm({...form, commissionRefundWithInterest: selected ? null : value.value})
                }}
              />
            </div>
          )
        },

        filename: {
          hidden: !fullyGenerated,
          type: "text",
          title: lang == "en" ? "Filename (Sharing purpose)" : "檔案名稱 (分享檔案時顯示)",
          onChangeOverride: str => {
            setForm(form => ({...form, filename: str?.replaceAll(/[\/\?]/g, "")}))
          }
        },

        generatePdf: {
          hidden: !fullyGenerated,
          type: "entireComponent",
          component: (
            <>
              <ButtonWithLoader
                loading={generating.flag}
                onClick={async() => {
                  const {token, signal} = generating.start();
                  try {
                    setDownloadUrl(null);
                    
                    const fileToken = await api.property.generateFormPdf(form, {signal});

                    const filename = `${form.filename || "form"}.pdf`;
                    const url = api.pdfUtil.getPdfUrl(filename, fileToken);
                    // console.log(url);
                    const popup = window.open(url, "_blank");
                    
                    if (popup) {
                      popup.focus();
                    } else {
                      console.warn("Popup blocked");
                      downloadFromUrl(url, filename);
                    }
                    setDownloadUrl(url);

                  } catch(e) {
                    modal.errorSpecific(e);
                  }
                  generating.stop(token);

                }}
              >
                {t(AdminPropertyI18NPrefix + "pdf.generatePdf")}
              </ButtonWithLoader>
              {
                generating.flag && (
                  <ButtonWithLoader variant="outline-danger" faIcon={faBan} size="sm" onClick={() => {
                    generating.abort();
                  }}>
                    {t(AdminPropertyI18NPrefix + "pdf.cancel")}
                  </ButtonWithLoader>
                )
              }
            </>
          )
        },

        downloadUrl: {
          type: "component",
          hidden: !downloadUrl,
          component: (
            <div className="text-center">
              <b><a href={downloadUrl} target="_blank" className="text-info text-decoration-none">&gt;&gt; {t(AdminPropertyI18NPrefix + "pdf.autoOpenFailed")} &lt;&lt;</a></b>
            </div>
          )
        },
        generatePdfInfo: {
          hidden: !fullyGenerated,
          type: "entireComponent",
          component: <>
            <div className="text-muted entity-data-item">
              <div><FontAwesomeIcon icon={faCircleInfo} fixedWidth className="me-2"/>
              {t(AdminPropertyI18NPrefix + "pdf.linkHints")}</div>
            </div>
          </>
        },

      }}
    />
  )
}
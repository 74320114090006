import React, { useContext, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { FileManager, FileManagerProps, FileManagerStatWithMime } from "../../../component/file-manager/src/FileManager";
import { useGlobalModal } from "../../../component/GlobalModal";
import "../../../styles/admin/file-manager-modal.scss";

interface FileManagerModalContextType {
  fileManagerChoose: (
    api: FileManagerProps["api"],
    mode: 'file' | 'folder',
    allowedMimeTypes?: RegExp,
    initialSelectedFilePath?: string,
    initialPath?: string,       // If selectedFilePath is null
  ) => Promise<string>
}
const FileManagerModalContext = React.createContext<FileManagerModalContextType>({
  fileManagerChoose: null,
});

export const useFileManagerModal = () => {
  return useContext(FileManagerModalContext);
}

export const FileManagerModalProvider = (props: React.PropsWithChildren<{
  basePath: string,
  awsS3Path?: string,
}>) => {
  const {basePath, awsS3Path} = props;
  const [open, setOpen] = useState(false);
  const [api, setApi] = useState<FileManagerProps["api"]>(null);
  const [allowedMimeTypes, setAllowMimeTypes] = useState<RegExp>(null);
  const [initialPath, setInitialPath] = useState<string>(null);
  const [initialSelectedFilePath, setInitialSelectedFilePath] = useState<string>(null);

  const handleChooseFile = useRef<(src: string) => void>(null);
  const handleChooseFiles = useRef<(srcs: string[]) => void>(null);
  
  const handleChoose = useRef<FileManagerProps["onChoose"]>(null);

  const modal = useGlobalModal();
  
  const chooseFile = async(
    api: FileManagerProps["api"],
    allowedMimeTypes?: RegExp,
    initialSelectedFilePath?: string,
  ): Promise<string> => (new Promise((resolve, reject) => {

    handleChooseFile.current = (src) => {
      setOpen(false);
      resolve(src);
    };
    handleChooseFiles.current = null;
    setApi(api);
    setAllowMimeTypes(allowedMimeTypes);
    setInitialSelectedFilePath(initialSelectedFilePath);
    setOpen(true);

  }))

  const chooseFiles = async(
    api: FileManagerProps["api"],
    allowedMimeTypes?: RegExp
  ): Promise<string[]> => (new Promise((resolve, reject) => {

    handleChooseFile.current = null;
    handleChooseFiles.current = (srcs) => {
      setOpen(false);
      resolve(srcs);
    };
    setApi(api);
    setAllowMimeTypes(allowedMimeTypes);
    setOpen(true);

  }))

  const fileManagerChoose = async(
    api: FileManagerProps["api"],
    mode: FileManagerProps["onChoose"]["mode"],
    allowedMimeTypes?: RegExp,
    initialSelectedFilePath?: string,
    initialPath?: string,       // If selectedFilePath is null
  ): Promise<any> => (new Promise((resolve, reject) => {
    handleChoose.current = {
      mode, 
      handler: (src) => {
        setOpen(false);
        resolve(src);
      }
    }

    setApi(api);
    setAllowMimeTypes(allowedMimeTypes);
    if (mode === 'file') {
      setInitialPath(!initialSelectedFilePath && initialPath);
      setInitialSelectedFilePath(initialSelectedFilePath);
    } else if (mode === 'folder') {
      setInitialPath(initialPath);
      setInitialSelectedFilePath(null);
    }
    setOpen(true);
  }))

  return (
    <FileManagerModalContext.Provider
      value={{fileManagerChoose}}
    >
      {props.children}
      {
        api && (
          <Modal
            show={open}
            centered
            className="file-manager-modal"
            backdropClassName="file-manager-modal-backdrop"
            onHide={() => {
              handleChooseFile.current && handleChooseFile.current(null);
              handleChooseFiles.current && handleChooseFiles.current(null);
              
              // Send null to handle to close the modal
              handleChoose?.current?.handler?.(null);
            }}
          >
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
              <FileManager
                basePath={basePath}
                awsS3Path={awsS3Path}
                api={api}
                allowedMimeTypes={allowedMimeTypes}
                initialPath={initialPath}
                initialSelectedFilePath={initialSelectedFilePath}   // aws-s3://aaa/bbbbb/def.txt ===> abc/bbbbb
                onChoose={handleChoose.current}
                onCreateFolder={() => {
                  return modal.prompt("Create New Folder", {
                    type: "text", placeholder: "New folder name"
                  }, true);
                }}
                onRename={(originalName) => {
                  return modal.prompt("Rename", {
                    type: "text", placeholder: "New name", initialValue: originalName
                  }, true);
                }}
                onOverwrite={() => {
                  return modal.confirm("Overwrite confirm", "File(s) exist. Press 'Yes' to overwrite, 'No' to keep both");
                }}
                onDeleteConfirm={() => {
                  return modal.confirm("Confirm delete", "Are you sure to delete the file?");
                }}
                onError={(e) => {
                  modal.errorSpecific(e);
                }}
                // onOpen={(src, mimeTypeAllowed)=> {
                //   openFileViewer(src, mimeTypeAllowed && (() => {
                //     handleChooseFile.current && handleChooseFile.current(src);
                //     handleChooseFiles.current && handleChooseFiles.current([src]);
                //   }));
                // }}
              />
            </Modal.Body>
    
          </Modal>
        )
      }

    </FileManagerModalContext.Provider>
  )
}
import { faCloudArrowDown, faFileExport, faFilePdf, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "@huner2/react-tooltip";
import { useCallback, useEffect, useState } from "react";
import { PdfConfig } from "../../../api/entities/pdf-config.entity";
import { S3File } from "../../../api/entities/s3-file-system";
import { Base64ImageButton } from "../../../component/Base64ImageButton";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider";
import { UnsavedPrompt } from "../../../component/UnsavedPrompt";
import { useLoadingManager } from "../../../utils/loading-manager";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";
import { EntityData } from "../component/EntityData";
import { AdminPdfUtilPath } from "../variables";

export const AdminPdfUtil = () => {

  const {api, loading, toast} = useAdminComponent({selectedItem: AdminPdfUtilPath});
  const [backupFiles, setBackupFiles] = useState<S3File[]>(null);
  const [config, setConfig] = useState<PdfConfig>(new PdfConfig());
  const [unsaved, setUnsaved] = useState(false);
  const modal = useGlobalModal();
  const {t} = useI18N();
  const testDownloading = useLoadingManager();

  const reload = useCallback(async() => {
    const token = loading.start();
    try {
      setConfig(await api.pdfUtil.getConfig());
      setUnsaved(false);
    } catch (e) {
      modal.errorSpecific(e);
    } finally {
      loading.stop(token);
    }
  }, []);

  const testPdfGeneration = useCallback(async(lang: "tc" | "en") => {
    const token = testDownloading.start();
    try {
      const fileToken = await api.pdfUtil.test(lang);
      const url = api.pdfUtil.getPdfUrl("test.pdf", fileToken);
      console.log(url);
      window.open(url, "_blank");
    } catch (e) {
      modal.errorSpecific(e);
    }
    testDownloading.stop(token);


  }, [modal])

  useEffect(() => {
    reload();
  }, [])

  return (
    <AdminComponent.Container>
      <UnsavedPrompt flag={unsaved} />

      <AdminHeaderTitle>{t('admin.pdfUtil.title')}</AdminHeaderTitle>
      <AdminComponent.TitleBar>
        <AdminComponentTitle faIcon={faFilePdf}>
          {t('admin.pdfUtil.pageTitle')}
        </AdminComponentTitle>
      </AdminComponent.TitleBar>
      <div className="hr" />
      <EntityData
        object={config}
        loading={loading.flag}
        unsaved={unsaved}
        timestamp={config?.timestamp}
        onChange={config => {
          setConfig(config);
          setUnsaved(true);
        }}
        onSave={() => toast.save(async() => {
          const token = loading.start();
          try {
            const updatedConfig = await api.pdfUtil.updateConfig(config);
            setConfig(updatedConfig);
            setUnsaved(false);
          } catch (e) {
            modal.errorSpecific(e);
            throw e;
          } finally {
            loading.stop(token);
          }
        })}
        meta={{
          "logo": {
            type: "valueComponent",
            title: t('admin.pdfUtil.logo'),
            component: (
              <div>
                {
                  config?.logoBase64 && config?.logoBase64 != "" && (
                    <img src={config.logoBase64} 
                      style={{maxHeight: "200px", maxWidth: "320px"}}
                      className="mb-2"
                    />
                  )
                }
                <Base64ImageButton 
                  title={t('commons.uploadImage')}
                  onRead={base64Data => {
                    setConfig(config =>( {...config, logoBase64: base64Data}));
                    setUnsaved(true);
                  }}
                />
              </div>
            )
          },
          "headerCompanyInfoEn": {
            type: "text-area",
            title: t('admin.pdfUtil.headerCompanyInfoEn'),
            divWidth: {xs: 12, lg: 6}
          },
          "headerCompanyInfoTc": {
            type: "text-area",
            title: t('admin.pdfUtil.headerCompanyInfoTc'),
            divWidth: {xs: 12, lg: 6}
          },
          "contactInfoEn": {
            type: "text-area",
            title: t('admin.pdfUtil.contactInfoEn'),
            divWidth: {xs: 12, lg: 6}
          },
          "contactInfoTc": {
            type: "text-area",
            title: t('admin.pdfUtil.contactInfoTc'),
            divWidth: {xs: 12, lg: 6}
          },
          "footerDisclaimerEn": {
            type: "text-area",
            title: t('admin.pdfUtil.footerDisclaimerEn'),
            divWidth: {xs: 12, lg: 6}
          },
          "footerDisclaimerTc": {
            type: "text-area",
            title: t('admin.pdfUtil.footerDisclaimerTc'),
            divWidth: {xs: 12, lg: 6}
          },
          "test": {
            type: "entireComponent",
            component: (
              <div className="d-flex align-items-center" style={{gap: "0.3rem"}}>
                <ButtonWithLoader
                  loading={testDownloading.flag || loading.flag}
                  faIcon={faFileExport}
                  variant="success"
                  onClick={() => testPdfGeneration("tc")}
                  >{t('admin.pdfUtil.testTc')}
                </ButtonWithLoader>
                <ButtonWithLoader
                  loading={testDownloading.flag || loading.flag}
                  faIcon={faFileExport}
                  variant="success"
                  onClick={() => testPdfGeneration("en")}
                  >{t('admin.pdfUtil.testEn')}
                </ButtonWithLoader>
                
              </div>
            )
          },
          "testWarning": {
            type: "entireComponent",
            hidden: !unsaved,
            component: (
              <div className="text-black-50 fst-italic">
                <FontAwesomeIcon 
                  icon={faTriangleExclamation} 
                  className="me-2"
                  style={{cursor: "pointer"}}
                  size="lg"
                />
                {t('admin.pdfUtil.testHint')}
              </div>
            )
          },
          "hr": {
            type: "hr",
          }
        }}
      />
    </AdminComponent.Container>
  )

}

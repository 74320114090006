import { faWhatsappSquare } from "@fortawesome/free-brands-svg-icons";
import { faSquareEnvelope, faSquarePhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { ComponentPropsWithRef, useEffect, useMemo } from "react"
import { validateEmail, validatePhoneNumber } from "../../../utils/validate";


export const ContactShortcut = (props: {
  contact: string
} & ComponentPropsWithRef<"div">) => {
  let {contact, ...divProps} = props;
  contact = contact.replace(/[\*\~\s]/g, "")
  const value: {
    type: "email" | "phoneNumber",
    value: string
  } = useMemo(() => {
    const email = validateEmail(contact);
    if (email) {
      return {
        type: "email",
        value: email
      }
    } else {
      const phoneNumber = validatePhoneNumber(contact);
      if (phoneNumber) {
        return {
          type: "phoneNumber",
          value: phoneNumber,
        }
      }
      
    } 
    return null;
  }, [contact])

  return (
    value && (
      <div {...divProps} className={clsx("d-flex", divProps.className)} onClick={event => event.stopPropagation()}
        style={{gap: "0.6rem", fontSize: "2rem", lineHeight: "2rem", ...divProps.style}}
      >
      {
        value.type == "email" ? (
          <a href={"mailto:" + value.value} target="_blank" className="text-info">
            <FontAwesomeIcon icon={faSquareEnvelope} />
          </a>
        ) : (value.type == "phoneNumber" && (
          <>
            <a href={"tel:" + value.value} target="_blank" className="text-info">
              <FontAwesomeIcon icon={faSquarePhone} />
            </a>
            <a href={"https://wa.me/" + value.value} target="_blank" className="text-success">
              <FontAwesomeIcon icon={faWhatsappSquare} />
            </a>
          </>
        ))
      }
      </div>
    )
  )
}
import { faBuilding, faSave, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faPlus, faArrowsRotate, faBan, faBuildingUser, faCheck, faPersonDigging, faMountainSun, faMountainCity } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { useState, useTransition, useMemo, useCallback, useEffect, useId } from "react";
import { Badge, Spinner } from "react-bootstrap";
import { FindAllQuery } from "../../../api/entities/findAllQuery";
import { Paginated } from "../../../api/entities/pagination";
import { PropertyView } from "../../../api/entities/property/property-view.entity";
import { AutocompleteSearch } from "../../../component/AutocompleteSearch";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider";
import { UnsavedPrompt } from "../../../component/UnsavedPrompt";
import { useAbortable, useAbortableLoading } from "../../../utils/abortable-loading";
import { useScrollToId } from "../../../utils/scroll-to-id";
import { AdminComponent, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";
import { DataList, DataListItem } from "../component/DataList";
import { EntitySelector, EntitySelectorParentProps } from "../component/EntitySelector";
import { EntityTimestamp } from "../component/EntityTimestamp";
import { useFindAllQuery } from "../component/FindAllQuery";
import { GenericInput } from "../component/GenericInput";
import { AdminPagination } from "../component/Pagination";

export const AdminPropertyViewsComponent = (props: {
  onSelect?: (propertyView: PropertyView) => void,
  selectedPropertyView?: PropertyView;
}) => {
    const {api, setSelectedItem, loading, toast} = useAdminComponent();
    const modal = useGlobalModal();
    const [isInit, setIsInit] = useState(false);
    const [propertyViews, setPropertyViews] = useState<PropertyView[]>(null);
    const [pendingInputFocus, setPendingInputFocus] = useState(false);
    const [propertyViewEditing, setPropertyViewEditing] = useState<(PropertyView & {
      isNew: boolean,
      newIdEdited: boolean, // Only for new, initially false, true if id is edited
      index: number | null, // null for create
      unsaved: boolean,
    }) | null>(null);

    // console.log(PropertyViewSort);

    const {findAllQuery, renderPaginationUI, renderSearchInput, setSearchInput, scrollTopRef, setSortByDropdownOpen} 
      = useFindAllQuery({
      isInit, loading: loading.flag, disabled: !!propertyViewEditing || !isInit
    })
  
    const reloadAbortable = useAbortable();
    const openCCT2SAbortableLoading = useAbortableLoading();
    
    const {scrollToId, idPrefix} = useScrollToId();
  
    const {t} = useI18N();
  
    const unsaved = useMemo(() => {
      return !!propertyViewEditing?.unsaved;
    }, [propertyViewEditing?.unsaved])
  
    const unsaveProceed = useCallback(async() => {
      return !unsaved || unsaved && await modal.confirmUnsaved();
    }, [unsaved])
  
    const reload = useCallback(async() => {
      const token = loading.start();
      const {signal} = reloadAbortable.create();
      try {
        setPropertyViews(await api.property.findAllViews(findAllQuery, {signal}));
        // TODO
        scrollToId(null);
      } catch (e) {
        modal.errorSpecific(e);
      } finally {
        loading.stop(token);
      }
    }, [findAllQuery])
  
    useEffect(() => {
      if (isInit) {
        // TODO: Handle unsaved prompt from external use
        reload();
      }
    }, [findAllQuery, isInit]);

    useEffect(() => {
      reload().then(() => setIsInit(true));
    }, []);
  
    return (
      <>
        <UnsavedPrompt flag={unsaved} />
        <div className="d-flex align-items-center mb-3" style={{gap: "0.5em", flexWrap: "wrap"}}>
  
          <ButtonWithLoader
            faIcon={faPlus}
            variant="primary"
            loading={loading.check()}
            onClick={async(event) => {
              event.stopPropagation();
              if (await unsaveProceed()) {
                setPropertyViewEditing({
                  isNew: true,
                  newIdEdited: false,
                  index: null,
                  nameEn: "",
                  nameTc: "",
                  nameSc: "",
                  unsaved: false,
                })
                scrollToId(null);
  
                setPendingInputFocus(true);
              }
  
            }}
          />
  
          <ButtonWithLoader
            faIcon={faArrowsRotate}
            variant="secondary"
            loading={loading.check()}
            onClick={async() => {
              if (await unsaveProceed()) {
                setPropertyViewEditing(null);
                await reload();
              }
            }}
          />

          {renderPaginationUI({className: "ms-auto"})}

        </div>
  
        <div>
          {/* <AutocompleteSearch
            loading={searchPending}
            placeholder={t("admin.propertyViews.search")}
            hideDropdown
            input={searchInput}
            onChange={(value: string) => {
              setSearchInput(value);
              startSearchTransition(() => {
                // setPropertyViewsFiltered(searchPropertyViews(value, propertyViews))
              })
            }}
          /> */}
          {/* <SearchInput /> */}
          {renderSearchInput({
            placeholder: t("admin.property.basicInfo.searchView")
          })}
        </div>
        <DataList divRef={scrollTopRef} className="scrollable" onClick={() => setSortByDropdownOpen(false)}>
          {
            propertyViews && (
              propertyViews.length == 0 && !propertyViewEditing ? (
                <DataList.Empty>{t('commons.noResult')}</DataList.Empty>
              ) : (
                <>{
                  [...propertyViews, propertyViewEditing].map((propertyView: PropertyView, index) => {
                    if (propertyView == null || (propertyView as typeof propertyViewEditing).isNew === false) {
                      return;
                    }
    
                    const isNew = (propertyView as typeof propertyViewEditing)?.isNew;
                    const editing = isNew || propertyViewEditing?.index === index; 
                    const isSelected = props.onSelect && props.selectedPropertyView?.nameEn == propertyView?.nameEn;
                    
                    return (
                      <DataListItem key={index} className={clsx(editing && "active")}>
                        <a id={idPrefix + propertyView.nameEn} className="anchor" />
                        <DataListItem.Title>
                          <Badge bg="info" className="data-key">EN</Badge>
                          {!editing ? <b>{propertyView.nameEn}</b> :
                          <GenericInput 
                            inputRef={pendingInputFocus ? (element) => {
                              element?.focus();
                              setPendingInputFocus(false);
                            } : undefined}
                            type="text"
                            value={propertyViewEditing.nameEn} 
                            loading={loading.check()}
                            onChange={(nameEn: string) => {
                              setPropertyViewEditing({...propertyViewEditing, nameEn, newIdEdited: nameEn != "", unsaved: true})
                              
                            }} 
                          />}
                          {
                            !editing && (
                              <DataListItem.TitleBtns>
                                <DataListItem.EditButton
                                  className={clsx(editing && "active")}
                                  onClick={async() => {
                                    // editPropertyView(index, {
                                    //   ...propertyView,
                                    // }, undefined, true)
                                    
                                    if (await unsaveProceed()) {
                                      setPropertyViewEditing({
                                        isNew: false,
                                        newIdEdited: true,
                                        index, 
                                        unsaved: false,
                                        ...propertyView
                                      });
                                      
                                      setPendingInputFocus(true);
                                      scrollToId(propertyView.nameEn);
                                    }
                                  }}
                                />
                              </DataListItem.TitleBtns>
                            )
                          }
                        </DataListItem.Title>
                        <DataListItem.Body>
                          <div><Badge bg="info" className="data-key">繁</Badge>{
                            !editing ? <>{propertyView.nameTc}</> :
                            <GenericInput 
                              type="text" 
                              value={propertyViewEditing.nameTc} 
                              loading={loading.check()}
                              onChange={async(nameTc) => {
                                setPropertyViewEditing(propertyViewEditing => ({...propertyViewEditing, nameTc, unsaved: true}))
                                const {token, signal} = openCCT2SAbortableLoading.start();
                                try {
                                  const text = await api.openCC.convertHK2S({text: nameTc}, {signal});
                                  setPropertyViewEditing(propertyViewEditing => ({...propertyViewEditing, nameSc: text, unsaved: true}))
                                } catch (e) {
                                  modal.errorSpecific(e);
                                } finally {
                                  openCCT2SAbortableLoading.stop(token);
                                }
    
                              }} 
                            />
                          }</div>
                          <div><Badge bg="info" className="data-key">简</Badge>{
                            !editing ? propertyView.nameSc :
                            <>
                              
                              <GenericInput 
                                type="text" 
                                value={propertyViewEditing.nameSc} 
                                loading={loading.check()}
                                onChange={nameSc => {
                                  openCCT2SAbortableLoading.abort(); 
                                  setPropertyViewEditing({...propertyViewEditing, nameSc, unsaved: true});
                                }} 
                              />
                              {
                                openCCT2SAbortableLoading.flag && (
                                  <div>
                                    <Spinner animation="border" size="sm" className="ms-2" />
                                  </div>
                                )
                              }
                            </>
                            
                          }</div>
                          {/* <div><Badge bg="info" className="data-key">EN</Badge>{
                            !editing ? <>{propertyView.nameEn}</> :
                            <GenericInput 
                              type="text" 
                              value={propertyViewEditing.nameEn} 
                              loading={loading.check()}
                              onChange={nameEn => {
                                const newObj = JSON.parse(JSON.stringify({...propertyViewEditing, nameEn, unsaved: true}));
                                if (!propertyViewEditing.newIdEdited) {
                                  // E.g., "Peter Au Yeung" => "PAY"
                                  newObj.id = nameEn.match(/\b(\w)/g)?.join('')?.toUpperCase() ?? "";
                                }
                                setPropertyViewEditing(newObj)
                              }} 
                            />
                          }</div> */}
                          {
                            editing && (
                              <div className="btns">
                                <ButtonWithLoader
                                  variant="success"
                                  faIcon={faSave}
                                  loading={loading.check()}
                                  onClick={() => toast.save(async() => {
                                    const token = loading.start();
                                    openCCT2SAbortableLoading.abort();
                                    let propertyViewUpdated: PropertyView;
                                    try {
                                      if (isNew) {
                                        propertyViewUpdated = await api.property.createView(propertyViewEditing);
                                      } else {
                                        propertyViewUpdated = await api.property.updateView(propertyView.nameEn, propertyViewEditing);
                                      }
                                      
                                      // setSearchInput(propertyViewUpdated.nameEn);
                                      setPropertyViewEditing(null);
                                      await reload();
                                      setTimeout(() => {
                                        // Delay to make sure reload() has update unsaved status
                                        scrollToId(propertyViewUpdated.nameEn);
                                      }, 100)
    
                                    } catch (e) {
                                      modal.errorSpecific(e);
                                      throw e;
                                    } finally {
                                      loading.stop(token);
                                    }
                                  }, "propertyView-save")}
                                >{t('entityData.save')}</ButtonWithLoader>
                                {
                                  !isNew && (
                                    <ButtonWithLoader
                                      variant="danger"
                                      faIcon={faTrashCan}
                                      loading={loading.check()}
                                      onClick={async() => {
                                        if (await modal.confirmDelete(true)) {
                                          toast.delete(async() => {
                                            const token = loading.start();
                                            try {
                                              await api.property.deleteView(propertyView.nameEn);
                                              await reload();
                                              setPropertyViewEditing(null);
                                              scrollToId(null);
                                              
                                            } catch (e) {
                                              modal.errorSpecific(e);
                                              throw e;
                                            } finally {
                                              loading.stop(token);
                                            }
                                          }, "propertyView-delete")
                                        }
                                      }}
                                    >{t('entityData.delete')}</ButtonWithLoader>
    
                                  )
                                }
                                
                                <ButtonWithLoader
                                  variant="info"
                                  faIcon={faBan}
                                  loading={loading.check()}
                                  onClick={async() => {
                                    if (await unsaveProceed()) {
                                      setPropertyViewEditing(null);
                                    }
                                  }}
                                >{t('entityData.cancel')}</ButtonWithLoader>
                              </div>
                              
                            )
                          }
                          {
                            !editing && props.onSelect && (
                              <ButtonWithLoader
                                size="sm"
                                variant="success"
                                onClick={() => {
                                  props.onSelect(propertyView);
                                }}
                                disabled={isSelected}
                                faIcon={isSelected && faCheck}
                              >
                                {t(`admin.commons.${isSelected ? "selected" : "select"}`)}
                              </ButtonWithLoader>
                            )
                          }
                        </DataListItem.Body>
                      </DataListItem>
                    )
                  })
                }
                
                {renderPaginationUI({className: "my-4"})}
                </>
              )
            )
          }
          {/* <AdminTable 
            className="propertyViews-table"
            keys={{
              id: {
                content: t('admin.propertyViews.id'),
              },
              nameEn: {
                content: t('admin.propertyViews.nameEn'),
              },
              nameTc: {
                content: t('admin.propertyViews.nameTc'),
              },
              nameSc: {
                content: t('admin.propertyViews.nameSc'),
              },
              reOrder: {
                content: "",
                shrink: true,
              }, 
              timestamp: {
                content: "",
                shrink: true,
              },
              delete: {
                content: "",
                shrink: true,
              },
            }} 
            rows={
              propertyViews && propertyViews.map((propertyView, index) => {
                if (propertyView.mode == "deleted") {return null}
                const editing = propertyView.mode == "editing";
  
                return {
                  onClick: (event) => {
                    event.stopPropagation();
                    // Set all editing propertyViews as uneditted
                    setPropertyViews(propertyViews => propertyViews?.map(propertyView => {
                      if (propertyView.mode == "editing") {
                        propertyView.mode = "edited";
                      }
                      return propertyView;
                    }))
                    editPropertyView(index, {
                      ...propertyView,
                      mode: "editing",
                    })
                  },
                  content: {
                    id: !editing ? propertyView.id :
                      <GenericInput type="text" value={propertyView.id} onChange={(id: string) => editPropertyView(index, {...propertyView, id: id.toUpperCase()}, true)} />,
                    nameEn: !editing ? propertyView.nameEn :
                      <GenericInput type="text" value={propertyView.nameEn} onChange={nameEn => editPropertyView(index, {...propertyView, nameEn}, true)} />,
                    nameTc: !editing ? propertyView.nameTc :
                      <GenericInput type="text" value={propertyView.nameTc} onChange={nameTc => editPropertyView(index, {...propertyView, nameTc}, true)} />,
                    nameSc: !editing ? propertyView.nameSc :
                      <GenericInput type="text" value={propertyView.nameSc} onChange={nameSc => editPropertyView(index, {...propertyView, nameSc}, true)} />,
                    reOrder: (
                      <div className="d-flex">
                        <button className="btn btn-outline-info btn-sm me-1" disabled={index == 0}
                          onClick={(event) => {
                            event.stopPropagation();
                            let propertyViewsCloned = [...propertyViews];
                            [propertyViewsCloned[index-1], propertyViewsCloned[index]] = [propertyViewsCloned[index], propertyViewsCloned[index-1]];
                            setPropertyViews(propertyViewsCloned);
                            setUnsavedOrders(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowUp} />
                        </button>
                        
                        <button className="btn btn-outline-info btn-sm" disabled={index == propertyViews.length - 1}
                          onClick={(event) => {
                            event.stopPropagation();
                            let propertyViewsCloned = [...propertyViews];
                            [propertyViewsCloned[index], propertyViewsCloned[index+1]] = [propertyViewsCloned[index+1], propertyViewsCloned[index]];
                            setPropertyViews(propertyViewsCloned);
                            setUnsavedOrders(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowDown} />
                        </button>
                      </div>
                    ),
                    timestamp: propertyView.timestamp && <EntityTimestamp created={propertyView.timestamp.created} updated={propertyView.timestamp.updated} />,
                    delete: (
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={async(event) => {
                          event.stopPropagation();
                          if (await modal.confirmDelete()) {
                            editPropertyView(index, {...propertyView, mode: "deleted"}, true)
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                    ),
                  }
                }
              })
            }
          /> */}
        </DataList>
      </>
    )
  }

// export const AdminPropertyViews = () => {
//   const {t} = useI18N();


//   return (
//     <AdminComponent.Container 
//       onClick={() => {

//       }}
//     >
//       <AdminHeaderTitle>{t('admin.propertyViews.title')}</AdminHeaderTitle>
//       <AdminComponent.TitleBar>
//         <AdminComponent.Title faIcon={faPersonDigging}>
//           {t('admin.propertyViews.pageTitle')}
//         </AdminComponent.Title>
//       </AdminComponent.TitleBar>

      
//       <div className="hr" />
      
//       <AdminPropertyViewsComponent />
//     </AdminComponent.Container>
//   )
  
// }


export const AdminPropertyViewPreview = (props: {
  propertyView: PropertyView
  onClear?: () => void;
  disabled?: boolean;
}) => {
  const {propertyView} = props;

  return propertyView && (
    <div className="preview">
      <div><Badge bg="info" >EN</Badge> {propertyView.nameEn}</div>
      <div><Badge bg="info" >繁</Badge> {propertyView.nameTc}</div>
      <div><Badge bg="info" >简</Badge> {propertyView.nameSc}</div>
      <ButtonWithLoader 
        faIcon={faTrashCan}
        variant="danger"
        size="sm"
        onClick={() => {
          props.onClear?.()
        }}
        disabled={props.disabled}
      />
    </div>
  )

}
export const AdminPropertyViewSelector = (props: EntitySelectorParentProps<PropertyView>) => {
  const {t} = useI18N();
  const [modalOpen, setModalOpen] = useState(false);
  const {item: propertyView} = props;

  return (
    <EntitySelector
      {...props}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      preview={<AdminPropertyViewPreview disabled={props.disabled} propertyView={propertyView} onClear={() => props.onChange?.(null)}/>}
      icon={faMountainCity}
      title={t('admin.property.basicInfo.chooseView')}
      hideButton={!!propertyView}
      modalContent={<AdminPropertyViewsComponent
        selectedPropertyView={propertyView}
        onSelect={(propertyView) => {
          setModalOpen(false);
          props.onChange?.(propertyView);
        }} 
      />}
    />
  )
}
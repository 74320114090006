

// export const AdminPath = "";
export const AdminLoginPath = "login";
export const AdminLogoutPath = "logout";
export const AdminDashboardPath = "dashboard";
export const AdminUsersPath = "users";
export const AdminUserPath = "user";
export const AdminSystemConfigPath = "config";
export const AdminSystemFileManagerPath = "files";
export const AdminI18NPath = "i18n";
export const AdminDistrictsPath = "districts";
export const AdminDevelopersPath = "developers";
export const AdminDeveloperPath = "developer";
export const AdminBuildingPath = "building";
export const AdminPropertyPublishmentPath = "property/publish";
export const AdminPropertyPath = "property";
export const AdminPropertyListingPath = "property-listing";
export const AdminBackupsPath = "backup";
export const AdminPdfUtilPath = "pdf-util";
export const AdminQRCodeUtilPath = "qr-code-util";
export const AdminPropertyPublishmentTagsPath = "property-tags";
import { ComponentProps } from "react"


export const InputHiddenRequired = (props: ComponentProps<"input">) => {
  const {style} = props;

  return (
    <input className="form-control" type="text" value="" required 
      onChange={() => {}}
      {...props}
      style={{
        height: "100%",
        width: "100%",
        position: "absolute",
        opacity: 0,
        pointerEvents: "none",
        ...style
        
      }}
    />
  )
}
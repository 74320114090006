import { faArrowRotateRight, faCheckCircle, faCopy, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ErrorInfo } from "react";
import { Button, Container } from "react-bootstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';

export class ErrorBoundary extends React.Component<React.PropsWithChildren<{}>, {
  error: Error | null,
  copied: boolean,
}> {
  constructor(props: {}) {
    super(props);
    this.state = { error: null, copied: false};
  }

  // static getDerivedStateFromError(error) {
  //   // Update state so the next render will show the fallback UI.
  //   return { error };
  // }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    // console.log(error);
    console.log(error)
    this.setState({error})
    // console.log(errorInfo.componentStack)
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <Container fluid className="d-flex flex-column py-4 px-4 h-100">
          <h3 className="text-danger"><FontAwesomeIcon fixedWidth icon={faTriangleExclamation} className="me-2"/>Fatal error occurs</h3>
          <hr />
          <p>Please report the error logs to developer to fix the issue: </p>
          <Button variant="danger" size="sm" className="mb-2"
            onClick={() => window.location.reload()}
          >
            <FontAwesomeIcon fixedWidth icon={faArrowRotateRight} className="me-2" />
            Refresh Page
          </Button>
          
          <CopyToClipboard text={this.state.error.stack}
            onCopy={() => this.setState({copied: true})}>
            <Button variant={this.state.copied ? "success" : "info"} size="sm" className="mb-2">
              <FontAwesomeIcon fixedWidth icon={this.state.copied ? faCheckCircle : faCopy} className="me-2" />
              Copy to Clipboard
            </Button>
          </CopyToClipboard>
          
          <textarea className="flex-grow-1 h-0 mb-2 font-monospace small" defaultValue={this.state.error.stack} ></textarea>
        </Container>
      )
    }

    return this.props.children; 
  }
}
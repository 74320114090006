import { AxiosInstance, AxiosRequestConfig } from "axios";
import { FindAllQuery } from "../entities/findAllQuery";
import { I18N, I18NDto } from "../entities/i18n.entity";
import { Paginated } from "../entities/pagination";
import { PropertyBasicInfo } from "../entities/property/property-basic-info.entity";
import { PropertyDecoration, PropertyDecorationDto } from "../entities/property/property-decoration.entity";
import { PropertyDetail } from "../entities/property/property-detail.entity";
import { PropertyLandlord } from "../entities/property/property-landlord.entity";
import { PropertyPriceStatus } from "../entities/property/property-price-status.entity";
import { PropertyView, PropertyViewDto } from "../entities/property/property-view.entity";
import { Property, PropertyByDistrictCount, PropertyDto, PropertyEditHistory, PropertyEditHistoryFindAllQuery, PropertyFindAllQuery, PropertyGeneratePdfEvent, PropertyListPdfContent, PropertyPdfContent, PropertyWithRawCount } from "../entities/property/property.entity";
import { APIBase } from "./apiBase";
import { APIEncodeQueryString } from "./helpers";
import { io } from "socket.io-client";
import { v4 as uuid } from 'uuid';
import { HighComputationResult } from "../entities/high-computation-result";
import { PropertySearchHistory, PropertySearchHistoryDto } from "../entities/property/property-search-history.entity";
import { PropertyPublishmentDto, PropertyPublishmentState } from "../entities/property/property-publishment.entity";
import { S3File } from "../entities/s3-file-system";
import { PropertyPublishmentTag } from "../entities/property/property-publishment-tag.entity";
import { PropertyReadHistoryUserDto, PropertyReadHistoryUserResults } from "../entities/property/property-read-history.entity";
import { User } from "../entities/user.entity";
import { PropertyForm } from "../entities/property/property-form";

export default class PropertyAPI extends APIBase {
  

  constructor(axios: AxiosInstance) {
    super(axios, "property");
  }

  // Views
  async findAllViews(query?: FindAllQuery, config?: AxiosRequestConfig): Promise<PropertyView[]> {
    return (await this.axios.get<PropertyView[]>(
      this.base + "/view?" + (query ? APIEncodeQueryString(FindAllQuery.toJson(query)) : ""), config
    )).data;
  }

  async createView(dto: PropertyViewDto): Promise<PropertyView> {
    return (await this.axios.post<PropertyView>(
      this.base + "/view/",
      dto
    )).data;
  }

  async updateView(nameEn: PropertyView["nameEn"], dto: PropertyViewDto): Promise<PropertyView> {
    return (await this.axios.put<PropertyView>(
      this.base + "/view/" + nameEn,
      dto
    )).data;
  }

  async deleteView(nameEn: PropertyView["nameEn"]): Promise<void> {
    return (await this.axios.delete<void>(
      this.base + "/view/" + nameEn
    )).data;
  }

  // Decoration
  async findAllDecorations(query?: FindAllQuery, config?: AxiosRequestConfig): Promise<PropertyDecoration[]> {
    return (await this.axios.get<PropertyDecoration[]>(
      this.base + "/decoration?" + (query ? APIEncodeQueryString(FindAllQuery.toJson(query)) : ""), config
    )).data;
  }

  async createDecoration(dto: PropertyDecorationDto): Promise<PropertyDecoration> {
    return (await this.axios.post<PropertyDecoration>(
      this.base + "/decoration/",
      dto
    )).data;
  }

  async updateDecoration(nameEn: PropertyDecoration["nameEn"], dto: PropertyDecorationDto): Promise<PropertyDecoration> {
    return (await this.axios.put<PropertyDecoration>(
      this.base + "/decoration/" + nameEn,
      dto
    )).data;
  }

  async deleteDecoration(nameEn: PropertyDecoration["nameEn"]): Promise<void> {
    return (await this.axios.delete<void>(
      this.base + "/decoration/" + nameEn
    )).data;
  }

  // Tags
  async findAllTags(query?: FindAllQuery, config?: AxiosRequestConfig): Promise<Paginated<PropertyPublishmentTag>> {
    return (await this.axios.get<Paginated<PropertyPublishmentTag>>(
      this.base + "/tag?" + (query ? APIEncodeQueryString(FindAllQuery.toJson(query)) : ""), config
    )).data;
  }

  async createTag(dto: PropertyPublishmentTag): Promise<PropertyPublishmentTag> {
    return (await this.axios.post<PropertyPublishmentTag>(
      this.base + "/tag/",
      dto
    )).data;
  }

  async updateTag(nameEn: PropertyPublishmentTag["nameEn"], dto: PropertyPublishmentTag): Promise<PropertyPublishmentTag> {
    return (await this.axios.put<PropertyPublishmentTag>(
      this.base + "/tag/" + nameEn,
      dto
    )).data;
  }

  async deleteTag(nameEn: PropertyPublishmentTag["nameEn"]): Promise<void> {
    return (await this.axios.delete<void>(
      this.base + "/tag/" + nameEn
    )).data;
  }

  // Publishment
  async upsertPublishment(propertyId: Property["id"], dto: PropertyPublishmentDto): Promise<void> {
    return (await this.axios.post<void>(
      this.base + "/publishment/" + propertyId, dto
    )).data;
  }

  async uploadImage(
    id: number,
    file: File,
    onUploadProgress?: (e: ProgressEvent) => void,
    config?: AxiosRequestConfig
  ) {
    let formData = new FormData();
    formData.append("file", file);
    return (await this.axios.post<any>(
      this.base + "/" + id + "/upload-image",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: onUploadProgress as any,
        ...config,
      }
    )).data;
  }

  async listImages(id: number, config?: AxiosRequestConfig) {
    return (await this.axios.get<S3File[]>(
      this.base + "/" + id + "/images", config
    )).data;
  }

  async downloadImage(id: number, filename: string, config?: AxiosRequestConfig) {
    return (await this.axios.get<any>(
      this.base + "/" + id + "/image/" + filename, config
    )).data;
  }
  
  async deleteImage(id: number, filename: string, config?: AxiosRequestConfig) {
    return (await this.axios.delete<void>(
      this.base + "/" + id + "/image/" + filename, config
    )).data;
  }

  // Search Histories

  async findAllSearchHistories(config?: AxiosRequestConfig): Promise<PropertySearchHistory[]> {
    return (await this.axios.get<PropertySearchHistory[]>(
      this.base + "/search-history", config
    )).data;
  }

  async saveSearchHistory(dto: PropertySearchHistoryDto, config?: AxiosRequestConfig): Promise<PropertySearchHistory> {
    return (await this.axios.post<PropertySearchHistory>(
      this.base + "/search-history", dto, config
    )).data;
  }

  async deleteAllSearchHistories(config?: AxiosRequestConfig): Promise<void> {
    await this.axios.delete<void>(
      this.base + "/search-history", config
    );
  }

  // Core
  async findAll(query: PropertyFindAllQuery, config?: AxiosRequestConfig): Promise<HighComputationResult<Paginated<Property>>> {
    return (await this.axios.get<HighComputationResult<Paginated<Property>>>(
      this.base + "?" + APIEncodeQueryString({...FindAllQuery.toJson(query)}), config
    )).data;
  }

  async findAllPublic(query: PropertyFindAllQuery, config?: AxiosRequestConfig): Promise<HighComputationResult<Paginated<PropertyWithRawCount>>> {
    return (await this.axios.get<HighComputationResult<Paginated<PropertyWithRawCount>>>(
      this.base + "/public?" + APIEncodeQueryString({...FindAllQuery.toJson(query)}), config
    )).data;
  }

  async findAllEditHistory(query: PropertyEditHistoryFindAllQuery, config?: AxiosRequestConfig): Promise<HighComputationResult<Paginated<PropertyEditHistory>>> {
    return (await this.axios.get<HighComputationResult<Paginated<PropertyEditHistory>>>(
      this.base + "/edit-history?" + APIEncodeQueryString({...FindAllQuery.toJson(query)}), config
    )).data;
  }

  async evaluateReadHistoryUser(dto: PropertyReadHistoryUserDto, config?: AxiosRequestConfig): Promise<PropertyReadHistoryUserResults> {
    return (await this.axios.post<PropertyReadHistoryUserResults>(
      this.base + "/evaluate-read-history", dto, config
    )).data;
  }

  async getDefaultContactUser(): Promise<User | null> {
    return (await this.axios.get<User | null>(
      this.base + "/default-contact-user"
    )).data;
  }

  async editDefaultContactUser(userId: User["id"]): Promise<User | null> {
    return (await this.axios.put<User | null>(
      this.base + "/default-contact-user/" + userId
    )).data;
  }


  async findById(id: Property["id"], config?: AxiosRequestConfig): Promise<Property> {
    return (await this.axios.get<Property>(
      this.base + "/" + id, config
    )).data;
  }

  async findByIdPublic(id: Property["id"], config?: AxiosRequestConfig): Promise<Property> {
    return (await this.axios.get<Property>(
      this.base + "/public/" + id, config
    )).data;
  }

  async countByDistrictPublic(config?: AxiosRequestConfig): Promise<PropertyByDistrictCount[]> {
    return (await this.axios.get<PropertyByDistrictCount[]>(
      this.base + "/public/count-by-district", config
    )).data;
  }
  async findBasicInfoHistoryById(id: Property["id"], config?: AxiosRequestConfig): Promise<PropertyBasicInfo[]> {
    return (await this.axios.get<PropertyBasicInfo[]>(
      this.base + "/" + id + "/basic-info-history", config
    )).data;
  }
  
  async findPriceStatusHistoryById(id: Property["id"], config?: AxiosRequestConfig): Promise<PropertyPriceStatus[]> {
    return (await this.axios.get<PropertyPriceStatus[]>(
      this.base + "/" + id + "/price-status-history", config
    )).data;
  }
  
  async findLandlordHistoryById(id: Property["id"], config?: AxiosRequestConfig): Promise<PropertyLandlord[]> {
    return (await this.axios.get<PropertyLandlord[]>(
      this.base + "/" + id + "/landlord-history", config
    )).data;
  }
  
  async findDetailHistoryById(id: Property["id"], config?: AxiosRequestConfig): Promise<PropertyDetail[]> {
    return (await this.axios.get<PropertyDetail[]>(
      this.base + "/" + id + "/detail-history", config
    )).data;
  }

  async update(id: number, dto: PropertyDto): Promise<Property> {
    return (await this.axios.put<Property>(
      this.base + "/" + id,
      dto
    )).data;
  }

  async updateStar(id: number, starred: boolean): Promise<Property> {
    return (await this.axios.put<Property>(
      this.base + "/star/" + id + "/" + starred,
    )).data;
  }

  async updatePublishmentState(id: number, state: PropertyPublishmentState): Promise<Property> {
    return (await this.axios.put<Property>(
      this.base + "/publishment-state/" + id + "/" + state,
    )).data;
  }

  async create(dto: PropertyDto): Promise<Property> {
    return (await this.axios.post<Property>(
      this.base,
      dto
    )).data;
  }

  async delete(id: Property["id"]): Promise<void> {
    return (await this.axios.delete<void>(
      this.base + "/" + id
    )).data;
  }

  async generatePdf(content: PropertyPdfContent, eventHandler?: (event: PropertyGeneratePdfEvent) => void, config?: AxiosRequestConfig): Promise<string> {
    
    
    const socketUrl = this.axios.getUri({url: this.base + "/pdf"});
    const wsToken = (await this.axios.post<string>(
      this.base + "/pdf-ws-token"
    )).data;
    
    eventHandler?.({isPropertyPdfEvent: true, wsToken, stage: "uploading"})

    const socket = io(socketUrl, {query: {wsToken}});
    // socket.on("connect", () => {
    //   console.log("connect");
    // })
    // socket.on("reconnect", () => {
    //   console.log("reconnect");
    // })
    socket.on(wsToken, (message: PropertyGeneratePdfEvent) => {
      if (message.isPropertyPdfEvent) {
        eventHandler?.(message);
      }
    });
    try {
      const result = (await this.axios.post<string>(
        this.base + "/pdf?ws-token=" + wsToken, content, config
      )).data;
      socket.close();
      return result;
    } catch (e) {
      socket.close();
      throw e;
    } 

  }

  async generateListPdf(content: PropertyListPdfContent, eventHandler?: (event: PropertyGeneratePdfEvent) => void, config?: AxiosRequestConfig): Promise<string> {
    
    
    const socketUrl = this.axios.getUri({url: this.base + "/pdf"});
    const wsToken = (await this.axios.post<string>(
      this.base + "/pdf-ws-token"
    )).data;
    
    eventHandler?.({isPropertyPdfEvent: true, wsToken, stage: "uploading"})

    const socket = io(socketUrl, {query: {wsToken}});
    socket.on(wsToken, (message: PropertyGeneratePdfEvent) => {
      if (message.isPropertyPdfEvent) {
        eventHandler?.(message);
      }
    });
    try {
      const result = (await this.axios.post<string>(
        this.base + "/list-pdf?ws-token=" + wsToken, content, config
      )).data;
      socket.close();
      return result;
    } catch (e) {
      socket.close();
      throw e;
    } 

  }

  
  async generatePdfWs(content: PropertyPdfContent) {
    // const url = this.axios.getUri({
    //   url: this.base + "/get/"
    // });

    // const url = this.axios.getUri({url: this.base});


    // const socket = io(url);

    // socket.emit("message", "test", (res) => {
    //   console.log(res);
    // })

    // socket.emit("generatePdf", content, (res) => {
    //   console.log(res);
    // })

    
  }

  async generateFormPdf(form: PropertyForm, config?: AxiosRequestConfig): Promise<string> {
    try {
      const result = (await this.axios.post<string>(
        this.base + "/form", form, config
      )).data;
      return result;
    } catch (e) {
      throw e;
    }
  }

}
import { Exclude, Type } from "class-transformer";
import { User } from "../user.entity";
import { HidePublic } from "../utils/hide-public";
import { Column, Entity, JoinColumn, ManyToOne, PrimaryColumn, Relation } from "../vars";
import { PropertyPublishment } from "./property-publishment.entity";


@Entity()
export class PropertyPublishmentContactUser {

  @Exclude()
  @PrimaryColumn("int")
  propertyPublishmentId?: PropertyPublishment["propertyId"];
  
  @ManyToOne(() => PropertyPublishment, p => p.contactUsers, {
    onUpdate: "CASCADE",
    onDelete: "CASCADE", 
  })
  @JoinColumn({name: "propertyPublishmentId"})
  @Type(() => PropertyPublishment)
  propertyPublishment?: PropertyPublishment;
  
  @HidePublic()
  @PrimaryColumn("int")
  userId: User["id"]
  
  @ManyToOne(() => User, {
    onUpdate: "CASCADE",
    onDelete: "CASCADE", 
  })
  @JoinColumn({name: "userId"})
  user?: User;

  @Column("tinyint", {
    default: 0
  })
  order?: number;
}
import _ from "lodash";
import { useCallback, useMemo, useState } from "react"

export const useUndoRedo = <T = any,>(initialState: T) => {

  const [editStacks, setEditStacks] = useState<T[]>([initialState]);
  const [currentStackIndex, setCurrentStackIndex] = useState(-1);

  const initializeEditStacks = useCallback((obj: T) => {
    setEditStacks([_.cloneDeep(obj)]);
    setCurrentStackIndex(0);
  }, []);

  const pushEditStacks = useCallback((stateAction: (oldObj: T) => T) => {
    console.log("pushEditStacks");
    setEditStacks(stacks => {
      const oldObj = stacks[currentStackIndex];
      // Remove any element afterwards
      const newStacks = stacks.slice(0, currentStackIndex + 1)  
      setCurrentStackIndex(newStacks.length);
      return [...newStacks, _.cloneDeep(stateAction(oldObj))]
   });
  }, [editStacks, currentStackIndex]);

  // console.log(editStacks);
  const canUndo = useMemo(() => editStacks.length > 1 && currentStackIndex > 0, [editStacks, currentStackIndex])
  const canRedo = useMemo(() => editStacks.length > 1 && currentStackIndex < editStacks.length - 1, [editStacks, currentStackIndex]);
  // console.log(editStacks);
  // console.log(editStacks.length);
  // console.log({currentStackIndex});

  const undo = useCallback(() => {
    if (!canUndo) {
      throw new Error("Can't undo");
    }
    const index = currentStackIndex - 1;
    setCurrentStackIndex(index);
    return _.cloneDeep(editStacks[index]);
  }, [canUndo, editStacks, currentStackIndex])

  const redo = useCallback(() => {
    if (!canRedo) {
      throw new Error("Can't redo");
    }
    const index = currentStackIndex + 1;
    setCurrentStackIndex(index);
    return _.cloneDeep(editStacks[index]);
  }, [canRedo, editStacks, currentStackIndex])

  return {
    initializeEditStacks, pushEditStacks,
    canUndo, canRedo,
    undo, redo
  }
  
}
import { faCircleInfo, faEnvelope, faExclamationTriangle, faFileExport, faFileImport, faFileInvoiceDollar, faFolderTree, faGears, faHouseChimney, faImage, faPaperPlane, faPenRuler, faRightToBracket, faToolbox, faVial, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { SystemConfig } from "../../../api/entities/system-config.entity";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";
import { EntityData } from "../component/EntityData";

import { faGoogle, faFacebookF, faPaypal, faAws } from "@fortawesome/free-brands-svg-icons";
import { GoogleAuthButton } from "../../../component/GoogleAuthButton";
import { ButtonGroup } from "react-bootstrap";
import { FacebookAuthButton } from "../../../component/FacebookAuthButton";
import { PayPalButton } from "../../../component/PayPalButton";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { GenericInput } from "../component/GenericInput";
import { FileHelper } from "../../../utils/file-helper";
import "../../../styles/sub-page.scss";
import clsx from "clsx";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { AdminSystemConfigPath } from "..";
import path from "path-browserify";
import { SingleImageUploader } from "../component/SingleImageUploader";
import { useGlobalModal } from "../../../component/GlobalModal";
import { SingleImageChooser } from "../component/SingleImageChooser";
import { useGlobalSystemConfig } from "../../../component/GlobalSystemConfig";
import { useI18N } from "../../../component/I18NProvider";
import { UnsavedPrompt } from "../../../component/UnsavedPrompt";

type TestFlag = "untested" | "testing" | "tested"

export interface AdminSystemConfigSection {
  icon?: IconDefinition,
  title: React.ReactNode
}
export const AdminSystemConfigSections: {[key: string]: AdminSystemConfigSection} = {
  "general": {
    title: "General",
    icon: faHouseChimney,
  },
  "file-system": {
    title: "File System",
    icon: faFolderTree,
  },
  "login": {
    title: "Login",
    icon: faRightToBracket
  },
  "system-email": {
    title: "Email",
    icon: faEnvelope
  },
}

export const AdminSystemConfig = (props: React.PropsWithChildren<{}>) => {

  const params = useParams<{id: string}>();
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useI18N();

  const {api, loading, toast, setSelectedItem} = useAdminComponent();
  const {i18ns} = useI18N();
  const {globalSystemConfig, reloadGlobalSystemConfig} = useGlobalSystemConfig();
  const modal = useGlobalModal();
  // States
  const [unsaved, setUnsaved] = useState(false);
  const [selectedSection, setSelectedSelection] = 
    useState<keyof typeof AdminSystemConfigSections | undefined>(params.id);
  const [systemConfigData, setSystemConfigData] = useState<Omit<SystemConfig, "id"> | null>(null);
  const [systemEmailTestReceipient, setSystemEmailTestReceipient] = useState("");
  const [emailTestFlag, setEmailTestFlag] = useState<TestFlag>("untested");
  const [googleAPITestFlag, setGoogleAPITestFlag] = useState<TestFlag>("untested");
  const [facebookAPITestFlag, setFacebookAPITestFlag] = useState<TestFlag>("untested");
  const [paypalAPITestFlag, setPaypalAPITestFlag] = useState<TestFlag>("untested");
  const [paypalAPITestAmount, setPaypalAPITestAmount] = useState<number | null>(null);
  const [paypalAPITestInvoiceId, setPaypalAPITestInvoiceId] = useState("");

  const reload = async(unsaved = false) => {
    const token = loading.start();
    try {
      setSystemConfigData(await api.systemConfig.getAll());
      setUnsaved(unsaved);
    } catch (e) {
      modal.errorSpecific(e);
    } finally {
      loading.stop(token);
    }
  }

  // Initialize
  useEffect(() => {
    setSelectedItem(AdminSystemConfigPath);
    reload();
  }, []);

  useEffect(() => {

    // If key does not exist, push to ../
    if (!Object.keys(AdminSystemConfigSections).includes(params.id as any)) {
      navigate(path.resolve(location.pathname, ".."));
    }
    setSelectedSelection(params.id);
    reload();

  }, [params.id])

  const handleChange = useCallback((data: Omit<SystemConfig, "id">) => {
    setSystemConfigData(data);
    setUnsaved(true);
  }, [])

  const handleSave = useCallback(async() => {
    systemConfigData && toast.save(async() => {

      const token = loading.start();
      try {
        let systemConfigDataUpdated: SystemConfig;
  
        systemConfigDataUpdated = await api.systemConfig.edit({
          ...systemConfigData,
        });
        setSystemConfigData(systemConfigDataUpdated);
        reloadGlobalSystemConfig();
        setUnsaved(false);

      } catch (e) {
        modal.errorSpecific(e);
        throw e;
      } finally {
        loading.stop(token);
      }

      
    }, "system-config-save"
      
    )
  }, [systemConfigData])


  return (
    <AdminComponent.Container name="system-config">
      <UnsavedPrompt flag={unsaved} />

      <AdminHeaderTitle>{t('admin.systemConfig.title')}</AdminHeaderTitle>
      <AdminComponent.TitleBar>
        <AdminComponentTitle faIcon={faGears}>
          {t('admin.systemConfig.pageTitle')}
        </AdminComponentTitle>
        
        <div className="d-flex">
          <ButtonWithLoader
            faIcon={faFileImport}
            variant="outline-primary"
            className="ms-auto"
            size="sm"
            onClick={async() => {
              try {
                const result = await FileHelper.importWithFileInfo();
                if (!result) {return;}
                let [dataRaw, file] = result;

                //const {id, ...data} = await FileHelper.importJson() as any;
                const ext = file.name.replace(/^(.*\.)/, "");
                if (ext.toLocaleLowerCase() == "aes") {
                  // Encrypted
                  const encryptionKey = await modal.prompt(
                    "Enter the password for decrypted", {
                      type: "password",
                      placeholder: "Password",
                      hint: "This is the password you entered during export"
                    },
                    true
                  );

                  dataRaw = FileHelper.decryptData(dataRaw, encryptionKey);

                } 

                // Decode JSON
                const data = JSON.parse(dataRaw);
                const {timestamp, ...systemConfigDataCloned} = {...systemConfigData};
                const missingKeys: string[] = [];
                
                for (const key in systemConfigDataCloned) {
                  if (data[key] === undefined) {
                    missingKeys.push(key);
                  } else {
                    systemConfigDataCloned[key] = data[key];
                  }
                }

                setSystemConfigData({timestamp, ...systemConfigDataCloned});
                setUnsaved(true);

                
                
                if (missingKeys.length > 0) {
                  modal.warning(
                    `Import successfully with ${missingKeys.length} missing key(s)`,
                    <>
                      <div>File imported and loaded with missing key(s): </div>
                      <div className="text-black-50 small mt-2">{missingKeys.join(", ")}</div>
                    </>
                  )
                } else {
                  modal.success(
                    "Import successfully",
                    "File imported and loaded successfully!"
                  )
                }
                
                
              } catch (e) {
                if (e) {
                  console.error(e);
                  modal.error(
                    "Import failed",
                    "Unable to import and load the file"
                  )
                }
                
              }
              
            }}
            disabled={loading.check()}
          >
            Import
          </ButtonWithLoader>

          <ButtonWithLoader
            faIcon={faFileExport}
            variant="primary"
            className="ms-2"
            size="sm"
            onClick={async() => {

              if (!systemConfigData) {return;}
              try {
                // this.alertModal.alert();
                const encrypt = await modal.confirm(
                  "Encrypt data?",
                  "Do you want to encrypt exported data?",
                  true
                );
                console.log(encrypt);
                if (encrypt) {
                  const encryptionKey = await modal.prompt(
                    "Enter encryption password", {
                      type: "password",
                      placeholder: "Password",
                      hint: "You will need the password to import and decrypt the file"
                    },
                    true
                  );
                  FileHelper.exportEncryptedJson({
                    filename: "system-config.json",
                    data: systemConfigData,
                    key: encryptionKey
                  });
                } else if (encrypt === false) {
                  FileHelper.exportJson({
                    filename: "system-config.json",
                    data: systemConfigData
                  });
                }
              } catch (e) {
                if (e) {
                  console.error(e);
                  modal.error(
                    "Export failed",
                    "Unable to export"
                  )
                }
              }
              
            }}
            disabled={loading.check()}
          >
            Export
          </ButtonWithLoader>
        </div>

      </AdminComponent.TitleBar>
      <div className="hr" />

      <ButtonGroup
        size="sm"
        className="d-flex justify-content-center align-items-center"
      >
        {
          Object.entries(AdminSystemConfigSections).map(([key, section]) => {
            return (
              <Link
                key={key}
                to={`../${key}`}
                className={clsx("btn text-nowrap flex-grow-0 px-3 my-2", selectedSection == key ? "btn-info" : "btn-outline-info")}
              >
                {/* <FontAwesomeIcon icon={section.icon} className="me-2"/> */}
                <small>{section.title}</small>

              </Link>
              // <ButtonWithLoader
              //   key={key}
              //   faIcon={section.icon}
              //   variant={selectedSection == key ? "info" : "outline-info"}
              //   onClick={() => {
              //     this.setState({
              //       selectedSection: key
              //     })
              //   }}
              // >
              //   {section.title}
              // </ButtonWithLoader>
            )
          })
        }
      </ButtonGroup>

      <div className="sub-page-container">
        <div className={clsx("sub-page", (selectedSection == "general") && "active")}>
          <EntityData
            unsaved={unsaved}
            noUnsavedPrompt
            className="mb-5"
            object={systemConfigData}
            loading={loading.check()}
            timestamp={systemConfigData?.timestamp}
            onChange={(data) => {
              handleChange(data);
            }}
            onSave={handleSave}
            meta={{
              GENERAL: {
                title: <span><FontAwesomeIcon icon={faHouseChimney} className="me-3" />General</span>,
                type: "section"
              },
              DEFAULT_LANGUAGE: {
                title: "Default language",
                type: "select",
                selectOptions: i18ns.map((i18n) => {
                  return {
                    value: i18n.id, 
                    label: `${i18n.name} (${i18n.id})`
                  }
                })
              },
              // LOGO: {
              //   title: "Logo",
              //   type: "valueComponent",
              //   component: (
              //     <SingleImageChooser
              //       api={api.fileManager}
              //       src={systemConfigData?.SYSTEM_LOGO_SRC}
              //       onChange={(src) => {
              //         handleChange({
              //           ...systemConfigData,
              //           SYSTEM_LOGO_SRC: src
              //         })
              //       }}
              //     />
              //   )
              // },

              // LOGO_MINI: {
              //   title: "Logo mini",
              //   type: "valueComponent",
              //   component: (
              //     <SingleImageChooser
              //       api={api.fileManager}
              //       src={systemConfigData?.SYSTEM_LOGO_MINI_SRC}
              //       onChange={(src) => {
              //         handleChange({
              //           ...systemConfigData,
              //           SYSTEM_LOGO_MINI_SRC: src
              //         })
              //       }}
              //     />
              //   )
              // }
              
              PENDING_PROPERTY_EMAIL: {
                title: "Pending Property Email",
                type: "text",
              }
            }}
          />
        </div>


        <div className={clsx("sub-page", (selectedSection == "file-system") && "active")}>
          <EntityData
            unsaved={unsaved}
            noUnsavedPrompt
            className="mb-5"
            object={systemConfigData}
            loading={loading.check()}
            timestamp={systemConfigData?.timestamp}
            onChange={(data) => {
              handleChange(data);
            }}
            onSave={handleSave}
            meta={{
              AWS: {
                title: <span><FontAwesomeIcon icon={faAws} className="me-3" />AWS S3</span>,
                type: "section"
              },

              AWS_S3_ENABLED: {
                title: "Enabled",
                type: "switch"
              },
              
              AWS_S3_ACCESS_KEY: {
                title: "Access key",
                type: "text",
                disabled: !systemConfigData?.AWS_S3_ENABLED
              },
              AWS_S3_SECRET_KEY: {
                title: "Secret key",
                type: "text-masked",
                disabled: !systemConfigData?.AWS_S3_ENABLED
              },
              AWS_S3_BUCKET: {
                title: "Bucket",
                type: "text",
                disabled: !systemConfigData?.AWS_S3_ENABLED
              },
              AWS_S3_BUCKET_REGION: {
                title: "Region",
                type: "text",
                disabled: !systemConfigData?.AWS_S3_ENABLED
              },
              AWS_S3_BUCKET_ROOT_DIR: {
                title: "Root directory",
                titleInfo: "Please don't add '/' in the beginning or end",
                type: "text",
                disabled: !systemConfigData?.AWS_S3_ENABLED
              },
              AWS_S3_HINT: {
                title: <><FontAwesomeIcon icon={faCircleInfo} className="me-2 text-muted" /> Tips</>,
                type: "valueComponent",
                component: (
                  <div>
                    <p className="my-0">In AWS S3 Console, please make the folder and files PUBLIC. <br/>
                      In the respective <code>.env</code> file in frontend system, please set <code>REACT_APP_AWS_S3_URL</code> variable to be: <br />
                      <code>https://{systemConfigData?.AWS_S3_BUCKET}.s3.amazonaws.com/{systemConfigData?.AWS_S3_BUCKET_ROOT_DIR}/</code><br />
                      and restart the service after change
                    </p>
                  </div>
                )
              },
              AWS_S3_WARNING: {
                title: <><FontAwesomeIcon icon={faExclamationTriangle} className="me-2 text-muted" /> Warning</>,
                type: "valueComponent",
                component: (
                  <div>
                    By changing any AWS S3 parameters, any file already linked may not be working anymore, and requires additional migration actions.
                  </div>
                )
              }
              
            }}
          />
        </div>


        <div className={clsx("sub-page", (selectedSection == "login") && "active")}>
          <EntityData
            unsaved={unsaved}
            noUnsavedPrompt
            className="mb-5"
            object={systemConfigData}
            loading={loading.check()}
            timestamp={systemConfigData?.timestamp}
            onSave={handleSave}
            meta={{
              LOGIN_SESSION: {
                title: <span><FontAwesomeIcon icon={faRightToBracket} className="me-3" />Login Session</span>,
                type: "section"
              },
              REFRESH_TOKEN_LIFETIME: {
                title: "Refresh token lifetime (second)",
                type: "text-int"
              },
              ACCESS_TOKEN_LIFETIME: {
                title: "Access token lifetime (second)",
                type: "text-int"
              },
              
              GOOGLE_RECAPTCHA: {
                title: <span><FontAwesomeIcon icon={faGoogle} className="me-3" />Google ReCAPTCHA</span>,
                type: "section",
              },
              GOOGLE_RECAPTCHA_ENABLED: {
                title: "Enabled",
                type: "switch"
              },
              GOOGLE_RECAPTCHA_V2_CHECKBOX_SITE_KEY: {
                title: "Checkbox Site Key",
                type: "text",
              },

              GOOGLE_RECAPTCHA_V2_CHECKBOX_SECRET_KEY: {
                title: "Checkbox Secret Key",
                type: "text-masked",
              },

              GOOGLE_RECAPTCHA_V2_INVISIBLE_SITE_KEY: {
                title: "Invisible Site Key",
                type: "text",
              },

              GOOGLE_RECAPTCHA_V2_INVISIBLE_SECRET_KEY: {
                title: "Invisible Secret Key",
                type: "text-masked",
              },

              GOOGLE_API: {
                title: <span><FontAwesomeIcon icon={faGoogle} className="me-3" />Google API</span>,
                type: "section"
              },
              GOOGLE_API_ENABLED: {
                title: "Enabled",
                type: "switch"
              },
              GOOGLE_API_CLIENT_ID: {
                title: "Client ID",
                type: "text",
                disabled: !systemConfigData?.GOOGLE_API_ENABLED
              },
              GOOGLE_API_CLIENT_SECRET: {
                title: "Client Secret",
                type: "text-masked",
                disabled: !systemConfigData?.GOOGLE_API_ENABLED
              },
              GOOGLE_API_PREVIEW: {
                title: "Test",
                titleInfo: "It DOES NOT involve backend processing",
                warning: unsaved && "Save is required before test",
                type: "valueComponent",
                component: (
                  googleAPITestFlag == "testing" ? (
                    <GoogleAuthButton 
                      clientId={globalSystemConfig.GOOGLE_API_CLIENT_ID}
                      handleResponse={(response) => {
                        console.log(response);
                        modal.success(
                          "Test successful", 
                          "Login response data received from Google API"
                        );
                        setGoogleAPITestFlag("tested");
                      }}
                    />
                  ) : (
                    <ButtonWithLoader faIcon={faVial} variant="primary" 
                      onClick={() => {
                        setGoogleAPITestFlag("testing");
                      }}
                      disabled={!globalSystemConfig.GOOGLE_API_ENABLED || !globalSystemConfig.GOOGLE_API_CLIENT_ID}
                    >
                      {googleAPITestFlag == "untested" ? "Test" : "Test again"}
                    </ButtonWithLoader>
                  )
                )
              },


              FACEBOOK_API: {
                title: <span><FontAwesomeIcon icon={faFacebookF} className="me-3" />Facebook API</span>,
                type: "section"
              },
              FACEBOOK_API_ENABLED: {
                title: "Enabled",
                type: "switch"
              },
              FACEBOOK_API_VERSION: {
                title: "Version",
                type: "text",
                disabled: !systemConfigData?.FACEBOOK_API_ENABLED
              },
              FACEBOOK_API_CLIENT_ID: {
                title: "Client ID",
                type: "text",
                disabled: !systemConfigData?.FACEBOOK_API_ENABLED
              },
              FACEBOOK_API_CLIENT_SECRET: {
                title: "Client Secret",
                type: "text-masked",
                disabled: !systemConfigData?.FACEBOOK_API_ENABLED
              },
              FACEBOOK_API_PREVIEW: {
                title: "Test",
                warning: unsaved && "Save is required before test",
                type: "valueComponent",
                component: (
                  facebookAPITestFlag == "testing" ? (
                    <FacebookAuthButton 
                      version={globalSystemConfig.FACEBOOK_API_VERSION}
                      clientId={globalSystemConfig.FACEBOOK_API_CLIENT_ID}
                      handleResponse={(response) => {
                        console.log(response);
                        modal.success(
                          "Test successful", 
                          "Login response data received from Facebook API"
                        );
                        setFacebookAPITestFlag("tested");
                      }}
                      onError={(e) => {
                        console.error(e);
                        console.error(e.message);
                        modal.error(
                          "Test failed", 
                          "Error from Facebook API: " + e.message
                        );
                      }}
                    />
                  ) : (
                    <ButtonWithLoader faIcon={faVial} variant="primary"
                      onClick={() => {
                        setFacebookAPITestFlag("testing");
                      }}
                      disabled={!globalSystemConfig.FACEBOOK_API_ENABLED || !globalSystemConfig.FACEBOOK_API_CLIENT_ID}
                    >
                      {facebookAPITestFlag == "untested" ? "Test" : "Test again"}
                    </ButtonWithLoader>
                  )
                )
              },
              "hr": {
                type: "hr",
              }
              
            }}
            onChange={(data) => {
              handleChange(data);
            }}
          />
        </div>

        <div className={clsx("sub-page", (selectedSection == "system-email") && "active")}>
          <EntityData
            unsaved={unsaved}
            noUnsavedPrompt
            className="mb-5"
            object={systemConfigData}
            loading={loading.check()}
            timestamp={systemConfigData?.timestamp}
            onSave={handleSave}
            meta={{
              SYSTEM_EMAIL: {
                title: <span><FontAwesomeIcon icon={faEnvelope} className="me-3" />System Emails</span>,
                type: "section"
              },
              SYSTEM_EMAIL_HOST: {
                title: "Host",
                type: "text",
                readonly: true
              },
              SYSTEM_EMAIL_PORT: {
                title: "Port",
                type: "text-int",
                readonly: true
              },
              SYSTEM_EMAIL_SECURE: {
                title: "Secure",
                type: "switch",
                titleInfo: "These 3 options are based on encryption type.<br/>Test all combinations to ensure the function works.",
                disabled: true
              },
              SYSTEM_EMAIL_IGNORE_TLS: {
                title: "Ignore TLS",
                type: "switch",
                disabled: true
              },
              SYSTEM_EMAIL_TLS_REJECT_UNAUTHORIZED: {
                title: "TLS reject unauthorized",
                type: "switch",
                disabled: true
              },
              SYSTEM_EMAIL_ADDRESS: {
                title: "Email Address",
                type: "text",
                readonly: true
              },
              // SYSTEM_EMAIL_PASSWORD: {
              //   title: "Password",
              //   type: "text-masked"
              // },
              SYSTEM_EMAIL_SENDER_NAME: {
                title: "Sender name",
                type: "text"
              },
              
              SYSTEM_EMAIL_SENDER_ADDRESS: {
                title: "Sender address",
                titleInfo: "Sender address usually needs to be <br/>same as the above set email address",
                type: "text"
              },
              
              SYSTEM_EMAIL_TEST: {
                title: "Test",
                warning: unsaved && "Save is required before test",
                type: "valueComponent",
                component: (
                  <>
                    <input 
                      type="email"
                      className="form-control me-2"
                      placeholder="Test receipient"
                      value={systemEmailTestReceipient}
                      onKeyDown={(e) => { 
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          (document.querySelector("#test-email-send") as any).click();
                        } 
                      }}
                      onChange={(event) => {setSystemEmailTestReceipient(event.target.value)}}
                    />
                    <ButtonWithLoader type="button" variant="primary" id="test-email-send" faIcon={faPaperPlane}
                      loading={emailTestFlag == "testing"}
                      disabled={!systemEmailTestReceipient}
                      onClick={async() => {
                        try {
                          setEmailTestFlag("testing");
                          const result = await api.mail.test(systemEmailTestReceipient);
                          if (result) {
                            modal.success("Test Successful", "Email successfully sent to " + systemEmailTestReceipient);
                          } else {
                            modal.error("Test Failed", "Email could not be sent to " + systemEmailTestReceipient);
                          }
                        } catch (e) {
                          modal.errorSpecific(e);
                        } finally {
                          setEmailTestFlag("tested");
                        }

                      }}
                    >
                      Send
                    </ButtonWithLoader>
                  </>
                )
              },
              "hr": {
                type: "hr",
              }
            }}
            onChange={(data) => {
              handleChange(data);
            }}
          />
        </div>
      </div>
    </AdminComponent.Container>
  )
}
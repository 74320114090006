

export function APIEncodeQueryString(data: {
  [key: string]: string | number | boolean
}) {
  let dataCopyWithoutNull = {};
  Object.entries(data)?.forEach(([key, value]) => {
    if (value != undefined) {
      dataCopyWithoutNull[key] = value;
    }
  })

  // console.log(dataCopyWithoutNull);
  return new URLSearchParams(dataCopyWithoutNull).toString();
}
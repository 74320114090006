import { faLanguage, faTableColumns } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AdminI18NPath } from "..";
import { useGlobalModal } from "../../../component/GlobalModal";
import { I18N, I18NDto } from "../../../api/entities/i18n.entity";
import { useLoadingManager } from "../../../utils/loading-manager";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";
import { EntityData } from "../component/EntityData";
import AceEditor from "react-ace";
import 'ace-builds/webpack-resolver';
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/ext-language_tools";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import Select from "react-select";
import { useI18N } from "../../../component/I18NProvider";

import * as jsonlint from '@prantlf/jsonlint';


export const AdminI18N = (props: {
  mode: "create" | "edit"
}) => {
  const {api, loading, toast, setSelectedItem} = useAdminComponent();
  const modal = useGlobalModal();
  const {t, i18ns, i18nsReload} = useI18N();
  const navigate = useNavigate();

  const {mode} = props;
  const {id} = useParams<{id: string}>();
  const [unsaved, setUnsaved] = useState(false);
  const [i18n, setI18n] = useState<Partial<I18N | null>>(null)
  const editorRef = useRef<AceEditor>(null);
  const [refI18n, setRefI18n] = useState<Omit<I18N, "json"> | null>(null);
  const [refI18nJson, setRefI18nJson] = useState<string | null>(null);
  const [jsonlintError, setJsonLintError] = useState<{
    message: string,
    row: number,
    column: number
  } | null>(null)

  const isNew = useMemo(() => (mode == "create"), [mode]);

  const reload = async() => {
    let token = loading.start();
    try {
      setI18n(await api.i18n.findById(id!));
    } catch (e) {
      modal.errorSpecific(e);
    } finally {
      loading.stop(token);
    }
  }

  useEffect(() => {
    if (i18n?.json) {
      try {
        //JSON.parse(i18n.json);
        jsonlint.parse(i18n.json)
        setJsonLintError(null);
      } catch (e) {
        // console.log(e);
        if (e instanceof SyntaxError) {
          let error = e as any;
          if (error.location?.start && error.message) {
            // console.log(error.location);
            // console.log(error.message);
            // console.log(error.reason);
            setJsonLintError({
              message: error.reason,
              row: error.location.start.line - 1,
              column: error.location.start.column - 1
            });
          }
          // editorRef.current.editor.getSession().setAnnotations([{
          //   text: "Error",
          //   type: "error",
          // }])
        }
      }
    }
  }, [i18n?.json]);

  // Init
  useEffect(() => {
    setSelectedItem(AdminI18NPath);
    if (mode != "create") {
      reload();
    } else {
      setI18n({
        id: undefined,
        isoId: "",
        name: "",
        shortName: "",
        alias: "",
        orderId: 999,
        json: "",
        timestamp: undefined
      })
    }
  }, [])


  // Render
  // if (mode == 'create' && i18n?.timestamp) {
  //   // Redirect to the user page for NEWLY CREATED USER or ID changed
  //   return <Redirect to={`${baseUrl}/${AdminI18NPath}/${i18n.id}`} />
  // }

  const title = useMemo(() => (
    mode === 'create' ? t('admin.i18n.title-create') : t('admin.i18n.title-edit')
  ), [mode])
  return (
    <AdminComponent.Container>
      
      <AdminHeaderTitle>{title}</AdminHeaderTitle>
      <AdminComponent.TitleBar>
        <AdminComponentTitle faIcon={faLanguage} backTo={`../../${AdminI18NPath}`}>
          {title}
        </AdminComponentTitle>
      </AdminComponent.TitleBar>
      {
        i18n && (
          <EntityData 
            unsaved={unsaved}
            object={i18n}  
            loading={loading.check()}
            timestamp={(mode != "create") && i18n?.timestamp || undefined}
            meta={{
              "hr": {
                type: "hr",
              },
              "id": {
                title: t('admin.i18n.id'),
                type: "text"
              },
              "isoId": {
                title: t('admin.i18n.isoId'),
                type: "text"
              },
              "name": {
                title: t('admin.i18n.name'),
                type: "text"
              },
              "shortName": {
                title: t('admin.i18n.shortName'),
                type: "text"
              },
              "alias": {
                title: t('admin.i18n.alias'),
                type: "text"
              },
              "json": {
                type: "entireComponent",
                component: (
                  <>
                    <div className="py-2 d-flex align-items-center ">
                      <ButtonWithLoader
                        faIcon={faCopy}
                        variant="outline-info"
                        loading={loading.check()}
                        disabled={!refI18n}
                        onClick={async() => {
                          if (refI18n) {
                            let token = loading.start();
                            let ref = await api.i18n.findById(refI18n.id);
                            setI18n({...i18n, json: ref.json});
                            setUnsaved(true);
                            loading.stop(token);
                          }
                        }}
                      > 
                        {t('admin.i18n.copyFrom')} {
                          refI18n ? `'${refI18n.id}'` : "..."
                        }
                      </ButtonWithLoader>
                      <ButtonWithLoader
                        className="ms-2"
                        faIcon={faTableColumns}
                        variant="outline-success"
                        loading={loading.check()}
                        disabled={!refI18n}
                        onClick={async() => {
                          if (refI18nJson === null && refI18n) {
                            let token = loading.start();
                            let ref = await api.i18n.findById(refI18n.id);
                            setRefI18nJson(ref.json)
                            loading.stop(token);
                          } else {
                            setRefI18nJson(null);
                          }
                        }}
                      > 
                        {t('admin.i18n.toggleSplitRef')} {
                          refI18n ? `'${refI18n.id}'` : "..."
                        }
                      </ButtonWithLoader>
                      <div style={{minWidth: "150px", zIndex: 10}}>
                        <Select
                          className="ms-2"
                          placeholder={t('admin.i18n.language')}
                          options={i18ns.map((obj) => {
                            return {
                              value: obj,
                              label: `${obj.name} (${obj.id})`
                            }
                          })}
                          value={refI18n && {
                            value: refI18n,
                            label: `${refI18n.name} (${refI18n.id})`
                          }}
                          tabIndex={-1}
                          onChange={(newValue) => setRefI18n(newValue!.value)}
                        />
                      </div>
                      
                    </div>
                    <div style={{height: "90vh", minHeight: "600px"}}>
                      <div style={{width: refI18nJson ? "50%" : "100%", height: "100%", display: "inline-block"}}>
                        <AceEditor
                          width="100%"
                          height="100%"
                          placeholder=""
                          mode="json"
                          theme="solarized_dark"
                          name="i18n-editor"
                          // onLoad={this.onLoad}
                          onChange={(value) => {
                            setI18n({
                              ...i18n,
                              json: value
                            });
                            setUnsaved(true);
                          }}
                          readOnly={loading.check()}
                          fontSize={14}
                          showPrintMargin={false}
                          showGutter={true}
                          highlightActiveLine={true}
                          value={i18n.json}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: true,
                            tabSize: 2,
                            useWorker: false
                          }}
                          annotations={jsonlintError && [{
                            row: jsonlintError.row,
                            column: jsonlintError.column,
                            text: jsonlintError.message,
                            type: "error"
                          }] || undefined}
                          markers={jsonlintError ? [{
                            startRow: jsonlintError.row,
                            endRow: jsonlintError.row,
                            startCol: jsonlintError.column,
                            endCol: jsonlintError.column+1,
                            className: "json-editor-error-line",
                            type: "fullLine"
                          }] : []}
                        />
                      </div>

                      {
                        refI18nJson && (
                          <div style={{width: "50%", height: "100%", display: "inline-block"}}>
                            <AceEditor
                              width="100%"
                              height="100%"
                              ref={editorRef}
                              placeholder=""
                              mode="json"
                              theme="solarized_dark"
                              name="blah2"
                              readOnly
                              fontSize={14}
                              showPrintMargin={false}
                              showGutter={true}
                              highlightActiveLine={true}
                              value={refI18nJson}
                              setOptions={{
                                enableBasicAutocompletion: false,
                                enableLiveAutocompletion: false,
                                enableSnippets: true,
                                showLineNumbers: true,
                                tabSize: 2,
                                useWorker: false
                              }}
                            />
                          </div>
                        )
                      }
                    </div>
                  </>
                )
              },
              "hr2": {
                type: "hr",
              }
            }}  
            onChange={(data) => {
              setI18n(data);
              setUnsaved(true);
            }}
            onDelete={isNew && id && (async() => {
              if (id && await modal.confirmDelete(true)) {
                let token = loading.start();
                try {
                  await api.i18n.delete(id);
                  await i18nsReload();
                  navigate(`..`);
                } catch (e) {
                  modal.errorSpecific(e);
                }
                loading.stop(token);
              }
            })}
            onSave={()=>{
              toast.save(async() => {

                let token = loading.start();
                
                try {
                  let {timestamp, ...dto} = i18n as I18N;
                  var i18nUpdated = await (mode === 'create' ? api.i18n.create(dto) : api.i18n.update(id, dto));

                  setI18n(i18nUpdated);
                  setUnsaved(false);
                  await i18nsReload();
                  if (id !== i18nUpdated.id) {
                    navigate(`../${i18nUpdated.id}`);
                  }
                } catch (e) {
                  modal.errorSpecific(e);
                  console.error(e);
                  throw e;
                } finally {
                  loading.stop(token);
                }

                
              }, "i18n-save")
            }}
          />
        )
      }
    </AdminComponent.Container>
  );
  
}
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, Canceler, Method } from "axios";
import { CustomHttpExceptionType, CustomHttpExceptionTypes } from "./entities/custom-http-exception-types";
import { AuthRefreshAccessTokenDto } from "./entities/user.entity";
import AppAPI from "./src/app";
import AppointmentAPI from "./src/appointment";
import AuthAPI from "./src/auth";
import BackupAPI from "./src/backup";
import BuildingsAPI from "./src/buildings";
import DevelopersAPI from "./src/developers";
import DistrictsAPI from "./src/districts";
import FileManagerAPI from "./src/file-manager";
import GoogleapisAPI from "./src/googelapis";
import I18NAPI from "./src/i18n";
import MailAPI from "./src/mail";
import OpenCCAPI from "./src/open-cc";
import PdfUtilAPI from "./src/pdf-util";
import PropertyAPI from "./src/property";
import PropertyListingAPI from "./src/property-listing";
import ServerCostSummaryAPI from "./src/server-cost-summary";
import SystemConfigAPI from "./src/system-config";
import SystemInfoAPI from "./src/system-info";
import UsersAPI from "./src/users";

interface BridgeURL {
  frontend: string;
  backend: string;
  backendAPI: string;
}

interface APIInstanceOptions {
  locale?: string
  bridgeUrl?: BridgeURL
}

class APIInstance {

  readonly axios: AxiosInstance;
  readonly URL: BridgeURL;
  readonly app: AppAPI;
  readonly systemInfo: SystemInfoAPI;
  readonly serverCostSummary: ServerCostSummaryAPI;
  readonly systemConfig: SystemConfigAPI;
  readonly auth: AuthAPI;
  readonly appointment: AppointmentAPI;
  readonly users: UsersAPI;
  readonly mail: MailAPI;
  readonly fileManager: FileManagerAPI;
  readonly i18n: I18NAPI;
  readonly openCC: OpenCCAPI;
  readonly googleapisApi: GoogleapisAPI;
  readonly districts: DistrictsAPI;
  readonly developers: DevelopersAPI;
  readonly buildings: BuildingsAPI;
  readonly property: PropertyAPI;
  readonly propertyListing: PropertyListingAPI;
  readonly backup: BackupAPI;
  readonly pdfUtil: PdfUtilAPI;

  // Root API
  readonly getCommon = async(): Promise<{
    GOOGLE_API_CLIENT_ID?: string,
    FACEBOOK_API_VERSION?: string,
    FACEBOOK_API_CLIENT_ID?: string,
  }> => {
    return (await this.axios.get("common")).data;
  }
  

  constructor(
    options?: APIInstanceOptions,
  ) {

    this.URL = (options && options.bridgeUrl) || {
      frontend: process.env.REACT_APP_FRONTEND_URL || "",
      backend: process.env.REACT_APP_BACKEND_URL || "",
      backendAPI: process.env.REACT_APP_BACKEND_API_URL || ""
    };

    if (!this.URL.frontend || !this.URL.backend || !this.URL.backendAPI) {
      console.log(this.URL);
      throw new Error("Missing URLs in .env file!");
    }

    this.axios = axios.create({
      // baseURL: "https://shirtstylist-admin.herokuapp.com/api/",
      baseURL: `${this.URL.backendAPI}`,
    });

    this.axios.interceptors.request.use((config) => {
      if (options?.locale) {
        config.headers["locale"] = options.locale;
      }
      return config;
    });

    // axiosRetry(this.axios, {
    //   retries: 5,
    // })

    // Import all APIs
    this.app = new AppAPI(this.axios);
    this.systemInfo = new SystemInfoAPI(this.axios);
    this.serverCostSummary = new ServerCostSummaryAPI(this.axios);
    this.systemConfig = new SystemConfigAPI(this.axios);
    this.auth = new AuthAPI(this.axios);
    this.appointment = new AppointmentAPI(this.axios);
    this.users = new UsersAPI(this.axios);
    this.mail = new MailAPI(this.axios);
    this.fileManager = new FileManagerAPI(this.axios);
    this.i18n = new I18NAPI(this.axios);
    this.openCC = new OpenCCAPI(this.axios);
    this.googleapisApi = new GoogleapisAPI(this.axios);
    this.districts = new DistrictsAPI(this.axios);
    this.developers = new DevelopersAPI(this.axios);
    this.buildings = new BuildingsAPI(this.axios);
    this.property = new PropertyAPI(this.axios);
    this.propertyListing = new PropertyListingAPI(this.axios);
    this.backup = new BackupAPI(this.axios);
    this.pdfUtil = new PdfUtilAPI(this.axios);
    
  }
  
}

export abstract class APIInstanceWithAuthBase extends APIInstance {
  accessTokenStorageId: string;
  refreshTokenStorageId: string;

  constructor(options: {
    accessTokenStorageId: string;
    refreshTokenStorageId: string;
  } & APIInstanceOptions) {
    const {accessTokenStorageId, refreshTokenStorageId, ...apiInstanceOptions} = options;
    super(apiInstanceOptions);

    this.accessTokenStorageId = accessTokenStorageId;
    this.refreshTokenStorageId = refreshTokenStorageId;


    // Reference: https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token
    // Assign access token to header
    this.axios.interceptors.request.use((config) => {
      const accessToken = this.getAccessToken();
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    });

    // Response interceptor for API calls
    let self = this;
    self.axios.interceptors.response.use((response) => {
      return response
    }, async (error: AxiosError<{
      type: CustomHttpExceptionType
    }> & {
      config: AxiosRequestConfig & {
        _retryForAccessToken: boolean,
        _retries: number,
      }
    }) => {
      // console.error("Axios interceptor ERROR!");
      const originalRequest = error.config;
      if (originalRequest) {
        // console.log(originalRequest._retries);
        // 5 times retry
        originalRequest._retryForAccessToken = 
          (originalRequest._retryForAccessToken == undefined) ? true : originalRequest._retryForAccessToken;
          originalRequest._retries = originalRequest._retries ?? 5;

        if ((error?.response?.data?.type === "InvalidAccessTokenException") 
          && originalRequest._retryForAccessToken 
          && originalRequest._retries > 0
          ) {
          let refresh_token = self.getRefreshToken();
          if (refresh_token) {
            originalRequest._retries--;
            const {access_token} = await self.auth.refreshAccessToken({refresh_token}); 
            self.setAccessToken(access_token);
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
            return self.axios(originalRequest);
          }
          
        }
      }
      throw error;
    });

  }

  setAccessToken(content: string) {
    if (content) {
      localStorage.setItem(this.accessTokenStorageId, content);
    } else {
      localStorage.removeItem(this.accessTokenStorageId);
    }
  }

  getAccessToken() {
    return localStorage.getItem(this.accessTokenStorageId)
  }

  setRefreshToken(content: string) {
    if (content) {
      localStorage.setItem(this.refreshTokenStorageId, content);
    } else {
      localStorage.removeItem(this.refreshTokenStorageId);
    }
  }

  getRefreshToken() {
    return localStorage.getItem(this.refreshTokenStorageId)
  }

}

class APIInstanceWithAuth extends APIInstanceWithAuthBase {
  constructor(options?: APIInstanceOptions) {
    super({
      accessTokenStorageId: "access-token",
      refreshTokenStorageId: "refresh-token",
      ...options
    });
  }
}

class APIInstanceWithAuthForAdmin extends APIInstanceWithAuthBase {
  constructor(options?: APIInstanceOptions) {
    super({
      accessTokenStorageId: "admin-access-token",
      refreshTokenStorageId: "admin-refresh-token",
      ...options
    });
  }
}

export {APIInstance, APIInstanceWithAuth, APIInstanceWithAuthForAdmin};

import { AxiosInstance } from "axios";
import { District, DistrictDto } from "../entities/district.entity";
import { I18N, I18NDto } from "../entities/i18n.entity";
import { APIBase } from "./apiBase";
import { APIEncodeQueryString } from "./helpers";

export default class DistrictsAPI extends APIBase {
  

  constructor(axios: AxiosInstance) {
    super(axios, "districts");
  }

  async findAll(): Promise<District[]> {
    return (await this.axios.get<District[]>(
      this.base
    )).data;
  }

  async findAllPublic(): Promise<District[]> {
    return (await this.axios.get<District[]>(
      this.base + "/public"
    )).data;
  }

  async updateOrderId(id: string, orderId: number): Promise<District> {
    return (await this.axios.put<District>(
      this.base + "/" + id + "/orderId/" + orderId
    )).data;
  }

  async update(id: string, dto: DistrictDto): Promise<District> {
    return (await this.axios.put<District>(
      this.base + "/" + id,
      dto
    )).data;
  }

  async create(dto: DistrictDto): Promise<District> {
    return (await this.axios.post<District>(
      this.base,
      dto
    )).data;
  }

  async delete(id: string): Promise<void> {
    return (await this.axios.delete<void>(
      this.base + "/" + id
    )).data;
  }

}
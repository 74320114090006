import { faAngleDoubleLeft, faAngleLeft, faArrowRightFromBracket, faBars, faCaretDown, faGlobe, faHome, faUser, faUserCircle, faUserGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { useCallback, useContext, useEffect, useId, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { AdminDashboardPath, AdminLoginPath, AdminLogoutPath, AdminPropertyListingPath, AdminUserPath, SidebarItems } from "..";
import { APIInstanceWithAuth, APIInstanceWithAuthForAdmin } from "../../../api";
import { AdminRole, User, userHasAdminRoles } from "../../../api/entities/user.entity";
import { Helmet } from "react-helmet-async";
import ReactTooltip from '@huner2/react-tooltip';
import { LocalCache } from "../../../api/src/local-cache";
import { toast } from "react-toastify";
import "../../../styles/admin/toastify.scss";
import { TopLoader } from "../../../component/TopLoader";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider";
import { LoadingManager, useLoadingManager } from "../../../utils/loading-manager";
import { useUser } from "../../../component/UserProvider";
import { FileViewerImage } from "../../../component/file-manager";
import { useGlobalSystemConfig } from "../../../component/GlobalSystemConfig";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Id, Toast } from "react-toastify/dist/types";
import { useGlobalLoadingScreen } from "../../GlobalLoadingScreen";
import { useWindowFocused } from "../../../utils/useWindowFocused";
import { useAbortable } from "../../../utils/abortable-loading";
import { Badge } from "react-bootstrap";

const AdminUserCheckInterval = 20000;  // 8-sec

const AdminComponentContext = React.createContext<AdminComponentContextType>(null);

interface AdminToastMessage {
  pending: React.ReactNode;
  success: React.ReactNode;
  error: React.ReactNode;
}

export interface AdminComponentContextType {
  api: APIInstanceWithAuth,
  adminUser: User,
  localCache: LocalCache<{
    sidebarCollapsed: boolean,
    embedded3DIFrameWidth: number,
  }>
  toast: {
    create: (fn: () => Promise<void>, message: AdminToastMessage, id?: string) => Promise<void>,
    save: (fn: () => Promise<void>, id?: string) => Promise<void>
    delete: (fn: () => Promise<void>, id?: string) => Promise<void>
  },
  loading: LoadingManager
  setSelectedItem: (item: string) => void;
  toggleSidebar: () => void;
  reloadPropertyListingCountPendingItems: () => Promise<void>;
  propertyListingPendingItemsCount: number;
}



export const useAdminComponent = (options?: {
  selectedItem?: string
}): AdminComponentContextType => {
  const context = useContext(AdminComponentContext);
  useEffect(() => {
    if (options?.selectedItem !== undefined) {
      context.setSelectedItem(options?.selectedItem);

      return () => {
        context.setSelectedItem(null);
      }
    }
  }, [options?.selectedItem])

  return context;
}

const AdminComponent = (
  props: React.PropsWithChildren<{}>
) => {
  // Props
  const {children} = props;
  const [selectedItem, setSelectedItem] = useState<string>("");
  const {globalLoading} = useGlobalLoadingScreen();
  const {windowFocusedRef} = useWindowFocused();
  // Local (shared for context)
  const localCache = new LocalCache<{
    sidebarCollapsed: boolean
  }>({
    prefix: 'admin.'
  });
  // const sessionCache = new LocalCache<{
  //   displayName: string
  // }>({
  //   prefix: 'admin.',
  //   useSessionStorage: true
  // },);
  const [scrollX, setScrollX] = useState(window.scrollX);
  const {i18ns, i18nsReload, t, openLanguageSelector, currentLang} = useI18N();
  const api = useMemo(() => (new APIInstanceWithAuthForAdmin({
    locale: currentLang
  })), []);
  const {adminUser, checkAdminUser} = useUser();
  // const [adminUserCheckError, setAdminUserCheckError] = useState(false);
  const adminUserCheckError = useRef(false);
  const loading = useLoadingManager();
  const {globalSystemConfig} = useGlobalSystemConfig();

  // States
  const [sidebarCollapsed, setSidebarCollapsed] = useState(localCache.get("sidebarCollapsed", false));
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [authResult, setAuthResult] = useState<boolean>(null);

  const toastPrefixId = useId();
  const toastCount = useRef<number>(0);

  const toastIdStatus = useRef<{[id: string]: 'pending' | 'success' | 'error'}>({});

  const checkAdminUserAbortable = useAbortable();
  
  const myToast = {
    create: async(fn: (() => Promise<void>), message: AdminToastMessage, id?: string): Promise<void> => {
      // toast.clearWaitingQueue({ containerId: "admin-toast" });
      // https://github.com/fkhadra/react-toastify/pull/729
      ++toastCount.current;
      const containerId = "admin-toast";
      const success = (toastId: Id) => setTimeout(() => {
        toast.update(toastId, {
          isLoading: false,
          type: toast.TYPE.SUCCESS,
          render: () => message.success,
          autoClose: 5000,
          closeOnClick: true,
          closeButton: true,
          
        })
      }, 100);

      const error = (toastId: Id) => setTimeout(() => {
        toast.update(toastId, {
          isLoading: false,
          type: toast.TYPE.ERROR,
          render: () => message.error,
          autoClose: 5000,
          closeOnClick: true,
          closeButton: true
        })
      }, 100);

      const toastId = toast.info(message.pending, {
        containerId,
        toastId: id,
        isLoading: true,
        autoClose: false,
        closeButton: false,
        closeOnClick: false,
        onOpen: () => {
          console.log("Id: ", toastId);
          console.log("status: ", toastIdStatus.current[toastId])
          
          switch (toastIdStatus.current[toastId]) {
            case "success": 
              success(toastId);
              break;
            case "error": 
              error(toastId);
              break;
          }
        }
      })
      console.log(toastId);

      toastIdStatus.current[toastId] = "pending";

      try {
        await fn();
        // await (new Promise<void>((resolve) => {
        //   setTimeout(() => {resolve()}, 10000);
        // }));
        success(toastId);
        toastIdStatus.current[toastId] = "success";
      } catch (e) {
        error(toastId);
        toastIdStatus.current[toastId] = "error";
      } finally {
        // toastIdPending.current.delete(toastId);
      }

      // toast.promise(
      //   fn, 
      //   {
      //     pending: {
      //       render: () => t("toast.save.pending"),
      //       updateId: id,
      //     },
      //     success: {
      //       render: () =>t("toast.save.success"),
      //       updateId: id,
      //     },
      //     error: {
      //       render: () => t("toast.save.error"),
      //       // autoClose: 1
      //     }
      //   },
      //   {
      //     toastId: id,
      //     containerId: "admin-toast"
      //   }
      // )
    },

    save: async(fn: (() => Promise<void>), id?: string): Promise<void> => { 
      return myToast.create(fn, {
        pending: t("toast.save.pending"), 
        success: t("toast.save.success"),
        error: t("toast.save.error")
      }, id);
    },

    delete: async(fn: (() => Promise<void>), id?: string): Promise<void> => { 
      return myToast.create(fn, {
        pending: t("toast.delete.pending"), 
        success: t("toast.delete.success"),
        error: t("toast.delete.error")
      }, id);
    }
  }

  // Provider consuming
  const modal = useGlobalModal();

  // Sidebar toggle button
  const toggleSidebar = useCallback((collapsed?: boolean) => {
    const newVal = collapsed ?? !sidebarCollapsed;
    setSidebarCollapsed(newVal);
    localCache.set({
      sidebarCollapsed: newVal
    })

  }, [sidebarCollapsed])

  const [propertyListingPendingItemsCount, setPropertyListingPendingItemsCount] = useState<number>(null);
  // Reload count pending
  const reloadPropertyListingCountPendingItems = useCallback(async() => {
    try {
      setPropertyListingPendingItemsCount(await api.propertyListing.countPendingItems());
    } catch (e) {

    }
  }, [])

  
  // Initializor
  const initialize = async() => {

    const globalLoadingToken = globalLoading.start();
    let authResult = false;


    if (api.getAccessToken()) {
      // console.log("init");
      const loadingToken = loading.start();
      try {
        authResult = await api.auth.check(true);
  
        if (authResult) {

        }
        
      } catch (e) {
        authResult = false;
        // modal.errorSpecific(e);
      } finally {
        loading.stop(loadingToken);
      }
    }

    globalLoading.stop(globalLoadingToken);

    setAuthResult(authResult);
  }


  // init
  useLayoutEffect(() => {
    let adminUserCheckTimeout: NodeJS.Timeout = null;
    
    const regularAdminUserCheck = async(chained = true) => {
      try {
        if (windowFocusedRef.current) {
          console.log("Admin User Regular Check");
          const {signal} = checkAdminUserAbortable.create();
          await checkAdminUser({signal});
        }
        if (chained) {
          adminUserCheckTimeout = setTimeout(async() => {
            regularAdminUserCheck(true);
          }, AdminUserCheckInterval);
        }
        
      } catch (e) {
        if (!adminUserCheckError.current) {
          modal.errorSpecific(e);
          // setAdminUserCheckError(true);
          adminUserCheckError.current = true;
        }
      }
    }

    const onWindowFocus = () => {
      regularAdminUserCheck(false);
    }

    initialize().then(() => {
      if (adminUser) {
        regularAdminUserCheck(true);
        window.addEventListener("focus", onWindowFocus);

        reloadPropertyListingCountPendingItems();
      }
    });

    const onScroll = () => setScrollX(window.scrollX);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      checkAdminUserAbortable.abort();
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener("focus", onWindowFocus);
      clearTimeout(adminUserCheckTimeout);
    };

  }, [])


  // console.log(auth, authResult, isCached);
  

  if (authResult === null) {
    return <></>
  } else if (authResult === false) {
    const currentPath = window.location.pathname + window.location.search;
    let returnTo = encodeURIComponent(currentPath);
    return <Navigate to={AdminLoginPath + `?to=${returnTo}&error`} />
    
  } else {
    // Render children with navbars
    //console.log(title);

    return (
      <AdminComponentContext.Provider
        value={{
          api, adminUser, localCache, toast: myToast, loading, 
          setSelectedItem: (item: string) => {
            setSelectedItem(item)
          },
          toggleSidebar,
          reloadPropertyListingCountPendingItems, propertyListingPendingItemsCount,
        }}
      >

        {
          <main
            className={clsx("admin", loading.check() && "loading")}
            data-sidebar-collapsed={sidebarCollapsed}
            onClick={() => {
              toggleSidebar(true);
              setUserDropdownOpen(false);
            }}
          >
            {
              loading.check() && <TopLoader color="#3c526a" />
            }
            
            
            
            <Helmet>
              {
                <title>{`${t('admin.title')} | ${t('title')}`}</title>
              }
              
            </Helmet>
            

            <div 
              className="admin-sidebar"
              style={{
                // left: -scrollX
              }}
              onClick={event => {
                event.stopPropagation()
                toggleSidebar(false);
                setUserDropdownOpen(false);
              }}
            >
              <div className="admin-sidebar-header">
                {/* <svg className="bi me-2" width="40" height="32"></svg> */}
                <div className={clsx("admin-sidebar-logo", loading.check() && "loading")}>
                  <img src="/logo-mini.png" />
                </div>
                <span className="admin-sidebar-title">{t('admin.title')}</span>
              </div>
              <hr />
              <ul className="nav nav-pills flex-column mb-auto">
                {
                  SidebarItems.map((item) => {
                    const selected = selectedItem && selectedItem == item.key;
                    const {requiredRoles} = item;
                    if (!userHasAdminRoles(adminUser, requiredRoles)) {
                      return;
                    }
                    return (
                      <li 
                        className="nav-item" 
                        key={item.key} 
                        onClick={event => {
                          event.stopPropagation(); 
                          setUserDropdownOpen(false);
                        }}
                      >
                        <Link 
                          to={item.key} 
                          className={clsx("nav-link", selected && "active")}
                          data-tip={t(item.title)}
                          data-for={item.key + '-tooltip'}
                          onClick={() => {
                            setSidebarCollapsed(true);
                          }}
                        >
                          <span 
                            className="nav-item-icon"
                          >
                            {item.icon}
                            {
                              (item.key == AdminPropertyListingPath && propertyListingPendingItemsCount) ? (
                                <Badge bg="warning" pill style={{position: "absolute", fontSize: "0.5em", top: "-7px", right: "7px"}}>{propertyListingPendingItemsCount}</Badge>
                              ) : <></>
                            }
                          </span>
                          <span className="nav-item-title">{t(item.title)}</span>
                          <ReactTooltip 
                            id={item.key + '-tooltip'}
                            className="nav-item-tooltip"
                            place="right" 
                            type="dark" 
                            effect="solid"
                            delayShow={selected ? 1000 : 0}
                            delayHide={100}
                            disable={!sidebarCollapsed}
                          />
                        </Link>
                      </li>
                    )
                  })
                }
                
                {/* <span className="admin-siderbar-minifier"
                  onClick={() => {
                    const newVal = !sidebarCollapsed;
                    window.scrollTo({
                      left: 0,
                      behavior: newVal ? 'smooth' : 'instant' as any
                    });
                    
                    setSidebarCollapsed(newVal);
                    localCache.set({
                      sidebarCollapsed: newVal
                    })
                  }}
                >
                  <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </span> */}
              </ul>
              
              <hr />
              <div className="dropdown footer"
                onClick={(event) => {
                  event.stopPropagation();
                  setUserDropdownOpen(flag => !flag);
                }}
              >
                <div 
                  className="admin-user"
                  aria-expanded="false"
                  
                >
                  <span className="admin-user-icon"><FontAwesomeIcon icon={faUserCircle}/></span>
                  <strong className="admin-user-display-name">
                    {(adminUser?.username || adminUser?.email)}
                  </strong>
                  <FontAwesomeIcon icon={faCaretDown} size="xs" className="admin-user-caret  ms-1" />
                </div>
                <ul 
                  className={clsx("dropdown-menu dropdown-menu-dark text-small shadow", userDropdownOpen && "show")}
                  onClick={() => {
                    setUserDropdownOpen(false);
                  }}
                >
                  <li>
                    <Link to={`../${AdminUserPath}/me`} className="dropdown-item">
                      <FontAwesomeIcon icon={faUser} className="me-3" fixedWidth/>
                      <b>{(adminUser?.username || adminUser?.email)}</b>
                    </Link>
                  </li>
                  <li><hr className="dropdown-divider my-1"></hr></li>
                  <li>
                    <Link to={`/${currentLang}`} className="dropdown-item" target="_blank">
                      <FontAwesomeIcon icon={faHome} className="me-3" fixedWidth/>
                      {t('commons.home')}
                    </Link>
                  </li>

                  <li>
                    <div
                      className="dropdown-item"
                      style={{cursor: "pointer"}}
                      onClick={() => {
                        openLanguageSelector();
                      }}
                    >
                      <FontAwesomeIcon icon={faGlobe} className="me-3" fixedWidth/>{t('commons.language')}
                    </div>
                  </li>

                  <li><hr className="dropdown-divider my-1"></hr></li>
                  <li>
                    <Link to={`${AdminLogoutPath}`} className="dropdown-item">
                      <FontAwesomeIcon icon={faArrowRightFromBracket} className="me-3" fixedWidth/>
                      {t('login.logout')}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            
            {children}
          </main>
          
        }
      </AdminComponentContext.Provider>
    )
  }
}

export const AdminSidebarToggleButton = (props: {
  className?: string
}) => {
  const {toggleSidebar} = useAdminComponent();
  return (
    <div
      className="sidebar-toggle-btn"
      onClick={(event) => {
        event.stopPropagation();
        toggleSidebar();
      }}
    >
      <FontAwesomeIcon icon={faBars} />
    </div>
  )
}

export const AdminHeaderTitle = (props: {
  children: string
}) => {
  const {t} = useI18N();
  return (
    <Helmet>
      {
        <title>{`${props.children} | ${t('admin.title')} | ${t('title')}`}</title>
      }
      
    </Helmet>
  )
}

AdminComponent.Container = (props: React.ComponentPropsWithoutRef<"div"> & {
  name?: string,
}) => {
  const {name, ...divProps} = props;
  return (
    <div {...divProps} 
      className={clsx(["admin-container container-fluid", props.className])}
      data-name={props.name}
    >
      {props.children}
    </div>
  )
}

AdminComponent.TitleBar = (props: React.ComponentPropsWithoutRef<"div">) => {
  return (
    <div {...props} className={clsx(["admin-container-title-bar", props.className])}>
      {props.children}
    </div>
  )
}

export const AdminComponentTitle = (props: React.ComponentPropsWithoutRef<"div"> & {
  backTo?: string;
  onBackToOverride?: () => void;
  faIcon?: IconDefinition,
  hideSidebarToggleButton?: boolean,
}) => {
  const navigate = useNavigate();
  return (
    <div className={clsx(["admin-container-title", props.className])}>
      {
        !props.hideSidebarToggleButton && (
          <AdminSidebarToggleButton />
        )
      }
      {
        props.backTo && (
          <Link
            onClick={(event) => {
              if (props.onBackToOverride) {
                event.preventDefault();
                props.onBackToOverride?.();
              } else {
                if (window.history.state && window.history.state?.idx > 0) {
                  event.preventDefault();
                  navigate(-1);
                  // window.history.back();
                }
              }
              
            }}
            to={props.backTo}
            className="back-btn"
          >
            <FontAwesomeIcon icon={faAngleLeft} fixedWidth />
          </Link>
        )
      }
      {
        props.faIcon && <FontAwesomeIcon icon={props.faIcon} className="admin-container-title-icon" />
      }
      {props.children}
    </div>
  )
}


export {AdminComponent};
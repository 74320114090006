import { IsString, IsInt, IsOptional, IsNumber, IsBoolean, IsEnum } from "class-validator";
import { Building, BuildingType, BuildingTypes } from "../building.entity";
import { UserPreview } from "../user.entity";
import { HidePublic, ShowPublicMatchKeyTarget } from "../utils/hide-public";
import { ApiProperty, Column, Entity, ManyToOne, OmitType, PickType, Relation } from "../vars";
import { PropertyDecoration } from "./property-decoration.entity";
import { PropertySectionAuditable } from "./property-section-auditable.entity";
import { PropertyView } from "./property-view.entity";
import { Property } from "./property.entity";

export const PropertyDirections = [
  "East", "South", "West", "North", 
  "NorthEast", "SouthEast", "SouthWest", "NorthWest",
] as const;

export type PropertyDirection = (typeof PropertyDirections)[number];

export const PropertyFurnishTypes = [
  "None",
  "Fully",
  "Partially",
  "Optional",
  "Unknown",
] as const;

export type PropertyFurnishType = (typeof PropertyFurnishTypes)[number];

@Entity()
export class PropertyBasicInfo extends PropertySectionAuditable {

  constructor(partial?: Partial<PropertyBasicInfo>) {
    super();
    Object.assign(this, partial);
    // this.postConstructor();
  }

  // @ApiProperty({type: () => Property})
  @ManyToOne(() => Property, {
    nullable: false,
    onUpdate: "CASCADE",
    onDelete: "CASCADE"
  }, )
  property: Relation<Property>;
  
  @ApiProperty()
  @ManyToOne(() => Building, {
    nullable: true,
    onUpdate: "CASCADE",
    onDelete: "SET NULL"
  })
  building: Building = null;

  @ApiProperty()
  @IsEnum(BuildingTypes)
  @IsOptional()
  @Column("enum", {enum: BuildingTypes, nullable: true})
  buildingType: BuildingType = null;

  @ShowPublicMatchKeyTarget("block")
  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 32,
    nullable: false,
    default: ""
  })
  block: string = "";

  @HidePublic()
  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 32,
    nullable: true
  })
  floor: string = ""

  @ShowPublicMatchKeyTarget("flat")
  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 16,
    nullable: false,
    default: ""
  })
  flat: string = "";

  @ApiProperty()
  @IsInt()
  @IsOptional()
  @Column("int", {
    nullable: true
  })
  grossArea: number = null;

  @ApiProperty()
  @IsInt()
  @IsOptional()
  @Column("int", {
    nullable: true
  })
  netArea: number = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  managementFee: number = null;

  @ApiProperty()
  @IsBoolean()
  @Column("boolean", {
    nullable: false,
    default: false,
  })
  managementFeeIncluded: boolean = false;
  
  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  airConditionerFee: number = null;

  @ApiProperty()
  @IsBoolean()
  @Column("boolean", {
    nullable: false,
    default: false,
  })
  airConditionerFeeIncluded: boolean = false;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  rates: number = null;

  @ApiProperty()
  @IsBoolean()
  @Column("boolean", {
    nullable: false,
    default: false,
  })
  ratesIncluded: boolean = false;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  govRent: number = null;

  @ApiProperty()
  @IsBoolean()
  @Column("boolean", {
    nullable: false,
    default: false,
  })
  govRentIncluded: boolean = false;

  @ApiProperty()
  @IsBoolean()
  @Column("boolean", {
    nullable: false,
    default: false,
  })
  hasKey: boolean = false;

  @HidePublic()
  @ApiProperty()
  @IsString()
  @Column("varchar", {
    nullable: false,
    default: "",
  })
  keyNumber: string = "";

  @HidePublic()
  @ApiProperty()
  @IsBoolean()
  @Column("boolean", {
    nullable: false,
    default: false,
  })
  isKeyAtManagement: boolean = false;

  @ApiProperty()
  @ManyToOne(() => PropertyView, {
    nullable: true,
    onUpdate: "CASCADE",
    onDelete: "SET NULL",
  })
  view: PropertyView = null;

  @ApiProperty()
  @ManyToOne(() => PropertyDecoration, {
    nullable: true,
    onUpdate: "CASCADE",
    onDelete: "SET NULL",
  })
  decoration: PropertyDecoration = null;

  @ApiProperty()
  @IsEnum(PropertyDirections)
  @IsOptional()
  @Column("enum", {
    enum: PropertyDirections,
    nullable: true
  })
  direction: PropertyDirection = null;

  @ApiProperty()
  @IsEnum(PropertyFurnishTypes)
  @IsOptional()
  @Column("enum", {
    enum: PropertyFurnishTypes,
    nullable: false,
    default: "None"
  })
  furnish: PropertyFurnishType = "None";

  @ApiProperty()
  @IsInt()
  @IsOptional()
  @Column("smallint", {
    nullable: true
  })
  bedroom: number = null;

  @ApiProperty()
  @IsInt()
  @IsOptional()
  @Column("smallint", {
    nullable: true
  })
  livingRoom: number = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  balconyArea: number = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  gardenArea: number = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  terraceArea: number = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  roofArea: number = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  loftArea: number = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  carportArea: number = null;

  @ApiProperty()
  @IsInt()
  @IsOptional()
  @Column("smallint", {
    nullable: true
  })
  coveredCarParkCount: number = null;

  @HidePublic()
  @ApiProperty()
  @IsString()
  @IsOptional()
  @Column("text")
  coveredCarParkInfo: string = "";

  @ApiProperty()
  @IsInt()
  @IsOptional()
  @Column("smallint", {
    nullable: true
  })
  uncoveredCarParkCount: number = null;

  @HidePublic()
  @ApiProperty()
  @IsString()
  @IsOptional()
  @Column("text")
  uncoveredCarParkInfo: string = "";

}

export class PropertyBasicInfoDto extends OmitType(
  PropertyBasicInfo, ["id", "property", "updated", "updatedBy", "building", "view", "decoration"] as const,
) {
  @ApiProperty()
  @IsInt()
  @IsOptional()
  buildingId: number = null;

  @ApiProperty()
  @IsString()
  @IsOptional()
  viewNameEn: string = null;

  @ApiProperty()
  @IsString()
  @IsOptional()
  decorationNameEn: string = null;
}

export class PropertyBasicInfoPublic extends PickType(
  PropertyBasicInfo, [
    "buildingType", 
    "block", "floor", "flat",
    "grossArea", "netArea", 
    "managementFee",
    "view", "decoration", "direction", "furnish",
  ]
) {
  constructor(partial?: Partial<PropertyBasicInfoPublic>) {
    super();
    Object.assign(this, partial);
    // this.postConstructor();
  }
}
import { faFolderTree } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminSystemFileManagerPath } from "..";
import { FileManager } from "../../../component/file-manager";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";


export const AdminFileManager = (props: React.PropsWithChildren<{
  basePath: string,
  awsS3Path?: string,
}>) => {

  const location = useLocation();
  const {t} = useI18N();
  const {api, setSelectedItem} = useAdminComponent();
  const modal = useGlobalModal();
  const navigate = useNavigate();

  const initialPath = useMemo(() => {
    console.log(location);
    const initialPath = new URLSearchParams(location.search).get("path");
    console.log("initialPath: " + initialPath);
    return initialPath;
  }, [])

  useEffect(() => {
    setSelectedItem(AdminSystemFileManagerPath);
  }, []);
  
  return (
    
    <AdminComponent.Container>
      
      <AdminHeaderTitle>{t('admin.fileManager.title')}</AdminHeaderTitle>
      <AdminComponent.TitleBar>
        <AdminComponentTitle faIcon={faFolderTree}>
          {t('admin.fileManager.pageTitle')}
        </AdminComponentTitle>
      </AdminComponent.TitleBar>

      <div className="hr" />
      <div className="flex-grow-1" style={{height: 0}}>
        <FileManager
          basePath={props.basePath}
          awsS3Path={props.awsS3Path}
          api={api.fileManager}
          initialPath={initialPath}
          onPathChange={(newPath) => {
            navigate(newPath ? ("?path=" + encodeURIComponent(newPath)) : "");
          }}
          onCreateFolder={() => {
            return modal.prompt("Create New Folder", {
              type: "text", placeholder: "New folder name"
            }, true);
          }}
          onRename={(originalName) => {
            return modal.prompt("Rename", {
              type: "text", placeholder: "New name", initialValue: originalName
            }, true);
          }}
          onOverwrite={() => {
            return modal.confirm("Overwrite confirm", "File(s) exist. Press 'Yes' to overwrite, 'No' to keep both");
          }}
          onDeleteConfirm={() => {
            return modal.confirm("Confirm delete", "Are you sure to delete the file?");
          }}
          onError={(e) => {
            modal.errorSpecific(e);
          }}
          // onOpen={(src)=> {
          //   openFileViewer(src);
          // }}
        />
      </div>
      
    </AdminComponent.Container>
  )
}
import { Exclude, Type } from "class-transformer";
import { User } from "../user.entity";
import { Column, Entity, JoinColumn, ManyToOne, PrimaryColumn, Relation } from "../vars";
import { PropertyPublishmentTag } from "./property-publishment-tag.entity";
import { PropertyPublishment } from "./property-publishment.entity";


@Entity()
export class PropertyPublishmentToTag {
  
  @Exclude()
  @PrimaryColumn("int")
  propertyPublishmentId?: PropertyPublishment["propertyId"];
  
  @ManyToOne(() => PropertyPublishment, p => p.contactUsers, {
    onUpdate: "CASCADE",
    onDelete: "CASCADE", 
  })
  @JoinColumn({name: "propertyPublishmentId"})
  @Type(() => PropertyPublishment)
  propertyPublishment?: PropertyPublishment;

  // @Exclude()
  @PrimaryColumn("varchar")
  tagId: PropertyPublishmentTag["nameEn"]

  @ManyToOne(() => PropertyPublishmentTag, {
    onUpdate: "CASCADE",
    onDelete: "CASCADE", 
  })
  @JoinColumn({name: "tagId"})
  tag?: PropertyPublishmentTag;

  @Column("smallint", {
    default: 0
  })
  order?: number;
}
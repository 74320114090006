import { AxiosInstance, AxiosProgressEvent, AxiosRequestConfig } from "axios";
import { Building, BuildingDto, BuildingImageCreator, BuildingImageRenameDto, BuildingSort, BuildingStreetName } from "../entities/building.entity";
import { FindAllQuery } from "../entities/findAllQuery";
import { I18N, I18NDto } from "../entities/i18n.entity";
import { Paginated } from "../entities/pagination";
import { S3File } from "../entities/s3-file-system";
import { APIBase } from "./apiBase";
import { APIEncodeQueryString } from "./helpers";

export default class BuildingsAPI extends APIBase {
  

  constructor(axios: AxiosInstance) {
    super(axios, "buildings");
  }

  async findAll(query: FindAllQuery<BuildingSort>, config?: AxiosRequestConfig): Promise<Paginated<Building>> {
    return (await this.axios.get<Paginated<Building>>(
      this.base + "?" + APIEncodeQueryString(FindAllQuery.toJson(query)), config
    )).data;
  }

  async findAllPublic(query: FindAllQuery<BuildingSort>, config?: AxiosRequestConfig): Promise<Paginated<Building>> {
    return (await this.axios.get<Paginated<Building>>(
      this.base + "/public?" + APIEncodeQueryString(FindAllQuery.toJson(query)), config
    )).data;
  }

  async findAllStreetNames(query: FindAllQuery, config?: AxiosRequestConfig): Promise<Paginated<BuildingStreetName>> {
    return (await this.axios.get<Paginated<BuildingStreetName>>(
      this.base + "/street-name?" + APIEncodeQueryString(FindAllQuery.toJson(query)), config
    )).data;
  }


  async findById(id: number, config?: AxiosRequestConfig): Promise<Building> {
    return (await this.axios.get<Building>(
      this.base + "/" + id, config
    )).data;
  }

  async update(id: number, dto: BuildingDto): Promise<Building> {
    return (await this.axios.put<Building>(
      this.base + "/" + id,
      dto
    )).data;
  }

  async create(dto: BuildingDto): Promise<Building> {
    return (await this.axios.post<Building>(
      this.base,
      dto
    )).data;
  }

  async delete(id: number): Promise<void> {
    return (await this.axios.delete<void>(
      this.base + "/" + id
    )).data;
  }

  async uploadImage(
    id: number,
    file: File,
    onUploadProgress?: (e: AxiosProgressEvent) => void,
    config?: AxiosRequestConfig
  ) {
    let formData = new FormData();
    formData.append("file", file);
    return (await this.axios.post<any>(
      this.base + "/" + id + "/upload-image",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: onUploadProgress,
        ...config,
      }
    )).data;
  }

  async listImages(id: number, config?: AxiosRequestConfig) {
    return (await this.axios.get<S3File[]>(
      this.base + "/" + id + "/images", config
    )).data;
  }

  async downloadImage(id: number, filename: string, config?: AxiosRequestConfig) {
    return (await this.axios.get<any>(
      this.base + "/" + id + "/image/" + filename, config
    )).data;
  }

  async getImageCreator(id: number, filename: string, config?: AxiosRequestConfig) {
    return (await this.axios.get<BuildingImageCreator>(
      this.base + "/" + id + "/image-creator/" + filename, config
    )).data;
  }

  async renameImage(id: number, filename: string, dto: BuildingImageRenameDto, config?: AxiosRequestConfig) {
    return (await this.axios.patch<void>(
      this.base + "/" + id + "/image/" + filename, dto, config
    )).data;
  }

  async presignImage(id: number, filename: string, config?: AxiosRequestConfig) {
    return (await this.axios.get<string>(
      this.base + "/" + id + "/image-presigned/" + filename, config
    )).data;
  }

  async deleteImage(id: number, filename: string, config?: AxiosRequestConfig) {
    return (await this.axios.delete<void>(
      this.base + "/" + id + "/image/" + filename, config
    )).data;
  }

}
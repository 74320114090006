import { useMemo } from "react";
import { FilePreviewByFileTypeProps, useFileViewer } from "../FileViewer";
import {OBJModel} from 'react-3d-viewer';

export const FileViewerModel = (props: FilePreviewByFileTypeProps) => {
  const {src, axios, onFileLoad, onFileError, ...othersProps} = props;
  const {getFullSrc} = useFileViewer();
  let fullSrc = useMemo(() => {
    return getFullSrc(src);
  }, [src])

  return (
    <div>
      <OBJModel 
        width="400" height="400"  
        position={{x:0,y: -130,z:-100}} 
        src={fullSrc}
        onLoad={()=>{
          //...
          console.log("Onload");
          onFileLoad()
        }}
        onProgress={xhr=>{
          //...
        }}
      />
    </div>
  )
}
import { IsInt, IsNotEmpty, IsOptional, IsString } from "class-validator";
import { ApiProperty, Column, Entity, OmitType, PrimaryColumn } from "../vars";


@Entity()
export class PropertyDecoration {

  @ApiProperty()
  @IsString()
  @IsNotEmpty()
  @PrimaryColumn("varchar", {
    length: 32,
    nullable: false
  })
  nameEn: string;

  @ApiProperty()
  @IsString()
  @IsNotEmpty()
  @Column("varchar", {
    length: 32,
    nullable: false,
    // unique: true,
  })
  nameTc: string;

  @ApiProperty()
  @IsString()
  @IsNotEmpty()
  @Column("varchar", {
    length: 32,
    nullable: false,
    // unique: true,
  })
  nameSc: string;

  @ApiProperty()
  @IsInt()
  @IsOptional()
  @Column("smallint", {
    nullable: true
  })
  order?: number;
}

export class PropertyDecorationDto extends OmitType(PropertyDecoration, ["order"]) {}
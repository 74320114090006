import { faArrowsLeftRightToLine, faDisplay } from "@fortawesome/free-solid-svg-icons";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { useI18N } from "../../../component/I18NProvider";
import { ScreenSize, useScreenSize } from "../../../utils/use-screen-size";

export const ScreenSizeButton = (props: {
  screenSize: ScreenSize,
  toggleScreenSize: () => void,
}) => {
  const {t} = useI18N();
  const {screenSize, toggleScreenSize} = props;
  return (
    <ButtonWithLoader 
      faIcon={faArrowsLeftRightToLine} 
      size="sm" 
      className="d-none d-md-block"
      variant={screenSize == "full" ? "info" : screenSize == "wide" ? "success" : "warning"}
      onClick={() => {
        toggleScreenSize();
      }}
    >
      {t("commons.screen." + screenSize)}
    </ButtonWithLoader>
  )
}
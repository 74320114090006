import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { ComponentPropsWithoutRef, DOMAttributes, HTMLAttributes } from "react";
import { Button, ButtonProps, Spinner } from "react-bootstrap";
import {
  IconProp,
} from '@fortawesome/fontawesome-svg-core';
import { Link, LinkProps } from "react-router-dom";

export type ButtonWithLoaderProps = {
  loading?: boolean
  faIcon?: IconProp
} & ButtonProps;

export const ButtonWithLoader = (props: ButtonWithLoaderProps) => {

  let {loading, disabled, className, faIcon, children, ...otherProps} = props;

  return (
    <Button
      disabled={loading || disabled}
      {...otherProps}
      className={clsx("text-nowrap", props.className || "px-3")}
    >
      <div className="d-flex align-items-center justify-content-center" style={{gap: "0.5rem"}}>
        {
          !loading ? (
            faIcon && <FontAwesomeIcon icon={faIcon} fixedWidth  />
          ) : 
          <Spinner animation="border" size="sm" />
        }
        {children}
      </div>
      
    </Button>
  )
}


export type LinkWithLoaderProps = {
  loading?: boolean
  disabled?: boolean
  faIcon?: IconProp
} & LinkProps;

export const LinkWithLoader = (props: LinkWithLoaderProps) => {

  let {loading, disabled, className, faIcon, children, ...otherProps} = props;

  return (
    <Link
      {...otherProps}
      className={clsx("px-3 text-nowrap", (loading || disabled) && "disabled", props.className)}
    >
      <div className="d-flex align-items-center justify-content-center" style={{gap: "0.5rem"}}>
        {
          !loading ? (
            faIcon && <FontAwesomeIcon icon={faIcon} fixedWidth  />
          ) : 
          <Spinner animation="border" size="sm" />
        }
        {children}
      </div>
    </Link>
  )
}
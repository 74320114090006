import { Transform, Type } from "class-transformer";
import { IsDate, IsOptional } from "class-validator";
import { TransformUserPreview, User, UserPreview } from "./user.entity";
import { ApiProperty, BaseEntity, CreateDateColumn, ManyToOne, UpdateDateColumn } from "./vars";


export class TimestampEntityWithUser extends BaseEntity {
    
  @ApiProperty()
  @IsDate()
  @Transform(({value}) => new Date(value))
  @IsOptional()
  @CreateDateColumn({ type: "timestamp", precision: 3, default: () => "CURRENT_TIMESTAMP(3)" })
  public created: Date;

  @ApiProperty()
  @ManyToOne(() => User, {
    onUpdate: "CASCADE"
  })
  @TransformUserPreview()
  public createdBy: UserPreview;

  @ApiProperty()
  @IsDate()
  @Transform(({value}) => new Date(value))
  @IsOptional()
  @UpdateDateColumn({ type: "timestamp", precision: 3, default: () => "CURRENT_TIMESTAMP(3)", onUpdate: "CURRENT_TIMESTAMP(3)" })
  public updated: Date;

  @ApiProperty()
  @ManyToOne(() => User, {
    onUpdate: "CASCADE"
  })
  @TransformUserPreview()
  public updatedBy: UserPreview;

}
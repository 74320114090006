import { IsInt, IsNotEmpty, IsOptional, IsString } from "class-validator";
import { ApiProperty, Column, Entity, OmitType, PrimaryColumn } from "../vars";


@Entity()
export class PropertyPublishmentTag {

  @ApiProperty()
  @IsString()
  @IsNotEmpty()
  @PrimaryColumn("varchar", {
    length: 128,
    nullable: false
  })
  nameEn: string;

  @ApiProperty()
  @IsString()
  @IsNotEmpty()
  @Column("varchar", {
    length: 128,
    nullable: false,
    // unique: true,
  })
  nameTc: string;

  @ApiProperty()
  @IsString()
  @IsNotEmpty()
  @Column("varchar", {
    length: 128,
    nullable: false,
    // unique: true,
  })
  nameSc: string;

  @ApiProperty()
  @Column("int", {
    nullable: false,
    default: 0
  })
  count?: number = 0;
}

export class PropertyPublishmentTagDto extends OmitType(
  PropertyPublishmentTag, ["count"] as const,
) {}
import { IsBoolean, IsDate, IsEnum, IsInt, IsNumber, IsOptional, IsString } from "class-validator";
import { User } from "../user.entity";
import { TransformDate } from "../utils/date";
import { HidePublic, ShowPublicMatchKeySource } from "../utils/hide-public";
import { ApiProperty, Column, Entity, JoinColumn, ManyToMany, ManyToOne, OmitType, OneToMany, OneToOne, PrimaryColumn, UpdateDateColumn } from "../vars";
import { PropertyPublishmentContactUser } from "./property-publishment-contact-user.entity";
import { PropertyPublishmentTag } from "./property-publishment-tag.entity";
import { PropertyPublishmentToTag } from "./property-publishment-to-tag.entity";
import { Property, PropertyPublicClone } from "./property.entity";
import { Transform } from "class-transformer";


export const PropertyPublishmentStates = [
  "unpublished",
  "pending",
  "published"
] as const;

export type PropertyPublishmentState = (typeof PropertyPublishmentStates)[number];

export const PropertyPublishmentFloors = [
  "high",
  "mid",
  "low"
] as const;

export type PropertyPublishmentFloor = (typeof PropertyPublishmentFloors)[number];


export abstract class PropertyPublishmentBase {

  @PrimaryColumn("int")
  propertyId: Property["id"];
  

  @OneToOne(() => Property, property => property.publishment, {
    onUpdate: "CASCADE",
    onDelete: "CASCADE",
  })
  @JoinColumn({name: "propertyId"})
  property?: Property;


  @ApiProperty()
  @IsEnum(PropertyPublishmentStates)
  @Column("enum", {
    enum: PropertyPublishmentStates,
    default: "unpublished"
  })
  state: PropertyPublishmentState;

  @ApiProperty()
  @IsString()
  @Column("text")
  titleEn: string = "";

  @ApiProperty()
  @IsString()
  @Column("text")
  titleTc: string = "";

  @ApiProperty()
  @IsString()
  @Column("text")
  titleSc: string = "";


  @ApiProperty()
  @IsString()
  @Column("text")
  descriptionEn: string = "";

  @ApiProperty()
  @IsString()
  @Column("text")
  descriptionTc: string = "";

  @ApiProperty()
  @IsString()
  @Column("text")
  descriptionSc: string = "";

  @ApiProperty()
  @OneToMany(() => PropertyPublishmentContactUser, (contactUser) => contactUser.propertyPublishment, {
    eager: false, cascade: false
  })
  contactUsers: PropertyPublishmentContactUser[];

  @ApiProperty()
  @OneToMany(() => PropertyPublishmentToTag, (tag) => tag.propertyPublishment, {
    eager: false, cascade: false
  })
  tags: PropertyPublishmentToTag[];

  @ApiProperty()
  @IsString()
  @Column("text")
  googleMapQuery: string = "";

  @ApiProperty()
  @IsString({each: true})
  @Column("simple-array")
  @Transform(({value}: {value: string[]}) => value.map(str => encodeURIComponent(str)), {toClassOnly: true})
  @Transform(({value}: {value: string[]}) => value.map(str => decodeURIComponent(str)), {toPlainOnly: true})
  photos: string[] = [];
  
  @ApiProperty()
  @TransformDate()
  @IsDate()
  @IsOptional()
  @Column({
    type: "timestamp",
    precision: 3,
    nullable: true,
    default: null,
  })
  highlightedDate?: Date | null;

  @ApiProperty()
  @TransformDate()
  @IsDate()
  @IsOptional()
  @Column({
    type: "timestamp",
    precision: 3,
    nullable: true,
    default: null,
  })
  highlighted2Date?: Date | null;

  @ShowPublicMatchKeySource("block")
  @ApiProperty()
  @IsBoolean()
  @Column({
    type: "boolean",
    default: false
  })
  showBlock?: boolean = false;

  @ApiProperty()
  @IsEnum(PropertyPublishmentFloors)
  @IsOptional()
  @Column({
    type: "enum",
    enum: PropertyPublishmentFloors,
    nullable: true,
    default: null
  })
  floor?: PropertyPublishmentFloor | null = null;

  @ShowPublicMatchKeySource("flat")
  @ApiProperty()
  @IsBoolean()
  @Column({
    type: "boolean",
    default: false
  })
  showFlat?: boolean = false;


  @ApiProperty()
  @Column({
    type: "timestamp",
    precision: 3,
    nullable: true,
  })
  publishedDate?: Date;
  
  @HidePublic()
  @ApiProperty()
  @ManyToOne(() => User)
  publishedBy?: User;

  @ApiProperty()
  @Column({
    type: "timestamp",
    precision: 3
  })
  updated?: Date;

  @HidePublic()
  @ApiProperty()
  @ManyToOne(() => User)
  updatedBy?: User;

}

@Entity()
export class PropertyPublishment extends PropertyPublishmentBase {
  @OneToOne(() => Property, property => property.publishment, {
    onUpdate: "CASCADE",
    onDelete: "CASCADE",
  })
  @JoinColumn({name: "propertyId"})
  property?: Property;
}

@Entity()
export class PropertyPublishmentPublicClone extends PropertyPublishmentBase {
  @OneToOne(() => PropertyPublicClone, property => property.publishment, {
    onUpdate: "CASCADE",
    onDelete: "CASCADE",
  })
  @JoinColumn({name: "propertyId"})
  property?: PropertyPublicClone;
}

export class PropertyPublishmentDto extends OmitType(
  PropertyPublishment, [
    "property", "propertyId", "contactUsers", "tags", "publishedDate", "publishedBy", "updatedBy",
  ] as const
) {
  @ApiProperty()
  @IsInt({each: true})
  contactUserIds?: User["id"][] = [];

  @ApiProperty()
  @IsString({each: true})
  tagIds?: PropertyPublishmentTag["nameEn"][] = [];
}
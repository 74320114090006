import clsx from "clsx";
import { useState, useEffect, useMemo } from "react";
import { FilePreviewByFileTypeProps, useFileViewer } from "../FileViewer";
import { emptyImageSrc } from "./emptyImageSrc";
import { loadingImageSrc } from "./loadingImageSrc";

export const FileViewerImage = 
  (props: React.HTMLAttributes<HTMLImageElement> & FilePreviewByFileTypeProps & {
    containerClassName?: string,
  }) => {

  const {src, axios, onFileLoad, onFileError, containerClassName, ...othersProps} = props;

  const [mode, setMode] = useState<'loading' | 'done' | 'error'>('loading');
  const {getFullSrc} = useFileViewer();

  if (!getFullSrc) {
    throw new Error("FileViewerContext is null, propably the parent of this element is not bounded with <FileViewerProvider />")
  }
  
  useEffect(() => {

  }, [src])


  let fullSrc = useMemo(() => {

    // console.log("src", src);
    if (!src) {
      setMode('error');
    } else {
      // console.log("Loading image: " + src);
      setMode('loading');
      return getFullSrc(src);
    }
  }, [src])

  return (
    <div className={clsx("file-viewer-image d-flex align-items-center justify-content-center", containerClassName)}>
      {
        fullSrc && (<img 
          src={fullSrc} 
          onLoad={() => {
            // console.log("ONLOAD!!");
            onFileLoad && onFileLoad()
            setMode('done');
          }} 
          onError={(e) => {
            setMode('error');
            console.error("ERROR");
            console.error(e);
            onFileError && onFileError("This image cannot be previewed")
          }}
          {...othersProps}
          className={clsx(mode !== 'done' && "collapsed", othersProps.className)} 
        />)
      }
      {
        mode === 'loading' && (
          <img 
            src={loadingImageSrc} 
            {...othersProps}
            className={clsx("no-image loading", othersProps.className)} 
          />
        )
      }
      {
        mode === 'error' && (
          <img 
            src={emptyImageSrc} 
            {...othersProps}
            className={clsx( "no-image", othersProps.className)} 
          />
        )
      }
      
    </div>
  )
}
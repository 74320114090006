import { IsEnum, IsNotEmpty, IsOptional, IsString } from "class-validator";
import { ApiProperty } from "./vars";

// https://developers.google.com/maps/documentation/places/web-service/supported_types#table2
export const GoogleApisMapsPlaceAutocompleteTypes = [
  "neighborhood",       // District
  "route",              // Street name
  "establishment",      // Building
  "premise",            // Building sub-block
  "point_of_interest",  // General building
  "geocode",            // Mix of "premise", "route",
  "lodging",            // Hotel
] as const;

export type GoogleApisMapsPlaceAutocompleteType = (typeof GoogleApisMapsPlaceAutocompleteTypes)[number];

export const GoogleApisMapsPlaceAutocompleteTypePreset: {
  [key: string]: GoogleApisMapsPlaceAutocompleteType[]
} = {
  District: ["neighborhood"],
  Street: ["route"],
  Building: ["establishment"],
  BuildingAndDiv: ["establishment", "premise"],
  BuildingGeneral: ["establishment", "premise", "point_of_interest"]
}

export class GoogleApisMapsPlaceAutocompleteDto {
  @ApiProperty()
  @IsString()
  @IsNotEmpty()
  input: string;

  @ApiProperty({
    required: false
  })
  @IsString()
  @IsOptional()
  sessiontoken?: string;

  @ApiProperty({
    required: false
  })
  @IsString()
  @IsOptional()
  components?: string;

  @ApiProperty({
    required: false
  })
  @IsString()
  @IsOptional()
  language?: string;

  @ApiProperty({
    required: false,
    enum: GoogleApisMapsPlaceAutocompleteTypes,
    isArray: true
  })
  @IsEnum(GoogleApisMapsPlaceAutocompleteTypes, {each: true})
  @IsOptional()
  types?: GoogleApisMapsPlaceAutocompleteType[];
}

export class GoogleApisMapsPlaceAutocompletePrediction {
  @ApiProperty()
  description: string;
  @ApiProperty()
  placeId: string;
}

export class GoogleApisMapsPlaceAutocompleteResult {
  @ApiProperty()
  predictions: GoogleApisMapsPlaceAutocompletePrediction[];
  @ApiProperty()
  sessiontoken?: string;
}

export class GoogleApisMapsPlaceNamesDto {
  @ApiProperty()
  @IsString()
  @IsNotEmpty()
  placeId: string;

  @ApiProperty({
    required: false
  })
  @IsString()
  @IsOptional()
  sessiontoken?: string;
}

export class GoogleApisMapsPlaceNamesResult {
  @ApiProperty()
  nameTc: string
  @ApiProperty()
  nameEn: string
  @ApiProperty()
  nameSc: string
  @ApiProperty()
  urlTc: string
  @ApiProperty()
  urlEn: string
  @ApiProperty()
  sessiontoken?: string;
}
import clsx from "clsx";
import { ComponentPropsWithRef, useEffect, useId, useMemo, useRef, useState } from "react";

let STATIC_COUNT = 0;

export const GoogleRecaptchaV2 = (props: {
  siteKey: string,
  executeRef?: (execute: () => Promise<string>) => void,
  resetRef?: (reset: () => void) => void,
  onload?: () => void;
  type: "checkbox" | "invisible"
  languageCode?: string,
} & ComponentPropsWithRef<"div">) => {

  const {siteKey, executeRef, resetRef, onload, type, languageCode, ...divProps} = props;
  
  const script = useRef<HTMLScriptElement>(null);
  const handleResponseResolveRef = useRef<(value: string | PromiseLike<string>) => void>(null);
  const handleResponseRejectRef = useRef<() => void>(null);
  const id = useMemo(() => {
    ++STATIC_COUNT;
    return `google_recaptcha_${STATIC_COUNT}`;
  }, [])
  const widgetId = useRef(null);
  const onloadFunctionName = "handleGoogleRecaptchaOnload_" + id;

  useEffect(() => {
    (window as any)[onloadFunctionName] = async () => {
      widgetId.current = (window as any).grecaptcha.render(
        id, {
          sitekey: siteKey,
          size: type,
          callback: (response: any) => {
            // console.log(response);
            handleResponseResolveRef.current?.(response);
          },
          "error-callback": () => {
            console.log("ERR-callback");
            handleResponseRejectRef.current?.();
          }
        }
      )
      onload?.();
    }

    executeRef?.(async() => {
      const grecaptcha = (window as any).grecaptcha;
      return new Promise((resolve, reject) => {
        const existingResponse = grecaptcha.getResponse(widgetId.current) as string;
        if (type == "checkbox" || existingResponse && existingResponse != "") {
          resolve(existingResponse);
        } else {
          handleResponseRejectRef.current = reject;
          grecaptcha?.reset(widgetId.current);
          grecaptcha?.execute(widgetId.current).then(() => {
            handleResponseResolveRef.current = resolve;
          }).catch((e: any) => {
            console.log("ERR");
            reject(e);
          })
        }
      })
    });

    resetRef?.(() => {
      (window as any).grecaptcha?.reset(widgetId.current);
    })

    // setSigninWidth(divRef.current.offsetWidth);

    if (!script.current) {
      script.current = document.createElement("script");
      script.current.src = `https://www.google.com/recaptcha/api.js?render=explicit&onload=${onloadFunctionName}` + (
        languageCode ? `&hl=${languageCode}` : ""
      );
      script.current.async = true; 
      script.current.defer = true;
      script.current.onerror = () => {
        console.log("ERROR!");
      }
      document.head.appendChild(script.current);
    }
    

    return () => {
      delete (window as any)[onloadFunctionName];

      if (script.current) {
        try {
          document.head.removeChild(script.current);
        } catch (e) {
          console.warn(e);
        }
      }
    }
  }, [])

  return (
    <div id={id} {...divProps} className={clsx("d-flex justify-content-center", divProps.className)} />
  )
}
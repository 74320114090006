import { faMars, faSave, faSquarePhone, faTrashCan, faUserGear, faUserPlus, faVenus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AdminUserPath, AdminUsersPath } from "..";
import { GlobalModalError, useGlobalModal } from "../../../component/GlobalModal";
import { User, AdminRoles, AdminRole, AdminRoleSubTypes, AdminRoleSubType } from "../../../api/entities/user.entity";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";
import { EntityData } from "../component/EntityData";
import { DeepPartial } from "react-hook-form";
import { useI18N } from "../../../component/I18NProvider";
import { validatePhoneNumber } from "../../../utils/validate";
import { faWhatsappSquare } from "@fortawesome/free-brands-svg-icons";
import { UserAdminLicenseTypes } from "../../../api/entities/user-admin-info.entity";
import { AdminTable } from "../component/Table";
import { Checkbox } from "../../../component/Checkbox";
import { APIInstanceWithAuth } from "../../../api";
import { UserAdminRolePreset, UserAdminRolePresetDto } from "../../../api/entities/user-admin-role-preset.entity";
import CreatableSelect from "react-select/creatable";
import { useLoadingManager } from "../../../utils/loading-manager";
import { Spinner } from "react-bootstrap";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { LangDisplay } from "../../../utils/lang-map";
import { useAbortableLoading } from "../../../utils/abortable-loading";
import { UserProfileGenders } from "../../../api/entities/user-profile.entity";

export interface AdminUserProps {
  mode: 'create' | 'edit' | 'me';
}


const AdminRolesSelector = (props: {
  api: APIInstanceWithAuth,
  selectedRoles: AdminRole[]
  onChange?: (roles: AdminRole[]) => void
}) => {
  const {api, selectedRoles} = props;
  const modal = useGlobalModal();
  const {t} = useI18N();
  const [presets, setPresets] = useState<UserAdminRolePresetDto[] | null>(null);
  const [selectedPresetId, setSelectedPresetId] = useState<string | null>(null);
  const selectedPreset = useMemo(() => selectedPresetId ? presets?.find(preset => preset.id == selectedPresetId) : null, [presets, selectedPresetId]);
  const [presetInput, setPresetInput] = useState("");
  const presetLoading = useLoadingManager();

  const adminRolesMap: {
    [key: string]: Set<AdminRoleSubType>
  } = useMemo(() => {
    return AdminRoles.reduce((acc, role) => {
      for (const subType of AdminRoleSubTypes) {
        const match = role.match(new RegExp(`^(.*)(${subType})$`));
        const key = match?.[1];
        if (key) {
          if (!acc[key]) {
            acc[key] = new Set().add(subType);
          } 
          acc[key].add(subType);
          break;
        }
      }

      return acc;
    }, {})
  }, [])

  const editRoles = useCallback((mode: "add" | "delete" | "toggle", role: AdminRole) => {
    const selectedRoles = [...props.selectedRoles];
    const checked = selectedRoles.includes(role);
    if (mode == "add" && !checked) {
      selectedRoles.push(role);
      props.onChange?.(selectedRoles);
    } else if (mode == "delete" && checked) {
      const index = selectedRoles.indexOf(role);
      if (index != -1) {
        selectedRoles.splice(index, 1);
        props.onChange?.(selectedRoles);
      }
    } else if (mode == "toggle") {
      editRoles(checked ? "delete" : "add", role);
    }
  }, [props.selectedRoles]);

  const reloadPresets = useCallback(async() => {
    const token = presetLoading.start();
    try {
      setPresets(await api.users.getAllAdminRolePreset())
    } catch (e) {

    } finally {
      presetLoading.stop(token);
    }
    
  }, [])

  useEffect(() => {
    reloadPresets();
  }, []);

  const allSelected = useMemo(() => AdminRoles.every(role => props.selectedRoles.includes(role)),[props.selectedRoles]);
  const someSelected = useMemo(() => AdminRoles.some(role => props.selectedRoles.includes(role)),[props.selectedRoles])


  return <div className="d-flex flex-column w-100">
    {
      presets && (
        
      <div className="d-flex w-100 mb-2" style={{gap: "0.3rem"}}>
        <CreatableSelect
          className="flex-grow-1"
          value={selectedPreset ? {
            label: selectedPreset.id,
            value: selectedPreset
          }: null}
          options={presets.map(preset => ({label: preset.id, value: preset}))}
          placeholder={t("admin.user.adminRolesPreset.title")}
          noOptionsMessage={() => t("entityData.empty")}
          formatCreateLabel={(input) => `${t("entityData.create")} "${input}"`}
          isClearable
          isLoading={presetLoading.flag}
          isDisabled={presetLoading.flag}
          loadingMessage={() => (<Spinner animation="border"/>)}
          inputValue={presetInput}
          onInputChange={(newValue, {action}) => {
            setPresetInput(newValue);
          }}
          onChange={async(option, {action}) => {

            console.log(option, action);

            switch (action) {
              case "create-option": 
                const token = presetLoading.start();
                try {
                  const id = (option as any).value;
                  await api.users.updateAdminRolePreset({
                    id: (option as any).value,
                    adminRoles: selectedRoles
                  });
                  setSelectedPresetId(id);
                } catch (e) {
                  modal.errorSpecific(e);
                } finally {
                  presetLoading.stop(token);
                  reloadPresets()
                }
                
              break;

              case "select-option": 
                props.onChange?.(option.value.adminRoles);
                setSelectedPresetId(option.value.id);
              break;

              case "clear":
                setPresetInput("");
                setSelectedPresetId(null);
              break;
            }

          }}
        />
        <ButtonWithLoader loading={presetLoading.flag} disabled={!selectedPresetId} variant="success"
          onClick={async() => {
            const token = presetLoading.start();
            try {
              await api.users.updateAdminRolePreset({
                id: selectedPresetId, 
                adminRoles: selectedRoles
              });
              reloadPresets()
            } catch (e) {
              modal.errorSpecific(e);
            } finally {
              presetLoading.stop(token);
            }
          }}
        >
          {t("admin.user.adminRolesPreset.save")}
        </ButtonWithLoader>
        <ButtonWithLoader loading={presetLoading.flag} disabled={!selectedPresetId} variant="danger"
          faIcon={faTrashCan}
          onClick={async() => {
            const token = presetLoading.start();
            try {
              await api.users.deleteAdminRolePreset(selectedPresetId);
              setPresetInput("");
              setSelectedPresetId(null);
              reloadPresets()
            } catch (e) {
              modal.errorSpecific(e);
            } finally {
              presetLoading.stop(token);
            }
          }}
        >

        </ButtonWithLoader>
      </div>
      )
    }
    <div style={{
      width: "100%",
      overflowX: "auto",
      maxWidth: "calc(100vw - 4rem)",
      margin: "0 auto",
    }}>
      <AdminTable
        keys={AdminRoleSubTypes.reduce((acc, subType) => {
          const rolesWithThisSubType = Object.entries(adminRolesMap).reduce((acc, [key, subTypes]) => {
            if (subTypes.has(subType)) {
              const role = (key + subType) as AdminRole;
              acc.push(role);
              return acc;
            }
            return acc;
          }, []);
          const allThisSubTypeSelected = rolesWithThisSubType.every(role => props.selectedRoles.includes(role));
          const someThisSubTypeSelected = rolesWithThisSubType.some(role => props.selectedRoles.includes(role));
          // console.log(rolesWithSubType);
          acc[subType] = {
            content: <div>
              {subType}
              <Checkbox className="form-check-input ms-2"
                checked={allThisSubTypeSelected}
                indeterminate={someThisSubTypeSelected && !allThisSubTypeSelected}
                onChange={() => props.onChange?.([...props.selectedRoles.filter(
                  role => !rolesWithThisSubType.includes(role)
                ), ...(allThisSubTypeSelected ? [] : rolesWithThisSubType)])}
              />
            </div>
          }
          return acc;
        }, {
          key: {
            content: <div className="text-end"><Checkbox className="form-check-input ms-2"
              checked={allSelected}
              indeterminate={someSelected && !allSelected}
              onChange={() => props.onChange?.(allSelected ? [] : [...AdminRoles])}
            /></div>
          }
        })}
        rows={Object.entries(adminRolesMap).map(([key, subTypes]) => {
          const availableSubTypes = [...subTypes];
          const rolesWithThisKey = availableSubTypes.map(subType => (key + subType) as AdminRole);
          const allThisKeySelected = rolesWithThisKey.every(role => props.selectedRoles.includes(role));
          const someThisKeySelected = rolesWithThisKey.some(role => props.selectedRoles.includes(role));

          return {
            content: [...AdminRoleSubTypes].reduce((acc, subType) => {
              const role = (key + subType) as AdminRole;
              const checked = props.selectedRoles.includes(role);
              return {
                ...acc, 
                [subType]: (
                  availableSubTypes.includes(subType) ? (
                    <input className="form-check-input" type="checkbox" checked={checked}
                      onChange={() => {
                        editRoles("toggle", role);
                      }}
                    />
                  ) : (
                    "-"
                  )
                )
              }
            }, {
              key: <div className="text-end">
                {key}
                <Checkbox className="form-check-input ms-2" 
                  checked={allThisKeySelected}
                  indeterminate={someThisKeySelected && !allThisKeySelected}
                  onChange={() => props.onChange?.([...props.selectedRoles.filter(
                    role => !rolesWithThisKey.includes(role)
                  ), ...(allThisKeySelected ? [] : rolesWithThisKey)])}
                />
              </div>
            
            })
          }
        })}
      />
    </div>
  </div>
}

export const AdminUser = (props: AdminUserProps) => {
  const location = useLocation();
  const {api, loading, toast, setSelectedItem, adminUser} = useAdminComponent();
  const modal = useGlobalModal();
  const {t} = useI18N();
  const {mode} = props;
  const publicNameT2SLoading = useAbortableLoading();

  // States
  const [unsaved, setUnsaved] = useState(false);
  const [userData, setUserData]  = useState<User | null>(null);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [myAdminRolesDeletionConfirmed, setMyAdminRolesDeletionConfirmed] = useState(false);


  const reload = async() => {
    let token = loading.start();
    try {
      let id = parseInt(new URLSearchParams(location.search).get("id") as any)
      setUserData(await api.users.findById(id));

    } catch (e) {
      modal.errorSpecific(e);
    }
    loading.stop(token);
  }

  const reloadMe = async() => {
    let token = loading.start();
    try {
      setUserData(await api.users.getMe());

    } catch (e) {
      modal.errorSpecific(e);
    }
    loading.stop(token);
  }

  // Initialize
  useEffect(() => {
    setSelectedItem(AdminUsersPath);
    if (mode == "edit") {
      reload();
    } else if (mode == "me") {
      reloadMe();
    } else {
      setUserData({
        id: null,
        username: "",
        email: "",
        isAdmin: false,
        isVerified: false,
        isActive: false,
        allowMultipleLogins: false,
        adminRoles: [],
        profile: {
          userId: null,
          firstNameEn: "",
          lastNameEn: "",
          firstNameTc: "",
          lastNameTc: "",
          gender: null,
          contactNumber: "",
          publicNameTc: "",
          publicNameSc: "",
          publicNameEn: "",
          publicContactNumber1: "",
          publicContactNumber1IsWhatsapp: false,
          publicContactNumber2: "",
          publicContactNumber2IsWhatsapp: false,
          publicContactEmail: "",
          timestamp: null,
        },
        adminInfo: {
          userId: null,
          licenseType: null,
          licenseNumber: "",
          timestamp: null,
        },
        lastLogin: undefined,
        lastActive: undefined,
        timestamp: null,
      });
    }

    return () => {
      publicNameT2SLoading.abort();
    }
  }, []);

  const title = useMemo(() => {
    switch (mode) {
      case "create": 
        return t('admin.user.createTitle');
      case "edit":
        return `${t('admin.user.editTitle')} (id: ${userData?.id || "-"})`
      case "me": 
        return t('admin.user.meTitle');
    }
  }, [userData?.id, mode]);
  
  const contactNumberPostfix = useMemo(() => {
    const contactNumber = validatePhoneNumber(userData?.profile?.contactNumber);
    return contactNumber && (
      <div className="d-flex ms-2" style={{gap: "0.6rem", fontSize: "2rem", lineHeight: "2rem"}}>
        <a href={"tel:" + contactNumber} target="_blank" className="text-info">
          <FontAwesomeIcon icon={faSquarePhone} />
        </a>
        <a href={"https://wa.me/" + contactNumber} target="_blank" className="text-success">
          <FontAwesomeIcon icon={faWhatsappSquare} />
        </a>
      </div>
    ) 
  }, [userData?.profile?.contactNumber])

  const publicContactNumber1Postfix = useMemo(() => {
    const contactNumber = validatePhoneNumber(userData?.profile?.publicContactNumber1);
    return contactNumber && (
      <div className="d-flex ms-2" style={{gap: "0.6rem", fontSize: "2rem", lineHeight: "2rem"}}>
        <a href={"tel:" + contactNumber} target="_blank" className="text-info">
          <FontAwesomeIcon icon={faSquarePhone} />
        </a>
        {
          userData?.profile?.publicContactNumber1IsWhatsapp && (
            <a href={"https://wa.me/" + contactNumber} target="_blank" className="text-success">
              <FontAwesomeIcon icon={faWhatsappSquare} />
            </a>
          )
        }
        
      </div>
    ) 
  }, [userData?.profile?.publicContactNumber1, userData?.profile?.publicContactNumber1IsWhatsapp])

  const publicContactNumber2Postfix = useMemo(() => {
    const contactNumber = validatePhoneNumber(userData?.profile?.publicContactNumber2);
    return contactNumber && (
      <div className="d-flex ms-2" style={{gap: "0.6rem", fontSize: "2rem", lineHeight: "2rem"}}>
        <a href={"tel:" + contactNumber} target="_blank" className="text-info">
          <FontAwesomeIcon icon={faSquarePhone} />
        </a>
        {
          userData?.profile?.publicContactNumber2IsWhatsapp && (
            <a href={"https://wa.me/" + contactNumber} target="_blank" className="text-success">
              <FontAwesomeIcon icon={faWhatsappSquare} />
            </a>
          )
        }
      </div>
    ) 
  }, [userData?.profile?.publicContactNumber2, userData?.profile?.publicContactNumber2IsWhatsapp])

  // Render
  if (mode == 'create' && userData?.id) {
    // Redirect to the user page for NEWLY CREATED USER
    return <Navigate to={`..?id=${userData.id}`} />
  } 



  return (
    <AdminComponent.Container>
      <AdminHeaderTitle>{title}</AdminHeaderTitle>
      <AdminComponent.TitleBar>
        <AdminComponentTitle 
          backTo={`../../${AdminUsersPath}`}
          faIcon={(mode == "create") ? faUserPlus : faUserGear}
        >
          {title}
        </AdminComponentTitle>
      </AdminComponent.TitleBar>
      <div className="hr"></div>
      {
        <EntityData
          unsaved={unsaved}
          object={userData}
          loading={loading.flag}
          timestamp={(mode != "create") && userData?.timestamp}
          floatingSaveBtn
          meta={{
            username: {
              title: t('admin.user.username'),
              type: "text",
              readonly: mode == "me"
            },
            email: {
              title: t('admin.user.email'),
              type: "email",
              readonly: mode == "me"
            },
            newPassword: {
              title: t('admin.user.newPassword'),
              type: "password",
              divWidth: 6,
              valueOverride: newPassword,
              onChangeOverride: (newPassword: string) => {
                setNewPassword(newPassword);
                if (newPassword == "") {
                  setNewPasswordConfirm("");
                }
                setUnsaved(true);
              }
            },
            newPasswordConfirm: {
              title: t('admin.user.newPasswordConfirm'),
              type: "password",
              divWidth: 6,
              valueOverride: newPasswordConfirm,
              disabled: newPassword == "",
              onChangeOverride: (newPasswordConfirm: string) => {
                setNewPasswordConfirm(newPasswordConfirm);
                setUnsaved(true);
              }
            },
            isActive: {
              title: t('admin.user.active'),
              type: "switch",
              hidden: mode == "me",
              divWidth: 4
            },
            isVerified: {
              title: t('admin.user.verified'),
              type: "switch",
              hidden: mode == "me",
              divWidth: 4
            },
            
            isAdmin: {
              title: t('admin.user.admin'),
              type: "switch",
              hidden: mode == "me",
              divWidth: 4
            },
            allowMultipleLogins: {
              title: t('admin.user.allowMultipleLogins'),
              type: "switch",
              hidden: mode == "me",
            },
            adminRoles: {
              title: t('admin.user.adminRoles'),
              type: "valueComponent",
              disabled: !userData?.isAdmin,
              hidden: mode == "me",
              component: (
                userData?.adminRoles && <AdminRolesSelector
                  api={api}
                  selectedRoles={userData?.adminRoles}
                  onChange={async(adminRoles) => {
                    console.log(adminRoles.length);
                    console.log(userData?.adminRoles.length);
                    if (userData?.id && adminUser?.id && userData.id == adminUser.id && 
                      adminRoles.length < userData?.adminRoles.length &&
                      !myAdminRolesDeletionConfirmed
                    ) {
                      if (await modal.confirm(
                        t("admin.user.adminRolesDeletionWarning.title"), 
                        t("admin.user.adminRolesDeletionWarning.body")
                      )) {
                        setMyAdminRolesDeletionConfirmed(true);
                      } else {
                        return;
                      }
                    }
                    setUserData(user => ({...user, adminRoles}));
                    setUnsaved(true);
                  }}
                />
              )
            },
            "profile": {
              title: t('admin.user.profile'),
              type: "section",
            },
            "profile.lastNameEn": {
              title: t('admin.user.lastNameEn'),
              type: "text",
              divWidth: 5
            },
            "profile.firstNameEn": {
              title: t('admin.user.firstNameEn'),
              type: "text",
              divWidth: 7
            },
            "profile.lastNameTc": {
              title: t('admin.user.lastNameTc'),
              type: "text",
              divWidth: 5
            },
            "profile.firstNameTc": {
              title: t('admin.user.firstNameTc'),
              type: "text",
              divWidth: 7
            },
            "profile.gender": {
              title: t('admin.user.gender'),
              type: "select",
              selectOptions: UserProfileGenders.map((gender) => ({
                label: <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={gender == "male" ? faMars : faVenus} className="me-2" />{t('admin.user.genders.' + gender)}
                </div>,
                value: gender
              }))
            },
            "profile.contactNumber": {
              title: t('admin.user.contactNumber'),
              type: "text",
              postfix: contactNumberPostfix
            },

            "profile.publicNameTc": {
              title: t('admin.user.publicName') + " - " + LangDisplay("tc"),
              type: "text",
              divWidth: {xs: 12, lg: 4},
              onChange: async(nameTc: string) => {
                const {token, signal} = publicNameT2SLoading.start();
                try {
                  const text = await api.openCC.convertHK2S({text: nameTc}, {signal});
                  setUserData(user => ({...user, profile: {...user.profile, publicNameSc: text}}))
                  setUnsaved(true);
                } catch (e) {}
                publicNameT2SLoading.stop(token);
              }
            },
            "profile.publicNameSc": {
              title: t('admin.user.publicName') + " - " + LangDisplay("sc"),
              type: "text",
              divWidth: {xs: 12, lg: 4}
            },
            "profile.publicNameEn": {
              title: t('admin.user.publicName') + " - " + LangDisplay("en"),
              type: "text",
              divWidth: {xs: 12, lg: 4}
            },

            "profile.publicContactNumber1": {
              title: t('admin.user.publicContactNumber') + " - 1",
              type: "text",
              postfix: publicContactNumber1Postfix,
              divWidth: {xs: 12, md: 6, lg: 7, }
            },
            "profile.publicContactNumber1IsWhatsapp": {
              title: t('admin.user.publicContactNumberIsWhatsapp'),
              type: "switch",
              divWidth: {xs: 12, md: 6, lg: 5,}
            },
            "profile.publicContactNumber2": {
              title: t('admin.user.publicContactNumber') + " - 2",
              type: "text",
              postfix: publicContactNumber2Postfix,
              divWidth: {xs: 12, md: 6, lg: 7, }
            },
            "profile.publicContactNumber2IsWhatsapp": {
              title: t('admin.user.publicContactNumberIsWhatsapp'),
              type: "switch",
              divWidth: {xs: 12, md: 6, lg: 5,}
            },
            "profile.publicContactEmail": {
              title: t('admin.user.publicContactEmail'),
              type: "text",
            },
            "license": {
              title: t('admin.user.license'),
              type: "section",
              hidden: mode == "me",
            },
            "adminInfo.licenseType": {
              title: t('admin.user.licenseType'),
              type: "select",
              hidden: mode == "me",
              divWidth: 7,
              selectOptions: UserAdminLicenseTypes.map(type => ({value: type, label: t('admin.user.licenceTypes.' + type)})),
            },
            "adminInfo.licenseNumber": {
              title: t('admin.user.licenseNumber'),
              type: "text",
              hidden: mode == "me",
              disabled: !userData?.adminInfo?.licenseType,
              divWidth: 5,
            },
            "hr2": {
              type: "hr",
            }
          }}
          onChange={(data) => {
            setUserData(data);
            setUnsaved(true);
          }}
          onSave={() => {

            toast.save(async() => {

              let token = loading.start();
              let {id, ...userPending} = userData;
              
              try {
                var userUpdated: User;

                if (newPassword) {
                  if (newPassword != newPasswordConfirm) {
                    // TODO
                    throw new GlobalModalError(
                      "Password mismatch",
                      "The new passwords you entered did not match"
                    )
                  }
                  userPending.password = newPassword;
                }

                if (mode == "create") {
                  userUpdated = await api.users.create(userPending as User);
                } else if (mode == "edit") {
                  userUpdated = await api.users.update(id!, userPending as User);
                } else if (mode == "me") {
                  userUpdated = await api.users.updateMe(userPending as User);
                }
                setUserData(userUpdated);
                setNewPassword("");
                setNewPasswordConfirm("");
                setUnsaved(false);
              } catch (e) {
                modal.errorSpecific(e);
                console.error(e);
                throw e;
              } finally {
                loading.stop(token);
              }

              
            }, "user-save")

          }}
        />
      }

      
    </AdminComponent.Container>
  )

}

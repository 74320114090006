import { useEffect, useRef, useState } from "react"


export const useCollisionCheck = <T = any>(
  id: T,
  onCollision?: (id?: T) => void,
  allowDuplicate?: boolean,
) => {
  
  const isWarnedRef = useRef(false);
  
  useEffect(() => {
    let bc = new BroadcastChannel("collision-check");

    if (id) {
      const checkingId = new Date().getTime();
      console.log(checkingId);
      bc.onmessage = (event) => {
        console.log(event);
        if (event?.data && event.data.id == id) {

          if (event.data.checking) {
            bc.postMessage({id, collided: true, checkingId: event.data.checkingId})
            console.log("Sending collided..");
          } else if (event.data.collided && checkingId == event.data.checkingId) {
            console.log("Collision received");
            if (!isWarnedRef.current || allowDuplicate) {
              onCollision?.(event.data.id);
            }
            isWarnedRef.current = true;
          }

        }

        // bc.postMessage({id})
      }
      bc.postMessage({id, checking: true, checkingId})
    }

    return () => {
      bc.close();
    }
  }, [id])

}
import { faSquareCaretDown, faSquareCaretUp } from "@fortawesome/free-regular-svg-icons";
import { faArrowDown, faArrowDownLong, faArrowsRotate, faArrowUp, faArrowUpLong, faGlobe, faPlus, faSort, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AdminI18NPath } from "..";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider";
import { I18N } from "../../../api/entities/i18n.entity";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";
import { AdminTable } from "../component/Table";


export const AdminI18Ns = (props) => {

  const {api, setSelectedItem, loading} = useAdminComponent();
  const [i18ns, setI18ns] = useState<Omit<I18N, "json">[]>(null);
  const modal = useGlobalModal();
  const {t} = useI18N();

  const [changeOrderToggled, setChangeOrderToggled] = useState(false);
  
  const reload = async() => {
    let token = loading.start();
    try {
      setI18ns(await api.i18n.findAll());
      setChangeOrderToggled(false);
    } catch (e) {
      modal.errorSpecific(e);
    }
    loading.stop(token);
  }

  const saveAllWithNewOrder = async() => {
    let token = loading.start();
    try {
      for (let index = 0; index < i18ns.length; index++) {
        await api.i18n.updateOrderId(i18ns[index].id, index);
      }
    } catch (e) {
      modal.errorSpecific(e);
    } finally {
      reload();
    }
    loading.stop(token);

  }
  
  useEffect(() => {
    setSelectedItem(AdminI18NPath);
    reload();
  }, []);

  return (
    <AdminComponent.Container>


      <AdminHeaderTitle>{t('admin.i18n.title')}</AdminHeaderTitle>
      <AdminComponent.TitleBar>
        <AdminComponentTitle faIcon={faGlobe}>
          {t('admin.i18n.pageTitle')}
        </AdminComponentTitle>
      </AdminComponent.TitleBar>
      
      <div className="hr" />
      <div className="d-flex align-items-center mb-3">
        <Link 
          to="new"
          className="btn btn-primary btn-sm px-3 py-2"
        >
          <FontAwesomeIcon icon={faPlus} className="pe-2" />
          {t('admin.i18n.title-create')}
        </Link>
        <button
          className="btn btn-secondary btn-sm mx-2 px-3 py-2"
          disabled={loading.check()}
          onClick={async() => {
            reload();
          }}
        >
          <FontAwesomeIcon icon={faArrowsRotate} className="pe-2" />
          {t('admin.commons.refresh')}
        </button>

        {
          !changeOrderToggled ? (
            <button
              className="btn btn-warning btn-sm me-1 px-3 py-2"
              disabled={loading.check()}
              onClick={async() => {
                setChangeOrderToggled(true);
              }}
            >
              <FontAwesomeIcon icon={faSort} className="pe-2" />
              {t('admin.i18n.changeOrder')}
            </button>
          ) : (
            <button
              className="btn btn-success btn-sm me-1 px-3 py-2"
              disabled={loading.check()}
              onClick={() => {
                saveAllWithNewOrder();
              }}
            >
              <FontAwesomeIcon icon={faSort} className="pe-2" />
              {t('admin.i18n.saveOrder')}
            </button>
          )
        }
        
      </div>
      <div className="card overflow-card mb-4">
        <AdminTable 
          keys={{
            id: {
              content: t('admin.i18n.id'),
              shrink: true,
            },
            isoId: {
              content: t('admin.i18n.isoId'),
            },
            name: {
              content: t('admin.i18n.name')
            },
            shortName: {
              content: t('admin.i18n.shortName')
            },
            alias: {
              content: t('admin.i18n.alias'),
            },
            ...(
              changeOrderToggled ? {
                reOrder: {
                  content: "",
                  shrink: true,
                }
              } : {}
            )
          }} 
          rows={
            i18ns && i18ns.map((i18n, index) => {
              return {
                content: {
                  id: i18n.id,
                  isoId: i18n.isoId,
                  name: i18n.name,
                  shortName: i18n.shortName,
                  alias: i18n.alias, 
                  ...(
                    changeOrderToggled ? {
                      reOrder: (
                        <div className="d-flex">
                          <button className="btn btn-outline-info btn-sm me-1" disabled={index == 0}
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              let i18nsCloned = [...i18ns];
                              [i18nsCloned[index-1], i18nsCloned[index]] = [i18nsCloned[index], i18nsCloned[index-1]];
                              setI18ns(i18nsCloned);
                            }}
                          >
                            <FontAwesomeIcon icon={faArrowUp} />
                          </button>
                          
                          <button className="btn btn-outline-info btn-sm" disabled={index == i18ns.length - 1}
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              let i18nsCloned = [...i18ns];
                              [i18nsCloned[index], i18nsCloned[index+1]] = [i18nsCloned[index+1], i18nsCloned[index]];
                              setI18ns(i18nsCloned);
                            }}
                          >
                            <FontAwesomeIcon icon={faArrowDown} />
                          </button>
                        </div>
                      )
                    } : {}
                  )
                },
                rowLink: i18n.id
              }
            })
          }
        />
      </div>
    </AdminComponent.Container>
  )
}
import { AxiosInstance, AxiosProgressEvent, Canceler } from "axios";
import { APIBase, DropzoneFile } from "./apiBase";
import { APIEncodeQueryString } from "./helpers";

export interface FileManagerStat {
  fileName: string
  path: string
  isDir: boolean
  isFile: boolean
  size: number
  dirContentSize?: number
  mtime: Date
  birthtime: Date
  subStat?: FileManagerStat[]             // If the file is a folder  (1 layer only)
  service?: string | null   // Storage service, null for direct local file system
  isServiceRootDir?: boolean,
  externalLink?: string                   // Link provided by the service
}

export default class FileManagerAPI extends APIBase {
  

  constructor(axios: AxiosInstance) {
    super(axios, "file-manager");
  }

  async get(path: string, deepScan = false): Promise<FileManagerStat> {
    return (await this.axios.get<FileManagerStat>(
      this.base + "?" + APIEncodeQueryString({path, deepScan})
    )).data;
  }

  async list(path?: string, deepScan = false): Promise<FileManagerStat[]> {
    return (await this.axios.get<FileManagerStat[]>(
      this.base + "/list?" + APIEncodeQueryString({path, deepScan})
    )).data;
  }

  async delete(path: string) {
    return (await this.axios.delete<void>(
      this.base + "?" + APIEncodeQueryString({path})
    )).data;
  }

  async makeDir(path: string): Promise<string> {
    return (await this.axios.post<string>(
      this.base + "?" + APIEncodeQueryString({path})
    )).data;
  }

  async rename(from: string, to: string): Promise<string> {
    return (await this.axios.put<string>(
      this.base + "?" + APIEncodeQueryString({from, to})
    )).data;
  }

  async upload(
    files: DropzoneFile[], 
    path: string,
    overwrite = false,
    onUploadProgress?: (e: AxiosProgressEvent) => void,
    cancelerHandler?: (canceler: Canceler) => void,
  ): Promise<void> {
    return this.uploadFiles(
      "post", 
      this.base + "/upload?" + APIEncodeQueryString({path, overwrite}), 
      files, 
      onUploadProgress, 
      cancelerHandler
    );
  }
}
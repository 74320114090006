import useResizeObserver from "@react-hook/resize-observer";
import { useRef, useState, useLayoutEffect } from "react";

export const useRefDimension = <T extends HTMLDivElement,>() => {
  const ref = useRef<T|null>();
  const [dimension, setDimension] = useState<DOMRect>(null);

  useLayoutEffect(() => {
    setDimension(ref?.current?.getBoundingClientRect());
  }, [ref])

  useResizeObserver(ref, (entry) => {
    setDimension(entry.contentRect)
  })

  return {ref, dimension};
}
import { faAddressCard, faBuilding, faSave, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faPlus, faArrowsRotate, faBan, faBuildingUser, faCheck, faPersonDigging, faAddressBook, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useState, useTransition, useMemo, useCallback, useEffect, useId } from "react";
import { Badge, Spinner } from "react-bootstrap";
import { FindAllQuery } from "../../../api/entities/findAllQuery";
import { Paginated } from "../../../api/entities/pagination";
import { PropertyPublishmentTag } from "../../../api/entities/property/property-publishment-tag.entity";
import { AutocompleteSearch } from "../../../component/AutocompleteSearch";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider";
import { UnsavedPrompt } from "../../../component/UnsavedPrompt";
import { useAbortable, useAbortableLoading } from "../../../utils/abortable-loading";
import { useScrollToId } from "../../../utils/scroll-to-id";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";
import { ContactShortcut } from "../component/ContactShortcut";
import { DataList, DataListItem } from "../component/DataList";
import { EntitySelector, EntitySelectorParentProps } from "../component/EntitySelector";
import { EntityTimestamp } from "../component/EntityTimestamp";
import { useFindAllQuery } from "../component/FindAllQuery";
import { GenericInput } from "../component/GenericInput";
import { AdminPagination } from "../component/Pagination";
import { AdminPropertyPublishmentTagsPath } from "../variables";
import { AdminPropertyI18NPrefix } from "./Property";

export const AdminPropertyPublishmentTagsComponent = (props: {
  onSelect?: (tag: PropertyPublishmentTag) => void,
  selectedPropertyPublishmentTags?: PropertyPublishmentTag[];
  shouldUseSearchParams?: boolean,
}) => {
    const {api, loading, toast} = useAdminComponent();
    const modal = useGlobalModal();
    const [isInit, setIsInit] = useState(false);
    const [tagsPaginated, setPropertyPublishmentTagsPaginated] = useState<Paginated<PropertyPublishmentTag>>(null);
    const [pendingInputFocus, setPendingInputFocus] = useState(false);
    const [tagEditing, setPropertyPublishmentTagEditing] = useState<(PropertyPublishmentTag & {
      isNew: boolean,
      newIdEdited: boolean, // Only for new, initially false, true if id is edited
      index: number | null, // null for create
      unsaved: boolean,
    }) | null>(null);

    // console.log(PropertyPublishmentTagSort);

    const {findAllQuery, renderPaginationUI, renderSearchInput, setSearchInput, scrollTopRef, setSortByDropdownOpen} 
      = useFindAllQuery({
      isInit, loading: loading.flag, disabled: !!tagEditing || !isInit, paginationMeta: tagsPaginated?.meta,
      shouldUseSearchParams: props.shouldUseSearchParams,
      defaultLimit: 50,
      sortKeys: ["id", "nameEn", "nameTc", "nameSc", "timestamp.created", "timestamp.updated"]
    })
  
    const reloadAbortable = useAbortable();
    const openCCT2SAbortableLoading = useAbortableLoading();
    
    const {scrollToId, idPrefix} = useScrollToId();
    
    const {t} = useI18N();
  
    const unsaved = useMemo(() => {
      return !!tagEditing?.unsaved;
    }, [tagEditing?.unsaved])
  
    const unsaveProceed = useCallback(async() => {
      return !unsaved || unsaved && await modal.confirmUnsaved();
    }, [unsaved])
  
    const reload = useCallback(async() => {
      const token = loading.start();
      const {signal} = reloadAbortable.create();
      try {
        setPropertyPublishmentTagsPaginated(await api.property.findAllTags(findAllQuery, {signal}));
        // TODO
        scrollToId(null);
      } catch (e) {
        modal.errorSpecific(e);
      } finally {
        loading.stop(token);
      }
    }, [findAllQuery])
  
    useEffect(() => {
      if (isInit) {
        // TODO: Handle unsaved prompt from external use
        reload();
      }
    }, [findAllQuery]);

    useEffect(() => {
      reload().then(() => setIsInit(true));
    }, []);
  
    return (
      <>
        <UnsavedPrompt flag={unsaved} />
        <div className="d-flex align-items-center mb-3" style={{gap: "0.5em", flexWrap: "wrap"}}>
  
          <ButtonWithLoader
            faIcon={faPlus}
            variant="primary"
            loading={loading.check()}
            onClick={async(event) => {
              event.stopPropagation();
              if (await unsaveProceed()) {
                setPropertyPublishmentTagEditing({
                  isNew: true,
                  newIdEdited: false,
                  index: null,
                  nameEn: "",
                  nameTc: "",
                  nameSc: "",
                  count: 0,
                  unsaved: true,
                })
                scrollToId(null);
  
                setPendingInputFocus(true);
              }
  
            }}
          />
  
          <ButtonWithLoader
            faIcon={faArrowsRotate}
            variant="secondary"
            loading={loading.check()}
            onClick={async() => {
              if (await unsaveProceed()) {
                setPropertyPublishmentTagEditing(null);
                await reload();
              }
            }}
          />

          {renderPaginationUI({className: "ms-auto"})}

        </div>
  
        <div>
          {/* <AutocompleteSearch
            loading={searchPending}
            placeholder={t("admin.tags.search")}
            hideDropdown
            input={searchInput}
            onChange={(value: string) => {
              setSearchInput(value);
              startSearchTransition(() => {
                // setPropertyPublishmentTagsFiltered(searchPropertyPublishmentTags(value, tags))
              })
            }}
          /> */}
          {/* <SearchInput /> */}
          {renderSearchInput({
            placeholder: t(AdminPropertyI18NPrefix + "publishment.tags.searchTags")
          })}
        </div>
        <DataList divRef={scrollTopRef} className="scrollable" onClick={() => setSortByDropdownOpen(false)}>
          {
            tagsPaginated?.items && (
              tagsPaginated?.items.length == 0 && !tagEditing ? (
                <DataList.Empty>{t('commons.noResult')}</DataList.Empty>
              ) : (
                <>{
                  [...tagsPaginated?.items, tagEditing].map((tag: PropertyPublishmentTag, index) => {
                    if (tag == null || (tag as typeof tagEditing).isNew === false) {
                      return;
                    }
    
                    const isNew = (tag as typeof tagEditing)?.isNew;
                    const editing = isNew || tagEditing?.index === index; 
                    const isSelected = props.onSelect && !!props.selectedPropertyPublishmentTags?.find(d => tag.nameEn == d.nameEn);
                    // return <></>
                    return (
                      <DataListItem key={index}
                        clickable
                        active={editing}
                        onClick={async() => {
                          if (!editing && await unsaveProceed()) {
                            setPropertyPublishmentTagEditing({
                              isNew: false,
                              newIdEdited: true,
                              index, 
                              unsaved: false,
                              ...tag
                            });
                            
                            setPendingInputFocus(true);
                            scrollToId(tag.nameEn);
                          }
                        }}
                      >
                        <a id={idPrefix + tag.nameEn} className="anchor" />
                        {/* <DataListItem.Title>
                          <Badge bg="info" className="data-key">id</Badge>
                          {!editing ? <b>{tag.nameEn}</b> :
                          <GenericInput 
                            type="text"
                            value={tagEditing.nameEn} 
                            loading={loading.check()}
                            onChange={(nameEn: string) => {
                              setPropertyPublishmentTagEditing({...tagEditing, nameEn, newIdEdited: nameEn != "", unsaved: true})
                              
                            }} 
                          />}
                          {
                            !editing && (
                              <DataListItem.TitleBtns>
                                <EntityTimestamp timestamp={tag.timestamp} />
                              </DataListItem.TitleBtns>
                            )
                          }
                        </DataListItem.Title> */}
                        <DataListItem.Body>
                          <div><Badge bg="info" className="data-key">EN</Badge>{
                            !editing ? <>{tag.nameEn}</> :
                            <GenericInput 
                              type="text" 
                              value={tagEditing.nameEn} 
                              loading={loading.check()}
                              onChange={nameEn => {
                                setPropertyPublishmentTagEditing(tagEditing => ({...tagEditing, nameEn, unsaved: true}))
                              }} 
                            />
                          }</div>
                          <div><Badge bg="info" className="data-key">繁</Badge>{
                            !editing ? <>{tag.nameTc}</> :
                            <GenericInput 
                              inputRef={pendingInputFocus ? (element) => {
                                element?.focus();
                                setPendingInputFocus(false);
                              } : undefined}
                              type="text" 
                              value={tagEditing.nameTc} 
                              loading={loading.check()}
                              onChange={async(nameTc) => {
                                setPropertyPublishmentTagEditing(tagEditing => ({...tagEditing, nameTc, unsaved: true}))
                                const {token, signal} = openCCT2SAbortableLoading.start();
                                try {
                                  const text = await api.openCC.convertHK2S({text: nameTc}, {signal});
                                  setPropertyPublishmentTagEditing(tagEditing => ({...tagEditing, nameSc: text, unsaved: true}))
                                } catch (e) {
                                  modal.errorSpecific(e);
                                } finally {
                                  openCCT2SAbortableLoading.stop(token);
                                }
    
                              }} 
                            />
                          }</div>
                          <div><Badge bg="info" className="data-key">简</Badge>{
                            !editing ? tag.nameSc :
                            <>
                              
                              <GenericInput 
                                type="text" 
                                value={tagEditing.nameSc} 
                                loading={loading.check()}
                                onChange={nameSc => {
                                  openCCT2SAbortableLoading.abort(); 
                                  setPropertyPublishmentTagEditing({...tagEditing, nameSc, unsaved: true});
                                }} 
                              />
                              {
                                openCCT2SAbortableLoading.flag && (
                                  <div>
                                    <Spinner animation="border" size="sm" className="ms-2" />
                                  </div>
                                )
                              }
                            </>
                            
                          }</div>
                          
                          {
                            editing && (
                              <div className="btns">
                                <ButtonWithLoader
                                  variant="success"
                                  faIcon={faSave}
                                  loading={loading.check()}
                                  onClick={() => toast.save(async() => {
                                    const token = loading.start();
                                    openCCT2SAbortableLoading.abort();
                                    let tagUpdated: PropertyPublishmentTag;
                                    try {
                                      if (isNew) {
                                        tagUpdated = await api.property.createTag(tagEditing);
                                      } else {
                                        tagUpdated = await api.property.updateTag(tag.nameEn, tagEditing);
                                      }
                                      
                                      setSearchInput(tagUpdated.nameEn);
                                      setPropertyPublishmentTagEditing(null);
                                      await reload();
                                      setTimeout(() => {
                                        // Delay to make sure reload() has update unsaved status
                                        scrollToId(tagUpdated.nameEn);
                                      }, 100)
    
                                    } catch (e) {
                                      modal.errorSpecific(e);
                                      throw e;
                                    } finally {
                                      loading.stop(token);
                                    }
                                  }, "tag-save")}
                                >{t('entityData.save')}</ButtonWithLoader>
                                {
                                  !isNew && (
                                    <ButtonWithLoader
                                      variant="danger"
                                      faIcon={faTrashCan}
                                      loading={loading.check()}
                                      onClick={async() => {
                                        if (await modal.confirmDelete()) {
                                          toast.delete(async() => {
                                            const token = loading.start();
                                            try {
                                              await api.property.deleteTag(tag.nameEn);
                                              await reload();
                                              setPropertyPublishmentTagEditing(null);
                                              scrollToId(null);
                                              
                                            } catch (e) {
                                              modal.errorSpecific(e);
                                              throw e;
                                            } finally {
                                              loading.stop(token);
                                            }
                                          }, "tag-delete")
                                        }
                                      }}
                                    >{t('entityData.delete')}</ButtonWithLoader>
    
                                  )
                                }
                                
                                <ButtonWithLoader
                                  variant="info"
                                  faIcon={faBan}
                                  loading={loading.check()}
                                  onClick={async() => {
                                    if (await unsaveProceed()) {
                                      setPropertyPublishmentTagEditing(null);
                                    }
                                  }}
                                >{t('entityData.cancel')}</ButtonWithLoader>
                              </div>
                              
                            )
                          }
                          {
                            !editing && props.onSelect && (
                              <ButtonWithLoader
                                size="sm"
                                variant="success"
                                onClick={() => {
                                  props.onSelect(tag);
                                }}
                                disabled={isSelected}
                                faIcon={isSelected && faCheck}
                              >
                                {t(`admin.commons.${isSelected ? "selected" : "select"}`)}
                              </ButtonWithLoader>
                            )
                          }
                        </DataListItem.Body>
                      </DataListItem>
                    )
                  })
                }
                
                {renderPaginationUI({className: "my-4"})}
                </>
              )
            )
          }
          {/* <AdminTable 
            className="tags-table"
            keys={{
              id: {
                content: t('admin.tags.nameEn'),
              },
              nameEn: {
                content: t('admin.tags.nameEn'),
              },
              nameTc: {
                content: t('admin.tags.nameTc'),
              },
              nameSc: {
                content: t('admin.tags.nameSc'),
              },
              reOrder: {
                content: "",
                shrink: true,
              }, 
              timestamp: {
                content: "",
                shrink: true,
              },
              delete: {
                content: "",
                shrink: true,
              },
            }} 
            rows={
              tags && tags.map((tag, index) => {
                if (tag.mode == "deleted") {return null}
                const editing = tag.mode == "editing";
  
                return {
                  onClick: (event) => {
                    event.stopPropagation();
                    // Set all editing tags as uneditted
                    setPropertyPublishmentTags(tags => tags?.map(tag => {
                      if (tag.mode == "editing") {
                        tag.mode = "edited";
                      }
                      return tag;
                    }))
                    editPropertyPublishmentTag(index, {
                      ...tag,
                      mode: "editing",
                    })
                  },
                  content: {
                    id: !editing ? tag.nameEn :
                      <GenericInput type="text" value={tag.nameEn} onChange={(id: string) => editPropertyPublishmentTag(index, {...tag, id: id.toUpperCase()}, true)} />,
                    nameEn: !editing ? tag.nameEn :
                      <GenericInput type="text" value={tag.nameEn} onChange={nameEn => editPropertyPublishmentTag(index, {...tag, nameEn}, true)} />,
                    nameTc: !editing ? tag.nameTc :
                      <GenericInput type="text" value={tag.nameTc} onChange={nameTc => editPropertyPublishmentTag(index, {...tag, nameTc}, true)} />,
                    nameSc: !editing ? tag.nameSc :
                      <GenericInput type="text" value={tag.nameSc} onChange={nameSc => editPropertyPublishmentTag(index, {...tag, nameSc}, true)} />,
                    reOrder: (
                      <div className="d-flex">
                        <button className="btn btn-outline-info btn-sm me-1" disabled={index == 0}
                          onClick={(event) => {
                            event.stopPropagation();
                            let tagsCloned = [...tags];
                            [tagsCloned[index-1], tagsCloned[index]] = [tagsCloned[index], tagsCloned[index-1]];
                            setPropertyPublishmentTags(tagsCloned);
                            setUnsavedOrders(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowUp} />
                        </button>
                        
                        <button className="btn btn-outline-info btn-sm" disabled={index == tags.length - 1}
                          onClick={(event) => {
                            event.stopPropagation();
                            let tagsCloned = [...tags];
                            [tagsCloned[index], tagsCloned[index+1]] = [tagsCloned[index+1], tagsCloned[index]];
                            setPropertyPublishmentTags(tagsCloned);
                            setUnsavedOrders(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowDown} />
                        </button>
                      </div>
                    ),
                    timestamp: tag.timestamp && <EntityTimestamp created={tag.timestamp.created} updated={tag.timestamp.updated} />,
                    delete: (
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={async(event) => {
                          event.stopPropagation();
                          if (await modal.confirmDelete()) {
                            editPropertyPublishmentTag(index, {...tag, mode: "deleted"}, true)
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                    ),
                  }
                }
              })
            }
          /> */}
        </DataList>
      </>
    )
  }

export const AdminPropertyPublishmentTags = () => {
  const {t} = useI18N();
  useAdminComponent({selectedItem: AdminPropertyPublishmentTagsPath});


  return (
    <AdminComponent.Container 
      onClick={() => {

      }}
    >
      <AdminHeaderTitle>{t(AdminPropertyI18NPrefix + "publishment.tags.longTitle")}</AdminHeaderTitle>
      <AdminComponent.TitleBar>
        <AdminComponentTitle faIcon={faPersonDigging}>
            {t(AdminPropertyI18NPrefix + "publishment.tags.longTitle")}
        </AdminComponentTitle>
      </AdminComponent.TitleBar>

      
      <div className="hr" />
      
      <AdminPropertyPublishmentTagsComponent shouldUseSearchParams/>
    </AdminComponent.Container>
  )
  
}


export const AdminPropertyPublishmentTagPreview = (props: {
  tag: PropertyPublishmentTag
  onDelete?: (tag: PropertyPublishmentTag) => void;
}) => {
  const {tag} = props;

  return tag && (
    <div className="preview">
      <div><Badge bg="info" >繁</Badge> {tag.nameTc}</div>
      <div><Badge bg="info" >简</Badge> {tag.nameSc}</div>
      <div><Badge bg="info" >EN</Badge> {tag.nameEn}</div>
      <ButtonWithLoader 
        faIcon={faTrashCan}
        variant="danger"
        size="sm"
        onClick={() => {
          props.onDelete?.(tag)
        }}
      />
    </div>
  )

}
export const AdminPropertyPublishmentTagsSelector = (props: EntitySelectorParentProps<PropertyPublishmentTag[]>) => {
  const {t} = useI18N();
  const [modalOpen, setModalOpen] = useState(false);

  const tags: PropertyPublishmentTag[] = useMemo(() => (
    [...(props.item ?? [])]
  ), [props.item])

  return (
    <>
      <EntitySelector
        {...props}
        item={null}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        icon={faPersonDigging}
        title={t('admin.tags.add')}
        preview={
          tags?.map(tag => (
            <AdminPropertyPublishmentTagPreview 
              key={tag.nameEn}
              tag={tag}
              onDelete={() => {
                const index = tags.findIndex(d => d.nameEn == tag.nameEn);
                index != -1 && tags.splice(index, 1);
                props.onChange(tags);
              }}
            />
          ))
        }
        modalContent={<AdminPropertyPublishmentTagsComponent onSelect={(tag) => {
          setModalOpen(false);
          tags.push(tag);
          props.onChange?.(tags);
        }}
          selectedPropertyPublishmentTags={tags}
        />}
      />
    </>

  )
}
import { AxiosInstance, AxiosRequestConfig } from "axios";
import { Developer, DeveloperDto, DeveloperSort } from "../entities/developer.entity";
import { FindAllQuery } from "../entities/findAllQuery";
import { I18N, I18NDto } from "../entities/i18n.entity";
import { Paginated } from "../entities/pagination";
import { APIBase } from "./apiBase";
import { APIEncodeQueryString } from "./helpers";

export default class DevelopersAPI extends APIBase {
  

  constructor(axios: AxiosInstance) {
    super(axios, "developers");
  }

  async findAll(query: FindAllQuery<DeveloperSort>, config?: AxiosRequestConfig): Promise<Paginated<Developer>> {
    return (await this.axios.get<Paginated<Developer>>(
      this.base + "?" + APIEncodeQueryString(FindAllQuery.toJson(query)), config
    )).data;
  }

  async update(id: string, dto: DeveloperDto): Promise<Developer> {
    return (await this.axios.put<Developer>(
      this.base + "/" + id,
      dto
    )).data;
  }

  async create(dto: DeveloperDto): Promise<Developer> {
    return (await this.axios.post<Developer>(
      this.base,
      dto
    )).data;
  }

  async delete(id: string): Promise<void> {
    return (await this.axios.delete<void>(
      this.base + "/" + id
    )).data;
  }

}
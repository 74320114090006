
import { IsString, IsNotEmpty, IsNumber, IsEnum, IsOptional } from "class-validator";
import { TimestampEntityWithUser } from "./timestamp-with-user.entity";
import { TimestampEntity } from "./timestamp.entity";
import { HidePublic } from "./utils/hide-public";
import { ApiProperty, Column, Entity, ManyToOne, OmitType, PrimaryColumn } from "./vars";

export const DistrictRegions = [
  "HK", "KLN", "NT"
] as const;

export type DistrictRegion = (typeof DistrictRegions)[number];

@Entity()
export class District {
  @ApiProperty()
  @IsString()
  @IsNotEmpty()
  @PrimaryColumn("varchar", {
    length: 16
  })
  id: string;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 64,
    nullable: false,
    default: ""
  })
  nameTc: string;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 64,
    nullable: false,
    default: ""
  })
  nameSc: string;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 64,
    nullable: false,
    default: ""
  })
  nameEn: string;

  @ApiProperty()
  @IsEnum(DistrictRegions)
  @IsOptional()
  @Column("enum", {
    enum: DistrictRegions,
    nullable: true,
  })
  region: DistrictRegion;
  
  @HidePublic()
  @ApiProperty()
  @Column(() => TimestampEntityWithUser)
  timestamp: TimestampEntityWithUser

}

export class DistrictDto extends OmitType(
  District, ["timestamp"]
) {}
import axios from "axios";
import {Buffer} from "buffer";

export const downloadImage = async(
  url: string, 
  type: "buffer" | "base64" = "base64",
  options?: {
    noCache?: boolean
  }
) => {
  // https://stackoverflow.com/questions/68953079/axios-cors-not-working-on-chrome-deployed-site
  const noCache = options?.noCache ?? false;

  const buffer = (await axios.get(url, {responseType: 'arraybuffer', headers: {
    ...(noCache && {'Cache-Control': 'no-cache'})
  }})).data;

  switch (type) {
    case "buffer": 
      return buffer;
    case "base64": 
      return Buffer.from(buffer).toString('base64');
  }

}
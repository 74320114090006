import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useI18N } from "../../../component/I18NProvider";
import { AdminComponent, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";


export const AdminNotFound = (props: React.PropsWithChildren<{}>) => {

  const {t} = useI18N();

  
  return (

    <AdminComponent.Container name="not-found">
      <AdminHeaderTitle>{t('commons.notFound')}</AdminHeaderTitle>
      <div>
        <FontAwesomeIcon icon={faExclamationTriangle} className="me-4"/>
        {t('commons.notFound')}
      </div>
    </AdminComponent.Container>

  )
}
import { useCallback, useEffect, useState } from "react"
import { APIInstanceWithAuth } from "../api"
import { LocalCache } from "../api/src/local-cache";
import { useI18N } from "../component/I18NProvider";

export const ScreenSizes = ["full", "wide", "thin"] as const;
export type ScreenSize = (typeof ScreenSizes)[number];

export const useScreenSize = () => {
  const localCache = new LocalCache<{
    "screen-size": ScreenSize
   }>({
     prefix: "admin."
   });
  const [screenSize, setScreenSize] = useState<ScreenSize>(localCache.get("screen-size", "full"));
  
  useEffect(() => {
    if (!ScreenSizes.includes(screenSize)) {
      setScreenSize("full");
      return;
    }
    localCache.set({"screen-size": screenSize})
  }, [screenSize]);


  const toggleScreenSize = useCallback(() => {
    setScreenSize(size => ScreenSizes[(ScreenSizes.indexOf(size) + 1) % ScreenSizes.length]);
  }, [])


  return {screenSize, setScreenSize, toggleScreenSize}


}


import clsx from "clsx"
import React, { ComponentPropsWithRef } from "react"

export const Section = (props: ComponentPropsWithRef<"div">) => {
  return (
    <div {...props} 
      className={clsx("d-flex align-items-center", props.className)}
      style={{
        ...(props.onClick && {cursor: "pointer"})
      }}
    >
      <div className="hr" style={{
        flex: 1, 
        margin: "0 1.5em 0 0",
        }}/>
      <div style={{
        fontSize: "0.92em",
        color: "grey",
        fontWeight: "bold",
      }}>{props.children}</div>
      <div className="hr" style={{
        flex: 1, 
        margin: "0 0 0 1.5em",
        }}/>
    </div>
  )
}
import { faArrowRightFromBracket, faArrowsRotate, faArrowUpRightFromSquare, faBolt, faCheck, faCheckCircle, faCity, faCross, faEllipsis, faEnvelope, faExclamationCircle, faLink, faP, faPhone, faSquareArrowUpRight, faTriangleExclamation, faUpRightFromSquare, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ComponentProps, ComponentPropsWithRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Alert, Badge, Button, ButtonGroup } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { APIInstance, APIInstanceWithAuth } from "../../../api";
import { PropertyPublishment, PropertyPublishmentFloors, PropertyPublishmentState, PropertyPublishmentStates } from "../../../api/entities/property/property-publishment.entity";
import { Property } from "../../../api/entities/property/property.entity";
import { User, userHasAdminRoles } from "../../../api/entities/user.entity";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider";
import { InputHiddenRequired } from "../../../component/InputHiddenRequired";
import { NullDisplay } from "../../../component/NullDisplay";
import { useUser } from "../../../component/UserProvider";
import { useAbortableLoading } from "../../../utils/abortable-loading";
import { LangDisplayShort, LangMap, Langs } from "../../../utils/lang-map";
import { LoadingManager, useLoadingManager } from "../../../utils/loading-manager";
import { numberInShortPublic } from "../../../utils/number-in-short";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent"
import { EntityData } from "../component/EntityData";
import { UsersSearch } from "../component/UsersSearch";
import { AdminBuildingPath, AdminPropertyPath, AdminPropertyPublishmentTagsPath } from "../variables";
import { AdminBuildingPreview } from "./Buildings";
import { AdminNotFound } from "./NotFound";
import { AdminPropertyI18NPrefix, PropertyBasicInfoPreview } from "./Property";
import "../../../styles/admin/publishment.scss";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { validatePhoneNumber } from "../../../utils/validate";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { PublishmentImageTool } from "../component/PublishmentImageTool";
import { BuildingAndPropertyImageTool } from "../component/BuildingAndPropertyImageTool";
import { useCollisionCheck } from "../../../utils/use-collision-check";
import { PropertyTagsInput } from "../component/PropertyTagsInput";
import { PropertyPublishmentTag } from "../../../api/entities/property/property-publishment-tag.entity";
import { AdminBuildingI18NPrefix } from "./Building";
import { PropertyPublishmentImagesPreview } from "../component/PropertyPublishmentImagesPreview";
import { commify } from "../../../utils/commify";
import ReactSwitch from "react-switch";
import moment from "moment";
import { GenericInput } from "../component/GenericInput";
import clsx from "clsx";

export const PublishmentStateDisplay = (props: {
  state: PropertyPublishmentState | null
} & ComponentPropsWithRef<"div">) => {
  const {state, ...divProps} = props;
  const {t} = useI18N();
  const color = useMemo(() => {
    switch (state) {
      case "unpublished":
        return "#ea8888";
      case "pending": 
        return "#dd8800"
      case "published": 
        return "#00dd00";
      default:
        return "#cacaca";
    }
  }, [state])

  return <div {...divProps} style={{textDecoration: "none", position: "relative",
    color, fontWeight: "bold", fontSize: "1.4em"
  }} className={clsx("d-flex align-items-center justify-content-center", divProps.className)}>
    {/* <span>P</span> */}
    {
      state == "unpublished" ? <FontAwesomeIcon icon={faXmark} /> :
      state == "pending" ? <span style={{fontSize: "0.66em"}}>{t(AdminPropertyI18NPrefix+"publishment.states.pending")}</span> :
      state == "published" ? <FontAwesomeIcon icon={faCheckCircle} /> : 
      <FontAwesomeIcon icon={faXmark} fixedWidth />
    }
  </div>
}

export const PropertyPublishmentContactPreview = (props: {
  user: User,
  onDelete?: () => void,
  isDefault?: boolean,
  onDefaultChange?: (flag: boolean) => Promise<void>
}) => {
  const {user, onDelete, isDefault, onDefaultChange} = props;
  const modal = useGlobalModal();
  const {t, currentLang} = useI18N();
  const loading = useLoadingManager();
  return (
    <div className="publishment-contact">
      <div className="close-btn" onClick={() => onDelete?.()}>
        <FontAwesomeIcon icon={faXmark} fixedWidth />
      </div>

      <div className="left">
        <img src={"/images/placeholder/" + ({
          "male": "male.jpg",
          "female": "female.jpg"
        }[user.profile?.gender] || "human.jpg")} className="image image-placeholder" />
      </div>
      <div className="right">
        <div className="name">{
            user.profile?.["publicName" + LangMap(currentLang)] || <NullDisplay />
        }</div>
        <div className="alt-name">{
            user.profile?.["publicName" + LangMap(currentLang == "tc" ? "en" : "tc")] || <NullDisplay />
        }</div>
        <div className="license">{
            user.adminInfo?.licenseNumber || <NullDisplay />
        }</div>
        <div className="contact-methods">
          {
            user.profile?.publicContactNumber1 && <>
              <div className="contact-method phone" onClick={() => {
                window.open("tel:" + user.profile?.publicContactNumber1)
              }}>
                <FontAwesomeIcon icon={faPhone} fixedWidth />
              </div>
              {
                user.profile?.publicContactNumber1IsWhatsapp && <div className="contact-method whatsapp" onClick={() => {
                  window.open("https://wa.me/" + validatePhoneNumber(user.profile?.publicContactNumber1))
                }}>
                  <FontAwesomeIcon icon={faWhatsapp} fixedWidth />
                </div>
              }
            </>
          }
          {
            user.profile?.publicContactEmail && <a className="contact-method email" target="_blank" href={"mailto:" + user.profile?.publicContactEmail}>
              <FontAwesomeIcon icon={faEnvelope} fixedWidth />
            </a>
          }
        </div>
        {
          isDefault != null && (
            <div className="default">
              <ButtonWithLoader
                loading={loading.flag}
                size="sm"
                className="mt-2"
                variant={isDefault ? "danger" : "success"}
                onClick={async() => {
                  if (!isDefault || (isDefault && await modal.confirmDelete())) {
                    const token = loading.start();
                    try {
                      await onDefaultChange?.(!isDefault);
                    } catch (e) {
  
                    }
                    loading.stop(token);
                  }
                  
                }}
              >
                {t(AdminPropertyI18NPrefix+"publishment.content.contactUsersDefault" + (isDefault ? "Cancel" : ""))}
              </ButtonWithLoader>
            </div>
          )
        }
      </div>
    </div>
  )
}

const AdminPropertyPublishmentPreview = (props: {
  property: Property
}) => {
  const {property} = props;
  const {t} = useI18N();
  const [open, setOpen] = useState(false);
  const [iframeReady, setIframeReady] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (open) {

      // window.parent.postMessage({
      //   type: "property-preview-ready"
      // }, process.env.REACT_APP_FRONTEND_URL)

      const onMessage = (e: MessageEvent<{
        type: string
      }>) => {
        console.log(e.data);
        if (e.data.type === "property-preview-ready") {
          console.log("READY!!");
          setIframeReady(true);
          
        }
      }
      window.addEventListener("message", onMessage);

      return () => {
        window.removeEventListener("message", onMessage);
      }
    } else {
      setIframeReady(false);
    }
  }, [open]);

  useEffect(() => { 
    console.log({property});
    if (iframeReady) {
      iframeRef.current?.contentWindow?.postMessage({
        type: "property-preview-receive", 
        property,
      }, process.env.REACT_APP_PUBLIC_URL)
    }
  }, [property, iframeReady])

  return (
    !open ? (
      <ButtonWithLoader
        onClick={() => setOpen(true)}
        variant="info"
        faIcon={faBolt}
      >{t(AdminPropertyI18NPrefix + "publishment.preview")}</ButtonWithLoader>
    ) : <>
      <ButtonWithLoader
        onClick={() => setOpen(false)}
        variant="danger"
        faIcon={faXmark}
      >{t(AdminPropertyI18NPrefix + "publishment.closePreview")}</ButtonWithLoader>
      <iframe 
        ref={iframeRef}
        src={`${process.env.REACT_APP_PUBLIC_URL}/property/preview`} 
        width="100%"
        style={{
          height: "88vh",
          minHeight: 420,
          boxShadow: "1px 1px 5px rgba(0, 0, 0, 60%)",
          backgroundColor: "white",
        }}
      />
    </>
  )
}

export const AdminPropertyPublishmentComponentInline = (props: {
  api: APIInstanceWithAuth,
  property: Property,
  publishment: PropertyPublishment,
  onChange: (dispatch: (publishment?: PropertyPublishment) => PropertyPublishment) => void,
  isLoading: boolean,
}) => {
  const {api, property, publishment, onChange, isLoading} = props;
  const modal = useGlobalModal();
  const [googleMapQueryEdit, setGoogleMapQueryEdit] = useState<string>(null);

  const [defaultContactUser, setDefaultContactUser] = useState<User | null | undefined>(undefined);

  const imagesPreviewReload = useRef<() => void>();

  const {toast, } = useAdminComponent();

  const {t, currentLang} = useI18N();
  const titleT2SLoading = useAbortableLoading();
  const descriptionT2SLoading = useAbortableLoading();
  const {adminUser} = useUser();

  const publishedLink = useMemo(() => {
    return property?.publicClone?.publishment?.state === "published" ? `${process.env.REACT_APP_PUBLIC_URL}/property/${property.id}` : null
  }, [property]);
  
  // useCollisionCheck(id && "property-publishment: " + id, (id) => {
  //   modal.warning(t("admin.commons.collisionAlert.title"), t("admin.commons.collisionAlert.bodyWithId", {id}))
  // });

  const userCanPublishPublishment = useMemo(() => {
    return userHasAdminRoles(adminUser, ["PropertyPublishmentPublisherEditor"])
  }, [adminUser])

  const {isSale, isLease, price, rent,
    propertyError, buildingError, districtError, priceStatusError
  } = useMemo(() => {
    const priceStatus = property?.priceStatus;
    const status = property?.priceStatus?.status;

    const isSale = status == "Sale" || status == "SaleAndLease";
    const isLease = status == "Lease" || status == "SaleAndLease";
    return {
      isSale, 
      isLease,
      price: (isSale && priceStatus?.price),
      rent: (isLease && priceStatus?.rent),
      propertyError: !property?.basicInfo?.buildingType,
      buildingError: !property?.basicInfo.building?.id,
      districtError: !property?.basicInfo.building?.district?.id,
      priceStatusError: !(isSale || isLease)
    }
  }, [property])

  const cannotPublish = useMemo(() => propertyError || buildingError || districtError || priceStatusError, [
    propertyError, buildingError, districtError, priceStatusError
  ]);

  useEffect(() => {
    if (publishment.state === "published" && cannotPublish) {
      // onChange?.(publishment => ({...publishment, state: "pending"}))
    }
  }, [cannotPublish, publishment])

  const reloadDefaultContactUser = useCallback(async() => {
    try {
      setDefaultContactUser((await api.property.getDefaultContactUser()) || null)
    } catch (e) {

    }
  }, [])

  useEffect(() => {
    // reload();
    reloadDefaultContactUser();

    return () => {
      titleT2SLoading.abort();
      descriptionT2SLoading.abort();
    }
  }, [])



  return (
    <EntityData
      object={publishment}
      loading={isLoading}
      useForm={false}
      // disabled={disabled}
      floatingSaveBtn
      meta={{
        // "testing": {
        //   type: "entireComponent",
        //   component: <Alert variant="warning" className="mb-0">
        //    <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
        //    Implementation in progress, can't be used yet.
        //   </Alert>
        // },
        "publishedWarningAdmin": {
          hidden: !(userCanPublishPublishment && publishment?.state === "pending" && property?.publicClone?.publishment?.state === "published"),
          type: "entireComponent",
          component: (
            <Alert variant="warning" className="mb-0">
              <div>
                <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                {t(AdminPropertyI18NPrefix + "publishment.publishedWarningAdmin2")}
              </div>
            </Alert>
          )
        },
        "publishedWarning": {
          hidden: !(!userCanPublishPublishment && property?.publicClone?.publishment?.state === "published"),
          type: "entireComponent",
          component: (
            <Alert variant="warning" className="mb-0">
              <div>
                <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                {t(AdminPropertyI18NPrefix + "publishment.publishedWarning")}
              </div>
            </Alert>
          )
        },
        "publishedLink": {  
          hidden: !publishedLink,
          type: "valueComponent",
          title: t(AdminPropertyI18NPrefix + "publishment.publishedLink"),
          component: <a href={publishedLink} target="_blank">{publishedLink}</a>

        },  
        "state": {
          // type: "select",
          // title: t(AdminPropertyI18NPrefix + 'publishment.state'),
          // placeholder: t("admin.commons.select") + "...",
          // required: true,
          // selectOptions: PropertyPublishmentStates.map(state => ({
          //   value: state,
          //   label: <div className="d-flex align-items-center">
          //     <PublishmentStateDisplay state={state} className="me-2" />
          //     {t(AdminPropertyI18NPrefix + 'publishment.states.' + state)}
          //   </div>,
          //   isDisabled: state == "published" && !userHasAdminRoles(adminUser, ["PropertyPublishmentPublisherEditor"])
          // }))
          type: "valueComponent",
          title: t(AdminPropertyI18NPrefix + 'publishment.state'),
          // hidden: !userCanPublishPublishment,
          component: (
            <ButtonGroup size="sm">
              <ButtonWithLoader size="sm" className="px-2" disabled={isLoading || !userCanPublishPublishment} variant={publishment?.state == "unpublished" ? "info": "outline-info"}
                onClick={() => onChange?.(publishment => ({...publishment, state: "unpublished"}))}
              >
                <PublishmentStateDisplay state={"unpublished"}  />
                {t(AdminPropertyI18NPrefix + 'publishment.states.unpublished')}
              </ButtonWithLoader>
              <ButtonWithLoader size="sm" className="px-2" disabled={isLoading || !userCanPublishPublishment} variant={publishment?.state == "pending" ? "info": "outline-info"}
                onClick={() => onChange?.(publishment => ({...publishment, state: "pending"}))}
              >
                <PublishmentStateDisplay state={"pending"}  />
                {t(AdminPropertyI18NPrefix + 'publishment.states.pending')}
              </ButtonWithLoader>

              <ButtonWithLoader size="sm" className="px-2" disabled={cannotPublish || isLoading || !userCanPublishPublishment} variant={publishment?.state == "published" ? "info": "outline-info"}
                onClick={() => onChange?.(publishment => ({...publishment, state: "published"}))}
              >
                <PublishmentStateDisplay state={"published"}  />
                <span
                  className={clsx(cannotPublish && "text-decoration-line-through")}
                >{t(AdminPropertyI18NPrefix + 'publishment.states.published')}</span>
              </ButtonWithLoader>
            </ButtonGroup>
          )

        },

        "property": {
          type: "valueComponent",
          title: <div className="d-flex">
            {t(AdminPropertyI18NPrefix + 'title')}
            {
              property?.id && <Link to={"../../" + AdminPropertyPath + "/" + property.id} className="ms-2">
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} fixedWidth />
              </Link>
            }
          </div>,
          component: property && <div className="d-flex flex-column">
            {(["en", "tc", "sc"] as const).map(lang => (
              <div key={lang} className="d-flex align-items-center" style={{gap: "0.5em"}}>
                <Badge bg="info">{LangDisplayShort(lang)}</Badge>
                <div>{
                  (PropertyBasicInfoPreview(t, {
                    building: property.basicInfo.building,
                    block: property.basicInfo.block,
                    floor: property.basicInfo.floor,
                    flat: property.basicInfo.flat,
                    lang
                  }))
                }</div>
              </div>
            ))}
            <div className="mt-2"></div>
            <div>
              {t(AdminBuildingI18NPrefix + "type.title")}{`: `}
              {property.basicInfo?.buildingType ? t(AdminBuildingI18NPrefix + "type." + property.basicInfo?.buildingType) : <NullDisplay />}
            </div>
            <div>{t(AdminPropertyI18NPrefix + "basicInfo.grossArea")}: {
              property.basicInfo.grossArea ? commify(property.basicInfo.grossArea) + `'` : <NullDisplay />
            }</div>
            <div>{t(AdminPropertyI18NPrefix + "basicInfo.netArea")}: {
              property.basicInfo.netArea ? commify(property.basicInfo.netArea) + `'` : <NullDisplay />
            }</div>
            {
              propertyError && (
                <span className="text-danger mt-2" style={{position: "relative"}}>
                  <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
                  {t(AdminPropertyI18NPrefix + 'publishment.propertyError')}
                  {t(AdminPropertyI18NPrefix + 'publishment.error')}
                  <InputHiddenRequired />
                </span>
              )
            }
          </div>
        },
        "buildingPreview": {
          type: "valueComponent",
          title: <div className="d-flex">
            {t(AdminPropertyI18NPrefix + 'publishment.buildingPreview')}
            {
              property?.basicInfo?.building?.id && <Link to={"../../" + AdminBuildingPath + "/" + property?.basicInfo?.building?.id} target="_blank" className="ms-2">
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} fixedWidth />
              </Link>
            }
          </div>,
          component: property && ((property.basicInfo?.building) ? (
            <div className="d-flex flex-column">
              {
                (["en", "tc", "sc"] as const).map(lang => (
                  <div key={lang} className="d-flex align-items-center" style={{gap: "0.5em"}}>
                    <Badge bg="info">{LangDisplayShort(lang)}</Badge>
                    <div className="me-1">{
                      property.basicInfo?.building?.district?.["name" + LangMap(lang)] || "-"
                    }</div>
                    <div>{
                      property.basicInfo?.building?.["name" + LangMap(lang)] || "-"
                    }</div>
                  </div>
                ))
              }
              {
                !property.basicInfo?.building?.district && (
                  <span className="text-danger" style={{position: "relative"}}>
                    <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
                    {t(AdminPropertyI18NPrefix + 'publishment.districtError')}
                    {t(AdminPropertyI18NPrefix + 'publishment.error')}
                    <InputHiddenRequired />
                  </span>
                )
              }
            </div>
          ) : (
            <span className="text-danger" style={{position: "relative"}}>
              <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
              {t(AdminPropertyI18NPrefix + 'publishment.buildingError')}
              {t(AdminPropertyI18NPrefix + 'publishment.error')}
              <InputHiddenRequired />
            </span>
          ))
        },

        "priceStatusPreview": {
          type: "valueComponent",
          title: <div className="d-flex">
            {t(AdminPropertyI18NPrefix + 'publishment.priceStatusPreview')}
          </div>,
          component: property && ((
            <div className="d-flex flex-column">
              <div>
                {t(AdminPropertyI18NPrefix + "priceStatus.status")}{`: `}
                {
                  property.priceStatus?.status ? t(
                    AdminPropertyI18NPrefix + `priceStatus.statuses.${property.priceStatus?.status}`
                  ) : <NullDisplay />
                }
              </div>
              {
                (isSale || isLease) ? (["en", "tc", "sc"] as const).map(lang => (
                  <div key={lang} className="d-flex align-items-center" style={{gap: "0.5em"}}>
                    <Badge bg="info">{LangDisplayShort(lang)}</Badge>
                    {
                      isSale && <div className="me-1">{
                        `${t(AdminPropertyI18NPrefix + "priceStatus.priceShort", {lng: lang})} ${price != null ? `$${commify(price)}` : t(AdminPropertyI18NPrefix + "priceStatus.sto")}`
                      }</div>
                    }
                    {
                      isSale && isLease && <div className="me-1">|</div>
                    }
                    {
                      isLease && <div className="me-1">{
                        `${t(AdminPropertyI18NPrefix + "priceStatus.rentShort", {lng: lang})} ${rent != null ? `$${commify(rent)}` : t(AdminPropertyI18NPrefix + "priceStatus.sto")}`
                      }</div>
                    }
                    
                    
                  </div>
                )) : (
                  <span className="text-danger" style={{position: "relative"}}>
                    <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
                    {t(AdminPropertyI18NPrefix + 'publishment.priceStatusError')}
                    {t(AdminPropertyI18NPrefix + 'publishment.error')}
                    <InputHiddenRequired />
                  </span>
                )
              }
            </div>
          ))
        },

        
        "titleTc": {
          type: "text",
          title: <div className="d-inline-flex align-items-center">
            {t(AdminPropertyI18NPrefix + 'publishment.content.title') + " - 繁體中文"}
            <a href={`https://translate.google.com/?sl=zh-TW&tl=en&text=${publishment?.titleTc || ""}&op=translate`} target="_blank">
              <ButtonWithLoader variant="outline-info" size="sm" className="ms-2">To EN</ButtonWithLoader>
            </a>
          </div>,
          placeholder: t(AdminPropertyI18NPrefix + 'publishment.content.title') + " - 繁體中文",
          required: true,
          onChange: async(titleTc: string) => {
            const {token, signal} = titleT2SLoading.start();
            try {
              const text = await api.openCC.convertHK2S({text: titleTc}, {signal});
              onChange?.(publishment => ({...publishment, titleSc: text}))
            } catch (e) {
              modal.errorSpecific(e);
            } finally {
              titleT2SLoading.stop(token);
            }
          }
        },
        "titleEn": {
          type: "text",
          title: <div className="d-inline-flex align-items-center">
            {t(AdminPropertyI18NPrefix + 'publishment.content.title') + " - English"}
            <a href={`https://translate.google.com/?sl=en&tl=zh-TW&text=${publishment?.titleEn || ""}&op=translate`} target="_blank">
              <ButtonWithLoader variant="outline-info" size="sm" className="ms-2">轉繁</ButtonWithLoader>
            </a>
          </div>,
          placeholder: t(AdminPropertyI18NPrefix + 'publishment.content.title') + " - English",
          required: true,
        },
        "titleSc": {
          type: "text",
          title: t(AdminPropertyI18NPrefix + 'publishment.content.title') + " - 简体中文",
          required: true,
        },
        "descriptionTc": {
          type: "text-area",
          title: <div className="d-inline-flex align-items-center">
            {t(AdminPropertyI18NPrefix + 'publishment.content.description') + " - 繁體中文"}
            <a href={`https://translate.google.com/?sl=zh-TW&tl=en&text=${publishment?.descriptionTc || ""}&op=translate`} target="_blank">
              <ButtonWithLoader variant="outline-info" size="sm" className="ms-2">To EN</ButtonWithLoader>
            </a>
          </div>,
          placeholder: t(AdminPropertyI18NPrefix + 'publishment.content.description') + " - 繁體中文",
          onChange: async(str: string) => {
            const {token, signal} = descriptionT2SLoading.start();
            try {
              const text = await api.openCC.convertHK2S({text: str}, {signal});
              onChange?.(publishment => ({...publishment, descriptionSc: text}))
            } catch (e) {
              modal.errorSpecific(e);
            } finally {
              descriptionT2SLoading.stop(token);
            }
          }
        },
        "descriptionEn": {
          type: "text-area",
          title: <div className="d-inline-flex align-items-center">
            {t(AdminPropertyI18NPrefix + 'publishment.content.description') + " - English"}
            <a href={`https://translate.google.com/?sl=en&tl=zh-TW&text=${publishment?.descriptionEn || ""}&op=translate`} target="_blank">
              <ButtonWithLoader variant="outline-info" size="sm" className="ms-2">轉繁</ButtonWithLoader>
            </a>
          </div>,
          placeholder: t(AdminPropertyI18NPrefix + 'publishment.content.description') + " - English"
        },
        "descriptionSc": {
          type: "text-area",
          title: t(AdminPropertyI18NPrefix + 'publishment.content.description') + " - 简体中文",
        },

        "showBlock": {
          type: "switch", 
          title: <div>
            <span>{t(AdminPropertyI18NPrefix + 'publishment.content.showBlock')}</span>
            {
              property?.basicInfo?.block && (
                <span className="text-muted fw-normal ms-1">(i.e., {property.basicInfo.block})</span>
              )
            }
          </div>,
          divWidth: {xs: 12, md: 4}
        },

        "floor": {
          type: "select", 
          title: <div>
            <span>{t(AdminPropertyI18NPrefix + 'publishment.content.floor')}</span>
            {
              property?.basicInfo?.floor && (
                <span className="text-muted fw-normal ms-1">(Ref: {property.basicInfo.floor})</span>
              )
            }
          </div>,
          placeholder: "-",
          selectOptions: PropertyPublishmentFloors.map(f => ({
            label: t(AdminPropertyI18NPrefix + 'publishment.content.floors.' + f),
            value: f
          })),
          divWidth: {xs: 12, md: 4}
        },

        "showFlat": {
          type: "switch",
          title: <div>
            <span>{t(AdminPropertyI18NPrefix + 'publishment.content.showFlat')}</span>
            {
              property?.basicInfo?.flat && (
                <span className="text-muted fw-normal ms-1">(i.e., {property.basicInfo.flat})</span>
              )
            }
          </div>,
          divWidth: {xs: 12, md: 4}
        },

        "googleMapQuery": {
          type: "text",
          title: <div className="d-inline-flex align-items-center">{
            t(AdminPropertyI18NPrefix + 'publishment.content.googleMapQuery')
          } <ButtonWithLoader size="sm" className="ms-2" variant="success"
            onClick={() => {
              onChange?.(publishment => ({...publishment, googleMapQuery: [
                property.basicInfo?.building?.district?.nameTc,
                property.basicInfo?.building?.streetNameTc,
                property.basicInfo?.building?.nameTc
              ].filter(str => !!str).join(" ")}))
            }}
          >Auto</ButtonWithLoader></div>,
          titleInfo: t(AdminPropertyI18NPrefix + 'publishment.content.googleMapTips')
        },

        "googeMapPreivew": {
          type: "component",
          hidden: !publishment?.googleMapQuery,
          component: publishment?.googleMapQuery && <div>
            <div key={publishment?.googleMapQuery} style={{width: "100%", height: "320px"}}>
              <iframe
                width="100%"
                height="100%"
                style={{border: 0}}
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src={
                  "https://www.google.com/maps/embed/v1/place?key=AIzaSyAqoKV1mp_Mo8gDmWYyu_6FlyQH06_DJEc&region=HK&language=zh-HK&q=" + (
                    encodeURIComponent(publishment.googleMapQuery?.replace(/\s+/g, "+"))
                  )
                }>
              </iframe> 
            </div>
          </div>
        },

        "highlightedDate": {
          type: "valueComponent", 
          title: t(AdminPropertyI18NPrefix + 'publishment.content.highlightedDate'),
          component: <div className="w-100 d-flex flex-row align-items-center" style={{gap: "0.5em"}}>
            <GenericInput 
              type="switch"
              value={!!publishment?.highlightedDate}
              onChange={(flag) => {
                onChange?.(publishment => ({...publishment, highlightedDate: flag ? new Date() : null}));
              }}
            />
            {
              publishment?.highlightedDate && <span className="text-muted">{moment(publishment.highlightedDate).format("YYYY-MM-DD HH:mm:ss")}</span>
            }
          </div>,
          divWidth: {xs: 12, md: 6},

        },

        "highlighted2Date": {
          type: "valueComponent", 
          title: t(AdminPropertyI18NPrefix + 'publishment.content.highlighted2Date'),
          component: <div className="w-100 d-flex flex-row align-items-center" style={{gap: "0.5em"}}>
            <GenericInput 
              type="switch"
              value={!!publishment?.highlighted2Date}
              onChange={(flag) => {
                onChange?.(publishment => ({...publishment, highlighted2Date: flag ? new Date() : null}));
              }}
            />
            {
              publishment?.highlighted2Date && <span className="text-muted">{moment(publishment.highlighted2Date).format("YYYY-MM-DD HH:mm:ss")}</span>
            }
          </div>,
          divWidth: {xs: 12, md: 6},

        },

        "contactUsers": {
          type: "valueComponent",
          required: true,
          title: t(AdminPropertyI18NPrefix + 'publishment.content.contactUsers'),
          component: <div className="w-100">
            <div className="w-100">
              {
                publishment?.contactUsers && publishment?.contactUsers?.length == 0 && <InputHiddenRequired />
              }
              <UsersSearch api={api} 
                excludeUsers={publishment?.contactUsers?.map(u => u.user)}
                onSelect={user => {
                  // setContactUsers(users => [...users, user]);
                  onChange?.(publishment => ({
                    ...publishment,
                    contactUsers: [
                      ...publishment.contactUsers, 
                      {userId: user.id, user}
                    ]
                  }))
                }}
              />
            </div>
            
            
            {
              publishment?.contactUsers?.length ? <div className="publishment-contacts mt-2">{
                publishment?.contactUsers.map(({user}) => <PropertyPublishmentContactPreview 
                  key={user.id} 
                  user={user} 
                  onDelete={() => {
                    onChange?.(publishment => ({
                      ...publishment,
                      contactUsers: publishment.contactUsers.filter(u => u.userId !== user.id)
                    }))
                  }}
                  isDefault={user.id === defaultContactUser?.id}
                  onDefaultChange={async(flag) => {
                    await api.property.editDefaultContactUser(flag ? user.id : null);
                    await reloadDefaultContactUser();
                  }}
                />)
              }</div> : <></>
            }
          </div>
        },

        

        "tags": {
          type: "valueComponent",
          title: <div className="d-flex align-items-center">
            {t(AdminPropertyI18NPrefix + "publishment.tags.title")}
            <Link to={"../../"+AdminPropertyPublishmentTagsPath} target="_blank" className="ms-2"><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></Link>
          </div>,
          component: <PropertyTagsInput 
            api={api}
            loading={isLoading}
            selectedTags={publishment?.tags?.map(t => t.tag)}
            onChange={(tags) => {
              // setTags(tags);
              onChange?.(publishment => ({
                ...publishment, 
                tags: tags.map(t => ({
                  tagId: t.nameEn,
                  tag: t
                }))
              }))
            }}
          />,
        },

        "photos-section": {
          type: "section",
          title: t(AdminPropertyI18NPrefix + 'publishment.photos')
        },
        
        "images": {
          type: "component",
          component: property?.id && <>
            <BuildingAndPropertyImageTool
              api={api}
              buildingId={property?.basicInfo?.building?.id}
              propertyId={property?.id}
              eager
              selectable
              selectedImageFiles={publishment?.photos?.map(photo => ({
                key: photo
              }))}
              onChangeSelectedImageFiles={(files) => {
                console.log(files);
                onChange?.(publishment => ({
                  ...publishment,
                  photos: files.map(f => f.key)
                }))
              }}
            />
          </>

        },

        "image-previews": {
          type: "valueComponent",
          title: <div className="d-flex align-items-center">
            {t(AdminPropertyI18NPrefix + "publishment.publicPhotosPreview")}
            <Badge bg="success" className="ms-2">{
              publishment?.photos?.length ?? "-"
            }</Badge>
            <Button className="ms-1" size="sm" variant="danger" disabled={!publishment?.photos?.length}
              onClick={async() => {
                if (await modal.confirmDelete()) {
                  onChange?.(publishment => ({
                    ...publishment,
                    photos: []
                  }));
                }
              }}
            >Delete All</Button>
            <div className="p-1 ms-2" style={{cursor: "pointer"}}
              onClick={() => imagesPreviewReload.current?.()}
            >
              <FontAwesomeIcon icon={faArrowsRotate} />
            </div>
          </div>,
          component: <div className="d-flex flex-column w-100" style={{gap: "0.5em"}}>
            <div className="text-muted">
              <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
              {t(AdminPropertyI18NPrefix + "publishment.photosTips")}
            </div>
            <div className="text-muted">
              <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
              {t(AdminPropertyI18NPrefix + "publishment.photosProcessingTips")}
            </div>
            <PropertyPublishmentImagesPreview 
              className="mt-2"
              imageKeys={publishment?.photos}
              reloadRef={fn => imagesPreviewReload.current = fn}
              onChange={(imageKeys) => { 
                console.log({imageKeys});
                onChange?.(publishment => ({
                  ...publishment,
                  photos: imageKeys
                }));
              }}
            />
          </div>
        },

        // "hr": {
        //   type: "hr"
        // }

        "preview": {
          type: "entireComponent",
          component: <AdminPropertyPublishmentPreview property={property} />
        }
        
      }}
      onChange={(newObj) => {
        onChange?.(publishment => newObj);
      }}
    />
  )

}

// export const AdminPropertyPublishmentComponent = (props: {
//   api: APIInstanceWithAuth,
//   id: number,
//   loading: LoadingManager,
// }) => {
//   const {api, id, loading} = props;
//   const [property, setProperty] = useState<Property>(null);
//   const modal = useGlobalModal();
//   const [publishment, setPublishment] = useState<PropertyPublishment>(null);
//   const [googleMapQueryEdit, setGoogleMapQueryEdit] = useState<string>(null);

//   const imagesPreviewReload = useRef<() => void>();

//   const {toast, } = useAdminComponent();

//   const [unsaved, setUnsaved] = useState(false);
//   const {t, currentLang} = useI18N();
//   const titleT2SLoading = useAbortableLoading();
//   const descriptionT2SLoading = useAbortableLoading();
//   const {adminUser} = useUser();

//   useCollisionCheck(id && "property-publishment: " + id, (id) => {
//     modal.warning(t("admin.commons.collisionAlert.title"), t("admin.commons.collisionAlert.bodyWithId", {id}))
//   });

//   const reload = useCallback(async() => {
//     const token = loading.start();
//     try {
//       const property = await api.property.findById(id);
//       setProperty(property);
//       setPublishment(property.publishment || {
//         ...new PropertyPublishment,
//         titleEn: property.basicInfo?.building?.nameEn || "",
//         titleTc: property.basicInfo?.building?.nameTc || "",
//         titleSc: property.basicInfo?.building?.nameSc || "",
//         googleMapQuery: (
//           [
//             property.basicInfo?.building?.district?.nameTc,
//             property.basicInfo?.building?.streetNameTc,
//             property.basicInfo?.building?.nameTc
//           ].filter(str => !!str).join(" ")
//         ),
//         propertyId: id,
//         contactUsers: [],
//         tags: [],
//         state: "pending",
//       });

//       setUnsaved(false);
//       imagesPreviewReload.current?.();
//     } catch (e) {
//       modal.errorSpecific(e);
//     }
//     loading.stop(token);

//   }, [id]);

//   const {isSale, isLease, price, rent,
//     propertyError, buildingError, districtError, priceStatusError
//   } = useMemo(() => {
//     const priceStatus = property?.priceStatus;
//     const status = property?.priceStatus?.status;

//     const isSale = status == "Sale" || status == "SaleAndLease";
//     const isLease = status == "Lease" || status == "SaleAndLease";
//     return {
//       isSale, 
//       isLease,
//       price: (isSale && priceStatus?.price) || 0,
//       rent: (isLease && priceStatus?.rent) || 0,
//       propertyError: !property?.basicInfo?.buildingType,
//       buildingError: !property?.basicInfo.building?.id,
//       districtError: !property?.basicInfo.building?.district?.id,
//       priceStatusError: !(isSale || isLease)
//     }
//   }, [property])

//   const disabled = useMemo(() => propertyError || buildingError || districtError || priceStatusError, [
//     propertyError, buildingError, districtError, priceStatusError
//   ]);

//   useEffect(() => {
//     reload();

//     return () => {
//       titleT2SLoading.abort();
//       descriptionT2SLoading.abort();
//     }
//   }, [])



//   return (
//     <EntityData
//       object={publishment}
//       loading={loading.flag}
//       unsaved={unsaved}
//       useForm
//       disabled={disabled}
//       floatingSaveBtn
//       meta={{
//         "property": {
//           type: "valueComponent",
//           title: <div className="d-flex">
//             {t(AdminPropertyI18NPrefix + 'title')}
//             {
//               property?.id && <Link to={"../../" + AdminPropertyPath + "/" + property.id} className="ms-2">
//                 <FontAwesomeIcon icon={faArrowUpRightFromSquare} fixedWidth />
//               </Link>
//             }
//           </div>,
//           component: property && <div className="d-flex flex-column">
//             {(["en", "tc", "sc"] as const).map(lang => (
//               <div key={lang} className="d-flex align-items-center" style={{gap: "0.5em"}}>
//                 <Badge bg="info">{LangDisplayShort(lang)}</Badge>
//                 <div>{
//                   (PropertyBasicInfoPreview(t, {
//                     building: property.basicInfo.building,
//                     block: property.basicInfo.block,
//                     floor: property.basicInfo.floor,
//                     flat: property.basicInfo.flat,
//                     lang
//                   }))
//                 }</div>
//               </div>
//             ))}
//             <div className="mt-2"></div>
//             <div>
//               {t(AdminBuildingI18NPrefix + "type.title")}{`: `}
//               {property.basicInfo?.buildingType ? t(AdminBuildingI18NPrefix + "type." + property.basicInfo?.buildingType) : <NullDisplay />}
//             </div>
//             <div>{t(AdminPropertyI18NPrefix + "basicInfo.grossArea")}: {
//               property.basicInfo.grossArea ? commify(property.basicInfo.grossArea) + `'` : <NullDisplay />
//             }</div>
//             <div>{t(AdminPropertyI18NPrefix + "basicInfo.netArea")}: {
//               property.basicInfo.netArea ? commify(property.basicInfo.netArea) + `'` : <NullDisplay />
//             }</div>
//             {
//               propertyError && (
//                 <span className="text-danger mt-2" style={{position: "relative"}}>
//                   <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
//                   {t(AdminPropertyI18NPrefix + 'publishment.propertyError')}
//                   {t(AdminPropertyI18NPrefix + 'publishment.error')}
//                   <InputHiddenRequired />
//                 </span>
//               )
//             }
//           </div>
//         },
//         "buildingPreview": {
//           type: "valueComponent",
//           title: <div className="d-flex">
//             {t(AdminPropertyI18NPrefix + 'publishment.buildingPreview')}
//             {
//               property?.basicInfo?.building?.id && <Link to={"../../" + AdminBuildingPath + "/" + property?.basicInfo?.building?.id} target="_blank" className="ms-2">
//                 <FontAwesomeIcon icon={faArrowUpRightFromSquare} fixedWidth />
//               </Link>
//             }
//           </div>,
//           component: property && ((property.basicInfo?.building) ? (
//             <div className="d-flex flex-column">
//               {
//                 (["en", "tc", "sc"] as const).map(lang => (
//                   <div key={lang} className="d-flex align-items-center" style={{gap: "0.5em"}}>
//                     <Badge bg="info">{LangDisplayShort(lang)}</Badge>
//                     <div className="me-1">{
//                       property.basicInfo?.building?.district?.["name" + LangMap(lang)] || "-"
//                     }</div>
//                     <div>{
//                       property.basicInfo?.building?.["name" + LangMap(lang)] || "-"
//                     }</div>
//                   </div>
//                 ))
//               }
//               {
//                 !property.basicInfo?.building?.district && (
//                   <span className="text-danger" style={{position: "relative"}}>
//                     <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
//                     {t(AdminPropertyI18NPrefix + 'publishment.districtError')}
//                     {t(AdminPropertyI18NPrefix + 'publishment.error')}
//                     <InputHiddenRequired />
//                   </span>
//                 )
//               }
//             </div>
//           ) : (
//             <span className="text-danger" style={{position: "relative"}}>
//               <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
//               {t(AdminPropertyI18NPrefix + 'publishment.buildingError')}
//               {t(AdminPropertyI18NPrefix + 'publishment.error')}
//               <InputHiddenRequired />
//             </span>
//           ))
//         },

//         "priceStatusPreview": {
//           type: "valueComponent",
//           title: <div className="d-flex">
//             {t(AdminPropertyI18NPrefix + 'publishment.priceStatusPreview')}
//           </div>,
//           component: property && ((
//             <div className="d-flex flex-column">
//               <div>
//                 {t(AdminPropertyI18NPrefix + "priceStatus.status")}{`: `}
//                 {
//                   property.priceStatus?.status ? t(
//                     AdminPropertyI18NPrefix + `priceStatus.statuses.${property.priceStatus?.status}`
//                   ) : <NullDisplay />
//                 }
//               </div>
//               {
//                 (isSale || isLease) ? (["en", "tc", "sc"] as const).map(lang => (
//                   <div key={lang} className="d-flex align-items-center" style={{gap: "0.5em"}}>
//                     <Badge bg="info">{LangDisplayShort(lang)}</Badge>
//                     {
//                       isSale && <div className="me-1">{
//                         `${t(AdminPropertyI18NPrefix + "priceStatus.priceShort", {lng: lang})} HKD$${numberInShortPublic(price, lang)}`
//                       }</div>
//                     }
//                     {
//                       isSale && isLease && <div className="me-1">|</div>
//                     }
//                     {
//                       isLease && <div className="me-1">{
//                         `${t(AdminPropertyI18NPrefix + "priceStatus.rentShort", {lng: lang})} HKD$${numberInShortPublic(rent, lang)}`
//                       }</div>
//                     }
                    
                    
//                   </div>
//                 )) : (
//                   <span className="text-danger" style={{position: "relative"}}>
//                     <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
//                     {t(AdminPropertyI18NPrefix + 'publishment.priceStatusError')}
//                     {t(AdminPropertyI18NPrefix + 'publishment.error')}
//                     <InputHiddenRequired />
//                   </span>
//                 )
//               }
//             </div>
//           ))
//         },

//         "state": {
//           type: "select",
//           title: t(AdminPropertyI18NPrefix + 'publishment.state'),
//           placeholder: t("admin.commons.select") + "...",
//           required: true,
//           selectOptions: PropertyPublishmentStates.map(state => ({
//             value: state,
//             label: <div className="d-flex align-items-center">
//               <PublishmentStateDisplay state={state} className="me-2" />
//               {t(AdminPropertyI18NPrefix + 'publishment.states.' + state)}
//             </div>,
//             // isDisabled: state == "published" && !userHasAdminRoles(adminUser, ["PropertyPublishmentPublisherEditor"])
//           }))

//         },
//         "titleTc": {
//           type: "text",
//           title: t(AdminPropertyI18NPrefix + 'publishment.content.title') + " - 繁體中文",
//           required: true,
//           onChange: async(titleTc: string) => {
//             const {token, signal} = titleT2SLoading.start();
//             try {
//               const text = await api.openCC.convertHK2S({text: titleTc}, {signal});
//               setPublishment(publishment => ({...publishment, titleSc: text}))
//               setUnsaved(true);
//             } catch (e) {
//               modal.errorSpecific(e);
//             } finally {
//               titleT2SLoading.stop(token);
//             }
//           }
//         },
//         "titleEn": {
//           type: "text",
//           title: <div className="d-inline-flex align-items-center">
//             {t(AdminPropertyI18NPrefix + 'publishment.content.title') + " - English"}
//             <a href={`https://translate.google.com/?sl=zh-HK&tl=en&text=${publishment?.titleTc || ""}&op=translate`} target="_blank">
//               <ButtonWithLoader variant="outline-info" size="sm" className="ms-2">Google Translate</ButtonWithLoader>
//             </a>
//           </div>,
//           placeholder: t(AdminPropertyI18NPrefix + 'publishment.content.title') + " - English",
//           required: true,
//         },
//         "titleSc": {
//           type: "text",
//           title: t(AdminPropertyI18NPrefix + 'publishment.content.title') + " - 简体中文",
//           required: true,
//         },
//         "descriptionTc": {
//           type: "text-area",
//           title: t(AdminPropertyI18NPrefix + 'publishment.content.description') + " - 繁體中文",
//           onChange: async(str: string) => {
//             const {token, signal} = descriptionT2SLoading.start();
//             try {
//               const text = await api.openCC.convertHK2S({text: str}, {signal});
//               setPublishment(publishment => ({...publishment, descriptionSc: text}))
//               setUnsaved(true);
//             } catch (e) {
//               modal.errorSpecific(e);
//             } finally {
//               descriptionT2SLoading.stop(token);
//             }
//           }
//         },
//         "descriptionEn": {
//           type: "text-area",
//           title: <div className="d-inline-flex align-items-center">
//             {t(AdminPropertyI18NPrefix + 'publishment.content.description') + " - English"}
//             <a href={`https://translate.google.com/?sl=zh-HK&tl=en&text=${publishment?.descriptionTc || ""}&op=translate`} target="_blank">
//               <ButtonWithLoader variant="outline-info" size="sm" className="ms-2">Google Translate</ButtonWithLoader>
//             </a>
//           </div>,
//           placeholder: t(AdminPropertyI18NPrefix + 'publishment.content.description') + " - English"
//         },
//         "descriptionSc": {
//           type: "text-area",
//           title: t(AdminPropertyI18NPrefix + 'publishment.content.description') + " - 简体中文",
//         },

//         "showBlock": {
//           type: "switch", 
//           title: <div>
//             <span>{t(AdminPropertyI18NPrefix + 'publishment.content.showBlock')}</span>
//             {
//               property?.basicInfo?.block && (
//                 <span className="text-muted fw-normal ms-1">(i.e., {property.basicInfo.block})</span>
//               )
//             }
//           </div>,
//           divWidth: {xs: 12, md: 4}
//         },

//         "floor": {
//           type: "select", 
//           title: <div>
//             <span>{t(AdminPropertyI18NPrefix + 'publishment.content.floor')}</span>
//             {
//               property?.basicInfo?.floor && (
//                 <span className="text-muted fw-normal ms-1">(Ref: {property.basicInfo.floor})</span>
//               )
//             }
//           </div>,
//           placeholder: "-",
//           selectOptions: PropertyPublishmentFloors.map(f => ({
//             label: t(AdminPropertyI18NPrefix + 'publishment.content.floors.' + f),
//             value: f
//           })),
//           divWidth: {xs: 12, md: 4}
//         },

//         "showFlat": {
//           type: "switch",
//           title: <div>
//             <span>{t(AdminPropertyI18NPrefix + 'publishment.content.showFlat')}</span>
//             {
//               property?.basicInfo?.flat && (
//                 <span className="text-muted fw-normal ms-1">(i.e., {property.basicInfo.flat})</span>
//               )
//             }
//           </div>,
//           divWidth: {xs: 12, md: 4}
//         },

//         "googleMapQuery": {
//           type: "text",
//           title: <div className="d-inline-flex align-items-center">{
//             t(AdminPropertyI18NPrefix + 'publishment.content.googleMapQuery')
//           } <ButtonWithLoader size="sm" className="ms-2" variant="success"
//             onClick={() => {
//               setPublishment(publishment => ({
//                 ...publishment,
//                 googleMapQuery: [
//                   property.basicInfo?.building?.district?.nameTc,
//                   property.basicInfo?.building?.streetNameTc,
//                   property.basicInfo?.building?.nameTc
//                 ].filter(str => !!str).join(" ")
//               }));
//               setUnsaved(true);
//             }}
//           >Auto</ButtonWithLoader></div>,
//           titleInfo: t(AdminPropertyI18NPrefix + 'publishment.content.googleMapTips')
//         },

//         "googeMapPreivew": {
//           type: "component",
//           hidden: !publishment?.googleMapQuery,
//           component: publishment?.googleMapQuery && <div>
//             <div key={publishment?.googleMapQuery} style={{width: "100%", height: "320px"}}>
//               <iframe
//                 width="100%"
//                 height="100%"
//                 style={{border: 0}}
//                 loading="lazy"
//                 allowFullScreen
//                 referrerPolicy="no-referrer-when-downgrade"
//                 src={
//                   "https://www.google.com/maps/embed/v1/place?key=AIzaSyAqoKV1mp_Mo8gDmWYyu_6FlyQH06_DJEc&region=HK&language=zh-HK&q=" + (
//                     encodeURIComponent(publishment.googleMapQuery?.replace(/\s+/g, "+"))
//                   )
//                 }>
//               </iframe> 
//             </div>
//           </div>
//         },

//         "highlightedDate": {
//           type: "valueComponent", 
//           title: t(AdminPropertyI18NPrefix + 'publishment.content.highlightedDate'),
//           component: <div className="w-100 d-flex flex-row align-items-center" style={{gap: "0.5em"}}>
//             <GenericInput 
//               type="switch"
//               value={!!publishment?.highlightedDate}
//               onChange={(flag) => {
//                 setPublishment(publishment => ({...publishment, highlightedDate: flag ? new Date() : null}));
//                 setUnsaved(true);
//               }}
//             />
//             {
//               publishment?.highlightedDate && <span className="text-muted">{moment(publishment.highlightedDate).format("YYYY-MM-DD HH:mm:ss")}</span>
//             }
//           </div>

//         },

//         "contactUsers": {
//           type: "valueComponent",
//           required: true,
//           title: t(AdminPropertyI18NPrefix + 'publishment.content.contactUsers'),
//           component: <div className="w-100">
//             <div className="w-100">
//               {
//                 publishment?.contactUsers && publishment?.contactUsers?.length == 0 && <InputHiddenRequired />
//               }
//               <UsersSearch api={api} 
//                 excludeUsers={publishment?.contactUsers?.map(u => u.user)}
//                 disabled={disabled}
//                 onSelect={user => {
//                   // setContactUsers(users => [...users, user]);
//                   setPublishment(publishment => ({
//                     ...publishment,
//                     contactUsers: [
//                       ...publishment.contactUsers, 
//                       {userId: user.id, user}
//                     ]
//                   }))
//                   setUnsaved(true);
//                 }}
//               />
//             </div>
            
            
//             {
//               publishment?.contactUsers?.length ? <div className="publishment-contacts mt-2">{
//                 publishment?.contactUsers.map(({user}) => <PropertyPublishmentContactPreview 
//                   key={user.id} 
//                   user={user} 
//                   onDelete={() => {
//                     setPublishment(publishment => ({
//                       ...publishment,
//                       contactUsers: publishment.contactUsers.filter(u => u.userId !== user.id)
//                     }))
//                     setUnsaved(true);
//                   }}
//                 />)
//               }</div> : <></>
//             }
//           </div>
//         },

        

//         "tags": {
//           type: "valueComponent",
//           title: <div className="d-flex align-items-center">
//             {t(AdminPropertyI18NPrefix + "publishment.tags.title")}
//             <Link to={"../../"+AdminPropertyPublishmentTagsPath} target="_blank" className="ms-2"><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></Link>
//           </div>,
//           component: <PropertyTagsInput 
//             api={api}
//             loading={loading.flag}
//             disabled={disabled}
//             selectedTags={publishment?.tags?.map(t => t.tag)}
//             onChange={(tags) => {
//               // setTags(tags);
//               setPublishment(publishment => ({
//                 ...publishment, 
//                 tags: tags.map(t => ({
//                   tagId: t.nameEn,
//                   tag: t
//                 }))
//               }))
//               setUnsaved(true);
//             }}
//           />,
//         },

//         "photos-section": {
//           type: "section",
//           title: t(AdminPropertyI18NPrefix + 'publishment.photos')
//         },
        
//         "images": {
//           type: "component",
//           component: property?.id && <>
//             <BuildingAndPropertyImageTool
//               api={api}
//               buildingId={property?.basicInfo?.building?.id}
//               propertyId={property?.id}
//               eager
//               selectable
//               selectedImageFiles={publishment?.photos?.map(photo => ({
//                 key: photo
//               }))}
//               onChangeSelectedImageFiles={(files) => {
//                 console.log(files);
//                 setPublishment(publishment => ({
//                   ...publishment,
//                   photos: files.map(f => f.key)
//                 }))
//                 setUnsaved(true);
//               }}
//             />
//           </>

//         },

//         "image-previews": {
//           type: "valueComponent",
//           title: <div className="d-flex align-items-center">
//             {t(AdminPropertyI18NPrefix + "publishment.publicPhotosPreview")}
//             <Badge bg="success" className="ms-2">{
//               publishment?.photos?.length ?? "-"
//             }</Badge>
//             <div className="p-1 ms-2" style={{cursor: "pointer"}}
//               onClick={() => imagesPreviewReload.current?.()}
//             >
//               <FontAwesomeIcon icon={faArrowsRotate} />
//             </div>
//           </div>,
//           component: <div className="d-flex flex-column w-100" style={{gap: "0.5em"}}>
//             <div className="text-muted">
//               <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
//               {t(AdminPropertyI18NPrefix + "publishment.photosTips")}
//             </div>
//             <div className="text-muted">
//               <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
//               {t(AdminPropertyI18NPrefix + "publishment.photosProcessingTips")}
//             </div>
//             <PropertyPublishmentImagesPreview 
//               className="mt-2"
//               imageKeys={publishment?.photos}
//               reloadRef={fn => imagesPreviewReload.current = fn}
//               onChange={(imageKeys) => {
//                 setPublishment(publishment => ({
//                   ...publishment,
//                   photos: imageKeys
//                 }));
//                 setUnsaved(true);
//               }}
//             />
//           </div>
//         },

//         "hr": {
//           type: "hr"
//         }
        
//       }}
//       onChange={(newObj) => {
//         setPublishment(newObj);
//         setUnsaved(true);
//       }}
//       onSave={async() => {
//         toast.save(async() => {
//           const token = loading.start();
//           try {
//             await api.property.upsertPublishment(property?.id, {
//               ...publishment,
//               contactUserIds: publishment.contactUsers?.map(user => user.userId) ?? [],
//               tagIds: publishment.tags?.map(t => t.tagId),
//             });
//             reload();
//           } catch (e) {
//             modal.errorSpecific(e);
//           }
//           loading.stop(token);
//         }, "property-publishment-save")
        
//       }}
//     />
//   )

// }

// export const AdminPropertyPublishment = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const {api, loading, toast, setSelectedItem, adminUser} = useAdminComponent();
//   const modal = useGlobalModal();
//   const {t, currentLang} = useI18N();
//   const {id: idRaw} = useParams<{id: string}>();



//   const id = useMemo(() => {
//     const id = parseInt(idRaw);
//     if (isNaN(id)) {
//       return null;
//     }
//     return id;
//   }, [idRaw])
//   const title = useMemo(() => {
//     return t(AdminPropertyI18NPrefix + 'publishment.title') + ` (id: ${id})`;
//   }, [id]);
  

//   if (id == null) {
//     return <AdminNotFound />
//   }

//   return (
//     <AdminComponent.Container>
//       <AdminHeaderTitle>{title}</AdminHeaderTitle>
//       <AdminComponent.TitleBar>
//         <AdminComponentTitle 
//           backTo={`../../${AdminPropertyPath}`}
//           faIcon={faCity}
//         >
//           {title}
//         </AdminComponentTitle>
//       </AdminComponent.TitleBar>
//       <div className="hr" />
//       <AdminPropertyPublishmentComponent api={api} id={id} loading={loading} />
//     </AdminComponent.Container>
//   )
// }
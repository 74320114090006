import React from "react";
import "../styles/top-loader.scss";

export class TopLoader extends React.Component<{
  color?: string
}, {
  
}> {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="top-loader" style={{
        backgroundColor: this.props.color
      }}>
      </div>
    )
  }
}
import { AxiosError, AxiosRequestConfig } from "axios";
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useGlobalLoadingScreen } from "../pages/GlobalLoadingScreen";
import { APIInstance, APIInstanceWithAuth, APIInstanceWithAuthForAdmin } from "../api";
import { User } from "../api/entities/user.entity";


const UserContext = React.createContext<{
  user: User | null,
  reloadUser: () => Promise<User>,
  
  adminUser: User | null,
  reloadAdminUser: () => Promise<User>,

  checkAdminUser: (config?: AxiosRequestConfig) => Promise<void>,
}>(null)

export const useUser = () => {
  return useContext(UserContext);
}

export const UserProvider = (props: PropsWithChildren<{}>) => {
  
  const api = useMemo(() => new APIInstanceWithAuth(), []);
  const apiForAdmin = useMemo(() => new APIInstanceWithAuthForAdmin(), []);
  const [user, setUser] = useState<User | null>(null);
  const [adminUser, setAdminUser] = useState<User | null>(null);
  const [isInit, setIsInit] = useState(false);
  const [isError, setIsError] = useState(false);
  const {globalLoading} = useGlobalLoadingScreen();

  const reloadUser = useCallback(async() => {
    const token = globalLoading.start();
    let user: User = null;
    try {
      // const accessToken = api.getAccessToken();
      user = await api.users.getMe();
      setUser(user);
      setIsError(false);
    } catch (e) {
      // console.log((e as any).response.status);
      if ((e as AxiosError)?.response?.status === 401) {
        setUser(null);
      } else {
        setIsError(true);
      }
    } 
    globalLoading.stop(token);
    return user;
  }, [])

  const reloadAdminUser = useCallback(async() => {
    const token = globalLoading.start();
    let adminUser: User = null;
    try {
      // const accessToken = apiForAdmin.getAccessToken();
      adminUser = await apiForAdmin.users.getMe();
      setAdminUser(adminUser);
      setIsError(false);
    } catch (e) {
      console.log((e as any).response.status);
      if ((e as AxiosError)?.response?.status === 401) {
        setAdminUser(null);
      } else {
        setIsError(true);
      }
    } 
    globalLoading.stop(token);
    return adminUser;
  }, []);

  const checkAdminUser = useCallback(async(config?: AxiosRequestConfig) => {
    await apiForAdmin.auth.check(true, config);
  }, []);

  useEffect(() => {
    // reloadUser().then(() => {
    //   reloadAdminUser().then(() => {
    //     setIsInit(true);
    //   })
    // })
    reloadAdminUser().then(() => {
      setIsInit(true);
    })
  }, []);
  
  return (
    <UserContext.Provider
      value={{user, reloadUser, adminUser, reloadAdminUser, checkAdminUser}}
    >
      {
        (isInit && !isError) ? props.children : (
          isError && "Server Error"
        )
      }
    </UserContext.Provider>
  )
}
import { ApiProperty, Column, Entity, JoinColumn, ManyToOne, OmitType, OneToOne, PickType, PrimaryGeneratedColumn, Relation } from "./vars";
import { Exclude, Expose, Transform, TransformFnParams, Type } from "class-transformer";
import { IsBoolean, IsEmail, IsEnum, IsNotEmpty, IsOptional, IsString, IsUrl, ValidateNested } from "class-validator";
import { TimestampEntity } from "./timestamp.entity";
import { UserProfile, UserProfileDto } from "./user-profile.entity";
import { UserAdminInfo, UserAdminInfoDto } from "./user-admin-info.entity";
import { HidePublic, HidePublicClass, ShowPublic } from "./utils/hide-public";

export const AdminRoles = [
  "UserViewer", "UserCreator", "UserEditor", "UserDeleter",
  "SystemInfoViewer",
  "SystemAppInfoViewer",
  "SystemCostSummaryViewer",
  "SystemCurrentStatusViewer",
  "UserActivityViewer",
  "AdminRolePresetViewer", "AdminRolePresetEditor",
  "SystemConfigViewer", "SystemConfigEditor",
  "FileManagerViewer", "FileManagerEditor",
  "I18NCreator", "I18NEditor", "I18NDeleter",
  "DistrictViewer", "DistrictCreator", "DistrictEditor", "DistrictDeleter",
  "DeveloperViewer", "DeveloperCreator", "DeveloperEditor", "DeveloperDeleter",
  "BuildingViewer", "BuildingCreator", "BuildingEditor", "BuildingDeleter",
  "BuildingImageViewer", "BuildingImageCreator", "BuildingImageDeleter",
  "PropertyViewer", "PropertyCreator", "PropertyEditor", "PropertyDeleter",
  "PropertyContactViewer",
  "PropertyImageDeleter",
  "PropertyPublishmentViewer", "PropertyPublishmentEditor",
  "PropertyPublishmentPublisherEditor",
  "PropertyForm3_5Viewer",
  "PropertyListingViewer", "PropertyListingEditor", 
  "PdfUtilViewer", "PdfUtilEditor",
  "BackupViewer", "BackupCreator", "BackupEditor", "BackupDeleter",
] as const;

export type AdminRole = (typeof AdminRoles)[number];

export const AdminRoleSubTypes = [
  "Viewer", "Creator", "Editor", "Deleter"
] as const;

export type AdminRoleSubType = (typeof AdminRoleSubTypes)[number];

// Auth related
export class AuthLoginDto {
  @ApiProperty()
  @IsString()
  @IsNotEmpty()
  @Transform(({ value }: TransformFnParams) => value.trim())
  username: string;
  
  @ApiProperty()
  @IsString()
  @IsNotEmpty()
  password: string

  @ApiProperty()
  @IsOptional()
  @IsBoolean()
  isAdmin: boolean;

  @ApiProperty()
  @IsString()
  @IsOptional()
  googleRecaptchaToken?: string;
}

export class SendResetPasswordEmailDto {
  @IsString()
  @IsNotEmpty()
  usernameOrEmail: string;

  @IsString()
  @IsUrl()
  link: string;

  @IsString()
  @IsOptional()
  lang?: string;

  @ApiProperty()
  @IsString()
  @IsOptional()
  googleRecaptchaToken?: string;
}

export class SendResetPasswordEmailResult {
  maskedEmail: string
}

export class ResetPasswordDto {

  @IsString()
  @IsNotEmpty()
  password: string;
}

export interface AuthLoginResult {
  access_token: string;
  refresh_token: string;
}

export class AuthRefreshAccessTokenDto {
  @ApiProperty()
  @IsNotEmpty()
  refresh_token: string;
}

export interface AuthRefreshAccessTokenResult {
  access_token: string;
}

export class AuthLogoutDto {
  @ApiProperty()
  @IsOptional()
  access_token?: string;
  
  @ApiProperty()
  @IsNotEmpty()
  refresh_token: string
}


export class UserPreview {

  constructor(partial: Partial<UserPreview>) {
    partial.id != null && (this.id = partial.id);
    partial.username != null && (this.username = partial.username);
    // partial.email != null && (this.email = partial.email);
  }

  @ApiProperty()
  @Expose()
  id: User["id"];

  @ApiProperty()
  @Expose()
  username: User["username"];

  // @Exclude({toPlainOnly: true})
  // email: User["email"];

}

export const TransformUserPreview = (): ReturnType<typeof Transform> => {
  return Transform(({value}) => value ? new UserPreview(value) : null)
}


@Entity()
@HidePublicClass()
export class User {

  constructor() {
    this.profile = new UserProfile();
    this.adminInfo = new UserAdminInfo();
  }

  @ApiProperty()
  @PrimaryGeneratedColumn()
  id: number;

  @ApiProperty()
  @Transform(({ value }) => value || "")
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  @Column("varchar", {
    unique: true,
    nullable: true,
  })
  username: string;

  @ApiProperty()
  @IsEmail()
  @IsNotEmpty()
  @IsOptional()
  @Column("varchar", {
    unique: true,
    nullable: true,
  })
  email: string;

  @ApiProperty()
  @IsString()
  @IsOptional()
  @Exclude({toPlainOnly: true})
  @Column("varchar", {
    nullable: true,
    default: null
  })
  password?: string;

  @ApiProperty()
  @IsBoolean()
  @Column("boolean", {
    nullable: false,
    default: false,
  })
  isAdmin: boolean;

  @ApiProperty()
  @IsBoolean()
  @Column("boolean", {
    nullable: false,
    default: false,
  })
  isVerified: boolean;

  @ApiProperty()
  @IsBoolean()
  @Column("boolean", {
    nullable: false,
    default: true,
  })
  isActive: boolean;

  @ApiProperty()
  @IsBoolean()
  @Column("boolean", {
    nullable: false,
    default: false,
  })
  allowMultipleLogins: boolean;

  @ApiProperty()
  @IsEnum(AdminRoles, {each: true})
  @Column("set", {
    enum: AdminRoles,
    default: []
  })
  adminRoles: AdminRole[];

  @ShowPublic()
  @ApiProperty()
  @ValidateNested()
  @Type(() => UserProfile)
  @OneToOne(() => UserProfile, profile => profile.user, {
    onUpdate: "CASCADE",
    onDelete: "SET NULL",
  })
  profile: UserProfile;

  @ShowPublic()
  @ApiProperty()
  @ValidateNested()
  @Type(() => UserAdminInfo)
  @OneToOne(() => UserAdminInfo, adminInfo => adminInfo.user, {
    onUpdate: "CASCADE",
    onDelete: "SET NULL",
  })
  adminInfo: UserAdminInfo;

  @ApiProperty()
  @Column("timestamp", {
    nullable: true,
    precision: 3
  })
  lastLogin: Date;

  @ApiProperty()
  @Column("timestamp", {
    nullable: true,
    precision: 3
  })
  lastActive: Date;
  
  @ApiProperty({type: () => TimestampEntity})
  @Column(() => TimestampEntity)
  @Type(() => TimestampEntity)
  timestamp: TimestampEntity;

  @ApiProperty()
  @ManyToOne(() => User)
  @TransformUserPreview()
  createdBy?: UserPreview;

  @ApiProperty()
  @ManyToOne(() => User)
  @TransformUserPreview()
  updatedBy?: UserPreview;

}

export class UserDtoForAdmin extends OmitType(
  User, ["id", "profile", "adminInfo", "timestamp", "createdBy", "updatedBy"]
) {
  @ApiProperty()
  @ValidateNested()
  @Type(() => UserProfileDto)
  profile: UserProfileDto;

  @ApiProperty()
  @ValidateNested()
  @Type(() => UserAdminInfoDto)
  adminInfo: UserAdminInfoDto;
}

export class UserDto extends PickType(
  User, ["password", "profile"]
){}

// export function UserPreviewTransform(): PropertyDecorator {
//   return Transform(({value}) => value && new UserPreview(value));
// }


export const userHasAdminRoles = (user: User, requiredRoles: (AdminRole | AdminRole[])[] | null) => {

  if (user?.isAdmin && (!requiredRoles || requiredRoles.length == 0)) {
    return true;
  } else if (user?.isAdmin && requiredRoles.some((roleOrRoles) => {
    let rolesAnd = Array.isArray(roleOrRoles) ? roleOrRoles : [roleOrRoles];
    return rolesAnd.every((role) => user.adminRoles?.includes(role))
  })) {
    return true;
  } else {
    return false;
  }
}
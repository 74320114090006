import { ApiProperty } from "../vars";
import { IsBoolean, IsDate, IsEnum, IsNumber, IsOptional, IsString, ValidateIf } from "class-validator";
import { TransformDate } from "../utils/date";

export const PropertyFormTypes = [
  "form3", "form5",
] as const;
export type PropertyFormType = (typeof PropertyFormTypes)[number];

export const PropertyFormAgencyExclusiveTypes = [
  "my", "ourExclusive", "nonExclusive"
] as const;
export type PropertyFormAgencyExclusiveType = (typeof PropertyFormAgencyExclusiveTypes)[number];

export const PropertyFormAgencyRelationships = [
  "single", "dual", "potential-dual"
] as const;
export type PropertyFormAgencyRelationship = (typeof PropertyFormAgencyRelationships)[number];

export class PropertyForm {
  @ApiProperty()
  @IsEnum(["en", "tc"])
  lang: "en" | "tc" = null;
  
  @ApiProperty()
  @IsEnum(PropertyFormTypes)
  formType: PropertyFormType;

  @ApiProperty()
  @IsString()
  refNo: string = "";

  @ApiProperty()
  @IsString()
  vendor: string = "";

  @ApiProperty()
  @IsString()
  agent: string = "";

  @ApiProperty()
  @IsEnum(PropertyFormAgencyExclusiveTypes)
  @IsOptional()
  agencyExclusiveType: PropertyFormAgencyExclusiveType = "nonExclusive";

  @ApiProperty()
  @IsString()
  property: string = "";

  @ApiProperty()
  @TransformDate()
  @IsDate()
  @IsOptional()
  validatyPeriodFrom: Date = null;

  @ApiProperty()
  @TransformDate()
  @IsDate()
  @IsOptional()
  validatyPeriodTo: Date = null;

  @ApiProperty()
  @IsEnum(PropertyFormAgencyRelationships)
  @IsOptional()
  agencyRelationship: PropertyFormAgencyRelationship = "potential-dual";

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  price: number | null = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  floatingRange: number | null = null;

  @ApiProperty()
  @IsString()
  priceShort: string = "";

  @ApiProperty()
  @IsBoolean()
  @IsOptional()
  @ValidateIf((form: PropertyForm) => form.formType == "form5")
  priceIncludeFees?: boolean = true;

  @ApiProperty()
  @IsBoolean()
  @IsOptional()
  agreeViewing: boolean;

  @ApiProperty()
  @IsBoolean()
  @IsOptional()
  agreeKeepKeys: boolean;

  @ApiProperty()
  @IsBoolean()
  @IsOptional()
  agreePassKeys: boolean;

  @ApiProperty()
  @IsBoolean()
  @IsOptional()
  authorizeSubListing: boolean = true;

  @ApiProperty()
  @IsBoolean()
  @IsOptional()
  authorizeAdvertising: boolean = true;

  @ApiProperty()
  @IsBoolean()
  @IsOptional()
  agentSpecificRelatives: boolean;

  @ApiProperty()
  @IsBoolean()
  @IsOptional()
  agreeFormReceived: boolean;

  @ApiProperty()
  @IsString()
  @IsOptional()
  extraTerms?: string = "";

  @ApiProperty()
  @IsString()
  @IsOptional()
  signatoryInfo: string = "";

  @ApiProperty()
  @IsString()
  @IsOptional()
  agentBusinessNumber: string = "";

  @ApiProperty()
  @IsString()
  @IsOptional()
  address: string = "";

  @ApiProperty()
  @IsString()
  @IsOptional()
  phone: string = "";

  @ApiProperty()
  @IsString()
  @IsOptional()
  fax: string = "";

  @ApiProperty()
  @TransformDate()
  @IsDate()
  @IsOptional()
  date: Date;

  @ApiProperty()
  @IsBoolean()
  @IsOptional()
  withSignature: boolean = true;

  @ApiProperty()
  @IsBoolean()
  @IsOptional()
  commissionUponSigning: boolean = false;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  commissionAmount?: number;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  commissionPercentage?: number;

  @ApiProperty()
  @IsBoolean()
  @IsOptional()
  commissionRefundWithInterest: boolean | null = false;

  @ApiProperty()
  @IsString()
  @IsOptional()
  interestTerms?: string = "";

  @ApiProperty()
  @IsString()
  @IsOptional()
  filename?: string = "";
}
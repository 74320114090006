import React, { useContext, useEffect, useMemo, useState } from "react"
import { GlobalSystemConfig, SystemConfig } from "../api/entities/system-config.entity";
import { useGlobalLoadingScreen } from "../pages/GlobalLoadingScreen";
import { CurrencyList } from "../api/entities/currency";
import { APIInstance, APIInstanceWithAuth } from "../api";
import { useI18N } from "./I18NProvider";
import { useLayoutEffect } from "react";



interface SystemConfigContextType {
  globalSystemConfig: GlobalSystemConfig,
  reloadGlobalSystemConfig: () => Promise<void>,
}

const SystemConfigContext = React.createContext<SystemConfigContextType>(null);

export const useGlobalSystemConfig = () => {
  let context = useContext(SystemConfigContext);
  if (!context) {
    throw new Error("Use of useGlobalSystemConfig without enclosing child with <GlobalSystemConfigProvider />");
  }
  return context;
}

export const GlobalSystemConfigProvider = (props: React.PropsWithChildren<{}>) => {
  const api = useMemo(() => new APIInstance(), []);
  const [globalSystemConfig, setGlobalSystemConfig] = useState<GlobalSystemConfig>(null);
  const [isError, setIsError] = useState(false);
  const {globalLoading} = useGlobalLoadingScreen();
  const reloadGlobalSystemConfig = async() => {
    let token = globalLoading.start();
    try {
      setGlobalSystemConfig(await api.systemConfig.get());
    } catch (e) {
      setIsError(true);
      window.setTimeout(() => {
        reloadGlobalSystemConfig();
      }, 1000);
    } finally {
      globalLoading.stop(token);
    }
  }

  useLayoutEffect(() => {
    console.debug("Loading global system config");
    reloadGlobalSystemConfig();
    console.debug("Loaded global system config");
  }, []);

  return (
    <SystemConfigContext.Provider value={{globalSystemConfig, reloadGlobalSystemConfig}}>
      {(globalSystemConfig) ? props.children : (
        isError && "Server Error.."
      )}
    </SystemConfigContext.Provider>
  )
}

import "../../../styles/admin/building-and-property-image-tool.scss";
import { faPlus, faArrowsRotate, faBan, faBuildingUser, faBuilding, faCity, faArrowUpRightFromSquare, faXmark, faSearch, faFilterCircleXmark, faHouseChimneyUser, faMapLocationDot, faCaretDown, faHashtag, faDollarSign, faRuler, faPenRuler, faChartGantt, faLanguage, faGear, faEyeSlash, faEye, faPhoneSquare, faAddressCard, faClockRotateLeft, faTags, faTextWidth, faGears, faLock, faLockOpen, faImage, faImagePortrait, faFileExport, faCircleMinus, faCirclePlus, faCircleInfo, faThumbTack, faUserPen, faExclamationTriangle, faTrash, faRoad, faBookmark, faFlag, faStar, faPhoneSlash, faLink, faArrowUp, faArrowDown, faPaperPlane, faCheck, faCheckCircle, faXmarkCircle, faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { useState, useTransition, useMemo, useCallback, useEffect, useRef, useDeferredValue, ComponentPropsWithRef } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useI18N } from "../../../component/I18NProvider";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";
import _, { keyBy } from "lodash";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "../../../styles/admin/date-range.scss";

import { PropertyListing, PropertyListingStatus } from "../../../api/entities/property/property-listing.entity";
import { AdminPropertyListingPath, AdminPropertyPath } from "../variables";
import { AdminTable } from "../component/Table";
import { Paginated } from "../../../api/entities/pagination";
import moment from "moment";
import { useFindAllQuery } from "../component/FindAllQuery";
import { useGlobalModal } from "../../../component/GlobalModal";
import { ButtonGroup, Modal } from "react-bootstrap";
import { PropertyListingComponent } from "./PropertyListing";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { useLoadingManager } from "../../../utils/loading-manager";
import { NullDisplay } from "./Properties";
import { commify } from "../../../utils/commify";

export const AdminPropertyListingI18NPrefix = "propertyListing.";

export const AdminPropertyFlag = (props: {
  updated: Date
}) => {

  const timeNow = new Date();
  const {updated} = props;

  // console.log({timeNow, updated});
  const timeDiff = (+timeNow - +new Date(updated)) / 1000;

  let flagType: 'red' | 'yellow' | 'green' | null = null;

  // console.log({timeDiff});
  if (timeNow && updated) {
    if (timeDiff > 90 * 24 * 3600) {
      flagType = "red";
    } else if (timeDiff > 60 * 24 * 3600) {
      flagType = "yellow";
    } else if (timeDiff > 30 * 24 * 3600) {
      flagType = "green";
    }
  }

  return flagType ? (
    <FontAwesomeIcon icon={faFlag} 
      className={clsx("me-1",
        flagType === "green" ? "text-success" : 
        flagType === "yellow" ? "text-warning" : "text-danger"
      )}
    />
  ) : <></>
  
}

export const PropertyListingStatusDisplay = (props: {
  status: PropertyListingStatus | null
} & ComponentPropsWithRef<"div">) => {
  const {status, ...divProps} = props;
  const {t} = useI18N();
  const color = useMemo(() => {
    switch (status) {
      case "rejected":
        return "#ea3333";
      case "pending": 
        return "#dd8800"
      case "approved": 
        return "#00aa00";
      default:
        return "#cacaca";
    }
  }, [status])

  return <div {...divProps} style={{textDecoration: "none", position: "relative",
    color, fontWeight: "bold", fontSize: "1.4em"
  }} className={clsx("d-flex align-items-center justify-content-center", divProps.className)}>
    {/* <span>P</span> */}
    {
      status == "rejected" ? <FontAwesomeIcon icon={faXmarkCircle} /> :
      status == "pending" ? <span style={{fontSize: "0.66em"}}>{t(AdminPropertyListingI18NPrefix+"statuses.pending")}</span> :
      status == "approved" ? <FontAwesomeIcon icon={faCheckCircle} /> : 
      <FontAwesomeIcon icon={faXmark} fixedWidth />
    }
  </div>
}

export const AdminPropertyListings = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openedPropertyListingId, setOpenedPropertyListingId] = useState<PropertyListing["id"]>(null);
  const openPropertyListingId = useCallback((id: PropertyListing["id"]) => {
    id ? searchParams.set("id", id) : searchParams.delete("id");
    setSearchParams(searchParams);
    setOpenedPropertyListingId(id)
  }, [])

  const [prevId, setPrevId] = useState<PropertyListing["id"]>(null);
  const [nextId, setNextId] = useState<PropertyListing["id"]>(null);

  const navigateId = useCallback((val: -1 | 1) => {
    if (val == -1 && prevId) {
      openPropertyListingId(prevId)
    } else if (val == 1 && nextId) {
      openPropertyListingId(nextId)
    }
  }, [prevId, nextId])
  const [modalOpen, setModalOpen] = useState(false);

  const updateLoading = useLoadingManager();
  const {api, loading, toast, adminUser, reloadPropertyListingCountPendingItems} = useAdminComponent({selectedItem: AdminPropertyListingPath});
  const {t} = useI18N();
  const modal = useGlobalModal();
  const [propertyListings, setPropertyListings] = useState<Paginated<PropertyListing> | null>(null);

  const [mode, setMode] = useState<"all" | "pending">("pending");

  const {findAllQuery, renderPaginationUI, renderSearchInput, setSearchInput, scrollTopRef, setSortByDropdownOpen} 
  = useFindAllQuery({
    loading: loading.flag, paginationMeta: propertyListings?.meta,
    shouldUseSearchParams: true
    // sortKeys: ["id", "nameEn", "nameTc", "nameSc", "timestamp.created", "timestamp.updated"]
  })
  
  const reload = async() => {
    let token = loading.start();
    try {
      const propertyListingsData = await api.propertyListing.findAll(findAllQuery);
      reloadPropertyListingCountPendingItems();
      setPropertyListings({
        ...propertyListingsData,
        items: propertyListingsData.items.map(item => ({
          ...item,
          // isOnline: user.lastActive && (new Date().getTime() - new Date(user.lastActive).getTime() < 5 * 60 * 1000)
        }))
      });
    } catch (e) {
      modal.errorSpecific(e);
    }
    loading.stop(token);
  }
  
  useEffect(() => {
    reload();
  }, [findAllQuery]);

  useEffect(() => {
    if (openedPropertyListingId) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [openedPropertyListingId])

  useEffect(() => {
    const searchParamId = searchParams.get("id");
    if (searchParamId != openedPropertyListingId) {
      setOpenedPropertyListingId(searchParamId);
    }
  }, [searchParams])

  useEffect(() => {
    if (propertyListings?.items && openedPropertyListingId) {
      const index = propertyListings.items.findIndex(p => p.id === openedPropertyListingId);
      
      if (index !== -1) {
        setPrevId(propertyListings.items[index - 1]?.id);
        setNextId(propertyListings.items[index + 1]?.id);
      }
    }
  }, [openedPropertyListingId, propertyListings])

  useEffect(() => {
    const keyDown = async(e: KeyboardEvent) => {
      console.log(e.key);
      switch (e.key) {
        case "PageUp": 
          navigateId(-1)
        break;
        case "PageDown":
          navigateId(1)
        break;
      }
    }

    window.addEventListener("keydown", keyDown);
    return () => {
      window.removeEventListener("keydown", keyDown);
    }
  }, [navigateId]);


  
  return <>
    <AdminComponent.Container >
      <AdminHeaderTitle>{(openedPropertyListingId ? `${openedPropertyListingId} | ` : "")+t(AdminPropertyListingI18NPrefix + 'title')}</AdminHeaderTitle>
      <AdminComponent.TitleBar>
        <AdminComponentTitle 
          faIcon={faPaperPlane}
        >
          {t(AdminPropertyListingI18NPrefix + 'title')}
        </AdminComponentTitle>


        <ButtonGroup>
          <ButtonWithLoader faIcon={faArrowsRotate} loading={loading.flag}
            onClick={() => reload()}
          >
            
          </ButtonWithLoader>
        </ButtonGroup>
      </AdminComponent.TitleBar>

      
      <div className="hr" />
      
      <React.Fragment >
        <div className="d-flex align-items-center mb-2" style={{gap: "0.5em", flexWrap: "wrap"}}>
          {renderPaginationUI({className: "mx-auto"})}
        </div>
        <div className="card overflow-card">
          <AdminTable
            sticky
            loading={loading.check()}
            // editable
            keys={{
              id: {
                content: "id",
                shrink: true,
              },
              status: {
                content: t(AdminPropertyListingI18NPrefix + 'status')
              },
              created: {
                content: t(AdminPropertyListingI18NPrefix + 'submissionTime')
              },
              type: {
                content: t(AdminPropertyListingI18NPrefix + 'type.title')
              },
              isSelling: {
                content: t(AdminPropertyListingI18NPrefix + 'isSelling')
              },
              isLeasing: {
                content: t(AdminPropertyListingI18NPrefix + 'isLeasing')
              },
              buildingName: {
                content: t(AdminPropertyListingI18NPrefix + 'building')
              },
              districtName: {
                content: t(AdminPropertyListingI18NPrefix + 'district')
              },
              block: {
                content: t(AdminPropertyListingI18NPrefix + 'block')
              },
              floor: {
                content: t(AdminPropertyListingI18NPrefix + 'floor')
              },
              flat: {
                content: t(AdminPropertyListingI18NPrefix + 'flat')
              },
              price: {
                content: t(AdminPropertyListingI18NPrefix + 'price')
              },
              rent: {
                content: t(AdminPropertyListingI18NPrefix + 'rent')
              },
              
            }}

            rows={
              propertyListings && (
                propertyListings.items.map((propertyListing) => {
                  // const editLink = `../${AdminUserPath}?id=${user.id}`;
                  // const componentId = "user-" + user.id;
                  return {
                    id: propertyListing.id,
                    content: {
                      id: propertyListing.id,
                      status: <PropertyListingStatusDisplay status={propertyListing.status} />,
                      created: <div className="d-flex align-items-center justify-content-center"
                      >
                        {moment(propertyListing.created).format("DD/MM/YYYY HH:mm:ss")}
                      </div>,
                      type: t(AdminPropertyListingI18NPrefix + 'type.' + propertyListing.type),
                      isSelling: propertyListing.isSelling ? <FontAwesomeIcon icon={faCheck} />: "",
                      isLeasing: propertyListing.isLeasing ? <FontAwesomeIcon icon={faCheck} />: "",
                      districtName: propertyListing.districtName,
                      buildingName: propertyListing.buildingName,
                      block: propertyListing.block,
                      floor: propertyListing.floor,
                      flat: propertyListing.flat,
                      price: propertyListing.isSelling ? `$${commify(propertyListing.price)}` : <NullDisplay />,
                      rent: propertyListing.isLeasing ? `$${commify(propertyListing.rent)}` : <NullDisplay />,

                    },
                    // editLink: `../${AdminUserPath}?id=${user.id}`,
                    rowLink: `../${propertyListing.id}`,
                    onRowLinkClick: (e) => {
                      e.preventDefault();
                      openPropertyListingId(propertyListing.id);
                    }
                  }
                })
              )
            }
          />
        </div>
      </React.Fragment>
    </AdminComponent.Container>
    <Modal 
      className="building-and-property-image-tool-preview py-3"
      backdropClassName="building-and-property-image-tool-preview-backdrop"
      show={modalOpen}
      onHide={() => {
        // setModalOpen(false);
        openPropertyListingId(null);
        // setTimeout(() => {
        //   openPropertyListingId(null);
        // }, 1000);
      }}
      centered
      dialogClassName="container-xs"
      // backdropClassName="building-and-property-image-tool-preview-backdrop"
    >
      <Modal.Header closeButton>
        {openedPropertyListingId}
      </Modal.Header>

      <Modal.Body className="px-2 pt-2" style={{}}>
        <PropertyListingComponent 
          className="h-100"
          id={openedPropertyListingId}
          onUpdate={(updatedPropertyListing) => {
            const id = updatedPropertyListing?.id;
            if (id) {
              setPropertyListings(listings => {
                if (listings) {
                  const index = listings?.items?.findIndex(l => l.id === id);
                  if (index !== -1) {
                    listings.items[index] = updatedPropertyListing;
                  }
                  return {...listings};
                } else {
                  return listings;
                }
              })
            }
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithLoader faIcon={faArrowLeft} disabled={!prevId} onClick={() => navigateId(-1)}/>
        <ButtonWithLoader faIcon={faArrowRight} disabled={!nextId} onClick={() => navigateId(1)}/>
      </Modal.Footer>
    </Modal>

    
    {/* {
      openedPropertyListingId && <AdminComponent.Container>
        <AdminPropertyComponent mode={"edit"} id={openedPropertyId} goToPublishment={openPropertyGoToPublishment} onBackToOverride={(payload) => {
            console.log({payload});
            
            setOpenedPropertyId(null);
            switch (payload) {
              case "PageUp":
                console.log("TEST");
                propertiesRef?.openPropertyOffset(openedPropertyId, -1);
              break;

              case "PageDown":
                propertiesRef?.openPropertyOffset(openedPropertyId, 1);
              break;
            }
            // window.history.back();
          }}
          onSave={(property) => {
            console.log({propertiesRef});
            propertiesRef?.updateProperty?.(property.id, property);
            console.log("ONSAVE!");
          }}
        
        />
      </AdminComponent.Container>
    } */}
  </>
    
}
  
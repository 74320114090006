import { useEffect, useMemo, useRef, useState } from "react"

export const useDetectAutofill = (props?: {
  onAutofill?: () => void,
  delayOnload?: number
}) => {
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const valueOnInputRef = useRef({
    username: null as string, 
    password: null as string,
    time: null as Date,
  })

  const onloadTime = useMemo(() => new Date(), []);

  const valueOnChangeRef = useRef({
    username: null as string,
    password: null as string,
    time: null as Date,
  })

  const lastAutofillTriggerTime = useRef(0);
  
  useEffect(() => {
    if (usernameRef.current && passwordRef.current) {

      // console.log(valueOnInputRef)

      // For normal user manual input, trigger onInput first, then after blur, onChange
      // For autofill, onInput and onChange happen in millseconds 

      const onInput = () => {
        // console.log({onInput: true, valueOnInputRef, newUsername, newPassword});

        valueOnInputRef.current = {
          username: usernameRef.current.value,
          password: passwordRef.current.value,
          time: new Date,
        }

        // console.log("OnInputTime: " + valueOnInputRef.current.time.getTime())
      }

      const onChange = () => {
        // console.log({onChange:true, valueOnChangeRef, newUsername, newPassword});
        const oldUsername = valueOnChangeRef.current.username;
        const oldPassword = valueOnChangeRef.current.password;

        valueOnChangeRef.current = {
          username: usernameRef.current.value,
          password: passwordRef.current.value,
          time: new Date,
        }
        
        const diffOnChangeOnInput = valueOnChangeRef.current.time.getTime() - valueOnInputRef.current.time.getTime();

        // console.log("OldValueOnChangeTime:" + valueOnChangeRef.current.time.getTime());
        if (diffOnChangeOnInput <= 50) {
          if (
            (oldUsername != valueOnChangeRef.current.username || oldPassword != valueOnChangeRef.current.password) &&
            Math.abs(lastAutofillTriggerTime.current - valueOnChangeRef.current.time.getTime()) > 50
          ) {
            console.log("OnAutoFill")

            console.log("Time from onload: " + (+valueOnChangeRef.current.time - +onloadTime));
            if (!props.delayOnload || (+valueOnChangeRef.current.time - +onloadTime > props.delayOnload)) {
              props?.onAutofill?.();
            }
            lastAutofillTriggerTime.current = valueOnChangeRef.current.time.getTime();
          }
        }
      }

      usernameRef.current.addEventListener("input", onInput)
      passwordRef.current.addEventListener("input", onInput)
      usernameRef.current.addEventListener("change", onChange)
      passwordRef.current.addEventListener("change", onChange)

      return () => {
        usernameRef.current.removeEventListener("input", onInput)
        passwordRef.current.removeEventListener("input", onInput)
        usernameRef.current.removeEventListener("change", onInput)
        passwordRef.current.removeEventListener("change", onInput)
      }
    }
  }, [usernameRef.current, passwordRef.current])

  return {usernameRef, passwordRef}
}
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { FindAllQuery } from "../entities/findAllQuery";
import { Paginated } from "../entities/pagination";
import { AnalyzeUserActivityDto, AnalyzeUserActivityResult } from "../entities/user-activity.entity";
import { UserAdminRolePreset, UserAdminRolePresetDto } from "../entities/user-admin-role-preset.entity";
import { AuthLoginDto, AuthLoginResult, User, UserDto, UserDtoForAdmin } from "../entities/user.entity";
import { APIBase } from "./apiBase";
import { APIEncodeQueryString } from "./helpers";

export default class UsersAPI extends APIBase {
  

  constructor(axios: AxiosInstance) {
    super(axios, "users");
  }

  async getMe(): Promise<User> {
    return (await this.axios.get<User>(
      this.base + "/me"
    )).data;
  }

  async updateMe(dto: UserDto): Promise<User> {
    return (await this.axios.put<User>(
      this.base + "/me", dto
    )).data;
  }

  async findById(id: number): Promise<User> {
    return (await this.axios.get<User>(
      this.base + "/" + id
    )).data;
  }

  async update(id: number, dto: UserDtoForAdmin): Promise<User> {
    return (await this.axios.put<User>(
      this.base + "/" + id, dto
    )).data;
  }

  async findAll(query: FindAllQuery, config?: AxiosRequestConfig): Promise<Paginated<User>> {
    return (await this.axios.get<Paginated<User>>(
      this.base + "?" + APIEncodeQueryString(FindAllQuery.toJson(query)), config
    )).data;
  }

  async create(dto: UserDtoForAdmin): Promise<User> {
    return (await this.axios.post<User>(
      this.base + "/", dto
    )).data;
  }

  async getAllAdminRolePreset() {
    return (await this.axios.get<UserAdminRolePreset[]>(
      this.base + "/admin-role-preset"
    )).data;
  }

  async updateAdminRolePreset(dto: UserAdminRolePresetDto) {
    (await this.axios.post<void>(
      this.base + "/admin-role-preset", dto
    ));
  }

  async deleteAdminRolePreset(id: UserAdminRolePreset["id"]) {
    (await this.axios.delete<void>(
      this.base + "/admin-role-preset/" + id
    ));
  }

  async analyzeActivity(id: User["id"], dto: AnalyzeUserActivityDto): Promise<AnalyzeUserActivityResult> {
    return (await this.axios.get<AnalyzeUserActivityResult>(
      this.base + "/" + id + "/analyze-activity?" + APIEncodeQueryString(dto as any)
    )).data;
  }

}
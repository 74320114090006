import { useRef, useReducer, useMemo } from "react";

export interface LoadingManager {
  start: () => number;
  stop: (token: number) => void;
  check: () => boolean;
  clear: () => void;

  flag: boolean,
};

export const useLoadingManager = (): LoadingManager => {
  const loadingFlagsCount = useRef(1);
  const loadingFlagsReducer = (flags: Set<number>, action: {type: 'start' | 'stop', token: number}) => {
    // console.log(action);
    let newFlags = new Set(flags);
    if (action.type == 'start') {
      newFlags.add(action.token);
    } else if (action.type == 'stop') {
      newFlags.delete(action.token);
      // console.log(newFlags);
    }
    return newFlags;
  }
  const [loadingFlags, dispatchLoadingFlags] = useReducer(loadingFlagsReducer, new Set<number>());
  
  const flag = useMemo(() => loadingFlags.size > 0, [loadingFlags.size]);
  return {
    start: () => {
      let token = loadingFlagsCount.current++;
      dispatchLoadingFlags({type: "start", token});
      return token;
    }, 
    stop: (token: number) => {
      dispatchLoadingFlags({type: "stop", token})
    },
    check: () => {
      return loadingFlags.size > 0;
    },
    clear: () => {
      loadingFlags.clear();
    },
    flag
  }
}
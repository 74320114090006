import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { AdminLogin } from "./src/Login";
import { AdminDashboard } from "./src/Dashboard";
import { AdminNotFound } from "./src/NotFound";
import { AdminLogout } from "./src/Logout";
import { AdminUsers } from "./src/Users";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faBuildingUser, faCity, faCloudArrowDown, faFileInvoiceDollar, faFilePdf, faFolderTree, faGaugeHigh, faGears, faGlobe, faHouse, faHouseChimneyUser, faLanguage, faMapLocation, faPaperPlane, faPersonDigging, faQrcode, faShirt, faUsers } from "@fortawesome/free-solid-svg-icons";
import { AdminUser } from "./src/User";
import { ToastContainer } from "react-toastify";
import { AdminSystemConfig } from "./src/SystemConfig";
import { AdminComponent } from "./component/AdminComponent";
import { AdminFileManager } from "./src/FileManager";
import { FileViewerProvider } from "../../component/file-manager/src/FileViewer";
import { FileManagerModalProvider } from "./component/FileManagerModal";
import { AdminI18Ns } from "./src/I18Ns";
import { AdminI18N } from "./src/I18N";
import 'react-toastify/dist/ReactToastify.css';
import "./../../styles/admin/styles.scss";
import { AdminDashboardPath, AdminUsersPath, AdminSystemFileManagerPath, AdminI18NPath, AdminSystemConfigPath, AdminLoginPath, AdminLogoutPath, AdminUserPath, AdminDistrictsPath, AdminBuildingPath, AdminDevelopersPath, AdminPropertyPath, AdminBackupsPath, AdminPdfUtilPath, AdminPropertyPublishmentPath, AdminQRCodeUtilPath, AdminPropertyPublishmentTagsPath, AdminPropertyListingPath } from "./variables";
import { AdminDistricts } from "./src/Districts";
import { AdminBuildings } from "./src/Buildings";
import { AdminDevelopers } from "./src/Developers";
import { AdminBuilding } from "./src/Building";
import { AdminProperties } from "./src/Properties";
import { AdminProperty } from "./src/Property";
import { AdminBackups } from "./src/Backups";
import { AdminPdfUtil } from "./src/PdfUtil";
import { AdminRole } from "../../api/entities/user.entity";
// import { AdminPropertyPublishment } from "./src/PropertyPublishment";
import { AdminQRCodeUtil } from "./src/QRCodeUtil";
import { AdminPropertyPublishmentTags } from "./src/PropertyPublishmentTags";
import { AdminPropertyListings } from "./src/PropertyListings";
export * from "./variables";

interface SidebarItem {
  key: string;
  icon: JSX.Element;
  title: string;
  requiredRoles?: AdminRole[] | AdminRole[][];
}

export const SidebarItems: SidebarItem[] = [
  {
    key: AdminDashboardPath,
    icon: <FontAwesomeIcon icon={faGaugeHigh} fixedWidth/>,
    title: "admin.dashboard.title"
  },
  {
    key: AdminPropertyPath,
    icon: <FontAwesomeIcon icon={faHouseChimneyUser} fixedWidth />,
    title: "admin.property.title",
    requiredRoles: ["PropertyViewer"]
  },
  {
    key: AdminPropertyListingPath,
    icon: <FontAwesomeIcon icon={faPaperPlane} fixedWidth />,
    title: "propertyListing.title",
    requiredRoles: ["PropertyListingViewer"]
  },
  {
    key: AdminBuildingPath,
    icon: <FontAwesomeIcon icon={faCity} fixedWidth/>,
    title: "admin.buildings.title",
    requiredRoles: ["BuildingViewer"]
  },
  {
    key: AdminDevelopersPath,
    icon: <FontAwesomeIcon icon={faPersonDigging} fixedWidth/>,
    title: "admin.developers.title",
    requiredRoles: ["DeveloperViewer"]
  },
  {
    key: AdminDistrictsPath,
    icon: <FontAwesomeIcon icon={faMapLocation} fixedWidth/>,
    title: "admin.districts.title",
    requiredRoles: ["DistrictViewer"]
  },
  {
    key: AdminQRCodeUtilPath,
    icon: <FontAwesomeIcon icon={faQrcode} fixedWidth />,
    title: "admin.qrCodeUtil.title"
  },
  {
    key: AdminUsersPath,
    icon: <FontAwesomeIcon icon={faUsers} fixedWidth/>,
    title: "admin.users.title",
    requiredRoles: ["UserViewer"]
  },
  {
    key: AdminSystemFileManagerPath,
    icon: <FontAwesomeIcon icon={faFolderTree} fixedWidth/>,
    title: "admin.fileManager.title",
    requiredRoles: ["FileManagerViewer"]
  },
  {
    key: AdminI18NPath,
    icon: <FontAwesomeIcon icon={faGlobe} fixedWidth/>,
    title: "admin.i18n.title",
    requiredRoles: ["I18NEditor"]
  },
  {
    key: AdminBackupsPath,
    icon: <FontAwesomeIcon icon={faCloudArrowDown} />,
    title: "admin.backups.title",
    requiredRoles: ["BackupViewer"]
  },
  {
    key: AdminPdfUtilPath,
    icon: <FontAwesomeIcon icon={faFilePdf} fixedWidth/>,
    title: "admin.pdfUtil.title",
    requiredRoles: ["PdfUtilViewer"]
  },
  {
    key: AdminSystemConfigPath,
    icon: <FontAwesomeIcon icon={faGears} fixedWidth/>,
    title: "admin.systemConfig.title",
    requiredRoles: ["SystemConfigViewer"]
  }
]

const BACKEND_URL_BASE_PATH = process.env.REACT_APP_BACKEND_URL!;
const AWS_S3_PATH = process.env.REACT_APP_AWS_S3_URL!;

export default function() {

  return (
    <FileManagerModalProvider basePath={BACKEND_URL_BASE_PATH} awsS3Path={AWS_S3_PATH}>
    
      <FileViewerProvider basePath={BACKEND_URL_BASE_PATH} awsS3Path={AWS_S3_PATH}>
        <ToastContainer
          className="admin-toast-container"
          toastClassName="admin-toast"
          containerId="admin-toast"
          enableMultiContainer
          position="bottom-right"
          autoClose={5000}
          limit={3}
          theme="colored"
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
        />
      
        <Routes>
          <Route index element={<Navigate to={AdminLoginPath} />} />
          <Route path={AdminLoginPath} element={<AdminLogin />} />
          <Route path={AdminLogoutPath} element={<AdminLogout />} />

          <Route path="*" element={<AdminComponent children={<Outlet />}/>}>
            <Route path={AdminDashboardPath} element={<AdminDashboard />} />
            <Route path={AdminUsersPath} element={<AdminUsers />} />
            <Route path={AdminUserPath}>
              <Route index element={<AdminUser mode="edit"/>} />
              <Route path="me" element={<AdminUser mode="me" />} />
              <Route path="new" element={<AdminUser mode="create"/>} />
            </Route>
            <Route path={AdminSystemConfigPath}>
              <Route index element={<Navigate to={"general"} />} />
              <Route path=":id" element={<AdminSystemConfig />} />
            </Route>
            <Route path={AdminI18NPath}>
              <Route index element={<AdminI18Ns />} />
              <Route path="new" element={<AdminI18N mode="create" />} />
              <Route path=":id" element={<AdminI18N mode="edit" />} />
            </Route>
            <Route path={AdminSystemFileManagerPath}>
              <Route path="*" element={
                <AdminFileManager basePath={BACKEND_URL_BASE_PATH} awsS3Path={AWS_S3_PATH}/>
              }/>
            </Route>
            <Route path={AdminDistrictsPath}>
              <Route index element={<AdminDistricts />} />
            </Route>
            <Route path={AdminDevelopersPath}>
              <Route index element={<AdminDevelopers />} />
            </Route>
            <Route path={AdminBuildingPath}>
              <Route index element={<AdminBuildings />} />
              <Route path="new" element={<AdminBuilding mode="create" />} />
              <Route path=":id" element={<AdminBuilding mode="edit" />} />
            </Route>
            <Route path={AdminPropertyPublishmentPath}>
              {/* <Route index element={<AdminProperties />} />
              <Route path="new" element={<AdminProperty mode="create" />} /> */}
              <Route index element={<AdminNotFound />} />
              {/* <Route path=":id" element={<AdminPropertyPublishment />} /> */}
            </Route>
            <Route path={AdminPropertyPath}>
              <Route index element={<AdminProperties />} />
              <Route path="new" element={<AdminProperty mode="create" />} />
              <Route path=":id" element={<AdminProperty mode="edit" />} />
            </Route>
            <Route path={AdminPropertyListingPath}>
              <Route index element={<AdminPropertyListings />} />
              {/* <Route path="new" element={<AdminProperty mode="create" />} /> */}
              {/* <Route path=":id" element={<AdminProperty mode="edit" />} /> */}
            </Route>
            <Route path={AdminPropertyPublishmentTagsPath}>
              <Route index element={<AdminPropertyPublishmentTags />} />
            </Route>
            <Route path={AdminQRCodeUtilPath}>
              <Route index element={<AdminQRCodeUtil />} />
            </Route>
            <Route path={AdminBackupsPath}>
              <Route index element={<AdminBackups />} />
            </Route>
            <Route path={AdminPdfUtilPath}>
              <Route index element={<AdminPdfUtil />} />
            </Route>
            <Route path="*" element={<AdminNotFound />} />
          </Route>
        </Routes>
      </FileViewerProvider>
    </FileManagerModalProvider>
  )
}
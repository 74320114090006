import { faCircleDown, faCircleUp } from "@fortawesome/free-regular-svg-icons";
import { faBan, faCircleInfo, faCircleMinus, faCirclePlus, faEye, faFileExport, faFileImport, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useCallback, useMemo, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { APIInstanceWithAuthForAdmin } from "../../../api";
import { PdfFonts } from "../../../api/entities/pdf-util.entity";
import { PropertyGeneratePdfEvent, PropertyPdfContent } from "../../../api/entities/property/property.entity";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider";
import { useAbortableLoading } from "../../../utils/abortable-loading";
import { downloadFromUrl } from "../../../utils/download-from-url";
import { useLoadingManager } from "../../../utils/loading-manager";
import { AdminPropertyI18NPrefix } from "../src/Property";
import { ImageFile, BuildingAndPropertyImageTool } from "./BuildingAndPropertyImageTool";
import { EntityData, EntityDataMeta, EntityDataMetaValue } from "./EntityData";
import { GenericInput } from "./GenericInput";
import { Buffer } from 'buffer';
import path from "path-browserify";
import mimeDb from "mime-db";
import { downloadImage } from "../../../utils/download-image";

export const PropertyPdfGeneratorDetails = (props: {
  details: PropertyPdfContent["details"],
  onChange: (details: PropertyPdfContent["details"]) => void
}) => {

  const modal = useGlobalModal();

  const meta: EntityDataMeta = useMemo(() => {
    return props.details.reduce((acc, detail, index) => {
      const isFirst = index == 0;
      const isLast = index == props.details.length - 1;

      const metaValue: EntityDataMetaValue = {
        id: props.details[index].key,
        type: "valueComponent",
        divWidth: {xs: 12, md: 6, xl: 4},
        title: (
          <div className="d-flex align-items-center" style={{gap: "0.5rem"}}>
            <GenericInput
              type="text"
              value={detail.key}
              style={{fontWeight: "bold"}}
              className="me-1"
              onChange={(value: string) => {
                const details = [...props.details];
                details[index].key = value;
                props.onChange(details);
              }}
            />
            <FontAwesomeIcon icon={faCirclePlus} className="text-success" size="lg" style={{cursor: "pointer"}}
              onClick={() => {
                let details = [...props.details];
                details.splice(index + 1, 0, {key: "", value: ""});
                props.onChange(details);
              }}
            />
            <FontAwesomeIcon icon={faCircleMinus} className="text-danger" size="lg" style={{cursor: "pointer"}}
              onClick={async() => {
                const isEmpty = detail.key == "" && detail.value == "";
                if (isEmpty || await modal.confirmDelete()) {
                  let details = [...props.details];
                  details.splice(index, 1);
                  props.onChange(details);
                }
              }}
            />
            <FontAwesomeIcon icon={faCircleUp} className="text-warning" size="lg" style={{
                cursor: "pointer",
                opacity: !isFirst ? 1 : 0.5,
                pointerEvents: isFirst ? "none" : null
              }}
              onClick={() => {
                let details = [...props.details];
                [details[index - 1], details[index]] = [details[index], details[index - 1]];
                props.onChange(details);
              }}
            />
            <FontAwesomeIcon icon={faCircleDown} className="text-warning" size="lg" style={{
                cursor: "pointer",
                opacity: !isLast ? 1 : 0.5,
                pointerEvents: isLast ? "none" : null
              }}
              onClick={() => {
                let details = [...props.details];
                [details[index], details[index + 1]] = [details[index + 1], details[index]];
                props.onChange(details);
              }}
            />
            
          </div>
          
        ),
        component: (
          <GenericInput
            type="text-area"
            value={detail.value}
            onChange={(value: string) => {
              const details = [...props.details];
              details[index].value = value;
              props.onChange(details);
            }}
          />
        )
      }
      return {
        ...acc,
        ["detail" + index]: metaValue
      }
    }, {})
  }, [props.details, props.onChange])
  return (
    <EntityData
      object={{}}
      meta={meta}
      noMarginCompensate
      flippable
    />
  )
}
export const PropertyPdfGenerator = (props: {
  api: APIInstanceWithAuthForAdmin,
  loading?: boolean,
  onGenerate: (lang: PropertyPdfContent["lang"]) => Omit<PropertyPdfContent, "lang" | "detailsLineSpacing" | "font"> & {
    filename: string
  }
}) => {
  const {api, loading} = props;
  const [generated, setGenerated] = useState(false);
  const {t} = useI18N();
  const [pdfContent, setPdfContent] = useState<PropertyPdfContent & {
    filename: string, 
    buildingId: number,
    propertyId: number,
  }>({
    ...(new PropertyPdfContent()),
    filename: "",
    buildingId: null,
    propertyId: null,
  }); 
  const [selectedImageFiles, setSelectedImageFiles] = useState<ImageFile[]>([]);
  const [generatePdfEvent, setGeneratePdfEvent] = useState<PropertyGeneratePdfEvent>(null);
  
  const modal = useGlobalModal();
  const downloadLoading = useAbortableLoading();
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);

  
  return (
    <div className="d-flex flex-column" style={{gap: "0.3rem"}}>
      {
        pdfContent && (
          <EntityData
            object={pdfContent}
            onChange={newObj => {
              setPdfContent(obj => ({...obj, ...newObj}));
            }}
            noMarginCompensate
            meta={{
              lang: {
                type: "select",
                title: t(AdminPropertyI18NPrefix + "pdf.language"),
                placeholder: t("admin.commons.select") + "...",
                selectOptions: ["tc", "en"].map(lang => ({
                  value: lang,
                  label: t(AdminPropertyI18NPrefix + "pdf.languages." + lang)
                })),
                onChangeOverride: async(lang: PropertyPdfContent["lang"]) => {
                  console.log(lang);
                  if (props.onGenerate && !generated || (generated && await modal.confirmUnsaved())) {

                    const content = props.onGenerate(lang);
                    setGenerated(true);

                    const LinesForOnePage = 14; // Estimation
                    let totalLines = 0; // Estimation

                    let filename = content.filename;
                    if (!filename || filename.trim() == "") {
                      filename = "property";
                    }

                    const details = content.details.reduce((acc, detail) => {
                      if (!detail || detail.value == null || detail.value == "") {
                        return acc;
                      }
                      totalLines += detail.value.split("\n").length;
                      return [...acc, detail];
                    }, []);

                    const detailsLineSpacing = totalLines ? Math.max(0.9, parseFloat((LinesForOnePage / totalLines).toFixed(2))) : 1;

                    setPdfContent({
                      ...pdfContent,
                      ...content,
                      lang,
                      detailsLineSpacing,
                      filename,
                      details
                    });
                    setSelectedImageFiles([]);
                  }
                },
                selectIsClearable: false,
                // postfix: (
                //   <ButtonWithLoader
                //     className="ms-2"
                //     faIcon={faFileImport}
                //     disabled={!pdfContent.lang}
                //     variant="warning"
                //     onClick={() => {
                //       if (props.onGenerate) {
                //         const content = props.onGenerate(pdfContent.lang);
                //         setGenerated(true);

                //         const LinesForOnePage = 14; // Estimation
                //         let totalLines = 0; // Estimation

                //         let filename = content.title1?.split("\n")?.map(str => str && str.trim())?.filter(str => str && str != "")?.join("-");
                //         if (!filename || filename.trim() == "") {
                //           filename = "property";
                //         }

                //         const details = content.details.reduce((acc, detail) => {
                //           if (!detail || detail.value == null || detail.value == "") {
                //             return acc;
                //           }
                //           totalLines += detail.value.split("\n").length;
                //           return [...acc, detail];
                //         }, []);

                //         const detailsLineSpacing = totalLines ? Math.max(0.9, parseFloat((LinesForOnePage / totalLines).toFixed(2))) : 1;

                //         setPdfContent({
                //           ...pdfContent,
                //           ...content,
                //           detailsLineSpacing,
                //           filename,
                //           details
                //         });
                //         setSelectedImageFiles([]);
                //       }
                      
                //     }}
                //   >{t(AdminPropertyI18NPrefix + "pdf.generate")}</ButtonWithLoader>
                // )
              },
              generatedInfo: {
                hidden: !generated,
                type: "entireComponent",
                component: <>
                  <div className="text-muted entity-data-item">
                    <div><FontAwesomeIcon icon={faCircleInfo} fixedWidth className="me-2"/>
                    {t(AdminPropertyI18NPrefix + "pdf.hints")}</div>
                  </div>
                </>
              },
              title1: {
                hidden: !generated,
                type: "text-area",
                title: t(AdminPropertyI18NPrefix + "pdf.content.title1"),
                divWidth: {xs: 12, md: 6}
              },
              title2: {
                hidden: !generated,
                type: "text-area",
                title: t(AdminPropertyI18NPrefix + "pdf.content.title2"),
                divWidth: {xs: 12, md: 6}
              },
              details: {
                hidden: !generated || !pdfContent.details?.length,
                type: "entireComponent",
                component: <PropertyPdfGeneratorDetails 
                  details={pdfContent.details}
                  onChange={(details) => {
                    console.log(details?.length);
                    setPdfContent(content => ({...content, details: [...details]}))
                  }}
                />
              },
              contactLine: {
                hidden: !generated,
                type: "text",
                title: t(AdminPropertyI18NPrefix + "pdf.content.contactLine"),
                divWidth: {xs: 12, md: 6}
              },
              licenseNumber: {
                hidden: !generated,
                type: "text",
                title: t(AdminPropertyI18NPrefix + "pdf.content.licenseNumber"),
                divWidth: {xs: 12, md: 6}
              },
              images: {
                hidden: !generated,
                type: "component",
                component: (
                  <BuildingAndPropertyImageTool
                    eager
                    api={api}
                    selectable
                    buildingId={pdfContent.buildingId}
                    propertyId={pdfContent.propertyId}
                    selectedImageFiles={selectedImageFiles}
                    onChangeSelectedImageFiles={(imageFiles) => {
                      setPdfContent(pdfContent => ({...pdfContent, 
                        // base64Images: imageFiles.map(imageFile => imageFile.processedImageSrc)
                      }))
                      setSelectedImageFiles(imageFiles);
                    }}
                  />
                )
              },
              imageTitle: {
                hidden: !generated,
                disabled: selectedImageFiles?.length == 0,
                title: t(AdminPropertyI18NPrefix + "pdf.content.imageTitle"),
                type: "text",
              },
              imageAddWatermark: {
                hidden: !generated,
                disabled: selectedImageFiles?.length == 0,
                title: t(AdminPropertyI18NPrefix + "pdf.imageAddWatermark"),
                type: "switch"
              },
              font: {
                hidden: !generated,
                type: "select",
                title: t(AdminPropertyI18NPrefix + "pdf.font"),
                divWidth: {xs: 12, sm: 8, md: 4, lg: 3},
                selectIsClearable: false,
                selectOptions: Object.entries(PdfFonts).map(([key, value]) => ({
                  label: <span style={{
                    fontFamily: value.fontFamily,
                    fontWeight: value.light ? 300 : null
                  }}>{value.title}</span>,
                  value: key
                }))
              },
              detailsLineSpacing: {
                hidden: !generated,
                type: "valueComponent",
                title: t(AdminPropertyI18NPrefix + "pdf.content.detailsLineSpacing"),
                divWidth: {xs: 12, sm: 4, md: 3, lg: 2},
                component: (
                  <div className="w-100 d-flex align-items-center" style={{gap: "0.5rem"}}>
                    <input 
                      type="number" 
                      inputMode="decimal"
                      className="form-control"
                      value={pdfContent.detailsLineSpacing ?? ""}
                      onChange={(event) => {
                        let value = parseFloat(event.target.value);
                        if (isNaN(value)) {value = null;}
                        setPdfContent(content => ({...content, detailsLineSpacing: value}))
                      }}
                      onBlur={(event) => {
                        let value = parseFloat(event.target.value);
                        if (isNaN(value)) {setPdfContent(content => ({...content, detailsLineSpacing: 1}))}
                        
                      }}
                      min={0.1}
                      max={5}
                      step={0.1}
                    />
                    <FontAwesomeIcon icon={faCircleMinus} className="text-info" size="lg" style={{cursor: "pointer"}}
                      onClick={() => {
                        setPdfContent(content => ({...content, detailsLineSpacing: Math.max(0.1, parseFloat((content.detailsLineSpacing - 0.05).toFixed(2)))}))
                      }}
                    />
                    <FontAwesomeIcon icon={faCirclePlus} className="text-info" size="lg" style={{cursor: "pointer"}}
                      onClick={() => {
                        setPdfContent(content => ({...content, detailsLineSpacing: Math.min(5, parseFloat((content.detailsLineSpacing + 0.05).toFixed(2)))}))
                      }}
                    />
                  </div>
                )
              },
              filename: {
                hidden: !generated,
                type: "text",
                divWidth: {xs: 12, sm: 12, md: 5, lg: 7},
                title: t(AdminPropertyI18NPrefix + "pdf.content.filename"),
                onChangeOverride: str => {
                  setPdfContent(content => ({...content, filename: str?.replaceAll(/[\/\?]/g, "")}))
                }
              },
              generatePdf: {
                hidden: !generated,
                type: "component",
                component: (
                  <>
                    <ButtonWithLoader
                      loading={downloadLoading.flag}
                      faIcon={faFileExport}
                      variant="success"
                      hidden={!!generatePdfEvent}
                      onClick={async() => {
                        const {token, signal} = downloadLoading.start();
                        
                        try {
                          setDownloadUrl(null);
                          
                          const base64Images = (await Promise.all(selectedImageFiles.map(async (file) => {
                            try {
                              const presignedUrl = file.presignedUrl;
                              return downloadImage(presignedUrl, "base64", {
                                noCache: true
                              })
                            } catch (e) {
                              return null;
                            }
                          }))).filter(base64 => !!base64)
                          const pdfContentTmp: typeof pdfContent = {
                            ...pdfContent, base64Images
                          }

                          const fileToken = await api.property.generatePdf(pdfContentTmp, (event) => {
                            console.log(event);
                            setGeneratePdfEvent(event);
                          }, {signal});
                          const filename = `${pdfContentTmp.filename}.pdf`;
                          const url = api.pdfUtil.getPdfUrl(filename, fileToken);
                          // console.log(url);
                          const popup = window.open(url, "_blank");
                          
                          if (popup) {
                            popup.focus();
                          } else {
                            console.warn("Popup blocked");
                            downloadFromUrl(url, filename);
                          }
                          setDownloadUrl(url);
                          
                        } catch (e) {
                          console.warn(e);
                          modal.errorSpecific(e);
                        }
                        setGeneratePdfEvent(null);
                        downloadLoading.stop(token);
                      }}
                    >{t(AdminPropertyI18NPrefix + "pdf.generatePdf")}</ButtonWithLoader>
                    {
                      generatePdfEvent && (
                        <div className="d-flex flex-column">
                          <div>
                            {
                              t(AdminPropertyI18NPrefix + "pdf.event." + generatePdfEvent.stage, {
                                index: generatePdfEvent.processedImageCount, count: generatePdfEvent.imageCount
                              })
                            }
                          </div>
                          <div className="d-flex align-items-center justify-content-center">
                            <ProgressBar
                              variant="success"
                              className="flex-grow-1 mt-1 h-100 me-2"
                              animated
                              now={
                                [generatePdfEvent.stage].reduce((acc, stage) => {
                                  switch (stage) {
                                    case "uploading":
                                      return 5;
                                    case "start": 
                                      return 10;
                                    case "processing-image":
                                      let imageProgress = (generatePdfEvent.processedImageCount) / generatePdfEvent.imageCount;
                                      return 20 + imageProgress * (90 - 20);
                                    case "generating-pdf":
                                      return 95;
                                  }
                                }, 0)
                              }
                            />
                            <ButtonWithLoader variant="outline-danger" faIcon={faBan} size="sm" onClick={() => {
                              downloadLoading.abort();
                            }}>
                              {t(AdminPropertyI18NPrefix + "pdf.cancel")}
                            </ButtonWithLoader>
                          </div>
                        </div>
                      )
                    }
                  </>
                )
              },
              downloadUrl: {
                type: "component",
                hidden: !downloadUrl,
                component: (
                  <div style={{marginTop: "-0.6rem"}} className="text-center">
                    <b><a href={downloadUrl} target="_blank" className="text-info text-decoration-none">&gt;&gt; {t(AdminPropertyI18NPrefix + "pdf.autoOpenFailed")} &lt;&lt;</a></b>
                  </div>
                )
              },
              generatePdfInfo: {
                hidden: !generated,
                type: "entireComponent",
                component: <>
                  <div className="text-muted entity-data-item">
                    <div><FontAwesomeIcon icon={faCircleInfo} fixedWidth className="me-2"/>
                    {t(AdminPropertyI18NPrefix + "pdf.linkHints")}</div>
                  </div>
                </>
              },
            }}
          />
        )
      }
    </div>
  )
}
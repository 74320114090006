import { IsInt, IsNumber, IsOptional, IsString } from "class-validator";
import { ApiProperty, BadRequestException, PickType } from "./vars";
import { Type } from "class-transformer";
import { PaginationOptions } from "./pagination";

type FindAllQuerySortType = "ASC" | "DESC" | undefined;

export type EntitySort<Entity = any> = Record<keyof Entity | string, FindAllQuerySortType>;

export const EntityFilterNull = "null()";
export type EntityFilterTypeMap = {
  exact: string | number | Date,
  contains: EntityFilterTypeMap["exact"],
  multiple: EntityFilterTypeMap["exact"][],
  range: [EntityFilterTypeMap["exact"], EntityFilterTypeMap["exact"]]
};

export type EntityFilterFieldType = keyof EntityFilterTypeMap;

export const EntityFilterFieldTypes: EntityFilterFieldType[] = [
  "exact", "contains", "multiple", "range"
];


export interface EntityFilterField<T extends EntityFilterFieldType = any> {
  type: EntityFilterFieldType,
  value: EntityFilterTypeMap[T]
}

export type EntityFilter = Record<string, EntityFilterField<any>>;

export function createEntityFilter<T extends EntityFilterFieldType>(type: T, value: EntityFilterTypeMap[T]): EntityFilterField {
  return {
    type, value
  }
}

export function getEntityFilterValueArray<T extends keyof EntityFilterTypeMap = any>(
  filter: EntityFilterField<T>
): EntityFilterTypeMap[T][] {
  if (!filter) {
    return null;
  }

  // console.log(filter.value);
  if (Array.isArray(filter.value) && filter.type != "range") {
    return filter.value as EntityFilterTypeMap[T][]
  } else {
    return [filter.value]
  }
}

export function entityFilterValuesSet(
  filter: EntityFilterField<"exact" | "multiple">, 
  action: "add" | "delete", 
  item: EntityFilterTypeMap["exact"], 
  exactIfSingleValue = true
): EntityFilterField<"exact" | "multiple"> {
  if (filter && filter.type != "exact" && filter.type != "multiple") {
    console.warn("Filter type IS NOT multiple");
    return filter as any;
  }

  if (filter?.type == "exact") {
    return entityFilterValuesSet({
      type: "multiple",
      value: [filter.value as EntityFilterTypeMap["exact"]]
    }, action, item, exactIfSingleValue)
  }

  if (!filter) {
    return entityFilterValuesSet({
      type: "multiple",
      value: []
    }, action, item, exactIfSingleValue)
  }

  const set = new Set(filter.value as EntityFilterTypeMap["multiple"]);
  if (action == "delete") {
    console.log(set);
    console.log("DELETING!!");
    console.log(item);
    set.delete(item?.toString())
  } else {
    set.add(item?.toString())
  }
  // console.log(item);
  // console.log(set);

  if (exactIfSingleValue && set.size <= 1) {
    const value = Array.from(set)?.[0];
    console.log(set);
    console.log(value);
    return value != null ? {
      type: "exact",
      value: value
    } : null
  }
  return {
    type: "multiple",
    value: Array.from(set)
  }

}


export class FindAllQuery<Entity = any> {
  // Ref: https://dev.to/avantar/validating-numeric-query-parameters-in-nestjs-gk9
  @ApiProperty()
  @IsInt()
  @Type(() => Number)
  @IsOptional()
  page?: number = 1;

  @ApiProperty()
  @Type(() => Number)
  @IsNumber()
  @IsOptional()
  limit?: number = 10;

  @ApiProperty()
  @IsString()
  @IsOptional()
  searchInput?: string | null = null;

  sort?: EntitySort<Entity> | null = null;

  @ApiProperty()
  @IsString()
  @IsOptional()
  sortDto?: string = null;

  filter?: EntityFilter | null;

  @ApiProperty()
  @IsString()
  @IsOptional()
  filterDto?: string = null;

  groupBy?: string[] = null;

  @ApiProperty()
  @IsString()
  @IsOptional()
  randDto?: string = null;

  @ApiProperty()
  rand?: boolean = false;

  @ApiProperty()
  @IsString()
  @IsOptional()
  groupByDto?: string = null;

  static toJson<E>(query: FindAllQuery<EntitySort<E>>) {
    const {page, limit, searchInput, sort, sortDto, filter, filterDto, groupBy, groupByDto, rand, ...otherQuery} = query;
    
    const obj: any = {};
    
    if (page != null || !isNaN(page)) {
      obj.page = page;
    }
    if (limit != null || !isNaN(limit)) {
      obj.limit = limit;
    }
    if (searchInput != null) {
      obj.searchInput = searchInput;
    }

    if (sort) {
      const sorts: string[] = [];
      Object.entries(sort).forEach(([key, value]) => {
        if (value) {
          sorts.push(`${key}:${value}`)
        }
      })
      obj.sortDto = sorts.length ? sorts.join('|') : undefined;
    }

    if (filter) {
      const filters: string[] = [];
      Object.entries(filter).map(([key, value]) => {
        // console.log({key, value});
        if (value != null) {
          switch (value.type) {
            case "exact":
            case "contains":
              filters.push(`${key}:${value.type}(${value.value})`)
            break;

            case "multiple":
            case "range":
              filters.push(`${key}:${value.type}(${(value.value as Array<any>)?.join(",")})`);
            break;
          }
        }
      })
      // console.log(filters);
      obj.filterDto = filters.length ? filters.join('|') : undefined;
    }

    if (groupBy) {
      obj.groupByDto = groupBy.join("|");
    }

    if (!!rand) {
      obj.randDto = "1";
    }

    return {...obj, ...otherQuery};
  }



  static getPagination<EntitySort>(query: FindAllQuery<EntitySort>): PaginationOptions {
    const {page, limit} = query;
    return {page, limit}
  }

  static getSearchInput<EntitySort>(query: FindAllQuery<EntitySort>): string {
    return query.searchInput;
  }

  static getRand(
    query: FindAllQuery 
  ): boolean {
    if (query?.randDto != null) {
      return true;
    } else {
      return false;
    }
  }

  static getSort<E = any>(
    query: FindAllQuery<EntitySort<E>>, 
    override?: (sort: EntitySort<E>, sortType: FindAllQuerySortType, key: string) => any
  ): EntitySort<E> {
    if (query.sort) {return query.sort}

    const sortDto = query.sortDto?.trim();
    if (!!sortDto && sortDto != "") {
      let sort = {};
      sortDto.split("|").map(subsort => {
        const splits = subsort.split(":");
        if (splits.length != 2) {
          // throw new BadRequestException("FindAllQuery filterDto error");
          return null;
        }
        const [key, sortType] = splits;

        if (key?.trim() != "" && ["ASC", "DESC"].includes(sortType)) {
          
          if (override) {
            override(sort as any, sortType as any, key);
          } else {
            sort[key] = sortType;
          }
        }
      }).filter(obj => !!obj)
      // console.log(sort);
      return sort as EntitySort;
    }
    return null;
  }


  static getFilterRaw<EntitySort>(
    query: FindAllQuery<EntitySort>
  ): EntityFilter {
    if (query.filter) {return query.filter}

    const filterDto = query.filterDto?.trim();
    if (!!filterDto && filterDto != "") {
      let filter = {};
      filterDto.split("|").map(subFilter => {
        const splits = subFilter.split(":");
        if (splits.length != 2) {
          // throw new BadRequestException("FindAllQuery sortDto error");
          return null;
        }
        const [key, filterValue] = splits;

        // console.log(filterValue);
        const filterValueMatch = filterValue.match(new RegExp(`^(${EntityFilterFieldTypes.join("|")})\\((.*)\\)$`))

        if (key?.trim() != "" && filterValueMatch) {
          const [wholeStr, type, str] = filterValueMatch;

          filter[key] = {type, value: (typeof str === "string" && (type == "multiple" || type == "range")) ? (
            str.split(",")
          ) : str};
        }
      }).filter(obj => !!obj)
      // console.log(filter);
      return filter;
    }
    return null;
  }

  static getFilter<EntitySort>(
    query: FindAllQuery<EntitySort>, 
    override?: (filter: any, key: string, value: any) => any
  ): Record<string, EntityFilterField> {
    if (query.filter) {return query.filter}

    const filterDto = query.filterDto?.trim();
    if (!!filterDto && filterDto != "") {
      let filter = {};
      filterDto.split("|").map(subFilter => {
        const splits = subFilter.split(":");
        if (splits.length != 2) {
          // throw new BadRequestException("FindAllQuery sortDto error");
          return null;
        }
        const [key, filterValue] = splits;

        // console.log(filterValue);
        const filterValueMatch = filterValue.match(new RegExp(`^(${EntityFilterFieldTypes.join("|")})\\((.*)\\)$`))

        if (key?.trim() != "" && filterValueMatch) {
          const [wholeStr, type, str] = filterValueMatch;
          if (override) {
            override(filter as any, key, str);
          } else {
            filter[key] = str;
          }
        }
      }).filter(obj => !!obj)
      // console.log(filter);
      return filter;
    }
    return null;
  }

  static getGroupBy(query: FindAllQuery<EntitySort>): string[] {

    if (query.groupBy) {return query.groupBy}

    const groupByDto = query.groupByDto?.trim();
    if (!!groupByDto && groupByDto != "") {
      return groupByDto.split("|");
    }
    return null;
  }

}

export class FindAllQueryPagination extends PickType(
  FindAllQuery, ["page", "limit"] as const
) {}

export class FindAllQuerySearchInput extends PickType(
  FindAllQuery, ["searchInput"] as const
) {}

export class FindAllQuerySort extends PickType(
  FindAllQuery, ["sort"] as const
) {}




import clsx from "clsx";
import { ComponentPropsWithRef } from "react";
import { Badge } from "react-bootstrap";
import { Lang } from "../../../utils/lang-map";


export const LangPreview = (
  props: ComponentPropsWithRef<"div"> & {
    value: {
      [lang in Lang]?: string
    },
    additionalFields?: Record<string, React.ReactNode>
  }
) => {
  const {value, additionalFields, className, style, ...divProps} = props;
  return (
    <div {...divProps} className={clsx("d-flex flex-column justify-content-center", className)} style={{gap: "0.3em", ...style}}>
      {
        value.tc && <div className="d-flex align-items-center"><Badge bg="info" className="me-2">繁</Badge>{value.tc}</div>
      }
      {
        value.sc && <div className="d-flex align-items-center"><Badge bg="info" className="me-2">简</Badge>{value.sc}</div>
      }
      {
        value.en && <div className="d-flex align-items-center"><Badge bg="info" className="me-2">EN</Badge>{value.en}</div>
      }
      {
        additionalFields && Object.entries(additionalFields)?.map(([key, value]) => {
          return <div className="d-flex align-items-center"><Badge bg="info" className="me-2">{key}</Badge>{value}</div>
        })
      }
    </div>
  )
}
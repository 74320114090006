import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, { AxiosError, CanceledError } from 'axios';
import clsx from 'clsx';
import React, { useCallback, useContext, useReducer, useRef, useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import parseHtml from 'html-react-parser';
import { GenericInput, GenericInputType } from '../pages/Admin/component/GenericInput';
import { AdminRolesForbiddenExceptionResponsePayload, CustomHttpExceptionResponse, CustomHttpExceptionType, EditConflictExceptionResponsePayload, InvalidTokenExceptionRequestPayload } from '../api/entities/custom-http-exception-types';
import { useI18N } from './I18NProvider';
import moment from 'moment';


export class GlobalModalError {
  title: React.ReactNode;
  body: React.ReactNode;
  isGlobalModalError: boolean = true;

  constructor(title: React.ReactNode, body: React.ReactNode) {
    this.title = title;
    this.body = body;
  }
}

export interface GlobalModalSingle {
  id?: any;
  open?: boolean;
  title?: React.ReactNode;
  body?: React.ReactNode;
  focusElement?: HTMLElement;
  footer?: React.ReactNode;
  modalProps?: ModalProps;
  promptInputValue?: any;
  onSubmit?: (event) => void;
  onClose?: (value?: any) => void;
}

const GlobalModalContext = React.createContext<{
  removeAll: () => void
  closeAll: () => void
  alert: (
    title: React.ReactNode, 
    body: React.ReactNode,
    options?: {
      buttonText?: string,
      buttonClassName?: string,
    }
  ) => Promise<void>
  confirm: (
    title: React.ReactNode, 
    body: React.ReactNode,
    showCancel?: boolean,
    yesContent?: React.ReactNode,
    noContent?: React.ReactNode,
  ) => Promise<boolean>
  prompt: (
    title: React.ReactNode,
    options: {
      type: GenericInputType,
      initialValue?: string,
      placeholder?: string,
      hint?: string,
    },
    showCancel?: boolean,
    buttonText?: string,
  ) => Promise<any>
  confirmDelete: (
    withValidation?: boolean
  ) => Promise<boolean>,
  confirmUnsaved: () => Promise<boolean>,
  success: (
    title: React.ReactNode, 
    body: React.ReactNode
  ) => Promise<void>
  warning: (
    title: React.ReactNode, 
    body: React.ReactNode
  ) => Promise<void>
  error: (
    title: React.ReactNode, 
    body: React.ReactNode
  ) => Promise<void>
  errorSpecific: (
    error: AxiosError | GlobalModalError | any
  ) => Promise<void>
}>(null);

export const useGlobalModal = () => {
  return useContext(GlobalModalContext);
}

export const GlobalModalProvider = (props: React.PropsWithChildren<{}>) => {


  
  const focusElementRef = useRef();
  const {t} = useI18N();


  const modalsReducer = useCallback((modals: GlobalModalSingle[], action: {
    type: 'create' | 'close' | 'prompt-edit' | 'close-all' | 'remove-all'
    modal?: GlobalModalSingle // for create
    index?: number            // for close
    promptEditValue?: any     // for prompt-edit
  }) => {
    // console.log("modalsReducer");
    // console.log(action);
    let newModals : GlobalModalSingle[] = [...modals];

    
    switch (action.type) {
      case 'create':
        // Prevent duplicate non-null id
        if (!modals.find(modal => modal.id != null && modal.id === action.modal.id)) { 
          newModals.push(action.modal);
        }
        break;
      case 'close': 
        newModals[action.index].open = false;
        break;
      case 'prompt-edit': 
        newModals = [...modals];
        break;
      case 'close-all':
        newModals.forEach(modal => {
          modal.open = false;
        })
        break;
      case 'remove-all':
        newModals = [];
        break;

    }

    return newModals;
  }, [])

  const [modals, dispatchModals] = useReducer(modalsReducer, []);

  const addModal = useCallback((modal: GlobalModalSingle) => {
    modal.open = true;
    dispatchModals({
      type: 'create', modal
    })
  }, [])
  
  const closeModal = useCallback((element: any) => {
    const index = parseInt((element as HTMLElement)?.closest('.modal-dialog')?.getAttribute('data-key'));

    dispatchModals({
      type: 'close', index
    })
  }, [])

  const closeAll = useCallback(() => {
    dispatchModals({type: 'close-all'});
  }, [])

  const removeAll = useCallback(() => {
    dispatchModals({type: 'remove-all'});
  }, [])
  
  const alert = (
    title: React.ReactNode, 
    body: React.ReactNode,
    options?: {
      id?: any;
      buttonText?: string,
      buttonClassName?: string,
    }
  ) => {
    return new Promise<void>((resolve, reject) => {
      addModal({
        id: options?.id,
        title: title,
        body: body,
        footer: (
          <button 
            ref={focusElementRef}
            type="submit" 
            className={clsx("btn btn-primary btn-sm px-3", options?.buttonClassName)}
            onClick={(event) => {
              closeModal(event.target);
              resolve();
            }}
          >
            {options?.buttonText !== undefined ? options.buttonText : "OK"}
          </button>
        )
      });
    })
  }

  const confirm = (
    title: React.ReactNode, 
    body: React.ReactNode,
    showCancel?: boolean,
    yesContent?: React.ReactNode,
    noContent?: React.ReactNode,
  ) => {
    return new Promise<boolean | null>((resolve, reject) => {
      addModal({
        title: title,
        body: body,
        footer: (
          <>
            {yesContent !== null && <button 
              ref={focusElementRef}
              type="button" 
              className="btn btn-danger btn-sm px-3"
              onClick={(event) => {
                closeModal(event.target);
                resolve(true);
              }}
            >
              {yesContent || t('commons.yes')}
            </button>}

            {noContent !== null && <button 
              type="button" 
              className="btn btn-primary btn-sm px-3"
              onClick={(event) => {
                closeModal(event.target);
                resolve(false);
              }}
            >
              {noContent || t('commons.no')}
            </button>}
            {
              showCancel && (
                <button 
                  type="button" 
                  className="btn btn-outline-primary btn-sm px-3"
                  onClick={(event) => {
                    closeModal(event.target);
                    resolve(null);
                  }}
                >
                  {t('commons.cancel')}
                </button>
              )
            }
          </>
        ),
      })
    })
  }

  const prompt = (
    title: React.ReactNode,
    options: {
      type: 'text' | 'password' | 'email' | any,
      placeholder?: string,
      hint?: string,
      initialValue?: string,
    },
    showCancel?: boolean,
    buttonText?: string,
  ) => {
    return new Promise<any>((resolve, reject) => {
      addModal({
        title: title,
        body: (
          <>
            <input 
              ref={focusElementRef}
              className={clsx("form-control")}
              type={options.type}
              placeholder={options.placeholder}
              defaultValue={options.initialValue}
              autoComplete={"off"}
            />
            {
              options.hint && <div className="text-black-50 small mx-2 my-1 fst-italic">{options.hint}</div>
            }
          </>
        ),
        
        footer: (
          <>
          <button 
            type="submit" 
            className="btn btn-primary btn-sm px-3"
          >
            {buttonText !== undefined ? buttonText : t('commons.confirm')}
          </button>
          {
            showCancel && (
              <button 
                type="button" 
                className="btn btn-outline-primary btn-sm px-3"
                onClick={(event) => {
                  closeModal(event.target);
                  resolve(null);
                }}
              >
                {t('commons.cancel')}
              </button>
            )
          }
          </>
          
        ),

        onSubmit: (event) => {
          closeModal(event.target);
          const value = event.target.querySelector("input").value;
          resolve(value);
        }

        
      })
    })
  }

  const confirmDelete = async(
    withValidation?: boolean,
    expectedInput?: string,
  ) => {
    if (withValidation) {
      if (expectedInput == null) {
        expectedInput = t("commons.confirmDeleteDefaultInput");
      }
      const result = await prompt(
        t("commons.confirmDeleteTitle"), {
          type: "text",
          hint: t("commons.confirmDeleteHint", {input: expectedInput})
        },
        true
      );

      return result === expectedInput;
    } else {
      return await confirm(t("commons.confirmDeleteTitle"), t("commons.confirmDelete"));
    }

  }

  const confirmUnsaved = async() => {
    return await confirm(t("commons.unsavedMessageTitle"), t("commons.unsavedMessage"));
  }

  const success = (
    title: React.ReactNode, 
    body: React.ReactNode
  ) => {
    return alert(
      <span className="text-success"><FontAwesomeIcon icon={faCheckCircle} fixedWidth className="pe-2 text-success" />{title}</span>,
      body,
      {buttonClassName: "btn-success"}
    )
  }

  const warning = (
    title: React.ReactNode, 
    body: React.ReactNode
  ) => {
    return alert(
      <span className="text-warning"><FontAwesomeIcon icon={faTriangleExclamation} fixedWidth className="pe-2" />{title}</span>,
      body,
      {buttonClassName: "btn-warning"}
    )
  }

  const error = (
    title: React.ReactNode, 
    body: React.ReactNode,
    options?: {
      id: any,
    }
  ) => {
   return alert(
      <span className="text-danger"><FontAwesomeIcon icon={faCircleXmark} fixedWidth className="pe-2" />{title}</span>,
        body,
      {
        id: options?.id,
        buttonClassName: "btn-danger"
      }
    )
  }

  const errorSpecific = (
    err: AxiosError | GlobalModalError | any
  ) => {
    // console.log((error).isAxiosError)
    if ((err)?.isAxiosError) {
      const axiosError = err as AxiosError<CustomHttpExceptionResponse>;
      console.log(axiosError.response);

      const data = axiosError?.response?.data;

      if (data?.isCustomHttpException === true) {
        const data = axiosError.response.data;
        let title = t(`exceptions.${data.type}.title`)
        const {type, payload} = data;
        let body: React.ReactNode;

        switch (data.type) {
          case "AdminRolesForbiddenException": {
            const payload: AdminRolesForbiddenExceptionResponsePayload = data.payload;
            const roles = payload.requiredRoles.map(
              roleOrRoles => Array.isArray(roleOrRoles) ? `(${roleOrRoles.join(t(`exceptions.${data.type}.and`))})` : roleOrRoles
            ).join(t(`exceptions.${data.type}.or`));
            body = t(`exceptions.${data.type}.body`, {roles});
          }
          break;

          case "EditConflictException": {
            const payload: EditConflictExceptionResponsePayload = data.payload;
            const oldTime = new Date(new Date().getTime() - payload.timeAgo * 1000);
            
            body = t(`exceptions.${data.type}.body`, {ago: moment(oldTime).fromNow()});
          }
          break;

          case "InvalidAccessTokenException": 
          case "InvalidRefreshTokenException": 
            title = t(`exceptions.InvalidTokenException.title`)
            const payload: InvalidTokenExceptionRequestPayload = data.payload;
            const hints = 
              <div className="text-black-50 small fst-italic">{
                t(`exceptions.InvalidTokenException.hints`)
              }</div>
            
            switch (payload?.revokeReason) {
              case "LoginInAnotherPlace": 
                body = <><div className="pb-2">{t(`exceptions.InvalidTokenException.LoginInAnotherPlace`)}</div>{hints}</>;
                break;
              default:
                body = <><div>{t(`exceptions.InvalidTokenException.default`)}</div>{hints}</>;
                break;
            }

            confirm(title, body, false, t('exceptions.InvalidTokenException.refresh'), t('exceptions.InvalidTokenException.stay')).then((result) => {
              if (result) {
                window.location.reload();
              }
            })
            return;

          default: 
            body = t(`exceptions.${data.type}.body`);
        }
        return error(
          title, body
        );
      } else if (data?.message) {
        let message: React.ReactNode = data.message;

        if (axiosError?.response?.status == 429) {
          const errorType = "ThrottlerException";
          return error(
            t(`exceptions.${errorType}.title`), t(`exceptions.${errorType}.body`)
          );
        } else if (axiosError?.response?.status == 401) {
          const errorType = "UnauthorizedException";
          return error(
            t(`exceptions.${errorType}.title`), t(`exceptions.${errorType}.body`)
          ).then(() => window.location.reload());
        } else if (Array.isArray(message)) {
          message = message[0];
        } else if (typeof message === 'string') {
          message = message.charAt(0).toUpperCase() + message.slice(1); // In case of proper string, capitalize the first letter
          message = parseHtml(message as string);
        }
        return error(
          `Error: ${axiosError.response.status} ${axiosError.response.statusText}`,
          message, {
            id: data.type
          }
        )
      } else if (axiosError.response) {
        return error(
          `Error: ${axiosError.response.status} ${axiosError.response.statusText}`,
          "Error occured"
        )
      } else if (axiosError instanceof CanceledError) {
        // console.warn("Request aborted");
      } else {
        console.warn(axiosError);
        return error(
          `Error`,
          "No response from server"
        )
      }
    } else if ((err)?.isGlobalModalError) {
      const e = err as GlobalModalError;
      return error(
        `Error: ` + e.title,
        e.body
      )
    } else if (axios.isCancel(err)) {
      return warning(
        'Upload Aborted',
        'Upload canceled manually'
      )
    } else {
      console.warn(err);
      return error(
        `Error`,
        "Unknown error occured"
      )
    }
  }

  // console.log(modals);

  return (
    <GlobalModalContext.Provider value={{
      closeAll, removeAll, alert, confirm, prompt, confirmDelete, confirmUnsaved, success, warning, error, errorSpecific
    }}>
      {
        modals.map((modal, index) => {
          const {open, title, body, modalProps} = modal;
          

          return (
            <Modal 
              // size="xl"
              key={index}
              data-key={index}
              show={open}
              onShow={() => {
                if (focusElementRef !== undefined) {
                  (focusElementRef as any)?.current?.focus();
                }
              }}
              onHide={() => {}} 
              backdrop='static'
              autoFocus
              centered
              {...modalProps}
            >
              <form
                onSubmit={(event) => {
                  console.log("OnSUBMIT");
                  event.preventDefault();
                  modal.onSubmit && modal.onSubmit(event);
                }}
              >
                <Modal.Header>
                  <Modal.Title className="h5 text-truncate">{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                  {/* <button 
                    ref={(element) => {
                      this.buttonRef = element;
                    }}
                    type="submit" 
                    className="btn btn-primary btn-sm"
                    onClick={() => {this.handleClose()}}
                  >
                    Close
                  </button> */}

                  {
                    modal.footer || (
                      <button 
                        ref={focusElementRef}
                        type="submit" 
                        className="btn btn-primary btn-sm"
                        onClick={(event) => {
                          modal.onClose && modal.onClose();
                          closeModal(event.target);
                        }}
                      >
                        Close
                      </button>
                    )
                  }
                </Modal.Footer> 
              </form>
            </Modal>
          )
        })
      }
      {props.children}

    </GlobalModalContext.Provider>
  );
}
import {
  IconProp,
} from '@fortawesome/fontawesome-svg-core';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import "../../../styles/admin/entity-selector.scss"
export interface EntitySelectorParentProps<EntityT> {
  item?: EntityT;
  onChange?: (item: EntityT | null) => void;
  onClear?: () => void;
  disabled?: boolean;
  loading?: boolean;
  mini?: boolean;
}

export type EntitySelectorProps<EntityT> = EntitySelectorParentProps<EntityT> & {
  modalOpen?: boolean;
  setModalOpen?: (flag: boolean) => void;
  icon?: IconProp;
  title: React.ReactNode;
  preview?: React.ReactNode;
  modalContent: React.ReactNode;
  hideButton?: boolean;
}


export const EntitySelector = <EntityT,>(props: EntitySelectorProps<EntityT>) => {
  const {modalOpen, setModalOpen, item, preview, icon, title, hideButton, disabled, mini} = props;
  
  return (
    <>
      <Modal
        show={modalOpen}
        className="entity-selector-modal"
        backdropClassName="entity-selector-modal-backdrop"
        centered
        onHide={() => {
          setModalOpen?.(false);
        }}
      >
        <Modal.Header closeButton>
          {
            icon && <FontAwesomeIcon icon={icon} className="me-2" />
          }
          {title}
        </Modal.Header>
        <Modal.Body>
          <div>
            {props.modalContent}
          </div>
        </Modal.Body>
      </Modal>

      {
        mini ? (
          <FontAwesomeIcon icon={faPlus} 
            style={{cursor: "pointer"}}
            onClick={() => {
              if (!disabled) {
                setModalOpen?.(true);
              }
            }}
          />
        ) : (
          <div className="entity-selector">
            {
              preview
            }
            {
              !hideButton && title && (
                <ButtonWithLoader
                  faIcon={icon}
                  onClick={() => {
                    setModalOpen?.(true);
                  }}
                  disabled={disabled}
                >{title}
                </ButtonWithLoader>
              )
            }
          </div>
        )
      }
      
    </>
  );
}
import { IsString, IsNotEmpty, IsEnum, IsOptional } from "class-validator";
import { Building } from "../building.entity";
import { Developer } from "../developer.entity";
import { PropertySectionAuditable } from "./property-section-auditable.entity";
import { Property } from "./property.entity";
import { User } from "../user.entity";
import { ApiProperty, Column, CreateDateColumn, Entity, JoinColumn, ManyToOne, OmitType, PickType, PrimaryColumn, PrimaryGeneratedColumn, Relation } from "../vars";
import { Exclude, Expose } from "class-transformer";


export const PropertyLandlordContactCount = 6;

export const PropertyContactTitles = [
  "Mr", "Ms", "Mrs", "Dr"
] as const;

export type PropertyContactTitle = (typeof PropertyContactTitles)[number];

export const PropertyContactVisibilities = [
  "propertyContactAdmin", "admin", "public"
] as const;

export type PropertyContactVisibility = (typeof PropertyContactVisibilities)[number];

export const PropertyContactInvisible = "PropertyContactVisibilities" as const;

@Entity()
export class PropertyLandlord extends PropertySectionAuditable {

  constructor(partial?: Partial<PropertyLandlord>) {
    super();
    Object.assign(this, partial);
    // this.postConstructor();
  }

  @ManyToOne(() => Property, {
    nullable: false,
    onUpdate: "CASCADE",
    onDelete: "CASCADE"
  })
  property: Relation<Property>;
  
  @ApiProperty()
  masked?: boolean = false;

  @Expose()
  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 128,
    nullable: false,
    default: ""
  })
  name: string = "";

  @ApiProperty()
  @IsEnum(PropertyContactTitles)
  @IsOptional()
  @Column("enum", {
    enum: PropertyContactTitles,
    nullable: true
  })
  contactTitle1: PropertyContactTitle = null;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 128,
    nullable: false,
    default: ""
  })
  contactName1: string = "";

  @ApiProperty()
  @IsString({each: true})
  @Column("simple-array", {nullable: false})
  contactInfo1: string[] = [];
  
  @ApiProperty()
  @IsEnum(PropertyContactTitles)
  @IsOptional()
  @Column("enum", {
    enum: PropertyContactTitles,
    nullable: true
  })
  contactTitle2: PropertyContactTitle = null;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 128,
    nullable: false,
    default: ""
  })
  contactName2: string = "";

  @ApiProperty()
  @IsString({each: true})
  @Column("simple-array", {nullable: false})
  contactInfo2: string[] = [];

  @ApiProperty()
  @IsEnum(PropertyContactTitles)
  @IsOptional()
  @Column("enum", {
    enum: PropertyContactTitles,
    nullable: true
  })
  contactTitle3: PropertyContactTitle = null;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 128,
    nullable: false,
    default: ""
  })
  contactName3: string = "";

  @ApiProperty()
  @IsString({each: true})
  @Column("simple-array", {nullable: false})
  contactInfo3: string[] = [];

  @ApiProperty()
  @IsEnum(PropertyContactTitles)
  @IsOptional()
  @Column("enum", {
    enum: PropertyContactTitles,
    nullable: true
  })
  contactTitle4: PropertyContactTitle = null;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 128,
    nullable: false,
    default: ""
  })
  contactName4: string = "";

  @ApiProperty()
  @IsString({each: true})
  @Column("simple-array", {nullable: false})
  contactInfo4: string[] = [];

  @ApiProperty()
  @IsEnum(PropertyContactTitles)
  @IsOptional()
  @Column("enum", {
    enum: PropertyContactTitles,
    nullable: true
  })
  contactTitle5: PropertyContactTitle = null;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 128,
    nullable: false,
    default: ""
  })
  contactName5: string = "";

  @ApiProperty()
  @IsString({each: true})
  @Column("simple-array", {nullable: false})
  contactInfo5: string[] = [];

  @ApiProperty()
  @IsEnum(PropertyContactTitles)
  @IsOptional()
  @Column("enum", {
    enum: PropertyContactTitles,
    nullable: true
  })
  contactTitle6: PropertyContactTitle = null;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 128,
    nullable: false,
    default: ""
  })
  contactName6: string = "";

  @ApiProperty()
  @IsString({each: true})
  @Column("simple-array", {nullable: false})
  contactInfo6: string[] = [];
  
  @ApiProperty()
  @IsEnum(PropertyContactVisibilities)
  @Column("enum", {
    enum: PropertyContactVisibilities,
    nullable: false,
    default: "propertyContactAdmin",
  })
  visibility: PropertyContactVisibility = "propertyContactAdmin";
  
}

@Expose()
export class PropertyLandlordDto extends OmitType(
  PropertyLandlord, ["id", "property", "updated", "updatedBy", "masked"] as const,
) {}

export class PropertyLandlordPublic extends PickType(
  PropertyLandlord, ["name"]
) {
  constructor(partial?: Partial<PropertyLandlordPublic>) {
    super();
    Object.assign(this, partial);
  }
}
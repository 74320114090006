import { faKey, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AuthLoginDto } from "../../../api/entities/user.entity";
import { useAdminComponent } from "../component/AdminComponent";
import { Helmet } from "react-helmet-async";
import "../../../styles/social-button.scss";
import { GoogleAuthButton } from "../../../component/GoogleAuthButton";
import { FacebookAuthButton } from "../../../component/FacebookAuthButton";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useGlobalSystemConfig } from "../../../component/GlobalSystemConfig";
import { LoginArea } from "../../../component/LoginArea";
import { FileViewerImage } from "../../../component/file-manager";
import { APIInstance, APIInstanceWithAuth, APIInstanceWithAuthForAdmin } from "../../../api";
import { useUser } from "../../../component/UserProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AdminDashboardPath } from "../variables";
import { useI18N } from "../../../component/I18NProvider";
import { Modal } from "react-bootstrap";
import { useLoadingManager } from "../../../utils/loading-manager";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";

export const ResetPasswordModal = (props: {
  api: APIInstance;
  resetPasswordToken: string | null;
}) => {
  const {api, resetPasswordToken} = props;
  const {t} = useI18N();
  const [open, setOpen] = useState(false);
  const modal = useGlobalModal();
  const loading = useLoadingManager();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const initCheck = useCallback(async() => {
    const token = loading.start();
    try {
      await api.auth.checkResetPasswordToken(resetPasswordToken);
    } catch (e) {
      modal.errorSpecific(e);
      setOpen(false);
    }
    loading.stop(token);
  }, [resetPasswordToken]);

  useEffect(() => {
    if (resetPasswordToken) {
      setOpen(true);
      initCheck();

    }
  }, [])

  return (
    <Modal
    show={open}
    centered
    onHide={() => setOpen(false)}
  >
      <Modal.Header closeButton>
        {t("login.resetPassword.title")}
      </Modal.Header>
      <Modal.Body>
        <form
          className="d-flex flex-column"
          style={{gap: "0.6rem"}}
          onSubmit={async(event) => {
            event.preventDefault();
            if (password != confirmPassword) {
              modal.error(
                t("login.resetPassword.passwordMismatch.title"),
                t("login.resetPassword.passwordMismatch.body"),
              )
              return;
            }

            const token = loading.start();
            try {
              await api.auth.resetPassword(resetPasswordToken, {password});
              modal.success(
                t("login.resetPassword.complete.title"),
                t("login.resetPassword.complete.body"),
              )
              setOpen(false);
            } catch (e) {
              modal.errorSpecific(e);
            }
            loading.stop(token);

          }}
        >
          <div className="col-12 form-group">
            <input type="password" className="form-control" 
              placeholder={t("login.resetPassword.password")}
              autoComplete="off"
              autoCapitalize="off"
              readOnly={loading.flag}
              value={password}
              onChange={(event) => {
                setPassword(event.target.value)
              }}
            />
          </div>
          <div className="col-12 form-group">
            <input type="password" className="form-control" 
              placeholder={t("login.resetPassword.confirmPassword")}
              autoComplete="off"
              autoCapitalize="off"
              readOnly={loading.flag}
              value={confirmPassword}
              onChange={(event) => {
                setConfirmPassword(event.target.value)
              }}
            />
          </div>

          <div className="col-12 mt-2" style={{gap: "0.5rem 0"}}>
            <ButtonWithLoader loading={loading.flag} type="submit" className="w-100">
              {t("login.resetPassword.submit")}
            </ButtonWithLoader>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export const AdminLogin = (props: React.PropsWithChildren<{}>) => {
  const {t, currentLang} = useI18N();
  const api = useMemo(() => (new APIInstanceWithAuthForAdmin({
    locale: currentLang
  })), []);
  const {globalSystemConfig} = useGlobalSystemConfig();
  const modal = useGlobalModal();
  const {adminUser} = useUser();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [resetPasswordToken, setResetPasswordToken] = useState(searchParams.get('reset-password-token'));


  const shouldRedirect = useMemo(() => {
    return adminUser && (searchParams.get('error') == null)
  }, [adminUser])

  useEffect(() => {
    // console.log(shouldRedirect);
    if (shouldRedirect) {
      navigate("../" + (searchParams.get('to') ?? AdminDashboardPath))
    } else {
      searchParams.delete('error');
      setSearchParams(searchParams);
    }
  }, [shouldRedirect]);

  useEffect(() => {
    if (searchParams.get('reset-password-token')) {
      searchParams.delete('reset-password-token');
    }
    modal.removeAll();
  }, []);

  return !shouldRedirect && (
    <>
      <ResetPasswordModal
        api={api}
        resetPasswordToken={resetPasswordToken}
      />
      <Helmet>
        <title>{`${t('admin.title')} | ${t('title')}`}</title>
      </Helmet>
      <div className="admin-login">
        <div className="card mx-auto">
          <div className="card-body py-0">
            <LoginArea
              api={api}
              forAdmin
              onSuccess={() => window.location.reload()}
              onError={(e) => modal.errorSpecific(e)}
              logo={(
                <img src="/logo-full-02.png" />
              )}
              title={t('admin.title')}
            />
          </div>
        </div>
      </div>
    </>
  ) || <></>
}
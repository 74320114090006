import clsx from "clsx";
import React, { PropsWithChildren, useContext, useEffect } from "react";
import {LoadingManager, useLoadingManager} from "../utils/loading-manager";


export const GlobalLoadingScreenContext = React.createContext<{
  globalLoading: LoadingManager
} | null>(null)


export const useGlobalLoadingScreen = () => {
  let context = useContext(GlobalLoadingScreenContext);
  if (!context) {
    throw new Error("Use of useGlobalLoadingScreen() without bounding child with <GlobalLoadingScreenProvider />")
  }
  return context;
}
export const GlobalLoadingScreenProvider = (props: PropsWithChildren<{}>) => {

  const globalLoading = useLoadingManager();
  
  useEffect(() => {
    let classList = document.querySelector(".global-loading-screen")?.classList;
    classList && (globalLoading.check() ? classList.add("open") : classList.remove("open"))

  }, [globalLoading.check()])

  return (
    <GlobalLoadingScreenContext.Provider
      value={{globalLoading}}
    >
      
      {props.children}
    </GlobalLoadingScreenContext.Provider>
  )
}
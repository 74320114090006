import { AxiosInstance, AxiosRequestConfig } from "axios";
import { APIBase } from "./apiBase";

export default class AppAPI extends APIBase {
  
  constructor(axios: AxiosInstance) {
    super(axios, "");
  }




}
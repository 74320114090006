import { faSquareMinus, faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import { faEllipsis, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useResizeObserver from "@react-hook/resize-observer";
import clsx from "clsx";
import { ComponentPropsWithRef, ReactComponentElement, ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";
import "../styles/collapsible.scss";

const useRefScrollHeight = <T extends HTMLDivElement,>() => {
  const ref = useRef<T|null>();
  const [scrollHeight, setScrollHeight] = useState(0);

  useLayoutEffect(() => {
    setScrollHeight(ref?.current?.scrollHeight);
  }, [ref])

  useResizeObserver(ref, () => {
    setScrollHeight(ref?.current?.scrollHeight);
  })

  return {ref, scrollHeight};
}

export const Collapsible = (props: Omit<ComponentPropsWithRef<"div">, "children"> & {
  collapsed?: boolean
  collapsedComponent?: ReactNode
  hideCollapsed?: boolean
  children?: ReactNode
  onExpand?: () => void;
}) => {
  const {collapsed, onExpand, collapsedComponent, hideCollapsed, className, children, ...divProps} = props;
  const {ref, scrollHeight} = useRefScrollHeight();
  const [scrollHeightAnimated, setScrollHeightAnimated] = useState(scrollHeight);
  const [animating, setAnimating] = useState(false);
  const [isInit, setIsInit] = useState(false);

  const timeout = useRef<NodeJS.Timeout>(null);
  useLayoutEffect(() => {
    if (!isInit) {
      setIsInit(true);
    } else {
      clearTimeout(timeout.current);
      setAnimating(true);
      setScrollHeightAnimated(collapsed ? 0 : ref?.current?.scrollHeight);
      timeout.current = setTimeout(() => {
        setScrollHeightAnimated(collapsed ? 0 : ref?.current?.scrollHeight);
        setAnimating(false);
      }, 500)


      return () => {
        clearTimeout(timeout.current);
      }
    }
  }, [collapsed]);

  useLayoutEffect(() => {
    setScrollHeightAnimated(collapsed ? 0 : ref?.current?.scrollHeight);
  }, [scrollHeight])
  return (
    <>
      <div 
        className={clsx("collapsible", (collapsed || animating) && "overflow-y-hidden", animating && "animating", className)} {...divProps} 
        style={{
          maxHeight: scrollHeightAnimated
        }}
      >
        <div ref={ref}>{children}</div>
      </div>
      {collapsed && !hideCollapsed && (
        <div className="d-flex flex-column align-items-center justify-content-center text-muted"
          style={{marginTop: "-0.6rem", gap: "1rem", cursor: "pointer"}}
          onClick={() => {
            onExpand?.();
          }}
        >
          {collapsedComponent && <div className="d-flex flex-column align-items-center justify-content-center text-muted">{collapsedComponent}</div>}
          <FontAwesomeIcon
            icon={faEllipsis}
            fixedWidth
            size="lg"
          />
        </div>
      )}
    </>

  )

}


export const CollapsibleButton = (props: ComponentPropsWithRef<"div"> & {
  title?: React.ReactNode,
  collapsed?: boolean,
  onClick?: () => void
}) => {
  const {title, collapsed, onClick, className, ...divProps} = props;

  return (
    <div
      className={clsx("collapsible-btn", className)}
      style={{gap: "0.6rem"}}
      onClick={() => {onClick?.()}}
    >
      {title}
      <FontAwesomeIcon icon={collapsed ? faSquarePlus : faSquareMinus} size="lg" />
    </div>
  )
}
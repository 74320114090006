
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import App from './App';
import { GlobalLoadingScreenProvider } from './pages/GlobalLoadingScreen';

import "./styles/styles.scss";
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from './component/ErrorBoundary';
const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <Suspense fallback={"suspense fallback"} >
      <GlobalLoadingScreenProvider>
        <ErrorBoundary>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ErrorBoundary>
      </GlobalLoadingScreenProvider>
    </Suspense>
  </React.StrictMode>
);



import { AxiosInstance, AxiosRequestConfig } from "axios";
import { GoogleApisMapsPlaceAutocompleteDto, GoogleApisMapsPlaceAutocompleteResult, GoogleApisMapsPlaceNamesDto, GoogleApisMapsPlaceNamesResult } from "../entities/googleapis";
import { APIBase } from "./apiBase";
import { APIEncodeQueryString } from "./helpers";

export default class GoogleapisAPI extends APIBase {
  

  constructor(axios: AxiosInstance) {
    super(axios, "googleapis");
  }

  async mapsPlaceAutocomplete(dto: GoogleApisMapsPlaceAutocompleteDto, config?: AxiosRequestConfig): Promise<GoogleApisMapsPlaceAutocompleteResult> {
    return (await this.axios.post<GoogleApisMapsPlaceAutocompleteResult>(
      this.base + "/maps/place/autocomplete",
      dto, config
    )).data;
  }

  async mapsPlaceNames(dto: GoogleApisMapsPlaceNamesDto, config?: AxiosRequestConfig): Promise<GoogleApisMapsPlaceNamesResult> {
    return (await this.axios.post<GoogleApisMapsPlaceNamesResult>(
      this.base + "/maps/place/names",
      dto, config
    )).data;
  }

}
import { faPlus, faArrowsRotate, faBan, faBuildingUser, faBuilding, faCity, faMapLocation, faCheck } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { useState, useTransition, useMemo, useCallback, useEffect } from "react";
import { Badge, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Building, BuildingDto, BuildingSort } from "../../../api/entities/building.entity";
import { FindAllQuery } from "../../../api/entities/findAllQuery";
import { Paginated } from "../../../api/entities/pagination";
import { AutocompleteSearch } from "../../../component/AutocompleteSearch";
import { ButtonWithLoader, LinkWithLoader } from "../../../component/ButtonWithLoader";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider";
import { UnsavedPrompt } from "../../../component/UnsavedPrompt";
import { useAbortable, useAbortableLoading } from "../../../utils/abortable-loading";
import { Lang, LangMap, Langs } from "../../../utils/lang-map";
import { useScrollToId } from "../../../utils/scroll-to-id";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";
import { DataList, DataListItem } from "../component/DataList";
import { EntitySelectorParentProps, EntitySelector } from "../component/EntitySelector";
import { EntityTimestamp } from "../component/EntityTimestamp";
import { useFindAllQuery } from "../component/FindAllQuery";
import { GenericInput } from "../component/GenericInput";
import { LangPreview } from "../component/LangPreview";
import { AdminPagination } from "../component/Pagination";
import { AdminBuildingPath } from "../variables";
import { StreetPreview } from "./Building";
import * as path from "path-browserify"
import { NullDisplay } from "../../../component/NullDisplay";

export const AdminBuildingsComponent = (props: {
  onSelect?: (building: Building) => void,
  selectedBuilding?: Building;
  targetBlankOnCreate?: boolean;
  shouldUseSearchParams?: boolean;
}) => {
    const {api, loading, toast} = useAdminComponent();
    const modal = useGlobalModal();
    const [isInit, setIsInit] = useState(false);
    const [buildingsPaginated, setBuildingsPaginated] = useState<Paginated<Building>>(null);
    const [pendingInputFocus, setPendingInputFocus] = useState(false);

    // console.log(BuildingSort);

    const {findAllQuery, renderPaginationUI, renderSearchInput, setSearchInput, scrollTopRef, setSortByDropdownOpen} 
      = useFindAllQuery({
      isInit, loading: loading.flag, paginationMeta: buildingsPaginated?.meta,
      shouldUseSearchParams: props.shouldUseSearchParams,
      defaultPage: 1, defaultLimit: 50,
      sortKeys: ["id", "nameEn", "nameTc", "nameSc", "timestamp.created", "timestamp.updated"],
    })
  
    const reloadAbortable = useAbortable();
    const openCCT2SAbortableLoading = useAbortableLoading();
    
    const {scrollToId, idPrefix} = useScrollToId();
  
    const {t, currentLang} = useI18N();
  
    const reload = useCallback(async(options?: {
      bypassScrollToAnchor?: boolean
    }) => {
      const token = loading.start();
      const {signal} = reloadAbortable.create();
      try {
        setBuildingsPaginated(await api.buildings.findAll(findAllQuery, {signal}));
        // TODO
        // setSearchInput("");
        if (!options?.bypassScrollToAnchor) {
          scrollToId(null);
        }
      } catch (e) {
        modal.errorSpecific(e);
      } finally {
        loading.stop(token);
      }
    }, [findAllQuery])
  
    useEffect(() => {
      if (isInit) {
        reload();
      }
    }, [findAllQuery]);

    useEffect(() => {
      reload().then(() => setIsInit(true));
    }, []);
  
    return (
      <>
        <div className="d-flex align-items-center mb-3" style={{gap: "0.5em", flexWrap: "wrap"}}>
  
          <LinkWithLoader
            to={"./new"}
            target={props.targetBlankOnCreate && "_blank"}
            faIcon={faPlus}
            className="btn btn-primary"
            loading={loading.check()}
            onClick={async(event) => {
              event.stopPropagation();
              
            }}
          />
  
          <ButtonWithLoader
            faIcon={faArrowsRotate}
            variant="secondary"
            loading={loading.check()}
            onClick={async() => {
              await reload();
            }}
          />

          {renderPaginationUI({className: "ms-auto"})}

        </div>
  
        <div>
          {/* <AutocompleteSearch
            loading={searchPending}
            placeholder={t("admin.buildings.search")}
            hideDropdown
            input={searchInput}
            onChange={(value: string) => {
              setSearchInput(value);
              startSearchTransition(() => {
                // setBuildingsFiltered(searchBuildings(value, buildings))
              })
            }}
          /> */}
          {/* <SearchInput /> */}
          {renderSearchInput({
            placeholder: t("admin.buildings.search")
          })}
        </div>
        <DataList divRef={scrollTopRef} className="scrollable" onClick={() => setSortByDropdownOpen(false)}>
          {
            buildingsPaginated?.items && (
              buildingsPaginated?.items.length == 0  ? (
                <DataList.Empty>{t('commons.noResult')}</DataList.Empty>
              ) : (
                <>{
                  [...buildingsPaginated?.items].map((building: Building, index) => {
                    if (building == null) {
                      return;
                    }
                    
                    
                    const isSelected = props.onSelect && props.selectedBuilding?.id == building.id;

                    const content = (
                      <DataListItem id={idPrefix + building.id} clickable active={isSelected}>
                        <DataListItem.Title>
                        <Badge bg="info" className="data-key">繁</Badge>
                        {building.nameTc}, {StreetPreview(t, {district: building.district?.nameTc, streetName: building.streetNameTc, streetNumber: building.streetNumber, lang: "tc"})}
                          <DataListItem.TitleBtns>
                            <EntityTimestamp timestamp={building.timestamp} />
                            {/* <DataListItem.EditLink
                              to={`../../${AdminBuildingPath}/${building.id}`}
                              target={props.onSelect && "_blank"}
                              className={clsx()}
                            /> */}
                          </DataListItem.TitleBtns>
                        </DataListItem.Title>
                        <DataListItem.Body>
                          {/* <div><Badge bg="info" className="data-key">繁</Badge>{building.nameTc}</div> */}
                          <div><Badge bg="info" className="data-key">简</Badge>
                          {building.nameSc}, {StreetPreview(t, {district: building.district?.nameSc, streetName: building.streetNameSc, streetNumber: building.streetNumber, lang: "sc"})}
                          </div>
                          <div><Badge bg="info" className="data-key">EN</Badge>
                          {building.nameEn}, {StreetPreview(t, {district: building.district?.nameEn, streetName: building.streetNameEn, streetNumber: building.streetNumber, lang: "en"})}
                          </div>
                          <div><Badge bg="info" className="data-key">{t('admin.developers.title')}</Badge>{
                            building.developers?.map((developer) => (
                              `${developer?.nameTc ?? ""} ${developer.nameEn ?? ""}`
                            ))?.join(", ")
                          }{
                            (!building.developers || building.developers?.length == 0) && <NullDisplay />
                          }</div>
                          
                          {/* {
                            props.onSelect && (
                              <ButtonWithLoader
                                size="sm"
                                variant="success"
                                onClick={() => {
                                  props.onSelect(building);
                                }}
                                disabled={isSelected}
                                faIcon={isSelected && faCheck}
                              >
                                {t(`admin.commons.${isSelected ? "selected" : "select"}`)}
                              </ButtonWithLoader>
                            )
                          } */}
                        </DataListItem.Body>
                      </DataListItem>
                    )

                    return props.onSelect ? (
                      <div key={building.id} className={clsx("data-list-item-clickable", isSelected && "active")} onClick={() => {props.onSelect?.(building)}}>{content}</div>
                    ) : (
                      <Link key={building.id} className="data-list-item-clickable" to={`../../${AdminBuildingPath}/${building.id}`} >{content}</Link>
                    );
                  })
                }
                
                {renderPaginationUI({className: "my-4"})}
                </>
              )
            )
          }
          {/* <AdminTable 
            className="buildings-table"
            keys={{
              id: {
                content: t('admin.buildings.id'),
              },
              nameEn: {
                content: t('admin.buildings.nameEn'),
              },
              nameTc: {
                content: t('admin.buildings.nameTc'),
              },
              nameSc: {
                content: t('admin.buildings.nameSc'),
              },
              reOrder: {
                content: "",
                shrink: true,
              }, 
              timestamp: {
                content: "",
                shrink: true,
              },
              delete: {
                content: "",
                shrink: true,
              },
            }} 
            rows={
              buildings && buildings.map((building, index) => {
                if (building.mode == "deleted") {return null}
                const editing = building.mode == "editing";
  
                return {
                  onClick: (event) => {
                    event.stopPropagation();
                    // Set all editing buildings as uneditted
                    setBuildings(buildings => buildings?.map(building => {
                      if (building.mode == "editing") {
                        building.mode = "edited";
                      }
                      return building;
                    }))
                    editBuilding(index, {
                      ...building,
                      mode: "editing",
                    })
                  },
                  content: {
                    id: !editing ? building.id :
                      <GenericInput type="text" value={building.id} onChange={(id: string) => editBuilding(index, {...building, id: id.toUpperCase()}, true)} />,
                    nameEn: !editing ? building.nameEn :
                      <GenericInput type="text" value={building.nameEn} onChange={nameEn => editBuilding(index, {...building, nameEn}, true)} />,
                    nameTc: !editing ? building.nameTc :
                      <GenericInput type="text" value={building.nameTc} onChange={nameTc => editBuilding(index, {...building, nameTc}, true)} />,
                    nameSc: !editing ? building.nameSc :
                      <GenericInput type="text" value={building.nameSc} onChange={nameSc => editBuilding(index, {...building, nameSc}, true)} />,
                    reOrder: (
                      <div className="d-flex">
                        <button className="btn btn-outline-info btn-sm me-1" disabled={index == 0}
                          onClick={(event) => {
                            event.stopPropagation();
                            let buildingsCloned = [...buildings];
                            [buildingsCloned[index-1], buildingsCloned[index]] = [buildingsCloned[index], buildingsCloned[index-1]];
                            setBuildings(buildingsCloned);
                            setUnsavedOrders(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowUp} />
                        </button>
                        
                        <button className="btn btn-outline-info btn-sm" disabled={index == buildings.length - 1}
                          onClick={(event) => {
                            event.stopPropagation();
                            let buildingsCloned = [...buildings];
                            [buildingsCloned[index], buildingsCloned[index+1]] = [buildingsCloned[index+1], buildingsCloned[index]];
                            setBuildings(buildingsCloned);
                            setUnsavedOrders(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowDown} />
                        </button>
                      </div>
                    ),
                    timestamp: building.timestamp && <EntityTimestamp created={building.timestamp.created} updated={building.timestamp.updated} />,
                    delete: (
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={async(event) => {
                          event.stopPropagation();
                          if (await modal.confirmDelete()) {
                            editBuilding(index, {...building, mode: "deleted"}, true)
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                    ),
                  }
                }
              })
            }
          /> */}
        </DataList>
      </>
    )
  }

export const AdminBuildings = () => {
    const {t} = useI18N();
    useAdminComponent({selectedItem: AdminBuildingPath})
  
  
    return (
      <AdminComponent.Container 
        onClick={() => {

        }}
      >
        <AdminHeaderTitle>{t('admin.buildings.title')}</AdminHeaderTitle>
        <AdminComponent.TitleBar>
          <AdminComponentTitle 
            faIcon={faCity}
          >
            {t('admin.buildings.pageTitle')}
          </AdminComponentTitle>
        </AdminComponent.TitleBar>
  
        
        <div className="hr" />
        
        <AdminBuildingsComponent shouldUseSearchParams/>
      </AdminComponent.Container>
    )
    
  }



  export const AdminBuildingPreview = (props: {
    building: Building
  }) => {
    const {building} = props;
    const {t} = useI18N();
    const streetPreview: {
      [lang in Lang]: string
    } = useMemo(() => {
      return Langs.reduce((acc, lang) => ({
        ...acc,
        [lang]: building?.["name" + LangMap(lang)] + ", " +
         StreetPreview(t, {
          district: building?.district?.["name" + LangMap(lang)],
          streetName: building?.["streetName" + LangMap(lang)],
          streetNumber: building?.["streetNumber"],
          lang
        }),
      }), {tc: "", sc: "", en: ""})
    }, [
      building?.nameTc, building?.nameSc, building?.nameEn, 
      building?.streetNameTc, building?.streetNameSc, building?.streetNameEn, 
      building?.streetNumber, building?.district
    ])
    
    const opDisplay = useMemo(() => {
      let display = "";
      if (building) {
        const {occupationYear, occupationMonth, occupationDay} = building;
        if (occupationYear) {
          display = `` + occupationYear;

          if (occupationMonth) {
            display += "-" + String(occupationMonth).padStart(2, "0");

            if (occupationDay) {
              display += "-" + String(occupationDay).padStart(2, "0");
            }
          }
        }
      }
      
      return display;
    }, [building?.occupationYear, building?.occupationMonth, building?.occupationDay])
  
    return building && (
      <>
        <LangPreview 
          value={streetPreview}

          additionalFields={{
            OP: opDisplay || <NullDisplay />
          }}
          
        />
      </>
    )
  
  }
  export const AdminBuildingSelector = (props: EntitySelectorParentProps<Building>) => {
    const {t} = useI18N();
    const [modalOpen, setModalOpen] = useState(false);
    const {item: building} = props;
  
    return (
      <EntitySelector
        {...props}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        preview={<AdminBuildingPreview building={building}/>}
        icon={faMapLocation}
        title={t('admin.buildings.choose')}
        modalContent={<AdminBuildingsComponent 
          shouldUseSearchParams={false}
          selectedBuilding={building}
          onSelect={(building) => {
            setModalOpen(false);
            props.onChange?.(building);
          }} 
          targetBlankOnCreate
        />}
      />
    )
  }
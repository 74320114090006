import React, { useCallback, useEffect, useMemo, useState } from "react";
import Admin, { AdminDashboardPath } from "..";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";
import "../../../styles/admin/dashboard.scss";
import { EntityTimestamp } from "../component/EntityTimestamp";
import moment from "moment";
import { satisfies } from 'compare-versions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Bar, ComposedChart, Area } from 'recharts';
import { Helmet } from "react-helmet-async";
// import Masonry from 'react-masonry-css';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import { Button, Spinner, SpinnerProps } from "react-bootstrap";
import clsx from "clsx";
import { GenericInput } from "../component/GenericInput";
import { ServerCostSummary } from "../../../api/entities/server-cost-summary.entity";
import { SystemAppInfo, SystemCurrentStatus, SystemInfo } from "../../../api/entities/system-info";
import { useLoadingManager } from "../../../utils/loading-manager";
import { useWindowFocused } from "../../../utils/useWindowFocused";
import { userHasAdminRoles } from "../../../api/entities/user.entity";
import { NullDisplay } from "../../../component/NullDisplay";

export const AdminDashboardLoader = (props: Omit<SpinnerProps, "animation">) => {
  return (
    <Spinner animation="border" style={{width: "1em", height: "1em", borderWidth: "3.5px"}} {...props} />
  )
}

export const AdminDashboard = (props: React.PropsWithChildren<{}>) => {
  const { t } = useI18N();
  const { api, setSelectedItem, loading, adminUser } = useAdminComponent();

  const userHasPermission = useMemo(() => ({
    systemInfo: userHasAdminRoles(adminUser, ["SystemInfoViewer"]),
    systemAppInfo: userHasAdminRoles(adminUser, ["SystemAppInfoViewer"]),
    systemCurrentStatus: userHasAdminRoles(adminUser, ["SystemCurrentStatusViewer"]),
    serverCostSummary: userHasAdminRoles(adminUser, ["SystemCostSummaryViewer"])
  }), [adminUser])

  const [systemInfo, setSystemInfo] = useState<SystemInfo>(null);
  const [systemAppInfo, setSystemAppInfo] = useState<SystemAppInfo>(null);
  const [systemCurrentStatus, setSystemCurrentStatus] = useState<SystemCurrentStatus>(null);
  const systemCurrentStatusUptimeDuration = useMemo(() => (
    systemCurrentStatus?.uptime && moment.duration(systemCurrentStatus?.uptime * 1000)
  ), [systemCurrentStatus?.uptime])
  const systemCurrentStatusLoading = useLoadingManager();

  const {windowFocusedRef} = useWindowFocused();

  const frontendVersion = useMemo(() => process.env.REACT_APP_VERSION, []);
  const latestUpdate = useMemo(() => systemAppInfo?.updateLogs?.[0], [systemAppInfo]);
  const frontendBackendCompatible = useMemo(() => (
    systemAppInfo?.version && satisfies(frontendVersion, "~" + systemAppInfo?.version)
  ), [systemAppInfo?.version, frontendVersion])
  const [serverCostSummary, setServerCostSummary] = useState<ServerCostSummary>(null);
  const latestServerCostUsage = useMemo(() => (serverCostSummary?.monthlyUsages?.[serverCostSummary?.monthlyUsages?.length - 1]), [serverCostSummary])
  const [serverCostUsageShowMonths, setServerCostUsageShowMonths] = useState<number>(3);
  const serverCostUsageDataMonthly = useMemo(() => serverCostUsageShowMonths && serverCostSummary?.monthlyUsages && (
    [...serverCostSummary.monthlyUsages].splice(-(serverCostUsageShowMonths + 1)).map(usage => {
      const isThisMonth = serverCostSummary?.forecast?.month == usage.month;
      return {
        period: moment(usage.month).format("MMM"),
        cost: usage.cost,
        forecast: !isThisMonth ? usage.cost : serverCostSummary?.forecast.cost,
        isThisMonth,
      }
    })
  ), [serverCostSummary?.monthlyUsages, serverCostSummary?.forecast, serverCostUsageShowMonths])

  
  const [serverCostUsageShowDays, setServerCostUsageShowDays] = useState<number>(null);
  const serverCostUsageDataDaily = useMemo(() => serverCostUsageShowDays && serverCostSummary?.dailyUsages && (
    [...serverCostSummary.dailyUsages].splice((-serverCostUsageShowDays)).map(usage => ({
      period: moment(usage.day).format("DD/MM"),
      cost: usage.cost
    }))
  ), [serverCostSummary?.dailyUsages, serverCostUsageShowDays])

  const modal = useGlobalModal();

  const initSystemInfo = useCallback(async() => {
    const token = loading.start();
    try {
      setSystemInfo(await api.systemInfo.getSystemInfo())
    } catch (e) {
      // modal.errorSpecific(e);
    }
    loading.stop(token);
  }, []);

  const initSystemAppInfo = useCallback(async() => {
    const token = loading.start();
    try {
      setSystemAppInfo(await api.systemInfo.getSystemAppInfo())
    } catch (e) {
      // modal.errorSpecific(e);
    }
    loading.stop(token);
  }, []);


  const initServerCostSummary = useCallback(async() => {
    const token = loading.start();
    try {
      setServerCostSummary(await api.serverCostSummary.get());
    } catch (e) {
      // modal.errorSpecific(e);
    }
    loading.stop(token);
  }, []);

  const initSystemCurrentStatus = useCallback(() => {
    return setInterval(async() => {
      if (!systemCurrentStatusLoading.flag) {
        const token = systemCurrentStatusLoading.start();
        try {
          setSystemCurrentStatus(await api.systemInfo.getCurrentStatus())
        } catch (e) {

        }
        systemCurrentStatusLoading.stop(token);
      }
    }, 1000)
  }, [])

  useEffect(() => {
    setSelectedItem(AdminDashboardPath);
    initSystemInfo();
    initSystemAppInfo();
    initServerCostSummary();
    const currentStatusInterval = initSystemCurrentStatus();

    return () => {
      clearInterval(currentStatusInterval);
    }
  }, []);


  
  return (
    <AdminComponent.Container>
      <AdminHeaderTitle>{t('admin.dashboard.title')}</AdminHeaderTitle>
      <AdminComponent.TitleBar>
        <AdminComponentTitle>
          {t('admin.dashboard.title')}
        </AdminComponentTitle>
      </AdminComponent.TitleBar>
      <Helmet>
        <script src="https://unpkg.com/react/umd/react.production.min.js"></script>
        <script src="https://unpkg.com/react-dom/umd/react-dom.production.min.js"></script>
        <script src="https://unpkg.com/prop-types/prop-types.min.js"></script>
        <script src="https://unpkg.com/recharts/umd/Recharts.js"></script>
      </Helmet>

      <div className="hr" />

      <ResponsiveMasonry 
        columnsCountBreakPoints={{
          // default: 5,
          2200: 4,
          1100: 2,
          400: 1,
        }}
        className="admin-dashboard"
      >
        <Masonry>
          {
            [
              userHasPermission.systemAppInfo && (
                <div className="admin-dashboard-card" key="system-app-info">
                  <div className="title">{t('admin.dashboard.systemAppInfo.title')} {!systemAppInfo && <AdminDashboardLoader className="ms-1" />}</div>
                  <div className="body">
                    <div>
                      {t('admin.dashboard.systemAppInfo.version')} {systemAppInfo?.version ?? "-"} ({t('admin.dashboard.systemAppInfo.server')}){` & `} 
                      {t('admin.dashboard.systemAppInfo.version')} {frontendVersion ?? "-"} ({t('admin.dashboard.systemAppInfo.client')})
                      {systemAppInfo?.version && !frontendBackendCompatible && <FontAwesomeIcon className="ms-1" color="darkgray" icon={faExclamationTriangle} />}
                    </div>
                    <div>
                      {
                      latestUpdate?.authorDate ? t(
                        'entityData.lastUpdated', {
                          ago: moment(new Date(latestUpdate?.authorDate).getTime()).fromNow()?.trim(),
                          by: ""
                      }) : "-"
                      }
                      {
                        latestUpdate?.authorDate && <EntityTimestamp inline className="ms-2" updated={latestUpdate?.authorDate} updatedBy={latestUpdate?.authorName} />
                      }
                    </div>
                  </div>
                </div>
              ),

              userHasPermission.systemInfo && (
                <div className="admin-dashboard-card" key="system-info">
                <div className="title">{t('admin.dashboard.systemInfo.title')} {!systemInfo && <AdminDashboardLoader className="ms-1" />}</div>
                  <div className="body">
                    <div>
                      <b>{t('admin.dashboard.systemInfo.system')}:</b> {systemInfo ? 
                        <>{systemInfo.system.manufacturer} - {systemInfo.system.model}</> : <NullDisplay />
                      }
                    </div>
                    <div>
                      <b>{t('admin.dashboard.systemInfo.CPU')}:</b> {systemInfo ?
                        <>{systemInfo.cpu.manufacturer} {systemInfo.cpu.brand} {systemInfo.cpu.speed}GHz ({systemInfo.cpu.cores} Cores)</> : <NullDisplay />
                      }
                    </div>
                    <div>
                      <b>{t('admin.dashboard.systemInfo.RAM')}:</b> {systemInfo ?
                        <>{Math.ceil(systemInfo.ram.total / 1024 / 1024 / 1024 * 10) / 10}GB</> : <NullDisplay />
                      }
                    </div>
                    <div>
                      <b>{t('admin.dashboard.systemInfo.OS')}:</b> {systemInfo ?
                        <>{systemInfo.os.platform} {systemInfo.os.distro} ({systemInfo.os.release})</> : <NullDisplay />
                      }
                    </div>
                    <div>
                      <b>{t('admin.dashboard.systemInfo.systemTimezone')}:</b> {systemInfo ?
                        <>{systemInfo.timezone.timezoneName} ({systemInfo.timezone.timezone})</> : <NullDisplay />
                      }
                    </div>
                  </div>
                </div>
              ),

              userHasPermission.systemCurrentStatus && (
                <div className="admin-dashboard-card" key="system-current-status">
                  <div className="title">{t('admin.dashboard.systemStatus.title')} {!systemCurrentStatus && <AdminDashboardLoader className="ms-1" />}</div>
                  <div className="body">
                    <div>
                      <b>{t('admin.dashboard.systemStatus.upTime')}: </b> {systemCurrentStatusUptimeDuration ? 
                        <>{Math.floor(systemCurrentStatusUptimeDuration.asDays())}{` day(s) `}
                        {systemCurrentStatusUptimeDuration.hours().toString().padStart(2, "0")}:
                        {systemCurrentStatusUptimeDuration.minutes().toString().padStart(2, "0")}:
                        {systemCurrentStatusUptimeDuration.seconds().toString().padStart(2, "0")}</> : <NullDisplay />
                      }
                      
                    </div>
                    <div>
                      <b>{t('admin.dashboard.systemStatus.time')}: </b>{systemCurrentStatus ? 
                        moment(systemCurrentStatus.time).format("YYYY-MM-DD HH:mm:ss") : <NullDisplay />
                      }
                    </div>
                    <ResponsiveContainer  width="100%" height={120} > 
                      <ComposedChart
                        // key={serverCostUsageShowMonths || serverCostUsageShowDays}
                        className="my-2"
                        style={{
                          border: "1px solid darkgrey"
                        }}
                        data={systemCurrentStatus?.loads ?? []}
                        margin={{
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis hide tick interval={60}/>
                        <YAxis hide tick minTickGap={0.2} domain={[0, 1]}/>
                        <Tooltip wrapperStyle={{ outline: "none" }} content={({active, payload, label}) => {
                          if (active && systemCurrentStatus?.loads?.[0] && payload) {
                            const load: typeof systemCurrentStatus.loads[0] = payload[0].payload;
                            return (
                              <div className="admin-dashboard-chart-tooltip small">
                                <div>CPU: {Math.round(load.cpu * 10000) / 100}%</div>
                                <div>RAM: {Math.round(load.ram * 10000) / 100}%</div>
                              </div>
                            )
                          }
                        }}/>
                        <Line type="monotone" isAnimationActive={false}  dataKey="ram" stroke="#0a345d" dot={false}/>
                        <Line type="monotone" isAnimationActive={false}  dataKey="cpu" stroke="#155ea5" dot={false} />
                      </ComposedChart>
                    </ResponsiveContainer>
                    <div className="d-flex align-items-center justify-content-around mt-2">
                      <div><b>CPU: </b>{systemCurrentStatus ? 
                        <>{Math.round(systemCurrentStatus.loads.slice(-1)?.[0].cpu * 10000) / 100}%</> : <NullDisplay />}</div>
                      <div><b>RAM: </b>{systemCurrentStatus ? 
                        <>{Math.round(systemCurrentStatus.loads.slice(-1)?.[0].ram * 10000) / 100}%</> : <NullDisplay />}</div>
                    </div>
                  </div>
                </div>
              ),

              userHasPermission.serverCostSummary && (
                <div className="admin-dashboard-card" key="system-cost-summary">
                  <div className="title">{t('admin.dashboard.serverCostSummary.title')} {
                  serverCostSummary?.lastUpdate && (<EntityTimestamp inline className="ms-1" updated={serverCostSummary?.lastUpdate} />)
                  } {!serverCostSummary && <AdminDashboardLoader className="ms-1" />}</div>
                  <div className="body">
                    <div>
                      <span className="highlight">HK${latestServerCostUsage?.cost ?? <NullDisplay />}</span>
                      <span className="small ms-2">{
                        latestServerCostUsage ? t('admin.dashboard.serverCostSummary.overall', {month: moment(latestServerCostUsage.month).format("MMMM")}) : 
                        <NullDisplay />
                      }</span>
                    </div>
                    {
                      serverCostSummary?.forecast && (
                        <div>{t('admin.dashboard.serverCostSummary.monthlyEstimate')}: <b>HK${serverCostSummary.forecast.cost}</b></div>
                      )
                    }
                    
                    <ResponsiveContainer  width="100%" height={180}>
                      <ComposedChart
                        key={serverCostUsageShowMonths || serverCostUsageShowDays}
                        data={serverCostUsageDataMonthly || serverCostUsageDataDaily}
                        margin={{
                          top: 20,
                          right: 10,
                          left: -25,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="period" />
                        <YAxis />
                        <Tooltip wrapperStyle={{ outline: "none" }} content={({active, payload, label}) => {
                          if (active) {
                            const value: typeof serverCostUsageDataMonthly[number] = payload?.[0].payload;
                            if (value) {
                              return (
                                <div className="admin-dashboard-chart-tooltip small">
                                  <div>{label}</div>
                                  <div>{t('admin.dashboard.serverCostSummary.cost')}: HK${value.cost}</div>
                                  {value.isThisMonth && (
                                    <div>{t('admin.dashboard.serverCostSummary.forecast')}: HK${value.forecast}</div>
                                  )}
                                </div>
                              )
                            }
                            
                          }
                        }}/>
                        <Area type="monotone" dataKey="forecast" stroke="#155ea5" stackId="2" fill="#bbcfe3"  activeDot={{ r: 5 }} />
                        <Area type="monotone" dataKey="cost" stroke="#0a345d" stackId="1" fill="#063f77"  activeDot={{ r: 5 }} />
                      </ComposedChart>
                    </ResponsiveContainer>
                    {/* <div><GenericInput 
                      type="switch"
                      value={serverCostUsageDataMonthlyDaily}
                      onChange={() => setServerCostUsageDataDaily(true)}
                    /></div> */}
                    <div className="d-flex justify-content-center mb-1" style={{gap: "0.3rem"}}>
                      {[7, 30].map((days) => (
                        <Button 
                          key={days} 
                          size="sm"
                          className="px-3"
                          variant={clsx(serverCostUsageShowDays == days ? "info" : "outline-info")} 
                          style={{flex: 1}}
                          onClick={() => {
                            setServerCostUsageShowDays(days);
                            setServerCostUsageShowMonths(null);
                          }}
                        >{t('admin.dashboard.serverCostSummary.days', {days})}</Button>
                      ))}
                    </div>
                    <div className="d-flex justify-content-center w-100" style={{gap: "0.3rem"}}>
                      {[3, 6, 12].map((months) => (
                        <Button 
                          key={months} 
                          size="sm"
                          className="px-3"
                          variant={clsx(serverCostUsageShowMonths == months ? "info" : "outline-info")} 
                          style={{flex: 1}}
                          onClick={() => {
                            setServerCostUsageShowMonths(months);
                            setServerCostUsageShowDays(null);
                          }}
                        >{t('admin.dashboard.serverCostSummary.months', {months})}</Button>
                      ))}
                    </div>
                  </div>
                </div>
              ),


            ].filter(element => !!element)
          }
        </Masonry>
      </ResponsiveMasonry>
    </AdminComponent.Container>
  )
}


// var CryptoJS = require("crypto-js");

import CryptoJS from 'crypto-js';

export interface FileHelperExportOption<DataType = string> {
  data: DataType, 
  filename: string,
  format?: string
}

export class FileHelper {

  static readonly JsonFormat = "application/json";
  static readonly GeneralFormat = "application/octet-stream";


  static encryptData(data: string, key: string): string {
    let encryptedData = CryptoJS.AES.encrypt(
      data,
      key
    ).toString();
    return encryptedData;
  }

  static decryptData(encryptedData: string, key: string): string {
    let decryptedData = CryptoJS.AES.decrypt(encryptedData, key).toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }

  static export(option: FileHelperExportOption) {
    const blob = new Blob([option.data], { type: option.format || "text/plain" });
    const link = document.createElement("a");

    link.download = option.filename;
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = [option.format, link.download, link.href].join(":");

    const evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });

    link.dispatchEvent(evt);
    link.remove()
  }

  static exportJson(option: FileHelperExportOption<Object>) {
    return this.export({
      format: this.JsonFormat,
      ...option,
      data: JSON.stringify(option.data)
    });
  }

  static exportEncrypted(
    option: FileHelperExportOption<string> & {
      key: string
    }) {

    let encryptedData = CryptoJS.AES.encrypt(
      option.data,
      option.key
    ).toString();

    let decryptedData = CryptoJS.AES.decrypt(encryptedData, option.key );
    console.log(decryptedData.toString(CryptoJS.enc.Utf8));

    return this.export({
      format: this.GeneralFormat,
      ...option,
      filename: option.filename + ".aes",
      data: encryptedData
    });
  }

  static exportEncryptedJson(
    option: FileHelperExportOption<Object> & {
      key: string
  }) {
    return this.exportEncrypted({
      format: this.GeneralFormat,
      ...option,
      data: JSON.stringify(option.data)
    });
  }


  static async importWithFileInfo(): Promise<[string, File] | null> {
    return new Promise((resolve, reject) => {
      const input = document.createElement("input");
      input.type = "file";
      input.onchange = () => {
        if (input.files && input.files[0]) {
          let file = input.files[0];
          let reader = new FileReader();
          reader.readAsText(file);
          reader.onload = () => {
            resolve([reader.result!.toString(), file]);
          }
          reader.onerror = (e) => {
            reject(e);
          }
          reader.onabort = () => {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      }
      const evt = new MouseEvent("click");
  
      input.dispatchEvent(evt);
      input.remove();
    })

  }

  static async import(): Promise<string | null> {
    let result = await this.importWithFileInfo();
    return result?.[0] ?? null;
  }



  static async importJson(): Promise<Object> {
    try {
      return JSON.parse(await this.import() as any);
    } catch(e) {
      throw e;
    }
  }
}


import { useMemo } from "react";
import ReactSelect, { components, GroupBase, MultiValueProps, Props } from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/stateManager";
import {
  DndContext, 
  useSensor,
  useSensors,
  closestCenter,
  MouseSensor,
  TouchSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { InputHiddenRequired } from "../../../component/InputHiddenRequired";


// https://docs.dndkit.com/presets/sortable

interface OptionType<ValueType,> {
  label: any,
  value: ValueType
}
const SortableMultiValue = 
  <Option extends OptionType<ValueType>, ValueType extends number | string>
  (props: MultiValueProps<Option, boolean, GroupBase<Option>>) => {
  
  const id = props.data.value;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({id});
  
  const style = useMemo(() => (
    {
      transition,
      transform: transform && `translate(${transform.x}px, ${transform.y}px)`,
      zIndex: isDragging ? 1 : 0,
      touchAction: "none"
    }
  ), [transform, isDragging]);

  return <div key={id} ref={setNodeRef} style={style} {...attributes} {...listeners} onClick={(event) => {
    event.stopPropagation();
  
  }}>
    <components.MultiValue {...props} />
  </div>;
};

export const SortableSelect = 
  <Option extends OptionType<ValueType>, ValueType extends number | string>(props: Omit<StateManagerProps<Option>, "value" | "options"> & {
    multiSelectSortable?: boolean,
    value: Option | Option[],
    options: Option[],
    onChange?: (newValue: Option | Option[]) => void,
    isClearable?: boolean,
    required?: boolean,
  }) => {

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(TouchSensor, {
      // activationConstraint: {
      //   delay: 250,
      //   tolerance: 1,
      // }
    })
  );

  if (props.isMulti && props.multiSelectSortable && Array.isArray(props.value)) {
    // const options = props.options?.map((option, index) => ({
    //   id: option.value,
    //   ...option,
    //   label: <>{index+1}: {option.label}</> 
    // }));
    return <>
      <DndContext 
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[restrictToParentElement]}
        autoScroll={false}
        onDragEnd={(event) => {
          const {active, over} = event;
          if (active.id !== over.id && Array.isArray(props.value)) {
            const oldIndex = props.value.findIndex(option => option.value == active.id);
            const newIndex = props.value.findIndex(option => option.value == over.id);
            console.log(oldIndex, newIndex);
            const newArray = arrayMove(props.value, oldIndex, newIndex);
            props.onChange?.(newArray);
          }
        }}
      >
        <SortableContext 
          items={props.value.map(item => ({
            id: item.value
          }))}
          strategy={rectSortingStrategy}
        >
          <ReactSelect
            {...props}
            components={{
              MultiValue: SortableMultiValue,
              // ValueContainer: (props) => (<components.ValueContainer {...props}>Hi</components.ValueContainer>)
            }}
            isClearable={props.isClearable ?? true}
            
          ></ReactSelect>
        </SortableContext>
      </DndContext>
      {
        (props.required && (props.value == null || props.value?.length == 0)) && <InputHiddenRequired />
      }
    </>
  } else {
    return <>
      <ReactSelect
        {...props}
        value={props.value}
        isClearable={props.isClearable ?? true}
      />
      {
        (props.required && props.value == null) && <InputHiddenRequired />
      }
    </>
  }

}
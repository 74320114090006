import { faAngleLeft, faAngleRight, faCaretDown, faCaretUp, faPenToSquare, faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { ComponentPropsWithRef, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, LinkProps, useLocation, useNavigate } from "react-router-dom";
import { useI18N } from "../../../component/I18NProvider";
import { Flipper, Flipped } from 'react-flip-toolkit';
import "../../../styles/admin/table.scss";
import { useScrollToId } from "../../../utils/scroll-to-id";

export type AdminTableKeysSortType = "ASC" | "DESC" | null;

export type AdminTableKeys = {
  [key: string]: {
    content: React.ReactNode;
    shrink?: boolean;
    sticky?: boolean;
    hidden?: boolean;
    noLink?: boolean;
    headerHighlighted?: boolean;
    sort?: {
      type?: AdminTableKeysSortType,
      onChange?: (newType: AdminTableKeysSortType) => void,
      onClick?: () => void,
      descFirst?: boolean, // if true, DESC first
    },
    previewArea?: React.ReactNode;
  }
}

export const AdminTableLink = (props: LinkProps) => {
  return <Link {...props} className={clsx("admin-table-td-link", props.className)} />
}

export const AdminTable = <T extends AdminTableKeys>(props: ComponentPropsWithRef<"table"> & {
  divProps?: ComponentPropsWithRef<"div">,
  styles?: {
    tbody?: React.CSSProperties
  },
  loading?: boolean;
  keys: T;
  scrollable?: boolean;
  sticky?: boolean;
  rows: {
    key?: string,
    id?: string,
    onClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void,
    onRowLinkClick?: React.MouseEventHandler<HTMLAnchorElement>,
    content: {
      [key in keyof T] : React.ReactNode | {
        cellProps?: React.ComponentProps<"td">,
        content: React.ReactNode,
      }
    },
    editLink?: string,
    rowLink?: string,
    onDelete?: () => {},
    selected?: boolean
  }[] | null;
  editable?: boolean;
  deletable?: boolean;
  // useHash?: boolean;
  onScrollToId?: (id: string) => void;
  onScrollY?: (y: number) => void;
  onScrollToIdDelay?: number;
  disableSort?: boolean;
  postfix?: React.ReactNode;
}) => {
  const {divProps, styles, className, loading, keys, scrollable, sticky, rows, editable, deletable, onScrollToId, onScrollToIdDelay, onScrollY, disableSort, postfix, ...tableProps} = props;
  const {t} = useI18N();
  
  const { pathname, hash, search } = useLocation();
  const navigate = useNavigate();
  const [scrolledToId, setScrolledToId] = useState<string>(null);
  const scrolledToIdRef = useRef<string>(null);

  // const history = useHistory();
  // use
  const divRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const divPropsRef = divProps?.ref as any;
  //   if (divPropsRef) {
  //     divPropsRef.current = divRef.current;
  //   }

  //   const onScroll = () => {
      
  //     console.log("TEST");
  //     if (rows && onScrollToId) {
  //       const scrollTop = divRef.current.scrollTop;
  //       const divRect = divRef.current.getBoundingClientRect();
  
  //       let newScrolledToId: string = null;
  //       for (const row of rows) {
  //         if (row.id) {
  //           // console.log(row.id);
  //           const rowEle = document.getElementById(row.id);
  //           if (rowEle) {
  //             const rowRect = rowEle.getBoundingClientRect();
  //             // console.log(rowEle.clientHeight);
  //             const y = rowRect.top - divRect.top + rowEle.clientHeight;
  //             if (y > 0) {

  //               // console.log(rowEle.offsetTop);
  //               // console.log(y);
  //               newScrolledToId = rowEle.id;
  //               break;
  //             }
  //           }
  //         }
  //       }
        
  //       setScrolledToId(newScrolledToId);
  //       // console.log(scrolledId);
  //       // navigate({pathname, hash: scrolledId, search}, {replace: true});
  //       // onScrollToId(newScrolledId);
  //     }

      
  //     if (props.onScrollY) {
  //       const divRect = divRef.current.getBoundingClientRect();
        
  //       console.log(divRect)
  //     }

  //     console.log("WTF");
  //   }
  //   divRef.current?.removeEventListener("scroll", onScroll as any); 
  //   divRef.current?.addEventListener("scroll", onScroll); 

  //   return () => {
  //     divRef.current?.removeEventListener("scroll", onScroll);
  //   }
  // }, [divRef.current, rows])

  useEffect(() => {
    const onScroll = () => {
      console.log("Hi");
    }
    divRef.current?.addEventListener("scroll", onScroll);
    return () => {

    }
  }, [divRef.current])

  useEffect(() => {

    if (onScrollToId) {
      if (!onScrollToIdDelay) {
        onScrollToId(scrolledToId);
      } else {
        scrolledToIdRef.current = scrolledToId;
        setTimeout(() => {
          if (scrolledToIdRef.current == scrolledToId) {
            onScrollToId(scrolledToId);
          }
        }, onScrollToIdDelay)
      }
    }
  }, [scrolledToId]);

  return (
    <div {...divProps} 
      className={clsx("d-flex w-100", scrollable && "admin-table-scrollable", divProps?.className)}
      // ref={divRef}
      onScroll={() => {
        console.log("T");
      }}
    >
      
      {
        keys && (
          <Flipper flipKey={rows?.map(row => row.id)?.join("")} className="w-100"  >
            <table {...tableProps} 
              className={clsx("table admin-table", sticky && "sticky", className)}
            >
              <thead>
                <tr className="header">
                  {
                    editable && (
                      <th scope="col"></th>
                    )
                  }
                  {
                    Object.entries(keys).map(([key, value]) => {
                      if (value.hidden) {
                        return <React.Fragment key={key} />;
                      }
                      let {headerHighlighted, sort, content, previewArea, sticky} = value;
                      if (disableSort) {
                        sort = null;
                      }
                      
                      return (
                        <th key={key} scope={clsx("col")} className={clsx(
                          (headerHighlighted || !!sort?.type) && "highlighted",
                          sort && "sortable",
                          sticky && "sticky",
                        )}
                          onClick={() => {
                            if (sort) {
                              const orders: AdminTableKeysSortType[] = [null, "ASC", "DESC"];
                              if (sort.descFirst) {orders.reverse()}
                              const currentSortIndex = orders.indexOf(sort.type || null);
                              const newSortIndex = (currentSortIndex + 1) % orders.length;
                              sort?.onChange?.(orders[newSortIndex]);
                              sort?.onClick?.();
                            }
                            
                          }}
                        >
                          <div>
                            {content}
                            {
                              sort && <FontAwesomeIcon fixedWidth
                                icon={
                                  sort?.type == "ASC" ? faSortUp : 
                                  sort?.type == "DESC" ? faSortDown : faSort
                                }
                                // className={clsx("icon", !!currentValue && "active", !currentValue && "transparent")}
                                className={clsx("ms-1 sort-icon", !sort?.type && "inactive")}
                                // style={{cursor: }}
                              />
                            }
                          </div>
                          <div className="d-flex align-items-center justify-content-center flex-wrap" style={{gap: "0.3rem"}}>
                          {
                            previewArea
                          }
                          </div>
                          
                        </th>
                      )
                    })
                  }
                </tr>
              </thead>
              <tbody
                style={styles?.tbody}
              >
                {
                  rows?.length == 0 && (
                    <tr>
                      <td colSpan={Object.keys(keys).length} className="admin-table-empty">
                        {t('entityData.empty')}
                      </td>
                    </tr>
                  )
                }
                {
                  loading && !rows && (
                    <tr className="loading">
                      <td colSpan={Object.keys(keys).length} className="admin-table-loading">
                        <Spinner animation="border" className="my-3" />
                      </td>
                    </tr>
                  )
                }
                {
                  rows?.map((row, index) => {
                    if (row == null) {return <React.Fragment key={index} />;}
                    let {key, content, selected, rowLink, onClick, onRowLinkClick} = row;

                    return (
                      <Flipped key={row.id || index} flipId={row.id} >
                        <tr key={key || index} onClick={(event) => onClick?.(event)} className={clsx(selected && "selected", !!onClick && "selectable")}
                          id={row.id}
                        >
                          {
                            props.editable && (
                              <td className="shrink">
                                <Link to={row.editLink!}>
                                  <FontAwesomeIcon icon={faPenToSquare} />
                                </Link>
                              </td>
                            )
                          }
                          {
                            Object.keys(keys).map((key, index) => {
                              if (keys[key].hidden) {
                                return <React.Fragment key={key} />;
                              }
                              const isCellPropsOject = (content[key] as Object)?.hasOwnProperty("content");

                              const cellProps = isCellPropsOject ? (content[key] as any).cellProps : {};
                              const children = isCellPropsOject ? (content[key] as any).content : content[key];

                              // console.log(children);
                              return (
                                <td key={key} {...cellProps} className={clsx(
                                  keys[key].shrink && "shrink", 
                                  selected && "selected", 
                                  rowLink && "td-link",
                                  keys[key].sticky && "sticky",
                                  cellProps?.className
                                )}>
                                  {/* {
                                    row.id && index == 0 && <a id={row.id} />
                                  } */}
                                  {
                                    rowLink && !keys[key].noLink ? (
                                      <AdminTableLink 
                                        to={rowLink}
                                        onClick={(event) => {
                                          onRowLinkClick?.(event);
                                        }}
                                      >{children}</AdminTableLink>
                                    ) : children
                                  }
                                </td>
                              )
                            })
                          }
                        </tr>
                      </Flipped>
                    )
                  })
                }
              </tbody>
            </table>
          </Flipper>
        )
      
      }

      {
        postfix
      }
    </div>
  );

}



export const validateEmail = (str: string) : string | false => {
  if (str.trim() == "") return false;
  return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(str) ? str : false;
}

export const validatePhoneNumber = (str: string, prefix = "852"): string | false => {
  if (!(str && str.trim() != "")) return false;
  const phoneNumber = str.replace(/\-|\+|\s/g, "");
  if (!(/^\d{8,}$/).test(phoneNumber)) {
    return false;
  } else {
    if (phoneNumber.length == 8) {
      return prefix + phoneNumber;
    } else {
      return phoneNumber;
    }
  }
}
import { AxiosInstance, AxiosRequestConfig } from "axios";
import { SystemAppInfo, SystemCurrentStatus, SystemInfo } from "../entities/system-info";
import { APIBase } from "./apiBase";

export default class SystemInfoAPI extends APIBase {
  
  constructor(axios: AxiosInstance) {
    super(axios, "");
  }

  async getSystemInfo(): Promise<SystemInfo> {
    return (await this.axios.get<SystemInfo>(
      "system-info"
    )).data;
  }

  async getSystemAppInfo(): Promise<SystemAppInfo> {
    return (await this.axios.get<SystemAppInfo>(
      "system-info/app"
    )).data;
  }

  async getCurrentStatus(): Promise<SystemCurrentStatus> {
    return (await this.axios.get<SystemCurrentStatus>(
      "system-info/current-status"
    )).data;
  }


}
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faCircleExclamation, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { MutableRefObject } from "react"
import { Link, LinkProps } from "react-router-dom";
import "../../../styles/admin/data-list.scss";

interface DataListContextProps {
  sortable?: boolean,
  divRef?: MutableRefObject<HTMLDivElement>
}

const DataListContext = React.createContext<DataListContextProps>(null);

const DataList = (props: React.ComponentPropsWithoutRef<"div"> & DataListContextProps) => {
  const {sortable, divRef, ...divProps} = props;
  return (
    <DataListContext.Provider value={{sortable}}>
      <div {...divProps} ref={divRef} className={clsx("data-list", props.className)}>
        {props.children}
      </div>
    </DataListContext.Provider>

  )
}

DataList.Empty = (props: React.ComponentPropsWithoutRef<"div"> & {
   
}) => {
  return (
    <div {...props} className={clsx("data-list-empty", props.className)}>
      <FontAwesomeIcon icon={faCircleExclamation} className="icon" />
      <span>{props.children}</span>
    </div>
  )
}


const DataListItem = (props: React.ComponentPropsWithoutRef<"div"> & {
  clickable?: boolean;
  active?: boolean;
  isNew?: boolean
}) => {
  const {sortable} = React.useContext(DataListContext);
  const {clickable, active, isNew, className, ...divProps} = props;
  return (
    <div {...divProps} className={clsx("data-list-item", clickable && "clickable", active && "active", className)} />
  )
}

DataListItem.Title = (props: React.ComponentPropsWithoutRef<"div">) => {
  return (
    <div {...props} className={clsx("data-list-item-title", props.className)}>
      {props.children}
    </div>
  )
}

DataListItem.TitleBtns = (props: React.ComponentPropsWithoutRef<"div">) => {
  return (
    <div {...props} className={clsx("data-list-item-title-btns", props.className)}>
      {props.children}
    </div>
  )
}

DataListItem.EditButton =  (props: React.ComponentPropsWithoutRef<"div">) => {
  return (
    <div {...props} className={clsx("data-list-item-edit-btn", props.className)}>
      <FontAwesomeIcon icon={faPenToSquare} />
    </div>
  )
}

DataListItem.EditLink = (props: LinkProps) => {
  return (
    <Link {...props} className={clsx("data-list-item-edit-btn", props.className)}>
      <FontAwesomeIcon icon={faPenToSquare} />
    </Link>
  )
}

DataListItem.Body = (props: React.ComponentPropsWithoutRef<"div">) => {
  return (
    <div {...props} className={clsx("data-list-item-body", props.className)}>
      {props.children}
    </div>
  )
}


export {DataList, DataListItem}
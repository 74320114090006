import { Exclude } from "class-transformer";
import { IsString } from "class-validator";
import { TimestampEntity } from "./timestamp.entity";
import { ApiProperty, Column, Entity, OmitType, PrimaryColumn } from "./vars";



@Entity()
export class PdfConfig {
  @ApiProperty()
  @Exclude()
  @PrimaryColumn("tinyint", {
    default: 1
  })
  id: number = 1;

  @ApiProperty()
  @IsString()
  @Column("longtext") 
  logoBase64: string = "";

  @ApiProperty()
  @Column("text")
  @IsString()
  headerCompanyInfoEn: string = "";

  @ApiProperty()
  @Column("text")
  @IsString()
  headerCompanyInfoTc: string = "";

  @ApiProperty()
  @Column("text")
  @IsString()
  contactInfoEn: string = "";

  @ApiProperty()
  @Column("text")
  @IsString()
  contactInfoTc: string = "";

  @ApiProperty()
  @Column("text")
  @IsString()
  footerDisclaimerEn: string = "";

  @ApiProperty()
  @Column("text")
  @IsString()
  footerDisclaimerTc: string = "";

  @ApiProperty()
  @Column(() => TimestampEntity)
  timestamp: TimestampEntity
}

export class PdfConfigDto extends OmitType(PdfConfig, ["id", "timestamp"] as const) {}
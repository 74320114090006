import { ComponentPropsWithoutRef, ComponentPropsWithRef, createRef, Dispatch, SetStateAction, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { EntitySort, FindAllQuery } from "../../../api/entities/findAllQuery"
import { AdminPagination } from "./Pagination";
import "../../../styles/admin/find-all-query.scss";
import clsx from "clsx";
import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faArrowDownShortWide, faArrowDownWideShort, faCaretDown, faCaretUp, faFilter, faGear, faSearch, faSort, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import useResizeObserver from '@react-hook/resize-observer'
import { Badge, BadgeProps, Spinner } from "react-bootstrap";
import { PaginationMeta } from "../../../api/entities/pagination";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import _ from 'lodash';
import { useRefDimension } from "../../../utils/use-ref-dimension";
import { ArrayRange } from "../../../utils/array-range";

const nullOrNaNToNull = (val: number) => {
  if (!val || isNaN(val)) {
    return null;
  } else {
    return val;
  }
}

const emptyStringToNull = (str: string) => {
  if (!str || str == "") {
    return null;
  } else {
    return str;
  }
}

const DEFAULT_SEARCH_INPUT_DELAY = 400;

export const BadgeCancellable = (props: BadgeProps & {onCancel?: () => void}) => {
  const {onCancel, children, className, ...badgeProps} = props;
  return (
    <Badge {...badgeProps} className={clsx("d-inline-flex align-items-center justify-content-center", className)}>
      {children}
      {
        onCancel && <FontAwesomeIcon
          onClick={(event) => { event.preventDefault(); event.stopPropagation(); onCancel?.()}}
          style={{cursor: "pointer"}}
          icon={faXmark}
          size="sm"
          className="py-1 px-1 ms-1"
        />
      }
      
    
    </Badge>
  )
}

export const MiniSearchButton = (props: Omit<FontAwesomeIconProps, "icon">) => {

  return <FontAwesomeIcon 
    icon={faSearch} 
    {...props}
    className={clsx("text-muted px-1 py-1", props.className)}
    style={{cursor: "pointer"}}
  />;

}

export const MiniFilterButton = (props: Omit<FontAwesomeIconProps, "icon">) => {

  return <FontAwesomeIcon 
    icon={faFilter} 
    {...props}
    className={clsx("text-muted px-1 py-1", props.className)}
    style={{cursor: "pointer"}}
  />;

}

const PaginationUI = (props: {
  setFindAllQuery: Dispatch<SetStateAction<FindAllQuery<any>>>,
  paginationMeta: PaginationMeta,
  isInit: boolean,
  disabled: boolean,
  scrollToTop: () => void,
} & ComponentPropsWithoutRef<"div">) => {
  const {setFindAllQuery, paginationMeta, isInit, disabled, scrollToTop, className, ...divProps} = props;
  const {currentPage, itemsPerPage, totalItems, itemCount, totalPages} = paginationMeta || {};

  const {dimension, ref} = useRefDimension();
  // const [showPageCount, setShowPageCount] = useState(5);

  const showPageCount = useMemo(() => {
    const width = dimension?.width;

    if (width < 400) {
      return 3;
    } else if (width < 600) {
      return 3;
    } else if (width < 800) {
      return 5;
    } else if (width < 900) {
      return 7;
    } else if (width < 1000) {
      return 9;
    } else if (width < 1100) {
      return 11;
    } else {
      return 13;
    }
  }, [dimension?.width])
  // console.log({width: dimension?.width});
  // const {from, to} = useMemo(() => {
  //   let from = currentPage && Math.max(currentPage - Math.floor(showPageCount / 2), 1);
  //   let to = currentPage && Math.min(currentPage - Math.floor(showPageCount / 2), totalPages);
  //   return {from, to};
  // }, [currentPage, totalPages, showPageCount]);

  const {from, to} = useMemo(() => {
    let from = currentPage && Math.max(Math.min(currentPage - Math.floor(showPageCount / 2), totalPages - showPageCount + 1), 1);
    let to = currentPage && Math.min(Math.max(currentPage + Math.floor(showPageCount / 2), showPageCount), totalPages);
    return {from, to};
  }, [currentPage, totalPages, showPageCount]);

  const pageRange = useMemo(() => {
    console.log({currentPage, from, to, totalPages})
    return from && to && ArrayRange(from, to);
  }, [from, to]);

  return (
    <div ref={ref} {...divProps} className={clsx("find-all-query-pagination", 
      !(isInit && paginationMeta && totalItems > 0) && "hidden", 
      className
    )}>
      {/* <div className={clsx("pagination-prev", (currentPage <= 1 || disabled) && "disabled")} onClick={() => {
        setFindAllQuery(query => ({...query, page: 1}));
        scrollToTop();
      }}>
        <FontAwesomeIcon icon={faAnglesLeft} size="sm" />
      </div> */}

      <div className={clsx("pagination-prev", (currentPage <= 1 || disabled) && "disabled")} onClick={() => {
        setFindAllQuery(query => ({...query, page: currentPage - 1}));
        scrollToTop();
      }}>
        <FontAwesomeIcon icon={faAngleLeft} size="sm" />
      </div>
      {/* <div className="pagination-text">
        {
          `${(currentPage - 1) * itemsPerPage + 1} - ` + 
          `${(currentPage - 1) * itemsPerPage + itemCount} / ` + 
          `${totalItems}`
        }
      </div> */}
      {
        from && from != 1 && (
          <div className={clsx("pagination-item", disabled && "disabled")}
            onClick={() => {
              setFindAllQuery(query => ({...query, page: 1}));
              scrollToTop();
            }}
          >1</div>
        )
      }

      {
        from && from > 2 && (
          <div className={clsx("pagination-item")}
            onClick={() => {
              setFindAllQuery(query => ({...query, page: from - 1}));
              scrollToTop();
            }}
          >...</div>
        )
      }

      

      {
        pageRange?.map?.(page => {
          const selected = page == currentPage;
          return <div key={page} className={clsx("pagination-item", selected && "selected", disabled && "disabled")}
            onClick={() => {
              setFindAllQuery(query => ({...query, page: page}));
        scrollToTop();
            }}
          >{page}</div>
        })
      }

      {
        to && to < totalPages - 1 && (
          <div className={clsx("pagination-item")}
            onClick={() => {
              setFindAllQuery(query => ({...query, page: to + 1}));
              scrollToTop();
            }}
          >...</div>
        )
      }

      {
        to && to != totalPages && (
          <div className={clsx("pagination-item", disabled && "disabled")}
            onClick={() => {
              setFindAllQuery(query => ({...query, page: totalPages}));
              scrollToTop();
            }}
          >{totalPages}</div>
        )
      }
      <div className={clsx("pagination-next", (currentPage >= totalPages || disabled) && "disabled")} onClick={() => {
        // console.log(currentPage);
        setFindAllQuery(query => ({...query, page: currentPage + 1}));
        scrollToTop();
      }}>
        <FontAwesomeIcon icon={faAngleRight} size="sm" />
      </div>
      {/* <div className={clsx("pagination-next", (currentPage >= totalPages || disabled) && "disabled")} onClick={() => {
        // console.log(currentPage);
        setFindAllQuery(query => ({...query, page: totalPages}));
        scrollToTop();
      }}>
        <FontAwesomeIcon icon={faAnglesRight} size="sm" />
      </div> */}
    </div>
  )
}
export const useFindAllQuery = (props: {
  isInit?: boolean;
  loading?: boolean;
  disabled?: boolean;
  paginationMeta?: PaginationMeta,
  sortKeys?: string[],
  shouldUseSearchParams?: boolean;
  additionalSearchParams?: {[key: string]: string};
  hashForSearchParams?: string,
  defaultPage?: number,
  defaultLimit?: number,
  searchOnSubmit?: boolean,
  searchInputDelay?: number | boolean,
}) => {
  const {loading, disabled, paginationMeta, sortKeys, searchOnSubmit, searchInputDelay, shouldUseSearchParams} = props;
  const isInit = props.isInit ?? true;


  const { dimension: searchInputPrefixDimension, ref: searchInputPrefixRef } = useRefDimension();
  const { dimension: searchInputPostfixDimension, ref: searchInputPostfixRef } = useRefDimension();

  const scrollTopRef = useRef<HTMLDivElement | null>(null);
  const [sortByDropdownOpen, setSortByDropdownOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const { pathname, hash, search } = useLocation();
  const navigate = useNavigate();

  const [isSearchParamsInit, setIsSearchParamsInit] = useState(false);
  const defaultPage = props.defaultPage ?? 1;
  const defaultLimit = props.defaultLimit ?? 100;
  const [searchInput, setSearchInput] = useState(emptyStringToNull(searchParams.get('searchInput')) ?? "");
  const searchInputRef = useRef<string>(emptyStringToNull(searchParams.get('searchInput')) ?? "");
  const [findAllQuery, setFindAllQueryRaw] = useState<FindAllQuery>({
    page: nullOrNaNToNull(parseInt(shouldUseSearchParams && searchParams.get('page'))) ?? defaultPage,
    limit: nullOrNaNToNull(parseInt(shouldUseSearchParams && searchParams.get('limit'))) ?? defaultLimit,
    searchInput: emptyStringToNull(shouldUseSearchParams && searchParams.get('searchInput')) || null,
    sort: (shouldUseSearchParams && FindAllQuery.getSort({sortDto: searchParams.get('sortDto')})) || null,
    filter: (shouldUseSearchParams &&FindAllQuery.getFilterRaw({filterDto: searchParams.get('filterDto')})) || null,

  });

  const resetFindAllQuery = useCallback(() => {
    setFindAllQuery({page: defaultPage, limit: defaultLimit})
  }, [])

  const scrollToTop = useCallback(() => {
    scrollTopRef.current?.scroll({top: 0, behavior: "smooth"});
  }, []);


  const updateFindAllQueryIfChanged = useCallback((action: Parameters<typeof setFindAllQuery>[0]) => {
    setFindAllQuery(query => {
      const newQuery = typeof action === "object" ? action : action(query);
      // console.log({query, newQuery})
      if (!_.isEqual(query, newQuery)) {
        // console.log("CHANGED");
        // console.log(newQuery);
        return {...newQuery};
      }
      return query;
    })
  }, []);

  const setFindAllQuery = useCallback((action: Parameters<typeof setFindAllQueryRaw>[0]) => {
    setFindAllQueryRaw(query => {
      const newQuery = typeof action === "object" ? action : action(query);
      // Remove all null variables

      if (newQuery.filter) {
        for (const [key, value] of Object.entries(newQuery.filter)) {
          if (value == null) {
            delete newQuery.filter[key]
          }
        }
        if (Object.keys(newQuery.filter).length == 0) {
          delete newQuery.filter;
        }
      }

      
      if (newQuery.sort) {
        for (const [key, value] of Object.entries(newQuery.sort)) {
          if (value == null) {
            delete newQuery.sort[key]
          }
        }
        if (Object.keys(newQuery.sort).length == 0) {
          delete newQuery.sort;
        }
      }

      return {...newQuery};
    })
  }, []);

  const searchInputUpdateToQuery = useCallback((newInput: string) => {
    updateFindAllQueryIfChanged(query => ({...query, searchInput: newInput?.trim() || null}))
  }, []);


  // useEffect(() => {
  //   // Search param default getter
  //   if (props.useSearchParams) {
  //     let page = parseInt(searchParams.get('page'));
  //     if (isNaN(page)) {page = null}
  //     let limit = parseInt(searchParams.get('limit'));
  //     if (isNaN(limit)) {limit = null}
  //     let searchInput = searchParams.get('searchInput');
  //     if (!searchInput) {
  //       searchInput = null;
  //     }
  //     // console.log({page, limit});
  //     updateFindAllQueryIfChanged(query => {
  //       return {
  //         ...query,
  //         ...(page && {page}),
  //         ...(limit && {limit}),
  //         ...{searchInput}
  //       }
  //     })
  //     setIsSearchParamsInit(true);
  //   }
  // }, [])

  useEffect(() => {
    // Search param setter
    if (shouldUseSearchParams) {
      const prevSearchParamsString = searchParams.toString();
      findAllQuery.page && searchParams.set("page", findAllQuery.page?.toString());
      findAllQuery.limit && searchParams.set("limit", findAllQuery.limit?.toString());
      findAllQuery.searchInput && findAllQuery.searchInput != "" ? searchParams.set("searchInput", findAllQuery.searchInput) : 
        searchParams.delete("searchInput")
      // console.log("WTFFFFFF");
      // console.log(findAllQuery.searchInput);
      const sortDto = FindAllQuery.toJson({sort: findAllQuery.sort}).sortDto;
      // console.log(sortDto);
      sortDto ? searchParams.set("sortDto", sortDto) : searchParams.delete("sortDto");

      // console.log(findAllQuery.filter);
      const filterDto = FindAllQuery.toJson({filter: findAllQuery.filter}).filterDto;
      // console.log({filterDto});
      filterDto ? searchParams.set("filterDto", filterDto) : searchParams.delete("filterDto");
      
      // setSearchParams(searchParams, {replace: true});

      if (props.additionalSearchParams) {
        console.log(props.additionalSearchParams)
        console.log(props.hashForSearchParams)
        Object.entries(props.additionalSearchParams).forEach(([key, value]) => {
          value != undefined ? searchParams.set(key, value) : searchParams.delete(key)
        })
      }
      const newSearchParamsString = searchParams.toString();
      if (prevSearchParamsString != newSearchParamsString || (props.hashForSearchParams && props.hashForSearchParams != hash)) {
        navigate({
          search: searchParams.toString(),
          hash: props.hashForSearchParams || hash, pathname
        }, {replace: true})
      }

    }
  }, [findAllQuery, findAllQuery?.page, findAllQuery?.limit, findAllQuery?.searchInput, findAllQuery?.sort, findAllQuery?.filter, props.hashForSearchParams,
    shouldUseSearchParams
  ])

  useEffect(() => {
    if (!findAllQuery?.searchInput) {
      setSearchInput("");
    }
  }, [findAllQuery, findAllQuery?.searchInput])
  

  const renderPaginationUI = useCallback((props: ComponentPropsWithoutRef<"div"> & {

  }) => {

    return <PaginationUI
      setFindAllQuery={setFindAllQuery}
      paginationMeta={paginationMeta}
      isInit={isInit}
      disabled={disabled}
      scrollToTop={scrollToTop}
      {...props}
    />
  }, [paginationMeta, loading, disabled])

  // const PaginationUI = useCallback((props: ComponentPropsWithoutRef<"div"> & {

  // }) => {
  //   const {className, ...divProps} = props;
  //   const {currentPage, itemsPerPage, totalItems, itemCount, totalPages} = paginationMeta || {};

  //   const [showPageCount, setShowPageCount] = useState(5);

  //   // const {from, to} = useMemo(() => {
  //   //   let from = currentPage && Math.max(currentPage - Math.floor(showPageCount / 2), 1);
  //   //   let to = currentPage && Math.min(currentPage - Math.floor(showPageCount / 2), totalPages);
  //   //   return {from, to};
  //   // }, [currentPage, totalPages, showPageCount]);

  //   const pageRange = useMemo(() => {
  //     let from = currentPage && Math.max(currentPage - Math.floor(showPageCount / 2), 1);
  //     let to = currentPage && Math.min(currentPage - Math.floor(showPageCount / 2), totalPages);

  //     return from && to && ArrayRange(from, to);
  //   }, [currentPage, totalPages, showPageCount])
  //   return isInit && paginationMeta && totalItems > 0 && (
  //     <div {...divProps} className={clsx("find-all-query-pagination", className)}>
  //       <div className={clsx("pagination-prev", (currentPage <= 1 || disabled) && "disabled")} onClick={() => {
  //         setFindAllQuery(query => ({...query, page: 1}));
  //         scrollToTop();
  //       }}>
  //         <FontAwesomeIcon icon={faAnglesLeft} size="sm" />
  //       </div>

  //       <div className={clsx("pagination-prev", (currentPage <= 1 || disabled) && "disabled")} onClick={() => {
  //         setFindAllQuery(query => ({...query, page: currentPage - 1}));
  //         scrollToTop();
  //       }}>
  //         <FontAwesomeIcon icon={faAngleLeft} size="sm" />
  //       </div>
  //       {/* <div className="pagination-text">
  //         {
  //           `${(currentPage - 1) * itemsPerPage + 1} - ` + 
  //           `${(currentPage - 1) * itemsPerPage + itemCount} / ` + 
  //           `${totalItems}`
  //         }
  //       </div> */}
  //       {
  //         pageRange?.map(page => {
  //           return <div key={page} className="pagination-item">{page}</div>
  //         })
  //       }
  //       <div className={clsx("pagination-next", (currentPage >= totalPages || disabled) && "disabled")} onClick={() => {
  //         // console.log(currentPage);
  //         setFindAllQuery(query => ({...query, page: currentPage + 1}));
  //         scrollToTop();
  //       }}>
  //         <FontAwesomeIcon icon={faAngleRight} size="sm" />
  //       </div>
  //       <div className={clsx("pagination-next", (currentPage >= totalPages || disabled) && "disabled")} onClick={() => {
  //         // console.log(currentPage);
  //         setFindAllQuery(query => ({...query, page: totalPages}));
  //         scrollToTop();
  //       }}>
  //         <FontAwesomeIcon icon={faAnglesRight} size="sm" />
  //       </div>
  //     </div>
  //   )
  // }, [paginationMeta, loading, disabled])

  const renderSearchInput = useCallback((props: {
    divProps?: ComponentPropsWithRef<"form">;
  } & ComponentPropsWithRef<"input">) => {
    const {className, divProps, ...inputProps} = props;

    return (
      <form action="" {...divProps} className={clsx("find-all-query-search-input", divProps?.className)}
        onSubmit={event => {
          event.preventDefault();
          searchInputUpdateToQuery(searchInput);
        }}
        onClick={e => {
          setSortByDropdownOpen(false);
        }}
      >
        <div className="input-prefix" ref={searchInputPrefixRef as any}>
          <div>
            {
              <FontAwesomeIcon icon={faSearch} className={clsx("icon", searchInput != "" && "active")} fixedWidth />
            }
          </div>
        </div>
        <input 
          {...inputProps}
          className={clsx("form-control", className)}
          style={{paddingLeft: searchInputPrefixDimension?.width, paddingRight: searchInputPostfixDimension?.width}}
          value={searchInput}
          disabled={disabled}
          onChange={(event) => {
            const newInput = event.target.value;
            setSearchInput(newInput);
            if (searchInputDelay) {
              const delay = searchInputDelay === true ? DEFAULT_SEARCH_INPUT_DELAY : searchInputDelay;
              searchInputRef.current = newInput;
              setTimeout(() => {
                if (searchInputRef.current == newInput) {
                  searchInputUpdateToQuery(newInput);
                }
              }, delay)
            } else {
              (!searchOnSubmit) && searchInputUpdateToQuery(newInput);
            }
          }}
          type="search"
        />
        <div className="input-postfix" ref={searchInputPostfixRef as any}>
          <div onClick={event => event.stopPropagation()}>
            {
              loading ? (
                <Spinner animation="border" className="loader" />
              ) : (
                sortKeys && <div className="sort-btn" onClick={() => setSortByDropdownOpen(flag => !flag)}>
                  <FontAwesomeIcon icon={faSort} className={clsx("icon", (sortByDropdownOpen || findAllQuery.sort) && "active")} fixedWidth />
                </div>
              )
            }
            {
              sortByDropdownOpen && sortKeys && (
                <div className="sort-by-dropdown">
                  <div className="title">Sort by:</div>
                  {
                    sortKeys.map(key => {
                      key = key as string;
                      const currentValue = findAllQuery.sort?.[key];
                      return (
                        <div key={key} className={clsx("item", disabled && "disabled")}
                          onClick={() => {
                            setFindAllQuery(query => {
                              const oldValue = query.sort?.[key];
                              let sort = {
                                // ...query.sort,   // TODO
                                [key]: oldValue == "DESC" ? "ASC" : oldValue == "ASC" ? undefined : "DESC",
                              } as any;
                              
                              if (!sort[key]) {
                                delete sort[key];
                              }

                              if (Object.keys(sort).length == 0) {
                                sort = null;
                              }

                              return {
                                  ...query,
                                  sort
                                };
                              
                            });
                          }}
                        >
                          <FontAwesomeIcon fixedWidth
                            icon={
                              currentValue == "ASC" ? faArrowDownShortWide : 
                              currentValue == "DESC" ? faArrowDownWideShort : faSort
                            }
                            className={clsx("icon", !!currentValue && "active", !currentValue && "transparent")}
                          />
                          {key}
                        </div>
                      )
                    })
                  }
                </div>
              )
            }
          </div>
          {/* <div onClick={event => event.stopPropagation()}>
            {
              <FontAwesomeIcon icon={faSearch} className={clsx("icon", searchInput != "" && "active")} fixedWidth />
            }
          </div> */}
        </div>
      </form>
    )

  }, [searchInput, searchInputPrefixDimension?.width, sortByDropdownOpen, loading, disabled])
  
  const renderSortButton = (props: {
    key: string,
    className?: string,
  }) => {
    const {key, className} = props;
    const currentValue = findAllQuery.sort?.[key];
    return (
      <div key={key} className={clsx("item", disabled && "disabled", className)}
        style={{cursor: "pointer"}}
        onClick={() => {
          setFindAllQuery(query => {
            const oldValue = query.sort?.[key];
            let sort = {
              ...query.sort,   // TODO
              [key]: oldValue == "DESC" ? "ASC" : oldValue == "ASC" ? undefined : "DESC",
            } as any;
            
            if (!sort[key]) {
              delete sort[key];
            }

            if (Object.keys(sort).length == 0) {
              sort = null;
            }

            return {
              ...query,
              sort
            };
            
          });
        }}
      >
        <FontAwesomeIcon fixedWidth
          icon={
            currentValue == "ASC" ? faCaretDown : 
            currentValue == "DESC" ? faCaretUp : faSort
          }
          // className={clsx("icon", !!currentValue && "active", !currentValue && "transparent")}
          className={clsx(!currentValue && "text-black-50")}
          // style={{cursor: }}
        />
      </div>
    )
  }

  return {
    findAllQuery, setFindAllQuery, resetFindAllQuery,
    renderPaginationUI, renderSearchInput, renderSortButton,
    PaginationUI,
    scrollTopRef, 
    searchInput, setSearchInput,
    sortByDropdownOpen, setSortByDropdownOpen
  }
}
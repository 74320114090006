import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactTooltip from '@huner2/react-tooltip';
import moment from 'moment';
import clsx from 'clsx';
import { useI18N } from '../../../component/I18NProvider';
import { TimestampEntity } from '../../../api/entities/timestamp.entity';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { UserPreview } from '../../../api/entities/user.entity';
import { TimestampEntityWithUser } from '../../../api/entities/timestamp-with-user.entity';

var Count = 0;

export const EntityTimestamp = (props: {
  timestamp?: Partial<TimestampEntityWithUser>,
  created?: Date,
  updated?: Date,
  updatedBy?: string | UserPreview,
  createdBy?: string | UserPreview,
  hidden?: boolean,
  showText?: boolean;
  className?: string,
  size?: SizeProp
  inline?: boolean
}) => {

  let { t } = useI18N();
  const format = "DD/MM/YYYY HH:mm:ss"

  const [tick, setTick] = useState(false);
  const tickRef = useRef(false);

  const created = useMemo(() => props.timestamp?.created || props.created, [props.timestamp, props.created]);
  const updated = useMemo(() => props.timestamp?.updated || props.updated, [props.timestamp, props.updated]);
  const updatedBy = useMemo(() => {
    const updatedBy = props.timestamp?.updatedBy?.username || props.updatedBy && (
      typeof(props.updatedBy) == "string" ? props.updatedBy : props.updatedBy.username
    );
    return updatedBy && updatedBy != "" && updatedBy
  }, [props.updatedBy]);
  const createdBy = useMemo(() => {
    const createdBy = props.timestamp?.createdBy?.username || props.createdBy && (
      typeof(props.createdBy) == "string" ? props.createdBy : props.createdBy.username
    );
    return createdBy && createdBy != "" && createdBy
  }, [props.createdBy]);

  const lines = useMemo(() => {
    var lines = [];

    if (created) {
      lines.push(t('entityData.timestamp.created') + ": " + moment(created).format(format) + (createdBy ? ` [${createdBy}]` : ""));
    }
    if (updated) {
      lines.push(t('entityData.timestamp.updated') + ": " + moment(updated).format(format) + (updatedBy ?` [${updatedBy}]` : ""));
    }
    return lines;
  }, [created, updated, createdBy, updatedBy])

  let id = "entity-timestamp-" + useMemo(() => {
    return Count++;
  }, []);
  
  const text = useMemo(() => (
    (updated && props.showText && t('entityData.lastUpdated', {ago: moment(Math.min(new Date(updated).getTime(), new Date().getTime())).fromNow()?.trim(), by: updatedBy ? t('entityData.by', {name: updatedBy}): ""})) || ""
  ), [props.showText, updated, updatedBy, tick]);

  useEffect(() => {
    const interval = window.setInterval(() => {
      tickRef.current = !tickRef.current;
      setTick(tickRef.current);
    }, 10000);

    return () => {
      window.clearInterval(interval);
    }
  }, [])
  return (
    !props.hidden ? (
      <div
        className={clsx(props.inline && "d-inline fw-normal")}
      >
        <FontAwesomeIcon 
          onClick={e => {e.stopPropagation(); e.preventDefault();}}
          icon={faClockRotateLeft} 
          style={{cursor: "pointer"}}
          className={clsx(props.className)} 
          color="darkgray"
          data-for={id} 
          data-tip={lines.join("<br/>")}
          size={props.size}
        />
        <ReactTooltip 
          id={id}
          type="dark" 
          effect="solid"
          clickable
          html
          className="text-center"
          delayHide={200}
          
        />
        {
          props.showText && (
            <span className="text-black-50">
              {text}
            </span>
          )
        }
      </div>
    ): null

  )
}

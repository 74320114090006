import { faImages } from "@fortawesome/free-regular-svg-icons";
import { faArrowsRotate, faArrowUpRightFromSquare, faCircleInfo, faDownload, faExclamationCircle, faExclamationTriangle, faEye, faFileUpload, faImage, faMagnifyingGlassMinus, faMagnifyingGlassPlus, faPenToSquare, faTrash, faTriangleExclamation, faUpload } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { APIInstanceWithAuth } from "../../../api"
import { Building, BuildingImageCreator } from "../../../api/entities/building.entity"
import { S3File } from "../../../api/entities/s3-file-system";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider";
import { useLoadingManager } from "../../../utils/loading-manager";
import { AdminBuildingI18NPrefix } from "../src/Building";
import "../../../styles/admin/building-and-property-image-tool.scss";
import { AdminTable, AdminTableKeysSortType } from "./Table";
import moment from "moment";
import { filesize } from "../../../utils/filesize";
import { Badge, ButtonGroup, Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAbortableLoading } from "../../../utils/abortable-loading";
import path from "path-browserify";
import mimeDb from "mime-db";
import { Buffer } from 'buffer';
import { useDropzone } from "react-dropzone";
import clsx from "clsx";
import axios from "axios";
import { LocalCache } from "../../../api/src/local-cache";
import { EntityTimestamp } from "./EntityTimestamp";
import { GenericInput } from "./GenericInput";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import _ from "lodash";
import { downloadImage } from "../../../utils/download-image";

export const AdminBuildingAndPropertyImageToolI18NPrefix = "admin.imageTool.";
const historyDateFormat = "DD/MM/YYYY HH:mm";

const BuildingImagePreviewSizes = [1,2,3,4] as const;
type BuildingImagePreviewSize = (typeof BuildingImagePreviewSizes)[number];
const DefaultBuildingImagePreviewSize: BuildingImagePreviewSize = 2;

export interface ImageFile {
  key: string, 
  processedImageSrc?: string,
  presignedUrl?: string,
}

export const BuildingAndPropertyImageTool = (props: {
  buildingId?: Building["id"],
  propertyId?: Building["id"],
  api: APIInstanceWithAuth,
  loading?: boolean,
  selectable?: boolean,
  selectedImageFiles?: ImageFile[],
  eager?: boolean,
  onChangeSelectedImageFiles?: (imageFiles: ImageFile[]) => void,
  imageMode?: "blob" | "base64" | "presignedUrl",
}) => {
  const {buildingId, propertyId, api, selectable, selectedImageFiles, eager, imageMode: _imageMode} = props;
  const imageMode = useMemo(() => _imageMode ?? "presignedUrl", [_imageMode])
  const {t} = useI18N();
  const modal = useGlobalModal();
  const loading = useLoadingManager();
  const uploadLoading = useLoadingManager();
  const singleImageDownloading = useLoadingManager();
  const [mode, setMode] = useState<"building" | "property">(null);

  const [imagesLoadKey, setImagesLoadKey] = useState(0);
  const updateImagesLoadKey = useCallback(() => {
    setTimeout(() => {
      setImagesLoadKey(val => val++);
    }, 500)
  }, [])
  const [buildingImages, setBuildingImages] = useState<(S3File & {
    mimeType?: string,
    processedImageSrc?: string,
    processedImageSrcWithBase64Prefix?: string,
  })[]>(null);

  const [propertyImages, setPropertyImages] = useState<(S3File & {
    mimeType?: string,
    processedImageSrc?: string
    processedImageSrcWithBase64Prefix?: string,
  })[]>(null);

  const images = useMemo(() => (
    mode == "building" && buildingImages || mode == "property" && propertyImages || null
  ), [mode, buildingImages, propertyImages])

  const allImages = useMemo(() => (
    [...buildingImages ?? [], ...propertyImages ?? []]
  ), [buildingImages, propertyImages])
  
  const open = useMemo(() => !!mode, [mode]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<S3File & {
    mimeType?: string,
    processedImageSrc?: string,
    processedImageSrcWithBase64Prefix?: string,
  }>(null);
  const [selectedFileCreator, setSelectorFileCreator] = useState<BuildingImageCreator>(null);

  const selectedBuildingImageCount = useMemo(() => {
    const imageFileKeys = selectedImageFiles?.map(f => f.key);
    return selectable && buildingImages?.filter(buildingImage => imageFileKeys?.includes(buildingImage.key)).length
  }, [selectable, buildingImages, selectedImageFiles]);

  const selectedPropertyImageCount = useMemo(() => {
    const imageFileKeys = selectedImageFiles?.map(f => f.key);
    return selectable && propertyImages?.filter(propertyImage => imageFileKeys?.includes(propertyImage.key)).length
  }, [selectable, propertyImages, selectedImageFiles]);

  // Remove removed images
  useEffect(() => {
    // if (selectedImageFiles && propertyImages && buildingImages) {
    //   const imageKeysConcat = [...propertyImages, ...buildingImages].map(image => image.key);
    //   const newSelectedImageFiles = selectedImageFiles.filter(file => imageKeysConcat.includes(file.key));
    //   if (!_.isEqual(selectedImageFiles, newSelectedImageFiles)) {
    //     props.onChangeSelectedImageFiles?.(newSelectedImageFiles);
    //   }
    // }
  }, [selectedImageFiles])

  const latestBuildingImageReloadTime = useRef<Date>(null);
  const latestPropertyImageReloadTime = useRef<Date>(null);
  
  const localCache = new LocalCache<{
    size: BuildingImagePreviewSize
  }>({
    prefix: 'admin.building-image-preview.'
  });
  const [imagePreviewSize, setImagePreviewSize] = 
    useState<BuildingImagePreviewSize>(localCache.get<BuildingImagePreviewSize>("size", DefaultBuildingImagePreviewSize));

  const reloadBuildingImages = useCallback(async() => {
    if (buildingId) {
      const startTime = new Date();
      const token = loading.start();
      try {
        const buildingImages = await api.buildings.listImages(buildingId);
  
        if (!latestBuildingImageReloadTime.current || latestBuildingImageReloadTime.current < startTime) {
          setBuildingImages(buildingImages?.filter(file => file.relativePath != "Thumbs.db"));
          latestBuildingImageReloadTime.current = startTime;
        }
      } catch (e) {
        modal.errorSpecific(e);
      }
      loading.stop(token);
    }

  }, [api, buildingId]);

  const reloadPropertyImages = useCallback(async() => {
    if (propertyId) {
      const startTime = new Date();
      const token = loading.start();
      try {
        const propertyImages = await api.property.listImages(propertyId);
  
        if (!latestPropertyImageReloadTime.current || latestPropertyImageReloadTime.current < startTime) {
          setPropertyImages(propertyImages?.filter(file => file.relativePath != "Thumbs.db"));
          latestPropertyImageReloadTime.current = startTime;
        }
      } catch (e) {
        modal.errorSpecific(e);
      }
      loading.stop(token);
    }

  }, [api, propertyId]);

  useEffect(() => {
    if (eager) {
      reloadBuildingImages();
      reloadPropertyImages();
    }
  }, [eager, api, propertyId, buildingId])

  const onDrop = useCallback(async(images: File[]) => {

    await Promise.all(images.map(async(file) => {
      const token = uploadLoading.start();
      try {
        switch (mode) {
          case "building": 
            await api.buildings.uploadImage(buildingId, file);
            reloadBuildingImages();
          break;

          case "property": 
            await api.property.uploadImage(propertyId, file);
            reloadPropertyImages();
          break;
        }
      } catch (e) {
        modal.errorSpecific(e);
      }
      uploadLoading.stop(token);

    }))
  }, [buildingId, propertyId, api, mode])

  const {getRootProps, getInputProps, isDragActive, open: upload} = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    disabled: !open,
  });

  const buildingImagesAllSelected = useMemo(() => (
    props.selectable && _.intersection(props.selectedImageFiles?.map(f => f.key), buildingImages?.map(i => i.key))?.length == buildingImages?.length
  ), [props.selectable, props.selectedImageFiles, buildingImages])

  const propertyImagesAllSelected = useMemo(() => (
    props.selectable && _.intersection(props.selectedImageFiles?.map(f => f.key), propertyImages?.map(i => i.key))?.length == propertyImages?.length
  ), [props.selectable, props.selectedImageFiles, propertyImages])

  const [sortBy, setSortBy] = useState<Partial<Record<keyof typeof buildingImages[0], AdminTableKeysSortType>>>({});



  const imagesSorted = useMemo(() => {
    if (!images) {return images}
    const imagesCloned = [...images];

    Object.entries(sortBy).forEach(([key, type]) => {
      if (type) {
        imagesCloned.sort((a, b) => {
          let sortValue = a[key] == b[key] ? 0 : a[key] < b[key] ? -1 : 1;
          if (type == "DESC") {sortValue = -sortValue}
          return sortValue;
        })
      }
    })
    return imagesCloned;
  }, [sortBy, images])

  const renameImagePrompt = useCallback(async(relativePath: string) => {
    if (buildingId) {
      let extChanged = false;
      let newRelativePath = relativePath;
      do {
        newRelativePath = await modal.prompt(t(AdminBuildingAndPropertyImageToolI18NPrefix + "rename"), {
          type: "text", 
          initialValue: newRelativePath,
        }, true);

        if (!newRelativePath) {return}
        if (relativePath == newRelativePath) {return}

        extChanged = false;
        if (path.extname(relativePath).toLowerCase() != path.extname(newRelativePath).toLowerCase()) {
          extChanged = !await modal.confirm(
            t(AdminBuildingAndPropertyImageToolI18NPrefix + "renameExtWarning.title"),
            t(AdminBuildingAndPropertyImageToolI18NPrefix + "renameExtWarning.body")
          )
        }
      } while (extChanged)
      
      const token = loading.start();
      try {
        await api.buildings.renameImage(buildingId, relativePath, {
          newFilename: newRelativePath
        })
      } catch (e) {
        modal.errorSpecific(e);
      }
      loading.stop(token);
      reloadBuildingImages();
    }
  }, [api, buildingId])

  useEffect(() => {
    if (!BuildingImagePreviewSizes.includes(imagePreviewSize)) {
      setImagePreviewSize(DefaultBuildingImagePreviewSize);
    }

    localCache.set({size: imagePreviewSize});
  }, [imagePreviewSize]);

  useEffect(() => {
    Promise.all(buildingImages?.map(async(file) => {
      const {presignedUrl, processedImageSrc} = file;
      if (!processedImageSrc && presignedUrl) {
        try {
          let ext = path.extname(file.relativePath)?.slice(1)?.toLowerCase();
          let mimeType = Object.keys(mimeDb).find(
            (key) => mimeDb[key].source == 'iana' && (mimeDb[key].extensions?.indexOf(ext) as any) >= 0
          );

          // Method 1: Blob URL
          // const buffer = (await axios.get(presignedUrl, {responseType: 'arraybuffer'})).data;
          
          // const binary = Buffer.from(buffer, "binary");
          // const blob = new Blob([binary.buffer]);
          // const imageSrc = window.URL.createObjectURL(blob);

          // Method 2: Base 64
          if (imageMode == "base64") {
            // const buffer = (await axios.get(presignedUrl, {responseType: 'arraybuffer'})).data;
            const imageSrc = await downloadImage(presignedUrl, "base64")
            
            setBuildingImages(buildingImages => {
              const index = buildingImages.findIndex(f => f.key == file.key);
              if (index != -1) {
                buildingImages = [...buildingImages];
                buildingImages[index] = {
                  ...buildingImages[index],
                  // mimeType,
                  processedImageSrc: imageSrc,
                  processedImageSrcWithBase64Prefix: `data:${mimeType};base64,` + imageSrc,
                }
              }
              return buildingImages;
            })
          }
          

        } catch (e) {
          console.log(e);
        }
      }
    }) ?? [])
  }, [buildingImages, imageMode]);

  useEffect(() => {
    Promise.all(propertyImages?.map(async(file) => {
      const {presignedUrl, processedImageSrc} = file;
      if (!processedImageSrc && presignedUrl) {
        try {
          let ext = path.extname(file.relativePath)?.slice(1)?.toLowerCase();
          let mimeType = Object.keys(mimeDb).find(
            (key) => mimeDb[key].source == 'iana' && (mimeDb[key].extensions?.indexOf(ext) as any) >= 0
          );

          // Method 1: Blob URL
          // const buffer = (await axios.get(presignedUrl, {responseType: 'arraybuffer'})).data;
          
          // const binary = Buffer.from(buffer, "binary");
          // const blob = new Blob([binary.buffer]);
          // const imageSrc = window.URL.createObjectURL(blob);

          // Method 2: Base 64
          if (imageMode == "base64") {
            const imageSrc = await downloadImage(presignedUrl, "base64")
            
            setPropertyImages(buildingImages => {
              const index = buildingImages.findIndex(f => f.key == file.key);
              if (index != -1) {
                buildingImages = [...buildingImages];
                buildingImages[index] = {
                  ...buildingImages[index],
                  // mimeType,
                  processedImageSrc: imageSrc,
                  processedImageSrcWithBase64Prefix: `data:${mimeType};base64,` + imageSrc,
                }
              }
              return buildingImages;
            })
          }
          

        } catch (e) {
          console.log(e);
        }
      }
    }) ?? [])
  }, [propertyImages, imageMode]);

  useEffect(() => {
    switch (mode) {
      case "building":
        reloadBuildingImages();
      break;
      case "property":
        reloadPropertyImages();
      break;
    }
  }, [mode])


  return (
    <div key={buildingId} className={clsx("building-and-property-image-tool", isDragActive && "dragging")} {...getRootProps()}>
      
      <input {...getInputProps()} type="file" accept="image/*" />
      <div className="d-flex align-items-center justify-content-center">
        <ButtonGroup size="sm">
          {
            <ButtonWithLoader
              faIcon={faImages}
              loading={props.loading || loading.flag}
              disabled={buildingId == null}
              onClick={() => {
                setMode(mode => mode == "building" ? null : "building");
              }}
              variant={mode == "building" ? "info" : "outline-info"}
              className="d-flex align-items-center"
            >
              {t(AdminBuildingAndPropertyImageToolI18NPrefix + "buildingImages")}
              {selectable ? <Badge bg="success">{selectedBuildingImageCount} / {buildingImages?.length || 0} </Badge> :
                buildingImages && <Badge bg="success">{buildingImages.length || 0} </Badge>
              }
              {
                buildingId == null && (
                  <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" />
                )
              }
            </ButtonWithLoader>
          }
          {
            propertyId !== undefined && <ButtonWithLoader
              faIcon={faImages}
              loading={props.loading || loading.flag}
              disabled={propertyId == null}
              onClick={() => {
                setMode(mode => mode == "property" ? null : "property");
              }}
              variant={mode == "property" ? "info" : "outline-info"}
              className="d-flex align-items-center"
            >
              {t(AdminBuildingAndPropertyImageToolI18NPrefix + "propertyImages")}
              {selectable ? <Badge bg="success">{selectedPropertyImageCount} / {propertyImages?.length || 0}</Badge> :
                propertyImages && <Badge bg="success">{propertyImages.length || 0} </Badge>
              }
              {
                propertyId == null && (
                  <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" />
                )
              }
            </ButtonWithLoader>
          }
        </ButtonGroup>
      </div>
      {
        mode == "building" && (
          <div className="text-muted text-center">
            <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
            {t(AdminBuildingAndPropertyImageToolI18NPrefix + "buildingImagesTips")}
          </div>
        )
      }
      {
        images && (
          <div className="building-and-property-image-tool-table">
            <AdminTable
              sticky
              keys={{
                ...(
                  props.selectable && {
                    select: {
                      content: (
                        <div className="d-flex align-items-center justify-content-center" onClick={(event) => event.stopPropagation()}>
                          {
                            <GenericInput 
                              type="switch" 
                              value={mode == "building" ? buildingImagesAllSelected : propertyImagesAllSelected}
                              onChange={(flag) => {
                                console.log(flag);
                                let imageFileKeySet = new Set<string>([]);
                                if (flag) {
                                  imageFileKeySet = new Set([
                                    ...props.selectedImageFiles.map(imageFile => imageFile.key),
                                    ...imagesSorted.map(file => file.key)
                                  ]);
                                  
                                } else {
                                  const imagesRemoving = (mode == "building" ? buildingImages : propertyImages)?.map(i => i.key);
                                  imageFileKeySet = new Set([
                                    ...props.selectedImageFiles.map(imageFile => imageFile.key),
                                  ]);
                                  imagesRemoving?.forEach(key => imageFileKeySet.delete(key))

                                }

                                props.onChangeSelectedImageFiles?.([...imageFileKeySet].map(key => {
                                  const file = allImages.find(f => f.key == key);
                                  return file && {
                                    key, processedImageSrc: file.processedImageSrc, presignedUrl: file.presignedUrl
                                  }
                                }).filter(imageFile => !!imageFile));
                                
                              }}
                            />
                          }
                        </div>
                      ),
                      shrink: true,
                    },
                  }
                ),
                preview: {
                  content: (
                    <div className="preview-zoom-btns">
                      {
                        imagePreviewSize > 1 && (
                          <FontAwesomeIcon icon={faMagnifyingGlassMinus} 
                            onClick={() => {
                              setImagePreviewSize(size => (size - 1) as any)
                            }}
                          />
                        )
                      }
                      {
                        imagePreviewSize < 4 && (
                          <FontAwesomeIcon icon={faMagnifyingGlassPlus}
                            onClick={() => {
                              setImagePreviewSize(size => (size + 1) as any)
                            }}
                          />
                        )
                      }
                      
                    </div>
                  ), 
                  // shrink: true
                },
                relativePath: {content: t(AdminBuildingAndPropertyImageToolI18NPrefix + "filename"),
                  sort: {
                    type: sortBy.relativePath,
                    onChange: (newSort) => setSortBy(({relativePath: newSort}))
                  }
                },
                size: {content: t(AdminBuildingAndPropertyImageToolI18NPrefix + "size"), shrink: true,
                  sort: {
                    type: sortBy.size,
                    onChange: (newSort) => setSortBy(({size: newSort}))
                  }
                },
                date: {content: t(AdminBuildingAndPropertyImageToolI18NPrefix + "date"), shrink: true,
                  sort: {
                    type: sortBy.lastModified,
                    onChange: (newSort) => setSortBy(({lastModified: newSort}))
                  }
                }
              }}
              rows={(imagesSorted)?.map((file) => {
                // const selected = props.selectable && props.selectedImageRelativePaths?.includes(file.relativePath);
                let selectedIndex = props.selectable ? props.selectedImageFiles?.findIndex(imageFile => file.key == imageFile.key) : null;
                if (selectedIndex == -1) {selectedIndex = null}
                const showSelectedIndex = props.selectedImageFiles?.length > 1;
                const selected = selectedIndex != null;

                return {
                  key: file.relativePath,
                  selected: selectedIndex != null,
                  content: {
                    ...(
                      props.selectable && {
                        select: <div>
                            <div className="w-100 h-100"
                              style={{position: "absolute", top: 0, left: 0, cursor: "pointer", zIndex: 2}}
                              onClick={() => {

                                let selectedImages = [...props.selectedImageFiles];

                                if (!selected) {
                                  if (imageMode == "presignedUrl" || (imageMode == "base64" && file.processedImageSrc)) {
                                    selectedImages.push({
                                      key: file.key,
                                      processedImageSrc: file.processedImageSrc,
                                      presignedUrl: file.presignedUrl
                                    });
                                  }
                                  
                                } else {
                                  selectedImages = selectedImages.filter(f => f.key != file.key)
                                }
                                props.onChangeSelectedImageFiles?.(selectedImages);
                              }}
                            />
                            <div 
                              className="d-flex align-items-center justify-content-center"
                            >
                            {selectedIndex != null && showSelectedIndex && (
                              <span className="text-success" style={{position: "absolute", zIndex: 1, marginLeft: 16, fontSize: "0.8em", pointerEvents: "none"}}>{selectedIndex + 1}</span>
                            )}
                            <GenericInput type="switch" value={selected} />
                          </div>
                        </div>
                      }
                    ),
                    preview: (
                      <div className="building-and-property-image-tool-preview-image" data-size={imagePreviewSize}
                        onClick={async() => {
                          setSelectedFile(file);
                          setModalOpen(true);
                          setSelectorFileCreator(null);
                          try {
                            const creator = await api.buildings.getImageCreator(buildingId, file.relativePath)
                            setSelectorFileCreator(creator);
                          } catch (e) {

                          }
                        }}
                      >
                        {(
                          imageMode == "presignedUrl" || 
                          (imageMode == "base64" && file.processedImageSrcWithBase64Prefix)
                        ) ? (
                          <img src={imageMode == "presignedUrl" ? file.presignedUrl : imageMode == "base64" && file.processedImageSrcWithBase64Prefix} 
                            onLoad={() => updateImagesLoadKey()}
                            loading="lazy"
                          />
                        ) : (<Spinner animation="border" className="loader" /> )}
                      </div>
                    ),
                    relativePath: <div className="d-flex align-items-center justify-content-center">
                      <span>{file.relativePath}</span>{
                        mode == "building" && 
                        <FontAwesomeIcon icon={faPenToSquare} size="lg" className="ms-1 p-2" style={{cursor: "pointer"}}
                          onClick={() => renameImagePrompt(file.relativePath)}
                        />
                      }
                    </div>,
                    size: <div className="px-1">{filesize(file.size)}</div>,
                    date: <div className="px-1">{moment(file.lastModified).format(historyDateFormat)}</div>
                  }
                }
              })}
            />
          </div>
          
        )
      }
        
      <div className="d-flex align-items-center" style={{gap: "0.6rem"}}>
        
        {
          open && (
            <ButtonWithLoader
              faIcon={faImages}
              loading={props.loading || loading.flag}
              disabled={!open}
              onClick={() => {
                setBuildingImages(null);
                setPropertyImages(null);
                setMode(null);
              }}
              variant={"danger"}
            >
              {t(AdminBuildingAndPropertyImageToolI18NPrefix + "close")}
            </ButtonWithLoader>
          )
        }
        {
          open && (
            <ButtonWithLoader
              faIcon={faArrowsRotate}
              loading={props.loading || loading.flag}
              variant="success"
              onClick={() => {
                switch (mode) {
                  case "building":
                    reloadBuildingImages();
                  break;
                  case "property":
                    reloadPropertyImages();
                  break;
                }
              }}
            />
          )
        }
        {
          open && (
            <ButtonWithLoader
              faIcon={faUpload}
              variant="info"
              loading={props.loading}
              disabled={buildingId == null}
              onClick={upload}
            >{t(AdminBuildingAndPropertyImageToolI18NPrefix + "upload")}
              {
                uploadLoading.flag && (
                  <Spinner animation="grow" className="ms-2" style={{width: "1rem", height: "1rem"}}/>
                )
              }
            </ButtonWithLoader>
          )
        }
      </div>

      {
        selectedFile && (
          <Modal 
            className="building-and-property-image-tool-preview"
            show={modalOpen}
            onHide={() => setModalOpen(false)}
            centered
            backdropClassName="building-and-property-image-tool-preview-backdrop"
          >
            <Modal.Header closeButton>
              <div className="relativePath">
                {selectedFile?.relativePath}
              </div>
              
            </Modal.Header>

            <Modal.Body className="p-0">
              <TransformWrapper centerOnInit centerZoomedOut disablePadding>
                <TransformComponent wrapperClass="w-100 h-100">
                {(
                  imageMode == "presignedUrl" || 
                  (imageMode == "base64" && selectedFile.processedImageSrcWithBase64Prefix)
                ) ? (
                  <img 
                    src={imageMode == "presignedUrl" ? selectedFile.presignedUrl : imageMode == "base64" && selectedFile.processedImageSrcWithBase64Prefix} 
                    className="image-preview p-2"
                    onLoad={() => console.log("Loaded")}
                  />
                ) : (
                  <Spinner animation="border" className="loader"/>
                )}
                </TransformComponent>
              </TransformWrapper>
            </Modal.Body>

            <Modal.Footer>
              <div className="me-3">{
                selectedFileCreator && (
                  <EntityTimestamp
                    created={selectedFileCreator?.createdAt}
                    createdBy={selectedFileCreator?.createdBy?.username}
                    size="lg"
                  />
                )
              }</div>
              
              <ButtonWithLoader
                faIcon={faTrash}
                loading={loading.flag}
                variant="danger"
                onClick={async() => {
                  if (await modal.confirmDelete(true)) {
                    const token = loading.start();
                    try {
                      switch (mode) {
                        case "building":
                          await api.buildings.deleteImage(buildingId, selectedFile.relativePath); 
                          reloadBuildingImages();
                        break;

                        case "property":
                          await api.property.deleteImage(propertyId, selectedFile.relativePath);
                          reloadPropertyImages(); 
                        break;
                      }
                    } catch (e) {
                      modal.errorSpecific(e);
                    }
                    loading.stop(token);
                    setModalOpen(false);
                  }
                }}
              >
              {t(AdminBuildingAndPropertyImageToolI18NPrefix + "delete")}
              </ButtonWithLoader>
              <ButtonWithLoader
                faIcon={faDownload}
                loading={singleImageDownloading.flag || !(imageMode == "presignedUrl" || (imageMode == "base64" && selectedFile.processedImageSrcWithBase64Prefix))}
                onClick={async() => {
                  const token = singleImageDownloading.start();
                  try {
                    const link = document.createElement('a');
                    if (imageMode == "presignedUrl") {
  
                      let ext = path.extname(selectedFile.relativePath)?.slice(1)?.toLowerCase();
                      let mimeType = Object.keys(mimeDb).find(
                        (key) => mimeDb[key].source == 'iana' && (mimeDb[key].extensions?.indexOf(ext) as any) >= 0
                      );
                      const imageSrc = await downloadImage(selectedFile.presignedUrl, "base64", {noCache: true})
                      const base64 = `data:${mimeType};base64,` + imageSrc;
                      link.href = base64;
                    } else if (imageMode == "base64") {
                      link.href = selectedFile.processedImageSrcWithBase64Prefix;
                    }
                    
                    link.setAttribute('download', selectedFile.relativePath);
                    document.body.appendChild(link);
                    link.click();
                  } catch (e) {
                    modal.errorSpecific(e);
                  }
                  singleImageDownloading.stop(token);
                }}
              >{t(AdminBuildingAndPropertyImageToolI18NPrefix + "download")}
              </ButtonWithLoader>
            </Modal.Footer>
          </Modal>
        )
      }
            
    </div>  
  )
}
import { IsBoolean, IsEnum, IsNumber, IsOptional, IsString } from "class-validator";
import { PropertySectionAuditable } from "./property-section-auditable.entity";
import { Column, Entity, ManyToOne, PrimaryColumn, PrimaryGeneratedColumn, ApiProperty, OmitType, Relation, PickType } from "../vars";
import { Property } from "./property.entity";
import { HidePublic } from "../utils/hide-public";


export const PropertyStatuses = [
  "Sale", 
  "SaleAndLease",
  "Lease",
  "Sold",
  "Leased",
  "Pending",
  "SelfUse",
  // "TP",
] as const;

export type PropertyStatus = (typeof PropertyStatuses)[number];

export const PropertySources = [
  "Newspaper",
  "EPRC",
  "Call-in",
  "Cold-call",
  "Refer",
  "Update",
] as const;

export type PropertySource = (typeof PropertySources)[number];

@Entity()
export class PropertyPriceStatus extends PropertySectionAuditable {

  constructor(partial?: Partial<PropertyPriceStatus>) {
    super();
    Object.assign(this, partial);
    // this.postConstructor();
  }

  @ManyToOne(() => Property, {
    nullable: false,
    onUpdate: "CASCADE",
    onDelete: "CASCADE"
  })
  property: Relation<Property>;

  @ApiProperty()
  @IsEnum(PropertyStatuses)
  @IsOptional()
  @Column("enum", {enum: PropertyStatuses, nullable: true})
  status: PropertyStatus = null;

  @HidePublic()
  @ApiProperty()
  @IsEnum(PropertySources)
  @IsOptional()
  @Column("enum", {enum: PropertySources, nullable: true})
  source: PropertySource = null;

  @HidePublic()
  @ApiProperty()
  @IsBoolean()
  @Column("boolean", {
    nullable: false,
    default: false,
  })
  marketable: boolean = false;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  price: number = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  pricePerGrossArea: number = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  pricePerNetArea: number = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  rent: number = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  rentPerGrossArea: number = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  rentPerNetArea: number = null;

  @HidePublic()
  @ApiProperty()
  @IsString()
  @Column("mediumtext")
  priceRemark: string = "";

  @HidePublic()
  @ApiProperty()
  @IsString()
  @IsOptional()
  @Column("mediumtext")
  pdfRemark: string = "";

  
}

export class PropertyPriceStatusDto extends OmitType(
  PropertyPriceStatus, ["id", "property", "updated", "updatedBy"] as const,
) {}

export class PropertyPriceStatusPublic extends PickType(
  PropertyPriceStatus, [
    "status", 
    "price", "pricePerGrossArea", "pricePerNetArea",
    "rent", "rentPerGrossArea", "rentPerNetArea",
  ]
) {
  constructor(partial?: Partial<PropertyPriceStatusPublic>) {
    super();
    Object.assign(this, partial);
    // this.postConstructor();
  }
}
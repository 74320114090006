
import "reflect-metadata";

const DUMMY_DECORATOR = (...args: any) => (...args: any) => {};
const DUMMY_FUNCTION = (...args: any): any => {};

export const ApiProperty = DUMMY_DECORATOR;
export const Entity = DUMMY_DECORATOR;
export const PrimaryColumn = DUMMY_DECORATOR;
export const OneToOne = DUMMY_DECORATOR;
export const ManyToOne = DUMMY_DECORATOR;
export const Column = DUMMY_DECORATOR;
export const JoinColumn = DUMMY_DECORATOR;
export const CreateDateColumn = DUMMY_DECORATOR;
export const UpdateDateColumn = DUMMY_DECORATOR;
export const DeleteDateColumn = DUMMY_DECORATOR;
export const PrimaryGeneratedColumn = DUMMY_DECORATOR;
export const OneToMany = DUMMY_DECORATOR;
export const ManyToMany = DUMMY_DECORATOR;
export const JoinTable = DUMMY_DECORATOR;
export const IsBooleanQueryString = DUMMY_DECORATOR;

export const SerializeOptions = DUMMY_DECORATOR;

export const BaseEntity = class {};

export interface Type<T = any> extends Function {
  new (...args: any[]): T;
}

export function OmitType<T, K extends keyof T>(classRef: Type<T>, keys: readonly K[]): Type<Omit<T, typeof keys[number]>> {return classRef};
export function PickType<T, K extends keyof T>(classRef: Type<T>, keys: readonly K[]): Type<Pick<T, typeof keys[number]>> {return classRef};

export declare type DeepPartial<T> = T | (T extends Array<infer U> ? DeepPartial<U>[] : T extends Map<infer K, infer V> ? Map<DeepPartial<K>, DeepPartial<V>> : T extends Set<infer M> ? Set<DeepPartial<M>> : T extends object ? {
  [K in keyof T]?: DeepPartial<T[K]>;
} : T);

export declare type Relation<T> = T;

export const moment = DUMMY_FUNCTION;
export const BadRequestException = class { constructor(...args: any) {} };

import { Exclude } from "class-transformer";
import { IsBoolean, IsString } from "class-validator";
import { SystemConfig, SystemConfigDto } from "./system-config.entity";
import { ApiProperty, Column, Entity, OmitType, PickType, PrimaryColumn, PrimaryGeneratedColumn } from "./vars";

export interface BackupFile {
  filename: string;
  size: number;
  createdAt: Date;
}

export interface BackupResult {
  relativePath: string;
  time: number;
}

@Entity() 
export class BackupConfig {
  @Exclude()
  @PrimaryColumn("tinyint", {
    default: 1
  })
  id: number = 1;
  
  @ApiProperty()
  DAILY_BACKUP_CRON_INTERVAL = process.env.DAILY_BACKUP_CRON_INTERVAL;

  @ApiProperty()
  @IsBoolean()
  @Column("boolean", {
    nullable: false,
    default: false
  })
  DAILY_BACKUP_ENABLED: boolean = false;
  
  @ApiProperty()
  @IsString()
  @Column("text")
  DAILY_BACKUP_EMAIL_RECEIPIENT: string = "";
}

export class BackupConfigDto extends OmitType(
  BackupConfig, ["DAILY_BACKUP_CRON_INTERVAL"] as const
) {}

export interface BackupEvent {
  isBackupEvent: true;
  wsToken: string;
  stage: "starting" | "database-dumping" | "zipping" | "uploading" | "completed"
  message?: string
}
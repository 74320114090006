import { useCallback, useEffect, useId } from "react";
import { useLocation, useNavigate } from "react-router-dom";


export const useScrollToId = (options?: {
  idPrefix?: string,
}) => {
  const idPrefix = useId();
  const { pathname, hash, key } = useLocation();
  const navigate = useNavigate();

  const scrollToId = useCallback((id: string | null) => {
    if (id) {
      const elementId = (options?.idPrefix ?? idPrefix) + id;
      const element = document.getElementById(elementId);
      if (element) {
        setTimeout(() => element.scrollIntoView({ behavior: "smooth" }), 0);
      }
    }

  }, [])

  return {scrollToId, idPrefix};
}
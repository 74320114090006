import { useCallback, useEffect, useMemo, useState } from "react";
import { APIInstance } from "../../../api"
import { User } from "../../../api/entities/user.entity";
import { AutocompleteSearch } from "../../../component/AutocompleteSearch";
import { useI18N } from "../../../component/I18NProvider"
import { useAbortableLoading } from "../../../utils/abortable-loading";
import { useLoadingManager } from "../../../utils/loading-manager";

export const UsersSearch = (props: {
  api: APIInstance,
  loading?: boolean,
  placeholder?: React.ReactNode,
  disabled?: boolean,
  excludeUsers?: User[],
  onSelect?: (user: User) => void,
}) => {
  const {api, excludeUsers} = props;
  const {t} = useI18N();
  const [users, setUsers] = useState<User[]>([]);
  const [inputValue, setInputValue] = useState("");
  const loading = useAbortableLoading();

  const searchUsers = useCallback(async() => {
    const {token, signal} = loading.start();
    try {
      setUsers((await api.users.findAll({
        page: 1,
        limit: 8,
        searchInput: inputValue
      }, {signal}))?.items)
    } catch (e) {

    }
    loading.stop(token);
  }, [inputValue])

  const usersFiltered = useMemo(() => {
    if (excludeUsers) {
      const excludeUserIds = excludeUsers.map(u => u.id);
      return users.filter(u => !excludeUserIds.includes(u.id))
    } else {
      return users;
    }
  }, [users, excludeUsers])

  useEffect(() => {
    searchUsers();
    return () => {
      loading.abort();
    }
  }, [inputValue])

  return (
    <AutocompleteSearch 
      input={inputValue}
      onChange={(input) => {
        setInputValue(input);
      }}
      placeholder={props.placeholder || t("admin.users.search")}
      loading={loading.flag || props.loading}
      options={usersFiltered.map((user) => ({
        label: <div>
          <span className="me-2">{user.username}</span>
          <span className="text-black-50">{user.email}</span>
        </div>,
        value: user.id,
        user
      }))}
      onSelect={({user}) => props.onSelect?.(user)}
      disabled={props.disabled}
    />
  )



  
}


export const ArrayRange = (from: number, to: number, step = 1) => {
  const array: number[] = [];

  if (from <= to) {
    for (let i = from; i <= to; i += step) {
      array.push(i);
    }
  } else {
    for (let i = from; i >= to; i -= Math.abs(step)) {
      array.push(i);
    }
  }
  return array;
}
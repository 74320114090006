import React, { CSSProperties, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Switch from "react-switch";
import "../../../styles/admin/entity-data.scss";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faInfoCircle, faRotateLeft, faRotateRight, faTrashCan, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { EntityTimestamp } from "./EntityTimestamp";
import ReactTooltip from '@huner2/react-tooltip';
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { GenericInput, GenericInputProps, GenericInputType, GenericInputTypes } from "./GenericInput";
import clsx from "clsx";
import { UnsavedPrompt } from "../../../component/UnsavedPrompt";
import { useI18N } from "../../../component/I18NProvider";
import { Section } from "../../../component/Section";
import { Spinner } from "react-bootstrap";
import { User, UserPreview } from "../../../api/entities/user.entity";
import { Flipped, Flipper } from "react-flip-toolkit";

type EntityItemDivWidthValue = null | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
interface EntityItemDivWidth {
  xs?: EntityItemDivWidthValue,
  sm?: EntityItemDivWidthValue,
  md?: EntityItemDivWidthValue,
  lg?: EntityItemDivWidthValue,
  xl?: EntityItemDivWidthValue,
  xxl?: EntityItemDivWidthValue,
};

export type EntityDataMetaValue = Omit<GenericInputProps, 'type'> & {
  title?: React.ReactNode
  titleInfo?: string
  warning?: string
  type: 
    GenericInputType
    | 'empty' | 'hr' | 'section'                                          // hr
    | 'textElement'
    | 'component' | 'entireComponent' | 'valueComponent'                      // Component
  placeholder?: string
  required?: boolean,
  requiredSign?: boolean,
  multiSelectSortable?: boolean,
  selectShowValue?: boolean,
  readonly?: boolean,
  hidden?: boolean,
  selectOptions?: {
    value: any,
    label: any
  }[]
  component?: React.ReactNode
  postfix?: React.ReactNode
  valueOverride?: any
  onChangeOverride?: (newValue: any) => void
  disabled?: boolean
  showComma?: boolean
  divWidth?: EntityItemDivWidthValue | EntityItemDivWidth,
  onClick?: () => void,
}

export type EntityDataMeta<T = any> = Record<keyof Partial<T> | string , EntityDataMetaValue>;

// (obj, "foo.bar") ==> obj.foo.bar
const nestedObjectKey = (obj: Object, key: string, newValue?: any) => {
  const keys = key.split(".");
  let _obj = obj;
  for (let i = 0; i < keys.length; i++) {
    if (i == keys.length - 1) {
      if (newValue !== undefined) {
        _obj[keys[i]] = newValue;
      }
    }

    _obj = _obj[keys[i]];
  }
  return _obj;
}

export interface EntityDataContextType<T extends Object = any> {
  object: T;
  loading: boolean;
  disabled: boolean;
  handleOnChange: (key: any, newValue: any) => void;
  handleOnSubmit: () => void;

}

export const EntityDataContext = React.createContext<EntityDataContextType>(null);




export const EntityDataItem = ({itemKey: key, meta}: {
  itemKey: string,
  meta: EntityDataMetaValue
}) => {
  const context = React.useContext(EntityDataContext);
  if (context == null) {
    throw "Use of EntityDataItem without EntityData";
  }

  let {title, titleInfo, warning, type, readonly, required, requiredSign, hidden} = meta;
  const inputLoading = meta.loading || context.loading;
  const metaForGenericInput: Omit<EntityDataMetaValue, 'type'> = {...meta};
  delete metaForGenericInput["type"];
  
  if (hidden) {
    return;
  }

  title = <span className={clsx("title", (required || requiredSign) && "required")}>{title}</span>;

  if (titleInfo) {
    title = (
      <React.Fragment key={key}>
        {title}
        <FontAwesomeIcon 
          icon={faInfoCircle} 
          color="darkgrey" 
          className="ms-2"
          style={{cursor: "pointer"}}
          data-tip={titleInfo}
          data-for={key + "-info-tooltip"}
        />
        <ReactTooltip
          id={key + "-info-tooltip"}
          place="top"
          effect="solid"
          delayHide={100}
          clickable
          className="text-center"
          html
        />
      </React.Fragment>
    )
  }

  if (warning) {
    title = (
      <React.Fragment key={key}>
        {title}
        <FontAwesomeIcon 
          icon={faTriangleExclamation} 
          color="darkgrey" 
          className="ms-2"
          style={{cursor: "pointer"}}
          data-tip={warning}
          data-for={key + "-warning-tooltip"}
        />
        <ReactTooltip
          id={key + "-warning-tooltip"}
          place="top"
          effect="solid"
          delayHide={100}
          clickable
          className="text-center"
          html
        />
      </React.Fragment>
    )
  }
  
  const value = (meta.valueOverride !== undefined) ? meta.valueOverride 
    : (context.object && nestedObjectKey(context.object, key));

  if (type == "hr") {
    return (
      <div key={key} className="hr" />
    )
  }

  if (type == "empty") {
    return (
      <div key={key} className="my-3" />
    )
  }


  if (type == "section") {
    return (
      <Section key={key} className="section" onClick={meta.onClick}>{title}</Section>
    )
    
  }
  
  if (type == 'component') {
    return <div className="entity-data-item">{meta.component}</div>;
  }

  if (type == 'entireComponent') {
    return <>{meta.component}</>;
  }

  const divWidth: EntityItemDivWidth = meta.divWidth && (typeof meta.divWidth == "number" ? {xs: meta.divWidth} : meta.divWidth);

  return (
    <Flipped key={meta.id || key} flipId={meta.id || key}>
      <div key={key} className={clsx(
        "entity-data-item",
        divWidth?.xs && `div-width-${divWidth?.xs}`,
        divWidth?.sm && `div-width-${divWidth?.sm}-sm`,
        divWidth?.md && `div-width-${divWidth?.md}-md`,
        divWidth?.lg && `div-width-${divWidth?.lg}-lg`,
        divWidth?.xl && `div-width-${divWidth?.xl}-xl`,
        divWidth?.xxl && `div-width-${divWidth?.xxl}-xxl`,
      )}>
        <div className="entity-data-item-key">
          {title}
        </div>
        
        <div className="entity-data-item-value">
          {
            GenericInputTypes.includes(type as any) &&
            <GenericInput
              type={type as GenericInputType}
              {...metaForGenericInput}
              placeholder={meta.placeholder || (typeof meta.title == "string" && meta.title)}
              value={value}
              multiSelectSortable={meta.multiSelectSortable}
              selectShowValue={meta.selectShowValue}
              required={required}
              readonly={readonly}
              loading={inputLoading}
              disabled={context.disabled || meta.disabled}
              onChange={(newValue) => {
                context.handleOnChange?.(key, newValue);
              }}
              selectOptions={meta.selectOptions}
            />
          }
          {
            type == "textElement" && <>{value}</>
          }
          {
            ['valueComponent'].includes(type) &&
            meta.component
          }
          {
            meta.postfix
          }
        </div>

      </div>
    </Flipped>
  )
}
export const EntityData = <T extends {[key: string]: any}>(props: {
  object?: T | null;
  className?: string;
  style?: CSSProperties;
  loading?: boolean;
  disabled?: boolean;
  meta: EntityDataMeta<T>;
  isNew?: boolean;
  unsaved?: boolean;
  noUnsavedPrompt?: boolean;
  timestamp?: {
    created: Date;
    updated: Date;
    createdBy?: string | UserPreview;
    updatedBy?: string | UserPreview;
  } | false;
  useForm?: boolean;
  onChange?: (newObject: T) => void;
  onSubmit?: () => void;
  onSave?: () => void;
  showDeleteOnCtrl?: boolean;
  onDelete?: () => void;
  floatingSaveBtn?: boolean;
  undoRedo?: {
    canUndo: boolean;
    onUndo: () => void;
    canRedo: boolean;
    onRedo: () => void;
  }
  noMarginCompensate?: boolean;
  tight?: boolean;
  width?: "full" | "wide" | "thin",
  flippable?: boolean;
}) => {

  const { loading, unsaved, disabled, timestamp, undoRedo, useForm } = props;
  const { t } = useI18N();

  const initLoading = useMemo(() => !props.object && props.loading, [props.object, props.loading]);

  const form = useRef<HTMLDivElement>(null);

  const saveBtnAreaRef = useRef<HTMLInputElement>(null);
  const floatingSaveBtnAreaRef = useRef<HTMLDivElement>(null);
  const [hideFloatingSaveBtnArea, setHideFloatingSaveBtnArea] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleOnSubmitRef = useRef<() => void>(null);
  const canSaveRef = useRef<boolean>(false);
  const [floatingSaveBtnAreaBottom, setFloatingSaveBtnAreaBottom] = useState(0);

  const undoRedoElement = useMemo(() => undoRedo && (<div className="me-2">
    <ButtonWithLoader className="me-2" variant="info" disabled={!undoRedo.canUndo} faIcon={faRotateLeft} onClick={() => undoRedo?.onUndo()} />
    <ButtonWithLoader variant="info" disabled={!undoRedo.canRedo} faIcon={faRotateRight} onClick={() => undoRedo?.onRedo()} />
  </div>), [undoRedo])
  
  useEffect(() => {
    if (props.floatingSaveBtn) {
      const onScroll = () => {
        // console.log(window.scrollY);
        if (saveBtnAreaRef.current && floatingSaveBtnAreaRef.current) {
          const saveBtnAreaFromBottom = saveBtnAreaRef.current.getBoundingClientRect().bottom - window.innerHeight;
          // console.log(saveBtnAreaFromBottom)
          const hideFloatSaveBtnArea = saveBtnAreaFromBottom <= 10 || !(unsaved || undoRedo?.canUndo || undoRedo?.canRedo);
          setHideFloatingSaveBtnArea(hideFloatSaveBtnArea);
          setFloatingSaveBtnAreaBottom(hideFloatSaveBtnArea ? -floatingSaveBtnAreaRef.current.clientHeight : 0);
        }
      }
      if (saveBtnAreaRef.current && floatingSaveBtnAreaRef.current) {
        window.addEventListener("scroll", onScroll)
        window.addEventListener("resize", onScroll)
      }
  
      onScroll();
  
      return () => {
        window.removeEventListener("scroll", onScroll);
        window.addEventListener("resize", onScroll)
      }
    }
  }, [saveBtnAreaRef.current, floatingSaveBtnAreaRef.current, unsaved, props.floatingSaveBtn])

  const [ctrlActive, setCtrlActive] = useState(false);

  useEffect(() => {
    const keyDown = (event: KeyboardEvent) => {
      setCtrlActive(event.ctrlKey || event.metaKey);
    }
    const keyUp = (event: KeyboardEvent) => {
      setCtrlActive(event.ctrlKey || event.metaKey);
    }

    window.addEventListener("keydown", keyDown);
    window.addEventListener("keyup", keyUp);
    return () => {
      window.removeEventListener("keydown", keyDown)
      window.removeEventListener("keyup", keyUp)
    }
  }, [])

  // useEffect(() => {
  //   const pressEnterEvent = (event: any) => {
  //     // Ref
  //     if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
  //       // let form = event.target.form;
  //       // let index = Array.prototype.indexOf.call(formform, event.target);
  //       // form.elements[index + 1]?.focus();
  //       let index = Array.prototype.indexOf.call(form.current, event.target);
  //       // form.current.children
  //       console.log(index);
  //       event.preventDefault();
  //     }
  //   }

  //   document.addEventListener("keydown", pressEnterEvent);

  //   return () => {
  //     document.removeEventListener("keydown", pressEnterEvent);
  //   }
  // }, [])
  
  const cloneObject = () => {
    if (props.object) {
      return {...props.object}
    } else {
      return null;
    }
  }

  const handleOnChange = (key, newValue) => {
    if (props.object) {
      if (!props.meta[key].onChangeOverride) {
        const newObject = cloneObject() as any;
        // newObject[key] = newValue;
        nestedObjectKey(newObject, key, newValue)
    
        props.onChange?.(newObject);
      } else {
        props.meta[key].onChangeOverride?.(newValue);
      }

      props.meta[key].onChange?.(newValue);
    }
  }

  useEffect(() => {
    canSaveRef.current = !loading && unsaved;
  }, [loading, unsaved]);

  useEffect(() => {
    handleOnSubmitRef.current = () => {
      if (canSaveRef.current) {
        props.onSubmit?.();
        props.onSave?.();
      }
    }
  }, [props.onSubmit, props.onSave]);


  useEffect(() => {
    if (containerRef.current) {
      const onKeyDown = (event: KeyboardEvent) => {

        const isMacOS = navigator.platform?.toLowerCase().startsWith("mac");

        if (!isMacOS && event.ctrlKey || isMacOS && event.metaKey) {
          if (event.key === 's') {
            console.log("Keyboard: control/cmd-s detected")
            event.preventDefault();

            handleOnSubmitRef.current?.();
          }
        }
        
      }

      containerRef.current.addEventListener("keydown", onKeyDown)

      return () => {
        containerRef.current?.removeEventListener("keydown", onKeyDown)
      }
    }
  }, [containerRef.current])

  if (initLoading) {
    return <div className="entity-data-loading">
        <Spinner animation="border" style={{width: "4rem", height: "4rem"}}/>
    </div>  
  }
  const children = props.object && (
    <>
      {
        (!props.noUnsavedPrompt) && <UnsavedPrompt flag={props.unsaved || false} />
      }

      {
        Object.entries(props.meta).map(([key, meta]) => (
          
            <EntityDataItem key={key} itemKey={key} meta={meta} />
          
        ))
      }

      {
        (timestamp || undoRedo || props.onSave || props.onDelete) && (
          <div className="d-flex align-items-center justify-content-end" ref={saveBtnAreaRef}>
            <div className="mx-3">
              {
                !loading && (
                  timestamp && (
                    <>
                      <EntityTimestamp
                        className="mx-2"
                        showText
                        {...timestamp}
                      />
                    </>
                  
                  )
                )
              }
            </div>
            {undoRedoElement}
            {
              props.onSave && (
                <ButtonWithLoader 
                  faIcon={faFloppyDisk}
                  variant="primary" 
                  loading={loading}
                  disabled={!props.unsaved}
                  type="submit"
                  onClick={() => {
                    !useForm && handleOnSubmitRef.current?.();
                  }}
                >
                  {props.isNew ? t('entityData.create') : t('entityData.save')}
                </ButtonWithLoader>
              )
            }
            {
              props.onDelete && (!props.showDeleteOnCtrl || ctrlActive) && (
                <button 
                  type="button" 
                  className="btn btn-danger px-3 mx-1 text-nowrap"
                  onClick={() => {
                    props.onDelete?.();
                  }}
                  disabled={loading}
                >
                  <FontAwesomeIcon icon={faTrashCan} fixedWidth className="me-1" />
                  {t('entityData.delete')}
                </button>
              )
            }
          </div>
        )
      }
      {
        props.onSave && props.floatingSaveBtn && (
          <div className={clsx("floating-save-btn-area", hideFloatingSaveBtnArea && "hidden")} ref={floatingSaveBtnAreaRef}
            style={{
              bottom: floatingSaveBtnAreaBottom
            }}
          >
            {undoRedoElement}
            <ButtonWithLoader 
              faIcon={faFloppyDisk}
              variant="primary" 
              loading={loading}
              disabled={!props.unsaved}
              type="submit"
              onClick={() => {
                !useForm && handleOnSubmitRef.current?.();
              }}
            >
              {props.isNew ? t('entityData.create') : t('entityData.save')}
            </ButtonWithLoader>
          </div>
        )
      }
    </>
  )

  // const containerFlipperRef = useRef<Flipper>(null);

  const className = clsx(
    "entity-data", 
    props.noMarginCompensate && "no-margin-compensate", 
    props.tight && "tight", 
    props.width,
    props.className
  );
  // console.log(props.flippable && Object.values(props.meta).map(m => m.id)?.join(""));
  return (
    <EntityDataContext.Provider
      value={{
        object: props.object, loading, disabled, handleOnChange, handleOnSubmit: handleOnSubmitRef.current
      }}
    >
      <div ref={containerRef} style={props.style}>
        <Flipper flipKey={props.flippable && Object.values(props.meta).map(m => m.id)?.join("")}
          className={className}
          // id="entity-data-form"
          // ref={form}
        >
          {
            useForm ? <form className={className} onSubmit={event => {
                event.preventDefault();
                handleOnSubmitRef.current?.();
              }}
            >
              {children}
            </form> : children
          }
        </Flipper>
      </div>

    </EntityDataContext.Provider>
 
  )
}

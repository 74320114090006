import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGlobalModal } from "./GlobalModal";
import { useGlobalSystemConfig } from "./GlobalSystemConfig";
import "../styles/login-area.scss";
import { useLoadingManager } from "../utils/loading-manager";
import { APIInstance, APIInstanceWithAuth, APIInstanceWithAuthBase } from "../api";
import { faUser, faKey, faUserCircle, faArrowRightFromBracket, faGaugeHigh, faGear, faPenRuler, faRightToBracket, faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthLoginDto, User } from "../api/entities/user.entity";
import { FacebookAuthButton } from "./FacebookAuthButton";
import { GoogleAuthButton } from "./GoogleAuthButton";
import { ButtonWithLoader } from "./ButtonWithLoader";
import { Link } from "react-router-dom";
import { useI18N } from "./I18NProvider";
import { GoogleRecaptchaV2 } from "./GoogleRecaptchaV2";
import { Modal } from "react-bootstrap";
import { faCircleQuestion as faCircleQuestionRegular } from "@fortawesome/free-regular-svg-icons";
import * as path from "path-browserify";
import { useDetectAutofill } from "../utils/use-detect-autofill";

export const ForgetPasswordModal = (props: {
  api: APIInstance;
  open: boolean,
  onClose?: () => void,
  defaultIdentifier?: string,
}) => {
  const {api, open, onClose} = props;
  const {t, currentI18n, currentLang} = useI18N();
  const [identifier, setIdentifier] = useState("");
  const loading = useLoadingManager();
  const {globalSystemConfig} = useGlobalSystemConfig();
  const modal = useGlobalModal();
  const googleRecaptchaV2ExecuteRef = useRef<() => Promise<string>>(null);
  const googleRecaptchaV2ResetRef = useRef<() => void>(null);
  const googleRecaptchaV2OnloadToken = useRef<number>(null);


  useEffect(() => {
    if (googleRecaptchaV2OnloadToken.current == null) {
      googleRecaptchaV2OnloadToken.current = loading.start();
    }
  }, [])

  useEffect(() => {
    if (open) {
      setIdentifier(props.defaultIdentifier);
    }
  }, [open]);
  
  return (
    <Modal
      show={open}
      centered
      onHide={() => onClose?.()}
    >
      <Modal.Header closeButton>
        <FontAwesomeIcon icon={faCircleQuestion} className="me-2" /> {t("login.forgetPassword.title")}
      </Modal.Header>
      <Modal.Body>
      {
        <form
          onSubmit={async(event) => {
            event.preventDefault();
              
            let googleRecaptchaToken: string | null;

            const token = loading.start();
            const link = window.location.origin + window.location.pathname;
            if (globalSystemConfig.GOOGLE_RECAPTCHA_ENABLED) {
              try {
                googleRecaptchaToken = await googleRecaptchaV2ExecuteRef.current?.();
              } catch (e) {

              }
            } 

            try {
              const result = await api.auth.sendResetPasswordEmail({
                usernameOrEmail: identifier,
                link,
                lang: currentLang,
                googleRecaptchaToken
              })
              modal.success(
                t("login.forgetPassword.title"), <>
                  <p>{t('login.forgetPassword.emailMessage1')}
                  <b>{result?.maskedEmail}</b>
                  </p>
                  <p>{t('login.forgetPassword.emailMessage2')}</p>
                </>
              );
              onClose?.();
            } catch (e) {
              modal.errorSpecific(e);
              googleRecaptchaV2ResetRef.current?.();
            }
            loading.stop(token);
          }}
        >
          <div className="col-12 form-group">
            <div className="input-group">
              <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faUser} /></span>
              <input type="text" className="form-control" 
                placeholder={t('login.usernameOrEmail')}
                autoComplete="username"
                autoCapitalize="off"
                readOnly={loading.flag}
                value={identifier}
                onChange={(event) => {
                  setIdentifier(event.target.value)
                }}
              />
            </div>
          </div>

          {
            globalSystemConfig.GOOGLE_RECAPTCHA_ENABLED && (
              <GoogleRecaptchaV2 
                className="mt-2"
                type="checkbox"
                siteKey={globalSystemConfig.GOOGLE_RECAPTCHA_V2_CHECKBOX_SITE_KEY}
                languageCode={currentI18n.isoId}
                onload={() => {
                  loading.stop(googleRecaptchaV2OnloadToken.current);
                }}
                executeRef={(execute) => {
                  googleRecaptchaV2ExecuteRef.current = execute;
                }}
                resetRef={(reset) => {
                  googleRecaptchaV2ResetRef.current = reset;
                }}
              />
            )

          }

          <div className="col-12 mt-2" style={{gap: "0.5rem 0"}}>
            <ButtonWithLoader faIcon={faRightToBracket} loading={loading.flag} type="submit" className="w-100">
              {t("login.forgetPassword.continue")}
            </ButtonWithLoader>
          </div>

        </form>
      }
      </Modal.Body>
    </Modal>
  )
}



export const LoginArea = (props: {
  api: APIInstanceWithAuthBase,
  forAdmin?: boolean,
  user?: User | null,
  isLoading?: boolean,
  onSuccess?: () => void,
  onError?: (e: any) => void,
  onLogout?: () => void,
  logo?: React.ReactNode,
  title?: React.ReactNode,
  settingsHref?: string,
  adminHref?: string
}) => {
  const {api, forAdmin, onSuccess, onError, onLogout, user} = props;
  const {t, currentI18n} = useI18N();
  const {globalSystemConfig} = useGlobalSystemConfig();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  
  const loading = useLoadingManager();
  const isLoading = useMemo(
    () => (props.isLoading || loading.flag),
    [props.isLoading, loading.flag]
  )

  const [forgetPasswordOpen, setForgetPasswordOpen] = useState(false);

  const googleRecaptchaV2ExecuteRef = useRef<() => Promise<string>>(null);
  const googleRecaptchaV2ResetRef = useRef<() => void>(null);
  const googleRecaptchaV2OnloadToken = useRef<number>(null);

  const onSubmit = useCallback(async(e?: FormEvent) => {
    e?.preventDefault();

    if (loading.flag) {
      return;
    }


    let googleRecaptchaToken: string | null;
    if (globalSystemConfig.GOOGLE_RECAPTCHA_ENABLED) {
      passwordRef.current.blur();
      usernameRef.current.blur();

      setTimeout(() => {
        passwordRef.current.blur();
        usernameRef.current.blur();
      }, 500)
      
      try {
        googleRecaptchaToken = await googleRecaptchaV2ExecuteRef.current?.();
        // console.log(googleRecaptchaToken);
      } catch (e) {
        // console.log("TEST");
        console.warn(e);
        // props.onError?.(e);
      }
    }

    let dto: AuthLoginDto = {
      username: usernameRef.current.value, password: passwordRef.current.value, isAdmin: !!props.forAdmin, googleRecaptchaToken
    };
    
    let token = loading.start();

    try {
      let result = await api.auth.login(dto);
      console.debug(result);
      api.setAccessToken(result.access_token);
      api.setRefreshToken(result.refresh_token);
      onSuccess?.();
    } catch (e) {
      onError?.(e);
      loading.stop(token);
      googleRecaptchaV2ResetRef.current?.();
    } 

  }, [globalSystemConfig, props.forAdmin])

  const {usernameRef, passwordRef} = useDetectAutofill({
    onAutofill: () => onSubmit(),
    delayOnload: 1000,
  });

  useEffect(() => {
    if (usernameRef.current) {
      usernameRef.current.focus();
    }
  }, [usernameRef.current]);
  
  useEffect(() => {
    if (globalSystemConfig.GOOGLE_RECAPTCHA_ENABLED && googleRecaptchaV2OnloadToken.current == null) {
      googleRecaptchaV2OnloadToken.current = loading.start();
    }
  }, [])

  return !user ? (
    <>
      <form className="login-area"
        onClick={e => {e.stopPropagation()}}
        onSubmit={onSubmit}
      >
        
        <div className="mb-1 title-area">
          {
            props.logo && (
              <div className="logo text-center ">
                {props.logo}
              </div>
            )
          }

          {
            props.title && (
              <div className="title text-center ">
                {props.title}
              </div>
            )
          }
        </div>

        

        <div className="col-12 form-group">
          <div className="input-group">
            <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faUser} /></span>
            <input type="text" className="form-control" 
              ref={usernameRef}
              placeholder={t('login.usernameOrEmail')}
              autoComplete="username"
              autoCapitalize="off"
              readOnly={isLoading}
              value={username}
              disabled={loading.flag}
              onChange={(event) => {
                setUsername(event.target.value)
              }}
            />
          </div>
        </div>
        <div className="col-12 form-group">
          <div className="input-group">
          <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faKey} /></span>
            <input type="password" className="form-control"
              ref={passwordRef}
              placeholder={t('login.password')}
              autoComplete="current-password"
              readOnly={isLoading}
              value={password}
              disabled={loading.flag}
              onChange={(event) => {
                setPassword(event.target.value)
              }}
            />
          </div>
        </div>

        {
          globalSystemConfig.GOOGLE_RECAPTCHA_ENABLED && (
            <GoogleRecaptchaV2 
              type="invisible"
              siteKey={globalSystemConfig.GOOGLE_RECAPTCHA_V2_INVISIBLE_SITE_KEY}
              languageCode={currentI18n.isoId}
              onload={() => {
                // console.log("GoogleRecaptchaV2 loaded");
                // console.log(googleRecaptchaV2OnloadToken.current);
                loading.stop(googleRecaptchaV2OnloadToken.current);
              }}
              executeRef={(execute) => {
                googleRecaptchaV2ExecuteRef.current = execute;
              }}
              resetRef={(reset) => {
                googleRecaptchaV2ResetRef.current = reset;
              }}
            />
          )

        }

        <div className="col-12 mt-2">
          <ButtonWithLoader faIcon={faRightToBracket} loading={isLoading} type="submit" className="w-100">
            {t("login.login")}
          </ButtonWithLoader>
        </div>


        {
          globalSystemConfig.GOOGLE_API_ENABLED && (
            <div className="col-12">
              <GoogleAuthButton
                clientId={globalSystemConfig.GOOGLE_API_CLIENT_ID}
                handleResponse={async({credential}) => {
                  let token = loading.start();
                  try {
                    let result = await api.auth.googleLogin(credential);
                    console.debug(result);
                    await api.setAccessToken(result.access_token);
                    await api.setRefreshToken(result.refresh_token);
                    onSuccess?.();
                  } catch (e) {
                    onError?.(e);
                    loading.stop(token);
                  }
                }}
              />
            </div>
          )
        }

        
        {
          globalSystemConfig.FACEBOOK_API_ENABLED && (
            <div className="col-12">
              <FacebookAuthButton 
                title={t('login.loginWithFacebook')}
                fluid
                clientId={globalSystemConfig.FACEBOOK_API_CLIENT_ID}
                version={globalSystemConfig.FACEBOOK_API_VERSION}
                handleResponse={(response) => {
                  if (response.authResponse?.accessToken) {
                    let token = loading.start();
                    // console.log(response.authResponse?.accessToken);
                    api.auth.facebookLogin(response.authResponse.accessToken).then(
                      (result) => {
                        console.debug(result);
                        api.setAccessToken(result.access_token);
                        api.setRefreshToken(result.refresh_token);
                        onSuccess?.();
                      }
                    ).catch((e) => {
                      onError?.(e);
                      loading.stop(token);
                    });
                  } else {
                    onError?.(new Error("No data is received from Facebook"));
                  }
                }}
              />
            </div>
          )
        }
        
        <div className="col-12">
          <ButtonWithLoader faIcon={faCircleQuestionRegular} disabled={isLoading} type="button" variant="secondary" className="w-100"
            onClick={() => {
              setForgetPasswordOpen(true);
            }}
          >
            {t("login.forgetPassword.title")}
          </ButtonWithLoader>
        </div>
        
      </form>
      <ForgetPasswordModal
        api={api}
        open={forgetPasswordOpen}
        defaultIdentifier={username}
        onClose={() => setForgetPasswordOpen(false)}
      />
    </>
  ) : (
    <div className="login-area">
      <div className="title-area">
        <div className="user">
          <FontAwesomeIcon icon={faUserCircle} className="icon" fixedWidth size="lg"/>
          {user.username || user.email}
        </div>
      </div>
      <div className="button-area">

        <Link to={props.settingsHref}>
          <ButtonWithLoader
            faIcon={faGear}
            variant="outline-primary"
          >
            {t("login.settings")}
          </ButtonWithLoader>
        </Link>
        {
          user?.isAdmin && (
          <Link to={props.adminHref} target="_blank">
            <ButtonWithLoader
              faIcon={faGaugeHigh}
              variant="outline-primary"
            >
              {t('admin.title')}
            </ButtonWithLoader>
          </Link>
          )
        }
        
      </div>

      <div className="hr" />
      <div className="button-area">
        <ButtonWithLoader
          faIcon={faArrowRightFromBracket}
          variant="primary"
          loading={isLoading}
          onClick={async(e) => {
            e.stopPropagation();
            let token = loading.start();
            try {
              await api.auth.logout({
                refresh_token: api.getRefreshToken(),
                access_token: api.getAccessToken()
              })
              api.setAccessToken(null);
              api.setRefreshToken(null);

              if (props.onLogout) {
                props.onLogout();
              } else {
                window.location.reload();
              }
            } catch (e) {
              loading.stop(token);
            }
            
          }}
        >
          {t("login.logout")}
        </ButtonWithLoader>
      </div>
      
    </div>
  )
}
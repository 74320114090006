
import { useEffect, useMemo, useState } from "react";
import { FilePreviewByFileTypeProps, useFileViewer } from "../FileViewer";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/ext-language_tools";

export const FileViewerJson = (props: FilePreviewByFileTypeProps) => {
  const {src, axios, onFileLoad, onFileError, ...othersProps} = props;
  const [loading, setLoading] = useState(true);
  const {getFullSrc} = useFileViewer();
  let fullSrc = useMemo(() => {
    return getFullSrc(src);
  }, [src])

  const [rawData, setRawData] = useState("");

  useEffect(() => {
    setLoading(true);
    props.axios.get(fullSrc, {transformResponse: []}).then((response) => {
      console.log(response.data);
      setRawData(response.data);
      props.onFileLoad();
    })
    .catch(() => props.onFileError("Error while downloading this file"))
    .finally(() => {props.onFileLoad(); setLoading(false)})
  }, [src])


  return (
    <AceEditor
      width="100%"
      placeholder=""
      mode="json"
      theme="solarized_dark"
      name="blah2"
      // onLoad={this.onLoad}
      // onChange={this.onChange}
      readOnly
      fontSize={14}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={true}
      value={rawData}
      setOptions={{
      enableBasicAutocompletion: false,
      enableLiveAutocompletion: false,
      enableSnippets: false,
      showLineNumbers: true,
      tabSize: 2,
      }}/>
            
  )
}

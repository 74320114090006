import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Helmet } from "react-helmet-async";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import clsx from 'clsx';

export interface FacebookAuthResponse {
  status: "connected" | string,
  authResponse: {
    accessToken: string,
    data_access_expiration_time: number,
    expiresIn: number,
    grantedScopes?: string,
    graphDomain: string,
    signedRequest: string
  }
};

export class FacebookAuthButton extends React.Component<{
  title?: string,
  version: string,  // e.g., "v13.0"
  clientId: string,
  scopes?: string[],
  fluid?: boolean,
  handleResponse: (reponse: FacebookAuthResponse) => void | Promise<void>
  onError?: (e) => void
}> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let self = this;
    let w = window as any;
    w.fbAsyncInit = function () {
      w.FB.init({
        appId: self.props.clientId,
        status: false,    // Disable auto login call
        cookie: true,
        xfbml: true,
        version: self.props.version,
      });

      w.FB.AppEvents.logPageView();   

      // auto authenticate with the api if already logged in with facebook
      // w.FB.getLoginStatus(({ authResponse }) => {
      //   console.log("TEST");
      //   if (authResponse) {
      //       // accountService.apiAuthenticate(authResponse.accessToken).then(resolve);
      //       console.log(authResponse.accessToken)
      //   } else {
      //       // resolve();
      //   }
      // });

    };


    // load facebook sdk script
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  facebookLogin() {

    try {
      
      let w = window as any;
      w.FB.login((response) => {
        console.log(response);
        this.props.handleResponse(response);
        
        // if (response.authResponse?.accessToken) {
        //   console.log(response.authResponse?.accessToken);
        //   this.api.auth.facebookLogin(response.authResponse.accessToken).then(
        //     (result) => {
        //       console.debug(result);
        //       this.api.setAccessToken(result.access_token);
        //       this.api.setRefreshToken(result.refresh_token);
        //       window.location.reload();
        //     }
        //   ).catch((e) => {
        //     console.error(e);
        //     this.alertModal.errorSpecific(e);
        //     this.setLoading(false);
        //   });
        // }
      }, {
        scope: this.props.scopes?.join(','), 
        return_scopes: true
      })
    } catch (e) {
      this.props.onError && this.props.onError(e);
    }


  }

  render() {
    return (
      <>
        <button
          type="button"
          className={clsx("btn social-button", this.props.fluid && "w-100")}
          style={{
            backgroundColor: "#1877f2",
            color: "white",
          }}
          onClick={() => {
            this.facebookLogin();
          }}
        >
          <FontAwesomeIcon icon={faFacebook} size="lg" className="me-2" />
          <span>{this.props.title || "Log in with Facebook"}</span>
        </button>

      </>
    )
  }
}
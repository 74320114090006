import { Exclude } from "class-transformer";
import { IsBoolean, IsEmail, IsEnum, IsNotEmpty, IsOptional, IsString, ValidateIf } from "class-validator";
import { HidePublic } from "./utils/hide-public";
import { TimestampEntity } from "./timestamp.entity";
import { User } from "./user.entity";
import { ApiProperty, Column, Entity, JoinColumn, OmitType, OneToOne, PrimaryColumn } from "./vars";

export const UserProfileGenders = [
  'male', 'female'
] as const;

export type UserProfileGender = (typeof UserProfileGenders)[number];

@Entity()
export class UserProfile {
  
  constructor(partials?: Partial<UserProfile>) {
    Object.assign(this, partials);
  }
  
  @HidePublic()
  @ApiProperty()
  @Exclude()
  @PrimaryColumn() 
  userId: number;
  
  @ApiProperty({type: () => User})
  @OneToOne(() => User, user => user.profile, {
    onUpdate: "CASCADE",
    onDelete: "CASCADE",
  })
  @JoinColumn({name: "userId"})
  user?: User;

  @HidePublic()
  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 64, 
    nullable: false,
    default: "",
  })
  firstNameEn: string;

  @HidePublic()
  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 64, 
    nullable: false,
    default: "",
  })
  lastNameEn: string;

  @HidePublic()
  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 64, 
    nullable: false,
    default: "",
  })
  firstNameTc: string;

  @HidePublic()
  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 64, 
    nullable: false,
    default: "",
  })
  lastNameTc: string;
  
  @ApiProperty({
    enum: UserProfileGenders
  })
  @IsEnum(UserProfileGenders)
  @IsOptional()
  @Column("enum", {
    nullable: true,
    enum: UserProfileGenders,
  })
  gender: UserProfileGender | null;

  @HidePublic()
  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 64, 
    nullable: false,
    default: "",
  })
  contactNumber: string;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 64, 
    nullable: false,
    default: "",
  })
  publicNameTc: string;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 64, 
    nullable: false,
    default: "",
  })
  publicNameSc: string;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 128, 
    nullable: false,
    default: "",
  })
  publicNameEn: string;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 64, 
    nullable: false,
    default: "",
  })
  publicContactNumber1: string;

  @ApiProperty()
  @IsBoolean()
  @Column("boolean", {
    nullable: false,
    default: false,
  })
  publicContactNumber1IsWhatsapp: boolean;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 64, 
    nullable: false,
    default: "",
  })
  publicContactNumber2: string;

  @ApiProperty()
  @IsBoolean()
  @Column("boolean", {
    nullable: false,
    default: false,
  })
  publicContactNumber2IsWhatsapp: boolean;


  @ApiProperty()
  @ValidateIf(obj => obj.publicContactEmail !== "")
  @IsString()
  @IsEmail()
  @Column("varchar", {
    length: 320, 
    nullable: false,
    default: "",
  })
  publicContactEmail: string;

  @HidePublic()
  @ApiProperty()
  @Column(() => TimestampEntity)
  timestamp: TimestampEntity
  
  static getNameEn(profile: UserProfile) {
    return [profile.firstNameEn, profile.lastNameEn].filter(str => str && str.trim() != "").join(" ");
  }

  static getNameTc(profile: UserProfile) {
    return [profile.lastNameTc, profile.firstNameTc].filter(str => str && str.trim() != "").join("");
  }
}


export class UserProfileDto extends OmitType(UserProfile, 
  ['user', 'userId', 'timestamp']
 ) {
 }
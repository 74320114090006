import { faSearchengin } from "@fortawesome/free-brands-svg-icons";
import { faPlus, faArrowsRotate, faBan, faBuildingUser, faBuilding, faCity, faArrowUpRightFromSquare, faXmark, faSearch, faFilterCircleXmark, faHouseChimneyUser, faMapLocationDot, faCaretDown, faHashtag, faDollarSign, faRuler, faPenRuler, faChartGantt, faLanguage, faGear, faEyeSlash, faEye, faPhoneSquare, faAddressCard, faClockRotateLeft, faTags, faTextWidth, faGears, faLock, faLockOpen, faImage, faImagePortrait, faFileExport, faCircleMinus, faCirclePlus, faCircleInfo, faThumbTack, faUserPen, faExclamationTriangle, faTrash, faRoad, faBookmark, faFlag, faStar, faPhoneSlash, faLink, faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import moment from "moment";
import React, { useState, useTransition, useMemo, useCallback, useEffect, useRef, useDeferredValue } from "react";
import { Badge, Button, Dropdown, Form, InputGroup, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ReactSelect from "react-select";
import { Building, BuildingStreetName, BuildingTypes } from "../../../api/entities/building.entity";
import { District } from "../../../api/entities/district.entity";
import { createEntityFilter, EntityFilter, EntityFilterFieldType, EntityFilterNull, entityFilterValuesSet, EntitySort, FindAllQuery, getEntityFilterValueArray } from "../../../api/entities/findAllQuery";
import { Paginated } from "../../../api/entities/pagination";
import { Property, PropertyEditHistory, PropertyEditHistoryFindAllQuery, PropertyListAvailableKey, PropertyListAvailableKeys, PropertyListKeysPresetKey, PropertyListKeysPresetKeys, PropertyListKeysPresets, PropertyListPdfContent, PropertyListStarKeys, PropertyListWholeRowKeys, PropertySort } from "../../../api/entities/property/property.entity";
import { AutocompleteSearch } from "../../../component/AutocompleteSearch";
import { ButtonWithLoader, LinkWithLoader } from "../../../component/ButtonWithLoader";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider";
import { UnsavedPrompt } from "../../../component/UnsavedPrompt";
import { useAbortable, useAbortableLoading } from "../../../utils/abortable-loading";
import { Lang, LangMap } from "../../../utils/lang-map";
import { useScrollToId } from "../../../utils/scroll-to-id";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";
import { DataList, DataListItem } from "../component/DataList";
import { EntityTimestamp } from "../component/EntityTimestamp";
import { BadgeCancellable, MiniFilterButton, MiniSearchButton, useFindAllQuery } from "../component/FindAllQuery";
import { commify, GenericInput } from "../component/GenericInput";
import { AdminPagination } from "../component/Pagination";
import { AdminTable } from "../component/Table";
import { AdminBuildingPath, AdminPropertyPath, AdminPropertyPublishmentPath } from "../variables";
import { AdminBuildingI18NPrefix, BuildingNameFromIdSync, StreetPreview } from "./Building";
import { searchDistricts } from "./Districts";
import { AdminPropertyComponent, AdminPropertyI18NPrefix, AdminPropertyStar, LandlordContactPreview, LandlordContactsPreview, PropertyBasicInfoCarparksPreview, PropertyBasicInfoPreview, PropertyPriceStatusPreview } from "./Property";
import * as humanFormat from "human-format";
import { PropertyStatuses } from "../../../api/entities/property/property-price-status.entity";
import { PropertyLandlord, PropertyLandlordContactCount } from "../../../api/entities/property/property-landlord.entity";
import { faFilePdf, faSquareMinus, faSquarePlus, faBookmark as farBookmark, faStar as faStarEmpty, faCircleUp, faCircleDown } from "@fortawesome/free-regular-svg-icons";
import ReactTooltip from "@huner2/react-tooltip";
import { HighComputationResult } from "../../../api/entities/high-computation-result";
import { roundToDp } from "../../../utils/round-to-dp";
import { Collapsible, CollapsibleButton } from "../../../component/Collapsible";
import FormRange from "react-bootstrap/esm/FormRange";
import { LocalCache } from "../../../api/src/local-cache";
import { EntityData } from "../component/EntityData";
import { numberInShort } from "../../../utils/number-in-short";
import { downloadFromUrl } from "../../../utils/download-from-url";
import { PdfFonts } from "../../../api/entities/pdf-util.entity";
import { createNumberArray } from "../../../utils/create-number-array";
import _, { keyBy } from "lodash";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, DateRange, RangeKeyDict, Range } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import "../../../styles/admin/date-range.scss";
import { UsersSearch } from "../component/UsersSearch";
import { User, userHasAdminRoles } from "../../../api/entities/user.entity";
import {distance} from "fastest-levenshtein";
import stringSimilarity from "string-similarity";
import { PropertySearchHistory } from "../../../api/entities/property/property-search-history.entity";
import { PublishmentStateDisplay } from "./PropertyPublishment";
import { PropertyPublishmentStates } from "../../../api/entities/property/property-publishment.entity";
import { PropertyReadHistoryUserResults } from "../../../api/entities/property/property-read-history.entity";
import { useDebounceValue } from "../../../utils/debounce-value";

export const NullDisplay = () => {
  return <span className="fst-italic" style={{color: "#bababa"}}>-</span>
};

// interface PropertiesQueryType {
//   title: string;
//   type: EntityFilterFieldType
// }


export const PropertySearchModes = [
  "general",
  "landlordName",
  "contactName",
  "contactInfo",
] as const;

export const EditHistoryDateRangePresets = [
  "today",
  "3days",
  "7days",
  // "30days",
  "customize"
] as const;

export type EditHistoryDateRangePreset = (typeof EditHistoryDateRangePresets)[number];

export type PropertySearchMode = (typeof PropertySearchModes)[number];

export const levenshteinScore = (query: string, obj: string) => {
  if (query.length == 0) {
    return 0;
  }
  const minLength = 2;
  let minLengthScoreWeight = 1;
  let score = 0;
  if (obj.length > minLength) {
    if (query.length < minLength) {
      minLengthScoreWeight  = query.length / minLength;
    }
    score = Math.sqrt(1 / (distance(query.toLowerCase().replace(/ /g, ""), obj.toLowerCase().replace(/\s/g, "")) + 1)) * minLengthScoreWeight;
  } else {
    // score
  }

  if (obj == "Wanchai")
    console.log(score)

  return score;
}

export const stringSimilarityScore = (query: string, obj: string) => {
  if (query.trim() == "" || obj.trim() == "") return 0;
  
  obj = obj.toLowerCase();
  let score = stringSimilarity.compareTwoStrings(query.toLowerCase(), obj);

  // if (obj.length <= 4) {
  //   if (score == 0) {
  //     if (obj.startsWith(query)) {
  //       // E.g., "金鐘" and "金"
  //       score = Math.max(0.6, query.length / obj.length);
  //     }
  //   }
  // }

  if (obj.startsWith(query)) {
    // E.g., "金鐘" and "金"
    score = Math.max(0.6, query.length / obj.length);
  }

  return score;
}

export const smartConvertNumber = (str: string): number | null => {
  if (str?.match(/^[\d\,\-\s\$\.\']+[kmb]?$/i)) {
    str = str.replace(/[\s\,]/g, "").replace(/^\$/, "").replace(",", "").replace("\'", "");
    
    // const number = parseFloat(str);

    let multiple = 1;
    if (str.match(/^\d+(\.\d+)?B$/i)) {
      multiple = 1000000000;
      str = str.replace("b", "");
    }
    else if (str.match(/^\d+(\.\d+)?M$/i)) {
      multiple = 1000000;
      str = str.replace("b", "");
    }
    else if (str.match(/^\d+(\.\d+)?K$/i)) {
      multiple = 1000;
      str = str.replace("b", "");
    }
  
    try {
      
      const number = parseFloat(str);
      if (!isNaN(number) && Number.isFinite(number)) {
        return number * multiple;
      }
    } catch (e) {
  
    }
  }


  return null;
}

export const smartConvertRange = (str: string): [number, number] | null => {
  const parts = str?.split("-");
  if (parts.length == 2) {
    const str1 = smartConvertNumber(parts[0]);
    const str2 = smartConvertNumber(parts[1]);
    if (str1 != null && str2 != null && str1 <= str2) {
      return [str1, str2];
    }
  } else {
    str = str?.trim();
    let indicator: string = null;
    let substring: string = null;
    if (str.startsWith("<")) {
      substring = str.substring(1);
      indicator = "<";
    } else if (str.startsWith(">")) {
      substring = str.substring(1);
      indicator = ">";
    } else if (str.endsWith("+")) {
      substring = str.substring(0, str.length - 1);
      indicator = ">";
    }

    const substrNumber = smartConvertNumber(substring);

    if (substrNumber != null) {
      if (indicator == ">") {
        return [substrNumber, 10000000000];
      } else if (indicator == "<") {
        return [0, substrNumber]
      }
    }

  }
  return null;
}

const CellGreenColor = "#d7ffd7";
const CellYellowColor = "#fdffd6";
const CellOrangeColor = "#fcd5c9";

export const AdminPropertyFlag = (props: {
  updated: Date
}) => {

  const timeNow = new Date();
  const {updated} = props;

  // console.log({timeNow, updated});
  const timeDiff = (+timeNow - +new Date(updated)) / 1000;

  let flagType: 'red' | 'yellow' | 'green' | null = null;

  // console.log({timeDiff});
  if (timeNow && updated) {
    if (timeDiff > 90 * 24 * 3600) {
      flagType = "red";
    } else if (timeDiff > 60 * 24 * 3600) {
      flagType = "yellow";
    } else if (timeDiff > 30 * 24 * 3600) {
      flagType = "green";
    }
  }

  return flagType ? (
    <FontAwesomeIcon icon={faFlag} 
      className={clsx("me-1",
        flagType === "green" ? "text-success" : 
        flagType === "yellow" ? "text-warning" : "text-danger"
      )}
    />
  ) : <></>
  
}

interface AdminPropertiesComponentRef {
  updateProperty: (id: Property["id"], property: Property) => void,
  openPropertyOffset: (currentId: Property["id"], offset: number) => void,

}
// export const PropertySearchModes
export const AdminPropertiesComponent = (props: {
  stateRef?: (ref: AdminPropertiesComponentRef) => void,
  editHistoryMode?: boolean;
  setEditHistoryMode?: React.Dispatch<React.SetStateAction<boolean>>,
  openedPropertyId?: Property["id"],
  setOpenedPropertyId?: (id: Property["id"]) => void,
  setOpenPropertyGoToPublishment?: (flag: boolean) => void,
  reloadReadHistoryKey?: any,
  onEnter?: (id: Property["id"]) => void,
}) => {
    const {api, loading, toast, adminUser} = useAdminComponent({selectedItem: AdminPropertyPath});
    
    const userCanPublishPublishment = useMemo(() => {
      return userHasAdminRoles(adminUser, ["PropertyPublishmentPublisherEditor"])
    }, [adminUser])

    const {editHistoryMode, setEditHistoryMode, openedPropertyId, setOpenedPropertyId, setOpenPropertyGoToPublishment, reloadReadHistoryKey
    } = props;
    
    // const adminTableRef = useRef<HTMLTableElement>(null);
    const [highlightedPropertyId, setHighlightedPropertyId] = useState<Property["id"]>(null);
    
    const [editHistoryDateRangePreset, setEditHistoryDateRangePreset] = useState<EditHistoryDateRangePreset>("today");
    const [editHistoryDateRange, setEditHistoryDateRange] = useState<{
      startDate?: Date, endDate?: Date,
    }>(null)
    const [editHistoryUserFiltered, setEditHistoryUserFiltered] = useState<User[]>([]);

    const editHistoryDateRanges = useMemo(() => editHistoryDateRange && [editHistoryDateRange], [editHistoryDateRange]);
    const editHistoryDateRangeTooLarge = useMemo(() => (
      editHistoryDateRange && (
        editHistoryDateRange.endDate?.getTime() - editHistoryDateRange.startDate?.getTime() > (31*24*3600*1000)
      )
    ), [editHistoryDateRange]);
    const modal = useGlobalModal();
    const [isInit, setIsInit] = useState(false);
    const [propertyPaginated, setPropertyPaginated] = useState<HighComputationResult<Paginated<Property>>>(null);
    const [propertyEditHistoryPaginated, setPropertyEditHistoryPaginated] = useState<HighComputationResult<Paginated<PropertyEditHistory>>>(null);
    const [searchHistories, setSearchHistories] = useState<PropertySearchHistory[]>([]);
    const localCache = new LocalCache<{
      tableFontSize: number,
      includeHistory: boolean,
      priceInShort: boolean
    }>({
      prefix: 'admin.property.'
    });

    const sessionCache = new LocalCache<{
      scrollY: number,
      highlightedPropertyId: Property["id"],
    }>({
      prefix: 'admin.property.',
      useSessionStorage: true
    })
    
    const {pathname, search, hash} = useLocation();
    const navigate = useNavigate();
    // console.log(BuildingSort);

    const [searchParams, setSearchParams] = useSearchParams();
    const [autoCompleteQueryRaw, setAutoCompleteQueryRaw] = useState(searchParams.get("query") || "");
    const autoCompleteQuery = useMemo(() => autoCompleteQueryRaw.trim(), [autoCompleteQueryRaw]);

    const [scrolledToId, setScrolledToId] = useState<string>(null);

    // const [editHistory, setValue] = React.useState<DateRange<Dayjs>>([null, null]);

    const [settingsMore, setSettingsMore] = useState(false);
    const [pdfUtilOpen, setPdfUtilOpen] = useState(false);
    const [pdfUtilMoreSettings, setPdfUtilMoreSettings] = useState(false);
    const [pdfUtilPinSelected, setPdfUtilPinSelected] = useState(true);
    const [pdfContent, setPdfContent] = useState<PropertyListPdfContent & {
      language: "en" | "tc" | "both",
      priceInShort: boolean,
      filename: string,
    }>(null);
    const [pdfPresetKey, setPdfPresetKey] = useState<PropertyListKeysPresetKey>("external");
    const [pdfContentSelectedKeys, setPdfContentSelectedKeys] = useState<PropertyListAvailableKey[]>([]);
    const [pdfSelectedProperties, setPdfSelectedProperties] = useState<Property[]>([]);
    // const [pdfContentSelectedIds, setPdfContentSelectedIds] = useState<number[]>([]);
    const pdfContentSelectedPropertyIds = useMemo(() => pdfSelectedProperties.map(p => p.id), [pdfSelectedProperties]);

    
    const [pdfSelectedPropertyEditHistory, setPdfSelectedPropertyEditHistory] = useState<PropertyEditHistory[]>([]);
    const pdfContentSelectedPrpoertyHistoryIds = useMemo(() => pdfSelectedPropertyEditHistory.map(item => (
      item.compositeId
    )), [pdfSelectedPropertyEditHistory]);


    const pdfLoading = useAbortableLoading();
    const [pdfDownloadUrl, setPdfDownloadUrl] = useState<string>(null);

    const [tableFontSize, setTableFontSize] = useState<number>(localCache.get("tableFontSize", 1));
    const tableFontSizeDeferred = useDeferredValue(tableFontSize);

    useEffect(() => localCache.set({"tableFontSize": tableFontSize}), [tableFontSize]);

    const properties = useMemo(() => {
      if (!pdfUtilOpen || !pdfUtilPinSelected) {
        return propertyPaginated?.items;
      } else {
        return [...pdfSelectedProperties, ...(propertyPaginated?.items ?? []).filter(
          p => !pdfContentSelectedPropertyIds.includes(p.id)
        )];
      }
    }, [pdfSelectedProperties, pdfContentSelectedPropertyIds, propertyPaginated?.items, pdfUtilPinSelected, pdfUtilOpen]);

    const scrollToId = useCallback((id: Property["id"], scrollY?: number) => {
      const propertyElement = document.getElementById("" + id);
      const thOffset = 50;
      const elementOffsetTop = propertyElement?.offsetTop;
      const elementOffsetHeight = propertyElement?.offsetHeight;
      const tableScrollTop = scrollTopRef.current?.scrollTop;
      const tableClientHeight = scrollTopRef.current?.clientHeight;

      console.log({scrollY});
      console.log(scrollTopRef.current?.offsetTop);
      console.log({elementOffsetTop, elementOffsetHeight, tableScrollTop, tableClientHeight});
      if (elementOffsetTop != null && elementOffsetHeight != null && tableScrollTop != null && tableClientHeight != null) {
        if (scrollY != null && scrollY <= elementOffsetTop - thOffset && scrollY + tableClientHeight >= elementOffsetTop - tableClientHeight + elementOffsetHeight) {
          scrollTopRef.current?.scroll({top: scrollY, behavior: 'smooth'});
        } else if (elementOffsetTop - thOffset < tableScrollTop) {
          scrollTopRef.current?.scroll({top: elementOffsetTop - thOffset, behavior: 'smooth'});
        } else if (elementOffsetTop - tableClientHeight + elementOffsetHeight > tableScrollTop) {
          scrollTopRef.current?.scroll({top: elementOffsetTop - tableClientHeight + elementOffsetHeight, behavior: 'smooth'});
        }
      }


    }, [])

    const updateProperty = useCallback((id: Property["id"], property: Property) => {
      setPropertyPaginated(propertyPaginated => {
        if (propertyPaginated) {
          const index = propertyPaginated?.items?.findIndex(p => p.id == id);
          if (index !== -1) {
            propertyPaginated.items[index] = property;
          } else {
            console.log("Can't find property with id: " + id);
          }
        }
        return {...propertyPaginated};
      })
      setPdfSelectedProperties(properties => {
        const index = properties?.findIndex(p => p.id == id);
          if (index !== -1) {
            properties[index] = property;
          } else {
            console.log("Can't find property with id: " + id);
          }
        return [...properties];
      })
    }, [])

    const openPropertyOffset = useCallback((currentId: Property["id"], offset: number) => {
      const index = properties?.findIndex(p => p.id == currentId);

      if (index != -1) {
        const targetId = properties?.[index + offset]?.id;
  
        console.log("OPEN ID: " + targetId);
        setHighlightedPropertyId(targetId);
        setOpenedPropertyId(targetId);
        scrollToId(targetId);

      }
    }, [properties, setHighlightedPropertyId, scrollToId])

    useEffect(() => {
      if (props.stateRef) {
        props.stateRef({updateProperty, openPropertyOffset})
      }
    }, [props.stateRef, updateProperty, openPropertyOffset])

    // Arrow key detection
    useEffect(() => {
      const keyDown = (e: KeyboardEvent) => {
        // if (!isInit) return;
        if (isInit && !pdfUtilOpen && !editHistoryMode) {
          const key = e.key;
          console.log({key})
  
          switch (key) {
            case "Escape":
              if (!openedPropertyId) {
                setHighlightedPropertyId(null);
              }
            break;
            case "Enter": 
              if (!pdfUtilOpen && highlightedPropertyId && highlightedPropertyId) {
                if (props.onEnter) {
                  props.onEnter(highlightedPropertyId);
                } else {
                  navigate(`../${highlightedPropertyId}`);
                }
              }
              // setOpenedPropertyId?.(highlightedPropertyId);
            break;
            case "ArrowUp": 
            case "ArrowDown": 
              if (!openedPropertyId) {
                const index = properties?.findIndex(p => p.id == highlightedPropertyId);
                console.log({index});
                const targetId = index == -1 ? properties?.[key == "ArrowDown" ? 0 : properties.length - 1]?.id : properties?.[index + (key == "ArrowDown" ? 1 : -1)]?.id;
  
                setHighlightedPropertyId(targetId);
                e.preventDefault();
                scrollToId(targetId);
              }

              

              // setHighlightedPropertyIds({current:null, prev: null, next: null});
          }
        }
        
      }
      window?.removeEventListener("keydown", keyDown);
      window?.addEventListener("keydown", keyDown);
      return () => {
        window?.removeEventListener("keydown", keyDown);
      }
    }, [props.onEnter, isInit, highlightedPropertyId, openedPropertyId, pdfUtilOpen, editHistoryMode, properties]);


    const propertyEditHistory = useMemo(() => {
      if (!editHistoryMode) {return null}
      if (!pdfUtilOpen || !pdfUtilPinSelected) {
        return propertyEditHistoryPaginated?.items ?? [];
      } else {
        return [...pdfSelectedPropertyEditHistory, ...(propertyEditHistoryPaginated?.items ?? []).filter(
          p => !pdfContentSelectedPrpoertyHistoryIds.includes(p.compositeId)
        )]
      }
    }, [editHistoryMode, pdfSelectedPropertyEditHistory, pdfContentSelectedPrpoertyHistoryIds, propertyEditHistoryPaginated?.items, pdfUtilPinSelected, pdfUtilOpen])

    
    // console.log(scrolledToId);
    const {findAllQuery, setFindAllQuery, resetFindAllQuery, renderPaginationUI, renderSearchInput, renderSortButton, setSearchInput, scrollTopRef, setSortByDropdownOpen} 
      = useFindAllQuery({
      isInit, loading: loading.flag, paginationMeta: (propertyEditHistoryPaginated || propertyPaginated)?.meta,
      shouldUseSearchParams: !pdfUtilOpen, 
      additionalSearchParams: {query: autoCompleteQuery || undefined},
      hashForSearchParams: scrolledToId?.toString() || "",
      searchInputDelay: true,
      // searchOnSubmit: true,
      // sortKeys: ["id", "nameEn", "nameTc", "nameSc", "created", "updated"]
    })

    const [isPageInit, setIsPageInit] = useState(false);

    useEffect(() => {
      setFindAllQuery(findAllQuery => ({
        ...findAllQuery, 
        sort: {
          "property.updated": "DESC",
          "property.starred": "DESC"
        }
      }));
      setIsInit(true);
      setIsPageInit(true);
      // setTimeout(() => {
      //   setIsPageInit(true);
      // }, 500)
    }, [])


    const [buildingsSearched, setBuildingsSearched] = useState<Building[]>(null);
    const [streetNamesSearched, setStreetNamesSearched] = useState<BuildingStreetName[]>(null);

    const buildingsLoading = useAbortableLoading();
    const streetNamesLoading = useAbortableLoading();
    const [districts, setDistricts] = useState<District[]>(null);
    

    const reloadAbortable = useAbortable();
    const reloadSearchHistoriesLoading = useAbortableLoading();
    const openCCT2SAbortableLoading = useAbortableLoading();


  
    
    // const {scrollToId} = useScrollToId({idPrefix: ""});
  
    const {t, currentLang, currentI18n} = useI18N();
    
    const [buildingDisplay, setBuildingDisplay] = useState(0);  // 0 for all, 1 for tc, 2 for en
    const [buildingLocked, setBuildingLocked] = useState(false);
    const [contactCollapsed, setContactCollapsed] = useState(true);
    const [internalRemarksCollapsed, setInternalRemarksCollapsed] = useState(false);
    const [historyIncluded, setHistoryIncluded] = useState(localCache.get("includeHistory", true));
    useEffect(() => localCache.set({"includeHistory": historyIncluded}), [historyIncluded]);
    const [priceInShort, setPriceInShort] = useState(localCache.get("priceInShort", true));
    useEffect(() => localCache.set({"priceInShort": priceInShort}), [priceInShort]);


    const tableKeyDisplay = useMemo(() => ({
      "property.id": "id",
      "publishment.state": t(AdminPropertyI18NPrefix + "publishment.titleShort"),
      "publishment.highlighted": t(AdminPropertyI18NPrefix + "publishment.content.highlighted"),
      "publishment.highlighted2": t(AdminPropertyI18NPrefix + "publishment.content.highlighted2"),
      "building.id": t(AdminPropertyI18NPrefix + "basicInfo.building"),
      "building.streetName": t(AdminBuildingI18NPrefix + "streetName"),
      "building.district.id": t("admin.districts.title"),
      "basicInfo.block": t(AdminPropertyI18NPrefix + "basicInfo.blockShort"),
      "basicInfo.floor": t(AdminPropertyI18NPrefix + "basicInfo.floorShort"),
      "basicInfo.flat": t(AdminPropertyI18NPrefix + "basicInfo.flatShort"),
      "basicInfo.area": <>{t(AdminPropertyI18NPrefix + "basicInfo.area")}</>,
      "basicInfo.grossArea": <>{t(AdminPropertyI18NPrefix + "basicInfo.grossAreaVeryShort")}</>,
      "basicInfo.netArea": <>{t(AdminPropertyI18NPrefix + "basicInfo.netAreaVeryShort")}</>,
      "priceStatus.price": t(AdminPropertyI18NPrefix + "priceStatus.price"),
      "priceStatus.rent": t(AdminPropertyI18NPrefix + "priceStatus.rent"),
      "priceStatus.status": t(AdminPropertyI18NPrefix + "priceStatus.status"),
      "basicInfo.buildingType": t(AdminPropertyI18NPrefix + "basicInfo.buildingType"),
      "landlord.name": t(AdminPropertyI18NPrefix + "landlord.name"),
      "landlord.contact": t(AdminPropertyI18NPrefix + "landlord.contact"),
      "landlord.contactName": t(AdminPropertyI18NPrefix + "landlord.contactName"),
      "landlord.contactInfo": t(AdminPropertyI18NPrefix + "landlord.contactInfo"),
      "priceStatus.pdfRemark": t(AdminPropertyI18NPrefix + "priceStatus.pdfRemark"),
      "basicInfo.view": t(AdminPropertyI18NPrefix + "basicInfo.view"),
      "basicInfo.rooms": t(AdminPropertyI18NPrefix + "basicInfo.rooms"),
      "basicInfo.carparks": t(AdminPropertyI18NPrefix + "basicInfo.carparks"),
      "detail.internalRemarks": t(AdminPropertyI18NPrefix + "detail.internalRemarks"),
      "updatedBy": t("entityData.timestamp.rawUpdatedBy"),
      "updated": t("entityData.timestamp.rawUpdated"),
      "createdBy": t("entityData.timestamp.createdBy"),
      "created": t("entityData.timestamp.created"),
    }), []);

    const smartSearchNumbersOptions = useMemo(() => {
      const autoCompleteQueryNumber = smartConvertNumber(autoCompleteQuery);
      const autoCompleteQueryInteger = Number.isSafeInteger(autoCompleteQueryNumber) && autoCompleteQueryNumber;
      const autoCompleteQueryRange = smartConvertRange(autoCompleteQuery);
      // console.log({autoCompleteQueryNumber, autoCompleteQueryInteger, autoCompleteQueryRange})
    
      const obj: {
        type: string,
        label: React.ReactNode,
        value: any,
      }[] = [];
      if (autoCompleteQuery.match(/^[\d\s\,]+$/) && autoCompleteQueryInteger && autoCompleteQueryInteger < 500000) {
        obj.push({
          type: "property.id",
          label: <>
            <FontAwesomeIcon icon={faHashtag} fixedWidth className="text-black-50 me-2"/>
            {<span className="text-black-50 me-2">{t(AdminPropertyI18NPrefix + "refNo")}</span>}{autoCompleteQueryInteger}</>,
          value: autoCompleteQueryInteger
        })
      }

      if (autoCompleteQueryRange) {
        const [from, to] = autoCompleteQueryRange;
        const fromCommify = commify(from);
        const toCommify = commify(to);

        const area: typeof obj = []
        if (!autoCompleteQuery.match(/\$/)) {
          area.push({
            type: "basicInfo.grossArea",
            label: <>
              <FontAwesomeIcon icon={faPenRuler} fixedWidth className="text-black-50 me-2"/>
              {<span className="text-black-50 me-2">{t(AdminPropertyI18NPrefix + "basicInfo.grossArea")}</span>}{`${fromCommify}' - ${toCommify}'`}</>,
            value: autoCompleteQueryRange
          })
          area.push({
            type: "basicInfo.netArea",
            label: <>
              <FontAwesomeIcon icon={faPenRuler} fixedWidth className="text-black-50 me-2"/>
              {<span className="text-black-50 me-2">{t(AdminPropertyI18NPrefix + "basicInfo.netArea")}</span>}{`${fromCommify}' - ${toCommify}'`}</>,
            value: autoCompleteQueryRange
          })
        }

        const priceAndRent: typeof obj = [];
        
        if (!autoCompleteQuery.match(/\'/)) {
          priceAndRent.push({
            type: "priceStatus.price",
            label: <>
              <FontAwesomeIcon icon={faDollarSign} fixedWidth className="text-black-50 me-2"/>
              {<span className="text-black-50 me-2">{t(AdminPropertyI18NPrefix + "priceStatus.price")}</span>}{`$${fromCommify} - $${toCommify}`}</>,
            value: autoCompleteQueryRange
          })
          priceAndRent.push({
            type: "priceStatus.rent",
            label: <>
              <FontAwesomeIcon icon={faDollarSign} fixedWidth className="text-black-50 me-2"/>
              {<span className="text-black-50 me-2">{t(AdminPropertyI18NPrefix + "priceStatus.rent")}</span>}{`$${fromCommify} - $${toCommify}`}</>,
            value: autoCompleteQueryRange
          });
        }


        if (to <= 1000000) {
          priceAndRent.reverse();
        }

        if (to <= 5000) {
          obj.push(...area, ...priceAndRent);
        } else {
          obj.push(...priceAndRent, ...area);
        }


      }
      return obj;
    }, [autoCompleteQuery])

    const smartSearchContactOptions = useMemo(() => {
      const obj: {
        type: string,
        label: React.ReactNode,
        value: any,
      }[] = [];

      if (autoCompleteQuery) {
        obj.push({
          type: "landlord.name",
          label: <span><FontAwesomeIcon fixedWidth icon={faAddressCard} className="text-black-50 me-2" /><span className="text-black-50 me-2">{t(AdminPropertyI18NPrefix + "landlord.name")}</span> {autoCompleteQuery}</span>,
          value: autoCompleteQuery
        }, {
          type: "landlord.contactName",
          label: <span><FontAwesomeIcon fixedWidth icon={faAddressCard} className="text-black-50 me-2" /><span className="text-black-50 me-2">{t(AdminPropertyI18NPrefix + "landlord.contactName")}</span> {autoCompleteQuery}</span>,
          value: autoCompleteQuery
        })
        const contactInfoFirst = autoCompleteQuery.match(/^\d/) || autoCompleteQuery.includes("@");

        const contactInfoObj = {
          type: "landlord.contactInfo",
          label: <span><FontAwesomeIcon fixedWidth icon={faPhoneSquare} className="text-black-50 me-2" /><span className="text-black-50 me-2">{t(AdminPropertyI18NPrefix + "landlord.contactInfo")}</span> {autoCompleteQuery}</span>,
          value: autoCompleteQuery
        };
  
        contactInfoFirst ? obj.unshift(contactInfoObj) : obj.push(contactInfoObj)
      }



      return obj;
    }, [autoCompleteQuery])

    const smartSearchDistricts = useMemo(() => {
      return (districts || [])?.map(district => ({
        type: "district",
        label: <span>
          <FontAwesomeIcon fixedWidth icon={faMapLocationDot} className="text-black-50 me-2" />
          {district["name" + LangMap(currentLang)] || 
            district.nameEn || district.nameTc || district.nameSc || 
            <NullDisplay />}
          <span className="text-muted ms-2">
            {district.region ? t("admin.districts.regions." + district.region) : <NullDisplay />}
          </span>
        </span>,
        value: district,
        score: Math.max(
          stringSimilarityScore(autoCompleteQuery, district.nameEn),
          stringSimilarityScore(autoCompleteQuery, district.nameTc),
          stringSimilarityScore(autoCompleteQuery, district.nameSc),
        )
      }))
    }, [autoCompleteQuery, districts])

    const smartSearchBuildings = useMemo(() => {
      if (!autoCompleteQuery) {
        return [];
      }
      return (buildingsSearched || [])?.map(building => ({
        type: "building",
        label: <span>
          <FontAwesomeIcon fixedWidth icon={faCity} className="text-black-50 me-2" />
          {
            building["name" + LangMap(currentLang)] || 
            building.nameEn || building.nameTc || building.nameSc || 
            <NullDisplay />
          }
          
          <span className="text-muted ms-2">
            {StreetPreview(t, {
              district: building.district?.["name" + LangMap(currentLang)], 
              streetName: building["streetName" + LangMap(currentLang)], streetNumber: building.streetNumber
            }) || 
            StreetPreview(t, {
              district: building.district?.["name" + LangMap(currentLang)], 
              streetName: building["streetName" + LangMap(currentLang)], streetNumber: building.streetNumber,
              lang: "en"
            }) ||
            StreetPreview(t, {
              district: building.district?.["name" + LangMap(currentLang)], 
              streetName: building["streetName" + LangMap(currentLang)], streetNumber: building.streetNumber,
              lang: "tc"
            }) ||
            <NullDisplay />}
          </span>
          
        </span>,
        value: building as District | Building | number | [number, number],
        score: Math.max(
          stringSimilarityScore(autoCompleteQuery, building.nameEn),
          stringSimilarityScore(autoCompleteQuery, building.nameTc),
          stringSimilarityScore(autoCompleteQuery, building.nameSc),
        )
      }))
    }, [autoCompleteQuery, buildingsSearched])

    const smartSearchStreetNames = useMemo(() => {
      if (!autoCompleteQuery) {
        return [];
      }
      return (streetNamesSearched || [])?.map(streetName => ({
        type: "building.streetName",
        label: <span>
          <FontAwesomeIcon fixedWidth icon={faRoad} className="text-black-50 me-2" />
          {
            streetName["streetName" + LangMap(currentLang)] || 
            streetName.streetNameEn || streetName.streetNameTc || streetName.streetNameSc ||
            <NullDisplay />
          }
          
          <span className="text-muted ms-2">
            {streetName.district?.["name" + LangMap(currentLang)] || 
            streetName.district?.nameEn ||
            streetName.district?.nameTc ||
            streetName.district?.nameSc ||
            <NullDisplay />}
          </span>
          
        </span>,
        value: streetName,
        score: Math.max(
          stringSimilarityScore(autoCompleteQuery, streetName.streetNameEn),
          stringSimilarityScore(autoCompleteQuery, streetName.streetNameTc),
          stringSimilarityScore(autoCompleteQuery, streetName.streetNameSc),
        )
      }))
    }, [autoCompleteQuery, streetNamesSearched])

    // console.log(smartSearchStatus);



    const addSort = useCallback((sort: EntitySort) => setFindAllQuery(query => ({
      ...query, 
      page: 1,
      sort: Object.fromEntries(Object.entries({
        ..._.omit(query.sort, Object.keys(sort)),
        ...sort
      }).filter((key, value) => value != undefined))
    })), [])

    useEffect(() => {
      if (editHistoryMode) {
        resetEditHistory();
        setPdfPresetKey("editHistory");
      } else {
        setPropertyEditHistoryPaginated(null);
        setPdfPresetKey("external");
      }
    }, [editHistoryMode]);


    const resetPdfContentWithPreset = useCallback((key: PropertyListKeysPresetKey) => {
      console.log("Reset: " + key);
      const defaultPdfContent: typeof pdfContent = {
        orientation: "landscape",
        priceInShort: true,
        filename: "properties-" + moment().format("YYYY-MM-DD"),
        to: "",
        from: [adminUser.profile?.firstNameEn, adminUser.profile?.lastNameEn].filter(str => !!str).join(" "),
        licenseNumber: adminUser.adminInfo?.licenseNumber || "",
        email: adminUser?.email || "",
        phone: adminUser?.profile?.contactNumber || "",
        language: "tc",
        showHeaderFooter: true,
        pageTitle: "Property Listings",
        keys: {},
        rows: [],
        font: "NotoSansHK",
        fontSize: 8.8,
        lineSpacing: 0.92,
      }

      setPdfContentSelectedKeys([...PropertyListKeysPresets[key]]);

      switch (key) {
        case "external": 
          setPdfContent({...defaultPdfContent, 
            orientation: "landscape", 
            language: "tc", 
            fontSize: 9,
            showHeaderFooter: true,
            filename: "properties-" + moment().format("YYYY-MM-DD"),
          })
        break;
        case "internal":
          setPdfContent({...defaultPdfContent, 
            orientation: "landscape", 
            language: "en", 
            fontSize: 8.2,
            showHeaderFooter: false,
            pageTitle: "Property Listings",
            filename: "internal-" + moment().format("YYYY-MM-DD"),
          });
        break;
        case "editHistory":
          setPdfContent({...defaultPdfContent, 
            orientation: "landscape", 
            language: "en", 
            fontSize: 8.2,
            showHeaderFooter: false,
            pageTitle: "Stock Update",
            filename: "stock-update-" + moment().format("YYYY-MM-DD"),
          });
        break;
      }
    }, []);

    useEffect(() => {
      resetPdfContentWithPreset(pdfPresetKey);
    }, [pdfPresetKey])
    

    const generateFilteredOptions = useCallback((filter: EntityFilter, hideCancel = false) => {
      return (
        filter && Object.entries(filter).map(([key, filter], index) => {
          if (!filter) {return null}
          const {type, value} = filter;
          // console.log(filter)
          return <React.Fragment key={index}>
          {
            getEntityFilterValueArray(filter).map(filterValue => {
              let prefixArea: React.ReactNode = null;
              let singleValue = filterValue;
              let hideValue = false;
              // console.log(singleValue);
              switch (key) {
                // case "basicInfo.building": 
                case "publishment.state": 
                  singleValue = t(AdminPropertyI18NPrefix + "publishment.states." + (singleValue || "null"))
                break;
                case "building.id": 
                  singleValue = <BuildingNameFromIdSync id={singleValue} api={api} />
                break;
  
                case "building.district.id": 
                  const district = districts?.find(d => d.id == singleValue);
                  singleValue = district ? (district["name" + LangMap(currentLang)] || district.nameEn || district.nameTc) : singleValue;
                break;
  
                case "building.streetName": {
                  singleValue = singleValue;
                }
                break;
  
                case "basicInfo.grossArea":
                case "basicInfo.netArea": 
                  singleValue = singleValue.map?.(area => commify(parseInt(area)) + "'");
                break;
  
                case "priceStatus.price":
                case "priceStatus.rent": 
                  singleValue = singleValue.map?.(val => "$" + numberInShort(parseInt(val)));
                break;
  
                case "priceStatus.status":
                  singleValue = t(AdminPropertyI18NPrefix + "priceStatus.statuses." + singleValue);
                break;
  
                case "basicInfo.buildingType": 
                  singleValue = t(AdminBuildingI18NPrefix + "type." + singleValue)
                break;

                case "publishment.highlighted": 
                case "publishment.highlighted2": 
                  hideValue = true;
  
              }
              return <BadgeCancellable
                key={key + ":" + JSON.stringify(filterValue)}
                bg="info"
                onCancel={!hideCancel && (() => {
                  if (findAllQuery.filter[key]) {
                    console.log(findAllQuery.filter[key]);
                    if (findAllQuery.filter[key].type == "multiple") {
                      findAllQuery.filter[key] = entityFilterValuesSet(findAllQuery.filter[key], "delete", filterValue, true);
                      if (findAllQuery.filter[key] == null) {
                     
                      }
                    } else {
                      delete findAllQuery.filter[key];
                      delete findAllQuery?.sort?.[key];
                    }
                    
                  }
  
                  setFindAllQuery(findAllQuery);
                })}
              >
              <>
                <span className="me-1">{
                  tableKeyDisplay[key] 
                }{!hideValue && `:`}</span>
                {
                  !hideValue && <span>{
                    type != "range" ? singleValue : (
                      singleValue.join(" - ")
                    )
                  }</span>
                }
              </></BadgeCancellable>
            })
          }
          </React.Fragment>
        })
      )
    }, [findAllQuery, districts]);

    const filteredOptions = useMemo(() => (findAllQuery?.filter && <>{
      generateFilteredOptions(findAllQuery.filter)
    }
    {
      <ButtonWithLoader variant="danger" faIcon={faTrash} size="sm" className="small p-0 px-2 ms-auto"
        onClick={() => {
          setFindAllQuery(query => ({
            ...query,
            filter: null
          }))
        }}
      >{
        t("commons.clearAll")
      }</ButtonWithLoader>
    }
    </>), [findAllQuery, findAllQuery?.filter, findAllQuery?.sort, districts])

    const smartSearchHistories = useMemo(() => (
      searchHistories?.length ? [{
        label: <div className="d-flex flex-wrap align-items-center">
          <span>{t("commons.recentSearch")}</span>
          <ButtonWithLoader size="sm" className="ms-auto small p-0 px-2" variant="outline-danger"
            loading={reloadSearchHistoriesLoading.flag}
            onClick={async() => {
              if (await modal.confirmDelete()) {
                const {token, signal} = reloadSearchHistoriesLoading.start();
                try {
                  await api.property.deleteAllSearchHistories();
                } catch (e) {}
                reloadSearchHistoriesLoading.stop(token);
                reloadSearchHistories();
              }
            }}
          >
            {t("commons.clearAll")}
          </ButtonWithLoader>
          
        </div>,
        options: searchHistories.map(searchHistory => {
          return {
            label: <div className="d-flex align-items-center flex-wrap" style={{gap: "0.3em"}}>
              
              {generateFilteredOptions(searchHistory.filter, true)}
              <div className="ms-1" />
              <div className="ms-auto" />
              {
                reloadSearchHistoriesLoading.flag ? <Spinner animation="border" style={{width: "1em", height: "1em"}} /> : (
                  <FontAwesomeIcon icon={searchHistory.bookmarked ? faBookmark : farBookmark} size="lg" 
                    className="px-1"
                    style={{cursor: "pointer"}}
                    onClick={async(event) => {
                      event.stopPropagation();
                      const {token, signal} = reloadSearchHistoriesLoading.start();
                      try {
                        await api.property.saveSearchHistory({
                          ...searchHistory,
                          bookmarked: !searchHistory.bookmarked
                        });
                      } catch (e) {

                      }
                      reloadSearchHistoriesLoading.stop(token);
                      reloadSearchHistories();
                      
                    }}
                  />
                )
              }
            </div>,
            value: searchHistory,
            type: "search-history",
          }
        })
      }] : []
    ), [searchHistories, reloadSearchHistoriesLoading])
    
    const reloadSearchHistories = useCallback(async() => {
      const {token, signal} = reloadSearchHistoriesLoading.start();
      try {
        setSearchHistories(await api.property.findAllSearchHistories({signal}))
      } catch (e) {

      }
      reloadSearchHistoriesLoading.stop(token);
    }, []);

    // const [readHistory, setReadHistory] = useState<PropertyReadHistoryUserResults>(null);

    // const reloadReadHistory = useCallback(async() => {
    //   if (propertyPaginated?.items) {
    //     try {
    //       setReadHistory(await api.property.evaluateReadHistoryUser({
    //         propertyIds: propertyPaginated?.items?.map(property => property.id)
    //       }))
    //     } catch (e) {
    //       modal.errorSpecific(e);

    //     }
       
    //   }
    // }, [propertyPaginated])

    // useEffect(() => {
    //   // reloadReadHistory();
    // }, [propertyPaginated, reloadReadHistoryKey])

    const reload = useCallback(async(options?: {
      bypassScrollToAnchor?: boolean
    }) => {
      const token = loading.start();
      const {signal} = reloadAbortable.create();
      try {
        console.log(JSON.stringify({querySort: findAllQuery?.sort}));
        setPropertyPaginated(await api.property.findAll({
          ...findAllQuery,
          includeHistory: historyIncluded
        }, {signal}));
        // TODO
        // setSearchInput("");
        if (!options?.bypassScrollToAnchor) {
          // scrollToId(null);
        }
        reloadSearchHistories();  // No need await

      } catch (e) {
        modal.errorSpecific(e);
      } finally {
        loading.stop(token);
      }
    }, [findAllQuery])

    const reloadEditHistory = useCallback(async(query?: Partial<PropertyEditHistoryFindAllQuery>) => {
      const token = loading.start();
      const {signal} = reloadAbortable.create();
      try {
        console.log(findAllQuery);
        const result = await api.property.findAllEditHistory({
          // ...findAllQuery,
          page: findAllQuery.page,
          limit: findAllQuery.limit,
          editHistoryFrom: editHistoryDateRange?.startDate,
          editHistoryTo: editHistoryDateRange?.endDate,
          editHistoryUsers: editHistoryUserFiltered?.map(user => user.id),
          ...query
        }, {signal})

        // setPropertyPaginated(result);
        setPropertyEditHistoryPaginated(result);
        // TODO
        // setSearchInput("");
        // scrollToId(null);
      } catch (e) {
        console.warn(e);
        modal.errorSpecific(e);
      } finally {
        loading.stop(token);
      }
    }, [findAllQuery, editHistoryDateRange, editHistoryUserFiltered])

    const resetEditHistory = useCallback(() => {
      const now = new Date();
      const startDate = moment(now).startOf("day").toDate();
      const endDate = moment(now).endOf("day").toDate();
      setEditHistoryDateRange({
        startDate, endDate
      });
      setEditHistoryDateRangePreset("today");
      setEditHistoryUserFiltered([]);
      reloadEditHistory({
        page: 1,
        editHistoryFrom: startDate,
        editHistoryTo: endDate,
        editHistoryUsers: null,
      });
    }, []);

    useEffect(() => {
      if (isInit && isPageInit) {
        console.log(JSON.stringify({querySort: findAllQuery?.sort}));
        
        !propertyEditHistoryPaginated ? reload() : reloadEditHistory();
        if (autoCompleteQuery) {
        searchParams.set("query", autoCompleteQuery);
      } else {
        searchParams.delete("query");
      }
      // navigate({
      //   pathname, search: searchParams.toString(), hash
      // }, {replace: true})
      }
    }, [isInit, isPageInit, findAllQuery]);

    useEffect(() => {
      api.districts.findAll().then(districts => setDistricts(districts))
    }, []);
    
    // useEffect(() => {
    //   const savedID = sessionCache.get("highlightedPropertyId", null);
    //   const scrollY = sessionCache.get("scrollY", null);
    //   reload().then(() => {
    //     if (savedID) {
    //       console.log("SCROLL TO " + savedID);
    //       // Set timeout for table init rendering
    //       setTimeout(() => {

    //         scrollToId(savedID, scrollY);
    //         setHighlightedPropertyId(savedID);
    //         // remove cache
    //         sessionCache.set({highlightedPropertyId: null, scrollY: null});
    //       }, 100)
    //     }
    //     setIsInit(true)
        
    //   });

    //   api.districts.findAll().then(districts => setDistricts(districts))

    //   return () => {
    //     reloadAbortable.abort();
    //   }
    // }, []);

    return (
      <>
        
        <Collapsible
          collapsed={!editHistoryMode}
          hideCollapsed
        >
          <div className="card py-2 px-3 d-flex flex-column" style={{gap: "0.6em", zIndex: 50}}>
            <div className="d-flex align-items-center" style={{gap: "0.5em"}}>
              <FontAwesomeIcon icon={faUserPen} size="lg" fixedWidth/><b>{t(AdminPropertyI18NPrefix + "editHistory.title")}</b>
              <ButtonWithLoader variant="warning" size="sm" className="ms-auto"
                onClick={async() => resetEditHistory()}
              >{t('commons.reset')}</ButtonWithLoader>
              <ButtonWithLoader variant="danger" size="sm"
                onClick={() => setEditHistoryMode(false)}
              >{t('commons.close')}</ButtonWithLoader>
            </div>
            <div className="d-flex justify-content-center flex-wrap" style={{gap: "0.5rem"}}>{
              EditHistoryDateRangePresets.map(preset => (<ButtonWithLoader key={preset} size="sm" className="small p-0 px-2"
                variant={editHistoryDateRangePreset == preset ? 
                  "info" : "outline-info"
                }
                onClick={() => {
                  setEditHistoryDateRangePreset(preset)
                  const {startDate, endDate} = editHistoryDateRange;
                  let startDateMoment = moment(startDate);
                  let endDateMoment = moment(endDate);
                  let now = new Date();
                  switch (preset) {
                    case "today":
                    case "customize": 
                      startDateMoment = moment(now).startOf("day");
                      endDateMoment = moment(now).endOf("day");
                    break;
                    case "3days": 
                      startDateMoment = moment(now).subtract(3, "days").startOf("day");
                      endDateMoment = moment(now).endOf("day");
                    break;
                    case "7days": 
                      startDateMoment = moment(now).subtract(7, "days").startOf("day");
                      endDateMoment = moment(now).endOf("day");
                    break;
                    // case "30days": 
                    //   startDateMoment = moment(now).subtract(30, "days").startOf("day");
                    //   endDateMoment = moment(now).endOf("day");
                    // break;
                  }

                  setEditHistoryDateRange({startDate: startDateMoment.toDate(), endDate: endDateMoment.toDate()})

                  if (preset != "customize") {
                    reloadEditHistory({
                      page: 1,
                      editHistoryFrom: startDateMoment.toDate(),
                      editHistoryTo: endDateMoment.toDate()
                    })
                  }

                }}
              >
                <small>{t(AdminPropertyI18NPrefix + "editHistory.dateRange.presets." + preset)}</small>
              </ButtonWithLoader>))
            }</div>

            {editHistoryDateRanges && <div className="d-flex justify-content-center">
              <DateRange
                key={editHistoryDateRangePreset}
                className="card"
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                showDateDisplay={true}
                dateDisplayFormat="dd/MM/yyyy HH:mm"
                // dayContentRenderer={(date) => null}
                classNames={{
                  monthAndYearWrapper: clsx(editHistoryDateRangePreset != "customize" && "hidden-component"),
                  months: clsx(editHistoryDateRangePreset != "customize" && "hidden-component")
                }}
                direction="vertical"
                // direction="horizontal"
                // scroll={{ enabled: true }}
                // months={2}
                minDate={moment().set("year", 2020).startOf("year").toDate()}
                maxDate={moment().add(1, "day").endOf("month").toDate()}
                locale={locales[{
                  "tc": "zhTW",
                  "sc": "zhCN"
                }[currentLang] ?? "enUS"]}
                ranges={editHistoryDateRanges}
                onChange={(item: RangeKeyDict) => {
                  if (editHistoryDateRangePreset == "customize") {
                    const {startDate, endDate} = Object.values(item)[0] as Range;
                    setEditHistoryDateRange({
                      startDate: moment(startDate).startOf("day").toDate(),
                      endDate: moment(endDate).endOf("day").toDate(),
                    })
                  }
                  
                }}
              />

            </div>}
            
            {
              editHistoryDateRangeTooLarge && 
              <div className="small text-danger text-center">
                <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                {t(AdminPropertyI18NPrefix + "editHistory.dateRange.warning")}
              </div>
            }

            <div className="d-flex align-items-center justify-content-center">
              <UsersSearch api={api} placeholder={t(AdminPropertyI18NPrefix + "editHistory.filterUsers")}
                excludeUsers={editHistoryUserFiltered}
                onSelect={user => {
                  setEditHistoryUserFiltered(users => {
                    if (!editHistoryUserFiltered.find(u => u.id == user.id)) {
                      return [...users, user];
                    } else {
                      return users;
                    }
                  })
                }}
              />
            </div>
            {
              editHistoryUserFiltered?.length ? <div className="d-flex flex-row" style={{gap: "0.3em"}}>{
               editHistoryUserFiltered.map(user => <BadgeCancellable
                key={user.id}
                bg="info"
                onCancel={() => {
                  setEditHistoryUserFiltered(users => users.filter(u => u.id != user.id))
                }}
              >{user.username}</BadgeCancellable>) 
              }</div> : null
            }
            {
              <ButtonWithLoader
                faIcon={faSearch}
                variant="info"
                size="sm"
                loading={loading.flag}
                onClick={() => {
                  // setFindAllQuery(query => ({...query, page: 1}));
                  reloadEditHistory({
                    page: 1
                  })
                }}
              >{t("commons.search")}</ButtonWithLoader>
            }
            {
              propertyEditHistoryPaginated && <div className="d-flex align-items-center justify-content-center flex-wrap" style={{gap: "0.5em"}}>
                <div style={{backgroundColor: CellGreenColor}} className="px-1">
                  <small>{t(AdminPropertyI18NPrefix + "editHistory.lastUpdate")}</small>
                </div>
                <div style={{backgroundColor: CellYellowColor}} className="px-1">
                  <small>{t(AdminPropertyI18NPrefix + "editHistory.created")}</small>
                </div>
                <div style={{backgroundColor: CellOrangeColor}} className="px-1">
                  <small>{t(AdminPropertyI18NPrefix + "editHistory.sectionChanged")}</small>
                </div>
              </div>
            }


          </div>
        </Collapsible>

        <Collapsible
          collapsed={!pdfUtilOpen}
          hideCollapsed
        >
          <div className="card py-2 px-3 d-flex flex-column" style={{gap: "0.5em", zIndex: 50}}>
            <div className="d-flex align-items-center" style={{gap: "0.5em"}}>
              <FontAwesomeIcon icon={faFilePdf} size="lg" fixedWidth/><b>{t(AdminPropertyI18NPrefix + "pdf.title")}</b>
              <ButtonWithLoader variant="danger" size="sm" className="ms-auto"
                onClick={async() => await modal.confirmUnsaved() && setPdfUtilOpen(false)}
              >{t('commons.close')}</ButtonWithLoader>
            </div>
            <div className="d-flex align-items-center flex-wrap" style={{gap: "0.6em"}}>

              <span>{t(AdminPropertyI18NPrefix + 'pdf.keysPresets.title')}: </span>
              
              {
                PropertyListKeysPresetKeys.map(preset => (
                  <ButtonWithLoader key={preset} size="sm" className="small p-0 px-2"
                    variant={pdfPresetKey == preset ?  "info" : "outline-info"}
                    onClick={() => {
                      setPdfPresetKey(preset);
                    }}
                    disabled={
                      (!editHistoryMode && preset == "editHistory") ||
                      (editHistoryMode && preset != "editHistory")
                  }
                  >
                    <small>{t(AdminPropertyI18NPrefix + "pdf.keysPresets." + preset)}</small>
                  </ButtonWithLoader>
                ))
              }
            </div>
            <EntityData
              unsaved={pdfUtilOpen}
              object={pdfContent}
              onChange={(obj) => setPdfContent(obj)}
              noMarginCompensate
              tight
              meta={{
                "settingsSection": {
                  type: "section",
                  title: <CollapsibleButton 
                    title={t(AdminPropertyI18NPrefix + "pdf.settings")} 
                    collapsed={!pdfUtilMoreSettings} 
                  />,
                  onClick: () => setPdfUtilMoreSettings(flag => !flag)
                },
                "settings": {
                  type: "component",
                  component: (
                    <Collapsible
                      collapsed={!pdfUtilMoreSettings}
                      hideCollapsed
                    >
                      <EntityData
                        unsaved={pdfUtilOpen}
                        object={pdfContent}
                        onChange={(obj) => setPdfContent(obj)}
                        noMarginCompensate
                        tight
                        meta={{
                          "orientation": {
                            type: "select",
                            selectIsClearable: false,
                            title: t('admin.property.pdf.orientation.title'),
                            placeholder: t('admin.property.pdf.orientation.title'),
                            selectOptions: [{
                              label: <div className="d-flex align-items-center" style={{gap: "0.6em"}}>
                                <div style={{border: "2px solid black", width: "1.2em", height: "0.8em"}}/>
                                {t('admin.property.pdf.orientation.landscape')}
                              </div>,
                              value: "landscape"
                            }, {
                              label: <div className="d-flex align-items-center" style={{gap: "0.6em"}}>
                                <div style={{border: "2px solid black", width: "0.8em", height: "1.2em", margin: "0 0.2em"}}/>
                                {t('admin.property.pdf.orientation.portrait')}
                              </div>,
                              value: "portrait"
                            }],
                            divWidth: {xs: 12, sm: 6, md: 4, xxl: 2}
                          },
                          "from": {
                            type: "text",
                            title: t(AdminPropertyI18NPrefix + "pdf.from"),
                            disabled: pdfContent?.orientation == "portrait" || !pdfContent?.showHeaderFooter,
                            divWidth: {xs: 12, sm: 6, md: 4, xxl: 2}
                          },
                          "licenseNumber": {
                            type: "text",
                            title: t(AdminPropertyI18NPrefix + "pdf.content.licenseNumber"),
                            disabled: pdfContent?.orientation == "portrait" || !pdfContent?.showHeaderFooter,
                            divWidth: {xs: 12, sm: 6, md: 4, xxl: 2}
                          },
                          "email": {
                            type: "text",
                            title: t(AdminPropertyI18NPrefix + "pdf.email"),
                            disabled: pdfContent?.orientation == "portrait" || !pdfContent?.showHeaderFooter,
                            divWidth: {xs: 12, sm: 6, md: 4, xxl: 2}
                          },
                          "phone": {
                            type: "text",
                            title: t(AdminPropertyI18NPrefix + "pdf.phone"),
                            disabled: pdfContent?.orientation == "portrait" || !pdfContent?.showHeaderFooter,
                            divWidth: {xs: 12, sm: 6, md: 4, xxl: 2}
                          },
                          "to": {
                            type: "text",
                            title: t(AdminPropertyI18NPrefix + "pdf.to"),
                            disabled: pdfContent?.orientation == "portrait" || !pdfContent?.showHeaderFooter,
                            divWidth: {xs: 12, sm: 6, md: 4, xxl: 2}
                          },
                          "priceInShort": {
                            type: "switch",
                            title: t(AdminPropertyI18NPrefix + "priceInShort"),
                            divWidth: {xs: 12, sm: 6, md: 4, xxl: 2}
                          },
                          "showHeaderFooter": {
                            type: "switch",
                            title: t(AdminPropertyI18NPrefix + "pdf.showHeaderFooter"),
                            divWidth: {xs: 12, sm: 6, md: 4, xxl: 2},
                          },
                          "pageTitle": {
                            type: "text",
                            title: t(AdminPropertyI18NPrefix + "pdf.pageTitle"),
                            disabled: pdfContent?.showHeaderFooter,
                            divWidth: {xs: 12, sm: 6, md: 4, xxl: 2},
                          },
          
                          "font": {
                            type: "select",
                            title: t(AdminPropertyI18NPrefix + "pdf.font"),
                            divWidth: {xs: 12, sm: 6, md: 4, xxl: 2},
                            selectIsClearable: false,
                            selectOptions: Object.entries(PdfFonts).map(([key, value]) => ({
                              label: <span key={key} style={{
                                fontFamily: value.fontFamily,
                                fontWeight: value.light ? 300 : null
                              }}>{value.title}</span>,
                              value: key
                            }))
                          },
          
                          "fontSize": {
                            type: "text-float",
                            title: <div className="d-flex align-items-center">
                            {t(AdminPropertyI18NPrefix + "pdf.fontSize")}
                            <FontAwesomeIcon icon={faCircleMinus} className="text-info ms-2" size="lg" style={{cursor: "pointer"}}
                              onClick={() => {
                                setPdfContent(content => ({...content, fontSize: Math.max(6, parseFloat((content.fontSize - 0.2).toFixed(1)))}))
                              }}
                            />
                            <FontAwesomeIcon icon={faCirclePlus} className="text-info ms-2" size="lg" style={{cursor: "pointer"}}
                              onClick={() => {
                                setPdfContent(content => ({...content, fontSize: Math.min(12, parseFloat((content.fontSize + 0.2).toFixed(1)))}))
                              }}
                            />
                          </div>,
                            min: 6, 
                            max: 12,
                            step: 0.2,
                            divWidth: {xs: 12, sm: 6, md: 4, xxl: 2},
                          },
                          "lineSpacing": {
                            type: "text-float",
                            title: <div className="d-flex align-items-center">
                              {t(AdminPropertyI18NPrefix + "pdf.lineSpacing")}
                              <FontAwesomeIcon icon={faCircleMinus} className="text-info ms-2" size="lg" style={{cursor: "pointer"}}
                                onClick={() => {
                                  setPdfContent(content => ({...content, lineSpacing: Math.max(0.8, parseFloat((content.lineSpacing - 0.1).toFixed(1)))}))
                                }}
                              />
                              <FontAwesomeIcon icon={faCirclePlus} className="text-info ms-2" size="lg" style={{cursor: "pointer"}}
                                onClick={() => {
                                  setPdfContent(content => ({...content, lineSpacing: Math.min(2, parseFloat((content.lineSpacing + 0.1).toFixed(1)))}))
                                }}
                              />
                            </div>,
                            min: 0.8,
                            max: 2,
                            step: 0.1,
                            divWidth: {xs: 12, sm: 6, md: 4, xxl: 2},
                          },
                          
                          "language": {
                            type: "select",
                            title: t(AdminPropertyI18NPrefix + "pdf.language"),
                            selectIsClearable: false,
                            selectOptions: ["en", "tc", "both"].map(lang => ({
                              label: t(AdminPropertyI18NPrefix + "pdf.languages." + lang),
                              value: lang
                            })),
                            divWidth: {xs: 12, sm: 6, md: 4, xxl: 2}
                          },
                          "filename": {
                            type: "text",
                            title: t(AdminPropertyI18NPrefix + "pdf.content.filename"),
                            divWidth: {xs: 12, sm: 6, md: 8, xxl: 10},
                          },
                          "selectedKeys": {
                            type: "multiselect",
                            title: <div className="d-flex align-items-center">
                              {t(AdminPropertyI18NPrefix + "pdf.key")}
                            </div>,
                            selectIsClearable: true,
                            selectOptions: PropertyListAvailableKeys.map(key => ({
                              label: t(AdminPropertyI18NPrefix + "pdf.keys." + key),
                              value: key
                            })),
                            multiSelectSortable: true,
                            valueOverride: pdfContentSelectedKeys,
                            onChangeOverride: (keys) => {
                              setPdfContentSelectedKeys(keys)
                            }
                          },
                        }}
                      />
                    </Collapsible>
                  )
                },
                
                "generate": {
                  type: "entireComponent",
                  component: <>
                    <ButtonWithLoader
                      loading={pdfLoading.flag}
                      faIcon={faFileExport}
                      variant="success"
                      disabled={!((editHistoryMode && pdfSelectedPropertyEditHistory) || pdfSelectedProperties)?.length}
                      hidden={false}
                      onClick={async() => {
                        const {language} = pdfContent;
                        const showTc = language != "en";
                        const showEn = language != "tc";

                        const filterLanguage = (tc: string, en: string, separator = "\n") => {
                          return [showTc && tc, showEn && en].filter(l => !!l).join(separator);
                        }

                        const dto: PropertyListPdfContent = {
                          ...pdfContent,
                          keys: Object.fromEntries(pdfContentSelectedKeys.map(key => {
                            const tc = t(AdminPropertyI18NPrefix + "pdf.keys." + key, {lng: "tc"});
                            const en = t(AdminPropertyI18NPrefix + "pdf.keys." + key, {lng: "en"});

                            return [
                              key, 
                              {
                                content: filterLanguage(tc, en),
                                star: PropertyListStarKeys.includes(key),
                                wholeRow: PropertyListWholeRowKeys.includes(key),
                                
                              }
                            ] 
                          })),
                          rows: ((editHistoryMode && pdfSelectedPropertyEditHistory) || pdfSelectedProperties).map((obj: PropertyEditHistory | Property) => Object.fromEntries(pdfContentSelectedKeys.map(key => {
                            let property: Property;
                            let editHistory: PropertyEditHistory;

                            if (!editHistoryMode) {
                              property = obj as Property;
                            } else {
                              editHistory = obj as PropertyEditHistory;
                              property = editHistory.property;
                            }
                            const basicInfo = editHistory?.basicInfo || property.basicInfo;
                            const priceStatus = editHistory?.priceStatus || property.priceStatus;
                            const landlord = editHistory?.landlord || property.landlord;
                            const detail = editHistory?.detail || property.detail;

                            const updated = editHistory?.updated || property?.updated;
                            const updatedBy = editHistory?.updatedBy || property?.updatedBy;

                            let content: string;
                            let alignment: "left" | "center" | "right" = null;
                            let color: string = null;

                            switch (key) {
                              case "name": 
                                const buildingShowBoth = buildingDisplay == 0;
                                content = [
                                  (buildingShowBoth || showTc) && basicInfo?.building?.nameTc,
                                  (buildingShowBoth || showEn) && basicInfo?.building?.nameEn
                                ].filter(str => !!str).join("\n");
                                // alignment = "left";
                              break;
                              case "block": 
                                content = basicInfo?.block;
                              break;
                              case "floor": 
                                content = "" + basicInfo?.floor;
                              break;
                              case "flat": 
                                content = "" + basicInfo?.flat;
                              break;
                              case "district": 
                                content = "" + basicInfo?.building?.district?.["name" + LangMap(language)]
                              break;
                              case "street":
                                content = StreetPreview(t, {
                                  streetName: basicInfo?.building?.["streetName" + LangMap(language)],
                                  streetNumber: basicInfo?.building?.streetNumber,
                                  lang: language
                                })
                              break;
                              case "area":
                                content = [
                                  basicInfo?.grossArea && (basicInfo?.grossArea + "' (G)"),
                                  basicInfo?.netArea && (basicInfo?.netArea + "' (N)"),
                                ].filter(a => !!a).join("\n")
                              break;
                              case "rooms": 
                                content = commify(basicInfo?.bedroom) ?? "";
                              break;
                              case "carparks":
                                content = [
                                  basicInfo?.coveredCarParkCount && (
                                    "" + basicInfo?.coveredCarParkCount + 
                                    (basicInfo?.coveredCarParkInfo ? ("\n" + basicInfo?.coveredCarParkInfo) : "")
                                  ),
                                  basicInfo?.uncoveredCarParkCount && (
                                    "" + basicInfo?.uncoveredCarParkCount + 
                                    (basicInfo?.uncoveredCarParkInfo ? ("\n" + basicInfo?.uncoveredCarParkInfo) : "")
                                  )
                                ].filter(str => !!str).join("\n")
                              break;
                              case "view":
                                content = filterLanguage(
                                  basicInfo?.view?.nameTc || basicInfo?.view?.nameEn, 
                                  basicInfo?.view?.nameEn
                                )
                              break;
                              case "price": 
                                content = [
                                  (pdfContent.priceInShort ? numberInShort(priceStatus?.price) : commify(priceStatus?.price)) ?? "",
                                  priceStatus.pricePerGrossArea && `G: $${commify(Math.round(priceStatus.pricePerGrossArea))}/ft`,
                                  priceStatus.pricePerNetArea && `N: $${commify(Math.round(priceStatus.pricePerNetArea))}/ft`
                                ].filter(str => !!str).join("\n");
                              break;
                              case "rent": 
                                content = [
                                  (pdfContent.priceInShort ? numberInShort(priceStatus?.rent) : commify(priceStatus?.rent)) ?? "",
                                  priceStatus.rentPerGrossArea && `G: $${commify(Math.round(priceStatus.rentPerGrossArea))}/ft`,
                                  priceStatus.rentPerNetArea && `N: $${commify(Math.round(priceStatus.rentPerNetArea))}/ft`
                                ].filter(str => !!str).join("\n");
                              break;
                              case "status": 
                                content = (priceStatus.status && filterLanguage(
                                  t(AdminPropertyI18NPrefix + "priceStatus.statuses." + priceStatus.status, {lng: "tc"}),
                                  t(AdminPropertyI18NPrefix + "priceStatus.statuses." + priceStatus.status, {lng: "en"})
                                )) || ""
                              break;
                              case "landlord":
                                content = landlord.name || "";
                              break;

                              case "contact": 
                                content = createNumberArray(1, PropertyLandlordContactCount).map(i => {
                                  if (landlord?.["contactName" + i] || 
                                    (landlord?.["contactInfo" + i]?.[0])
                                  ) {
                                    return [
                                      (landlord?.["contactName" + i] && `[${landlord?.["contactName" + i]}]`),
                                      ...(landlord?.["contactInfo" + i] || [])
                                    ].filter(str => !!str).join("\n")
                                  } else {
                                    return null;
                                  }
                                }).filter(str => !!str).join("\n")
                              break;
                              case "remarks":
                                content = priceStatus.pdfRemark;
                              break;
                              case "refNo":
                                content = "" + property.id;
                              break;
                              case "internalRemarks":
                                content = detail?.internalRemarks?.map(line => "• " + line).join("\n")
                              break;
                              case "lastUpdate":
                                content = filterLanguage(t('entityData.lastUpdate', {lng: "tc"}), t('entityData.lastUpdate', {lng: "en"}), " ")
                                  + `: ` + moment(updated).format("DD/MM/YYYY HH:mm:ss")
                                  + (updatedBy?.username ? ` [${updatedBy?.username}]` : "")
                                ;
                                alignment = "right";
                                color = "#8b8b8b";
                              break;
                            }
                            return [key, {content, alignment, color}]
                          })))
                        }

                        console.log(dto);
                        const {token, signal} = pdfLoading.start();
                        
                        try {
                          setPdfDownloadUrl(null);
                          const fileToken = await api.property.generateListPdf(dto, (event) => {
                            console.log(event);
                            // setGeneratePdfEvent(event);
                          }, {signal});
                          const filename = `${pdfContent.filename}.pdf`;
                          const url = api.pdfUtil.getPdfUrl(filename, fileToken);
                          // console.log(url);
                          const popup = window.open(url, "_blank");
                          
                          if (popup) {
                            popup.focus();
                          } else {
                            console.warn("Popup blocked");
                            downloadFromUrl(url, filename);
                          }
                          setPdfDownloadUrl(url);
                          
                        } catch (e) {
                          modal.errorSpecific(e);
                        }
                        // setGeneratePdfEvent(null);
                        pdfLoading.stop(token);
                      }}
                    >{t(AdminPropertyI18NPrefix + "pdf.generatePdf")}</ButtonWithLoader>
                  </>
                },
                pdfDownloadUrl: {
                  type: "component",
                  hidden: !pdfDownloadUrl,
                  component: (
                    <div className="text-center">
                      <b><a href={pdfDownloadUrl} target="_blank" className="text-info text-decoration-none">&gt;&gt; {t(AdminPropertyI18NPrefix + "pdf.autoOpenFailed")} &lt;&lt;</a></b>
                    </div>
                  )
                },
                generatePdfInfo: {
                  hidden: !pdfDownloadUrl,
                  type: "entireComponent",
                  component: <>
                    <div className="text-muted entity-data-item">
                      <div><FontAwesomeIcon icon={faCircleInfo} fixedWidth className="me-2"/>
                      {t(AdminPropertyI18NPrefix + "pdf.linkHints")}</div>
                    </div>
                  </>
                },
                
              }}
            />
          </div>
        </Collapsible>
        

        {
          pdfUtilOpen && (
            <div className="card px-3 py-2" style={{gap: "0.3em"}}>
              <div className="d-flex align-items-center">
                <b>{t(AdminPropertyI18NPrefix + "pdf.selectedRows", {count: 
                  (!editHistoryMode ? pdfContentSelectedPropertyIds: pdfContentSelectedPrpoertyHistoryIds).length
                })}</b>
                
                <ButtonWithLoader variant="danger" size="sm" className="ms-auto small p-0 px-2"
                  disabled={(!editHistoryMode ? pdfContentSelectedPropertyIds: pdfContentSelectedPrpoertyHistoryIds).length == 0}
                  onClick={async() => (await modal.confirmUnsaved()) && (
                    !editHistoryMode ? setPdfSelectedProperties([]) : setPdfSelectedPropertyEditHistory([])
                  )}
                >{
                  t("commons.clearAll")
                }</ButtonWithLoader>
              </div>
              <div>
                
                <ButtonWithLoader size="sm" className="ms-1 small p-0 px-2"
                  variant={pdfUtilPinSelected ? "success" : "outline-success"}
                  faIcon={faThumbTack}
                  onClick={() => setPdfUtilPinSelected(flag => !flag)}
                >{
                  t(AdminPropertyI18NPrefix + "pdf.pinSelected")
                }</ButtonWithLoader>
              </div>
            </div>
          )
        }
      

        
        <div>
          <AutocompleteSearch<any,any> 
            onFocus={() => {
              setHighlightedPropertyId(null);
            }}
            prefix={<>
              <FontAwesomeIcon icon={faGears} className={clsx("ms-2", settingsMore && "text-primary")} style={{cursor: "pointer"}} onClick={() => {
                setSettingsMore(flag => !flag);
              }} />
               
            </>}
            postfix={
              <div className="pe-3 ps-1 py-2"
                style={{cursor: "pointer"}}
                onClick={async() => {
                  if (pdfUtilOpen) {
                    await modal.confirmUnsaved() && setPdfUtilOpen(false);
                  } else {
                    setPdfUtilOpen(true)
                  }
                }}
              >
                <FontAwesomeIcon icon={faFilePdf} className={clsx(pdfUtilOpen && "text-primary")} size="lg" style={{cursor: "pointer"}} />
              </div>

              // <div className="me-2"
              //   style={{cursor: "pointer"}}
              //   onClick={() => setHistoryIncluded(flag => !flag)}
              //   data-for="includeHistory"
              //   data-tip={t(AdminPropertyI18NPrefix + "" + (!historyIncluded ? "includeHistory" : "excludeHistory"))}
              //   data-place="left"
              // >
              //   <FontAwesomeIcon 
              //     className={clsx("px-1", "text-primary")} 
              //     style={{opacity: historyIncluded ? 1 : 0.2 }}
              //     icon={faClockRotateLeft}
              //   />
              //   <ReactTooltip 
              //     id={"includeHistory"}
              //     type="dark" 
              //     effect="solid"
              //     // clickable
              //     html
              //     className="text-center"
                  
                  
              //   />
              //   {/* {
              //     historyIncluded && <span>{t(AdminPropertyI18NPrefix + "includeHistory")}</span>
              //   } */}
              // </div>
            }
            input={autoCompleteQueryRaw}
            placeholder={t(AdminPropertyI18NPrefix + "search")}
            loading={loading.flag || buildingsLoading.flag || streetNamesLoading.flag}
            options={
              autoCompleteQueryRaw ? [ 
                ...smartSearchNumbersOptions, ...smartSearchContactOptions,
                ...smartSearchDistricts, ...smartSearchBuildings, ...smartSearchStreetNames
              ].filter((item: any, index) => item?.score == null || item.score >= 0.2).sort((a: any, b: any) => {
                const aScore = a.score ?? 0.45;
                const bScore = b.score ?? 0.45;

                return bScore - aScore;
              }) : smartSearchHistories
            }
            onSelect={({value, type}) => {
              setAutoCompleteQueryRaw("");

              console.log({value, type});
              if (type == "search-history") {
                const searchHistory = {...value} as PropertySearchHistory;

                // HARD CODED
                if (!!searchHistory.filter["building.id"]) {
                  searchHistory.sort = {
                    "property.updated": "DESC", "property.starred": "DESC",
                    "basicInfo.flat": "ASC", "basicInfo.floor": "ASC", "basicInfo.block": "ASC"
                  }
                }
                setFindAllQuery(findAllQuery => ({
                  ...findAllQuery,
                  page: 1,
                  filter: searchHistory.filter,
                  sort: searchHistory.sort,
                }));
              } else if (type == "property.id") {
                setFindAllQuery(findAllQuery => ({
                  ...findAllQuery,
                  page: 1,
                  filter: {
                    ...findAllQuery.filter,
                    "property.id": entityFilterValuesSet(findAllQuery.filter?.["property.id"], "add", value, true)
                  }
                }));
              } else if (type == "priceStatus.status") {
                setFindAllQuery(findAllQuery => ({
                  ...findAllQuery,
                  page: 1,
                  filter: {
                    ...findAllQuery.filter,
                    "priceStatus.status": entityFilterValuesSet(findAllQuery.filter?.["priceStatus.status"], "add", value, true)
                  }
                }));
              } else if (type == "basicInfo.buildingType") {
                setFindAllQuery(findAllQuery => ({
                  ...findAllQuery,
                  page: 1,
                  filter: {
                    ...findAllQuery.filter,
                    "basicInfo.buildingType": entityFilterValuesSet(findAllQuery.filter?.["basicInfo.buildingType"], "add", value, true)
                  }
                }));
              } else if (type == "building.streetName") {

                const additionalFilter: Record<string, any> = {};

                // for (const lang of [LangMap(currentLang), "En", "Tc", "Sc"]) {
                //   if (value["streetName" + lang]) {
                //     additionalFilter["building.streetName" + lang]
                //   }
                // }
                
                setFindAllQuery(findAllQuery => ({
                  ...findAllQuery,
                  page: 1,
                  filter: {
                    ...findAllQuery.filter,
                    "building.streetName": createEntityFilter(
                      "contains", 
                      value["streetName" + LangMap(currentLang)] || value.streetNameEn || value.streetNameTc || value.streetNameSc
                    ),
                    ...(value.district && {
                      "building.district.id": createEntityFilter("exact",  value.district.id),
                    })
                    // "building.district.id": undefined,
                    
                  }
                }))
              } else if (type == "building") {
                setFindAllQuery(findAllQuery => ({
                  ...findAllQuery,
                  page: 1,
                  filter: {
                    ...findAllQuery.filter,
                    "building.id": entityFilterValuesSet(findAllQuery.filter?.["building.id"], "add", value.id, true),
                    // "building.district.id": undefined,
                    
                  },
                  sort: {
                    ...findAllQuery.sort,
                    "basicInfo.flat": "ASC", "basicInfo.floor": "ASC", "basicInfo.block": "ASC"
                  }
                }))
              } else if (type == "district") {
                setFindAllQuery(findAllQuery => ({
                  ...findAllQuery,
                  page: 1,
                  filter: {
                    ...findAllQuery.filter,
                    // "building.id": undefined,
                    "building.district.id": entityFilterValuesSet(findAllQuery.filter?.["building.district.id"], "add", value?.id, true),
                  },
                }))
              } else if (type == "basicInfo.grossArea" || type == "basicInfo.netArea") {
                setFindAllQuery(findAllQuery => ({
                  ...findAllQuery,
                  page: 1,
                  filter: {
                    ...findAllQuery.filter,
                    "basicInfo.grossArea": undefined,
                    "basicInfo.netArea": undefined,
                    [type]: createEntityFilter("range", value)
                  },
                  sort: {
                    ...findAllQuery.sort,
                    "basicInfo.grossArea": undefined,
                    "basicInfo.netArea": undefined,
                    [type]: findAllQuery.sort?.[type] || "ASC"
                  }
                }))
              } else if (type == "priceStatus.price" || type == "priceStatus.rent") {
                setFindAllQuery(findAllQuery => ({
                  ...findAllQuery,
                  page: 1,
                  filter: {
                    ...findAllQuery.filter,
                    "priceStatus.price": undefined,
                    "priceStatus.rent": undefined,
                    [type]: createEntityFilter("range", value)
                  },
                  sort: {
                    ...findAllQuery.sort,
                    "priceStatus.price": undefined,
                    "priceStatus.rent": undefined,
                    [type]: findAllQuery.sort?.[type] || "ASC"
                  }
                }))
              } else if (type?.startsWith("landlord.")) {
                const filterReset = {
                  "landlord.name": undefined,
                  "landlord.contactName": undefined,
                  "landlord.contactInfo": undefined
                }
                setFindAllQuery(findAllQuery => ({
                  ...findAllQuery,
                  page: 1,
                  filter: {
                    ...findAllQuery.filter,
                    "landlord.name": undefined,
                    "landlord.contactName": undefined,
                    "landlord.contactInfo": undefined,
                    [type]: createEntityFilter("contains", value),
                  },
                }))
              }
            }}
            onChange={async(query) => {
              setAutoCompleteQueryRaw(query);
              if (query.trim()) {
                const searchBuildings = async() => {
                  const {token, signal} = buildingsLoading.start();
                  try {
                    setBuildingsSearched(query.trim() ? (await api.buildings.findAll({
                      searchInput: query.trim(),
                      limit: 50
                    }))?.items : [])
                  } catch (e) {
  
                  }
                  buildingsLoading.stop(token);
                }
  
                const searchStreetNames = async() => {
                  const {token, signal} = streetNamesLoading.start();
                  try {
                    setStreetNamesSearched(query.trim() ? (await api.buildings.findAllStreetNames({
                      searchInput: query.trim(),
                      limit: 50
                    }))?.items : [])
                  } catch (e) {
  
                  }
                  streetNamesLoading.stop(token);
                }
  
                
                searchBuildings();
                searchStreetNames();
              }
              
            }}
            onSubmit={(input) => {
              if (input === "") {
                setFindAllQuery(q => ({...q}));
              }
            }}
          />
        </div>
        
        {
          filteredOptions && <div className="card d-flex flex-row flex-wrap py-1 px-2" style={{gap: "0.3em"}}>{filteredOptions}</div>
        }

        <Collapsible
          collapsed={!settingsMore}
          hideCollapsed
        >
          <div className="card py-2 px-3 d-flex flex-column" style={{gap: "0.5em"}}>
            <div className="d-flex w-100 align-items-center" style={{gap: "0.5em"}}>
              <FontAwesomeIcon icon={faTextWidth} size="lg" className="me-1" />
              <FormRange className="w-100" min={0.6} max={1.1} step={0.01} value={tableFontSize}
                onChange={(event) => {
                  setTableFontSize(event.target.value as unknown as number);
                }}
              />
              <ButtonWithLoader variant="warning" size="sm" className="ms-1"
                onClick={() => setTableFontSize(1)}
              >{t('commons.reset')}</ButtonWithLoader>
              <ButtonWithLoader variant="danger" size="sm"
                onClick={() => setSettingsMore(false)}
              >{t("commons.close")}</ButtonWithLoader>
            </div>
            
            <div className="d-flex w-100 align-items-center" style={{gap: "0.5em"}}>
              <GenericInput 
                type="switch"
                value={historyIncluded}
                onChange={flag => setHistoryIncluded(flag)}            
              />{t(AdminPropertyI18NPrefix + "includeHistory")}
              <GenericInput 
                className="ms-auto"
                type="switch"
                value={priceInShort}
                onChange={flag => setPriceInShort(flag)}            
              />{t(AdminPropertyI18NPrefix + "priceInShort")}
            </div>
            
          </div>
        </Collapsible>

        <div className="card px-3">
          <div className="d-flex align-items-center my-2  mx-sm-0" style={{gap: "0.5em", flexWrap: "wrap", position: "relative"}}>
            <div className="fst-italic text-black-50" style={{fontSize: "0.9em"}}>{
              (propertyEditHistoryPaginated || propertyPaginated) && (
                (propertyEditHistoryPaginated || propertyPaginated)?.isCached ? 
                  t('commons.cached') : 
                  t('commons.timeElapsed', {timeElapsed: roundToDp((propertyEditHistoryPaginated || propertyPaginated)?.timeElapsed, 2)})
              )
            }</div>
            {renderPaginationUI({style: {
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              whiteSpace: "nowrap",
              width: "100%"
            }})}
            <div className="fst-italic text-black-50 ms-auto" style={{fontSize: "0.9em"}}>{
              (propertyEditHistoryPaginated || propertyPaginated)?.meta?.totalItems
            }</div>
          </div>
        </div>

        
        <div className="card admin-table-card "
          ref={scrollTopRef}
          onScroll={(event) => {
            const {scrollTop, clientHeight, offsetHeight} = event.currentTarget;
            sessionCache.set({scrollY: scrollTop});
            // console.log({scrollTop, clientHeight, offsetHeight});
          }}
        >
          <div className="admin-table-scrollable">
            <AdminTable
              // ref={adminTableRef}
              className=""
              style={{fontSize: tableFontSizeDeferred + "em"}}
              styles={{
                // tbody: !!propertyEditHistoryPaginated ? {filter: "hue-rotate(240deg)"} : {}
              }}
              sticky
              // scrollable
              loading={loading.check()}
              onScrollToId={(id: string) => {
                setScrolledToId(id);
              }}
              onScrollToIdDelay={100}
              disableSort={editHistoryMode}
              keys={{
                selector: {
                  noLink: true,
                  content: <div>
                      <div className="w-100 h-100"
                        style={{position: "absolute", top: 0, left: 0, cursor: "pointer", zIndex: 2}}
                        onClick={() => {
                          if (!editHistoryMode) {
                            const allSelected = _.intersection(pdfContentSelectedPropertyIds, propertyPaginated?.items?.map(i => i.id)).length == propertyPaginated?.items?.length;
                            if (!allSelected) {
                              setPdfSelectedProperties(properties => {
                                const newProperties = [...properties];
                                propertyPaginated?.items?.forEach(p => {
                                  if (pdfContentSelectedPropertyIds.indexOf(p.id) == -1) {
                                    newProperties.push(p)
                                  }
                                })
                                return newProperties;
                              })
                            } else {
                              setPdfSelectedProperties([]);
                            }
                            
                          } else {
                            setPdfSelectedPropertyEditHistory(items => {
                              const newEditHistory = [...items];
                              propertyEditHistoryPaginated?.items?.forEach(i => {
                                if (pdfContentSelectedPrpoertyHistoryIds.indexOf(i.compositeId) == -1) {
                                  newEditHistory.push(i)
                                }
                              })
                              return newEditHistory;
                            })
                          }
                          
                          
                        }}
                      />
                      <div 
                        className="d-flex align-items-center justify-content-center"
                      >
                      <GenericInput type="switch" value={
                        !editHistoryMode ? 
                          _.intersection(pdfContentSelectedPropertyIds, propertyPaginated?.items?.map(i => i.id)).length == propertyPaginated?.items?.length :
                          _.intersection(pdfContentSelectedPrpoertyHistoryIds, propertyEditHistoryPaginated?.items?.map(i => i.compositeId)).length == propertyEditHistoryPaginated?.items?.length
                      }/>
                    </div>
                  </div>,
                  hidden: !pdfUtilOpen
                },
                "property.starred": {
                  content: <AdminPropertyStar className="py-0 px-1 clickable" starred={!!findAllQuery.sort?.["property.starred"]} size="lg" 
                    onUpdate={async() => {
                      const starSorted = !!findAllQuery.sort?.["property.starred"];
                      addSort({"property.starred": starSorted ? null : "DESC"})
                    }}
                  />,
                  headerHighlighted: !!findAllQuery.sort?.["property.starred"],
                  shrink: true,
                  noLink: true
                },
                "flag": {
                  content: "",
                  shrink: true,
                },
                "noPhone": {
                  content: "",
                  shrink: true,
                },
                "property.id": {
                  content: tableKeyDisplay["property.id"],
                  shrink: true,
                  // headerHighlighted: !!findAllQuery?.sort?.["id"],
                  sort: {
                    type: findAllQuery?.sort?.["property.id"],
                    descFirst: true,
                    onChange: (newType) => addSort({"property.id": newType})
                  }
                },
                "publishment.state": {
                  content: <div className="dropdown">
                    <div className="dropdown-toggle d-flex align-items-center justify-content-center"
                      data-bs-toggle="dropdown" aria-expanded="false"
                      style={{cursor: "pointer"}}
                    >
                      {tableKeyDisplay["publishment.state"]}
                    </div>
                    <ul className="dropdown-menu">
                    {
                      [...PropertyPublishmentStates].reverse().map(state => (
                        <div key={state} className="dropdown-item"
                          onClick={() => {
                            setFindAllQuery(findAllQuery => ({
                              ...findAllQuery,
                              page: 1,
                              filter: {
                                ...findAllQuery.filter,
                                "publishment.state": entityFilterValuesSet(findAllQuery.filter?.["publishment.state"], "add", state)
                              }
                            }));
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <PublishmentStateDisplay state={state} className="me-2"/> {t(AdminPropertyI18NPrefix + "publishment.states." + state)}  
                          </div>
                        </div>
                      ))   
                    }
                      <div className="dropdown-item"
                        onClick={() => {
                          setFindAllQuery(findAllQuery => ({
                            ...findAllQuery,
                            page: 1,
                            filter: {
                              ...findAllQuery.filter,
                              "publishment.highlighted": createEntityFilter("exact", "1"),
                              "publishment.highlighted2": null,
                            },
                          }));
                        }}
                      >
                        <div className="d-flex align-items-center">
                          {t(AdminPropertyI18NPrefix + 'publishment.content.highlighted')}
                        </div>
                      </div>
                      <div className="dropdown-item"
                        onClick={() => {
                          setFindAllQuery(findAllQuery => ({
                            ...findAllQuery,
                            page: 1,
                            filter: {
                              ...findAllQuery.filter,
                              "publishment.highlighted": null,
                              "publishment.highlighted2": createEntityFilter("exact", "1"),
                            },
                          }));
                        }}
                      >
                        <div className="d-flex align-items-center">
                          {t(AdminPropertyI18NPrefix + 'publishment.content.highlighted2')}
                        </div>
                      </div>
                      
                    
                    </ul>
                  </div>,
                  shrink: true,
                  noLink: true,
                },
                "updatedBy": {
                  hidden: true,
                  content: tableKeyDisplay["updatedBy"],
                },
                "updated": {
                  content: tableKeyDisplay["updated"],
                  sort: {
                    type: findAllQuery?.sort?.["property.updated"],
                    descFirst: true,
                    onChange: (newType) => {addSort({"property.updated": newType})}
                  }
                },
                
                "building.id": {
                  sticky: buildingLocked,
                  content: <div style={{cursor: "pointer"}} onClick={() => setBuildingDisplay(flag => (flag + 1) % 3)}>{tableKeyDisplay["building.id"]}
                    <FontAwesomeIcon icon={faLanguage} 
                      className="text-info ms-1"
                      size="lg"
                      fixedWidth
                    />
                    <FontAwesomeIcon 
                      icon={buildingLocked ? faLock : faLockOpen} 
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setBuildingLocked(flag => !flag)
                      }}
                      className={clsx(
                        "ms-2",
                        buildingLocked ? "text-warning" : "text-black-50"
                      )}
                      size="lg"
                      fixedWidth
                    />
                  </div>,
                  
                },
                "building.district.id": {
                  content: tableKeyDisplay["building.district.id"],
                  shrink: true,
                },
                "building.streetName": {
                  hidden: true,
                  content: tableKeyDisplay["building.streetName"],
                  shrink: true,
                },
                "basicInfo.block": {
                  content: tableKeyDisplay["basicInfo.block"],
                  shrink: true,
                  sort: {
                    type: findAllQuery?.sort?.["basicInfo.block"],
                    onChange: (newType) => {
                      if (newType && !findAllQuery?.sort?.["basicInfo.floor"] && !findAllQuery?.sort?.["basicInfo.flat"]) {
                        addSort({"basicInfo.flat": "ASC", "basicInfo.floor": "ASC"})
                      }
                      addSort({"basicInfo.block": newType})
                      
                    }
                  }
                },
                "basicInfo.floor": {
                  content: tableKeyDisplay["basicInfo.floor"],
                  shrink: true,
                  sort: {
                    type: findAllQuery?.sort?.["basicInfo.floor"],
                    onChange: (newType) => {
                      if (newType && !findAllQuery?.sort?.["basicInfo.flat"]) {
                        addSort({"basicInfo.flat": "ASC"})
                      }
                      addSort({"basicInfo.floor": newType})
                      
                    }
                  }
                },
                "basicInfo.flat": {
                  content: tableKeyDisplay["basicInfo.flat"],
                  shrink: true,
                  sort: {
                    type: findAllQuery?.sort?.["basicInfo.flat"],
                    onChange: (newType) => {addSort({"basicInfo.flat": newType})}
                  }
                },
                "basicInfo.area": {
                  content: tableKeyDisplay["basicInfo.area"],
                  shrink: true,
                  sort: {
                    type: findAllQuery?.sort?.["basicInfo.grossArea"],
                    onChange: (newType) => {addSort({"basicInfo.grossArea": newType})}
                  },
                },
                "basicInfo.grossArea": {
                  hidden: true,
                  content: tableKeyDisplay["basicInfo.grossArea"],
                  shrink: true,
                  sort: {
                    type: findAllQuery?.sort?.["basicInfo.grossArea"],
                    onChange: (newType) => {addSort({"basicInfo.grossArea": newType})}
                  },
                },
                "basicInfo.netArea": {
                  hidden: true,
                  content: tableKeyDisplay["basicInfo.netArea"],
                  shrink: true,
                  sort: {
                    type: findAllQuery?.sort?.["basicInfo.netArea"],
                    onChange: (newType) => {addSort({"basicInfo.netArea": newType})}
                  },
                },

                
                
                "priceStatus.price": {
                  content: tableKeyDisplay["priceStatus.price"],
                  shrink: true,
                  sort: {
                    type: findAllQuery?.sort?.["priceStatus.price"],
                    onChange: (newType) => {addSort({"priceStatus.price": newType})}
                  }
                },
                "priceStatus.rent": {
                  content: tableKeyDisplay["priceStatus.rent"],
                  shrink: true,
                  sort: {
                    type: findAllQuery?.sort?.["priceStatus.rent"],
                    onChange: (newType) => {addSort({"priceStatus.rent": newType})}
                  },
                },

                "priceStatus.status": {
                  content: <div className="dropdown">
                    <div className="dropdown-toggle d-flex align-items-center justify-content-center"
                      data-bs-toggle="dropdown" aria-expanded="false"
                      style={{cursor: "pointer"}}
                    >
                      {t(AdminPropertyI18NPrefix + "priceStatus.status")}
                    </div>
                    <ul className="dropdown-menu">{
                      PropertyStatuses.map(status => (
                        <div key={status} className="dropdown-item"
                          onClick={() => {
                            setFindAllQuery(findAllQuery => ({
                              ...findAllQuery,
                              page: 1,
                              filter: {
                                ...findAllQuery.filter,
                                "priceStatus.status": entityFilterValuesSet(findAllQuery.filter?.["priceStatus.status"], "add", status)
                              }
                            }));
                          }}
                        >{t(AdminPropertyI18NPrefix + "priceStatus.statuses." + status)}</div>
                      ))   
                    }</ul>
                  </div>,
                  shrink: true
                },

                "basicInfo.buildingType": {
                  content: <div className="dropdown">
                    <div className="dropdown-toggle d-flex align-items-center justify-content-center"
                      data-bs-toggle="dropdown" aria-expanded="false"
                      style={{cursor: "pointer"}}
                    >
                      {tableKeyDisplay["basicInfo.buildingType"]}
                    </div>
                    <ul className="dropdown-menu">{
                      BuildingTypes.map(type => (
                        <div className="dropdown-item"
                          key={type}
                          onClick={() => {
                            setFindAllQuery(findAllQuery => ({
                              ...findAllQuery,
                              page: 1,
                              filter: {
                                ...findAllQuery.filter,
                                "basicInfo.buildingType": entityFilterValuesSet(findAllQuery.filter?.["basicInfo.buildingType"], "add", type)
                              }
                            }));
                          }}
                        >{t(AdminBuildingI18NPrefix + "type." + type)}</div>
                      ))   
                    }</ul>
                  </div>,
                  shrink: true,
                },
                

                "detail.internalRemarks": {
                  content: <div style={{cursor: "pointer"}} onClick={() => setInternalRemarksCollapsed(hidden => !hidden)}>{
                    tableKeyDisplay["detail.internalRemarks"]
                  }
                    <FontAwesomeIcon icon={internalRemarksCollapsed ? faSquarePlus : faSquareMinus} 
                      className="text-info ms-1"
                      
                      fixedWidth
                    />
                  </div>,
                  shrink: true,
                  noLink: true,
                },

                "landlord.name": {
                  content: <>{tableKeyDisplay["landlord.name"]}</>,
                  shrink: true,
                },
                "landlord.contact": {
                  content: <div style={{cursor: "pointer"}} onClick={() => setContactCollapsed(hidden => !hidden)}>{tableKeyDisplay["landlord.contact"]}
                    <FontAwesomeIcon icon={contactCollapsed ? faSquarePlus : faSquareMinus} 
                      className="text-info ms-1"
                      
                      fixedWidth
                    />
                  </div>,
                  shrink: true,
                  noLink: true,
                },

                "priceStatus.pdfRemark": {
                  content: tableKeyDisplay["priceStatus.pdfRemark"]
                },

                "basicInfo.view": {
                  content: tableKeyDisplay["basicInfo.view"]
                },
                "basicInfo.rooms": {
                  content: tableKeyDisplay["basicInfo.rooms"]
                },
                "basicInfo.carparks": {
                  content: tableKeyDisplay["basicInfo.carparks"]
                },
                
                

                "createdBy": {
                  content: tableKeyDisplay["createdBy"],
                },
                "created": {
                  content: tableKeyDisplay["created"],
                  sort: {
                    type: findAllQuery?.sort?.["property.created"],
                    descFirst: true,
                    onChange: (newType) => {addSort({"property.created": newType})}
                  }
                }
              }}
              rows={((editHistoryMode && propertyEditHistory) || properties)?.map((obj: PropertyEditHistory | Property, index: number) => {
                // console.log(obj);

                let property: Property;
                let editHistory: PropertyEditHistory;
                let isLatest = false;
                let isNew = false;
                let sectionChanged = {
                  basicInfo: false,
                  priceStatus: false,
                  landlord: false,
                  detail: false
                };

                if (editHistoryMode) {
                  editHistory = obj as PropertyEditHistory;
                  property = editHistory.property;

                  sectionChanged.basicInfo = !!editHistory?.basicInfo?.id;
                  sectionChanged.priceStatus = !!editHistory?.priceStatus?.id;
                  sectionChanged.landlord = !!editHistory?.landlord?.id;
                  sectionChanged.detail = !!editHistory?.detail?.id;

                  if (
                    // TODO: don't use round up later
                    Math.abs(+new Date(editHistory.updated) - +new Date(property.updated)) < 1500
                  ) {
                    isLatest = true;
                  }

                  if (
                    // TODO: don't use round up later
                    Math.abs(+new Date(editHistory.updated) - +new Date(property.created)) < 1500
                  ) {
                    isNew = true;
                  }

                } else {
                  property = obj as Property;
                }

                const renderOrange = (flag: boolean) => (flag && {style: {backgroundColor: CellOrangeColor}})
                const renderYellow = (flag: boolean) => (flag && {style: {backgroundColor: CellYellowColor}})
                const renderGreen = (flag: boolean) => (flag && {style: {backgroundColor: CellGreenColor}})

                
                // const {basicInfo, priceStatus, landlord, detail} = property;

                const basicInfo = editHistory?.basicInfo || property.basicInfo;
                const priceStatus = editHistory?.priceStatus || property.priceStatus;
                const landlord = editHistory?.landlord || property.landlord;
                const detail = editHistory?.detail || property.detail;

                const {building} = basicInfo;
                const listIndex = pdfUtilOpen && !editHistoryMode && (
                  pdfContentSelectedPropertyIds.indexOf(property.id)
                );
                const listIndexIsFirst = listIndex === 0;
                const listIndexIsLast = listIndex === pdfContentSelectedPropertyIds.length - 1;

                const selected = pdfUtilOpen ? (
                  !editHistoryMode ? 
                    pdfContentSelectedPropertyIds.includes(property.id) : 
                    pdfContentSelectedPrpoertyHistoryIds.includes(editHistory?.compositeId)
                ) : (highlightedPropertyId == property.id);
                const key = !editHistoryMode ? "" + property.id : editHistory?.compositeId;

                const noPhone = createNumberArray(1, PropertyLandlordContactCount).map(i => {
                  const contactInfo = landlord?.["contactInfo" + i] as typeof landlord["contactInfo1"];
                  const noPhone = contactInfo.length == 0;
                  return noPhone;
                }).every(flag => flag);

                // const readHistoryFlag = readHistory?.flags?.[property.id];

                return {
                  rowLink: `../${property.id}`,
                  onRowLinkClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                    // if (event.ctrlKey || event.metaKey) {
                      
                    // } else {
                    //   event.preventDefault();
                    //   setOpenedPropertyId(property.id);
                    // }
                    setHighlightedPropertyId(property.id);

                    if (setOpenedPropertyId) {
                      event.preventDefault();
                      setOpenPropertyGoToPublishment(false);
                      setOpenedPropertyId(property.id);
                    } else {
                      sessionCache.set({highlightedPropertyId: property.id})
                    }
                  },
                  // onClick: pdfUtilOpen ? () => {
                  //   if (!selected) {
                  //     if (!editHistoryMode) {
                  //       setPdfSelectedProperties(arr => ([...arr, property]))
                  //     } else {
                  //       setPdfSelectedPropertyEditHistory(arr => ([...arr, editHistory]))
                  //     }
                  //   } else {
                  //     if (!editHistoryMode) {
                  //       setPdfSelectedProperties(arr => arr.filter(p => p.id != property.id))
                  //     } else {
                  //       setPdfSelectedPropertyEditHistory(arr => arr.filter(p => p.compositeId != editHistory.compositeId))
                  //     }
                  //   }
                  // } : null,
                  
                  selected: selected,
                  id: key,
                  key,
                  content: {
                    selector: <div className="d-flex align-items-center" style={{gap: "0.5em"}}>
                      {/* <div className="w-100 h-100"
                        style={{position: "absolute", top: 0, left: 0, cursor: "pointer", zIndex: 2}}
                        onClick={() => {
                          
                        }}
                      /> */}
                      <div 
                        className="d-flex align-items-center justify-content-center"
                      >
                        {
                          selected && (
                            <span className="text-success" style={{position: "absolute", zIndex: 1, marginLeft: 16, fontSize: "0.8em", pointerEvents: "none"}}>{
                              (!editHistoryMode ? 
                                listIndex :
                                pdfContentSelectedPrpoertyHistoryIds.indexOf(editHistory.compositeId)
                              ) + 1
                            }</span>
                          )
                        }
                        
                        <GenericInput type="switch" value={selected} 
                          onChange={() => {
                            if (!selected) {
                              if (!editHistoryMode) {
                                setPdfSelectedProperties(arr => ([...arr, property]))
                              } else {
                                setPdfSelectedPropertyEditHistory(arr => ([...arr, editHistory]))
                              }
                            } else {
                              if (!editHistoryMode) {
                                setPdfSelectedProperties(arr => arr.filter(p => p.id != property.id))
                              } else {
                                setPdfSelectedPropertyEditHistory(arr => arr.filter(p => p.compositeId != editHistory.compositeId))
                              }
                            }
                          }}
                        />
                      </div>
                      {
                        pdfUtilOpen && !editHistoryMode && selected && <>
                          <FontAwesomeIcon icon={faCircleUp} size="lg" style={{
                              cursor: "pointer",
                              opacity: !listIndexIsFirst ? 1 : 0.3,
                              pointerEvents: listIndexIsFirst ? "none" : null
                            }} 
                            onClick={() => {
                              let arr = [...pdfSelectedProperties];
                              [arr[index - 1], arr[index]] = [arr[index], arr[index - 1]];
                              setPdfSelectedProperties(arr);
                            }}
                          />
                          <FontAwesomeIcon icon={faCircleDown} size="lg" style={{
                              cursor: "pointer",
                              opacity: !listIndexIsLast ? 1 : 0.3,
                              pointerEvents: listIndexIsLast ? "none" : null
                            }} 
                            onClick={() => {
                              let arr = [...pdfSelectedProperties];
                              [arr[index], arr[index + 1]] = [arr[index + 1], arr[index]];
                              setPdfSelectedProperties(arr);
                            }}
                          />
                        </>
                      }
                    </div>,
                    "property.starred": {
                      content: <AdminPropertyStar className="py-2 px-1 clickable" starred={property.starred} size="lg" 
                        onUpdate={async() => {
                          try {
                            const updatedProperty = await api.property.updateStar(property.id, !property.starred);
                            console.log({updatedProperty});
                            updateProperty(property.id, updatedProperty)
                          } catch (e) {
                            modal.errorSpecific(e);
                          }
                        }}
                      />,
                    },
                    "flag": {
                      content: <AdminPropertyFlag updated={property.updated} />
                    },
                    "noPhone": {
                      content: <>
                        {noPhone && <FontAwesomeIcon icon={faPhoneSlash} className="text-danger" />}
                      </>
                    },
                    "property.id": {
                      // cellProps: editHistoryMode && (renderGreen(isLatest)),
                      content: <div className="d-flex align-items-center justify-content-center" style={{gap:"0.3em"}}>
                        <span>{property.id}</span>
                      </div>
                    },
                    "publishment.state": {
                      // content: <Link to={"../" + property.id + "?go-to-publishment"}
                      //   style={{textDecoration: "none"}}
                      //   onClick={(event) => {
                      //     setHighlightedPropertyId(property.id);

                      //     if (setOpenedPropertyId) {
                      //       event.preventDefault();
                      //       setOpenPropertyGoToPublishment(true);
                      //       setOpenedPropertyId(property.id);
                      //     } else {
                      //       sessionCache.set({highlightedPropertyId: property.id})
                      //     }
                      //   }}
                      // >
                      //   <PublishmentStateDisplay state={property?.publishment?.state} />
                      // </Link>,
                      content: <div className="d-flex flex-column align-items-center justify-content-center">
                        <div style={{cursor: "pointer"}} className="d-flex align-items-center justify-content-center py-1 px-2" onClick={async() => {
                          const isPublishmentEditor = userHasAdminRoles(adminUser, ["PropertyPublishmentPublisherEditor"]);
                          const publishedLink = property?.publicClone?.publishment?.state === "published" ? `${process.env.REACT_APP_PUBLIC_URL}/property/${property.id}` : null;
                          const state = property?.publishment?.state;
                          const linkTo = "../" + property.id + "?go-to-publishment";

                          let goToPropertyId: Property["id"] = null;
                          if (!state || state == "pending" || state == "unpublished") {
                            // navigate(linkTo);
                            goToPropertyId = property.id;
                          } else {
                            let confirmWording = "";
                            switch (state) {
                              case "published": 
                                confirmWording = t(AdminPropertyI18NPrefix+"publishment.stateUpdate.unpublish");
                              break;
                            }
                            const result = await modal.confirm(
                              "Property", 
                              <div>
                                <div className="d-inline-flex">
                                  <PublishmentStateDisplay state={state} className="me-2" />
                                  {t(AdminPropertyI18NPrefix+"publishment.stateUpdate." + state + ".description")}
                                </div>
                                {
                                  publishedLink && <div>
                                    <FontAwesomeIcon icon={faLink} className="me-2" />
                                    {t(AdminPropertyI18NPrefix + "publishment.publishedLink")}{`: `}
                                    <a href={publishedLink} target="_blank">{publishedLink}</a>
                                  </div>
                                }
                              </div>
                              , 
                              true, 
                              userCanPublishPublishment ? t(AdminPropertyI18NPrefix+"publishment.stateUpdate." + state + ".button") : null, 
                              t(AdminPropertyI18NPrefix+"publishment.stateUpdate.checkProperty")
                            );
                            if (result === false) {
                              // navigate(linkTo);
                              goToPropertyId = property.id;
                            } else if (result === true) {
                              switch (state) {
                                case "published": 
                                  const token = loading.start();
                                  try {
                                    const updatedProperty = await api.property.updatePublishmentState(property.id, "unpublished");
                                    updateProperty(property.id, updatedProperty);
                                  } catch (e) {
                                    modal.errorSpecific(e);
                                  }
                                  loading.stop(token);
                                break;
                              }
                            }

                            
                          }

                          console.log({goToPropertyId})
                          if (goToPropertyId != null) {
                            setHighlightedPropertyId(goToPropertyId);
                            if (setOpenedPropertyId) {
                              setOpenPropertyGoToPublishment(true);
                              setOpenedPropertyId(goToPropertyId);
                            }
                          }
                          
                        }}>
                          {
                            property?.publicClone?.publishment?.state !== property?.publishment?.state && property?.publicClone?.publishment?.state == "published" && (
                              <PublishmentStateDisplay state={property?.publicClone?.publishment?.state} className="me-1" />
                            )
                          }
                          <PublishmentStateDisplay state={property?.publishment?.state} />
                        </div>
                        {
                          ["published", "pending"].includes(property?.publishment?.state) && property?.publishment?.photos?.length == 0 && (
                            <div className="d-flex align-items-center text-danger">
                              <FontAwesomeIcon icon={faExclamationTriangle} size="lg" /> No Img
                            </div>
                          )
                        }
                        {
                          (property?.publishment?.highlightedDate || property?.publishment?.highlighted2Date) && (
                            <div className="d-flex align-items-center" style={{gap: "0.3em"}}>
                              {
                                property?.publishment?.highlightedDate && 
                                <div className="text-success">{t(AdminPropertyI18NPrefix + 'publishment.content.highlightedShort')}</div>
                              }
                              {
                                property?.publishment?.highlighted2Date && 
                                <div className="text-success">{t(AdminPropertyI18NPrefix + 'publishment.content.highlighted2Short')}</div>
                              }
                            </div>
                          )
                        }
                      </div>
                    },
                    
                    "updatedBy": {
                      cellProps: editHistoryMode && (renderGreen(isLatest)),
                      content: editHistory?.updatedBy?.username || property.updatedBy?.username || <NullDisplay />
                    },
                    "updated": {
                      cellProps: editHistoryMode && (renderGreen(isLatest)),
                      content: [
                        editHistory?.updated && moment(editHistory.updated).format("DD/MM/YYYY") ||
                        property.updated && moment(property.updated).format("DD/MM/YYYY"),
                        editHistory?.updated && moment(editHistory.updated).format("HH:mm:ss") ||
                        property.updated && moment(property.updated).format("HH:mm:ss"),
                        editHistory?.updatedBy?.username || property.updatedBy?.username
                      ].filter(str => !!str).map((str, index) => {
                        return <div>{str}</div>
                      }) || <NullDisplay />
                    },
                    "building.id": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.basicInfo)),
                      content: <div className="d-flex align-items-center justify-content-center w-100 h-100"
                        onClick={e => {}}
                      >
                        <div className="d-flex flex-column align-items-center justify-content-center">
                          {buildingDisplay != 2 && <div>{building?.nameTc || <NullDisplay />}</div>}
                          {buildingDisplay != 1 && <div>{building?.nameEn || <NullDisplay />}</div>}
                          {
                            false && building?.id && <>
                              {
                                !(
                                  findAllQuery?.filter?.["building.id"]?.value == building?.id || 
                                  findAllQuery?.filter?.["building.id"]?.value?.include?.(building?.id) ||
                                  buildingLocked || 
                                  editHistoryMode
                                ) && (
                                  <FontAwesomeIcon icon={faSearchengin} className="text-muted ms-2 icon-link px-1 py-1" size="lg" 
                                    style={{zIndex: 2}}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setFindAllQuery(query => ({
                                        ...query,
                                        page: 1,
                                        filter: {
                                          ...query.filter,
                                          "building.id": createEntityFilter("exact", building?.id),
                                        },
                                        sort: {
                                          ...findAllQuery.sort,
                                          "basicInfo.flat": "ASC", "basicInfo.floor": "ASC", "basicInfo.block": "ASC"
                                        }
                                      }))
                                    }}
                                  />
                                )
                              }
                            </>
                          }
                        </div>
                      </div>
                    },
                    "building.district.id": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.basicInfo)),
                      content: [
                        basicInfo?.building?.district?.["name" + LangMap(currentLang)],
                        basicInfo?.building?.["streetName" + LangMap(currentLang)]
                      ].filter(str => !!str).map((str, index) => {
                        return <div key={index}>{str}</div>
                      })
                    },
                    "building.streetName": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.basicInfo)),
                      content: basicInfo?.building?.["streetName" + LangMap(currentLang)]
                    },
                    "basicInfo.block": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.basicInfo)),
                      content: basicInfo?.block?.toString() || <NullDisplay />
                    },
                    "basicInfo.floor": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.basicInfo)),
                      content: basicInfo?.floor?.toString() || <NullDisplay />
                    },
                    "basicInfo.flat": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.basicInfo)),
                      content: basicInfo?.flat?.toString() || <NullDisplay />
                    },
                    
                    "basicInfo.grossArea": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.basicInfo)),
                      content: basicInfo?.grossArea != null && (commify(basicInfo?.grossArea)?.toString() + "'") || <NullDisplay />,
                    },
                    "basicInfo.netArea": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.basicInfo)),
                      content: basicInfo?.netArea != null && (commify(basicInfo?.netArea)?.toString() + "'") || <NullDisplay />,
                    },
                    "basicInfo.area": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.basicInfo)),
                      content: [
                        (priceStatus?.price != null || priceStatus?.rent != null) && '　',
                        basicInfo?.grossArea != null && `${t(AdminPropertyI18NPrefix + "basicInfo.grossAreaVeryShort")} ${commify(basicInfo?.grossArea)}'`,
                        basicInfo?.netArea != null && `${t(AdminPropertyI18NPrefix + "basicInfo.netAreaVeryShort")} ${commify(basicInfo?.netArea)}'`
                      ].filter(str => !!str).map((str, index) => {
                        return <div key={index}>{str}</div>
                      }),
                    },
                    "priceStatus.price": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.priceStatus)),
                      content: [
                        priceStatus?.price != null ? (priceInShort ? `$${numberInShort(priceStatus?.price)}` : `$${commify(priceStatus?.price)?.toString()}`) : priceStatus?.rent != null && '　',
                        priceStatus?.pricePerGrossArea ? `@$${commify(Math.round(priceStatus?.pricePerGrossArea))}` : basicInfo?.grossArea != null && '　',
                        priceStatus?.pricePerNetArea ? `@$${commify(Math.round(priceStatus?.pricePerNetArea))}` : basicInfo?.netArea != null && '　',
                      ].filter(str => !!str).map((str, index) => {
                        return <div key={index}>{str}</div>
                      }) || <NullDisplay />,
                    },
                    "priceStatus.rent": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.priceStatus)),
                      content: [
                        priceStatus?.rent != null ? (priceInShort ? `$${numberInShort(priceStatus?.rent)}` : `$${commify(priceStatus?.rent)?.toString()}`) : priceStatus?.price != null && '　',
                        priceStatus?.rentPerGrossArea ? `@$${commify(Math.round(priceStatus?.rentPerGrossArea))}` : basicInfo?.grossArea != null && '　',
                        priceStatus?.rentPerNetArea ? `@$${commify(Math.round(priceStatus?.rentPerNetArea))}` : basicInfo?.netArea != null && '　',
                      ].filter(str => !!str).map((str, index) => {
                        return <div key={index}>{str}</div>
                      }) || <NullDisplay />,
                    },
                    "priceStatus.status": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.priceStatus)),
                      content: (priceStatus?.status && t(AdminPropertyI18NPrefix + "priceStatus.statuses." + priceStatus?.status)) || <NullDisplay />,
                    },
                    "basicInfo.buildingType": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.basicInfo)),
                      content: basicInfo?.buildingType ? t(`${AdminBuildingI18NPrefix}type.${basicInfo?.buildingType}`) : <NullDisplay />,
                    },
                    "detail.internalRemarks": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.detail)),
                      content: (<div className="d-flex align-items-center ">{
                        detail?.internalRemarks?.length ? (
                          <ul className="m-0">{
                            detail?.internalRemarks?.map((str, index, arr) => {
                              if (internalRemarksCollapsed && index > 0) {
                                return null;
                              } else {
                                return <li key={index} className="text-start">{str}{(internalRemarksCollapsed && arr.length > 1) ? "....." : ""}</li>
                              }
                            })
                          }</ul>
                        ) : <NullDisplay />
                      }</div>),
                    },
                    "landlord.name": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.landlord)),
                      content: landlord?.name || <NullDisplay />,
                    },
                    "landlord.contact": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.landlord)),
                      content: <LandlordContactsPreview t={t} brief={contactCollapsed} landlord={landlord} style={{zIndex: 2}} onClick={e=>{e.stopPropagation(); e.preventDefault()}}/>,
                    },
                    "priceStatus.pdfRemark": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.priceStatus)),
                      content: priceStatus?.pdfRemark?.split("\n").join(" ") || <NullDisplay />,
                    },
                    "basicInfo.view": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.basicInfo)),
                      content: basicInfo?.view?.["name" + LangMap(currentLang)] || basicInfo?.view?.["nameEn"] || <NullDisplay />,
                    },
                    "basicInfo.rooms": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.basicInfo)),
                      content: basicInfo?.bedroom != null ? basicInfo?.bedroom : <NullDisplay />,
                    },
                    "basicInfo.carparks": {
                      cellProps: editHistoryMode && (renderYellow(isNew) || renderOrange(sectionChanged.basicInfo)),
                      content: PropertyBasicInfoCarparksPreview(t, {
                        coveredCarParkCount: basicInfo?.coveredCarParkCount,
                        coveredCarParkInfo: basicInfo?.coveredCarParkInfo,
                        uncoveredCarParkCount: basicInfo?.uncoveredCarParkCount,
                        uncoveredCarParkInfo: basicInfo?.uncoveredCarParkInfo,
                      }) || <NullDisplay />,
                    },
                    
                    "createdBy": property.createdBy?.username || <NullDisplay />,
                    "created": property.created && moment(property.created).format("DD/MM/YYYY HH:mm:ss") || <NullDisplay />
                  }
                }
              })}

            />

            
          </div>
          
        </div>

          
      </>
    )
  }

export const AdminProperties = () => {
    const {t} = useI18N();
    const [resetFlag, setResetFlag] = useState(0);
    const navigate = useNavigate();
    const {pathname, search, hash} = useLocation();

    const [editHistoryMode, setEditHistoryMode] = useState(false);
    const [isInit, setIsInit] = useState(false);

    const location = useLocation();

    const [openedPropertyId, setOpenedPropertyId] = useState<Property["id"]>(null);
    const [openPropertyGoToPublishment, setOpenPropertyGoToPublishment] = useState(false);

    const [reloadReadHistoryKey, setReloadReadHistoryKey] = useState(0);

    const [propertiesRef, setPropertiesRef] = useState<AdminPropertiesComponentRef>(null);

    const originalLocationRef = useRef(window.location);
  
    const propertiesRefFn = useCallback((ref) => {
      console.log("PROPERTIES REF");
      setPropertiesRef(ref);
    }, [])
    useEffect(() => {
      setIsInit(true);
    }, [])
    useEffect(() => {
      const pathIsPropertyOpened = window.location.pathname.match(new RegExp(`\/${AdminPropertyPath}/\\\d+`));
      if (!pathIsPropertyOpened) {
        // Store original location
        originalLocationRef.current = {...window.location};
        console.log("test1: ");
        console.log("test1: " + originalLocationRef.current.search);
      }
      if (openedPropertyId) {
        // navigate(`../${1}`, {replace: true});
        window.history.pushState(null, null, 
          pathIsPropertyOpened ? `./${openedPropertyId}` : `./${AdminPropertyPath}/${openedPropertyId}`);
      } else {
        if (isInit) {
          console.log(window.location.pathname);
          console.log({pathIsPropertyOpened});
          console.log(originalLocationRef.current);
          if (pathIsPropertyOpened) {
            if (originalLocationRef.current) {
            

              window.history.pushState(null, null, originalLocationRef.current.pathname + 
                (originalLocationRef.current.search ? originalLocationRef.current.search : "") + 
                (originalLocationRef.current.hash ? originalLocationRef.current.hash : "")
              );

            } else {
              // Safety net
              window.history.pushState(null, null, `../${AdminPropertyPath}`);
            }
          }
        }

        console.log("test");
        setReloadReadHistoryKey(key => ++key);
      }


    }, [openedPropertyId]);

    useEffect(() => {
      if (openedPropertyId && !window.location.pathname.match(new RegExp(`\/${AdminPropertyPath}/\\\d+`))) {
        setOpenedPropertyId(null);
      } else if (window.location.pathname.match(new RegExp(`\/${AdminPropertyPath}(/?)$`)) && !window.location.search) {
        console.log("Reset flag");
        setResetFlag(flag => ++flag);
      }
    }, [location])

    useEffect(() => {
      const popState = (event) => {
        setOpenedPropertyId(openedId => {
          if (openedId) {
            return null;
          } else {
            return null;
          }
        })
      }
      window.addEventListener("popstate", popState);

      return () => {
        window.removeEventListener("popstate", popState);
      }
    }, [])
    
    return <>
      <AdminComponent.Container className={clsx(openedPropertyId && "d-none")}>
        <AdminHeaderTitle>{t('admin.property.title')}</AdminHeaderTitle>
        <AdminComponent.TitleBar>
          <AdminComponentTitle 
            faIcon={faHouseChimneyUser}
          >
            {t('admin.property.pageTitle')}
          </AdminComponentTitle>

          <ButtonWithLoader
            faIcon={faUserPen}
            variant={editHistoryMode ? "success" : "outline-success"}
            className="ms-auto"
            onClick={async() => {
              setEditHistoryMode(flag => !flag);
            }}
          >
            <span className="d-none d-sm-block">{t(AdminPropertyI18NPrefix + "editHistory.title")}</span>
          </ButtonWithLoader>

          <LinkWithLoader
            to={`./new`}
            faIcon={faPlus}
            className="btn btn-primary"
            onClick={async(event) => {
              event.stopPropagation();
              
            }}
          >
            <span className="d-none d-md-block">{t(AdminPropertyI18NPrefix + "createTitle")}</span>
          </LinkWithLoader>
  
          <ButtonWithLoader
            faIcon={faFilterCircleXmark}
            variant="danger"
            onClick={async() => {
              // resetFindAllQuery();
              // setFieldSearch(null);
              // await reload();
              setResetFlag(flag => ++flag);
              setEditHistoryMode(false);
              navigate({
                pathname
              }, {replace: true})

            }}
          >
            <span className="d-none d-md-block">{t("commons.resetFilter")}</span>
          </ButtonWithLoader>
        </AdminComponent.TitleBar>
  
        
        <div className="hr" />
        
        <React.Fragment key={resetFlag} >
          <AdminPropertiesComponent 
            onEnter={(id) => setOpenedPropertyId(id)}
            stateRef={propertiesRefFn}
            editHistoryMode={editHistoryMode} 
            setEditHistoryMode={setEditHistoryMode}
            openedPropertyId={openedPropertyId}
            setOpenedPropertyId={setOpenedPropertyId}
            setOpenPropertyGoToPublishment={setOpenPropertyGoToPublishment}
            reloadReadHistoryKey={reloadReadHistoryKey}
          />
        </React.Fragment>
      </AdminComponent.Container>


      
      {
        openedPropertyId && <AdminComponent.Container>
          <AdminPropertyComponent mode={"edit"} id={openedPropertyId} goToPublishment={openPropertyGoToPublishment} onBackToOverride={(payload) => {
              console.log({payload});
              
              setOpenedPropertyId(null);
              switch (payload) {
                case "PageUp":
                  console.log("TEST");
                  propertiesRef?.openPropertyOffset(openedPropertyId, -1);
                break;

                case "PageDown":
                  propertiesRef?.openPropertyOffset(openedPropertyId, 1);
                break;
              }
              // window.history.back();
            }}
            onSave={(property) => {
              console.log({propertiesRef});
              propertiesRef?.updateProperty?.(property.id, property);
              console.log("ONSAVE!");
            }}
          
          />
        </AdminComponent.Container>
      }
    </>
    
  }
  
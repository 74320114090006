import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { ReactNode, useCallback, useState } from "react";
import { Spinner } from "react-bootstrap";
import ReactSelect, { components, OptionsOrGroups, GroupBase, Options } from "react-select"
import "../styles/autocomplete-search.scss";
import { useRefDimension } from "../utils/use-ref-dimension";
import { useI18N } from "./I18NProvider";

export interface AutocompleteSearchProps<T, PrefixT> {
  input: string;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  onFocus?: () => void;
  loading?: boolean;
  disabled?: boolean;
  placeholder?: ReactNode;
  options?: OptionsOrGroups<T, GroupBase<T>>
  onSelect?: (value: T) => void;
  hideDropdown?: boolean;
  prefix?: React.ReactNode;
  prefixSelect?: {
    value?: PrefixT,
    options?: OptionsOrGroups<PrefixT, GroupBase<PrefixT>>,
    onSelect?: (option: PrefixT) => void
  };
  postfix?: React.ReactNode;
  overrideInputComponent?: React.ReactNode;
}

const LoadingIndicator = () => null;



export const AutocompleteSearch = <T, PrefixT>(props: AutocompleteSearchProps<T, PrefixT>) => {

  const {t} = useI18N();
  const { dimension: prefixDimension, ref: prefixRef } = useRefDimension();
  const { dimension: postfixDimension, ref: postfixRef } = useRefDimension();
  
  const NoOptionsMessage = useCallback(({inputValue}) => (
    (!inputValue?.trim() || props.hideDropdown) ? null : t("commons.noResult")
  ), [props.hideDropdown]);


  const mainMarginLeft = `calc(0.6em${prefixDimension?.width && (" + " + prefixDimension?.width + "px")})`;
  const mainMarginRight = postfixDimension?.width;
  // const mainMarginLeft = prefixDimension?.width;

  return (
    <form className="autocomplete-search-container" 
      onSubmit={event => {
        event.preventDefault();
        props.onSubmit?.(props.input);
      }}
      onKeyDown={e => {
        e.stopPropagation();
      }}
    >

      
      <div className="prefix" ref={prefixRef}>
        
        <div className={clsx("prefix-icon-area", props.loading && "loading")}>
          <div className="loader"><Spinner animation="border" /></div>
          <FontAwesomeIcon icon={faSearch} className="search-icon" fixedWidth />
        </div>
        {props.prefix}
        {
          props.prefixSelect && (
            <ReactSelect
              // menuIsOpen
              components={{
                // Control: ({children, ...rest}) => <div {...rest}>{children}</div>
                IndicatorSeparator: () => null
              }}
              isSearchable={false}
              styles={{
                // container: (provided) => ({...provided, position: "absolute", pointerEvent: "none", top: 0}),
                container: (provided) => ({...provided, marginRight: "-0.6em", color: "black"}),
                control: (provided) => ({...provided, border: "none", background: "transparent"}),
                menu: (provided) => ({...provided, margin: 0}),
                indicatorsContainer: (provided) => ({...provided, marginLeft: "-0.6em"})
                // option: (provided) => ({...provided, whiteSpace: "nowrap"})
              }}
              options={props.prefixSelect.options}
              value={props.prefixSelect.value}
              onChange={(newValue) => {
                props.prefixSelect?.onSelect?.(newValue);
              }}
            />
          )
        }
      </div>
      <ReactSelect
        className="autocomplete-search"
        isClearable
        isDisabled={props.disabled}
        placeholder={props.placeholder ?? t("commons.search")}
        // loadingMessage={}
        classNamePrefix="autocomplete-search"
        // isSearchable
        inputValue={props.input}
        filterOption={() => true}
        onInputChange={(newValue, action) => {
          // console.log({newValue, action})
          if (action.action === "input-change") {
            props.onChange?.(newValue)
          }
        }}
        controlShouldRenderValue={false}
        onFocus={() => {
          props.onFocus?.();
        }}
        onChange={(value: T) => {
          if (value == null) {
            props.onChange?.("")
          } else {
            props.onSelect?.(value);
          }
        }}
        noOptionsMessage={NoOptionsMessage}
        value={{
          value: "",
          label: ""
        } as any}
        options={props.options}
        loadingMessage={({inputValue}) => null}
        components={{
          DropdownIndicator: null,
          LoadingIndicator: null,
          ...(props.overrideInputComponent && {
            Input: () => <div style={{marginLeft: mainMarginLeft}}>{props.overrideInputComponent}</div>,
            Placeholder: () => <></>,
          })
        }}
        styles={{
          input: (provided, state) => ({...provided, marginLeft: mainMarginLeft, marginRight: mainMarginRight}),
          placeholder: (provided) => ({...provided, marginLeft: mainMarginLeft, marginRight: mainMarginRight}),
          indicatorsContainer: (provided) => ({...provided, marginRight: mainMarginRight}),
        }}
      />

      
      <div className="postfix" ref={postfixRef}>
        {
          props.postfix
        }
      </div>
    </form>
    
  )
}


import { faLanguage, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { ComponentProps, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../styles/global-selector.scss';
import { useI18N } from './I18NProvider';

export interface GlobalSelectorRef {
  openSelector: () => void;
  open: boolean;
}

export interface GlobalSelectorProp {
  accessRef?: (ref: GlobalSelectorRef) => void,
  className?: string,
}

const GlobalSelector = (props: React.PropsWithChildren<GlobalSelectorProp>) => {
  
  const [open, setOpen] = useState(false);
  const openLanguageSelector = useCallback(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    props.accessRef?.({
      openSelector: () => {
        setOpen(true)
      },
      open
    })
  }, [])

  const {t} = useI18N();

  return (
    <Modal
      show={open}
      fullscreen
      className="global-selector"
    >
      {/* <div 
        className={clsx("global-selector", open && "open", props.className)}
        onClick={() => {
          setOpen(false);
        }}
      > */}
        <div 
          className="close-btn"
          onClick={(event) => {
            event.stopPropagation();
            setOpen(false);
          }}
        >
          <FontAwesomeIcon icon={faXmark} fixedWidth />
        </div>
        <div 
          className="global-selector-main"
          onClick={event => event.stopPropagation()}
        >
          {props.children}
        </div>
      {/* </div> */}
    </Modal>

  )
}

GlobalSelector.Title = (props: PropsWithChildren<{}>) => {
  return (
    <div className="title">
      {props.children}
    </div>
  )
}

GlobalSelector.List = (props: ComponentProps<"ul"> & {
  selected?: boolean
}) => {
  return (
    <ul 
      {...props}
    >
      {props.children}
    </ul>
  )
}

GlobalSelector.ListItem = (props: ComponentProps<"li"> & {
  selected?: boolean
}) => {
  return (
    <li 
      {...props}
      className={clsx(props.selected && "selected", props.className)}
    >
      {props.children}
    </li>
  )
}

export {GlobalSelector};
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { LocalCache } from "../api/src/local-cache";
import i18n, { t, Resource, TFunction } from 'i18next';
import { initReactI18next, useTranslation } from "react-i18next";
import { I18N } from "../api/entities/i18n.entity";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { useGlobalLoadingScreen } from "../pages/GlobalLoadingScreen";
import moment from "moment";

import 'moment/min/locales';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage, faXmark } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { APIInstance } from "../api";
import { useGlobalSystemConfig } from "./GlobalSystemConfig";
import { GlobalSelector, GlobalSelectorRef } from "./GlobalSelector";

export interface I18NContextType {
  t: (...args: any[]) => string,
  i18ns: I18N[],
  i18nsReload: () => Promise<I18N[]>,
  openLanguageSelector: () => void,
  currentLang: string,
  currentI18n: I18N,
}

export const I18NContext = React.createContext<I18NContextType>(null);

export const useI18N = () => {
  return useContext(I18NContext);
}

const I18NChild = (props: React.PropsWithChildren<{}>) => {

  const [isInit, setIsInit] = useState(false);
  const [langNotFound, setLangNotFound] = useState(false);
  const [isError, setIsError] = useState(false);
  const [i18ns, setI18ns] = useState<I18NContextType["i18ns"]>([]);
  const {globalSystemConfig} = useGlobalSystemConfig();
  const localCache = new LocalCache<{language: string}>();
  const {lang} = useParams<{lang: string}>();
  const location = useLocation();
  const navigate = useNavigate();
  const hrefWithLangParam = useMemo(() => {
    return window.location.origin + location.pathname.replace(/^\/([^\/]+)/, "/:lang") + location.search + location.hash
  }, [location.pathname])


  
  const {globalLoading} = useGlobalLoadingScreen();
  const api = useMemo(() => new APIInstance({locale: lang}), []);
  const [selector, setSelector] = useState<GlobalSelectorRef>(null);

  const i18nsReload = useCallback(async(): Promise<I18N[]> => {
    const i18ns = await api.i18n.findAll();
    setI18ns(i18ns);
    return i18ns;
  }, []);

  const openLanguageSelector = useCallback(() => {
    selector?.openSelector();
  }, [selector])

  const currentI18n = useMemo(() => (
    i18ns?.find(i18n => i18n.id === lang)
  ), [i18ns, lang])

  const init = useCallback(async() => {
    let defaultLanguage = "en";
    const token = globalLoading.start();

    try {
      defaultLanguage = globalSystemConfig.DEFAULT_LANGUAGE;
    } catch (e) {

    }

    if (!lang) {
      let evaluatedLanguage: string = null;
      // Redirect to evaluated language
      // Language from browser
      // 1. Stored language in browser
      try {
        console.log("Local storage lang: " + localCache.get("language"));
        evaluatedLanguage = await api.i18n.checkAlias(localCache.get("language"));
      } catch (e) {

      }

      if (!evaluatedLanguage) {
        // 2. Browser
        const browserLanguages = window.navigator.languages;
        for (const browserLanguage of browserLanguages) {
          try {
            evaluatedLanguage = await api.i18n.checkAlias(browserLanguage);
          } catch (e) {

          }
        }
      }

      // 3. Default
      if (!evaluatedLanguage) {
        evaluatedLanguage = defaultLanguage;
      }

      globalLoading.stop(token);
      navigate("/" + evaluatedLanguage, { replace: false });
      window.location.reload();
      return;
    } else {

      // Normal loading
      try {
        const i18ns = await i18nsReload();
        const i18nEntity = i18ns?.find(i18n => i18n.id.toLowerCase() === lang.toLowerCase());

        // console.log({lang});

        if (!i18nEntity) {
          throw "LANG_NOT_FOUND";
        }

        // E.g., "Tc" !== "tc"
        if (i18nEntity.id !== lang) {
          // Direct to equivalent case first
          navigate(location.pathname.replace(/^\/([^\/]+)/, "/" + i18nEntity.id) + (location.search ?? "") + (location.hash ?? ""));
          window.location.reload();
          return;
        }
        const langForI18ns = i18nEntity?.id;

        const resources: Resource = i18ns.reduce((acc, i18n) => {
          // console.log(i18n);
          const langForI18ns = i18n.id.replace("-", "_");  // i18n doesn't support "-" but "_"
          return {
            ...acc,
          [langForI18ns]: {
            translation: i18n.json
          }
          }
        }, {})
        
        // {
        //   [langForI18ns]: {
        //     translation: await api.i18n.getJson(lang)
        //   }
        // };

        // if (langForI18ns !== defaultLanguage) {
        //   // Load default language for fallback
        //   try {
        //     // In case default language does not exist, try and catch without error
        //     // Although this is not supposed to happen
        //     resources[defaultLanguage] = {
        //       translation: await api.i18n.getJson(defaultLanguage)
        //     }
        //   } catch (e) {
            
        //   }
        // }
        // console.log(resources);
        await i18n
          .use(initReactI18next) // bind react-i18next to the instance
          .init({
            lng: langForI18ns,
            fallbackLng: defaultLanguage,
            debug: false,
            resources,
            saveMissing: true,
            missingKeyHandler: (
              lngs: readonly string[],
              ns: string,
              key: string,
              fallbackValue: string,
              updateMissing: boolean,
              options: any,
            ) => {
              console.debug(`Missing key: ${key} for language: ${lngs}`);
            }
          });
        
        // Store the language in local cache
        // Set Moment locale
        moment.locale(i18nEntity.isoId);
        localCache.set({language: lang})
        setIsError(false);
      } catch (e) {
        if (e == "LANG_NOT_FOUND" || (e as AxiosError)?.response?.status === 404) {
          setLangNotFound(true);
        } else {
          setIsError(true);
          window.setTimeout(() => {
            init();
          }, 1000);
        }
      } finally {
        setIsInit(true);
        globalLoading.stop(token);
      }
    }
  }, [lang]);

  useEffect(() => {
    console.debug("Loading i18n");
    init().then(() => {
      console.debug("Loaded i18n");
    });
  }, [lang])

  return (
    <I18NContext.Provider
      value={{
        t: i18n.t,
        i18ns,
        i18nsReload,
        openLanguageSelector,
        currentLang: lang,
        currentI18n
      }}
    >
      {isInit && !isError && !langNotFound ? (
        <>
          <GlobalSelector
            accessRef={ref => setSelector(ref)}
          >
            <GlobalSelector.Title>
              <FontAwesomeIcon icon={faLanguage} className="me-2" size="lg"/>
              {
                `${t('commons.switchLanguage')}`
              }:
            </GlobalSelector.Title>
            <GlobalSelector.List>
            {
              i18ns?.map(item => (
                <GlobalSelector.ListItem 
                  key={item.id}
                  selected={item.id == lang}
                >
                  <a 
                    href={hrefWithLangParam.replace(':lang', item.id)}
                  >
                    {item.name}
                  </a>
                </GlobalSelector.ListItem>
              ))
            }
            </GlobalSelector.List>
            
          </GlobalSelector>
        {
          
          props.children
        }
        </>
        
      ) : (
        langNotFound ? (<div>Language "{lang}" not found</div>) : (
          isError && "Server Error..."
        )
      )}
    </I18NContext.Provider>
  )
}

export const I18NProvider = (props: React.PropsWithChildren<{}>) => {

  return (
    <Routes>
      <Route path=":lang/*" element={<I18NChild {...props}/>} />
      <Route index element={<I18NChild {...props} />} />
    </Routes>
  )
}

export const I18NTranslate = (props: {key: string | any[]}) => {
  const {t} = useI18N();
  const result = useMemo(() => (
    t(props.key)
  ), [props.key]);

  return <>{result}</>;
}
import React, { useMemo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Routes, Route, useMatch, BrowserRouter, useLocation } from 'react-router-dom';
import { GlobalModalProvider } from './component/GlobalModal';
import { GlobalSystemConfigProvider } from './component/GlobalSystemConfig';
import { I18NProvider } from './component/I18NProvider';
import { UserProvider } from './component/UserProvider';
import { GlobalLoadingScreenProvider, useGlobalLoadingScreen } from './pages/GlobalLoadingScreen';
import * as path from 'path-browserify';

import Admin from './pages/Admin';
import Main from './pages/Main';
import { RemoveTrailingSlash } from './pages/Admin/component/RemoveTrailingSlash';
// const Admin = React.lazy(() => import('./pages/Admin')); 
// const Main = React.lazy(() => import('./pages/Main')); 

export const App = () => {

  let {globalLoading} = useGlobalLoadingScreen();

  // CODE SPLITTING (don't load other modules while not in use, i.e., admin vs main)
  // Ref: https://dev.to/iamandrewluca/react-lazy-without-default-export-4b65
  // Ref2: https://17.reactjs.org/docs/concurrent-mode-suspense.html

  
  return (
    <>
      {
        process.env.REACT_APP_MODE && (
          <div 
            className="app-mode"
          >
            {process.env.REACT_APP_MODE.toUpperCase()}
          </div>
        )
      }
      <GlobalSystemConfigProvider>
        <I18NProvider>
          <UserProvider>
            <GlobalModalProvider>
              <HelmetProvider>
                <RemoveTrailingSlash/>
                <Routes>
                  <Route path={path.join("/", "", "*")} element={<Admin />} />
                  {/* <Route path={"*"} element={<Main />} /> */}
                </Routes>
              </HelmetProvider>
            </GlobalModalProvider>
          </UserProvider>
        </I18NProvider>
      </GlobalSystemConfigProvider>
    </>


  )
}

export default App;



export class LocalCache<T extends {
  [key: string]: any
}> {

  prefix: string;               
  useSessionStorage: boolean;

  constructor(options?: {
    prefix?: string,              // default ""
    useSessionStorage?: boolean   // default false
  }) {
    this.prefix = (options && options.prefix) || "";
    this.useSessionStorage = (options && options.useSessionStorage) || false;
  }

  private getStorage() {
    return this.useSessionStorage ? sessionStorage : localStorage;
  }

  set(values: Partial<T>) {
    for (let [key, value] of Object.entries(values)) {
      // console.log(this.prefix + key + ':' + JSON.stringify(value));
      this.getStorage().setItem(this.prefix + key, JSON.stringify({value}));
    }
  }

  get<V = any>(key: keyof T, defaultValue?: V): V {
    try {
      var rawValue: any = this.getStorage().getItem(this.prefix + (key as string));
      if (rawValue == null) {
        return defaultValue;
      } else {
        const rawValueParsed = JSON.parse(rawValue);
        const value = rawValueParsed?.value;
        return value;
      }
    } catch (e) {
      // console.error(e);
      console.error("Cannot parse JSON string: ");
      console.error(rawValue);
      return null;
    }
  }

}
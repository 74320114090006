import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { faCrosshairs, faCube, faDownload, faFolderOpen, faInfoCircle, faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios, { AxiosInstance } from "axios";
import mimeDb from "mime-db";
import path from "path-browserify";
import { Modal } from "react-bootstrap";
import urlJoin from "url-join";
import "../styles/file-viewer.scss";
import clsx from "clsx";
import { AWS_S3_URL_PREFIX, FileManagerProps, FileManagerStatWithMime } from "./FileManager";
import { FileViewerImage } from "./file-viewer/Image";
import { FileViewerModel } from "./file-viewer/Model";
import { FileViewerJson } from "./file-viewer/Json";
import { filesize } from "../../../utils/filesize";
import { faFile, faFileCode, faFileImage, faFileLines } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import { faAws } from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FileManagerStat } from "../../../api/src/file-manager";


export interface FileViewerContextType {
  processFile: <T extends {
    fileName: string,
    isFile: boolean,
    isDir: boolean,
    isServiceRootDir?: boolean,
  }>(file: T, allowedMimeTypes?: RegExp) => T & {
    mimeType: string;
    mimeTypeAllowed: boolean;
    icon: IconProp;
  }
  openFileViewer: (
    src: string,
    onChoose?: () => void,
    apiForProperty?: FileManagerProps["api"]
  ) => void;
  openFileProperty: (
    str: string,
    api: FileManagerProps["api"],
  ) => void;
  getFullSrc: (src: string) => string;

}

export const FileViewerContext = React.createContext<FileViewerContextType | null>(null);

export const useFileViewer = () => {
  const context = useContext(FileViewerContext);
  if (!context) {
    throw new Error("Use of useFileViewer without <FileViewerProvider />");
  }
  return context
}

export const FileViewerProvider = (props: React.PropsWithChildren<{
  basePath: string,
  awsS3Path?: string,
}>) => {
  const {basePath, awsS3Path} = props;
  const [fileViewerOpen, setFileViewerOpen] = useState(false);
  const [filePropertyOpen, setFilePropertyOpen] = useState(false);
  const [src, setSrc] = useState<string | null>(null);
  const [fileProperty, setFileProperty] = useState<FileManagerStat | null>(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [filePropertyLoading, setFilePropertyLoading] = useState(false);
  const [filePropertyError, setFilePropertyError] = useState(false);
  const api = useRef<FileManagerProps["api"] | null>(null);
  const axiosInstance = axios;
  const onChoose = useRef<((src: string) => void) | null>(null);

  const processFile = useCallback(<T extends {
    fileName: string,
    isFile: boolean,
    isDir: boolean,
    isServiceRootDir?: boolean,
  }>(file: T, allowedMimeTypes?: RegExp): T & {
    mimeType: string | null;
    mimeTypeAllowed: boolean;
    icon: IconProp;
  } => {
    let mimeType: string | null = null;
    var mimeTypeAllowed = true;
    if (file.isFile) {
      let ext = path.extname(file.fileName).slice(1).toLowerCase();
      mimeType = Object.keys(mimeDb).find(
        (key) => mimeDb[key].source == 'iana' && (mimeDb[key].extensions?.indexOf(ext) as any) >= 0
      ) || null;
      // console.log(mimeType);
      if (allowedMimeTypes) {
        mimeTypeAllowed = !!mimeType?.match(allowedMimeTypes);
      }
    } else {
      mimeType = "folder"
    }

    let icon = file.isServiceRootDir ? faUpRightFromSquare :
      file.isDir ? faFolderOpen : 
      mimeType?.match(/^image\//) ? faFileImage : 
      mimeType?.match(/^text\//) ? faFileLines : 
      mimeType == "application/json" ? faFileCode : 
      mimeType == 'model/obj' ? faCube : faFile;

    return {
      ...file,
      mimeType,
      mimeTypeAllowed,
      icon
    };
  }, []);

  const getFullSrc = useCallback((src: string) => {
    var fullSrc = src;
    // console.log(src);
    // console.log(awsS3Path);
    // console.log(src?.startsWith(AWS_S3_URL_PREFIX));
    if (!src) {
      return null;
    }
    if (src.match(/^(https?:\/\/|\/\/)/)) {
      // console.log("TEST1");
      // Source is already an URL
      return src;
    } else if (src && src.startsWith(AWS_S3_URL_PREFIX) && awsS3Path) {
      // console.log("TEST2");
      fullSrc = src.replace(new RegExp(`^${AWS_S3_URL_PREFIX}`), awsS3Path);
    } else if (src && basePath.match(/^https?:\/\/|\/\//)) {
      // console.log("TEST3");
      // If URL
      fullSrc = urlJoin(basePath, src);
    } else {
      // console.log("TEST4");
      // If normal path
      fullSrc = path.join(basePath,src);
    }
    // console.log(fullSrc);
    return fullSrc;
  }, []);

  const {filePropertyWithMime, contains, publicLink} = useMemo(() => {
    return {
      filePropertyWithMime: fileProperty && processFile(fileProperty),
      contains: fileProperty && fileProperty.subStat && {
        fileCount: fileProperty.subStat.filter(f => f.isFile).length,
        folderCount: fileProperty.subStat.filter(f => f.isDir).length,
      },
      publicLink: fileProperty && (fileProperty.externalLink ? fileProperty.externalLink : getFullSrc(fileProperty.path))
    }
  }, [fileProperty])
  
  const {filename, ext, mimeType, fullSrc, Element} = useMemo(() => {
    if (src === null && !fileViewerOpen) {
      return {filename: null, ext: null, mimeType: null, fullSrc: null, Element: null};
    }

    let filename = path.basename(src!);
    let ext = path.extname(src!).slice(1).toLowerCase();
    let mimeType = Object.keys(mimeDb).find(
      (key) => mimeDb[key].source == 'iana' && (mimeDb[key].extensions?.indexOf(ext) as any) >= 0
    );
    // console.log(mimeType);
    let fullSrc = getFullSrc(src!);

    setLoading(true);
    setErrorMessage(null);


    var Element: ((props: FilePreviewByFileTypeProps) => JSX.Element) | null = null;
    if (mimeType?.match(/^image\//)) {
      Element = FileViewerImage;
    } else if (mimeType?.match(/^model\//)) {
      Element = FileViewerModel;
    } else if (mimeType == 'application/json') {
      Element = FileViewerJson;
    } else {
      setLoading(false);
    }

    return {filename, ext, mimeType, fullSrc, Element};
  }, [src, fileViewerOpen])

  const openFileViewer = (src: string, onChooseFn: (src: string) => void | null, apiForProperty?: FileManagerProps["api"]) => {
    setSrc(src);
    api.current = apiForProperty || null;
    onChoose.current = onChooseFn;
    setFileViewerOpen(true);
  }

  const openFileProperty = (src: string, api: FileManagerProps["api"]) => {
    setSrc(src);
    setFilePropertyError(false);
    setFilePropertyLoading(true);
    setFilePropertyOpen(true);
    // let fullSrc = getFullSrc(src);
    api.get(src, true).then((data) => {
      setFileProperty(data);
      setFilePropertyError(false);
    }).catch((e) => {
      console.log(e);
      console.log("C");
      setFilePropertyError(true);
    }).finally(() => {
      setFilePropertyLoading(false);
    })
  }

  return (
    <FileViewerContext.Provider
      value={{
        processFile, openFileViewer, openFileProperty, getFullSrc
      }}
    >
      {props.children}
      {/* File Viewer Modal */}
      {
        src && (
          <Modal 
            className="file-viewer"
            show={fileViewerOpen}
            onHide={() => setFileViewerOpen(false)}
            centered
            backdropClassName="file-viewer-backdrop"
          >
            <Modal.Header closeButton>
              <div className="filename">
                {filename}
              </div>
              
            </Modal.Header>
            <Modal.Body>
              <div className={clsx("file-viewer-loader", loading && "active")}>
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
              </div>
              
              {
                (!errorMessage && Element) ? (
                  <Element 
                    src={src}
                    axios={axiosInstance} 
                    onFileLoad={() => setLoading(false)} 
                    onFileError={(message) => {setErrorMessage(message); setLoading(false)}}
                  />
                ) : !errorMessage ? (
                  <small style={{color: "grey"}}>File type <i>.{ext}</i> cannot be previewed</small>
                ) : <small style={{color: "grey"}}>{errorMessage}</small>
              }
            </Modal.Body>
            <Modal.Footer>
              {
                onChoose.current && (
                  <button className="btn btn-info btn-sm px-3" onClick={() => {
                    onChoose.current(src);
                    setFileViewerOpen(false)
                  }}>
                    <FontAwesomeIcon icon={faCrosshairs} className="choose icon"/>
                    Choose
                  </button>
                )
              }

              {
                //  Only render if API available
                api.current && (
                  <button 
                    className="btn btn-outline-info btn-sm px-3"
                    onClick={() => {
                      openFileProperty(src, api.current);
                    }}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} className="icon" />
                    Property
                  </button>
                )
              }
              

              
              <a href={fullSrc} target="_blank" className="btn btn-primary btn-sm px-3">
                <FontAwesomeIcon icon={faDownload} className="icon"/>
                Download
              </a>
            </Modal.Footer>
          </Modal>
        )
      }

      {/* File Property Modal */}
      {
        src !== null && (
          <Modal 
            className="file-viewer file-property"
            show={filePropertyOpen}
            onHide={() => setFilePropertyOpen(false)}
            centered
            backdropClassName="file-property-backdrop"
          >
            <Modal.Header closeButton>
              <div className="filename">
                Property of {filename || <span className="text-muted fw-light fst-italic">root</span>}
              </div>
              
            </Modal.Header>
            <Modal.Body>
              <div className={clsx("file-viewer-loader", filePropertyLoading && "active")}>
                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
              </div>
              
              {
                !filePropertyError ? !filePropertyLoading && filePropertyWithMime && (
                  <div className="file-property-list">
                    <div className="row item">
                      <div className="col-3 key">Name:</div>
                      <div className="col-9 value">
                        <input 
                          className="form-control form-control-sm"
                          value={filePropertyWithMime.fileName}
                          onChange={() => {}}
                        />
                      </div>
                    </div>
                    <div className="row item">
                      <div className="col-3 key">Type:</div>
                      <div className="col-9 value">
                        <FontAwesomeIcon className="icon me-2" icon={filePropertyWithMime.icon} />
                        {filePropertyWithMime.mimeType || (
                          <span className="text-muted fw-light fst-italic">Unknown file type</span>
                        )}
                      </div>
                    </div>
                    <div className="row item">
                      <div className="col-3 key">Location:</div>
                      <div className="col-9 value">{
                        // whatever/filename/ ===> whatever/
                        fileProperty.path.replace(new RegExp("^(.*)" + fileProperty.fileName + "/?$"), "$1")|| (
                          <span className="text-muted fw-light fst-italic">root</span>
                        )
                      }</div>
                    </div>
                    <div className="row item">
                      <div className="col-3 key">Size:</div>
                      <div className="col-9 value">
                        {filesize(fileProperty.size)}
                        &nbsp;({fileProperty.size.toLocaleString("en-US")} bytes)
                      </div>
                    </div>
                    {
                      fileProperty.dirContentSize != null && (
                        <div className="row item">
                          <div className="col-3 key">Content size:</div>
                          <div className="col-9 value">
                            {filesize(fileProperty.dirContentSize)}
                            &nbsp;({fileProperty.dirContentSize.toLocaleString("en-US")} bytes)
                          </div>
                        </div>
                      )
                    }
                    {
                      contains && (
                        <div className="row item">
                          <div className="col-3 key">Contains:</div>
                          <div className="col-9 value">
                            {contains.fileCount} files, {contains.folderCount} folders
                          </div>
                        </div>
                      )
                    }
                    <div className="row item">
                      <div className="col-3 key">Created:</div>
                      <div className="col-9 value">
                        {fileProperty.birthtime ? moment(fileProperty.birthtime).format('YYYY-MM-DD HH:mm:ss') : "-"}
                      </div>
                    </div>
                    <div className="row item">
                      <div className="col-3 key">Modified:</div>
                      <div className="col-9 value">
                        {moment(fileProperty.mtime).format('YYYY-MM-DD HH:mm:ss')}
                      </div>
                    </div>
                    {
                      fileProperty.service && (
                      <div className="row item">
                        <div className="col-3 key">Service:</div>
                        <div className="col-9 value">
                          {
                            fileProperty.service == "aws-s3" ? 
                            <>
                            <b><FontAwesomeIcon icon={faAws} className="me-2" />AWS S3</b>
                            </> : "Unknown"
                          }
                        </div>
                      </div>
                      )
                    }
                    {
                      fileProperty.isFile && (
                      <div className="row item">
                        <div className="col-3 key">Link:</div>
                        <div className="col-9 value">
                          <a href={publicLink} target="_blank">
                            {publicLink}
                          </a>
                        </div>
                      </div>
                      )
                    }
                  </div>
                ) : (
                  <small style={{color: "grey"}}>Error loading file property.</small>
                )
              }
            </Modal.Body>
            <Modal.Footer>
              <button 
                className="btn btn-primary btn-sm px-4"
                onClick={() => {
                  setFilePropertyOpen(false);
                }}
              >
                OK
              </button>
            </Modal.Footer>
          </Modal>
        )
      }

    </FileViewerContext.Provider>
  )
}


// File Preview by file type
export interface FilePreviewByFileTypeProps {
  src: string;
  axios?: AxiosInstance;
  onFileLoad?: () => any;
  onFileError?: (message: string) => any;
}





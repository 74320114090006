import { faAddressCard, faBuilding, faSave, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faPlus, faArrowsRotate, faBan, faBuildingUser, faCheck, faPersonDigging, faAddressBook, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useState, useTransition, useMemo, useCallback, useEffect, useId } from "react";
import { Badge, Spinner } from "react-bootstrap";
import { Developer, DeveloperDto, DeveloperSort } from "../../../api/entities/developer.entity";
import { FindAllQuery } from "../../../api/entities/findAllQuery";
import { Paginated } from "../../../api/entities/pagination";
import { AutocompleteSearch } from "../../../component/AutocompleteSearch";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider";
import { UnsavedPrompt } from "../../../component/UnsavedPrompt";
import { useAbortable, useAbortableLoading } from "../../../utils/abortable-loading";
import { useScrollToId } from "../../../utils/scroll-to-id";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";
import { ContactShortcut } from "../component/ContactShortcut";
import { DataList, DataListItem } from "../component/DataList";
import { EntitySelector, EntitySelectorParentProps } from "../component/EntitySelector";
import { EntityTimestamp } from "../component/EntityTimestamp";
import { useFindAllQuery } from "../component/FindAllQuery";
import { GenericInput } from "../component/GenericInput";
import { AdminPagination } from "../component/Pagination";
import { AdminDevelopersPath } from "../variables";

export const AdminDevelopersComponent = (props: {
  onSelect?: (developer: Developer) => void,
  selectedDevelopers?: Developer[];
  shouldUseSearchParams?: boolean,
}) => {
    const {api, loading, toast} = useAdminComponent();
    const modal = useGlobalModal();
    const [isInit, setIsInit] = useState(false);
    const [developersPaginated, setDevelopersPaginated] = useState<Paginated<Developer>>(null);
    const [pendingInputFocus, setPendingInputFocus] = useState(false);
    const [developerEditing, setDeveloperEditing] = useState<(Developer & {
      isNew: boolean,
      newIdEdited: boolean, // Only for new, initially false, true if id is edited
      index: number | null, // null for create
      unsaved: boolean,
    }) | null>(null);

    // console.log(DeveloperSort);

    const {findAllQuery, renderPaginationUI, renderSearchInput, setSearchInput, scrollTopRef, setSortByDropdownOpen} 
      = useFindAllQuery({
      isInit, loading: loading.flag, disabled: !!developerEditing || !isInit, paginationMeta: developersPaginated?.meta,
      shouldUseSearchParams: props.shouldUseSearchParams,
      defaultLimit: 50,
      sortKeys: ["id", "nameEn", "nameTc", "nameSc", "timestamp.created", "timestamp.updated"]
    })
  
    const reloadAbortable = useAbortable();
    const openCCT2SAbortableLoading = useAbortableLoading();
    
    const {scrollToId, idPrefix} = useScrollToId();
    
    const {t} = useI18N();
  
    const unsaved = useMemo(() => {
      return !!developerEditing?.unsaved;
    }, [developerEditing?.unsaved])
  
    const unsaveProceed = useCallback(async() => {
      return !unsaved || unsaved && await modal.confirmUnsaved();
    }, [unsaved])
  
    const reload = useCallback(async() => {
      const token = loading.start();
      const {signal} = reloadAbortable.create();
      try {
        setDevelopersPaginated(await api.developers.findAll(findAllQuery, {signal}));
        // TODO
        scrollToId(null);
      } catch (e) {
        modal.errorSpecific(e);
      } finally {
        loading.stop(token);
      }
    }, [findAllQuery])
  
    useEffect(() => {
      if (isInit) {
        // TODO: Handle unsaved prompt from external use
        reload();
      }
    }, [findAllQuery]);

    useEffect(() => {
      reload().then(() => setIsInit(true));
    }, []);
  
    return (
      <>
        <UnsavedPrompt flag={unsaved} />
        <div className="d-flex align-items-center mb-3" style={{gap: "0.5em", flexWrap: "wrap"}}>
  
          <ButtonWithLoader
            faIcon={faPlus}
            variant="primary"
            loading={loading.check()}
            onClick={async(event) => {
              event.stopPropagation();
              if (await unsaveProceed()) {
                setDeveloperEditing({
                  isNew: true,
                  newIdEdited: false,
                  index: null,
                  id: "",
                  nameEn: "",
                  nameTc: "",
                  nameSc: "",
                  contactName: "",
                  contactInfo: "",
                  timestamp: null,
                  unsaved: true,
                })
                scrollToId(null);
  
                setPendingInputFocus(true);
              }
  
            }}
          />
  
          <ButtonWithLoader
            faIcon={faArrowsRotate}
            variant="secondary"
            loading={loading.check()}
            onClick={async() => {
              if (await unsaveProceed()) {
                setDeveloperEditing(null);
                await reload();
              }
            }}
          />

          {renderPaginationUI({className: "ms-auto"})}

        </div>
  
        <div>
          {/* <AutocompleteSearch
            loading={searchPending}
            placeholder={t("admin.developers.search")}
            hideDropdown
            input={searchInput}
            onChange={(value: string) => {
              setSearchInput(value);
              startSearchTransition(() => {
                // setDevelopersFiltered(searchDevelopers(value, developers))
              })
            }}
          /> */}
          {/* <SearchInput /> */}
          {renderSearchInput({
            placeholder: t("admin.developers.search")
          })}
        </div>
        <DataList divRef={scrollTopRef} className="scrollable" onClick={() => setSortByDropdownOpen(false)}>
          {
            developersPaginated?.items && (
              developersPaginated?.items.length == 0 && !developerEditing ? (
                <DataList.Empty>{t('commons.noResult')}</DataList.Empty>
              ) : (
                <>{
                  [...developersPaginated?.items, developerEditing].map((developer: Developer, index) => {
                    if (developer == null || (developer as typeof developerEditing).isNew === false) {
                      return;
                    }
    
                    const isNew = (developer as typeof developerEditing)?.isNew;
                    const editing = isNew || developerEditing?.index === index; 
                    const isSelected = props.onSelect && !!props.selectedDevelopers?.find(d => developer.id == d.id);
                    // return <></>
                    return (
                      <DataListItem key={index}
                        clickable
                        active={editing}
                        onClick={async() => {
                          if (!editing && await unsaveProceed()) {
                            setDeveloperEditing({
                              isNew: false,
                              newIdEdited: true,
                              index, 
                              unsaved: false,
                              ...developer
                            });
                            
                            setPendingInputFocus(true);
                            scrollToId(developer.id);
                          }
                        }}
                      >
                        <a id={idPrefix + developer.id} className="anchor" />
                        <DataListItem.Title>
                          <Badge bg="info" className="data-key">id</Badge>
                          {!editing ? <b>{developer.id}</b> :
                          <GenericInput 
                            type="text"
                            value={developerEditing.id} 
                            loading={loading.check()}
                            onChange={(id: string) => {
                              setDeveloperEditing({...developerEditing, id: id.toUpperCase(), newIdEdited: id != "", unsaved: true})
                              
                            }} 
                          />}
                          {
                            !editing && (
                              <DataListItem.TitleBtns>
                                <EntityTimestamp timestamp={developer.timestamp} />
                              </DataListItem.TitleBtns>
                            )
                          }
                        </DataListItem.Title>
                        <DataListItem.Body>
                          <div><Badge bg="info" className="data-key">繁</Badge>{
                            !editing ? <>{developer.nameTc}</> :
                            <GenericInput 
                              inputRef={pendingInputFocus ? (element) => {
                                element?.focus();
                                setPendingInputFocus(false);
                              } : undefined}
                              type="text" 
                              value={developerEditing.nameTc} 
                              loading={loading.check()}
                              onChange={async(nameTc) => {
                                setDeveloperEditing(developerEditing => ({...developerEditing, nameTc, unsaved: true}))
                                const {token, signal} = openCCT2SAbortableLoading.start();
                                try {
                                  const text = await api.openCC.convertHK2S({text: nameTc}, {signal});
                                  setDeveloperEditing(developerEditing => ({...developerEditing, nameSc: text, unsaved: true}))
                                } catch (e) {
                                  modal.errorSpecific(e);
                                } finally {
                                  openCCT2SAbortableLoading.stop(token);
                                }
    
                              }} 
                            />
                          }</div>
                          <div><Badge bg="info" className="data-key">简</Badge>{
                            !editing ? developer.nameSc :
                            <>
                              
                              <GenericInput 
                                type="text" 
                                value={developerEditing.nameSc} 
                                loading={loading.check()}
                                onChange={nameSc => {
                                  openCCT2SAbortableLoading.abort(); 
                                  setDeveloperEditing({...developerEditing, nameSc, unsaved: true});
                                }} 
                              />
                              {
                                openCCT2SAbortableLoading.flag && (
                                  <div>
                                    <Spinner animation="border" size="sm" className="ms-2" />
                                  </div>
                                )
                              }
                            </>
                            
                          }</div>
                          <div><Badge bg="info" className="data-key">EN</Badge>{
                            !editing ? <>{developer.nameEn}</> :
                            <GenericInput 
                              type="text" 
                              value={developerEditing.nameEn} 
                              loading={loading.check()}
                              onChange={nameEn => {
                                const newObj = JSON.parse(JSON.stringify({...developerEditing, nameEn, unsaved: true}));
                                if (!developerEditing.newIdEdited) {
                                  // E.g., "Peter Au Yeung" => "PAY"
                                  newObj.id = nameEn.match(/\b(\w)/g)?.join('')?.toUpperCase() ?? "";
                                }
                                setDeveloperEditing(newObj)
                              }} 
                            />
                          }</div>
                          <div><Badge bg="info" className="data-key"><FontAwesomeIcon icon={faAddressCard} fixedWidth/></Badge>{
                             !editing ? <>{developer.contactName}</> :
                             <GenericInput 
                               type="text" 
                               value={developerEditing.contactName} 
                               loading={loading.check()}
                               onChange={contactName => {
                                 setDeveloperEditing(developerEditing => ({
                                  ...developerEditing, contactName, unsaved: true
                                 }))
                               }} 
                             />
                          }</div>
                          <div><Badge bg="info" className="data-key"><FontAwesomeIcon icon={faPhone} fixedWidth/></Badge>{
                             !editing ? <>{developer.contactInfo}</> :
                             <GenericInput 
                               type="text" 
                               value={developerEditing.contactInfo} 
                               loading={loading.check()}
                               onChange={contactName => {
                                 setDeveloperEditing(developerEditing => ({
                                  ...developerEditing, contactName, unsaved: true
                                 }))
                               }} 
                             />
                          }<ContactShortcut className="ms-2" contact={developer.contactInfo} /></div>
                          {
                            editing && (
                              <div className="btns">
                                <ButtonWithLoader
                                  variant="success"
                                  faIcon={faSave}
                                  loading={loading.check()}
                                  onClick={() => toast.save(async() => {
                                    const token = loading.start();
                                    openCCT2SAbortableLoading.abort();
                                    let developerUpdated: Developer;
                                    try {
                                      if (isNew) {
                                        developerUpdated = await api.developers.create(developerEditing);
                                      } else {
                                        developerUpdated = await api.developers.update(developer.id, developerEditing);
                                      }
                                      
                                      setSearchInput(developerUpdated.id);
                                      setDeveloperEditing(null);
                                      await reload();
                                      setTimeout(() => {
                                        // Delay to make sure reload() has update unsaved status
                                        scrollToId(developerUpdated.id);
                                      }, 100)
    
                                    } catch (e) {
                                      modal.errorSpecific(e);
                                      throw e;
                                    } finally {
                                      loading.stop(token);
                                    }
                                  }, "developer-save")}
                                >{t('entityData.save')}</ButtonWithLoader>
                                {
                                  !isNew && (
                                    <ButtonWithLoader
                                      variant="danger"
                                      faIcon={faTrashCan}
                                      loading={loading.check()}
                                      onClick={async() => {
                                        if (await modal.confirmDelete()) {
                                          toast.delete(async() => {
                                            const token = loading.start();
                                            try {
                                              await api.developers.delete(developer.id);
                                              await reload();
                                              setDeveloperEditing(null);
                                              scrollToId(null);
                                              
                                            } catch (e) {
                                              modal.errorSpecific(e);
                                              throw e;
                                            } finally {
                                              loading.stop(token);
                                            }
                                          }, "developer-delete")
                                        }
                                      }}
                                    >{t('entityData.delete')}</ButtonWithLoader>
    
                                  )
                                }
                                
                                <ButtonWithLoader
                                  variant="info"
                                  faIcon={faBan}
                                  loading={loading.check()}
                                  onClick={async() => {
                                    if (await unsaveProceed()) {
                                      setDeveloperEditing(null);
                                    }
                                  }}
                                >{t('entityData.cancel')}</ButtonWithLoader>
                              </div>
                              
                            )
                          }
                          {
                            !editing && props.onSelect && (
                              <ButtonWithLoader
                                size="sm"
                                variant="success"
                                onClick={() => {
                                  props.onSelect(developer);
                                }}
                                disabled={isSelected}
                                faIcon={isSelected && faCheck}
                              >
                                {t(`admin.commons.${isSelected ? "selected" : "select"}`)}
                              </ButtonWithLoader>
                            )
                          }
                        </DataListItem.Body>
                      </DataListItem>
                    )
                  })
                }
                
                {renderPaginationUI({className: "my-4"})}
                </>
              )
            )
          }
          {/* <AdminTable 
            className="developers-table"
            keys={{
              id: {
                content: t('admin.developers.id'),
              },
              nameEn: {
                content: t('admin.developers.nameEn'),
              },
              nameTc: {
                content: t('admin.developers.nameTc'),
              },
              nameSc: {
                content: t('admin.developers.nameSc'),
              },
              reOrder: {
                content: "",
                shrink: true,
              }, 
              timestamp: {
                content: "",
                shrink: true,
              },
              delete: {
                content: "",
                shrink: true,
              },
            }} 
            rows={
              developers && developers.map((developer, index) => {
                if (developer.mode == "deleted") {return null}
                const editing = developer.mode == "editing";
  
                return {
                  onClick: (event) => {
                    event.stopPropagation();
                    // Set all editing developers as uneditted
                    setDevelopers(developers => developers?.map(developer => {
                      if (developer.mode == "editing") {
                        developer.mode = "edited";
                      }
                      return developer;
                    }))
                    editDeveloper(index, {
                      ...developer,
                      mode: "editing",
                    })
                  },
                  content: {
                    id: !editing ? developer.id :
                      <GenericInput type="text" value={developer.id} onChange={(id: string) => editDeveloper(index, {...developer, id: id.toUpperCase()}, true)} />,
                    nameEn: !editing ? developer.nameEn :
                      <GenericInput type="text" value={developer.nameEn} onChange={nameEn => editDeveloper(index, {...developer, nameEn}, true)} />,
                    nameTc: !editing ? developer.nameTc :
                      <GenericInput type="text" value={developer.nameTc} onChange={nameTc => editDeveloper(index, {...developer, nameTc}, true)} />,
                    nameSc: !editing ? developer.nameSc :
                      <GenericInput type="text" value={developer.nameSc} onChange={nameSc => editDeveloper(index, {...developer, nameSc}, true)} />,
                    reOrder: (
                      <div className="d-flex">
                        <button className="btn btn-outline-info btn-sm me-1" disabled={index == 0}
                          onClick={(event) => {
                            event.stopPropagation();
                            let developersCloned = [...developers];
                            [developersCloned[index-1], developersCloned[index]] = [developersCloned[index], developersCloned[index-1]];
                            setDevelopers(developersCloned);
                            setUnsavedOrders(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowUp} />
                        </button>
                        
                        <button className="btn btn-outline-info btn-sm" disabled={index == developers.length - 1}
                          onClick={(event) => {
                            event.stopPropagation();
                            let developersCloned = [...developers];
                            [developersCloned[index], developersCloned[index+1]] = [developersCloned[index+1], developersCloned[index]];
                            setDevelopers(developersCloned);
                            setUnsavedOrders(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowDown} />
                        </button>
                      </div>
                    ),
                    timestamp: developer.timestamp && <EntityTimestamp created={developer.timestamp.created} updated={developer.timestamp.updated} />,
                    delete: (
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={async(event) => {
                          event.stopPropagation();
                          if (await modal.confirmDelete()) {
                            editDeveloper(index, {...developer, mode: "deleted"}, true)
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                    ),
                  }
                }
              })
            }
          /> */}
        </DataList>
      </>
    )
  }

export const AdminDevelopers = () => {
  const {t} = useI18N();
  useAdminComponent({selectedItem: AdminDevelopersPath});


  return (
    <AdminComponent.Container 
      onClick={() => {

      }}
    >
      <AdminHeaderTitle>{t('admin.developers.title')}</AdminHeaderTitle>
      <AdminComponent.TitleBar>
        <AdminComponentTitle faIcon={faPersonDigging}>
          {t('admin.developers.pageTitle')}
        </AdminComponentTitle>
      </AdminComponent.TitleBar>

      
      <div className="hr" />
      
      <AdminDevelopersComponent shouldUseSearchParams/>
    </AdminComponent.Container>
  )
  
}


export const AdminDeveloperPreview = (props: {
  developer: Developer
  onDelete?: (developer: Developer) => void;
}) => {
  const {developer} = props;

  return developer && (
    <div className="preview">
      <div><Badge bg="info" >id</Badge> {developer.id}</div>
      <div><Badge bg="info" >繁</Badge> {developer.nameTc}</div>
      <div><Badge bg="info" >简</Badge> {developer.nameSc}</div>
      <div><Badge bg="info" >EN</Badge> {developer.nameEn}</div>
      <ButtonWithLoader 
        faIcon={faTrashCan}
        variant="danger"
        size="sm"
        onClick={() => {
          props.onDelete?.(developer)
        }}
      />
    </div>
  )

}
export const AdminDevelopersSelector = (props: EntitySelectorParentProps<Developer[]>) => {
  const {t} = useI18N();
  const [modalOpen, setModalOpen] = useState(false);

  const developers: Developer[] = useMemo(() => (
    [...(props.item ?? [])]
  ), [props.item])

  return (
    <>
      <EntitySelector
        {...props}
        item={null}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        icon={faPersonDigging}
        title={t('admin.developers.add')}
        preview={
          developers?.map(developer => (
            <AdminDeveloperPreview 
              key={developer.id}
              developer={developer}
              onDelete={() => {
                const index = developers.findIndex(d => d.id == developer.id);
                index != -1 && developers.splice(index, 1);
                props.onChange(developers);
              }}
            />
          ))
        }
        modalContent={<AdminDevelopersComponent onSelect={(developer) => {
          setModalOpen(false);
          developers.push(developer);
          props.onChange?.(developers);
        }}
          selectedDevelopers={developers}
        />}
      />
    </>

  )
}
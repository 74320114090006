import React, { ComponentPropsWithRef, useCallback, useEffect, useMemo, useState } from "react";
import { User } from "../../../api/entities/user.entity";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";
import { AdminTable, AdminTableLink } from "../component/Table";
import { AdminUserPath, AdminUsersPath } from "..";
import { AdminPagination } from "../component/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faCheck, faExclamationTriangle, faUserPlus, faUsers } from "@fortawesome/free-solid-svg-icons";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider";
import { Paginated } from "../../../api/entities/pagination";
import { useFindAllQuery } from "../component/FindAllQuery";
import { useAbortable } from "../../../utils/abortable-loading";
import { ButtonWithLoader, LinkWithLoader } from "../../../component/ButtonWithLoader";
import ReactTooltip from "@huner2/react-tooltip";
import moment from "moment";
import { APIInstanceWithAuth } from "../../../api";
import { AnalyzeUserActivityResult, UserActivity } from "../../../api/entities/user-activity.entity";
import { Spinner } from "react-bootstrap";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";

export const AdminUserStatusDot = (props: {status: "online" | "offline"} & ComponentPropsWithRef<"div">) => {
  const {status, ...divProps} = props;
  return <div
    {...divProps}
    style={{
      width: "8px", height: "8px",
      borderRadius: "50%",
      backgroundColor: status == "online" ? "#00b000" : "#dadada",
      ...props.style
    }}
  />
}

export const AdminUserActivityAnalysisBrief = (props: {
  api: APIInstanceWithAuth,
  userId: User["id"],
}) => {
  const {api, userId} = props;
  const [initLoading, setInitLoading] = useState(true);
  const [activity, setActivity] = useState<AnalyzeUserActivityResult>(null);
  const hourRange = 12;
  const activityBriefData = useMemo(() => {
    if (activity) {
      const thisHour = moment().startOf("hour");
      const briefCounts: {
        key: string,
        count: number
      }[] = [];

      for (let i = hourRange - 1; i >= 0; --i) {
        const time = moment().startOf("hour").subtract(i, "hours");
        // console.log(time.toDate())
        const count = activity.counts.find(c => +new Date(c.date) == +time.toDate())?.count ?? 0;
        briefCounts.push({key: time.format("HH"), count});
        
      }
      if (activity.counts.length) {
        console.log(activity);
        console.log(briefCounts);

        return briefCounts;

      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [activity])

  const init = useCallback(async() => {
    try {
      // setActivity(await api.users.analyzeActivity(userId, {
      //   interval: "hour",
      //   from: moment().subtract(hourRange, "hours").toISOString(),
      //   to: moment().toISOString()
      // }))
    } catch (e) {

    }
    setInitLoading(false);
  }, [])
  useEffect(() => {
    init();
  }, [])

  return <div
    className="w-100 h-100"
    onClick={(event) => {
      event.stopPropagation();
      event.preventDefault();
    }}
  >{
    initLoading ? (
      <Spinner animation="border" size="sm"/>
    ) : (
      activity ? (
        activityBriefData ? (
          <ResponsiveContainer width={82} height={25}>
            <AreaChart
              width={60}
              height={25}
              data={activityBriefData}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis hide dataKey="key" />
              <YAxis hide domain={[0, 60]}/>
              {/* <Tooltip /> */}
              <Area type="monotone" dataKey="count" stroke="#25394e" fill="#25394e" />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <span className="text-muted">-</span>
        )
      ) : (
        <span className="text-muted"><FontAwesomeIcon icon={faExclamationTriangle} /></span>
      )
    )
  }</div>
}

export const AdminUsers = (props: React.PropsWithChildren<{}>) => {
  const {api, loading, setSelectedItem} = useAdminComponent();
  const {t} = useI18N();
  const navigate = useNavigate();
  const modal = useGlobalModal();
  
  const [isInit, setIsInit] = useState(true);
  const [usersData, setUsersData] = useState<Paginated<User & {isOnline: boolean}> | null>(null);
  const reloadAbortable = useAbortable();

  const {findAllQuery, renderPaginationUI, renderSearchInput, setSearchInput, scrollTopRef, setSortByDropdownOpen} 
    = useFindAllQuery({
      isInit, loading: loading.flag, paginationMeta: usersData?.meta,
      shouldUseSearchParams: true
      // sortKeys: ["id", "nameEn", "nameTc", "nameSc", "timestamp.created", "timestamp.updated"]
    })

  const reload = async() => {
    let token = loading.start();
    try {
      const usersData = await api.users.findAll(findAllQuery);
      setUsersData({
        ...usersData,
        items: usersData.items.map(user => ({
          ...user,
          isOnline: user.lastActive && (new Date().getTime() - new Date(user.lastActive).getTime() < 5 * 60 * 1000)
        }))
      });
    } catch (e) {
      modal.errorSpecific(e);
    }
    loading.stop(token);
  }

  useEffect(() => {
    if (isInit) {
      console.log(findAllQuery);
      // TODO: Handle unsaved prompt from external use
      reload();
    }
  }, [findAllQuery]);

  useEffect(() => {
    setSelectedItem(AdminUsersPath);
    reload().then(() => setIsInit(true));
  }, []);

  return (
    <AdminComponent.Container>

      <AdminHeaderTitle>{t('admin.users.title')}</AdminHeaderTitle>
      <AdminComponent.TitleBar>
        <AdminComponentTitle 
          faIcon={faUsers}
        >
          {t('admin.users.pageTitle')}
          
        </AdminComponentTitle>
        <LinkWithLoader
          loading={loading.flag}
          className="btn btn-primary ms-auto"
          to={`../${AdminUserPath}/new`} 
          faIcon={faUserPlus}
        />
        <ButtonWithLoader
          variant="secondary"
          faIcon={faArrowsRotate}
          onClick={async() => {
            await reload();
          }}
        >
        </ButtonWithLoader>
      </AdminComponent.TitleBar>
      
      <div className="hr" />
      <div className="d-flex align-items-center mb-2" style={{gap: "0.5em", flexWrap: "wrap"}}>
        {renderPaginationUI({className: "mx-auto"})}
      </div>
      
      <div className="card overflow-card">
        <AdminTable
          sticky
          loading={loading.check()}
          // editable
          keys={{
            id: {
              content: "id",
              shrink: true,
            },
            username: {
              content: t('admin.user.username')
            },
            // email: {
            //   content: t('admin.user.email')
            // },
            lastSeen: {
              content: t('admin.users.lastSeen')
            },
            // activity: {
            //   content: t('admin.users.activityAnalysis.titleShort'),
            //   shrink: true
            // },
            isActive: {
              content: t('admin.user.active'),
              shrink: true,
            },
            isVerified: {
              content: t('admin.user.verified'),
              shrink: true,
            },
            isAdmin: {
              content: t('admin.user.admin'),
              shrink: true,
            }
          }}

          rows={
            usersData && (
              usersData.items.map((user) => {
                const editLink = `../${AdminUserPath}?id=${user.id}`;
                const componentId = "user-" + user.id;
                return {
                  content: {
                    id: user.id,
                    username: <div className="d-flex align-items-center justify-content-center"
                    >
                      {user.username}
                    </div>,
                    // email: user.email,
                    lastSeen: <div className="d-flex align-items-center justify-content-center">
                      {
                        user.lastActive && (
                          user.isOnline ? 
                            <AdminUserStatusDot status="online" className="me-2" />
                          : <AdminUserStatusDot status="offline" className="me-2" />
                        )
                      }
                      {user.lastActive ?
                         moment(Math.min(new Date(user.lastActive).getTime(), new Date().getTime())).fromNow() : 
                         <span className="text-black-50 fst-italic">{t('admin.users.never')}</span>
                      
                        }
                    </div>,
                    // activity: <AdminUserActivityAnalysisBrief key={user.id} api={api} userId={user.id} />,
                    isActive: user.isActive ? <FontAwesomeIcon icon={faCheck} />: "",
                    isVerified: user.isVerified ? <FontAwesomeIcon icon={faCheck} /> : "",
                    isAdmin: user.isAdmin ? <FontAwesomeIcon icon={faCheck} /> : "",
                  },
                  // editLink: `../${AdminUserPath}?id=${user.id}`,
                  rowLink: `../${AdminUserPath}?id=${user.id}`,
                }
              })
            )
          }
        />
      </div>
    </AdminComponent.Container>
  )
}

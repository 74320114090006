import { AxiosInstance } from "axios";
import { APIBase } from "./apiBase";
import { APIEncodeQueryString } from "./helpers";

export default class MailAPI extends APIBase {
  

  constructor(axios: AxiosInstance) {
    super(axios, "mail");
  }

  async test(receipient: string): Promise<boolean> {
    return (await this.axios.get<boolean>(
      this.base + "/test?" + APIEncodeQueryString({receipient})
    )).data;
  }
}
import { AxiosInstance } from "axios";
import { I18N, I18NDto } from "../entities/i18n.entity";
import { APIBase } from "./apiBase";
import { APIEncodeQueryString } from "./helpers";

export default class I18NAPI extends APIBase {
  

  constructor(axios: AxiosInstance) {
    super(axios, "i18n");
  }

  async findAll(): Promise<I18N[]> {
    return (await this.axios.get<I18N[]>(
      this.base
    )).data;
  }

  async findById(id: string): Promise<I18N> {
    return (await this.axios.get<I18N>(
      this.base + "/" + id
    )).data;
  }

  async getJson(id: string): Promise<Object> {
    return (await this.axios.get<Object>(
      this.base + "/json/" + id,
    )).data;
  }

  async checkAlias(id: string): Promise<string> {
    return (await this.axios.get<string>(
      this.base + "/check?" + APIEncodeQueryString({id})
    )).data;
  }

  async updateOrderId(id: string, orderId: number): Promise<I18N> {
    return (await this.axios.put<I18N>(
      this.base + "/" + id + "/orderId/" + orderId
    )).data;
  }

  async update(id: string, dto: I18NDto): Promise<I18N> {
    return (await this.axios.put<I18N>(
      this.base + "/" + id,
      dto
    )).data;
  }

  async create(dto: I18NDto): Promise<I18N> {
    return (await this.axios.post<I18N>(
      this.base,
      dto
    )).data;
  }

  async delete(id: string): Promise<void> {
    return (await this.axios.delete<void>(
      this.base + "/" + id
    )).data;
  }

}
import { AxiosInstance, AxiosRequestConfig } from "axios";
import { BackupFile, BackupConfig, BackupConfigDto, BackupResult } from "../entities/backup.entity";
import { Developer, DeveloperDto, DeveloperSort } from "../entities/developer.entity";
import { FindAllQuery } from "../entities/findAllQuery";
import { I18N, I18NDto } from "../entities/i18n.entity";
import { Paginated } from "../entities/pagination";
import { S3File } from "../entities/s3-file-system";
import { SystemConfig } from "../entities/system-config.entity";
import { APIBase } from "./apiBase";
import { APIEncodeQueryString } from "./helpers";

export default class BackupAPI extends APIBase {
  

  constructor(axios: AxiosInstance) {
    super(axios, "backup");
  }

  async getConfig(): Promise<BackupConfig> {
    return (await this.axios.get<BackupConfig>(
      this.base + "/config"
    )).data;
  }

  async patchConfig(dto: BackupConfigDto): Promise<SystemConfig> {
    return (await this.axios.put<SystemConfig>(
      this.base + "/config", dto
    )).data;
  }

  async testEmail(): Promise<void> {
    return (await this.axios.post<void>(
      this.base + "/test-email"
    )).data;
  }

  async listBackups(config?: AxiosRequestConfig): Promise<S3File[]> {
    return (await this.axios.get<S3File[]>(
      this.base, config
    )).data;
  }

  async download(id: string, config?: AxiosRequestConfig): Promise<string> {
    return (await this.axios.get<string>(
      this.base + "/" + id,
      {
        // responseType: "blob",
        ...config
      }
    )).data;
  }

  async delete(id: string, config?: AxiosRequestConfig): Promise<void> {
    return (await this.axios.delete<void>(
      this.base + "/" + id, config
    )).data;
  }

  async createBackup(): Promise<BackupResult> {
    return (await this.axios.post<BackupResult>(
      this.base,
    )).data;
  }



}
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet-async";

export interface GoogleAuthResponse {
  clientId: string,
  credential: string,
  select_by?: string,
};

export const GoogleAuthButton = (props: {
  clientId: string
  handleResponse: (reponse: GoogleAuthResponse) => void | Promise<void>
}) => {
  const [signinWidth, setSigninWidth] = useState<number>(null);
  const script = useRef<HTMLScriptElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (window as any).handleGoogleCredentialResponse = async (response: GoogleAuthResponse) => {
      console.log(response);
      props.handleResponse?.(response);
    }

    setSigninWidth(divRef.current.offsetWidth);

    if (!script.current) {
      script.current = document.createElement("script");
      script.current.src = "https://accounts.google.com/gsi/client";
      script.current.async = true; 
      script.current.defer = true;
      document.body.appendChild(script.current);
    }
    

    return () => {
      delete (window as any).handleGoogleCredentialResponse;
      

      if (script.current) {
        // document.body.removeChild(script.current);
      }
    }
  }, [])

  useEffect(() => {

  }, [divRef])
  return (
    <>
      <Helmet>
        <script src="https://accounts.google.com/gsi/client" async defer></script>
      </Helmet>
      <div id="g_id_onload"
        data-client_id={props.clientId}
        data-context="use"
        data-ux_mode="popup"
        data-callback="handleGoogleCredentialResponse"
        data-auto_prompt="false"
      >
      </div>

      <div
        className="w-100" 
        ref={divRef}
      >
      </div>
      {
        
        signinWidth && (
          <div className="g_id_signin w-100"
            ref={(element) => {
              // let width = element?.clientWidth;
              // width && setSigninWidth(width);
            }}
            data-type="standard"
            data-shape="rectangular"
            data-theme="outline"
            data-text="signin_with"
            data-size="large"
            data-locale="en"
            data-logo_alignment="left"
            data-width={signinWidth}
          >
          </div>
        )
      }
      

    </>
  )
}

export class GoogleAuthButtonHOC extends React.Component<{
  clientId: string
  handleResponse: (reponse: GoogleAuthResponse) => void | Promise<void>
}> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    (window as any).handleGoogleCredentialResponse = async (response: GoogleAuthResponse) => {
      console.log(response);
      
      this.props.handleResponse(response);

    }
  }

  render() {
    return (
      <>
        <Helmet>
          <script src="https://accounts.google.com/gsi/client" async defer></script>
        </Helmet>
        
        <div id="g_id_onload"
          data-client_id={this.props.clientId}
          data-context="use"
          data-ux_mode="popup"
          data-callback="handleGoogleCredentialResponse"
          data-auto_prompt="false"
        >
        </div>

        <div className="g_id_signin"
          data-type="standard"
          data-shape="rectangular"
          data-theme="outline"
          data-text="signin_with"
          data-size="large"
          data-locale="en"
          data-logo_alignment="left"
          data-width="298"
        >
        </div>

      </>
    )
  }
}
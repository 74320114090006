import { faArrowDown19, faArrowDownAZ, faArrowsRotate, faArrowUp91, faArrowUpZA, faCircleCheck, faCircleInfo, faCircleXmark, faCrosshairs, faCube, faCubes, faFileSignature, faFolderOpen, faFolderPlus, faGrip, faHouse, faInfoCircle, faList, faMinimize, faPause, faTrash, faTrashCan, faTriangleExclamation, faUpload, faUpRightFromSquare, faWindowMaximize } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { filesize } from "../../../utils/filesize";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import "../styles/file-manager.scss";
import * as path from "path-browserify";
import { useDropzone } from "react-dropzone";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useNavigate } from "react-router-dom";
import { FileViewerProvider, useFileViewer } from "./FileViewer";
import { FileViewerImage } from "./file-viewer/Image";
import { faAws } from "@fortawesome/free-brands-svg-icons";
import { AxiosProgressEvent, Canceler } from "axios";
import { useLoadingManager } from "../../../utils/loading-manager";
import { DropzoneFile } from "../../../api/src/apiBase";
import { escapeRegExp } from "../../../utils/escapeRegExp";
import { FileManagerStat } from "../../../api/src/file-manager";

export const AWS_S3_PREFIX = "aws-s3";
export const AWS_S3_URL_PREFIX = AWS_S3_PREFIX + "://";


export interface FileManagerStatWithMime extends FileManagerStat {
  mimeType: string;
  mimeTypeAllowed: boolean;
  icon: IconProp;
}

export type FileManagerViewMode = 'list' | 'icon'; 

export interface FileManagerProps {
  basePath: string,
  awsS3Path?: string,
  api: {
    get(path: string, deepScan?: boolean): Promise<FileManagerStat>,
    list(path?: string, deepScan?: boolean): Promise<FileManagerStat[]>,
    delete(path: string): Promise<void>,
    makeDir(path: string): Promise<string>,
    rename(from: string, to: string): Promise<string>,
    upload(
      files: File[], 
      path: string,
      overwrite: boolean,
      onUploadProgress?: (e: AxiosProgressEvent) => void,
      cancelerHandler?: (canceler: Canceler) => void,
    ): Promise<void>
  },
  initialPath?: string
  initialSelectedFilePath?: string,
  allowedMimeTypes?: RegExp   // For choosing
  onPathChange?: (newPath: string) => void;
  onDeleteConfirm?: () => boolean | Promise<boolean>;
  onRename?: (originalName: string) => string | Promise<string|null> | null;
  onOverwrite?: () => boolean | Promise<boolean>;
  onCreateFolder?: () => string | Promise<string>;
  onError?: (e) => void | Promise<void>;
  onChoose?: {
    mode: 'file' | 'folder',
    handler: (src: string | string[]) => void
  } | {
    mode: 'files', 
    handler: (src: string[]) => void
  }
};

export interface PendingFileGroup {
  id: number;
  files: File[];
  totalFilesize: number;
  folderName?: string;         // For folder upload only
  overwrite?: boolean;
  progress: number;
  status: 'pending' | 'uploading' | 'complete' | 'error';
  preDeleted: boolean;
  canceler?: Canceler
}

export const FileManager = (props: FileManagerProps) => {
  return (
    <FileViewerProvider basePath={props.basePath} awsS3Path={props.awsS3Path}>
      <FileManagerChildren {...props} />
    </FileViewerProvider>
  )
}

export const FileManagerChildren = (props: FileManagerProps) => {
  const navigate = useNavigate();
  // States
  const [isInit, setIsInit] = useState(false);
  const initialPath = 
    (props.initialSelectedFilePath?.replace(new RegExp("^(.*)" + escapeRegExp(path.basename(props.initialSelectedFilePath)) + "/?$"), "$1") ||
    props.initialPath || "")
    console.log(initialPath);
  const [currentPath, setCurrentPath] = useState(initialPath);
  const currentPathTree = useMemo(() => {
    console.log("currentPath: ");
    console.log(currentPath);

    let AWS_S3_PREFIX_MATCH = currentPath?.match(new RegExp(`^(${AWS_S3_URL_PREFIX})(.*)$`));
    if (AWS_S3_PREFIX_MATCH) {
      let SUFFIX = AWS_S3_PREFIX_MATCH[2];
      return [
        "",
        AWS_S3_PREFIX_MATCH[1], // "aws-s3://"
        ...(SUFFIX && SUFFIX.replace(/\/+$/, "").split('/'))
      ]
    } else {
      return currentPath ? ["", ...currentPath.replace(/\/$/, "").split('/')] : [""];
    }
  }, [currentPath])
  const currentPathRef = useRef(initialPath);

  const [files, setFiles] = useState<FileManagerStatWithMime[]>(null);
  const filesLastFetchTime = useRef(new Date());
  const [serviceRootFolders, setServiceRootFolders] = useState<FileManagerStatWithMime[]>([]);
  const [folders, setFolders] = useState<FileManagerStatWithMime[]>([]);
  const [filesMimeTypeAllowed, setFilesMimeTypeAllowed] = useState<FileManagerStatWithMime[]>([]);
  const [filesMimeTypeNotAllowed, setFilesMimeTypeNotAllowed] = useState<FileManagerStatWithMime[]>([]);
  const filesRef = useRef<FileManagerStatWithMime[]>(null);
  const [selectedFullPaths, setSelectedFilesFullPath] = useState(new Set<string>());
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [contextMenuPos, setContextMenuPos] = useState({top: "0", left: "0"});
  const [sortedBy, setSortedBy] = 
    useState<'name' | 'name-desc' | 'size' | 'size-desc' | 'modified' | 'modified-desc'>('name');
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false); 
  const [viewMode, setViewMode] = useState<FileManagerViewMode>('icon');

  const bodyTableElement = useRef<HTMLDivElement>(null);
  const contextMenuElement = useRef<HTMLDivElement>(null);

  const AWS_S3_ELEMENT = useMemo(() => {
    return (
      <b><FontAwesomeIcon icon={faAws} className="me-2"/>S3</b>
    )
  }, []);
  const {processFile, openFileViewer, openFileProperty} = useFileViewer();
  if (!openFileViewer) {
    throw new Error("useFileViewer() returns null, FileViewerProvider needs to be bounded")
  }

  // Loading manager
  const loading = useLoadingManager();
  const backgroundLoading = useLoadingManager();

  const pendingFilesStatusRef = useRef<{[key: number]: 'pending' | number | 'done'}>({}); // Only for CREATE, start upload and DELETE reference purpose
  const pendingFilesCounter = useRef(0);    // For index generation
  const pendingFileGroupsReducer = (
    prevState: PendingFileGroup[], 
    action: {
      type: 'create' | 'create-onload' | 'update' | 'pre-delete' | 'delete',
      filesWithId?: {id: number, files: File[]}[],            // For create
      id?: number,                                         // For update
      pendingFileGroup?: Partial<PendingFileGroup>,   // For update
    }
  ) => {
    let newPendingFileGroups = [...prevState];

    switch (action.type) {
      case 'create':
        if (!action.filesWithId) {throw new Error("Missing 'file' on dispatchPendingFiles.create")}
        for (let {id, files} of action.filesWithId) {
          let newPendingFileGroup: PendingFileGroup = {
            id,
            files,
            totalFilesize: files.map(f=>f.size).reduce((a,b) => a+b),
            progress: 0,
            status: 'pending',
            preDeleted: false,
            ...action.pendingFileGroup
          };
          pendingFilesStatusRef.current[id] = 'pending';
          newPendingFileGroups.unshift(newPendingFileGroup);
        }
        break;
        
      case 'update': 
      case 'pre-delete': 
      case 'delete': 
        if (action.id == null) {throw new Error(`Missing 'id' on dispatchPendingFiles.${action.type}`)}
        let index = newPendingFileGroups.findIndex(f => f.id == action.id);
        if (index === -1) {console.warn(`Missing file id ${action.id} on dispatchPendingFiles.${action.type}`)}
          
        switch (action.type) {
          case 'update': 
            if (!action.pendingFileGroup) {throw new Error(`Missing 'pendingFileGroup' on dispatchPendingFiles.${action.type}`)}
            newPendingFileGroups[index] = {
              ...newPendingFileGroups[index],
              ...action.pendingFileGroup
            }
          break;


          case 'pre-delete': 
            newPendingFileGroups[index] = {
              ...newPendingFileGroups[index],
              preDeleted: true
            }
          break;

          case 'delete':
            newPendingFileGroups.splice(index, 1);
            delete pendingFilesStatusRef.current[action.id];
          break;
        }
        break;

    }

    return newPendingFileGroups;
  }

  const [pendingFileGroups, dispatchPendingFileGroups] = useReducer(pendingFileGroupsReducer, []);

  // Methods

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    const x = event.pageX - window.scrollX;
    const y = event.pageY - window.scrollY;
    const bodyTableElementRect = bodyTableElement.current.getBoundingClientRect();
    const parentX = bodyTableElementRect.left;
    const parentY = bodyTableElementRect.top;
    var top = y - parentY;
    var left = x - parentX;

    console.log(bodyTableElement.current.scrollTop);
    
    const topLimit = 
      bodyTableElementRect.top
      + bodyTableElement.current.clientHeight 
      - contextMenuElement.current.offsetHeight;

    const leftLimit = 
      bodyTableElementRect.left
      + bodyTableElementRect.width
      - contextMenuElement.current.offsetWidth;

    if (y > topLimit) {
      top -= contextMenuElement.current.offsetHeight;
    }
    if (x > leftLimit) {
      left -= contextMenuElement.current.offsetWidth;
    }

    top += bodyTableElement.current.scrollTop;

    // console.log(left, top);
    // console.log(top, left);
    // console.log(topLimit, leftLimit);
    setContextMenuOpen(true);
    setContextMenuPos({top: top + "px", left: left + "px"});

  }

  const handleError = async(e: any): Promise<void> => {
    if (props.onError) {
      await props.onError(e);
    } else {
      alert("Error occured!");
    }
    console.error(e);
    return;
  }

  const handleOnChoose = () => {
    console.log("handleOnChoose");
    if (props.onChoose) {
      if (props.onChoose.mode === 'file' || props.onChoose.mode === 'files') {
        let selectedFiles = [...selectedFullPaths].map(
          (fullPath) => files.find((file) => file.path === fullPath && file.isFile)
        )
        if (props.onChoose.mode === 'file') {
          props.onChoose.handler(selectedFiles[0]?.path);
        } else {
          props.onChoose.handler(selectedFiles.map((file) => file.path));
        }
      } else if (props.onChoose.mode === 'folder') {
        if (selectedFullPaths.size === 0) {
          // Choose current path as the folder if no folder is selected
          props.onChoose.handler(currentPathRef.current);
        } else {
          // Choose selected folder
          let selectedFolder = [...selectedFullPaths].find(
            (fullPath) => files.find((file) => file.path === fullPath && file.isDir)
          )
          props.onChoose.handler(selectedFolder);
        }
        

      }
    }

  }




  const fetch = async(options?: {
    local?: boolean,               // default false
    backgroundLoading?: boolean,   // default false
  }) => {
    // console.debug("fetching from " + currentPathRef.current);
    // console.debug("local: " + local);
    var newFiles: FileManagerStatWithMime[] = [];

    if (!options?.local) {
      console.log("Start fetching again");
      let token = (!options?.backgroundLoading ? loading : backgroundLoading).start();
      let fetchTime = new Date();
      filesLastFetchTime.current = fetchTime;
      try {
        let newFilesRaw = await props.api.list(currentPathRef.current);
        if (fetchTime < filesLastFetchTime.current) {
          // This fetching thread runs earlier than another fetching thread
          return;
        }
        for (let newFileRaw of newFilesRaw) {
          newFiles.push(processFile(newFileRaw, props.allowedMimeTypes));
        }
      } catch (e) {
        console.error(e);
        handleError(e);
      } finally {
        (!options?.backgroundLoading ? loading : backgroundLoading).stop(token);
      }
    } else {
      newFiles = [...(files ?? [])];
    }



    newFiles = newFiles.sort((a, b) => {
      switch (sortedBy) {
        case 'name': 
          return a.fileName > b.fileName ? 1 : -1;
        case 'name-desc': 
          return a.fileName < b.fileName ? 1 : -1;
        case 'size': 
          return a.size > b.size ? 1 : -1;
        case 'size-desc': 
          return a.size < b.size ? 1 : -1;
        case 'modified': 
          return a.mtime > b.mtime ? 1 : -1;
        case 'modified-desc': 
          return a.mtime < b.mtime ? 1 : -1;
      }
    })
    let serviceRootFolders = newFiles?.filter(f => f.isServiceRootDir);
    let folders = newFiles?.filter(f => !f.isServiceRootDir && f.isDir);
    let filesMimeTypeAllowed = newFiles?.filter(f => f.isFile && f.mimeTypeAllowed);
    let filesMimeTypeNotAllowed = newFiles?.filter(f => f.isFile && !f.mimeTypeAllowed);
    
    newFiles = [...serviceRootFolders, ...folders, ...filesMimeTypeAllowed, ...filesMimeTypeNotAllowed];

    setFiles(newFiles);
    filesRef.current = newFiles;
    setServiceRootFolders(serviceRootFolders);
    setFolders(folders);
    setFilesMimeTypeAllowed(filesMimeTypeAllowed);
    setFilesMimeTypeNotAllowed(filesMimeTypeNotAllowed);

    setSelectedFilesFullPath(new Set());
  }


  const selectFile = (file: FileManagerStatWithMime, mode?: 'ctrl' | 'shift' | 'context-menu'): Set<string> => {
    if ((file && !file.mimeTypeAllowed)) {
      return null;
    }

    let selectedFilePathsClone = new Set(selectedFullPaths);
    if (!mode) {
      selectedFilePathsClone.clear();
      if (file) {
        selectedFilePathsClone.add(file.path);
      }
    } else if (mode == 'ctrl') {
      let fileFullPath = file.path;
      if (selectedFilePathsClone.has(fileFullPath)) {
        selectedFilePathsClone.delete(fileFullPath);
      } else {
        selectedFilePathsClone.add(fileFullPath);
      }
    } else if (mode == 'shift') {
      let array = Array.from(selectedFilePathsClone);
      var fromIndex = 0;
      var toIndex = files.findIndex((f) => f.path == file.path);
      if (array.length) {
        fromIndex = files.findIndex((f) => f.path == array[0]);
      }

      if (fromIndex > toIndex) {
        [fromIndex, toIndex] = [toIndex, fromIndex];
      }

      console.log(fromIndex, toIndex);
      for (let i = fromIndex; i <= toIndex; i++) {
        let tmpFile = files[i];
        // console.log(tmpFile);
        if (!selectedFilePathsClone.has(tmpFile.path)) {
          selectedFilePathsClone.add(tmpFile.path);
        }
      }
    } else if (mode == 'context-menu') {
      console.log('context-menu');
      if (!file) {
        selectedFilePathsClone.clear();
      } else if (!selectedFilePathsClone.has(file.path)) {
        selectedFilePathsClone.clear();
        selectedFilePathsClone.add(file.path);
      } else {
        
      }

    }
    setSelectedFilesFullPath(selectedFilePathsClone);
    return selectedFilePathsClone;
  }

  const openFile = async(file: FileManagerStatWithMime) => {
    if (file.isDir) {
      // Open directory
      setCurrentPath(file.path);
    } else if (file.isFile) {
      openFileViewer(file.path, (props.onChoose) && file.mimeTypeAllowed && (() => {
        handleOnChoose();
      }), props.api);
      // Open file
      // props.onOpen && props.onOpen(file.path, file.mimeTypeAllowed);
    }
  }



  const renderChooseFileButton = (hiddenIfDisabled = false) => {
    if (!props.onChoose) {
      return null;
    }

    var disabled = false;
    if (props.onChoose.mode == 'file') {
      // Disable when not only one selected file, OR, if selected one is a folder
      disabled = selectedFullPaths.size == 0 
        || selectedFullPaths.size != 1 
        || !!files.find((file) => [...selectedFullPaths][0] === file.path && !file.isFile);
    } else if (props.onChoose.mode == 'folder') {
      // Disable when not only one selected file, OR, if selected one is a file
      disabled = 
      selectedFullPaths.size != 0 
      && (selectedFullPaths.size != 1 || !!files.find((file) => [...selectedFullPaths][0] === file.path && !file.isDir));
    }

    return !(hiddenIfDisabled && disabled) && (
      <li 
        className="choose-button"
        data-disabled={disabled}
        onClick={(event) => {
          event.stopPropagation();
          setContextMenuOpen(false);
          handleOnChoose();
        }}
      >
        <FontAwesomeIcon className="icon" icon={faCrosshairs} fixedWidth/>
        Choose
      </li>
    )
  }

  const renderNewFolderButton = () => {
    return (
      <li
        onClick={async(event) => {
          event.stopPropagation();
          setContextMenuOpen(false);
          var newFolderName = "";
          if (props.onCreateFolder) {
            newFolderName = await props.onCreateFolder();
          } else {
            newFolderName = prompt("New folder name");
          }

          if (newFolderName) {
            let token = loading.start();
            try {
              let newPath = await props.api.makeDir(path.join(currentPath, newFolderName));
              await fetch();
              selectFile(files.find((f) => f.path == newPath));
            } catch (e) {
              handleError(e);
            } finally {
              loading.stop(token);
            }
          }
         
        }}
      >
        <FontAwesomeIcon className="icon" icon={faFolderPlus} fixedWidth/>
        New Folder
      </li>
    )
  }


  const renderDeleteButton = (hiddenIfDisabled = false) => {
    let disabled = selectedFullPaths.size == 0;

    return !(hiddenIfDisabled && disabled) && (
      <li 
        data-disabled={disabled}
        onClick={async(event) => {
          event.stopPropagation();
          setContextMenuOpen(false);
          var result = false;
          if (props.onDeleteConfirm) {
            result = await props.onDeleteConfirm();
          } else {
            result = window.confirm("Are you sure to delete?");
          }
          if (result) {
            let token = loading.start();
            try {
              for (let path of selectedFullPaths) {
                await props.api.delete(path);
              }
              await fetch();
            } catch (e) {
              handleError(e);
            } finally {
              loading.stop(token);
            }
          }
        }}
      >
        <FontAwesomeIcon className="icon" icon={faTrashCan} fixedWidth/>
        Delete
      </li>
    )
  }
  
  const renderPropertyButton = () => {
    let disabled = selectedFullPaths.size > 1;
    let src = selectedFullPaths.size == 0 ? currentPathRef.current : [...selectedFullPaths][0]
    return (
      <li
        data-disabled={disabled}
        onClick={(event) => {
          event.stopPropagation();
          setContextMenuOpen(false);
          console.log(src);
          openFileProperty(src, props.api);
        }}
      >
        <FontAwesomeIcon className="icon" icon={faInfoCircle} fixedWidth />
        Property
      </li>
    )
  }

  const onDrop = useCallback(async(acceptedFiles: DropzoneFile[]) => {
    // Do something with the files
    console.log(acceptedFiles);

    if (acceptedFiles.length) {
      // Separate files by group
      let preFileGroup: {[key: string]: File[]} = {
        "": []      // No folder
      }

      var overwrite: boolean = null;

      for (let file of acceptedFiles) {
        let rootPath = file.path?.match(/^\/([^\/]+)\/.+$/)?.[1] || "";

        preFileGroup[rootPath] = [...(preFileGroup[rootPath] || []), file];

        if (overwrite === null) {
          if (filesRef.current.find(f => (rootPath && f.isDir) ? (f.fileName == rootPath) : (f.fileName == file.name))) {
            if (props.onOverwrite) {
              overwrite = await props.onOverwrite();
            } else {
              overwrite = window.confirm("File(s) exist. Press 'Yes' to overwrite, 'No' to keep both");
            }
          }
        }

      }

      console.log(preFileGroup);

      for (let rootPath of Object.keys(preFileGroup)) {
        let files = preFileGroup[rootPath];
        let currentPath = currentPathRef.current;

        if (rootPath) {
          // Folder
          let id = pendingFilesCounter.current++;
          
          dispatchPendingFileGroups({
            type: 'create',
            filesWithId: [{
              id, 
              files
            }],
            pendingFileGroup: {
              folderName: rootPath,
              overwrite
            }
          })
        } else {
          // Individual files
          dispatchPendingFileGroups({
            type: 'create',
            filesWithId: files.map((file) => {
              let id = pendingFilesCounter.current++;
              return {id, files: [file], dirPath: currentPath};
            }),
            pendingFileGroup: {
              overwrite
            }
          })
        }
        
      }
    
    }

    
    // loading.stop(token);
    setUploadDialogOpen(true);
    // startUpload();
  }, [])

  useEffect(() => {
    for (let pendingFileGroup of pendingFileGroups) {
      
      let {id, files} = pendingFileGroup;
      
      if (pendingFilesStatusRef.current[id] !== 'pending') {continue;}

      let uploadingCount = Object.values(pendingFilesStatusRef.current).filter(ref => typeof ref === "number").length;
      console.log(uploadingCount);
      if (uploadingCount > 10) {continue;}

      pendingFilesStatusRef.current[id] = 0;

      props.api.upload(
        files, 
        currentPathRef.current,
        pendingFileGroup.overwrite,
        (event) => {
          let {loaded, total} = event;
          let uploadProgress = loaded / total;
          let lastUIUpdate = ((pendingFilesStatusRef.current[id] !== 'pending') ? pendingFilesStatusRef.current[id] : 0) as number;
          console.log(uploadProgress);
          if (uploadProgress == 1 || lastUIUpdate + 500 < new Date().getTime()) {
            pendingFilesStatusRef.current[id] = new Date().getTime();

            dispatchPendingFileGroups({
              type: 'update',
              id,
              pendingFileGroup: {
                status: 'uploading',
                progress: uploadProgress
              },
            })
          }
          
        },
        (canceler) => {
          dispatchPendingFileGroups({
            type: 'update',
            id,
            pendingFileGroup: {
              canceler
            }
          })
        }
      ).then(() => {
        pendingFilesStatusRef.current[id] = 'done';
        dispatchPendingFileGroups({
          type: 'update',
          id,
          pendingFileGroup: {
            status: 'complete'
          },
        })
        // fetch
        fetch({backgroundLoading: true}).then()
        // selectFile(files.find((f) => f.path == newFilePath));
        // Select uploaded file
      }).catch((e) => {
        pendingFilesStatusRef.current[id] = 'done';
        dispatchPendingFileGroups({
          type: 'update',
          id,
          pendingFileGroup: {
            status: 'error'
          },
        })
        props.onError(e);
      })
    }


  }, [pendingFileGroups]);

  const {getRootProps, getInputProps, isDragActive, open} = useDropzone({
    onDrop,
    noClick: true,
    multiple: true
  })

  // Initial
  useEffect(() => {
    console.debug(currentPath, currentPathRef);
    fetch().then(() => setIsInit(true));
  }, [])

  // State change handler
  useEffect(() => {
    if (!isInit) return;
    console.log("Current path: " + currentPath);
    currentPathRef.current = currentPath;

    props.onPathChange?.(currentPathRef.current);
    


    fetch();
  }, [currentPath, isInit])

  useEffect(() => {
    if (!isInit) return;
    // console.log("Current sorted by: " + sortedBy);
    fetch({local: true});
  }, [sortedBy, isInit])
  


  const renderFileTable = (files: FileManagerStatWithMime[]) => {
    return (
      <table 
        data-view-mode={viewMode}
        className="file-manager-main-file-table"
      >
        <tbody>
          {
            files.map((file, index) => {
                if (typeof file === "string") {

                  // Row break
                  // files[index] => If has next element (i.e., next element is file <= the directory is not folders-only)
                  // index != 0 => If this is not the first element (i.e., the directory is not files-only)
                  if (files[index] && index != 0) {
                    return (
                      <>
                        <tr className="ms-auto my-0" style={{opacity: 0, height: 0, display: 'block'}}/>
                        <div className="hr mx-2 mt-2" />
                      </>
                    )
                  }
                  
                } else {
                  return (
                    <tr 
                      key={index} 
                      className={clsx(
                        "file-manager-main-file-table-item",
                        selectedFullPaths.has(file.path) && "selected",
                        !file.mimeTypeAllowed && "mime-not-allowed"
                      )}
                      onDoubleClick={() => {
                        openFile(file);
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        setContextMenuOpen(false);
                        if (event.ctrlKey) {
                          console.log("Ctrl - click");
                          selectFile(file, 'ctrl');
                        } else if (event.shiftKey) {
                          console.log("Shift - click");
                          selectFile(file, 'shift');
                        } else {
                          selectFile(file);
                        }
                      }}
                      onContextMenu={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        let selected = selectFile(file, 'context-menu');
                        selected && handleContextMenu(event);
                      }}
                    >
                      {
                        viewMode == 'icon' && file.isFile &&
                        <td className="preview">
                          <div className="content">
                            {
                              file.mimeType?.match(/^image\//) ? (
                                <FileViewerImage
                                  className="image"
                                  src={file.path}
                                />
                              ) : (
                                <FontAwesomeIcon className="icon" icon={file.icon} />
                              )
                            }
                          </div>
                        </td>
                      }

                      <td className="name">
                        <FontAwesomeIcon
                          className="icon"
                          icon={file.icon}
                          fixedWidth
                        />
                        {file.isServiceRootDir ? (
                          <>
                            {file.service == "aws-s3" ? AWS_S3_ELEMENT : 
                            <b>External Storage Service</b>}
                          </>
                        ) : file.fileName
                        }
                      </td>
                      {
                        viewMode == 'list' && (
                          <>
                            <td className="size">
                              {file.isFile ? filesize(file.size) : ""}
                            </td>
                            <td className="modified">
                              {file.mtime && moment(file.mtime).format('YYYY-MM-DD HH:mm:ss')}
                            </td>
                          </>
                        )
                      }
                      
                    </tr>
                  )
                }
              }
            )
          }
        </tbody>
      </table>
    )
  }


  // Render
  return (
    <div 
      className="file-manager"
      {...getRootProps()}
    >
      <input 
        {...getInputProps()}
      />
      <div 
        className="file-manager-main"
        onClick={(event) => {
          console.debug("Clicking blank area");
          selectFile(null);
          setContextMenuOpen(false);
        }}
      >

        <div className={clsx("file-manager-main-loading", loading.check() && "active")}>
          <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>

        <div className={clsx("file-manager-main-dragging", isDragActive && "active")}>
          <div className="icon"><FontAwesomeIcon icon={faUpload} /></div>
          <div className="text">Drop to upload folders and files</div>
        </div>
        <div className="file-manager-main-path-bar">

          {
            currentPathTree.map((subPath, index) => {
              let element: React.ReactNode = subPath;

              if (index == 0) {
                element = <FontAwesomeIcon icon={faHouse} />
              }

              if (subPath == AWS_S3_URL_PREFIX) {
                element = AWS_S3_ELEMENT;
              }
              
              return (
                <React.Fragment key={index}>
                  <div 
                    className="path"
                    onClick={() => {
                      console.log(currentPathTree);
                      var targetPath = currentPathTree.slice(1, index + 1).join('/');
                      targetPath = targetPath.replace(/\:\/\/\/+/, "://"); // For external service ://// => ://
                      targetPath = targetPath.replace(/(\:\/\/.+)/, "$1/"); // Add ending / for external service
                      console.log("ON CLICK", targetPath)
                      setCurrentPath(targetPath);
                    }}
                  >
                    {element}
                  </div>
                  
                  {
                    (index < currentPathTree.length - 1) && 
                    <div className="path-to">
                      {"／"}
                    </div>
                  }
                </React.Fragment>
              )
            })
          }
          <div className={clsx("background-loading ms-auto me-1", backgroundLoading.check() && "show")}>
            <FontAwesomeIcon icon={faArrowsRotate} size="lg" />
          </div>
        </div>

        <div className="file-manager-main-function-bar">
          <ul>
            {renderChooseFileButton()}
            {renderNewFolderButton()}
            {renderDeleteButton()}
            {renderPropertyButton()}
            <li onClick={() => {
              fetch();
            }}>
              <FontAwesomeIcon className="icon" icon={faArrowsRotate} />
              Refresh
            </li>

            <li className="ms-auto" onClick={() => {
              setViewMode(viewMode == 'list' ? 'icon' : 'list')
            }}>
              <FontAwesomeIcon className="icon" icon={viewMode == 'list' ? faList : faGrip} />
              {viewMode == 'list' ? "List View" : "Icon View"}
            </li>
          </ul>
        </div>
        
        {
          viewMode == 'list' && (
            <div className="file-manager-main-file-table-header-table">
              <table className="file-manager-main-file-table">
                <thead>
                  <tr>
                    <th
                      className="name"
                      onClick={() => {
                        setSortedBy(sortedBy === 'name' ? 'name-desc' : 'name');
                      }}
                    >
                      <div>
                        Name
                        {
                          ['name', 'name-desc'].includes(sortedBy) && 
                          <FontAwesomeIcon 
                            className="icon" 
                            icon={sortedBy == 'name' ? faArrowDownAZ : faArrowUpZA}
                          />
                        }
                      </div>
                    </th>
                    <th
                      className="size"
                      onClick={() => {
                        setSortedBy(sortedBy === 'size' ? 'size-desc' : 'size');
                      }}
                    >
                      <div>
                        Size
                        {
                          ['size', 'size-desc'].includes(sortedBy) && 
                          <FontAwesomeIcon 
                            className="icon" 
                            icon={sortedBy == 'size' ? faArrowDown19 : faArrowUp91}
                          />
                        }
                      </div>
                    </th>
                    <th
                      className="modified"
                      onClick={() => {
                        setSortedBy(sortedBy === 'modified' ? 'modified-desc' : 'modified');
                      }}
                    >
                      <div>
                        Modified
                        {
                          ['modified', 'modified-desc'].includes(sortedBy) && 
                          <FontAwesomeIcon 
                            className="icon" 
                            icon={sortedBy == 'modified' ? faArrowDown19 : faArrowUp91}
                          />
                        }
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          )
        }

        <div 
          className={clsx("file-manager-main-file-table-body-table")}
          data-view-mode={viewMode}
          onContextMenu={(event) => {
            event.preventDefault();
            selectFile(null);
            handleContextMenu(event);
          }}
          onScroll={(event) => {
            event.preventDefault();
            setContextMenuOpen(false);
          }}
          ref={bodyTableElement}
        >
          <div 
            className={clsx("context-menu", contextMenuOpen && "active")}
            style={contextMenuPos}
            ref={contextMenuElement}
            onContextMenu={(event) => {
              event.stopPropagation();
            }}
          >
            <ul>
              {
                renderChooseFileButton(true)
              }
              {
                selectedFullPaths.size == 1 && (
                  <li
                    onClick={async (event) => {
                      event.stopPropagation();
                      let fileFullPath = [...selectedFullPaths][0];
                      console.log(fileFullPath);
                      setContextMenuOpen(false);
                      openFile(files.find((f) => f.path == fileFullPath));
                      
                    }}
                  >
                    <FontAwesomeIcon className="icon" icon={faUpRightFromSquare} fixedWidth/>
                    Open
                  </li>
                )
              }
              
              {
                renderNewFolderButton()
              }

              {
                renderDeleteButton()
              }

              {
                selectedFullPaths.size == 1 && (
                  <li
                    onClick={async(event) => {
                      event.stopPropagation();
                      setContextMenuOpen(false);
                      let srcPath = [...selectedFullPaths][0];
                      let pathParsed = path.parse(srcPath);
                      let dir = pathParsed.dir;
                      let originalName = pathParsed.base;
                      var newName = "";
                      if (props.onRename) {
                        newName = await props.onRename(originalName);
                      } else {
                        newName = prompt("New name", originalName);
                      }

                      if (newName !== null && originalName !== newName) {
                        let token = loading.start();
                        try {
                          let dstPath = dir + "/" + newName;
                          let newPath = await props.api.rename(srcPath, dstPath);
                          await fetch();
                          console.log(files.find((f) => f.path == newPath));
                          selectFile(files.find((f) => f.path == newPath));
                        } catch (e) {
                          handleError(e);
                        } finally {
                          loading.stop(token);
                        }
                      }
                    }}
                  >
                    <FontAwesomeIcon className="icon" icon={faFileSignature} fixedWidth/>
                    Rename
                  </li>
                )
              }
              {
                renderPropertyButton()
              }
            </ul>
          </div>

          {
            files && files.length == 0 && (
              <div className="file-manager-main-no-file">
                Empty Directory
              </div>
            )
          }
          
          {
            files && (
              viewMode == 'list' ? renderFileTable([...serviceRootFolders, ...folders, ...filesMimeTypeAllowed, ...filesMimeTypeNotAllowed]) : (
                <>
                  {
                    renderFileTable([...serviceRootFolders, ...folders])
                  }
                  {
                    folders.length > 0 && (filesMimeTypeAllowed.length + filesMimeTypeNotAllowed.length) > 0 && <div className="hr my-0" />
                  }
                  {
                    renderFileTable([...filesMimeTypeAllowed, ...filesMimeTypeNotAllowed])
                  }
                </>
              )
            )
          }
        </div>

        






        <div className={clsx("upload-dialog", uploadDialogOpen && "open")}
          onContextMenu={(event) => {
            event.stopPropagation();
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div 
            className={"upload-dialog-bar"} 
            onClick={() => {
              open();
              setUploadDialogOpen(true);
            }}
          >
            <FontAwesomeIcon className="icon" icon={faUpload} />
            Upload {pendingFileGroups.length > 0 && (
              <span>&nbsp;({pendingFileGroups.filter(file => file.status == "complete").length} / {pendingFileGroups.length})</span>
            )}
            <FontAwesomeIcon 
              className="maximize-icon" 
              icon={faWindowMaximize}
              onClick={(e) => {
                e.stopPropagation();
                setUploadDialogOpen(!uploadDialogOpen);
              }}
            />
          </div>
          <div className="upload-dialog-content">
            {
              pendingFileGroups.map((pendingFileGroup: PendingFileGroup, index) => {
                let fileProcessed = processFile({
                  fileName: pendingFileGroup.folderName || pendingFileGroup.files?.[0]?.name,
                  isFile: !pendingFileGroup.folderName,
                  isDir: !!pendingFileGroup.folderName
                })

                return (
                  <div key={pendingFileGroup.id} className={clsx("upload-file", pendingFileGroup.preDeleted && "hidden")}>
                    <div className={clsx("upload-file-loading")}>
                      {
                        pendingFileGroup.status == 'pending' ? (
                          <FontAwesomeIcon icon={faPause} className="text-info" />
                        ) : pendingFileGroup.status == 'uploading' ? (
                          <div className="lds-ring lds-ring-sm"><div></div><div></div><div></div><div></div></div>
                        ) : pendingFileGroup.status == 'error' ? (
                          <FontAwesomeIcon icon={faTriangleExclamation} className="text-danger" />
                        ) : pendingFileGroup.status == 'complete' ? (
                          <FontAwesomeIcon icon={faCircleCheck} className="text-success" />
                        ) : <></>
                      }
                      
                    </div>
                    <div className="upload-file-info">
                      <div className="name"><FontAwesomeIcon icon={fileProcessed.icon} fixedWidth className="me-2"/>{fileProcessed.fileName}</div>
                      <div className="size">
                        {filesize(pendingFileGroup.totalFilesize)}
                        {
                          ['uploading', 'complete'].includes(pendingFileGroup.status) && (
                            `  (${(pendingFileGroup.progress*100).toFixed(1)}%)`
                          )
                        }
                      </div>
                    </div>
                    <div 
                      className="upload-file-cancel"
                      onClick={() => {
                        if (pendingFileGroup.status !== 'uploading') {
                          // Pre-delete for animation
                          dispatchPendingFileGroups({
                            type: "pre-delete",
                            id: pendingFileGroup.id,
                          })
                          setTimeout(() => {
                            dispatchPendingFileGroups({
                              type: "delete",
                              id: pendingFileGroup.id,
                            })
                          }, 500)
                        } else {
                          // Cancel upload
                          pendingFileGroup.canceler && pendingFileGroup.canceler();
                          dispatchPendingFileGroups({
                            type: "update",
                            id: pendingFileGroup.id,
                            pendingFileGroup: {
                              status: 'error'
                            }
                          })
                        }
                        
                      }}
                    >
                      <FontAwesomeIcon icon={faCircleXmark} />
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

      {
        selectedFullPaths && (
          <div className="file-manager-sidebar">
            
          </div>
        )
      }
      
    </div>
  )
}

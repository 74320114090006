import axios, { AxiosInstance, AxiosResponse, Canceler } from "axios";
import { GlobalSystemConfig, SystemConfig, SystemConfigDto } from "../entities/system-config.entity";
import { APIBase, DropzoneFile } from "./apiBase";



export default class SystemConfigAPI extends APIBase {
  

  constructor(axios: AxiosInstance) {
    super(axios, "system-config");
  }

  async get(): Promise<GlobalSystemConfig> {
    return (await this.axios.get(this.base)).data;
  }

  async getAll(): Promise<SystemConfig> {
    return (await this.axios.get(this.base + "/all")).data;
  }

  async edit(dto: SystemConfigDto): Promise<SystemConfig> {
    return (await this.axios.put(this.base, dto)).data;
  }
}

// export function commify(n: number): string | null {
//   if (n == null || isNaN(n)) {return null;}
//   var parts = n.toString().split(".");
//   const numberPart = parts[0];
//   const decimalPart = parts[1];
//   const thousands = /\B(?=(\d{3})+(?!\d))/g;
//   return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
// }

import { commify } from "./commify";
import { roundToDp } from "./round-to-dp";

export const numberInShort = (n: number | null): string | null => {

  if (n == null || isNaN(n)) {return null;}

  if (n >= 1_000_000_000) {
    let approx = roundToDp(n / 1_000_000_000, 2) + "B"
    if (n % 10_000_000 != 0) {
      approx = "~" + approx;
    } 
    return approx;
  } else if (n >= 1_000_000) {
    let approx = roundToDp(n / 1_000_000, 2) + "M"
    if (n % 10_000 != 0) {
      approx = "~" + approx;
    } 
    return approx;
  } else if (n >= 10_000) {
    let approx = roundToDp(n / 1_000, 1) + "K"
    if (n % 100 != 0) {
      approx = "~" + approx;
    } 
    return approx;
  } else {
    return commify(n);
  }

}

export const floorToDp = (num: number, dp: number) => {
  const multiplier = Math.pow(10, dp);
  return Math.floor(num * multiplier) / multiplier;
}

export const numberInShortPublic = (n: number | null | undefined,
  lang: "tc" | "sc" | "en" | string
): string | null => {

  if (n == null || isNaN(n)) {return null;}


  switch (lang) {
    case "en": 
      if (n >= 1_000_000_000) {
        let approx = commify(n / 1_000_000_000) + "B"
        // if (n % 10_000_000 != 0) {
        //   approx = approx + "+";
        // } 
        return approx;
      } else if (n >= 1_000_000) {
        let approx = commify(n / 1_000_000) + "M"
        // if (n % 10_000 != 0) {
        //   approx = approx + "+";
        // } 
        return approx;
      } else {
        return commify(n);
      }

    case "tc":
    case "sc":
      if (n >= 1_0000_0000) {
        let approx = commify(n / 1_0000_0000) + {
          "tc": "億", "sc": "亿"
        }[lang]
        // if (n % 100_0000 != 0) {
        //   approx = approx + "+";
        // } 
        return approx;
      } else if (n >= 10_0000) {
        let approx = commify(n / 1_0000) + {
          "tc": "萬", "sc": "万"
        }[lang]
        // if (n % 100 != 0) {
        //   approx = approx + "+";
        // } 
        return approx;
      } else {
        return commify(n);
      }
  }

}

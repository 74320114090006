
import { faCheck, faCheckCircle, faDownload, faXmark, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import mimeDb from "mime-db";
import moment from "moment";
import path from "path-browserify";
import { ComponentProps, useCallback, useEffect, useState } from "react";
import { ButtonGroup, Modal, Spinner } from "react-bootstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { PropertyListing } from "../../../api/entities/property/property-listing.entity";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider";
import { NullDisplay } from "../../../component/NullDisplay";
import { commify } from "../../../utils/commify";
import { useLoadingManager } from "../../../utils/loading-manager"
import { useAdminComponent } from "../component/AdminComponent";
import { AdminBuildingAndPropertyImageToolI18NPrefix } from "../component/BuildingAndPropertyImageTool";
import { EntityData } from "../component/EntityData";
import { AdminPropertyListingI18NPrefix, PropertyListingStatusDisplay } from "./PropertyListings";
import { Buffer } from 'buffer';


export const PropertyListingComponent = (props: {
  id: string,
  onUpdate?: (propertyListing: PropertyListing) => void
} & ComponentProps<"div">) => {
  const {id, onUpdate, ...divProps} = props;
  const {api, reloadPropertyListingCountPendingItems} = useAdminComponent();
  const loading = useLoadingManager();
  const {t} = useI18N();
  const [propertyListing, setPropertyListing] = useState<PropertyListing & {
    photosWithPresignedUrl: string[],
    agentNameCardPhotosWithPresignedUrl: string[],
  } | null>(null);
  const modal = useGlobalModal();

  const [selectedPhoto, setSelectedPhoto] = useState<{
    key: string,
    presignedUrl: string
  } | null>(null);
  const singleImageDownloading = useLoadingManager();
  const [modalOpen, setModalOpen] = useState(false);
  
  const reload = useCallback(async() => {

    if (id) {
      const token = loading.start();
      try {
        const obj = await api.propertyListing.findById(id);
        setPropertyListing({
          ...obj, 
          photosWithPresignedUrl: await Promise.all(obj.photos?.map(async(key) => {
            try {
              return await api.propertyListing.getPresignedPhotoUrl(key)
            } catch (e) {
              return "";
            }
          })),
          agentNameCardPhotosWithPresignedUrl: await Promise.all(obj.agentNameCardPhotos?.map(async(key) => {
            try {
              return await api.propertyListing.getPresignedPhotoUrl(key)
            } catch (e) {
              return "";
            }
          })),
        });
        onUpdate?.(obj);
      } catch (e) {
        modal.errorSpecific(e);
      }
      loading.stop(token);
      reloadPropertyListingCountPendingItems();
    }
  }, [id]);

  useEffect(() => {
    if (!id) {
      setPropertyListing(null);
    } else {
      reload();
    }
  }, [id, reload])


  const changeStatus = useCallback(async(status: PropertyListing["status"]) => {
    try {
      if (await modal.confirm(
        t(AdminPropertyListingI18NPrefix + 'changeStatus.title'), 
        <>{t(AdminPropertyListingI18NPrefix + 'changeStatus.description')} {t(AdminPropertyListingI18NPrefix + 'statuses.' + status)}</>
      )) {
        const token = loading.start()
        try {
          await api.propertyListing.updateStatus(id, status);
          reload();
        } catch (e) {
          modal.errorSpecific(e);
        }
        loading.stop(token);
        
      }
    } catch (e) {
      
    }
  }, [id, reload])

  return <div {...divProps}>
    <EntityData
      className="pb-4"
      style={{height: "100%", width: "100%"}}
      width="thin"
      loading={loading.flag}
      tight
      object={propertyListing}
      meta={propertyListing && {
        status: {
          title: t(AdminPropertyListingI18NPrefix + "status"),
          type: "valueComponent",
          component: <>
            <ButtonGroup>
              <ButtonWithLoader variant={propertyListing.status == "pending" ? "warning" : "outline-warning"} loading={loading.flag} onClick={() => changeStatus("pending")}>{
                t(AdminPropertyListingI18NPrefix+"statuses.pending")
              }</ButtonWithLoader>
              <ButtonWithLoader variant={propertyListing.status == "approved" ? "success" : "outline-success"} loading={loading.flag} faIcon={faCheckCircle} onClick={() => changeStatus("approved")}>{
                t(AdminPropertyListingI18NPrefix+"statuses.approve")
              }</ButtonWithLoader>
              <ButtonWithLoader variant={propertyListing.status == "rejected" ? "danger" : "outline-danger"} loading={loading.flag} faIcon={faXmarkCircle} onClick={() => changeStatus("rejected")}>{
              t(AdminPropertyListingI18NPrefix+"statuses.reject")
              }</ButtonWithLoader>
            </ButtonGroup>
          </>,
          divWidth: {xs: 12}
        },
        created: {
          title: t(AdminPropertyListingI18NPrefix + "submissionTime"),
          type: "valueComponent",
          component: <>{moment(propertyListing.created).format("DD-MM-YYYY HH:mm:ss")}</>,
          divWidth: {xs: 12}
        },
        type: {
          title: t(AdminPropertyListingI18NPrefix + "type.title"),
          type: "valueComponent",
          component: <>{t(AdminPropertyListingI18NPrefix + "type." + propertyListing.type)}</>,
          divWidth: {xs: 4}
        },
        isSelling: {
          title: t(AdminPropertyListingI18NPrefix + "isSelling"),
          type: "valueComponent",
          component: <>{propertyListing.isSelling ? <FontAwesomeIcon icon={faCheck} />: ""}</>,
          divWidth: {xs: 4}
        },
        isLeasing: {
          title: t(AdminPropertyListingI18NPrefix + "isLeasing"),
          type: "valueComponent",
          component: <>{propertyListing.isLeasing ? <FontAwesomeIcon icon={faCheck} />: ""}</>,
          divWidth: {xs: 4}
        },
        propertyInfo: {
          title: t(AdminPropertyListingI18NPrefix + "propertyInfo"),
          type: "section",
        },
        districtName: {
          title: t(AdminPropertyListingI18NPrefix + "district"),
          type: "valueComponent",
          component: <>{propertyListing.districtName}</>,
          divWidth: {xs: 6}
        },
        buildingName: {
          title: t(AdminPropertyListingI18NPrefix + "building"),
          type: "valueComponent",
          component: <>{propertyListing.buildingName}</>,
          divWidth: {xs: 6}
        },
        block: {
          title: t(AdminPropertyListingI18NPrefix + "block"),
          type: "valueComponent",
          component: <>{propertyListing.block || <NullDisplay />}</>,
          divWidth: {xs: 4}
        },
        floor: {
          title: t(AdminPropertyListingI18NPrefix + "floor"),
          type: "valueComponent",
          component: <>{propertyListing.floor || <NullDisplay />}</>,
          divWidth: {xs: 4}
        },
        flat: {
          title: t(AdminPropertyListingI18NPrefix + "flat"),
          type: "valueComponent",
          component: <>{propertyListing.flat || <NullDisplay />}</>,
          divWidth: {xs: 4}
        },

        price: {
          title: t(AdminPropertyListingI18NPrefix + "price"),
          type: "valueComponent",
          component: <>{propertyListing.price != null ? `$${commify(propertyListing.price)}` : <NullDisplay />}</>,
          divWidth: {xs: 6}
        },
        rent: {
          title: t(AdminPropertyListingI18NPrefix + "rent"),
          type: "valueComponent",
          component: <>{propertyListing.rent != null ? `$${commify(propertyListing.rent)}` : <NullDisplay />}</>,
          divWidth: {xs: 6}
        },

        remarks: {
          title: t(AdminPropertyListingI18NPrefix + "remarks"),
          type: "valueComponent",
          component: <>{propertyListing.remarks || <NullDisplay />}</>,
          divWidth: {xs: 12}
        },
        photos: {
          title: `${t(AdminPropertyListingI18NPrefix + "photos")} (${propertyListing.photos?.length})`,
          type: "valueComponent",
          component: <>{propertyListing.photos.length > 0 ? (
            <div className="d-flex align-items-start flex-wrap" style={{gap: "0.6em"}}>{
              propertyListing.photos?.map((photo, index) => {
                const presignedUrl = propertyListing.photosWithPresignedUrl?.[index];
                return (
                  <div style={{maxWidth: 120, height: 120, cursor: "pointer"}}
                    onClick={() => {
                      setSelectedPhoto({key: photo, presignedUrl})
                      setModalOpen(true);
                    }}
                  >
                    <img src={presignedUrl} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
                  </div>
                )
              })
            }</div>

          ) : <NullDisplay />}</>,
          divWidth: {xs: 12}
        },
        contact: {
          title: t(AdminPropertyListingI18NPrefix + "contact"),
          type: "section",
        },
        lastName: {
          title: t(AdminPropertyListingI18NPrefix + "lastName"),
          type: "valueComponent",
          component: <>{propertyListing.lastName || <NullDisplay />}</>,
          divWidth: {xs: 6}
        },
        firstName: {
          title: t(AdminPropertyListingI18NPrefix + "firstName"),
          type: "valueComponent",
          component: <>{propertyListing.firstName || <NullDisplay />}</>,
          divWidth: {xs: 6}
        },
        phone: {
          title: t(AdminPropertyListingI18NPrefix + "phone"),
          type: "valueComponent",
          component: <>{propertyListing.phone || <NullDisplay />}</>,
          divWidth: {xs: 6}
        },
        email: {
          title: t(AdminPropertyListingI18NPrefix + "email"),
          type: "valueComponent",
          component: <>{propertyListing.email || <NullDisplay />}</>,
          divWidth: {xs: 6}
        },
        agentNameCardPhotos: {
          title: `${t(AdminPropertyListingI18NPrefix + "agentNameCardPhotos")} (${propertyListing.agentNameCardPhotos?.length})`,
          type: "valueComponent",
          component: <>{propertyListing.agentNameCardPhotos.length > 0 ? (
            <div className="d-flex align-items-start flex-wrap" style={{gap: "0.6em"}}>{
              propertyListing.agentNameCardPhotos?.map((photo, index) => {
                const presignedUrl = propertyListing.agentNameCardPhotosWithPresignedUrl?.[index];
                return (
                  <div style={{maxWidth: 120, height: 120, cursor: "pointer"}}
                    onClick={() => {
                      setSelectedPhoto({key: photo, presignedUrl})
                      setModalOpen(true);
                    }}
                  >
                    <img src={presignedUrl} style={{width: "100%", height: "100%", objectFit: "contain"}}/>
                  </div>
                )
              })
            }</div>

          ) : <NullDisplay />}</>,
          divWidth: {xs: 12}
        },
      }}
    />
    {
      selectedPhoto && (
        <Modal 
          key={selectedPhoto?.key}
          className="building-and-property-image-tool-preview-front"
          show={modalOpen}
          onHide={() => setModalOpen(false)}
          centered
          backdropClassName="building-and-property-image-tool-preview-backdrop-front"
        >
          <Modal.Header closeButton>
            {path.basename(selectedPhoto.key)}
          </Modal.Header>

          <Modal.Body className="p-0">
            <TransformWrapper initialScale={0.8} minScale={0.4} centerOnInit centerZoomedOut disablePadding>
              <TransformComponent wrapperClass="w-100 h-100">
                <img 
                  src={selectedPhoto?.presignedUrl} 
                  className="image-preview p-2"
                  onLoad={() => console.log("Loaded")}
                />
              </TransformComponent>
            </TransformWrapper>
          </Modal.Body>

          <Modal.Footer>
            
           
            <ButtonWithLoader
              faIcon={faDownload}
              loading={singleImageDownloading.flag}
              onClick={async() => {
                const token = singleImageDownloading.start();
                try {
                  const link = document.createElement('a');
                  // https://stackoverflow.com/questions/68953079/axios-cors-not-working-on-chrome-deployed-site
                  const buffer = (await axios.get(selectedPhoto.presignedUrl, {responseType: 'arraybuffer', headers: {'Cache-Control': 'no-cache'}})).data;

                  let ext = path.extname(selectedPhoto.key)?.slice(1)?.toLowerCase();
                  let mimeType = Object.keys(mimeDb).find(
                    (key) => mimeDb[key].source == 'iana' && (mimeDb[key].extensions?.indexOf(ext) as any) >= 0
                  );
                  console.log({buffer});
                  const imageSrc = Buffer.from(buffer).toString('base64');
                  const base64 = `data:${mimeType};base64,` + imageSrc;
                  link.href = base64;
                  
                  link.setAttribute('download', path.basename(selectedPhoto.key));
                  document.body.appendChild(link);
                  link.click();
                } catch (e) {
                  console.warn(e);
                  modal.errorSpecific(e);
                }
                singleImageDownloading.stop(token);
              }}
            >{t(AdminBuildingAndPropertyImageToolI18NPrefix + "download")}
            </ButtonWithLoader>
          </Modal.Footer>
        </Modal>
      )
    }
  </div>
}
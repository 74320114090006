import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { ComponentPropsWithoutRef, PropsWithoutRef, useMemo } from "react";


export const GoogleMapPlaceValue = (options: {
  lang: "en" | "tc",
  country?: string,
  place?: string
}) => {
  let {country, place} = options;

  if (country == undefined) {
    switch (options.lang) {
      case "en": 
        country = "Hong Kong";
        break;
      case "tc":
        country = "香港";
        break;
    }
  }

  switch (options.lang) {
    case "en": 
      return `${place}, ${country}`;
    case "tc":
      return `${country}${place}`;
  }
  
}

export const GoogleMapSearchLink = (props: ComponentPropsWithoutRef<"a"> & {
  lang: "en" | "tc",
  country?: string,
  place: string,
}) => {
  let {lang, country, place, className, ...otherProps} = props;

  const placeValue = useMemo(() => (GoogleMapPlaceValue({lang, country, place})), [props.country, props.place, props.lang]);
  const placeNotEmpty = useMemo(() => (!!place?.trim()), [props.place])

  return placeNotEmpty && (
    <a 
      onClick={e => {e.stopPropagation(); }}
      href={`https://www.google.com/maps/search/${placeValue}`} 
      target="_blank" 
      {...otherProps}
      className={clsx("google-map-link", className)}
    >
      <FontAwesomeIcon icon={faMapLocationDot} />
    </a>
  )
}
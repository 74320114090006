
import { closestCenter, DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { arrayMove, rectSortingStrategy, SortableContext, useSortable } from "@dnd-kit/sortable";
import clsx from "clsx";
import { ComponentPropsWithoutRef, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useI18N } from "../../../component/I18NProvider";
import "../../../styles/admin/property-publishment-images-preview.scss";
import { CSS } from "@dnd-kit/utilities";
import { Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown, faTriangleExclamation, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { useGlobalModal } from "../../../component/GlobalModal";

const RESORUCE_URL = process.env.REACT_APP_PUBLIC_RESOURCE_URL;

export const PropertyPublishmentImagesPreviewImage = (props: {
  imageKey?: string,
  onOpen?: () => void,
} & ComponentPropsWithoutRef<"img">) => {
  const {className, imageKey, onOpen, ...imgProps} = props;
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const modal = useGlobalModal();
  const loading = useMemo(() => !loaded && !error, [loaded, error]);

  return <>
    <img 
      className={clsx(className, (loading || error) && "temp-transparent")}
      onLoad={() => {
        setLoaded(true);
      }}
      onError={() => {
        setError(true);
      }}
      onClick={() => {
        onOpen?.();
      }}
      {...imgProps} 
    />
    {
      loading && <div className={clsx(className, "temp")}><Spinner animation="border" /></div>
    }
    {
      error && <div className={clsx(className, "temp")} ><FontAwesomeIcon icon={faTriangleExclamation} size="2x" className="text-warning"/></div>
    }
  </>
}

const PropertyPublishmentImagesPreviewItem = (props: PropsWithChildren<{
  id: string
  onDelete?: () => void
  isKey?: boolean
}>) => {
  const {id, onDelete, isKey} = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({id});
  const modal = useGlobalModal();
  const style = useMemo(() => (
    {
      transition,
      transform: CSS.Translate.toString(transform),
      zIndex: isDragging ? 1 : 0,
      touchAction: "none"
    }
  ), [transform, isDragging]);

  return (
    <div className={clsx("item", isDragging && "dragging")} ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {
        isKey && <div className="key">
          <FontAwesomeIcon icon={faCrown} className="text-warning" size="lg" />
        </div>
      }
      
      <div className="delete-btn pb-1 ps-1"
        onClick={(event) => {
          event.stopPropagation();
          onDelete?.();
        }}
      >
        <FontAwesomeIcon icon={faXmarkCircle} className="text-danger" size="2x" />
      </div>
      {props.children}
    </div>
  )
}

export const PropertyPublishmentImagesPreview = (props: {
  className?: string;
  imageKeys: string[],
  onChange: (imageKeys: string[]) => void;
  reloadRef?: (fn: () => void) => void;
}) => {
  const {t} = useI18N();
  const {onChange, reloadRef} = props;
  const [reloadKey, setReloadKey] = useState(0);
  const [openedImage, setOpenedImage] = useState<string | null>(null);
  const modal = useGlobalModal();

  const imageKeys = useMemo(() => Array.from(new Set(props.imageKeys)), [props.imageKeys])
  const reload = useCallback(() => {
    setReloadKey(key => (key + 1));
  }, []);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(TouchSensor, {
      // activationConstraint: {
      //   delay: 250,
      //   tolerance: 1,
      // }
    })
  );
  
  useEffect(() => {
    reloadRef?.(reload);
  }, [reloadRef, reload])

  return <div key={reloadKey} className={clsx("property-publishment-images-preview", props.className)}>
    <Modal 
      className="property-publishment-images-preview-modal"
      show={!!openedImage}
      onHide={() => setOpenedImage(null)}
      centered
      backdropClassName="property-publishment-images-preview-modal-backdrop"
    >
      <Modal.Header closeButton>
        {openedImage}
      </Modal.Header>
      <Modal.Body>
        <img src={RESORUCE_URL + "/" + openedImage} className="image" />
      </Modal.Body>
    </Modal>
    <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToParentElement]}
      autoScroll={false}
      onDragEnd={(event) => {
        const {active, over} = event;
        if (active.id !== over.id && Array.isArray(props.imageKeys)) {
          const oldIndex = props.imageKeys.findIndex(key => key == active.id);
          const newIndex = props.imageKeys.findIndex(key => key == over.id);
          console.log(oldIndex, newIndex);
          const newArray = arrayMove(props.imageKeys, oldIndex, newIndex);
          props.onChange?.(newArray);
        }
      }}
    >
      <SortableContext 
        items={imageKeys}
        strategy={rectSortingStrategy}
      >
        {
          imageKeys?.length ? imageKeys.map((imageKey, index) => {
            return (
              <PropertyPublishmentImagesPreviewItem
                key={imageKey}
                id={imageKey}
                isKey={index == 0}
                onDelete={async() => {
                  if (await modal.confirmDelete()) {
                    const newImageKeys = imageKeys.filter(key => key !== imageKey);
                    console.log({imageKeys, newImageKeys});
                    props.onChange?.(newImageKeys)
                  }
                }}
              >
                <PropertyPublishmentImagesPreviewImage 
                  className="image" 
                  imageKey={imageKey} 
                  src={RESORUCE_URL + "/" + imageKey}  
                  onOpen={() => setOpenedImage(imageKey)}
                />
              </PropertyPublishmentImagesPreviewItem>
            )
          }) : (
            <div className="empty">{
              t("entityData.empty")
            }</div>
          )
        }
      </SortableContext>
    </DndContext>
    
  </div>
}
import { AxiosInstance, AxiosRequestConfig } from "axios";
import { OpenCCConvertDto, OpenCCConvertDtoWithConfig } from "../entities/open-cc";
import { APIBase } from "./apiBase";
import { APIEncodeQueryString } from "./helpers";

export default class OpenCCAPI extends APIBase {
  

  constructor(axios: AxiosInstance) {
    super(axios, "open-cc");
  }

  async convert(dto: OpenCCConvertDtoWithConfig, config?: AxiosRequestConfig): Promise<string> {
    return (await this.axios.post<string>(
      this.base + "/convert",
      dto, config
    )).data;
  }

  async convertT2S(dto: OpenCCConvertDto, config?: AxiosRequestConfig): Promise<string> {
    return (await this.axios.post<string>(
      this.base + "/convert-t2s",
      dto, config
    )).data;
  }

  async convertS2T(dto: OpenCCConvertDto, config?: AxiosRequestConfig): Promise<string> {
    return (await this.axios.post<string>(
      this.base + "/convert-s2t",
      dto, config
    )).data;
  }

  async convertHK2S(dto: OpenCCConvertDto, config?: AxiosRequestConfig): Promise<string> {
    return (await this.axios.post<string>(
      this.base + "/convert-hk2s",
      dto, config
    )).data;
  }
}
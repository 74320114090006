import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { AdminBuildingPath } from "..";
import { GlobalModalError, useGlobalModal } from "../../../component/GlobalModal";
import { Building, BuildingDto, BuildingRiseTypes, BuildingTypes } from "../../../api/entities/building.entity";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";
import { EntityData } from "../component/EntityData";
import { DeepPartial } from "react-hook-form";
import { useI18N } from "../../../component/I18NProvider";
import { faBuilding, faCity } from "@fortawesome/free-solid-svg-icons";
import { GoogleApiMapsSearch } from "../component/GoogleApiMapsSearch";
import { GoogleApisMapsPlaceAutocompleteTypePreset } from "../../../api/entities/googleapis";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import clsx from "clsx";
import moment from "moment";
import { useAbortableLoading } from "../../../utils/abortable-loading";
import { AdminDistrictSelector } from "./Districts";
import { AdminDevelopersSelector } from "./Developers";
import { TFunction } from "i18next";
import { Badge } from "react-bootstrap";
import { Lang, LangMap, Langs } from "../../../utils/lang-map";
import { LangPreview } from "../component/LangPreview";
import { AdminBuildingPreview } from "./Buildings";
import { AdminBuildingAndPropertyImageToolI18NPrefix, BuildingAndPropertyImageTool } from "../component/BuildingAndPropertyImageTool";
import { ContactShortcut } from "../component/ContactShortcut";
import { GenericInput } from "../component/GenericInput";
import { ArrayRange } from "../../../utils/array-range";
import { APIInstanceWithAuth } from "../../../api";

export interface AdminBuildingProps {
  mode: 'create' | 'edit';
}

export const AdminBuildingI18NPrefix = "admin.buildings.";

export const BuildingNameFromIdSync = (props: {
  id: Building["id"],
  api: APIInstanceWithAuth
}) => {
  const {id, api} = props;
  const [building, setBuilding] = useState<Building>(null);
  const {currentLang} = useI18N();

  const name = useMemo(() => {
    return building && (
      building?.["name" + LangMap(currentLang)] ||
      building.nameEn || building.nameTc
    )
  }, [building])

  useEffect(() => {
    api.buildings.findById(id).then(building => {
      setBuilding(building);
    })
  }, [id])

  return name || ("#" + id);
}

export const StreetNumberPreview = (t: TFunction, parts: {
  streetNumber: string;
  lang?: Lang | string
}) => {
  let {streetNumber} = parts;
  if (!streetNumber || streetNumber?.trim() == "") {streetNumber = ""}
  return streetNumber != "" ? t(`${AdminBuildingI18NPrefix}preview.streetNumber`, {streetNumber, lng: parts.lang}) : "";
}

export const StreetPreview = (t: TFunction, parts: {
  district?: string;
  streetName: string;
  streetNumber: string;
  lang?: Lang | string
}) => {
  let {district, streetName, streetNumber, lang} = parts;
  if (!streetName || streetName?.trim() == "") {streetName = ""}
  
  const streetNumberPreview = StreetNumberPreview(t, {streetNumber, lang});
  const delimiter = t(`${AdminBuildingI18NPrefix}preview.streetDelimiter`, {lng: lang});
  const array = t(`${AdminBuildingI18NPrefix}preview.street`, {
    district, streetName, streetNumber: streetNumberPreview, lng: lang, returnObjects: true
  }) as string[];

  return array?.filter?.(str => str && str.trim() != "").map(str => str.trim()).join(delimiter) ?? "";
}




export const AdminBuilding = (props: AdminBuildingProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {api, loading, toast, setSelectedItem} = useAdminComponent();
  const modal = useGlobalModal();
  const {t} = useI18N();
  const {mode} = props;
  const {id: idRaw} = useParams<{id: string}>();

  const id: number | null = useMemo(() => {
    if (idRaw == null || mode == "create") {return null;}
    let id = parseInt(idRaw);
    if (isNaN(id) || id.toString() != idRaw) {return null;}
    return id;
  }, [idRaw]);

  const isNew = useMemo(() => (mode == "create"), [idRaw]);
  
  const nameT2SLoading = useAbortableLoading();
  const streetNameT2SLoading = useAbortableLoading();
  

  // States
  const [unsaved, setUnsaved] = useState(false);
  const [building, setBuilding] = useState<Building>({
    id: null,
    nameTc: "",
    nameSc: "",
    nameEn: "",
    district: null,
    streetNameTc: "",
    streetNameSc: "",
    streetNameEn: "",
    streetNumber: "",
    lotNumber: "",
    developers: [],
    type: null,
    riseType: null,
    occupationYear: null,
    occupationMonth: null,
    occupationDay: null,
    managementCompany: "",
    managementContactInfo1: "",
    managementContactInfo2: "",
    managementFeePerUnit: null,
    remark: "",
    timestamp: null,
  });


  const reload = async() => {
    let token = loading.start();
    try {
      setBuilding(await api.buildings.findById(id));

    } catch (e) {
      modal.errorSpecific(e);
    }
    loading.stop(token);
  }

  // Initialize
  useEffect(() => {
    setSelectedItem(AdminBuildingPath);
    if (mode == "edit") {
      reload();
    }
  }, []);

  const title = useMemo(() => {
    switch (mode) {
      case "create": 
        return t(AdminBuildingI18NPrefix + 'createTitle');
      case "edit":
        return `${t(AdminBuildingI18NPrefix + 'editTitle')} (id: ${building?.id ?? "-"})`
    }
  }, [building?.id, mode]);

  const streetPreview: {
    [lang in Lang]: string
  } = useMemo(() => {
    return Langs.reduce((acc, lang) => ({
      ...acc,
      [lang]: building?.["name" + LangMap(lang)] + ", " +
       StreetPreview(t, {
        district: building.district?.["name" + LangMap(lang)],
        streetName: building["streetName" + LangMap(lang)],
        streetNumber: building["streetNumber"],
        lang
      }),
    }), {tc: "", sc: "", en: ""})
  }, [
    building?.nameTc, building?.nameSc, building?.nameEn, 
    building?.streetNameTc, building?.streetNameSc, building?.streetNameEn, 
    building?.streetNumber, building?.district
  ])
  
  // console.log(streetPreview);
  // Render
  if (mode == 'create' && building?.id) {
    // Redirect to the building page for NEWLY CREATED entity
    return <Navigate to={`../${building.id}`} />
  } 

  return (
    <AdminComponent.Container>
      <AdminHeaderTitle>{title}</AdminHeaderTitle>
      <AdminComponent.TitleBar>
        <AdminComponentTitle 
          backTo={`../../${AdminBuildingPath}`}
          faIcon={faCity}
        >
          {title}
        </AdminComponentTitle>
      </AdminComponent.TitleBar>
      <div className="hr"></div>
      {
        <EntityData
          unsaved={unsaved}
          object={building}
          loading={loading.flag}
          timestamp={(mode != "create") && building?.timestamp}
          meta={{
            googleSearch: {
              type: "component",
              component: (
                <div className="d-flex w-100">
                  <GoogleApiMapsSearch 
                    api={api} 
                    types={GoogleApisMapsPlaceAutocompleteTypePreset.Building}
                    placeholder={t(AdminBuildingI18NPrefix + "googleSearch")}
                    onComplete={(result) => {
                      const {nameTc, nameSc, nameEn} = result;
                      setBuilding(building => ({
                        ...building, nameTc, nameSc, nameEn, unsaved: true
                      }))
                    }}
                  />
                </div>
                
              )
            },
            nameTc: {
              title: `${t(AdminBuildingI18NPrefix + 'name')} - 繁`,
              type: "text",
              onChange: async(nameTc: string) => {
                console.log(nameTc);
                const {token, signal} = nameT2SLoading.start();
                try {
                  const text = await api.openCC.convertHK2S({text: nameTc}, {signal});
                  setBuilding(building => ({...building, nameSc: text}))
                  setUnsaved(true);
                } catch (e) {
                  modal.errorSpecific(e);
                } finally {
                  nameT2SLoading.stop(token);
                }
              }
            },
            nameSc: {
              title: `${t(AdminBuildingI18NPrefix + 'name')} - 简`,
              type: "text",
            },
            nameEn: {
              title: `${t(AdminBuildingI18NPrefix + 'name')} - EN`,
              type: "text",
            },
            district: {
              title: t("admin.districts.title"),
              type: "section",
            },
            districtSelector: {
              type: "component",
              component: (
                <AdminDistrictSelector
                  item={building.district}
                  onChange={(district) => {
                    setBuilding(building => ({...building, district}));
                    setUnsaved(true);
                  }}
                />
              )
            },
            streetName: {
              title: t(AdminBuildingI18NPrefix + 'streetName'),
              type: "section",
            },
            googleSearchStreetName: {
              type: "component",
              component: (
                <div className="d-flex w-100">
                  <GoogleApiMapsSearch 
                    api={api} 
                    types={GoogleApisMapsPlaceAutocompleteTypePreset.Street}
                    placeholder={t(AdminBuildingI18NPrefix + "streetNameGoogleSearch")}
                    onComplete={(result) => {
                      const {nameTc, nameSc, nameEn} = result;
                      setBuilding(building => ({
                        ...building, streetNameTc: nameTc, streetNameSc: nameSc, streetNameEn: nameEn, unsaved: true
                      }))
                    }}
                  />
                </div>
                
              )
            },
            streetNameTc: {
              title: `${t(AdminBuildingI18NPrefix + 'streetName')} - 繁`,
              type: "text",
              onChange: async(nameTc: string) => {
                const {token, signal} = streetNameT2SLoading.start();
                try {
                  const text = await api.openCC.convertHK2S({text: nameTc}, {signal});
                  setBuilding(building => ({...building, nameSc: text}))
                  setUnsaved(true);
                } catch (e) {
                  modal.errorSpecific(e);
                } finally {
                  streetNameT2SLoading.stop(token);
                }
              }
            },
            streetNameSc: {
              title: `${t(AdminBuildingI18NPrefix + 'streetName')} - 简`,
              type: "text",
            },
            streetNameEn: {
              title: `${t(AdminBuildingI18NPrefix + 'streetName')} - EN`,
              type: "text",
            },
            streetNumber: {
              title: t(AdminBuildingI18NPrefix + "streetNumber"),
              titleInfo: t(AdminBuildingI18NPrefix + "streetNumberHint"),
              type: "text",
            },
            lotNumber: {
              title: t(AdminBuildingI18NPrefix + "lotNumber"),
              type: "text",
            },
            preview: {
              title: t('admin.commons.preview'),
              type: "valueComponent",
              component: (
                <AdminBuildingPreview building={building} />
              )
            },
            developer: {
              title: t("admin.developers.title"),
              type: "section",
            },
            developerSelector: {
              type: "component",
              component: (
                <AdminDevelopersSelector
                  item={building.developers}
                  onChange={(developers) => {
                    setBuilding(building => ({...building, developers}));
                    setUnsaved(true);
                  }}
                />
              )
            },
            types: {
              title: t(AdminBuildingI18NPrefix + "type.title"),
              type: "section",
            },
            type: {
              title: t(AdminBuildingI18NPrefix + "type.title"),
              type: "select",
              selectOptions: BuildingTypes.map(type => ({
                value: type,
                label: t(`${AdminBuildingI18NPrefix}type.${type}`)
              }))
            },
            riseType: {
              title: t(AdminBuildingI18NPrefix + "riseType.title"),
              type: "select",
              selectOptions: BuildingRiseTypes.map(type => ({
                value: type,
                label: t(`${AdminBuildingI18NPrefix}riseType.${type}`)
              }))
            },
            occupationDateTitle: {
              title: t(AdminBuildingI18NPrefix + "occupationDate"),
              type: "section",
            },
            occupationDate: {
              title: t(AdminBuildingI18NPrefix + "occupationDate"),
              type: "valueComponent",
              // datePickerProps: {
              //   showYearPicker: true,
              //   dateFormat: "yyyy",
              // },
              // valueOverride: building.occupationYear && new Date(new Date().setFullYear(building.occupationYear)),
              // onChangeOverride: (newDate: Date) => {
              //   setBuilding(building => ({...building, occupationYear: newDate?.getFullYear()}));
              //   setUnsaved(true);
              // }
              component: (
                <div className="d-flex w-100" style={{gap: "0.6em"}}>
                  <GenericInput
                    selectSearchable
                    type="select"
                    placeholder={t(AdminBuildingI18NPrefix + "occupationYear")}
                    value={building.occupationYear}
                    hideSelectIndicator
                    onChange={year => {
                      setBuilding(({occupationMonth, occupationDay, ...building}) => ({...building, occupationYear: year,
                        occupationMonth: year ? occupationMonth : null,
                        occupationDay: year && occupationMonth && occupationDay ? (
                          Math.min(occupationDay, new Date(year, occupationMonth, 0).getDate())
                        ) : null,
                      }));
                      setUnsaved(true);
                    }}
                    selectOptions={ArrayRange(2022, 1900)}
                  />
                  <GenericInput
                    type="select"
                    selectSearchable
                    placeholder={t(AdminBuildingI18NPrefix + "occupationMonth")}
                    value={building.occupationMonth}
                    disabled={!building.occupationYear}
                    hideSelectIndicator
                    onChange={month => {
                      setBuilding(({occupationDay, ...building}) => ({...building, 
                        occupationMonth: month,
                        occupationDay: month && occupationDay ? (
                          Math.min(occupationDay, new Date(building.occupationYear, month, 0).getDate())
                        ) : null,
                      }));
                      setUnsaved(true);
                    }}
                    selectOptions={ArrayRange(1, 12)}
                  />
                  <GenericInput
                    type="select"
                    selectSearchable
                    placeholder={t(AdminBuildingI18NPrefix + "occupationDay")}
                    value={building.occupationDay}
                    disabled={!building.occupationMonth}
                    hideSelectIndicator
                    onChange={day => {
                      setBuilding(building => ({...building, occupationDay: day}));
                      setUnsaved(true);
                    }}
                    selectOptions={(building.occupationYear && building.occupationMonth) ? 
                      ArrayRange(1, new Date(building.occupationYear, building.occupationMonth, 0).getDate()) 
                      : []}
                  />
                </div>
              )
            },
            "managementCompanySection": {
              type: "section",
              title: t(AdminBuildingI18NPrefix + "managementCompany")
            },
            "managementCompany": {
              type: "text",
              title: t(AdminBuildingI18NPrefix + "managementCompany")
            },
            "managementContactInfo1": {
              type: "text",
              title: t(AdminBuildingI18NPrefix + "managementContactInfo1"),
              postfix: <ContactShortcut className="ms-2" contact={building.managementContactInfo1} />
            },
            "managementContactInfo2": {
              type: "text",
              title: t(AdminBuildingI18NPrefix + "managementContactInfo2"),
              postfix: <ContactShortcut className="ms-2" contact={building.managementContactInfo2} />
            },
            "imageToolSection": {
              type: "section",
              title: t(AdminBuildingAndPropertyImageToolI18NPrefix + "title")
            },
            "imageTool": {
              type: "component",
              component: (
                <div key={building?.id}>
                  <BuildingAndPropertyImageTool
                    eager
                    buildingId={building?.id}
                    api={api}
                  />
                </div>
                
              )
            },
            "remarkSection": {
              type: "section",
              title: t(AdminBuildingI18NPrefix + "remark")
            },
            "remark": {
              type: "text-area",
              title: t(AdminBuildingI18NPrefix + "remark")
            },
            "hr2": {
              type: "hr",
            }
          }}
          onChange={(data) => {
            setBuilding(building => ({...building, ...data}));
            setUnsaved(true);
          }}
          onDelete={!isNew && (async() => {
            if (id != null && await modal.confirmDelete(true)) {
              let token = loading.start();
              try {
                await api.buildings.delete(id);
                // await reload();
                navigate(`..`);
              } catch (e) {
                modal.errorSpecific(e);
              }
              loading.stop(token);
            }
          })}
          onSave={() => {

            toast.save(async() => {

              let token = loading.start();
              let {id, ...buildingPending} = building;
              
              const dto: BuildingDto = {
                ...buildingPending,
                districtId: buildingPending.district?.id ?? null,
                developerIds: buildingPending.developers?.map(developer => (developer.id)) ?? []
              }

              try {
                var buildingUpdated: Building;

                if (mode == "create") {
                  buildingUpdated = await api.buildings.create(dto);
                } else if (mode == "edit") {
                  buildingUpdated = await api.buildings.update(id!, dto);
                }
                setBuilding(buildingUpdated);
                setUnsaved(false);
              } catch (e) {
                modal.errorSpecific(e);
                console.error(e);
                throw e;
              } finally {
                loading.stop(token);
              }

              
            }, "building-save")

          }}
        />
      }

      
    </AdminComponent.Container>
  )

}

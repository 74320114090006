import { useCallback, useRef } from "react";
import { useLoadingManager } from "./loading-manager";

export interface Abortable {
  create: () => AbortController;
  abort: () => void;
}

export interface AbortableLoading {
  abort: () => AbortableLoading;
  start: () => {
      token: number;
      signal: AbortSignal;
  };
  stop: (token: number) => void;
  flag: boolean;
}

export const useAbortable = (): Abortable => {
  const abortController = useRef<AbortController | null>(null); 

  const create = useCallback(() => {
    abort();
    abortController.current = new AbortController();
    return abortController.current;
  }, []);

  const abort = useCallback(() => {
    abortController.current?.abort();
  }, [])

  return {create, abort}
}

export const useAbortableLoading = (): AbortableLoading => {

  const abortController = useRef<AbortController | null>(null); 
  const loading = useLoadingManager();
  const self: AbortableLoading = this;

  const abort = useCallback(() => {
    loading.clear();
    abortController.current?.abort();
    return self;
  }, [])

  const start = useCallback(() => {
    abort();
    abortController.current = new AbortController();
    const token = loading.start();
    return {token, signal: abortController.current.signal}
  }, []);

  const stop = useCallback((token: number) => {
    loading.stop(token);
  }, []);

  return {abort, start, stop, flag: loading.flag};

}
import axios, { AxiosInstance, AxiosProgressEvent, Canceler, Method } from "axios";

export interface DropzoneFile extends File {
  path?: string
}

export class APIBase {
  axios: AxiosInstance;
  base: string;
  constructor(axios: AxiosInstance, base: string) {
    this.axios = axios;
    this.base = base;
  }

  async uploadFiles(
    method: Method,
    url: string,
    files: DropzoneFile[], 
    onUploadProgress?: (e: AxiosProgressEvent) => void,
    handleCanceler?: (canceler: Canceler) => void
  ) {
    console.log(files);
    let formData = new FormData();
    for (let file of files) {
      var parentDirName = file.path?.match(/^(\/.*)\/.*$/)?.[1] || "";

      formData.append("files:" + parentDirName, file);
    }
    let cancelTokenSource = axios.CancelToken.source();
    
    if (handleCanceler) {
      handleCanceler(cancelTokenSource.cancel);
    }
    
    return (await this.axios.request<any>({
      method: method,
      url: url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: onUploadProgress,
      cancelToken: cancelTokenSource.token
    })).data;
  }
  
}
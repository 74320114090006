import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Canceler } from "axios";
import { PdfConfig, PdfConfigDto } from "../entities/pdf-config.entity";
import { GlobalSystemConfig, SystemConfig, SystemConfigDto } from "../entities/system-config.entity";
import { APIBase, DropzoneFile } from "./apiBase";



export default class PdfUtilAPI extends APIBase {

  constructor(axios: AxiosInstance) {
    super(axios, "pdf-util");
  }

  async getConfig(): Promise<PdfConfig> {
    return (await this.axios.get(this.base + "/config")).data;
  }

  async updateConfig(dto: PdfConfigDto) {
    return (await this.axios.put(this.base + "/config", dto)).data;
  }

  async test(lang?: "en" | "tc", config?: AxiosRequestConfig): Promise<string> {
    return (await this.axios.get(this.base + "/test/" + lang ?? "", config)).data;
  }

  getPdfUrl(filename: string, token: string): string {
    return this.axios.getUri({
      url: this.base + "/get/" + filename + "?token=" + token
    });
  }
}
import { Exclude } from "class-transformer";
import { IsEnum, IsNotEmpty, IsOptional, IsString } from "class-validator";
import { TimestampEntity } from "./timestamp.entity";
import { User } from "./user.entity";
import { HidePublic } from "./utils/hide-public";
import { ApiProperty, Column, Entity, JoinColumn, OmitType, OneToOne, PrimaryColumn } from "./vars";

export const UserAdminLicenseTypes = [
  "EstateAgent", "Salesperson"
] as const;

export type UserAdminLicenseType = (typeof UserAdminLicenseTypes)[number];

@Entity()
export class UserAdminInfo {
  
  constructor(partials?: Partial<UserAdminInfo>) {
    Object.assign(this, partials);
  }

  @ApiProperty()
  @Exclude()
  @PrimaryColumn() 
  userId: number;
  
  @ApiProperty({type: () => User})
  @OneToOne(() => User, user => user.profile, {
    onUpdate: "CASCADE",
    onDelete: "CASCADE",
  })
  @JoinColumn({name: "userId"})
  user?: User;
  
  @ApiProperty({
    enum: UserAdminLicenseTypes
  })
  @IsEnum(UserAdminLicenseTypes)
  @IsOptional()
  @Column("enum", {
    nullable: true,
    enum: UserAdminLicenseTypes,
  })
  licenseType: UserAdminLicenseType;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 64, 
    nullable: false,
    default: "",
  })
  licenseNumber: string;

  @HidePublic()
  @ApiProperty()
  @Column(() => TimestampEntity)
  timestamp: TimestampEntity
  
}


export class UserAdminInfoDto extends OmitType(UserAdminInfo, 
  ['user', 'userId', 'timestamp']
 ) {
 }
import { faSquareCaretDown, faSquareCaretUp } from "@fortawesome/free-regular-svg-icons";
import { faArrowDown, faArrowDownLong, faArrowsRotate, faArrowUp, faArrowUpLong, faCalendarDays, faCircleInfo, faCloudArrowDown, faDownload, faEnvelope, faFile, faGaugeHigh, faGlobe, faPlus, faRotate, faSave, faServer, faSort, faTrash, faTrashCan, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { AdminBackupsPath, AdminI18NPath } from "..";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider";
import { I18N } from "../../../api/entities/i18n.entity";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";
import { AdminTable } from "../component/Table";
import { BackupFile, BackupConfig, BackupConfigDto } from "../../../api/entities/backup.entity";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { DataList, DataListItem } from "../component/DataList";
import clsx from "clsx";
import moment from "moment";
import { filesize } from "../../../utils/filesize";
import { S3File } from "../../../api/entities/s3-file-system";
import axios from "axios";
import { EntityData } from "../component/EntityData";
import { UnsavedPrompt } from "../../../component/UnsavedPrompt";
import * as cronParser from 'cron-parser';
import { GenericInput } from "../component/GenericInput";
import { useFindAllQuery } from "../component/FindAllQuery";
import { PaginationMeta } from "../../../api/entities/pagination";

export const AdminBackups = () => {

  const {api, loading} = useAdminComponent({selectedItem: AdminBackupsPath});
  const [backupFiles, setBackupFiles] = useState<S3File[]>(null);
  const [config, setConfig] = useState<BackupConfig>(new BackupConfig());
  const [unsaved, setUnsaved] = useState(false);
  const modal = useGlobalModal();
  const {t} = useI18N();

  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>(null)

  const {findAllQuery, renderPaginationUI, renderSearchInput, setSearchInput, scrollTopRef, setSortByDropdownOpen} 
  = useFindAllQuery({
    loading: loading.flag, paginationMeta,
    shouldUseSearchParams: true
    // sortKeys: ["id", "nameEn", "nameTc", "nameSc", "timestamp.created", "timestamp.updated"]
  })
  
  useEffect(() => {
    if (findAllQuery?.page != null) {
      setPaginationMeta(meta => ({...meta, currentPage: findAllQuery.page}))
    }
  }, [findAllQuery])
  const backupFilesShown = useMemo(() => {
    if (backupFiles && paginationMeta) {
      let start = (paginationMeta.currentPage - 1) * paginationMeta.itemsPerPage
      return backupFiles.slice(start, start + paginationMeta.itemsPerPage)
    } else {
      return null;
    }
  }, [backupFiles, paginationMeta])

  const nextBackupDate = useMemo(() => {
    return config?.DAILY_BACKUP_CRON_INTERVAL && cronParser.parseExpression(config?.DAILY_BACKUP_CRON_INTERVAL)?.next()?.toDate();
  }, [config?.DAILY_BACKUP_CRON_INTERVAL]);
  
  const reloadConfig = useCallback(async() => {
    let token = loading.start();
    try {
      setConfig(await api.backup.getConfig());
      setUnsaved(false);
    } catch (e) {
      modal.errorSpecific(e);
    }
    loading.stop(token);
  }, [])

  const reload = useCallback(async() => {
    let token = loading.start();
    try {
      const backups = await api.backup.listBackups();
      setBackupFiles(backups);

      const itemsPerPage = 10; 
      setPaginationMeta({
        itemCount: backups.length,
        totalItems: backups.length,
        itemsPerPage: itemsPerPage,
        totalPages: Math.ceil(backups.length / itemsPerPage),
        currentPage: 1,
      })

      return true;
    } catch (e) {
      modal.errorSpecific(e);
      return false;
    } finally {
      loading.stop(token);
    }
  }, [])

  const download = useCallback(async(relativePath: string) => {
    let token = loading.start();
    try {
      // const buffer = await api.backup.download(filename);

      const presignedUrl = await api.backup.download(relativePath);
      const buffer = (await axios.get(presignedUrl, {responseType: 'arraybuffer'})).data;
      
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', relativePath);
      document.body.appendChild(link);
      link.click();
      // document.body.removeChild(link);
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (e) {
      modal.errorSpecific(e);
    }
    loading.stop(token);
  }, [])

  const backupNow = useCallback(async() => {
    let token = loading.start()
    try {
      const backupResult = await api.backup.createBackup();
      await reload();
      // await download(backupResult.filename);
      await download(backupResult.relativePath);

    } catch (e) {
      
      modal.errorSpecific(e);
    }
    loading.stop(token);
  }, [])
  
  useEffect(() => {
    reload().then((result) => {
      if (result) {
        reloadConfig();
      }
    })
  }, []);

  return (
    <AdminComponent.Container>
      <UnsavedPrompt flag={unsaved} />

      <AdminHeaderTitle>{t('admin.backups.title')}</AdminHeaderTitle>
      <AdminComponent.TitleBar>
        <AdminComponentTitle faIcon={faCloudArrowDown}>
          {t('admin.backups.pageTitle')}
        </AdminComponentTitle>
      </AdminComponent.TitleBar>
      
      <div className="hr" />
      <p className="d-flex align-items-center mb-3" style={{gap: "0.6rem"}}>
        <GenericInput 
          type="switch" 
          value={config.DAILY_BACKUP_ENABLED}
          disabled={loading.flag}
          onChange={flag => {setConfig(config => ({...config, DAILY_BACKUP_ENABLED: flag})); setUnsaved(true)}}
        />
        {t('admin.backups.dailyBackup')}{` `}
        {config?.DAILY_BACKUP_CRON_INTERVAL && (
          `(${t('admin.backups.dailyBackupNext')}: ${moment(nextBackupDate).format("YYYY-MM-DD HH:mm:ss")})`
        )}
      </p>
      <form className="d-flex align-items-center justify-content-center mb-3" style={{gap: "0.6rem"}}
        onSubmit={async(event) => {
          event.preventDefault();
          const token = loading.start();
          try {
            await api.backup.patchConfig(config);
            await reloadConfig();
          } catch (e) {
            modal.errorSpecific(e);
          }
          loading.stop(token);
        }}
      >
        <FontAwesomeIcon icon={faEnvelope} size="lg" fixedWidth />
        <GenericInput 
          type="text" 
          placeholder={t('admin.backups.dailyBackupEmail')}
          value={config.DAILY_BACKUP_EMAIL_RECEIPIENT}
          disabled={loading.flag || !config.DAILY_BACKUP_ENABLED}
          onChange={DAILY_BACKUP_EMAIL_RECEIPIENT => {setConfig(config => ({...config, DAILY_BACKUP_EMAIL_RECEIPIENT})); setUnsaved(true)}}
        />
        <ButtonWithLoader 
          type="submit"
          faIcon={faSave}
          loading={loading.flag}
          disabled={!unsaved}
          variant="info"
        />
        <ButtonWithLoader 
          // faIcon={faSave}
          disabled={unsaved || !config.DAILY_BACKUP_ENABLED}
          loading={loading.flag}
          variant="warning"
          onClick={async() => {
            const token = loading.start();
            try {
              await api.backup.testEmail();
            } catch (e) {
              modal.errorSpecific(e);
            }
            loading.stop(token);
          }}
        >{t('admin.backups.dailyBackupEmailTest')}</ButtonWithLoader>
      </form>
      <div className="hr"></div>
      <div className="d-flex align-items-center mb-3" style={{gap: "0.5em", flexWrap: "wrap"}}>
        <ButtonWithLoader
          faIcon={faServer}
          variant="primary"
          loading={loading.flag}
          onClick={() => backupNow()}
        >
          {t('admin.backups.backupNow')}
        </ButtonWithLoader>
        <ButtonWithLoader
          faIcon={faRotate}
          variant="success"
          loading={loading.flag}
          onClick={() => {
            reload();
          }}
        >
        </ButtonWithLoader>
      </div>
      <p className="text-muted">
        <FontAwesomeIcon icon={faCircleInfo} fixedWidth className="me-2"/>
        {t('admin.backups.hints')}
      </p>
      {
        backupFiles && (
          <p className="mt-2 mb-1">
            {t('admin.backups.backupsFound', {count: backupFiles.length})}
          </p>
        )
      }

      {renderPaginationUI({})}
      <div>
        <DataList>
          {
            backupFilesShown && (
              backupFilesShown.length == 0 ? (
                <DataList.Empty>{t('commons.noResult')}</DataList.Empty>
              ) : (
                <>{
                  backupFilesShown.map((backupFile, index) => {
                    const {filename, relativePath} = backupFile;
                    return (
                      <DataListItem key={index} >
                        <DataListItem.Body style={{fontSize: "1em"}}>
                          <div>
                            <FontAwesomeIcon icon={faFile} fixedWidth className="me-2" />
                            {filename}
                          </div>
                          <div className="text-muted">
                            <FontAwesomeIcon icon={faGaugeHigh} fixedWidth className="me-2" />
                            {filesize(backupFile.size)}
                          </div>
                          <div className="btns">
                            <ButtonWithLoader
                              faIcon={faDownload}
                              loading={loading.flag}
                              variant="info"
                              size="sm"
                              onClick={() => {
                                download(relativePath);
                              }}
                            />
                            <ButtonWithLoader
                              faIcon={faTrashCan}
                              loading={loading.flag}
                              variant="danger"
                              size="sm"
                              onClick={async() => {
                                if (await modal.confirmDelete(true)) {
                                  const token = loading.start();
                                  try {
                                    await api.backup.delete(relativePath);
                                    reload();
                                  } catch (e) {
                                    modal.errorSpecific(e);
                                  }
                                  loading.stop(token);
                                  
                                }
                              }}
                            />
                          </div>
                        </DataListItem.Body>
                      </DataListItem>
                    )
                  })
                }
                </>
              )
            )
          }
        </DataList>
      </div>
    </AdminComponent.Container>
  )
}
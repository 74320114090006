import axios from "axios";



export const downloadFromUrl = async(url: string, filename: string) => {
  const data = (await axios({
    url, //your url
    method: 'GET',
    responseType: 'blob', // important
  })).data;
  const href = URL.createObjectURL(data);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  // Clean up and remove the link
  link.parentNode.removeChild(link);

}
import React, { ComponentPropsWithoutRef, ComponentPropsWithRef, HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Switch from "react-switch";
import Select from 'react-select';
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import clsx from "clsx";
import { SortableSelect } from "./SortableSelect";
import TextareaAutosize from 'react-textarea-autosize';

// export const DynamicTextarea = (props: ComponentPropsWithRef<"textarea">) => {
//   const ref = useRef<HTMLTextAreaElement>();
//   const MinHeight = 60;
//   const MaxHeight = 420;
//   const [height, setHeight] = useState(MinHeight);

//   const updateHeight = useCallback(() => {
//     if (ref.current?.scrollHeight) {
//       setHeight(ref.current?.scrollHeight);
//     }
//   }, [])
//   useEffect(() => {
//     console.log(ref.current.scrollHeight);
//   }, [])
//   return (
//     <textarea 
//       {...props} 
//       ref={(element) => {
//         ref.current = element;
//         updateHeight();
//       }}
//       className={clsx(props.className, "overflow-hidden")}
//       onInput={() => {
//         updateHeight();
//       }}
//       style={{height, minHeight: MinHeight, maxHeight: MaxHeight}}
//     />
//   )
// }

export function commify(n: number): string | null {
  if (n == null || isNaN(n)) {return null;}
  var parts = n.toString().split(".");
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
}

export const GenericInputTypes = [
  'text', 'email', 'password', 'text-int', 'text-float', 'text-masked',  'text-area', // Text
  'switch',                                                   // Boolean
  'select', 'multiselect',                                    // Select
  'datetime',                                                 // Datepicker
] as const;

export type GenericInputType = (typeof GenericInputTypes)[number];

export interface GenericInputProps {
  id?: string,
  inputRef?: React.LegacyRef<any>,
  className?: string
  style?: React.CSSProperties
  type: GenericInputType,
  selectOptions?: ({
    value: any,
    label: any
  } | any)[]
  selectIsClearable?: boolean,
  min?: number;
  max?: number;
  step?: number;
  multiSelectSortable?: boolean
  selectShowValue?: boolean
  selectSearchable?: boolean
  value?: any
  placeholder?: string
  onChange?: (newValue: any, event?: any) => void
  readonly?: boolean,
  disabled?: boolean,
  required?: boolean,
  requiredSign?: boolean,
  loading?: boolean,
  datePickerProps?: Partial<ReactDatePickerProps>,
  hideNumberComma?: boolean,
  hideSelectIndicator?: boolean,
  // tabIndex?: string,
}
export const GenericInput = (props: GenericInputProps) => {

  const [textFloatPendingDot, setTextFloatPendingDot] = useState(false);
  const textareaRows = useMemo(() => {
    if (props.type == "text-area") {
      if (props.value != null && typeof props.value === "string") {
        return Math.max(3, props.value.split("\n").length);
      } else { 
        return 2;
      }
    }
  }, [props.value, props.type])

  let {id, inputRef, className, style, type, value, placeholder, onChange, required, requiredSign, readonly, disabled, loading, selectOptions} = props;
  
  if (['text', 'email', 'password', 'text-int', 'text-float', 'text-masked'].includes(type)) {

    if (value != null && !props.hideNumberComma && (type == 'text-int' || type == 'text-float')) {
      value = commify(value);
    }

    if (type === 'text-float' && value !== null && textFloatPendingDot) {
      value = value + ".";
    }

    let inputType: React.HTMLInputTypeAttribute = 'text';
    if ((type === "text-int" || type === "text-float" ) && (props.hideNumberComma || props.step)) {
      inputType = 'number';
    }

    if (type === "password") {
      inputType = "password";
    }

    var inputMode: React.InputHTMLAttributes<HTMLInputElement>["inputMode"] = (
      type == 'text-int' ? 'numeric' : type == 'text-float' ? 'decimal' : undefined
    );

    if (value != null && type === 'text-masked') {
      value = value.replace(/./g, '●')
    }



    return (
      <input 
        id={id}
        ref={inputRef}
        className={clsx("form-control", className)}
        style={style}
        type={inputType} 
        required={required}
        readOnly={readonly}
        value={value ?? ""}
        min={props.min}
        max={props.max}
        step={props.step}
        inputMode={inputMode}
        placeholder={placeholder || null}
        onChange={(event) => {
          var newValue: string | number = event.target.value;
          if (type == 'text-int' || type == 'text-float') {
            if (type == 'text-float') {
              // Set pending dot if 1 ending dot (e.g., "25.")
              setTextFloatPendingDot(!!newValue.match(/^(\d|\,)+\.$/))
            }
            newValue = newValue.replace(/\,/g, "");
            newValue = type == 'text-int' ? parseInt(newValue) : parseFloat(newValue);
            if (newValue > Number.MAX_SAFE_INTEGER) {
              return;
            }
            if (isNaN(newValue)) {
              newValue = null;
            } else {
              // newValue = commify(value as number);
            }
          }
          onChange?.(newValue, event);
        }}
        autoComplete={(type == 'password') ? 'new-password' : "off"}
        disabled={disabled || loading}
      />
    )
  }

  if (type === 'text-area') {
    return (
      <TextareaAutosize
        // ref={inputRef}
        className={clsx("form-control pb-3 overflow-hidden", className)}
        // style={style}
        required={required}
        readOnly={readonly}
        value={value}
        placeholder={placeholder || undefined}
        // rows={textareaRows}
        onChange={(event) => {
          const value = event.target.value;
          onChange?.(value, event);
        }}
        disabled={disabled || loading}
      />
    )
  }

  if (type === 'switch') {
    return (
      <Switch 
        id={id}
        className={clsx(className)}
        width={36}
        height={20}
        checkedIcon={false}
        uncheckedIcon={false}
        onChange={(newValue, event) => {
          onChange && onChange(newValue, event);
        }}
        checked={(value == null) ? false : value}
        readOnly={readonly}
        disabled={disabled || loading}
      />
    )
  }

  if (type === 'select' || type === 'multiselect') {
    const optionsIsObject = typeof(selectOptions?.[0]) == "object";
    return (
      <SortableSelect
        components={{
          ...(props.hideSelectIndicator && (
            {DropdownIndicator: () => null, IndicatorSeparator: () => null}
          ))
        }}
        id={id}
        className={clsx("w-100", className)}
        isMulti={type === 'multiselect'}
        multiSelectSortable={props.multiSelectSortable}
        options={optionsIsObject ? selectOptions : selectOptions.map(option => ({value: option, label: option}))}
        placeholder={props.placeholder}
        isSearchable={props.selectSearchable || props.selectOptions?.length > 16}
        isClearable={props.selectIsClearable}
        required={required}
        value={type === 'select' ? 
          selectOptions?.find((o) => (o.value === value)) && {
            value: value,
            label: (props.selectShowValue || !optionsIsObject) ? value : selectOptions?.find((o) => (o.value === value))?.label
          } : (
            value?.map(
              (v: any) => {
                return {
                  value: v, 
                  label: (props.selectShowValue || !optionsIsObject)? v : selectOptions?.find((o) => (o.value === v))?.label
                }
              }
            )
          )}
        onChange={(value) => {
          if (type === 'select') {
            onChange?.(value?.value ?? null);
          } else {
            let valueArray = value?.map((v) => {
              return v.value;
            })
            onChange?.(valueArray);
          }
          
          
        }}
        isLoading={loading}
        isDisabled={disabled || loading}
      />
    )
  }

  if (type == 'datetime') {
    return (
      <DatePicker  
        id={id}
        className={clsx("form-control", className)}
        placeholderText={props.placeholder}
        dateFormat="yyyy-MM-dd HH:mm:ss"
        selected={value}
        onChange={(newDate, event) => {
          onChange && onChange(newDate, event);
        }}
        // showTimeSelect
        readOnly={readonly}
        disabled={disabled || loading}
        {...props.datePickerProps}
      />
    )
  }

  
}
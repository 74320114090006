import { Property } from "./property.entity";
import { TransformUserPreview, User, UserPreview } from "../user.entity";
import { ApiProperty, CreateDateColumn, DeleteDateColumn, ManyToOne, PrimaryGeneratedColumn, Relation } from "../vars"
import { Transform, Type } from "class-transformer";
import { HidePublic } from "../utils/hide-public";

export abstract class PropertySectionAuditable {
  // constructor(partial?: Partial<PropertySectionAuditable>) {
  //   Object.assign(this, partial);
  //   this.updatedBy != null && (this.updatedBy = new UserPreview(this.updatedBy));
  // }

  // protected postConstructor?() {
  //   this.updatedBy != null && (this.updatedBy = new UserPreview(this.updatedBy));
  // }

  @HidePublic()
  @ApiProperty()
  @PrimaryGeneratedColumn()
  id: number

  @HidePublic()
  @ApiProperty()
  @CreateDateColumn({ type: "timestamp", precision: 3, default: () => "CURRENT_TIMESTAMP(3)" })
  updated: Date;

  @HidePublic()
  @ApiProperty()
  @TransformUserPreview()
  @ManyToOne(() => User, {
    nullable: false,
    onUpdate: "CASCADE"
  })
  updatedBy: UserPreview;

  @HidePublic()
  @DeleteDateColumn({
    precision: 3
  })
  deletedAt: Date;

}

import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faArrowDown, faArrowsRotate, faArrowUp, faBan, faGlobe, faMagicWandSparkles, faMapLocation, faPenToSquare, faPlus, faSearch, faSort, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { startTransition, useCallback, useEffect, useId, useMemo, useRef, useState, useTransition } from "react";
import { District, DistrictDto, DistrictRegions } from "../../../api/entities/district.entity";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider";
import { UnsavedMessageI18NId, UnsavedMessageTitleI18NId, UnsavedPrompt } from "../../../component/UnsavedPrompt";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";
import { GenericInput } from "../component/GenericInput";
import { AdminTable } from "../component/Table";
import { AdminDistrictsPath } from "../variables";
import "../../../styles/admin/districts-table.scss";
import { EntityTimestamp } from "../component/EntityTimestamp";
import { DataList, DataListItem } from "../component/DataList";
import { Badge, Spinner } from "react-bootstrap";
import clsx from "clsx";
import { useLoadingManager } from "../../../utils/loading-manager";
import { GoogleMapSearchLink } from "../../../component/GoogleMapLink";
import { useAbortableLoading } from "../../../utils/abortable-loading";
import { AutocompleteSearch } from "../../../component/AutocompleteSearch";
import { GoogleApiMapsSearch } from "../component/GoogleApiMapsSearch";
import { GoogleApisMapsPlaceAutocompleteTypePreset } from "../../../api/entities/googleapis";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { EntitySelector, EntitySelectorParentProps, EntitySelectorProps } from "../component/EntitySelector";
import { useScrollToId } from "../../../utils/scroll-to-id";
import { NullDisplay } from "../../../component/NullDisplay";
import { Link } from "react-router-dom";
import { FindAllQuery, createEntityFilter } from "../../../api/entities/findAllQuery";
import { APIEncodeQueryString } from "../../../api/src/helpers";
import { LangMap } from "../../../utils/lang-map";
import { AdminPropertyI18NPrefix } from "./Property";


export const searchDistricts = (keyword: string, districts: District[]) => {
  keyword = keyword.trim();

  if (keyword == "") {return districts;}

  return districts?.filter(district => {
    const regex = new RegExp(keyword.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), "i");
    
    return district.id?.match(regex) || 
      district.nameTc?.match(regex) || 
      district.nameEn?.match(regex) || 
      district.nameSc?.match(regex);
  })
}

export const AdminDistrictsComponent = (props: {
  // disable
  onSelect?: (district: District) => void
}) => {
  const {api, loading, toast} = useAdminComponent();
  const modal = useGlobalModal();
  const [districts, setDistricts] = useState<District[]>(null);
  const [pendingInputFocus, setPendingInputFocus] = useState(false);
  const [districtEditing, setDistrictEditing] = useState<(District & {
    isNew: boolean,
    index: number | null, // null for create
    unsaved: boolean;
  }) | null>(null);

  const openCCT2SAbortableLoading = useAbortableLoading();

  const [searchInput, setSearchInput] = useState("");
  const [searchPending, startSearchTransition] = useTransition();
  const [districtsFiltered, setDistrictsFiltered] = useState<District[]>(null);
  
  const {scrollToId, idPrefix} = useScrollToId();

  const {t} = useI18N();

  const unsaved = useMemo(() => {
    return !!districtEditing?.unsaved;
  }, [districtEditing?.unsaved])


  const unsaveProceed = useCallback(async() => {
    return !unsaved || unsaved && await modal.confirmUnsaved();
  }, [unsaved])

  const reload = useCallback(async() => {
    let token = loading.start();
    try {
      const districts = await api.districts.findAll();
      setDistricts(districts);
      setDistrictsFiltered(districts);
      setSearchInput("");
    } catch (e) {
      modal.errorSpecific(e);
    } finally {
      loading.stop(token);
    }
  }, [])

  
  useEffect(() => {
    reload();
  }, []);

  return (
    <>
      <UnsavedPrompt flag={unsaved} />
      <div className="d-flex align-items-center mb-3" style={{gap: "0.5em", flexWrap: "wrap"}}>

        <ButtonWithLoader
          faIcon={faPlus}
          variant="primary"
          loading={loading.flag}
          onClick={async(event) => {
            event.stopPropagation();
            // setDistricts(districts => districts.map(district => ({...district, needFocus: false})));
            if (await unsaveProceed()) {
              setDistrictEditing({
                isNew: true,
                index: null,
                id: "",
                nameEn: "",
                nameTc: "",
                nameSc: "",
                region: null,
                timestamp: null,
                unsaved: true,
              })
              scrollToId(null);

              setPendingInputFocus(true);
            }

          }}
        >
        </ButtonWithLoader>

        <ButtonWithLoader
          faIcon={faArrowsRotate}
          variant="secondary"
          loading={loading.flag}
          onClick={async() => {
            if (await unsaveProceed()) {
              setDistrictEditing(null);
              await reload();
            }
          }}
        >
        </ButtonWithLoader>
      </div>

      <div>
        <AutocompleteSearch
          loading={searchPending}
          placeholder={t("admin.districts.search")}
          hideDropdown
          input={searchInput}
          onChange={(value: string) => {
            setSearchInput(value);
            startSearchTransition(() => {
              setDistrictsFiltered(searchDistricts(value, districts))
            })
          }}
        />
      </div>
      <DataList className="scrollable">
        {
          districtsFiltered && (
            districtsFiltered.length == 0 && !districtEditing ? (
              <DataList.Empty>{t('commons.noResult')}</DataList.Empty>
            ) : (
              [...districtsFiltered, districtEditing].map((district: District, index) => {
                if (district == null || (district as typeof districtEditing).isNew === false) {
                  return;
                }

                const isNew = (district as typeof districtEditing)?.isNew;
                const editing = isNew || districtEditing?.index === index; 
                
                const content = (
                  <DataListItem key={index} clickable active={editing}
                    onClick={async() => {
                      if (!editing && await unsaveProceed()) {
                        setDistrictEditing({
                          isNew: false,
                          index, 
                          unsaved: false,
                          ...district
                        });
                        
                        setPendingInputFocus(true);
                        scrollToId(district.id);
                      }
                    }}
                  >
                    <a id={idPrefix + district.id} className="anchor" />
                    <DataListItem.Title>
                      <Badge bg="info" className="data-key">id</Badge>
                      {!editing ? <div>
                        <b>{district.id}</b>
                        <Link target="_blank" to={"../../property?" + APIEncodeQueryString({...FindAllQuery.toJson({
                            filter: {"building.district.id": createEntityFilter("exact", district.id)}
                          })})} className="ms-3"
                          onClick={event => event.stopPropagation()}
                        >
                          <ButtonWithLoader variant="outline-info" size="sm" faIcon={faSearch} className="px-2">
                            {t(AdminPropertyI18NPrefix + "title")}
                          </ButtonWithLoader>
                          </Link>
                      </div> :
                      <GenericInput 
                        inputRef={pendingInputFocus ? (element) => {
                          element?.focus();
                          setPendingInputFocus(false);
                        } : undefined}
                        type="text"
                        value={districtEditing.id} 
                        loading={loading.flag}
                        onChange={(id: string) => setDistrictEditing({...districtEditing, id: id.toUpperCase(), unsaved: true})} 
                      />}
                      {
                        !editing && (
                          <DataListItem.TitleBtns>
                            <EntityTimestamp timestamp={district.timestamp} />
                            {/* <DataListItem.EditButton
                              className={clsx(editing && "active")}
                              onClick={async() => {
                                // editDistrict(index, {
                                //   ...district,
                                // }, undefined, true)
                                
                                if (await unsaveProceed()) {
                                  setDistrictEditing({
                                    isNew: false,
                                    index, 
                                    unsaved: false,
                                    ...district
                                  });
                                  
                                  setPendingInputFocus(true);
                                  scrollToId(district.id);
                                }
                              }}
                            /> */}
                          </DataListItem.TitleBtns>
                        )
                      }
                    </DataListItem.Title>
                    <DataListItem.Body>
                      {
                        editing && (
                          <div>
                            <FontAwesomeIcon icon={faGoogle} fixedWidth size="lg" className="me-3 text-info"/>
                            <GoogleApiMapsSearch
                              api={api}
                              types={GoogleApisMapsPlaceAutocompleteTypePreset.District}
                              placeholder={t("admin.districts.googleSearch")}
                              onComplete={(result) => {
                                const {nameTc, nameSc, nameEn} = result;
                                setDistrictEditing(districtEditing => ({
                                  ...districtEditing, nameTc, nameSc, nameEn, unsaved: true
                                }))
                              }}
                            />
                          </div>
                        )
                      }
                      <div><Badge bg="info" className="data-key">繁</Badge>{
                        !editing ? <>{district.nameTc} <GoogleMapSearchLink lang="tc" className="text-primary ms-2" place={district.nameTc} /></> :
                        <GenericInput 
                          type="text" 
                          value={districtEditing.nameTc} 
                          loading={loading.flag}
                          onChange={async(nameTc) => {
                            setDistrictEditing(districtEditing => ({...districtEditing, nameTc, unsaved: true}))
                            const {token, signal} = openCCT2SAbortableLoading.start();
                            try {
                              const text = await api.openCC.convertHK2S({text: nameTc}, {signal});
                              setDistrictEditing(districtEditing => ({...districtEditing, nameSc: text, unsaved: true}))
                            } catch (e) {
                              modal.errorSpecific(e);
                            } finally {
                              openCCT2SAbortableLoading.stop(token);
                            }

                          }} 
                        />
                      }</div>
                      <div><Badge bg="info" className="data-key">简</Badge>{
                        !editing ? district.nameSc :
                        <>
                          
                          <GenericInput 
                            type="text" 
                            value={districtEditing.nameSc} 
                            loading={loading.flag}
                            onChange={nameSc => {
                              openCCT2SAbortableLoading.abort(); 
                              setDistrictEditing({...districtEditing, nameSc, unsaved: true})
                            }} 
                          />
                          {
                            openCCT2SAbortableLoading.flag && (
                              <div>
                                <Spinner animation="border" size="sm" className="ms-2" />
                              </div>
                            )
                          }
                        </>
                        
                      }</div>
                      <div><Badge bg="info" className="data-key">EN</Badge>{
                        !editing ? <>{district.nameEn} <GoogleMapSearchLink lang="en" className="text-primary ms-2" place={district.nameEn} /></> :
                        <GenericInput 
                          type="text" 
                          value={districtEditing.nameEn} 
                          loading={loading.flag}
                          onChange={nameEn => setDistrictEditing({...districtEditing, nameEn, unsaved: true})} 
                        />
                      }</div>

                      <div><Badge bg="info" className="data-key">{t("admin.districts.region")}</Badge>{
                        !editing ? <>{district.region ? t("admin.districts.regions." + district.region) : <NullDisplay />} </> :
                        <GenericInput 
                          type="select"
                          placeholder={t("admin.districts.region")}
                          selectOptions={DistrictRegions.map(region => ({
                            value: region, 
                            label: t("admin.districts.regions." + region)
                          }))}
                          value={districtEditing.region} 
                          loading={loading.flag}
                          onChange={region => setDistrictEditing({...districtEditing, region, unsaved: true})} 
                        />
                      }</div>

                      {
                        editing && (
                          <div className="btns">
                            <ButtonWithLoader
                              variant="success"
                              faIcon={faSave}
                              loading={loading.flag}
                              onClick={() => toast.save(async() => {
                                const token = loading.start();
                                openCCT2SAbortableLoading.abort();
                                let districtUpdated: District;
                                try {
                                  if (isNew) {
                                    districtUpdated = await api.districts.create(districtEditing);
                                  } else {
                                    districtUpdated = await api.districts.update(district.id, districtEditing);
                                  }
                                  await reload();
                                  setDistrictEditing(null);
                                  setTimeout(() => {
                                    // Delay to make sure reload() has update unsaved status
                                    scrollToId(districtUpdated.id);
                                  }, 100)

                                } catch (e) {
                                  modal.errorSpecific(e);
                                  throw e;
                                } finally {
                                  loading.stop(token);
                                }
                              }, "district-save")}
                            >{t('entityData.save')}</ButtonWithLoader>
                            {
                              !isNew && (
                                <ButtonWithLoader
                                  variant="danger"
                                  faIcon={faTrashCan}
                                  loading={loading.flag}
                                  onClick={async() => {
                                    if (await modal.confirmDelete()) {
                                      toast.delete(async() => {
                                        const token = loading.start();
                                        try {
                                          await api.districts.delete(district.id);
                                          await reload();
                                          setDistrictEditing(null);
                                          scrollToId(null);
                                          
                                        } catch (e) {
                                          modal.errorSpecific(e);
                                          throw e;
                                        } finally {
                                          loading.stop(token);
                                        }
                                      }, "district-delete")
                                    }
                                  }}
                                >{t('entityData.delete')}</ButtonWithLoader>

                              )
                            }
                            
                            <ButtonWithLoader
                              variant="info"
                              faIcon={faBan}
                              loading={loading.flag}
                              onClick={async() => {
                                if (await unsaveProceed()) {
                                  setDistrictEditing(null);
                                }
                              }}
                            >{t('entityData.cancel')}</ButtonWithLoader>
                          </div>
                          
                        )
                      }
                      {
                        !editing && props.onSelect && (
                          <ButtonWithLoader
                            size="sm"
                            variant="success"
                            onClick={() => {
                              props.onSelect(district);
                            }}
                          >
                            {t("admin.commons.select")}
                          </ButtonWithLoader>
                        )
                      }
                    </DataListItem.Body>
                  </DataListItem>
                )

                return content;
              })
            )
          )
        }
        {/* <AdminTable 
          className="districts-table"
          keys={{
            id: {
              content: t('admin.districts.id'),
            },
            nameEn: {
              content: t('admin.districts.nameEn'),
            },
            nameTc: {
              content: t('admin.districts.nameTc'),
            },
            nameSc: {
              content: t('admin.districts.nameSc'),
            },
            reOrder: {
              content: "",
              shrink: true,
            }, 
            timestamp: {
              content: "",
              shrink: true,
            },
            delete: {
              content: "",
              shrink: true,
            },
          }} 
          rows={
            districts && districts.map((district, index) => {
              if (district.mode == "deleted") {return null}
              const editing = district.mode == "editing";

              return {
                onClick: (event) => {
                  event.stopPropagation();
                  // Set all editing districts as uneditted
                  setDistricts(districts => districts?.map(district => {
                    if (district.mode == "editing") {
                      district.mode = "edited";
                    }
                    return district;
                  }))
                  editDistrict(index, {
                    ...district,
                    mode: "editing",
                  })
                },
                content: {
                  id: !editing ? district.id :
                    <GenericInput type="text" value={district.id} onChange={(id: string) => editDistrict(index, {...district, id: id.toUpperCase()}, true)} />,
                  nameEn: !editing ? district.nameEn :
                    <GenericInput type="text" value={district.nameEn} onChange={nameEn => editDistrict(index, {...district, nameEn}, true)} />,
                  nameTc: !editing ? district.nameTc :
                    <GenericInput type="text" value={district.nameTc} onChange={nameTc => editDistrict(index, {...district, nameTc}, true)} />,
                  nameSc: !editing ? district.nameSc :
                    <GenericInput type="text" value={district.nameSc} onChange={nameSc => editDistrict(index, {...district, nameSc}, true)} />,
                  reOrder: (
                    <div className="d-flex">
                      <button className="btn btn-outline-info btn-sm me-1" disabled={index == 0}
                        onClick={(event) => {
                          event.stopPropagation();
                          let districtsCloned = [...districts];
                          [districtsCloned[index-1], districtsCloned[index]] = [districtsCloned[index], districtsCloned[index-1]];
                          setDistricts(districtsCloned);
                          setUnsavedOrders(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faArrowUp} />
                      </button>
                      
                      <button className="btn btn-outline-info btn-sm" disabled={index == districts.length - 1}
                        onClick={(event) => {
                          event.stopPropagation();
                          let districtsCloned = [...districts];
                          [districtsCloned[index], districtsCloned[index+1]] = [districtsCloned[index+1], districtsCloned[index]];
                          setDistricts(districtsCloned);
                          setUnsavedOrders(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faArrowDown} />
                      </button>
                    </div>
                  ),
                  timestamp: district.timestamp && <EntityTimestamp created={district.timestamp.created} updated={district.timestamp.updated} />,
                  delete: (
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={async(event) => {
                        event.stopPropagation();
                        if (await modal.confirmDelete()) {
                          editDistrict(index, {...district, mode: "deleted"}, true)
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </button>
                  ),
                }
              }
            })
          }
        /> */}
      </DataList>
    </>
  )
}

export const AdminDistricts = () => {
  const {t} = useI18N();
  useAdminComponent({selectedItem: AdminDistrictsPath})


  return (
    <AdminComponent.Container 
      onClick={() => {
        // Set all editing districts as edited
        // setDistricts(districts => districts?.map(district => {
        //   if (district.mode == "editing") {
        //     district.mode = "edited";
        //   }
        //   return district;
        // }))
      }}
    >
      <AdminHeaderTitle>{t('admin.districts.title')}</AdminHeaderTitle>
      <AdminComponent.TitleBar>
        <AdminComponentTitle faIcon={faMapLocation}>
          {t('admin.districts.pageTitle')}
        </AdminComponentTitle>
      </AdminComponent.TitleBar>

      
      <div className="hr" />

      <AdminDistrictsComponent />
    </AdminComponent.Container>
  )
  
}

export const AdminDistrictPreview = (props: {
  district: District
}) => {
  const {district} = props;

  return district && (
    <div className="preview">
      {/* <div><Badge bg="info" >id</Badge> {district.id}</div> */}
      <div><Badge bg="info" >繁</Badge> {district.nameTc}</div>
      <div><Badge bg="info" >简</Badge> {district.nameSc}</div>
      <div><Badge bg="info" >EN</Badge> {district.nameEn}</div>
    </div>
  )

}
export const AdminDistrictSelector = (props: EntitySelectorParentProps<District>) => {
  const {t} = useI18N();
  const [modalOpen, setModalOpen] = useState(false);
  const {item: district} = props;

  return (
    <EntitySelector
      {...props}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      preview={<AdminDistrictPreview district={district}/>}
      icon={faMapLocation}
      title={t('admin.districts.choose')}
      modalContent={<AdminDistrictsComponent onSelect={(district) => {
        setModalOpen(false);
        props.onChange?.(district);
      }} />}
    />
  )
}

import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { APIInstanceWithAuth, APIInstanceWithAuthForAdmin } from "../../../api";
import { useUser } from "../../../component/UserProvider";
import { AdminDashboardPath, AdminLoginPath } from "../variables";

export const AdminLogout = (props: React.PropsWithChildren<{}>) => {
  const api = useMemo(() => (new APIInstanceWithAuthForAdmin()), []);
  const {adminUser, reloadAdminUser} = useUser();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!adminUser) {
      navigate("../" + AdminLoginPath)
    }
  }, [adminUser]);

  useEffect(() => {
    api.auth.logout({
      refresh_token: api.getRefreshToken(),
      access_token: api.getAccessToken()
    }).finally(() => {
      api.setAccessToken(null);
      api.setRefreshToken(null);
      // window.location.reload();
      reloadAdminUser();
    })
  }, [])

  return <></>;
}


import { Exclude, Type } from "class-transformer";
import { IsString, IsNotEmpty, IsNumber, IsInt, IsOptional, IsEnum, ValidateNested } from "class-validator";
import { BuildingToDeveloper } from "./building-to-developer.entity";
import { Developer } from "./developer.entity";
import { District } from "./district.entity";
import { EntitySort } from "./findAllQuery";
import { TimestampEntityWithUser } from "./timestamp-with-user.entity";
import { TimestampEntity } from "./timestamp.entity";
import { UserPreview } from "./user.entity";
import { HidePublic } from "./utils/hide-public";
import { ApiProperty, Column, DeleteDateColumn, Entity, JoinTable, ManyToMany, ManyToOne, OmitType, OneToMany, PickType, PrimaryColumn, PrimaryGeneratedColumn } from "./vars";


export const BuildingRiseTypes = [
  "low", "mid", "high"
] as const;

export type BuildingRiseType = (typeof BuildingRiseTypes)[number];

export const BuildingTypes = [
  "Apartment",
  "House",
  "Office",
  "Industrial",
  "Shop",
  "Land",
  "Carpark"
] as const;

export type BuildingType = (typeof BuildingTypes)[number];

export interface BuildingImageCreator {
  createdBy: UserPreview;
  createdAt: Date;
}

export class BuildingImageRenameDto {
  @IsString()
  @IsNotEmpty()
  newFilename: string;
}

@Entity()
export class Building {
  @ApiProperty()
  @PrimaryGeneratedColumn()
  id: number;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 128,
    nullable: false,
    default: ""
  })
  nameTc: string;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 128,
    nullable: false,
    default: ""
  })
  nameSc: string;

  
  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 128,
    nullable: false,
    default: ""
  })
  nameEn: string;

  @ApiProperty()
  @ManyToOne(() => District, {
    nullable: true,
    onUpdate: "CASCADE",
    onDelete: "SET NULL",
    // eager: true
  })
  district: District | null;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 128,
    nullable: false,
    default: ""
  })
  streetNameTc: string;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 128,
    nullable: false,
    default: ""
  })
  streetNameSc: string;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 128,
    nullable: false,
    default: ""
  })
  streetNameEn: string;

  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 32,
    nullable: false,
    default: ""
  })
  streetNumber: string;
  
  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 128,
    nullable: false,
    default: ""
  })
  lotNumber: string;

  @ApiProperty()
  developers: Developer[];
  
  @ApiProperty()
  @IsEnum(BuildingTypes)
  @IsOptional()
  @Column("enum", {enum: BuildingTypes, nullable: true})
  type: BuildingType;

  @ApiProperty()
  @IsEnum(BuildingRiseTypes)
  @IsOptional()
  @Column("enum", {enum: BuildingRiseTypes, nullable: true})
  riseType: BuildingRiseType;

  @ApiProperty()
  @IsInt()
  @IsOptional()
  @Column("smallint", {
    nullable: true
  })
  occupationYear: number;

  @ApiProperty()
  @IsInt()
  @IsOptional()
  @Column("tinyint", {
    nullable: true
  })
  occupationMonth: number;

  @ApiProperty()
  @IsInt()
  @IsOptional()
  @Column("tinyint", {
    nullable: true
  })
  occupationDay: number;
  
  @HidePublic()
  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 128,
    nullable: false,
    default: "",
  })
  managementCompany: string;

  @HidePublic()
  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 128,
    nullable: false,
    default: "",
  })
  managementContactInfo1: string;

  @HidePublic()
  @ApiProperty()
  @IsString()
  @Column("varchar", {
    length: 128,
    nullable: false,
    default: "",
  })
  managementContactInfo2: string;

  @HidePublic()
  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true,
  })
  managementFeePerUnit: number;
  


  @ApiProperty()
  @IsString()
  @Column("mediumtext")
  remark: string;
  
  @HidePublic()
  @ApiProperty()
  @ValidateNested()
  @IsOptional()
  @Column(() => TimestampEntityWithUser)
  timestamp: TimestampEntityWithUser

  @ApiProperty()
  @DeleteDateColumn({precision: 3})
  @Exclude({toPlainOnly: true})
  deletedAt?: Date;
}

export class BuildingDto extends OmitType(
  Building, ["id", "district", "developers"]
) {
  @ApiProperty()
  @IsString()
  @IsOptional()
  districtId: string;

  @ApiProperty()
  @IsString({each: true})
  developerIds: string[];
}

export interface BuildingSort extends EntitySort<Omit<Building, "timestamp" | "district" | "developers"> & {
  "timestamp.updated": any;
  "timestamp.created": any;
}> {}

export class BuildingStreetName extends PickType(Building, [
  "streetNameEn", "streetNameTc", "streetNameSc", "district"
]) {}
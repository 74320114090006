import { useCallback, useEffect, useRef, useState } from "react";


export const useWindowFocused = (props?: {
  onFocus?: () => void;
  onBlur?: () => void;
}) => {
  const [isFocused, setIsFocused] = useState(true);
  const isFocusedRef = useRef<boolean>(true);

  const handleFocus = useCallback(() => {
    console.log('Window has focus');
    setIsFocused(true);
    isFocusedRef.current = true;
    props?.onFocus?.();
  }, []);

  const handleBlur = useCallback(() => {
    console.log('Window lost focus');
    setIsFocused(false);
    isFocusedRef.current = false;
    props?.onBlur?.();
  }, []);

  useEffect(() => {
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, [])

  return {windowFocused: isFocused, windowFocusedRef: isFocusedRef};
}
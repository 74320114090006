import { Transform } from "class-transformer";
import { IsString, IsInt, IsOptional, IsNumber, IsBoolean } from "class-validator";
import { HidePublic } from "../utils/hide-public";
import { ApiProperty, Column, Entity, ManyToOne, OmitType, Relation } from "../vars";
import { PropertySectionAuditable } from "./property-section-auditable.entity";
import { Property } from "./property.entity";

@Entity()
export class PropertyDetail extends PropertySectionAuditable {

  constructor(partial?: Partial<PropertyDetail>) {
    super();
    Object.assign(this, partial);
    // this.postConstructor();
  }

  @ManyToOne(() => Property, {
    nullable: false,
    onUpdate: "CASCADE",
    onDelete: "CASCADE"
  })
  property: Relation<Property>;
  
  @ApiProperty()
  @IsInt()
  @IsOptional()
  @Column("smallint", {
    nullable: true
  })
  bedroom: number = null;

  @ApiProperty()
  @IsInt()
  @IsOptional()
  @Column("smallint", {
    nullable: true
  })
  livingRoom: number = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  balconyArea: number = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  gardenArea: number = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  terraceArea: number = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  roofArea: number = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  loftArea: number = null;

  @ApiProperty()
  @IsNumber()
  @IsOptional()
  @Column("float", {
    nullable: true
  })
  carportArea: number = null;

  @ApiProperty()
  @IsInt()
  @IsOptional()
  @Column("smallint", {
    nullable: true
  })
  coveredCarParkCount: number = null;

  @HidePublic()
  @ApiProperty()
  @IsString()
  @IsOptional()
  @Column("text")
  coveredCarParkInfo: string = "";

  @ApiProperty()
  @IsInt()
  @IsOptional()
  @Column("smallint", {
    nullable: true
  })
  uncoveredCarParkCount: number = null;

  @HidePublic()
  @ApiProperty()
  @IsString()
  @IsOptional()
  @Column("text")
  uncoveredCarParkInfo: string = "";

  // Deprecated
  @HidePublic()
  @ApiProperty()
  @IsString()
  @Column("text")
  quickRemark: string = "";

  @HidePublic()
  @ApiProperty()
  @IsString({each: true})
  @Column("simple-array", {nullable: false})
  @Transform(({value}: {value: string[]}) => value.map(str => str.replace(/，/g, ",")), {toPlainOnly: true})
  @Transform(({value}: {value: string[]}) => value.map(str => str.replace(/,/g, "，")), {toClassOnly: true})
  internalRemarks: string[] = [];

  // Deprecated
  @HidePublic()
  @ApiProperty()
  @IsString()
  @IsOptional()
  @Column("text")
  publicRemarkTc: string = "";

  @HidePublic()
  @ApiProperty()
  @IsString()
  @IsOptional()
  @Column("text")
  publicRemarkSc: string = "";

  @HidePublic()
  @ApiProperty()
  @IsString()
  @IsOptional()
  @Column("text")
  publicRemarkEn: string = "";


}

export class PropertyDetailDto extends OmitType(
  PropertyDetail, ["id", "property", "updated", "updatedBy"] as const,
) {}
import { PropertyLandlord, PropertyContactInvisible, PropertyLandlordDto, PropertyLandlordPublic } from "./property-landlord.entity";
import { ApiProperty, Column, CreateDateColumn, DeleteDateColumn, Entity, JoinColumn, ManyToOne, OmitType, OneToMany, OneToOne, PrimaryGeneratedColumn, Relation, IsBooleanQueryString, PickType } from "../vars";
import { PropertyBasicInfo, PropertyBasicInfoDto, PropertyBasicInfoPublic } from "./property-basic-info.entity";
import { PropertyPriceStatus, PropertyPriceStatusDto, PropertyPriceStatusPublic } from "./property-price-status.entity";
import { PropertyDetail, PropertyDetailDto } from "./property-detail.entity";
import { TransformUserPreview, User, UserPreview } from "../user.entity";
import { IsArray, IsBase64, IsBoolean, IsDate, IsDateString, IsEnum, IsInt, IsNotEmpty, IsNumber, IsObject, IsOptional, IsString, Validate, ValidateIf, ValidateNested } from "class-validator";
import { Exclude, Expose, Transform, Type } from "class-transformer";
import { Building } from "../building.entity";
import { EntitySort, FindAllQuery } from "../findAllQuery";
import { PdfAvailableFont, PdfAvailableFonts } from "../pdf-util.entity";
import { PropertyPublishment, PropertyPublishmentDto, PropertyPublishmentPublicClone } from "./property-publishment.entity";
import { HidePublic } from "../utils/hide-public";
import { TransformDate } from "../utils/date";
import { District } from "../district.entity";


// export const PropertyViews = [
//   "CityView",
//   "MountainView",
//   "SeaView",
// ] as const;

// export type PropertyView = (typeof PropertyViews)[number];



export abstract class PropertyBase {
  @ApiProperty()
  @PrimaryGeneratedColumn()
  id: number;

  @ApiProperty()
  @CreateDateColumn({ type: "timestamp", default: () => "CURRENT_TIMESTAMP(6)" })
  created: Date;

  @HidePublic()
  @ApiProperty()
  @TransformUserPreview()
  @ManyToOne(() => User, {
    nullable: false,
    onUpdate: "CASCADE"
  })
  createdBy: UserPreview;

  @ApiProperty()
  @IsDate()
  @IsOptional()
  @Transform(({value}) => new Date(value))
  @Column("timestamp", {
    precision: 3,
    nullable: true
  })
  updated: Date;

  @HidePublic()
  @ApiProperty()
  @TransformUserPreview()
  @ManyToOne(() => User, {
    nullable: true,
    onUpdate: "CASCADE"
  })
  updatedBy: UserPreview;

  @ApiProperty({type: () => PropertyBasicInfo})
  @OneToOne(() => PropertyBasicInfo, basicInfo => basicInfo.property, {nullable: true})
  @JoinColumn()
  basicInfo: PropertyBasicInfo;

  @ApiProperty()
  @OneToMany(() => PropertyBasicInfo, basicInfo => basicInfo.property)
  basicInfoHistories?: PropertyBasicInfo[];

  @ApiProperty()
  @OneToOne(() => PropertyPriceStatus, priceStatus => priceStatus.property, {nullable: true})
  @JoinColumn()
  priceStatus: PropertyPriceStatus;

  @ApiProperty()
  @HidePublic()
  @OneToOne(() => PropertyLandlord, landlord => landlord.property, {nullable: true})
  @JoinColumn()
  @Type(() => PropertyLandlord)
  landlord: PropertyLandlord;

  @ApiProperty()
  @OneToMany(() => PropertyLandlord, landlord => landlord.property)
  landlordHistories?: PropertyLandlord[];
  
  @ApiProperty()
  @OneToOne(() => PropertyDetail, detail => detail.property, {nullable: true})
  @JoinColumn()
  detail: PropertyDetail;

  @HidePublic()
  @ApiProperty()
  @Column({
    nullable: false,
    default: false
  })
  starred?: boolean = false;
  
  @ApiProperty()
  @OneToOne(() => PropertyPublishment, publishment => publishment.property, {nullable: true})
  // @JoinColumn()
  publishment?: PropertyPublishment;

  @DeleteDateColumn({precision: 3})
  @Exclude({toPlainOnly: true})
  deletedAt?: Date;
}


@Entity()
export class PropertyPublicClone extends PropertyBase {
  @ApiProperty()
  @OneToOne(() => PropertyPublishmentPublicClone, publishment => publishment.property, {nullable: true})
  // @JoinColumn()
  publishment?: PropertyPublishmentPublicClone;
}

@Entity()
export class Property extends PropertyBase {
  @ApiProperty()
  @OneToOne(() => PropertyPublishment, publishment => publishment.property, {nullable: true})
  // @JoinColumn()
  publishment?: PropertyPublishment;
  
  @ApiProperty({
    type: () => PropertyPublicClone
  })
  publicClone?: PropertyPublicClone;
}

// export class PropertyLoaded extends Property {
//   constructor(obj: PropertyLoaded) {
//     super();
//     Object.assign(this, obj);
//     this.basicInfo = new PropertyBasicInfo(obj.basicInfo);
//     this.priceStatus = new PropertyPriceStatus(obj.priceStatus);
//     this.landlord = new PropertyLandlord(obj.landlord);
//     this.detail = new PropertyDetail(obj.detail);
//     this.createdBy && (this.createdBy = new UserPreview(this.createdBy));
//     this.updatedBy && (this.updatedBy = new UserPreview(this.updatedBy));
//   }

//   // Just for API output
//   @ApiProperty({
//     type: () => PropertyBasicInfo
//   })
//   basicInfo: PropertyBasicInfo;

//   @ApiProperty({
//     type: () => PropertyPriceStatus
//   })
//   priceStatus: PropertyPriceStatus;

//   @ApiProperty({
//     type: () => PropertyLandlord
//   })
//   landlord: PropertyLandlord;
  
//   @ApiProperty({
//     type: () => PropertyDetail
//   })
//   detail: PropertyDetail;



// }

export class PropertyDto extends OmitType(Property, [
  "id", "basicInfo", "priceStatus", "landlord", "detail", "landlordHistories", "updatedBy", "created", "createdBy", "publishment", "starred",
] as const) {

  @ApiProperty({
    type: () => PropertyBasicInfoDto
  })
  @ValidateNested()
  @Type(() => PropertyBasicInfoDto)
  basicInfoDto: PropertyBasicInfoDto;

  @ApiProperty({
    type: () => PropertyPriceStatusDto
  })
  @ValidateNested()
  @Type(() => PropertyPriceStatusDto)
  priceStatusDto: PropertyPriceStatusDto;

  @ApiProperty({
    type: () => PropertyLandlordDto
  })
  @ValidateNested()
  @Type(() => PropertyLandlordDto)
  landlordDto: PropertyLandlordDto;
  
  @ApiProperty({
    type: () => PropertyDetailDto
  })
  @ValidateNested()
  @Type(() => PropertyDetailDto)
  detailDto: PropertyDetailDto;

  @ApiProperty({
    type: () => PropertyPublishmentDto
  })
  @Type(() => PropertyPublishmentDto)
  @IsOptional()
  @ValidateNested()
  publishmentDto: PropertyPublishmentDto;


}

export class PropertyPdfContentDetail {
  @ApiProperty()
  @IsString()
  key: string;

  @ApiProperty()
  @IsString()
  value: string;
}
export class PropertyPdfContent {
  @ApiProperty()
  @IsEnum(["en", "tc"])
  lang: "en" | "tc" = null;

  @ApiProperty()
  @IsString()
  title1: string = "";

  @ApiProperty()
  @IsString()
  title2: string = "";

  
  @ApiProperty()
  @IsNumber()
  detailsLineSpacing: number = 1;

  @ApiProperty()
  @IsEnum(PdfAvailableFonts)
  font: PdfAvailableFont = "NotoSansHK";

  @ApiProperty()
  @ValidateNested({each: true})
  @Type(() => PropertyPdfContentDetail)
  details: PropertyPdfContentDetail[] = [];

  @ApiProperty()
  @IsString()
  contactLine: string = "";

  @ApiProperty()
  @IsString()
  licenseNumber: string = "";

  // @ApiProperty()
  // @IsInt()
  // @IsOptional()
  // imageBuildingId: number | null = null;

  // @ApiProperty()
  // @IsString({each: true})
  // @IsOptional()
  // imageFilenames: string[] = [];

  // @ApiProperty()
  // @IsInt()
  // @IsOptional()
  // imageBuildingId?: number;

  // @ApiProperty()
  // @ValidateIf(obj => obj.building != null)
  // @IsString({each: true})
  // @IsNotEmpty({each: true})
  // imageFilenames?: string[] = [];
  @ApiProperty()
  @IsBase64({each: true})
  base64Images?: string[] = [];

  @ApiProperty()
  @IsBoolean()
  @IsOptional()
  imageAddWatermark = true;


  @ApiProperty()
  @IsString()
  imageTitle: string = "";
}

export const PropertyListPdfOrientations = ["landscape", "portrait"] as const;
export type PropertyListPdfOrientation = (typeof PropertyListPdfOrientations)[number];

export const PropertyListAvailableKeys = [
  "name",
  "block",
  "floor",
  "flat",
  "district",
  "street",
  "area",
  "rooms",
  "carparks",
  "view",
  "price",
  "rent",
  "status",
  "landlord", 
  "contact",
  "remarks",
  "refNo",
  "internalRemarks",
  "lastUpdate",
] as const; 


export type PropertyListAvailableKey = (typeof PropertyListAvailableKeys)[number];

export const PropertyListStarKeys: PropertyListAvailableKey[] = [
  "name", "area", "lastUpdate", "price", "rent"
];

export const PropertyListWholeRowKeys: PropertyListAvailableKey[] = [
  "lastUpdate"
];

export const PropertyListKeysPresetKeys = [
  "external", "internal", "editHistory"
] as const;

export type PropertyListKeysPresetKey = (typeof PropertyListKeysPresetKeys)[number];

export const PropertyListKeysPresets: Record<PropertyListKeysPresetKey, PropertyListAvailableKey[]> = {
  external: ["name", "block", "floor", "flat", "district", "street", "area", "rooms", "carparks", "view", "price", "remarks"],
  internal: ["refNo", "name", "block", "floor", "flat", "area", "price", "rent", "landlord", "contact", "status", "internalRemarks", "lastUpdate"],
  editHistory: ["refNo", "name", "block", "floor", "flat", "area", "price", "rent", "landlord", "contact", "status", "internalRemarks", "lastUpdate"],
}



export class PropertyListPdfContent {
  @ApiProperty()
  @IsEnum(PropertyListPdfOrientations)
  orientation: PropertyListPdfOrientation = "landscape";

  @ApiProperty()
  @IsString()
  to: string;

  @ApiProperty()
  @IsString()
  from: string;

  @ApiProperty()
  @IsString()
  licenseNumber: string;

  @ApiProperty()
  @IsString()
  email: string;

  @ApiProperty()
  @IsString()
  phone: string;

  @ApiProperty()
  @IsObject()
  keys: {[key: string]: {
    content: string,
    star: boolean,
    wholeRow?: boolean,
  }}

  @ApiProperty()
  @IsEnum(PdfAvailableFonts)
  font: PdfAvailableFont;

  @ApiProperty()
  @IsNumber()
  fontSize: number;

  @ApiProperty()
  @IsNumber()
  lineSpacing: number;

  @ApiProperty()
  @IsBoolean()
  showHeaderFooter: boolean;

  @ApiProperty()
  @IsString()
  pageTitle: string;

  @ApiProperty()
  @IsArray()
  @IsObject({each: true})
  rows: {[key: string]: {
    content: string,
    bold?: boolean,
    alignment?: "left" | "center" | "right",
    color?: string
  }}[];



}


export interface PropertyGeneratePdfEvent {
  isPropertyPdfEvent: true;
  wsToken: string;
  stage: "uploading" | "start" | "processing-image" | "generating-pdf";
  imageCount?: number
  processedImageCount?: number;
}

export interface PropertyPublishmentImageEvent {
  isPropertyPublishmentImageEvent: true;
  wsToken: string;
  stage: "start" | "processing-image";
  imageCount?: number
  processedImageCount?: number;
}

export interface PropertySort extends EntitySort<Pick<Property, "id"> & {
  // "timestamp.updated": any;
  // "timestamp.created": any;
}> {}

export class PropertyFindAllQuery extends FindAllQuery {
  @ApiProperty()
  @IsBooleanQueryString()
  @IsOptional()
  includeHistory? = false;
  
  @ApiProperty()
  @IsBooleanQueryString()
  @IsOptional()
  cache? = true;


}

export const PropertyEditHistoryUpdateKeys = [
  "basicInfo", "priceStatus", "landlord", "detail"
] as const;

export type PropertyEditHistoryUpdateKey = (typeof PropertyEditHistoryUpdateKeys)[number];


export class PropertyEditHistory {
  constructor(partial: Partial<PropertyEditHistory>) {
    Object.assign(this, partial);
  }

  compositeId: string;
  
  property: Property;

  basicInfo: PropertyBasicInfo | null;

  priceStatus: PropertyPriceStatus | null;

  landlord: PropertyLandlord | null;

  detail: PropertyDetail | null;

  updated: Date;

  @TransformUserPreview()
  updatedBy: User;
}

export class PropertyEditHistoryFindAllQuery extends FindAllQuery {


  @ApiProperty()
  @ValidateIf(o => o.editHistory)
  @TransformDate()
  @IsDate()
  editHistoryFrom?: Date;

  @ApiProperty()
  @ValidateIf(o => o.editHistory)
  @TransformDate()
  @IsDate()
  editHistoryTo?: Date;

  @ApiProperty()
  @ValidateIf(o => o.editHistory)
  @Transform(({value}) => {
    return value ? value.split(",").map(str => parseInt(str)) : undefined
  })
  @IsInt({each: true})
  @IsOptional()
  editHistoryUsers?: number[]
}

export class PropertyByDistrictCount {
  @ApiProperty()
  district: District;

  @ApiProperty()
  count: number;
}

export class PropertyWithRawCount extends Property {
  constructor(partial: Partial<PropertyWithRawCount>) {
    super();
    Object.assign(this, partial);
  }

  @ApiProperty()
  districtCount?: number;

  @ApiProperty()
  buildingCount?: number;

}
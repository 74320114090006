import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { AuthLoginDto, AuthLoginResult, AuthLogoutDto, AuthRefreshAccessTokenDto, AuthRefreshAccessTokenResult, ResetPasswordDto, SendResetPasswordEmailDto, SendResetPasswordEmailResult } from "../entities/user.entity";
import { APIBase } from "./apiBase";
import { APIEncodeQueryString } from "./helpers";

export default class AuthAPI extends APIBase {
  

  constructor(axios: AxiosInstance) {
    super(axios, "auth");
  }

  async login(dto: AuthLoginDto): Promise<AuthLoginResult> {
    return (await this.axios.post<AuthLoginResult>(
      this.base + "/login",
      dto,
      {
        _retryForAccessToken: false   // No retry for login, to avoid refresh token check
      } as any
    )).data;
  }

  async check(isAdmin?: boolean, config?: AxiosRequestConfig): Promise<boolean> {
    return (await this.axios.get<boolean>(
      this.base + "/check?" + APIEncodeQueryString({isAdmin}), config
    )).data;
  }

  async refreshAccessToken(dto: AuthRefreshAccessTokenDto): Promise<AuthRefreshAccessTokenResult> {
    return (await this.axios.post<AuthRefreshAccessTokenResult>(
      this.base + "/refresh-access-token",
      dto, 
      {
        _retries: 0   // No retry for refresh access token, to avoid infinite loop
      } as any
    )).data;
  }

  async logout(dto: AuthLogoutDto): Promise<boolean> {
    return (await this.axios.post<boolean>(
      this.base + "/logout",
      dto
    )).data;
  }

  async googleLogin(credential: string): Promise<AuthLoginResult> {
    return (await this.axios.post<any>(
      this.base + "/google/login",
      {credential},
      {
        _retryForAccessToken: false   // No retry for login, to avoid refresh token check
      } as any
    )).data;
  }

  async facebookLogin(accessToken: string): Promise<AuthLoginResult> {
    return (await this.axios.post<any>(
      this.base + "/facebook/login",
      {accessToken},
      {
        _retryForAccessToken: false   // No retry for login, to avoid refresh token check
      } as any
    )).data;
  }

  async sendResetPasswordEmail(dto: SendResetPasswordEmailDto): Promise<SendResetPasswordEmailResult> {
    return (await this.axios.post<SendResetPasswordEmailResult>(
      this.base + "/send-reset-password-email", dto
    )).data;
  }

  async checkResetPasswordToken(token: string) {
    return (await this.axios.get(
      this.base + "/reset-password/" + token
    )).data;
  }

  async resetPassword(token: string, dto: ResetPasswordDto) {
    return (await this.axios.post(
      this.base + "/reset-password/" + token, dto
    )).data;
  }
}
import { faCamera, faImage } from "@fortawesome/free-solid-svg-icons"
import { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { ButtonWithLoader, ButtonWithLoaderProps } from "./ButtonWithLoader"



export const Base64ImageButton = (props: Omit<ButtonWithLoaderProps, "title"> & {
  title?: React.ReactNode;
  onRead?: (base64Data: string) => void;
}) => {
  let {title, onRead, ...otherProps} = props;

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files

    let file = acceptedFiles[0];

    if (file.type.match('image.*')) {
      console.log(file);

      var reader = new FileReader();
      reader.onload = (event) => {
        onRead?.(event.target.result.toString());
      }
      reader.readAsDataURL(file);
    }


  }, [])
  const {getRootProps, getInputProps, isDragActive, open} = useDropzone({
    onDrop,
    multiple: false,
    disabled: props.loading || props.disabled
  })

  return (
    <div 
      className="base64-image-btn w-100"
      style={{
        ...(
          isDragActive && {
            opacity: 0.5
          }
        )
      }}
      {...getRootProps()}
    >
      <input 
        {...getInputProps()}
        accept="image/*"
      />
      <ButtonWithLoader
        size="sm"
        className="w-100"
        variant="info"
        faIcon={faImage}
        {...otherProps}
      >
        {title || "Upload image"}
      </ButtonWithLoader>
    </div>
  )
}


export const PdfDocOrientations = [
  "landscape", "protrait"
] as const;

export type PdfDocOrientation = (typeof PdfDocOrientations)[number];

export const PdfAvailableFonts = [
  "NotoSansHK", "NotoSerifHK", "NotoSansHKLight", "NotoSerifHKLight"
] as const;

export type PdfAvailableFont = (typeof PdfAvailableFonts)[number];

export interface PdfFont {
  title: string;
  light?: boolean;
  bold?: boolean;
  fontFamily?: string;
  lineHeightRatio?: number;
}

export const PdfFonts: Record<PdfAvailableFont, PdfFont> = {
  NotoSansHK: {
    title: "Noto Sans 思源黑體",
    fontFamily: "'Noto Sans HK', sans-serif"
  },
  NotoSerifHK: {
    title: "Noto Serif 思源宋體",
    fontFamily: "'Noto Serif HK', serif",
    lineHeightRatio: 1.22,
  },
  NotoSansHKLight: {
    title: "Light Noto Sans 幼思源黑體",
    fontFamily: "'Noto Sans HK', sans-serif",
    light: true,
  },
  NotoSerifHKLight: {
    title: "Light Noto Serif 幼思源宋體",
    fontFamily: "'Noto Serif HK', serif",
    light: true,
    lineHeightRatio: 1.22,
  }
}
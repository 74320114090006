import { faCheck, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { Flipper, Flipped } from 'react-flip-toolkit';
import ReactSelect from "react-select";
import { APIInstance } from "../../../api"
import { PropertyPublishmentTag } from "../../../api/entities/property/property-publishment-tag.entity";
import { User } from "../../../api/entities/user.entity";
import { AutocompleteSearch } from "../../../component/AutocompleteSearch";
import { ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { useGlobalModal } from "../../../component/GlobalModal";
import { useI18N } from "../../../component/I18NProvider"
import { useAbortableLoading } from "../../../utils/abortable-loading";
import { LangMap } from "../../../utils/lang-map";
import { useLoadingManager } from "../../../utils/loading-manager";
import { AdminPropertyI18NPrefix } from "../src/Property";
import { SortableSelect } from "./SortableSelect";

export const PropertyTagsInput = (props: {
  api: APIInstance,
  loading?: boolean,
  placeholder?: React.ReactNode,
  disabled?: boolean,
  selectedTags?: PropertyPublishmentTag[],
  onChange?: (tags: PropertyPublishmentTag[]) => void,
}) => {
  const {api, selectedTags} = props;
  const {t, currentLang} = useI18N();
  const [allTags, setAllTags] = useState<PropertyPublishmentTag[]>(null);
  const [inputValue, setInputValue] = useState("");
  const loading = useAbortableLoading();
  const modal = useGlobalModal();
  const reload = useCallback(async() => {
    const {token, signal} = loading.start();
    try {
      setAllTags((await api.property.findAllTags({
        page: 1,
        limit: 100,
        searchInput: inputValue
      }, {signal}))?.items)
    } catch (e) {
      modal.errorSpecific(e);
    }
    loading.stop(token);
  }, [inputValue])

  const selectedTagsNameEn = useMemo(() => selectedTags?.map(tag => tag.nameEn), [selectedTags])
  useEffect(() => {
    reload();
    return () => {
      loading.abort();
    }
  }, [inputValue])

  const allTagsSorted = useMemo(() => {
    return allTags && [
      ...selectedTagsNameEn?.map(nameEn => allTags.find(tag => tag.nameEn == nameEn)),
      ...allTags.filter(tag => !selectedTagsNameEn.includes(tag.nameEn))
    ]
  }, [allTags, selectedTagsNameEn])

  // console.log(allTagsSorted);
  const allTagsSortedNameEn = useMemo(() => allTagsSorted?.map(tag => tag.nameEn), [allTagsSorted]);
  // console.log({allTagsSortedNameEn})
  return <Flipper flipKey={allTagsSortedNameEn?.join("")} className="w-100">{
    loading.flag ? <Spinner animation="border" /> : <div className="d-flex flex-wrap" style={{gap: "0.3em"}}>{
      allTagsSorted ? (
        allTagsSorted.map((tag) => {
          const {nameEn} = tag;
          // console.log(nameEn);
          const flipId = nameEn.replace(/\s/g, "");
          const isSelected = selectedTagsNameEn.includes(nameEn);
          return (
            <Flipped key={flipId} flipId={flipId}>
              <div>
                <ButtonWithLoader size="sm" variant={isSelected ? "primary" : "outline-info"}
                  faIcon={isSelected && faCheck}
                  onClick={() => {
                    if (!isSelected) {
                      props.onChange?.([...selectedTags, tag])
                    } else {
                      props.onChange?.(selectedTags.filter(t => t.nameEn != tag.nameEn))
                    }
                  }}
                >{tag.nameTc} {tag.nameEn}</ButtonWithLoader>
              </div>
              
            </Flipped>
          )
        })
      ) : <></>
    }</div>
  }</Flipper>



  
}

export const PropertyTagsInputSearch = (props: {
  api: APIInstance,
  loading?: boolean,
  placeholder?: React.ReactNode,
  disabled?: boolean,
  selectedTags?: PropertyPublishmentTag[],
  onChange?: (tags: PropertyPublishmentTag[]) => void,
}) => {
  const {api, selectedTags} = props;
  const {t, currentLang} = useI18N();
  const [searchedTags, setSearchedTags] = useState<PropertyPublishmentTag[]>([]);
  const [inputValue, setInputValue] = useState("");
  const loading = useAbortableLoading();

  const searchTags = useCallback(async() => {
    const {token, signal} = loading.start();
    try {
      setSearchedTags((await api.property.findAllTags({
        page: 1,
        limit: 20,
        searchInput: inputValue
      }, {signal}))?.items)
    } catch (e) {

    }
    loading.stop(token);
  }, [inputValue])


  useEffect(() => {
    searchTags();
    return () => {
      loading.abort();
    }
  }, [inputValue])

  return <div className="w-100" style={{zIndex: 20}}>
    <SortableSelect
      className="w-100"
      isMulti
      multiSelectSortable
      isDisabled={props.disabled || props.loading}
      placeholder={<div><FontAwesomeIcon icon={faSearch} className="text-black-50 ms-1 me-2"/>{
        t(AdminPropertyI18NPrefix + "publishment.tags.searchTags")
      }</div>}
      // required={false}
      onInputChange={str => {
        setInputValue(str);
      }}
      options={searchedTags.map((tag) => ({
        label: <div>
          <span className="me-2">{tag.nameTc}</span>
          <span className="text-black-50">{tag.nameEn}</span>
        </div>,
        value: tag.nameEn,
        tag
      }))}
      value={selectedTags.map((tag) => ({
        label: <div>
          <span>{tag["name" + LangMap(currentLang)]}</span>
        </div>,
        value: tag.nameEn,
        tag
      }))}
      onChange={tags => {
        props.onChange?.(tags.map(({tag}) => tag))
      }}
    />
  </div>



  
}
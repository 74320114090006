import { faUsers, faUserPlus, faArrowsRotate, faQrcode, faDownload } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { LinkWithLoader, ButtonWithLoader } from "../../../component/ButtonWithLoader";
import { useI18N } from "../../../component/I18NProvider";
import { AdminComponent, AdminComponentTitle, AdminHeaderTitle, useAdminComponent } from "../component/AdminComponent";
import { EntityData } from "../component/EntityData";
import { AdminQRCodeUtilPath, AdminUserPath } from "../variables";
import QRCodeStyling from "qr-code-styling";
import { Button, ButtonGroup, Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { faCopy } from "@fortawesome/free-regular-svg-icons";

export interface qrCodeUtilContent {
  url: string;
  imageSrc?: string;
  tracking: boolean;
  source: string;
  medium: string;
  campaign: string;

}

const loadImage = async(src: string) => {
  return new Promise<void>((resolve, reject) => {
    const img = document.createElement("img");
    img.src = src;
    img.onload = () => {
      console.log("Image loaded");
      resolve();
    }
    img.onerror = () => {
      reject();
    }
  })
}

const toDataURL = (url: string) => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))

export const AdminQRCodeUtil = () => {
  const {t} = useI18N();
  const {} = useAdminComponent({selectedItem: AdminQRCodeUtilPath});
  const preview = useRef<HTMLDivElement>(null);

  const [content, setContent] = useState<qrCodeUtilContent>({
    url: process.env.REACT_APP_PUBLIC_URL,
    imageSrc: "/images/福-green-01.png",
    tracking: false,
    source: "星島",
    medium: "newspaper",
    campaign: moment().format("YYYYMM"),
  })

  const qrCode = useRef(new QRCodeStyling({
    width: 280,
    height: 280,
    backgroundOptions: {
      color: "#ffffff"
    },
    qrOptions: {
      errorCorrectionLevel: "H"
    }
  }));


  const generatedUrl = useMemo(() => {
    const searchParams = new URLSearchParams();
    if (content.source) {searchParams.set("utm_source", content.source)}
    if (content.medium) {searchParams.set("utm_medium", content.medium)}
    if (content.campaign) {searchParams.set("utm_campaign", content.campaign)}
    return content.url + (
      content.tracking ? "?" + searchParams.toString() : ""
    )
  }, [content]);

  const reloadQrCodeImage = useCallback(async(oneMoreWithTimeout = true) => {
    let imgSrc = "";

    if (content.imageSrc) {
      imgSrc = await toDataURL(content.imageSrc);
    }
    qrCode.current.update({
      data: generatedUrl,
      image: content.imageSrc || null
    })
    // Saveguard reload
    if (oneMoreWithTimeout) {
      setTimeout(() => {
        reloadQrCodeImage(false);
      }, 300)
    }
  }, [content, generatedUrl]);

  useEffect(() => {
    qrCode.current.append(preview.current);
    reloadQrCodeImage();

  }, [preview.current])

  useEffect(() => {
    reloadQrCodeImage();
  }, [content, generatedUrl])

  return (
    <AdminComponent.Container>

      <AdminHeaderTitle>{t('admin.qrCodeUtil.title')}</AdminHeaderTitle>
      <AdminComponent.TitleBar>
        <AdminComponentTitle 
          faIcon={faQrcode}
        >
          {t('admin.qrCodeUtil.pageTitle')}
          
        </AdminComponentTitle>
      </AdminComponent.TitleBar>
      
      <div className="hr" />
      <EntityData
        className="mb-5"
        tight
        object={content}
        onChange={content => setContent(content)}
        meta={{
          preview: {
            type: "entireComponent",
            component: <>
              <div className="d-flex align-items-center justify-content-center" ref={preview}></div>
              <div className="d-flex align-items-center justify-content-center mt-2 mb-2">
                <Button size="sm" variant="success" className="me-2 px-3"
                  onClick={() => reloadQrCodeImage()}
                >
                  <FontAwesomeIcon icon={faArrowsRotate}/>
                </Button>
                <ButtonGroup size="sm">
                  {
                    ["svg", "png", "jpeg"].map(extension => (
                      <Button key={extension} variant="outline-info" className="px-3 d-flex align-items-center"
                        onClick={() => {
                          const payload = content.tracking ? [
                            content.source, content.medium, content.campaign
                          ].join("-") : "";
                          qrCode.current?.download?.({
                            extension: extension as any,
                            name: "bestgroup-qr" + (payload ? "-" + payload : ""),
                          })
                        }}
                      >
                        <FontAwesomeIcon icon={faDownload} className="me-2 text-info" size="lg" />{extension.toUpperCase()}
                      </Button>
                    ))
                  }
                </ButtonGroup>
              </div>

            </>
          },
          hr: {
            type: "hr",
          },
          imageSrc: {
            type: "select",
            title: t('admin.qrCodeUtil.useImage'),
            divWidth: {
              xs: 12, md: 6
            },
            selectOptions: [null, "/images/福-green-01.png", "/images/福-01.png"].map(src => ({
              label: src ? <img src={src} style={{width: 20}} /> : <span className="text-black-50">N/A</span>,
              value: src || null
            }))
          },
          tracking: {
            type: "switch",
            title: t('admin.qrCodeUtil.tracking'),
            divWidth: {
              xs: 12, md: 6
            }
          },
          source: {
            hidden: !content.tracking,
            type: "text",
            title: <>{t('admin.qrCodeUtil.source')}{` `}<span className="text-black-50 fw-normal">{t('admin.qrCodeUtil.sourceExample')}</span></>,
            divWidth: {
              xs: 12, md: 6
            }
          },
          medium: {
            hidden: !content.tracking,
            type: "text",
            title: <>{t('admin.qrCodeUtil.medium')}{` `}<span className="text-black-50 fw-normal">{t('admin.qrCodeUtil.mediumExample')}</span></>,
            divWidth: {
              xs: 12, md: 6
            }
          },
          campaign: {
            hidden: !content.tracking,
            type: "text",
            title: <>{t('admin.qrCodeUtil.campaign')}{` `}<span className="text-black-50 fw-normal">{t('admin.qrCodeUtil.campaignExample')}</span></>
          },
          generatedUrl: {
            type: "valueComponent",
            title: <div className="d-flex align-items-center">
              <span> {t('admin.qrCodeUtil.generatedUrl')}</span>
              <CopyToClipboard text={generatedUrl}
                onCopy={() => {console.log("Copied")}}>
                <FontAwesomeIcon icon={faCopy} className="ms-2" style={{cursor: "pointer"}}/>
              </CopyToClipboard>
            </div>,
            component: <div style={{wordBreak: "break-word"}}>{generatedUrl}</div>
          }

        }}
      />
    </AdminComponent.Container>

  
  );
}
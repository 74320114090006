

import { useEffect, useMemo } from 'react';
import { Beforeunload } from 'react-beforeunload';
import { useGlobalModal } from './GlobalModal';
import { useI18N } from './I18NProvider';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { usePrompt } from '../utils/useBlocker';

export const UnsavedMessageTitleI18NId = 'commons.unsavedMessageTitle';
export const UnsavedMessageI18NId = 'commons.unsavedMessage';

export const UnsavedPrompt = (props: {
  flag: boolean
}) => {
  const {t} = useI18N();
  let unsavedMessage = useMemo(() => {
    return t(UnsavedMessageI18NId)
  }, []);
 
  usePrompt(unsavedMessage, props.flag);

  return (
    <>
      {
        // Refresh case
        props.flag && <Beforeunload onBeforeunload={() => unsavedMessage} />
      }
      {/* {
        // Navigate case
        <Prompt when={props.flag === true} message={unsavedMessage} />
      } */}
    </>
  )
}
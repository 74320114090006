import { AxiosInstance, AxiosRequestConfig } from "axios";
import { FindAllQuery } from "../entities/findAllQuery";
import { Paginated } from "../entities/pagination";
import { PropertyListing, PropertyListingCreationResult, PropertyListingDto } from "../entities/property/property-listing.entity";
import { APIBase } from "./apiBase";
import { APIEncodeQueryString } from "./helpers";

export default class PropertyListingAPI extends APIBase {
  

  constructor(axios: AxiosInstance) {
    super(axios, "property-listing");
  }

  async create(dto: PropertyListingDto): Promise<PropertyListingCreationResult> {
    return (await this.axios.post<PropertyListingCreationResult>(
      this.base,
      dto
    )).data;
  }

  async findAll(query: FindAllQuery, config?: AxiosRequestConfig): Promise<Paginated<PropertyListing>> {
    return (await this.axios.get<Paginated<PropertyListing>>(
      this.base + "?" + APIEncodeQueryString(FindAllQuery.toJson(query)), config
    )).data;
  }

  async countPendingItems(): Promise<number> {
    return (await this.axios.get<number>(
      this.base + "/count-pending" 
    )).data;
  }

  async findById(id: PropertyListing["id"]): Promise<PropertyListing> {
    return (await this.axios.get<PropertyListing>(
      this.base + "/" + id
    )).data;
  }

  async updateStatus(id: PropertyListing["id"], status: PropertyListing["status"]): Promise<PropertyListing> {
    return (await this.axios.patch<PropertyListing>(
      this.base + "/" + id + "/status/" + status
    )).data;
  }

  async uploadImage(
    file: File,
    onUploadProgress?: (e: ProgressEvent) => void,
    config?: AxiosRequestConfig
  ) {
    let formData = new FormData();
    formData.append("file", file);
    return (await this.axios.post<{
      key: string,
      presignedUrl: string
    }>(
      this.base + "/upload-image",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: onUploadProgress as any,
        ...config,
      }
    )).data;
  }

  async getPresignedPhotoUrl(key: string): Promise<string> {
    return (await this.axios.get<string>(
      this.base + "/photo/" + key
    )).data;
  }

}
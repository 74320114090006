import "reflect-metadata";

export interface HidePublicMetadata {
  hidePublic: boolean
}


const HidePublicMetadataKey = Symbol("HidePublic");
const ShowPublicMatchKeySourceMetadataKey = Symbol("ShowPublicMatchKeySource");
const ShowPublicMatchKeyTargetMetadataKey = Symbol("ShowPublicMatchKeyTarget");

export function HidePublic(): PropertyDecorator {
  return Reflect.metadata(HidePublicMetadataKey, {hidePublic: true});
}

export function ShowPublic(): PropertyDecorator {
  return Reflect.metadata(HidePublicMetadataKey, {hidePublic: false});
}

export function ShowPublicMatchKeySource(key: string): PropertyDecorator {
  return Reflect.metadata(ShowPublicMatchKeySourceMetadataKey, {key});
}

export function ShowPublicMatchKeyTarget(key: string): PropertyDecorator {
  return Reflect.metadata(ShowPublicMatchKeyTargetMetadataKey, {key});
}

export function getHidePublic<T>(target: T, propertyKey?: string | symbol) {
  return Reflect.getMetadata(HidePublicMetadataKey, target, propertyKey) as HidePublicMetadata | undefined;
}

export function HidePublicClass(): ClassDecorator {
  return (target) => {
    Reflect.defineMetadata(HidePublicMetadataKey, {hidePublic: true}, target)
  };
}

// Ref: https://github.com/nestjs/swagger/issues/217
export const SerializeHidePublic = (): MethodDecorator => {
  return (target: Object, methodName: string | symbol, descriptor: TypedPropertyDescriptor<any>) => {

    const className = target.constructor.name;
    const method = descriptor.value;
  
    descriptor.value = new Proxy(method, {
      apply: async function(target, thisArg, args) {
        // console.log(
        //   `Call with args: ${JSON.stringify(args)}`,
        //   `${className}#${methodName as string}`,
        // );

        const result = await target.apply(thisArg, args);
        const showPublicMatchKeySource = extractShowPublicMatchKeySource(result);
        // console.log({showPublicMatchKeySource});
        return hidePublicProperties(result, showPublicMatchKeySource);
        // console.log(
        //   `Return: ${JSON.stringify(result)}`,
        //   `${className}#${methodName as string}}`,
        // );
        return result;
      },
    });
  }

}

// Scan through all properties with ShowPublicMatchKeySource decorator
export function extractShowPublicMatchKeySource<T>(target: T): Record<string, boolean> {
  let matches: Record<string, boolean> = {};
  Object.keys(target).forEach((propertyKey) => {
    // console.log({propertyKey, classConstructor});
    const metadata = Reflect.getMetadata(ShowPublicMatchKeySourceMetadataKey, target, propertyKey);
    if ((metadata?.key != null)) {
      matches[metadata?.key] = target[propertyKey];
    }
    if (target[propertyKey] != null && typeof target[propertyKey] === "object") {
      // Nested
      const nestedMatches = extractShowPublicMatchKeySource(target[propertyKey]);
      matches = {...matches, ...nestedMatches}
    }
  })
  return matches;
}


export function hidePublicProperties<T>(target: T, showPublicMatchKeySource?: Record<string, boolean>): T {
  const classConstructor = target.constructor;
  // console.log(target);
  const classMetadata = getHidePublic(target.constructor);
  const hidePublicClass = !!classMetadata?.hidePublic;

  Object.keys(target).forEach((propertyKey) => {
    // console.log({propertyKey, classConstructor});
    const metadata = getHidePublic(target, propertyKey);
    const showPublicMatchKeyTargetMetaDataKey = Reflect.getMetadata(ShowPublicMatchKeyTargetMetadataKey, target, propertyKey)?.key;

    const showPublicMatchKeyIsFalse = showPublicMatchKeySource[showPublicMatchKeyTargetMetaDataKey] === false;

    // if (showPublicMatchKeyTargetMetaDataKey) console.log({showPublicMatchKeyTargetMetaDataKey, showPublicMatchKeyIsFalse});
    if (showPublicMatchKeyIsFalse || (hidePublicClass && metadata?.hidePublic !== false) || metadata?.hidePublic) {
      delete target[propertyKey];
    } else if (target[propertyKey] != null && typeof target[propertyKey] === "object") {
      // Nested
      hidePublicProperties(target[propertyKey], showPublicMatchKeySource);
    }
  });
  return target;
}

export const Langs = ["tc", "sc", "en"] as const;

export type Lang = (typeof Langs)[number];

export const LangMap = (lang: Lang | string) => {
  switch (lang) {
    case "tc": 
      return "Tc";
    case "sc": 
      return "Sc";
    default:
      return "En";
  }
}

export const LangDisplay = (lang: Lang) => {
  return {
    "tc": "繁體中文",
    "sc": "简体中文",
    "en": "English"
  }[lang] ?? "";
}

export const LangDisplayShort = (lang: Lang) => {
  return {
    "tc": "繁",
    "sc": "简",
    "en": "EN"
  }[lang] ?? "";
}


import { AxiosInstance, AxiosRequestConfig } from "axios";
import { ServerCostSummary } from "../entities/server-cost-summary.entity";
import { APIBase } from "./apiBase";

export default class ServerCostSummaryAPI extends APIBase {
  
  constructor(axios: AxiosInstance) {
    super(axios, "server-cost-summary");
  }

  async get(): Promise<ServerCostSummary> {
    return (await this.axios.get<ServerCostSummary>(this.base)).data;
  }



}
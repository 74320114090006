import { AxiosInstance, AxiosResponse } from "axios";
import { APIBase } from "./apiBase";

export interface Appointment {
  shirtDesignData: null;
  info: {
      region: string;
      city: string;
      name: string;
      email: string;
      contactNumber: string;
      location: string;
  };
}

export default class AppointmentAPI extends APIBase {
  

  constructor(axios: AxiosInstance) {
    super(axios, "appointment");
  }

  create(data: Appointment): Promise<AxiosResponse<any>> {
    return this.axios.post(this.base, data);
  }
}
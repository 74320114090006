import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";

export const ButtonGroupSelector = <T, Option extends {label: React.ReactNode, value: T} = {label: React.ReactNode, value: T}>(props: Omit<React.ComponentProps<typeof ButtonGroup>, "onChange"> & {
  value: T,
  options: Option[],
  onChange: (value: Option, isSelected?: boolean) => void,
  deleteOthersIfSelected?: boolean,
}) => {
  const {value, options, onChange, deleteOthersIfSelected, ...buttonGroupProps} = props;
  return (
    <ButtonGroup {...buttonGroupProps}>
      {
        options.map((option: Option, index) => {
          const selected = value === option.value;
          return <Button key={index}
            variant={selected ? "info" : "outline-info"}
            className={clsx(deleteOthersIfSelected === true && !selected && value != null && "text-decoration-line-through")}
            onClick={() => {
              onChange?.(option, selected)
            }}
          >{
          selected && <FontAwesomeIcon icon={faCheck} className="me-2" /> 
          }{
            option.label 
          }</Button>
        })
      }
    </ButtonGroup>
  )
}
import { ComponentPropsWithoutRef, useEffect, useRef } from "react";


export const Checkbox = (props: Omit<ComponentPropsWithoutRef<"input">, "type"> & {
  indeterminate?: boolean
}) => {
  const {indeterminate, ...inputProps} = props;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = props.indeterminate;
    }
  }, [props.indeterminate])

  return (
    <input 
      {...inputProps}
      ref={inputRef}
      type="checkbox"
    />
  )
}
import { ReactNode, useCallback, useEffect, useState } from "react";
import { AutocompleteSearch } from "../../../component/AutocompleteSearch"
import { v4 as uuid } from 'uuid';
import { APIInstance } from "../../../api";
import { GoogleApisMapsPlaceAutocompleteDto, GoogleApisMapsPlaceAutocompleteResult, GoogleApisMapsPlaceNamesResult } from "../../../api/entities/googleapis";
import { useAbortableLoading } from "../../../utils/abortable-loading";
import { useLoadingManager } from "../../../utils/loading-manager";
import { useI18N } from "../../../component/I18NProvider";
import { useGlobalModal } from "../../../component/GlobalModal";
import { Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";

var util = require('util')

export interface GoogleApiMapsSearchProps extends Omit<GoogleApisMapsPlaceAutocompleteDto, "input" | "sessiontoken"> {
  api: APIInstance;
  onComplete?: (result: GoogleApisMapsPlaceNamesResult) => void;
  placeholder?: ReactNode;
}

export const GoogleApiMapsSearch = (props: GoogleApiMapsSearchProps) => {
  const {api, onComplete, ...dto} = props;

  const [keyword, setKeyword] = useState("");
  const [sessionToken, setSessionToken] = useState<string | null>(null);
  const abortableLoading = useAbortableLoading();
  const placeLoading = useLoadingManager();

  const {t} = useI18N();
  const modal = useGlobalModal();

  const [autocompleteResult, setAutocompleteResult] = useState<GoogleApisMapsPlaceAutocompleteResult>(null);
  
  const renewSessionToken = useCallback(() => setSessionToken(uuid()), []);

  useEffect(() => {
    renewSessionToken();
  }, [])

  return (
    <AutocompleteSearch 
      input={keyword}
      loading={abortableLoading.flag || placeLoading.flag}
      placeholder={props.placeholder}
      disabled={placeLoading.flag}
      options={autocompleteResult?.predictions?.map(prediction => ({
        label: prediction.description,
        value: prediction.placeId
      }))}
      onChange={async(value) => {
        setKeyword(value);

        const input = value.trim();

        if (input == "") {
          setAutocompleteResult(null);
          abortableLoading.abort();
        } else {
          const {token, signal} = abortableLoading.start();
          try {
            const result = await api.googleapisApi.mapsPlaceAutocomplete({
              ...dto,
              input,
              sessiontoken: sessionToken
            }, {signal})
            console.log(result);
            setAutocompleteResult(result);
          } catch (e) {
            console.warn(e);
          } finally {
            abortableLoading.stop(token);
          }
        }
      }}
      onSelect={async(value) => {
        const placeId = value.value;
        const token = placeLoading.start();
        try {
          const result = await api.googleapisApi.mapsPlaceNames({
            placeId,
            sessiontoken: sessionToken
          });
          renewSessionToken();
          if (await modal.confirm(t("commons.confirmUse"), (
            <div className="d-flex flex-column" style={{gap: "0.5rem"}}>
              <div className="d-flex align-items-center" style={{gap: "0.5rem"}}>
                <Badge bg="info">繁</Badge>
                {result.nameTc}
                <a href={result.urlTc} target="_blank"><FontAwesomeIcon icon={faMapLocationDot}/></a>
              </div>
              <div className="d-flex align-items-center" style={{gap: "0.5rem"}}>
                <Badge bg="info">简</Badge>{result.nameSc}
              </div>
              <div className="d-flex align-items-center" style={{gap: "0.5rem"}}>
                <Badge bg="info">EN</Badge>{result.nameEn}
                <a href={result.urlTc} target="_blank"><FontAwesomeIcon icon={faMapLocationDot}/></a>
              </div>
            </div>
          ))) {
            props.onComplete?.(result);
          }

        } catch (e) {
          
        } finally {
          placeLoading.stop(token);
        }
      }}
    />
  )
}